import { Select, MenuItem, FormControl, InputLabel, Tooltip, Box } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import { StyledInput, StyledInputLabel, MenuItemIcon, StyledInfoOutlined } from './styles'

import { CREDIT_CARD_PAYMENT_OPTIONS, InputCreditCardOptionProps } from './types'
import { useJWT } from '@/shared/hooks'
import { usePaymentCreditCard } from '@/escolas/hooks'

const InputCreditCardPaymentOptions = ({
  handleValue,
  selectedValue,
}: InputCreditCardOptionProps) => {
  const { isSchool } = useJWT()
  const { isCreditCardFlow } = usePaymentCreditCard()
  const canUsePaymentLink = !(isSchool && isCreditCardFlow)

  return (
    <StyledInput>
      <StyledInputLabel>
        <Typography variation="bodySmall" withoutMargin color="secondary">
          Link de pagamento ou maquininha?
        </Typography>
      </StyledInputLabel>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="credit_card_payment_option">Selecionar forma de pagar</InputLabel>
        <Select
          labelId="credit_card_payment_option"
          value={selectedValue || ''}
          label="Selecionar forma de pagar"
          onChange={e => {
            handleValue(e.target.value)
          }}
        >
          <MenuItem value={CREDIT_CARD_PAYMENT_OPTIONS.POS}>Maquininha</MenuItem>
          {canUsePaymentLink ? (
            <MenuItem value={CREDIT_CARD_PAYMENT_OPTIONS.PAYMENT_LINK}>Link de pagamento</MenuItem>
          ) : (
            <Tooltip title="Oriente o responsável financeiro a gerar o link de pagamento pelo app meu isaac.">
              <Box>
                <MenuItem disabled>
                  Link de pagamento
                  <MenuItemIcon>
                    <StyledInfoOutlined />
                  </MenuItemIcon>
                </MenuItem>
              </Box>
            </Tooltip>
          )}
        </Select>
      </FormControl>
    </StyledInput>
  )
}

export default InputCreditCardPaymentOptions
