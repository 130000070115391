import { FilterOptionsType } from '../../types'
import { FiltersInstallmentsFormType } from '../components/GuardianDetailsInstallmentsFilter/types'
import { InstallmentsFiltersType } from '../types'

/**
 *
 * @param activeFilters Filters selected by the user.
 * @returns Filters to be shown in the ActiveFilters component.
 */
export const filtersToShow = (activeFilters: FiltersInstallmentsFormType) => {
  return Object.keys(activeFilters).reduce((acc, key) => {
    const filter = activeFilters[key as keyof InstallmentsFiltersType]

    if (Array.isArray(filter)) {
      if (filter.length === 0) return acc

      return [
        ...acc,
        ...filter.map(item => ({
          label: item.label,
          value: item.value,
        })),
      ]
    }

    if (!Array.isArray(filter) && !filter) return acc

    return [
      ...acc,
      {
        label: filter.label,
        value: filter.value,
      },
    ]
  }, [] as FilterOptionsType[])
}
