import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  padding: 0 ${({ theme }) => theme.spacing(3)}px ${({ theme }) => theme.spacing(3)}px;
  border-radius: 8px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
