import styled from 'styled-components'
import { colors, spacing } from '@gravity/tokens'
import { Accordion } from '@gravity/accordion'
import { Text } from '@gravity/text'

export const AccordionTriggerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]};
  text-align: left;
`

export const AccordionTriggerItems = styled.div`
  display: flex;
  gap: ${spacing[4]};
`

export const AccordionTriggerItem = styled.div`
  display: flex;
  gap: ${spacing[2]};

  > svg {
    color: #000;
    opacity: 0.6;
  }
`

export const AccordionItem = styled(Accordion.Item)<{ disabled?: boolean; isLoading?: boolean }>`
  > [data-state] > [aria-controls] {
    ${({ disabled }) => disabled && 'cursor: default;'};
    ${({ isLoading }) => isLoading && 'cursor: progress;'};
    ${({ disabled, isLoading }) => !disabled && !isLoading && 'cursor: pointer;'};

    > svg {
      ${({ disabled }) => disabled && 'display: none;'}
    }
  }
`

export const AccordionContent = styled(Accordion.Content)`
  > div {
    padding: ${spacing[4]} ${spacing[6]} ${spacing[8]};
    display: flex;
    flex-direction: column;
    gap: ${spacing[8]};
    background-color: transparent;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: ${colors['colors-border-neutral-2']};
    border-radius: 0 0 4px 4px;
  }
`

export const DateSeparator = styled.div`
  margin-top: ${spacing[4]};
`

export const NegotiationInfoContainer = styled.div<{ columns: number }>`
  display: grid;
  ${({ columns }) => `grid-template-columns: repeat(${columns}, 1fr);`}
  gap: ${spacing[4]} ${spacing[6]};

  & + & {
    margin-top: ${spacing[4]};
  }
`

export const NegotiationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[1]};
`

export const NegotiationContentSubtitle = styled(Text)`
  display: inline-block;
  margin-bottom: ${spacing[4]};
`

export const NegotiationActionsContainer = styled.div`
  display: flex;
  gap: ${spacing[4]};
  justify-content: space-evenly;
`
