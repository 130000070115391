import { useContextEligibility } from '../contexts/CreditDataContext'
import { GracePeriodDate } from '@/shared/services/credit/types'
import { dateToFormattedString } from './formatDate'

export const useGetGracePeriodDates = (): GracePeriodDate[] => {
  const { eligibility } = useContextEligibility()

  let dates: Date[] = []

  const cpDates = eligibility.gracePeriod.CP || []
  const cpcrDates = eligibility.gracePeriod.CPCR || []
  dates = Array.from(new Set([...cpDates, ...cpcrDates]))

  const gracePeriodDates: GracePeriodDate[] = dates.map(date => ({
    label: dateToFormattedString(date),
    value: date.toString(),
  }))

  return gracePeriodDates
}
