import styled from 'styled-components'
import { Table } from '@gravity/table'

export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;
`
export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;
`

export const TableHeaderCell = styled(Table.HeaderCell)`
  span {
    -webkit-line-clamp: 2;
  }
`
