export type TableRowProps = {
  columns: string[]
  hasPaymentPlan: boolean
  key: string
}

export enum columnNames {
  END_DATE = 'end_date',
  ENGAGED_STUDENTS_COUNT = 'engaged_students_count',
  NAME = 'name',
  PAYMENTS_CONFIRMED = 'paid_enrollments_count',
  STATUS = 'status',
  STUDENTS_COUNT = 'students_count',
  TOTAL_OF_CAMPAIGN_EMAILS_READ = 'total_of_campaign_emails_read',
}
type ColumnName = `${columnNames}`

export type SortableColumns = 'name' | 'created_at'

export type ColumnProps = {
  key: ColumnName
  label: string
  minWidth: string
  sortable?: boolean
}
