import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: ${props => props.theme.gravity.spacing[4]};

  .title {
    color: ${props => props.theme.gravity.colors['colors-text-main-1']};
  }

  .logo-isaac {
    margin-bottom: ${props => props.theme.gravity.spacing[14]};
  }
`

export const Logo = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
`
