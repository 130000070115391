import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

export type NoResultsProps = {
  filterParam: string
  secondaryText?: string
}

export const NoResults = ({ filterParam, secondaryText }: NoResultsProps) => {
  return (
    <>
      {filterParam ? (
        <Box width="50%" mx="auto" textAlign="center" my={10}>
          <Typography variant="h3">{`Não encontramos resultados para "${filterParam}"`}</Typography>
          <Typography variant="body2" color="textSecondary">
            {`Verifique se não houve erro de digitação. ${secondaryText || ''}`}
          </Typography>
        </Box>
      ) : null}
    </>
  )
}
