import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components'

const StyledTypography = styled(Typography).attrs({ withoutMargin: true })`
  overflow-wrap: break-word;
`
export const DueDayBox = styled(Box)`
  margin-bottom: 4px;
  margin-top: 24px;
`
export const StartMonthBox = styled(Box)`
  margin: 34px 0;
`

export const FieldTitleTypography = styled(StyledTypography)`
  line-height: 24px;
  margin-bottom: 4px;
  color: #414141;
`

export const FieldLegendTypography = styled(StyledTypography)`
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #6d6d6d;
`
export const ArithmeticOperator = styled(StyledTypography)`
  display: flex;
  align-items: center;
  margin: 0 12px;
  font-weight: 500;
  font-size: 18px;
  color: #414141;
`
