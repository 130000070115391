import { RouteDefinition, Router } from '@/shared/core/router'
import { PayoutAnticipationProvider } from '../contexts/PayoutAnticipationContext'
import { useRouteMatch } from 'react-router-dom'
import { ModuleRegister } from '@/shared/core/module'
import { IsaacPayPayoutReport } from '../pages/IsaacPayPayoutReport'
import { EnrollmentPayoutReport } from '../pages/EnrollmentPayoutReport'
import { TuitionPayoutReport } from '../pages/TuitionPayoutReport'
import { PayoutReportDashboard } from '../pages/PayoutReportDashboard'

export const internalRoutes: RouteDefinition[] = [
  {
    key: 'PAYOUT_DASHBOARD',
    path: '/',
    component: PayoutReportDashboard,
    exact: true,
  },
  {
    key: 'TUITION_PAYOUT',
    path: '/mensalidades',
    component: TuitionPayoutReport,
    exact: true,
  },
  {
    key: 'ENROLLMENT_PAYOUT',
    path: '/matriculas',
    component: EnrollmentPayoutReport,
    exact: true,
  },
  {
    key: 'ISAAC_PAY_PAYOUT',
    path: '/isaac-pay',
    component: IsaacPayPayoutReport,
    exact: true,
  },
]

const ModuleInternalRoutesWrapper = () => {
  const { path: basePath } = useRouteMatch()

  const moduleRegister = new ModuleRegister()
  moduleRegister.registerRoutes(internalRoutes, { basePath })

  return (
    <PayoutAnticipationProvider>
      <Router routes={moduleRegister.getRoutes()} loadingComponent={<p>loading</p>} />
    </PayoutAnticipationProvider>
  )
}

export const routes: RouteDefinition[] = [
  {
    key: 'PAYOUT_ROUTES',
    path: '/repasses',
    component: ModuleInternalRoutesWrapper,
    exact: false,
  },
]
