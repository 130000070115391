import dayjs from 'dayjs'
import { LOCALSTORAGE_USERGUIDING_STATE_BY_USERS } from '../constants'

interface UserGuidingState {
  enabled: boolean
  viewDate: datestring
}

function getUserGuidingByUsers(): Record<uuid, UserGuidingState> {
  const userGuidingByUsers = localStorage.getItem(LOCALSTORAGE_USERGUIDING_STATE_BY_USERS)

  return userGuidingByUsers ? JSON.parse(userGuidingByUsers) : {}
}

export function addUserGuidingSeenItem(userId: uuid, enabled = true) {
  const userGuidingByUser = getUserGuidingByUsers()
  userGuidingByUser[userId] = {
    enabled,
    viewDate: new Date().toString(),
  }

  localStorage.setItem(LOCALSTORAGE_USERGUIDING_STATE_BY_USERS, JSON.stringify(userGuidingByUser))
}

export function shouldShowTheUserGuiding(userId: uuid) {
  const userGuidingByUser = getUserGuidingByUsers()
  const userGuidingState = userGuidingByUser[userId]

  if (!userGuidingState) return true
  if (!userGuidingState.enabled) return false

  const isPastOneWeek = dayjs().diff(userGuidingState.viewDate, 'day') >= 7

  return isPastOneWeek
}
