import { Badge } from '@gravity/badge'

import * as Styled from './styles'

import { PaymentDisputeSummaryState } from '@/modules/payment-dispute/models/PaymentDisputeSummaryState.enum'
import { PaymentDisputeFilterType } from '@/modules/payment-dispute/pages/PaymentDispute/types'

type PaymentDisputeStatusFilterProps = {
  filter: PaymentDisputeFilterType
  updateFilter: (updatedFilter?: PaymentDisputeFilterType) => void
}

export const PaymentDisputeStatusFilter = ({
  filter,
  updateFilter,
}: PaymentDisputeStatusFilterProps) => {
  const paymentDisputeStatusList: Array<PaymentDisputeSummaryState> = [
    PaymentDisputeSummaryState.NOT_STARTED,
    PaymentDisputeSummaryState.STARTED,
    PaymentDisputeSummaryState.COMPLETED,
  ]

  const paymentDisputeStatusTranslationDict: Record<PaymentDisputeSummaryState, string> = {
    [PaymentDisputeSummaryState.NOT_STARTED]: 'Não iniciada',
    [PaymentDisputeSummaryState.STARTED]: ' Iniciada',
    [PaymentDisputeSummaryState.COMPLETED]: 'Concluída',
  }

  return (
    <Styled.Container>
      <Styled.Text variant="body-1-regular">Filtrar por:</Styled.Text>
      {paymentDisputeStatusList.map(status => {
        const statusFilter = status
        const isSelected = filter.status.includes(statusFilter)
        return (
          <Styled.StatusContainer
            key={status}
            onClick={() => {
              const activeFilters = filter.status
              let nextFilters = [...activeFilters, statusFilter]

              if (activeFilters.includes(statusFilter)) {
                nextFilters = activeFilters.filter(e => e !== statusFilter)
              }

              updateFilter({
                ...filter,
                status: nextFilters,
              })
            }}
          >
            <Badge variant={isSelected ? 'solid' : 'outline'} color="accent" size={1}>
              {paymentDisputeStatusTranslationDict[status]}
            </Badge>
          </Styled.StatusContainer>
        )
      })}
    </Styled.Container>
  )
}
