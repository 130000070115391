import styled from 'styled-components'

export const Content = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: ${({ theme }) => theme.gravity.spacing[10]};
  column-gap: ${({ theme }) => theme.gravity.spacing[8]};

  .email {
    grid-area: 1 / 1 / 2 / 4;
  }

  .access-type {
    grid-area: 2 / 1 / 3 / 2;
  }

  .school {
    grid-area: 2 / 2 / 3 / 4;
  }

  .first-name {
    grid-area: 3 / 1 / 4 / 2;
  }

  .last-name {
    grid-area: 3 / 2 / 4 / 4;
  }
`
