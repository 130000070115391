import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { MAX_STEP_WIDTH } from './constants'

export const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  margin-left: -96px;
`

export const Content = styled.div`
  margin: 0 auto;
`

export const StepperWrapper = styled.div`
  max-width: ${MAX_STEP_WIDTH}px;
  margin: 0 auto;
`

export const ContainerWithActionDrawer = styled(Grid)<{ $isActionDrawerOpen?: boolean }>(
  ({ $isActionDrawerOpen }) => ({
    transition:
      'flex-basis 0.3s cubic-bezier(0.42,0.29,0.39,0.83), max-width 0.3s cubic-bezier(0.42,0.29,0.39,0.83)',
    ...($isActionDrawerOpen
      ? { flexGrow: 0, maxWidth: '76.666667%', flexBasis: '66.666667%' }
      : {}),
  })
)
