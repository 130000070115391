import theme from '@/shared/theme'
import { Box, Dialog } from '@material-ui/core'
import CloseRounded from '@material-ui/icons/CloseRounded'

import {
  Typography,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@olaisaac/design-system'
import { StyledIconButton } from './styles'

type ModalConfirmationProps = {
  isLoading?: boolean
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  onReview: () => void
}

export function ModalConfirmation({
  isLoading = false,
  isOpen,
  onReview,
  onClose,
  onConfirm,
}: ModalConfirmationProps) {
  const currentYear = new Date().getFullYear()

  return (
    <Dialog open={isOpen} onClose={isLoading ? () => null : onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="baseline">
          <Typography variation="headlineDesktopSmall" style={{ margin: 0, marginBottom: -30 }}>
            Ao manter todas as cobranças do aluno, você confirma:
          </Typography>
          <StyledIconButton onClick={onClose}>
            <CloseRounded />
          </StyledIconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box
          paddingLeft={theme.primitiveTokens.spacing[6]}
          paddingTop={theme.primitiveTokens.spacing[3]}
          paddingBottom={theme.primitiveTokens.spacing[6]}
        >
          <ul>
            <li>
              <Typography>
                A matrícula do ciclo {currentYear.toString()} está regularizada;
              </Typography>
            </li>
            <li>
              <Typography>O contrato está ativo;</Typography>
            </li>
            <li>
              <Typography>O pagamento das parcelas não foi efetuado na escola.</Typography>
            </li>
          </ul>
        </Box>

        <Typography>Se necessário, revise as cobranças do aluno.</Typography>
      </DialogContent>

      <DialogActions>
        <Button variation="ghost" onClick={onReview} disabled={isLoading}>
          Revisar cobranças
        </Button>

        <Button variation="primary" onClick={onConfirm} loading={isLoading}>
          Manter cobranças
        </Button>
      </DialogActions>
    </Dialog>
  )
}
