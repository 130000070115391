import { useApi } from '@/shared/hooks'

export type UseRefreshStudentSituationProps = {
  schoolId: uuid
  studentIds: uuid[]
}

export const useRefreshStudentsSituation = (props: UseRefreshStudentSituationProps) => {
  const { api } = useApi()

  const updateEnrollmentSituation = (studentId: uuid, enrollmentCycle: number) => {
    return api.enrollment.updateEnrollmentSituation(props.schoolId, studentId, enrollmentCycle)
  }

  const fetchUpdateEnrollmentSituationAll = (enrollmentCycle: number) => {
    return Promise.all(
      props.studentIds.map(studentId => updateEnrollmentSituation(studentId, enrollmentCycle))
    )
  }

  const refreshStudentsSituation = (enrollmentCycle: number) => {
    return fetchUpdateEnrollmentSituationAll(enrollmentCycle)
  }

  return {
    refreshStudentsSituation,
  }
}
