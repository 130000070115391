import { BoxStyled } from '../../styles'
import { Button } from '@gravity/button'
import { Box } from '@material-ui/core'
import { TextStyled, TitleStyled } from './styles'
import Icon from '@mdi/react'
import { mdiRefresh } from '@mdi/js'
type ErrorStateProps = {
  onRetry: () => void
  title: string
}

/**
 * Error state for dashboard card component
 *
 * @param props
 * @param props.title Title
 * @param props.onRetry Callback function for retry button
 */
export const ErrorState = ({ title, onRetry }: ErrorStateProps) => {
  return (
    <BoxStyled data-testid="error-state">
      <TitleStyled data-testid="title-element">{title}</TitleStyled>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection="column"
        style={{ gap: '9.5rem' }}
      >
        <TextStyled>
          Ocorreu um erro inesperado ao carregar as informações do seu repasse. {'\n'}Tente
          novamente mais tarde.
        </TextStyled>
        <Box display="flex" alignItems="flex-start" alignSelf="stretch">
          <Button size={2} iconStart={<Icon path={mdiRefresh} size={1} />} onClick={onRetry}>
            Recarregar
          </Button>
        </Box>
      </Box>
    </BoxStyled>
  )
}
