import { buildCategoryItems } from '../buildCategoryItems'

import { FINANCIAL_CATEGORIES_LABELS } from '../../constants/FINANCIAL_CATEGORIES_LABELS'
import { FINANCIAL_CATEGORIES_TOOLTIPS } from '../../constants/FINANCIAL_CATEGORIES_TOOLTIPS'

import type { FinancialCategories } from '@/modules/report/models/FinancialCategories'
import type { FinancialOperations } from '@/modules/report/models/FinancialOperations'
import type { Category } from '../../components/PayoutTransfersSectionGroup/types'

/**
 * Utility function to build drawer financial categories
 *
 * @param financialGroup Financial operations group
 *
 * @returns Formatted categories
 */
export const buildCategories = (financialGroup: FinancialOperations): Category[] => {
  const categories = Object.entries(financialGroup.items).map(([key, category]) => {
    const label = FINANCIAL_CATEGORIES_LABELS[key as FinancialCategories]
    const tooltip = FINANCIAL_CATEGORIES_TOOLTIPS[key as FinancialCategories]

    const categoryItems = buildCategoryItems(category.items ?? [])

    return {
      key: key as FinancialCategories,
      label,
      tooltip,
      total: categoryItems.length === 0 ? category.total : undefined,
      items: categoryItems,
    }
  })

  return categories
}
