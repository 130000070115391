import { useNavigation } from 'src/escolas/hooks'

export const useRedirection = (guardianId: uuid) => {
  const { navigateTo } = useNavigation()
  const navigateToContractsPage = () => {
    navigateTo({
      path: `/responsaveis/${guardianId}/contratos`,
    })
  }

  return { navigateToContractsPage }
}
