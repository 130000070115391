import { useEffect, useState } from 'react'
import { FailureFeedbackDialog } from '@/escolas/components/modal/ConfirmationDialog'

type ErrorDialogProps = {
  isError: boolean
}

export const ErrorDialog = ({ isError }: ErrorDialogProps) => {
  const [showErrorDialog, setShowErrorDialog] = useState(true)

  useEffect(() => {
    if (isError) setShowErrorDialog(true)
  }, [isError])

  return (
    <FailureFeedbackDialog
      isVisible={isError && showErrorDialog}
      onClose={() => setShowErrorDialog(false)}
      submitHandler={() => setShowErrorDialog(false)}
    />
  )
}
