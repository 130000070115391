import styled from 'styled-components'

import theme from '@/shared/theme'

const BORDER_COLOR = theme.primitiveTokens.colors.gray[10]

export const Divider = styled.span`
  margin-top: 1rem;
  display: block;
  width: 100%;
  height: 1px;
  background-color: ${BORDER_COLOR};
`
