import styled from 'styled-components'

import { colors, spacing, lineHeight } from '@gravity/tokens'
import { Table } from '@gravity/table'

type TableRowProps = {
  disabled: boolean
}

export const TableWrapper = styled.div`
  width: 100%;
`
export const TableRow = styled(Table.Row)<TableRowProps>`
  ${({ disabled }) =>
    disabled &&
    `
    path {
      color: ${colors['colors-background-neutral-4']};
    }
  `}
  span {
    -webkit-line-clamp: 2;
  }
`
export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;
`

export const TableHeaderCell = styled(Table.HeaderCell)`
  span {
    -webkit-line-clamp: 2;
  }
`

export const EmptyRow = styled(Table.Row)`
  display: flex;
  width: 100%;
  min-width: 300px;
  padding: ${spacing[5]} ${spacing[3]};
  line-height: ${lineHeight.normal};
`
