import styled from 'styled-components'
import { Select } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'

export const SelectYearTitle = styled(Typography)`
  font-weight: 500;
  font-size: 2rem;
`

export const Container = styled.div`
  margin: 24px 16px;
  display: flex;
  gap: 16px;
  width: 100%;
`

export const SelectYear = styled(Select).attrs(() => ({
  disableUnderline: true,
  MenuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  },
}))`
  border-bottom: none;
  border-radius: ${({ theme }) => theme.primitiveTokens.spacing[2]};
  padding: 2px ${({ theme }) => theme.primitiveTokens.spacing[2]};
  margin-left: ${({ theme }) => theme.primitiveTokens.spacing[1]};

  &.Mui-focused,
  &:hover {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]}14;
  }

  .MuiInput-input {
    font-size: 2rem;
    line-height: 2.35rem;
    font-weight: 500;
    color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
  }

  .MuiSelect-selectMenu {
    display: flex;
  }
`
