import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

type Event = {
  customProperties: Record<string, any>
  identifierName?: string
  name: string
  pageName?: string
  scope: string
}

const createEvent = (): Event => ({
  name: '',
  scope: EventDispatcherEventScopes.ENROLLMENT,
  customProperties: {},
  identifierName: '',
  pageName: '',
})

const setName = (event: Event, name: string): Event => ({
  ...event,
  name,
})

const setIdentifierName = (event: Event, identifierName: string): Event => ({
  ...event,
  identifierName,
})

const setPageName = (event: Event, pageName: string): Event => ({
  ...event,
  pageName,
})

const addCustomProperty = (event: Event, key: string, value: any): Event => ({
  ...event,
  customProperties: {
    ...event.customProperties,
    [key]: value,
  },
})

const createEventBuilder = () => {
  let event = createEvent()

  const builder = {
    setName: (name: string) => {
      event = setName(event, name)
      return builder
    },
    setIdentifierName: (identifierName: string) => {
      event = setIdentifierName(event, identifierName)
      return builder
    },
    setPageName: (pageName: string) => {
      event = setPageName(event, pageName)
      return builder
    },
    addCustomProperty: (key: string, value: string) => {
      event = addCustomProperty(event, key, value)
      return builder
    },
    build: () => event,
  }

  return builder
}

export const useEnrollmentMixpanelEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const sendEvent = (eventBuilder: ReturnType<typeof createEventBuilder>) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent(eventBuilder.build())
    }
  }

  const sendButtonClickEvent = (
    buttonName: string,
    pageName?: string,
    identifierName?: string,
    selected_item?: string
  ) => {
    const eventBuilder = createEventBuilder()
      .setName(EventDispatcherEvents.BUTTON_CLICKED)
      .addCustomProperty('$button_name', buttonName)

    if (pageName) eventBuilder.setPageName(pageName)
    if (identifierName) eventBuilder.setIdentifierName(identifierName)
    if (selected_item) eventBuilder.addCustomProperty('$selected_item', selected_item)

    sendEvent(eventBuilder)
  }

  /**
   * Sends a page view event to the event dispatcher.
   * Used to track pages that will not change their URL structure, e.g., campaign steps.
   * To track page redirects that change the URL structure, it is recommended to use useTrackPageView.ts.
   *
   * @param {string} pageName - The name of the page where the button was clicked.
   */
  const sendPageViewEvent = (pageName: string) => {
    const eventBuilder = createEventBuilder()
      .setName(EventDispatcherEvents.PAGE_VIEWED)
      .setPageName(pageName)
      .addCustomProperty('$page_name', pageName)

    sendEvent(eventBuilder)
  }

  const sendTabClickEvent = (name: string, selected_tab: string) => {
    sendEvent(
      createEventBuilder()
        .setName(EventDispatcherEvents.TAB_CLICKED)
        .addCustomProperty('$tab_name', name)
        .addCustomProperty('$selected_item', selected_tab)
    )
  }

  const sendModalViewEvent = (name: string) => {
    sendEvent(
      createEventBuilder()
        .setName(EventDispatcherEvents.MODAL_VIEWED)
        .addCustomProperty('$modal_name', name)
    )
  }

  const sendFeedbackButtonClickEvent = (
    buttonName: string,
    actionFeedback: string,
    pageName: string,
    identifierName: string
  ) => {
    const eventBuilder = createEventBuilder()
      .setName(EventDispatcherEvents.BUTTON_CLICKED)
      .setPageName(pageName)
      .setIdentifierName(identifierName)
      .addCustomProperty('$button_name', buttonName)
      .addCustomProperty('$button_feedback', actionFeedback)

    sendEvent(eventBuilder)
  }

  return {
    sendButtonClickEvent,
    sendPageViewEvent,
    sendTabClickEvent,
    sendModalViewEvent,
    sendFeedbackButtonClickEvent,
  }
}
