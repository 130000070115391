import { FC, useEffect } from 'react'
import { Button, ButtonDocker, DialogContent } from '@olaisaac/design-system'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UseFormReturn } from 'react-hook-form'
import styled from 'styled-components'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from 'src/escolas/components/modal/ConfirmationDialog'
import { useApi, useSnackbar } from 'src/shared/hooks'

type DrawerFormProps = {
  border?: boolean
  buttonDockerDescription?: string
  disableFeedback?: boolean
  errorMessage?: string
  errorTitle?: string
  form: UseFormReturn<any>
  isEditDiscount?: boolean
  isLoading?: boolean
  onFinish?: () => void
  onSubmit?: (form?: any, e?: React.BaseSyntheticEvent) => Promise<void> | void
  prevDiscountsLength?: number
  successMessage?: string
}

const StyledOverlay = styled.div`
  opacity: 0.87;
  position: absolute;
  background: #fff;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DrawerForm: FC<DrawerFormProps> = ({
  children,
  disableFeedback,
  buttonDockerDescription,
  form,
  prevDiscountsLength,
  successMessage,
  errorMessage,
  errorTitle,
  onFinish,
  onSubmit,
  isLoading,
  isEditDiscount,
}) => {
  const { setCorrelationId } = useApi()
  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()

  if (!form) return null

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isSubmitted, isSubmitSuccessful, isSubmitting, isValid, isDirty, touchedFields },
  } = form

  const { discounts } = getValues()
  const quantityOfDiscountsChanged = prevDiscountsLength !== discounts?.length
  const hasNotChanged = Object.keys(touchedFields).length === 0 && !quantityOfDiscountsChanged
  const resetErrors = () => {
    reset()
    setCorrelationId('')
  }

  const showSuccessFeedback = isSubmitted && isSubmitSuccessful && !isSubmitting && !disableFeedback
  const showFailureFeedback =
    isSubmitted && !isSubmitSuccessful && !isSubmitting && !disableFeedback

  useEffect(() => {
    if (showSuccessFeedback) {
      setSnackbarMessage(successMessage)
      setSnackbarIsOpen(true)
      resetErrors()
      onFinish()
    }
  }, [showSuccessFeedback])
  return (
    <>
      {isSubmitting && (
        <StyledOverlay>
          <CircularProgress />
        </StyledOverlay>
      )}
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
      </DialogContent>

      <ButtonDocker description={buttonDockerDescription}>
        <Button
          fullWidth
          variation="primary"
          disabled={isSubmitting || !isValid || !isDirty || (isEditDiscount && hasNotChanged)}
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        >
          Confirmar
        </Button>
      </ButtonDocker>
      <FailureFeedbackDialog
        buttonLabel="Voltar"
        isVisible={showFailureFeedback}
        onClose={resetErrors}
        submitHandler={resetErrors}
        title={errorTitle}
        centered
      >
        <FailureFeedbackContent message={errorMessage} />
      </FailureFeedbackDialog>
    </>
  )
}

export default DrawerForm
