import { Button } from '@gravity/button'
import { ErrorContainer, ErrorContainerItem, ErrorDescription, ErrorTitle } from './styles'

type Props = {
  description?: string
  handleRetryButton?: () => void
  src: string
  title: string
  titleButton: string
}
export const CreditComponentError = ({
  src,
  title,
  titleButton,
  description,
  handleRetryButton = () => {
    location.reload()
  },
}: Props) => {
  return (
    <ErrorContainer>
      <ErrorContainerItem>
        <img src={src} alt="Imagem de erro" />

        <ErrorTitle>{title}</ErrorTitle>

        <ErrorDescription>{description}</ErrorDescription>

        <Button style={{ marginTop: '2rem' }} size={2} onClick={handleRetryButton}>
          {titleButton}
        </Button>
      </ErrorContainerItem>
    </ErrorContainer>
  )
}
