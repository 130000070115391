import type { AxiosInstance } from 'axios'
import type {
  FetchUsersRequest,
  FetchUsersResponse,
  CreateUserAccessRequest,
  CreateUserAccessResponse,
  UpdateUserAccessRequest,
  UpdateUserAccessResponse,
  RemoveUserAccessRequest,
  RemoveUserAccessResponse,
  CheckCreateUserAccessRequest,
  CheckCreateUserAccessResponse,
} from './types'

export const userAccessService = (fetcher: AxiosInstance) => {
  return {
    fetchUsers: async ({
      schoolId,
      sortBy,
      sortOrder,
    }: FetchUsersRequest): Promise<FetchUsersResponse> => {
      const { data } = await fetcher.get<FetchUsersResponse>('access-management/users', {
        params: {
          school_id: schoolId,
          sort_by: sortBy,
          sort_order: sortOrder,
        },
      })

      return data
    },
    createUserAccess: async ({
      schoolId,
      firstName,
      lastName,
      username,
      email,
      group,
    }: CreateUserAccessRequest): Promise<CreateUserAccessResponse> => {
      const { data: response } = await fetcher.post<{ data: CreateUserAccessResponse }>(
        'access-management/users',
        {
          school_id: schoolId,
          first_name: firstName,
          last_name: lastName,
          username,
          email,
          group,
        }
      )

      return response.data
    },
    updateUserAccess: async ({
      userId,
      schoolId,
      group,
    }: UpdateUserAccessRequest): Promise<UpdateUserAccessResponse> => {
      const { data: response } = await fetcher.patch<{ data: UpdateUserAccessResponse }>(
        `access-management/users/${userId}`,
        {
          school_id: schoolId,
          group,
        }
      )

      return response.data
    },
    removeUserAccess: async ({
      schoolId,
      userId,
    }: RemoveUserAccessRequest): Promise<RemoveUserAccessResponse> => {
      const { data: response } = await fetcher.patch<{ data: RemoveUserAccessResponse }>(
        `access-management/users/remove/${userId}`,
        {
          school_id: schoolId,
        }
      )

      return response.data
    },
    checkCreateUserAccess: async ({
      email,
      schoolId,
    }: CheckCreateUserAccessRequest): Promise<CheckCreateUserAccessResponse> => {
      const { data: response } = await fetcher.get<{ data: CheckCreateUserAccessResponse }>(
        'access-management/users/check-create',
        {
          params: {
            school_id: schoolId,
            email,
          },
        }
      )

      return response.data
    },
  }
}
