import { Box, Grid } from '@material-ui/core'
import { DefaultTemplateProps } from './types'
import { Button } from '@gravity/button'
import { ArrowBack } from '@material-ui/icons'
import { RowContainer, Text, TitleText } from './styles'

const DefaultTemplate = ({
  text,
  title,
  child,
  hasBackButton = true,
  handleBackPreviousRoute,
}: DefaultTemplateProps) => {
  return (
    <RowContainer>
      <Box mt={6}>
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            {hasBackButton && (
              <Button
                iconStart={<ArrowBack />}
                size={1}
                variant="ghost"
                onClick={handleBackPreviousRoute}
              >
                Voltar
              </Button>
            )}
            <TitleText> {title}</TitleText>
            {text && <Text>{text}</Text>}
            {child}
          </Grid>
        </Grid>
      </Box>
    </RowContainer>
  )
}

export default DefaultTemplate
