import { useContext, useLayoutEffect } from 'react'

import { LayoutContext } from '@/shared/contexts/Layout'
import { useIsFirstRender } from '@/shared/hooks/useIsFirstRender'

import type { HookValue, Options } from './types'

/**
 * Custom hook to manage layout options
 *
 * @param options Options to manipulate layout visibility
 *
 * @returns Values indicating if header and side menu is visible
 */
export const useLayout = (options: Options): HookValue => {
  const isFirstRender = useIsFirstRender()
  const { layoutOptions, updateLayoutOptions, updateHeaderTitle } = useContext(LayoutContext)

  useLayoutEffect(() => {
    updateLayoutOptions({
      header: options.enableHeader,
      sideMenu: options.enableSideMenu,
    })
    updateHeaderTitle(options.headerTitle ?? '')

    return () => {
      updateLayoutOptions({ header: false, sideMenu: true })
    }
  }, [])

  useLayoutEffect(() => {
    if (isFirstRender) return

    updateHeaderTitle(options.headerTitle ?? '')
  }, [options])

  return { isHeaderVisible: layoutOptions.header, isSideMenuVisible: layoutOptions.sideMenu }
}
