import CreditCard from 'src/escolas/assets/creditCard.svg'
import { Typography } from '@olaisaac/design-system'
import { formatCentsToReal } from 'src/shared/utils'
import type { PaymentPlanMethodsProps } from './types'
import { AvailableMethod, StyledImg } from './styles'

export const InfoPaymentPlanCreditCard = ({
  creditCardFee,
  isPaymentFeeEnabled,
  paymentPlan,
  totalAmount,
}: PaymentPlanMethodsProps) => {
  const { max_installments_allowed } = paymentPlan
  const numberOfInstallments = max_installments_allowed
  const total = isPaymentFeeEnabled ? totalAmount + creditCardFee : totalAmount

  let noFeeText = ''
  if (isPaymentFeeEnabled && creditCardFee > 0) {
    noFeeText = ' (com taxa)'
  }
  if (!isPaymentFeeEnabled) {
    noFeeText = ' sem juros'
  }

  const infoText =
    numberOfInstallments > 1
      ? `até ${numberOfInstallments}x de ${formatCentsToReal(
          total / numberOfInstallments
        )}${noFeeText}  `
      : `à vista de ${formatCentsToReal(total)}${noFeeText}`

  return (
    <AvailableMethod>
      <StyledImg src={CreditCard} />
      <Typography variation="bodySmall" color="secondary">
        <b>Cartão de crédito:</b> {infoText}
      </Typography>
    </AvailableMethod>
  )
}
