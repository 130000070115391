import styled from 'styled-components'
import { Link as DSLink } from '@olaisaac/design-system'

export const Container = styled.div``

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(5)}px;
`

export const Link = styled(DSLink)`
  margin-left: ${({ theme }) => theme.spacing(2)}px; ;
`

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(3)}px 0;
  margin-bottom: 100px;

  table > thead > tr > th:last-child .MuiTableSortLabel-root {
    width: 100%;
    justify-content: flex-end;
  }
`

export const AlignTableContent = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 100%;
`
