import {
  InstallmentType,
  MAX_DOWN_PAYMENT_INSTALLMENTS_IN_CART,
  MIN_CART_ITEMS,
} from '../../../constants'
import { Installment } from '../../../types'
import { sameContractInstallments } from './sameContractInstallments'

export const validateCart = (checkoutCart: Installment[]) => {
  const isCartEmpty = checkoutCart?.length < MIN_CART_ITEMS
  const downPaymentInstallments = checkoutCart?.filter(
    item =>
      item?.type === InstallmentType.DOWN_PAYMENT && (item.negotiation_id || item.simulation_id)
  )

  const negotiatedInstallments = checkoutCart?.filter(
    item =>
      item?.type === InstallmentType.INSTALLMENT && (item.negotiation_id || item.simulation_id)
  )

  if (isCartEmpty) {
    return {
      isValid: false,
      message: 'Antes de pagar, selecione as parcelas a serem pagas.',
    }
  }

  if (downPaymentInstallments?.length > MAX_DOWN_PAYMENT_INSTALLMENTS_IN_CART) {
    return {
      isValid: false,
      message:
        'Não é possível pagar - simultaneamente - parcelas de entrada de negociação de contratos diferentes.',
    }
  }

  if (
    downPaymentInstallments?.length === MAX_DOWN_PAYMENT_INSTALLMENTS_IN_CART &&
    checkoutCart?.length > MAX_DOWN_PAYMENT_INSTALLMENTS_IN_CART
  ) {
    return {
      isValid: false,
      message:
        'Não é possível pagar - simultaneamente - parcelas de entrada de negociação e outros tipos de parcela',
    }
  }

  if (
    negotiatedInstallments?.length >= MIN_CART_ITEMS &&
    !sameContractInstallments(negotiatedInstallments)
  ) {
    return {
      isValid: false,
      message:
        'Não é possível pagar - simultaneamente - parcelas de negociação de contratos diferentes.',
    }
  }

  if (
    negotiatedInstallments?.length >= MIN_CART_ITEMS &&
    checkoutCart?.length > negotiatedInstallments?.length &&
    !sameContractInstallments(checkoutCart)
  ) {
    return {
      isValid: false,
      message:
        'Não é possível pagar - simultaneamente - parcelas de negociação e outros tipos de parcela de contratos diferentes',
    }
  }

  return { isValid: true, message: null }
}
