import { StyledCard } from './styled'
import { Skeleton } from '@gravity/skeleton'
import { Box } from '@material-ui/core'

export const SkeletonCards = ({ cardsNumber }) => {
  return (
    <>
      {Array.from({ length: cardsNumber }).map((_, index) => (
        <StyledCard key={index}>
          <Skeleton radius={8} width={25} height={24} />
          <Skeleton radius={8} width={145} height={24} />

          {Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={index} radius={8} width={292} height={24} />
          ))}
        </StyledCard>
      ))}
    </>
  )
}

export const SkeletonLargeCard = () => {
  return (
    <StyledCard>
      <Box mb={3}>
        <Skeleton radius={8} width={621} height={38} />
      </Box>

      {Array.from({ length: 4 }).map((_, index) => (
        <Skeleton key={index} radius={8} width={739} height={24} />
      ))}

      <Box mt={4}>
        <Skeleton radius={8} width={353} height={24} />
      </Box>
    </StyledCard>
  )
}

export const SkeletonSmallCard = () => {
  return (
    <>
      <Box mb={4}>
        <Skeleton radius={8} width={214} height={24} />
      </Box>

      {Array.from({ length: 4 }).map((_, index) => (
        <Skeleton key={index} radius={8} width={214} height={24} />
      ))}

      <Box mt={4}>
        <Skeleton radius={8} width={214} height={24} />
      </Box>
    </>
  )
}
