import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Contract,
  ContractStatus,
  Discount,
  Installment,
  InstallmentType,
  PreContractStatuses,
  SignAndPaymentStatus,
  SignAndPaymentStatusLabel,
} from 'src/shared/interfaces'
import { ContractDetailsDrawerActions } from 'src/escolas/components/contract/ContractDetailsDrawer/ContractDetailsDrawerActions'
import { prop, sum } from 'ramda'
import { ContractDetailsSummary } from './ContractDetailsSummary'
import { ContractDetailsDrawerState } from 'src/escolas/components/contract/ContractDetailsDrawer/constants'
import { DialogContent, Notification } from '@olaisaac/design-system'
import { BufferInstallmentResponse } from 'src/shared/interfaces/bufferInstallment'

export type ContractDetailsDrawerDefaultContentProps = {
  contract: Contract
  contractStatus: string
  isSignable: boolean
  setDrawerState: Dispatch<SetStateAction<ContractDetailsDrawerState>>
}

type ContractInstallmentProps = {
  discounts: number
  installment: number
  installments: Installment[]
  installments_quantity: number
  totalAmount: number
}

export type ContractInstallments = {
  annuity: {
    totalAmount: number
    totalDiscounts: number
    totalWithDiscounts: number
  }
  enrollment: ContractInstallmentProps
  isaacPay: ContractInstallmentProps
  tuition: ContractInstallmentProps
}

export const sumDiscounts = (discounts: Discount[]) =>
  discounts?.reduce((acum, curr) => acum + curr.amount, 0) ?? 0

const defaultContractInstallments = {
  annuity: {
    totalAmount: 0,
    totalDiscounts: 0,
    totalWithDiscounts: 0,
  },
  enrollment: {
    discounts: 0,
    installment: 0,
    installments: [] as any[],
    installments_quantity: 0,
    totalAmount: 0,
  },
  tuition: {
    discounts: 0,
    installment: 0,
    installments: [] as any[],
    installments_quantity: 0,
    totalAmount: 0,
  },
  isaacPay: {
    discounts: 0,
    installment: 0,
    installments: [] as any[],
    installments_quantity: 0,
    totalAmount: 0,
  },
}

const calculateBufferInstallmentsForContractDetails = (
  bufferInstallments: BufferInstallmentResponse[]
): ContractInstallmentProps => {
  if (!bufferInstallments || bufferInstallments.length === 0) {
    return {
      discounts: 0,
      installment: 0,
      installments: null,
      installments_quantity: 0,
      totalAmount: 0,
    }
  }

  const firstInstallment = bufferInstallments[0]

  const monthlyAmount = firstInstallment.original_amount
  const totalMonthlyDiscount = sumDiscounts(firstInstallment.discounts)
  const intallmentsQuantity = bufferInstallments.length
  const totalAmount = monthlyAmount * intallmentsQuantity

  return {
    discounts: totalMonthlyDiscount,
    installment: monthlyAmount,
    installments: null, // not used for contract details drawer
    installments_quantity: bufferInstallments.length,
    totalAmount,
  }
}

const ContractDetailsDrawerDefaultContent = ({
  contract,
  contractStatus,
  setDrawerState,
  isSignable,
}: ContractDetailsDrawerDefaultContentProps) => {
  const [contractInstallments, setContractInstallments] = useState<ContractInstallments>(
    defaultContractInstallments
  )

  const [isEnrolled, setIsEnrolled] = useState(false)
  const [isPreEnrolled, setIsPreEnrolled] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [isPending, setIsPending] = useState(false)

  const getInstallmentsTotalAmount = (installments: any) => {
    return sum(installments.map(prop('amount')))
  }

  const getInstallmentsDiscountsTotalAmount = (installments: any) => {
    const discounts = installments?.map(prop('receivables'))[0]?.map(prop('discounts'))[0]

    return discounts?.length ? sum(discounts.map(prop('amount'))) : 0
  }

  const getContractInstallmentsDetails = (currentContract: Contract) => {
    const enrollmentInstallments = currentContract?.installments?.filter(({ type }) => {
      return type === InstallmentType.ENROLLMENT
    })

    const isaacPayInstallments = currentContract?.installments?.filter(({ type }) => {
      return type === InstallmentType.ISAACPAY
    })

    const enrollmentAmount = enrollmentInstallments[0]?.amount || 0

    const enrollment = {
      discounts: getInstallmentsDiscountsTotalAmount(enrollmentInstallments),
      installment: enrollmentAmount,
      installments: enrollmentInstallments,
      installments_quantity: enrollmentInstallments?.length,
      totalAmount: getInstallmentsTotalAmount(enrollmentInstallments),
    }

    const isaacPayAmount = isaacPayInstallments[0]?.amount || 0

    const isaacPay = {
      discounts: getInstallmentsDiscountsTotalAmount(isaacPayInstallments),
      installment: isaacPayAmount,
      installments: isaacPayInstallments,
      installments_quantity: isaacPayInstallments?.length,
      totalAmount: getInstallmentsTotalAmount(isaacPayInstallments),
    }

    let tuition: ContractInstallmentProps

    const tuitionInstallments = contract?.installments?.filter(({ type }) => {
      return type === InstallmentType.TUITION
    })

    if (
      currentContract.pre_contract_status === PreContractStatuses.EFFECTED_PCS ||
      tuitionInstallments.length > 0
    ) {
      const tuitionAmount = tuitionInstallments[0]?.amount || 0
      tuition = {
        discounts: getInstallmentsDiscountsTotalAmount(tuitionInstallments),
        installment: tuitionAmount,
        installments: tuitionInstallments,
        installments_quantity: tuitionInstallments?.length,
        totalAmount: getInstallmentsTotalAmount(tuitionInstallments),
      }
    } else {
      tuition = calculateBufferInstallmentsForContractDetails(
        currentContract.pre_contract_installments
      )
    }

    const enrollmentTotalDiscount = enrollment.discounts * enrollment.installments_quantity
    const tuitionTotalDiscount = tuition.discounts * tuition.installments_quantity
    const isaacPayTotalDiscount = isaacPay.discounts * isaacPay.installments_quantity

    const annuityTotalAmount = enrollment.totalAmount + tuition.totalAmount + isaacPay.totalAmount

    const annuity_with_discounts =
      annuityTotalAmount - (enrollmentTotalDiscount + tuitionTotalDiscount + isaacPayTotalDiscount)

    const annuity_total_discounts =
      enrollmentTotalDiscount + tuitionTotalDiscount + isaacPayTotalDiscount

    const annuity = {
      totalWithDiscounts: annuity_with_discounts,
      totalDiscounts: annuity_total_discounts,
      totalAmount: annuityTotalAmount,
    }

    setContractInstallments({
      annuity,
      enrollment,
      tuition,
      isaacPay,
    })
  }
  useEffect(() => {
    contract && getContractInstallmentsDetails(contract)
  }, [contract])

  useEffect(() => {
    if (!contractStatus) return
    setIsEnrolled(contractStatus === SignAndPaymentStatus.COMPLETE)
    setIsCanceled(contractStatus === SignAndPaymentStatus.CANCELED)
    setIsPreEnrolled(contractStatus === SignAndPaymentStatus.PRE_ENROLLED)
    setIsPending(contractStatus === ContractStatus.PENDING)
  }, [contractStatus])

  return (
    <>
      {isEnrolled && (
        <Notification
          variation="success"
          description={
            SignAndPaymentStatusLabel[contractStatus as keyof typeof SignAndPaymentStatusLabel]
          }
        />
      )}
      {isPreEnrolled && (
        <Notification
          variation="information"
          description={
            SignAndPaymentStatusLabel[contractStatus as keyof typeof SignAndPaymentStatusLabel]
          }
        />
      )}
      {isCanceled && <Notification variation="error" description="Inativo" />}
      {isPending && <Notification variation="warning" description="Em andamento" />}
      <DialogContent>
        <ContractDetailsSummary contractInstallments={contractInstallments} />
      </DialogContent>
      <ContractDetailsDrawerActions isSignable={isSignable} setDrawerState={setDrawerState} />
    </>
  )
}

export default ContractDetailsDrawerDefaultContent
