import { Tooltip } from '@material-ui/core'
import { Button, ButtonProps } from '@gravity/button'
import React from 'react'

export const EditActionButton = ({
  color,
  text,
  disabled,
  tooltip,
  icon,
  onClick,
  variant = 'ghost',
}: {
  color?: ButtonProps['color']
  disabled: boolean
  icon: React.ReactElement
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  text: string
  tooltip: string
  variant?: ButtonProps['variant']
}) => (
  <Tooltip title={tooltip}>
    <span>
      <Button
        onClick={onClick}
        iconStart={icon}
        variant={variant}
        disabled={disabled}
        color={color}
      >
        {text}
      </Button>
    </span>
  </Tooltip>
)
