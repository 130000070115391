import { useEffect } from 'react'
import { useQuery } from '@/shared/hooks/useQuery'

type Props = {
  ready?: boolean
}

/**
 * Hook para scrollar para um elemento com determinado id na página. O id é passado como o parâmetro `scroll_to` na url.
 * Opcionalmente, é possível passar um booleano `ready` para indicar que o scroll está pronto para ser realizado.
 *
 * Exemplo de uso:
 *
 * ```js
 * const { data, loading } = useSomeApiCall()
 *
 * useScrollToElement({
 *  ready: !loading,
 * })
 *
 * return (
 *   ...
 * )
 * ```
 */

export function useScrollToElement({ ready = true }: Props = {}) {
  const { query } = useQuery()

  useEffect(() => {
    if (!ready) return

    const id = query.get('scroll_to')

    if (id) {
      const element = document.getElementById(id)

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [ready])
}
