import { Box } from '@material-ui/core'
import { Divider, Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

export const CardWrapper = styled(Box)`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  padding: 32px;
`

export const InformationHeader = styled(Box)`
  display: flex;
  margin-bottom: 0.5px;
  justify-content: space-between;
  padding-top: 3px;
`

export const BoldTypography = styled(Typography)`
  font-weight: 700;
`

export const AgreementNumber = styled(Typography)`
  margin-bottom: 4px;
`

export const Subtitle = styled(Typography)`
  margin-bottom: 12px;
`

export const StyledDivider = styled(Divider)`
  margin: 24px 0;
`
