import { Box, FormControl, Select } from '@material-ui/core'
import { Radio, TextField, Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`
export const StyledBoxFields = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: centes;
`

export const TextRadio = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
`

export const StyledRadio = styled(Radio)`
  width: 20px;
  height: 20px;
  padding-top: 10px;
  margin-right: 10px;
`

export const WrapperRadio = styled(Box)`
  display: flex;
  align-items: flex-start;
`

export const StyledContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const StyledSelect = styled(Select)`
  text-align: initial;
`

export const StyledForm = styled(FormControl)`
  min-width: 290px;
  width: auto;
`

export const LargeStyledForm = styled(StyledForm)`
  width: 100%;
  padding-right: ${({ theme }) => theme.spacing(20)}px;
`

export const NoWrapTypography = styled(Typography)`
  white-space: nowrap;
`

export const StyledTypograph = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

export const NoWrapTypographyDisabled = styled(NoWrapTypography)`
  color: ${({ theme }) => theme.palette.text.disabled};
`
export const StyledBoxLoading = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  align-items: center;
  height: 100%;
  background: white;
  z-index: 3;
  justify-content: center;
  box-sizing: border-box;
`

export const StyledBoxIndex = styled(Box)`
  z-index: 4;
`

export const StyledTextArea = styled(TextField)`
  max-width: 752px;
  width: 100%;
`
