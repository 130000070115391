import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks'
import { UseLegacyContractsProps } from '../../services/types'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'

import { DEFAULT_QUERY_STALE_TIME } from '../constants'
import { Contract } from '@/shared/interfaces'

export const useGuardianContractsListQuery = (
  props: UseLegacyContractsProps,
  options?: UseQueryOptions<Contract[], XMLHttpRequest>
) => {
  const { api } = useApi()

  const queryKey = ['guardian-contracts-list', props.guardianId, props.schoolId]

  const {
    data: guardianContractsList,
    isLoading: isFetchGuardianContractsListLoading,
    isError: isFetchGuardianContractsListError,
    isFetched: isFetchGuardianContractsListFetched,
    refetch: refetchGuardianContractsList,
    isRefetching: isRefetchingGuardianContractsList,
  } = useQuery<Contract[], XMLHttpRequest>(
    queryKey,
    () => {
      return api.guardians.getContractsList(props.guardianId, props.schoolId)
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: Boolean(props.guardianId && props.schoolId),
      retry: false,
      staleTime: DEFAULT_QUERY_STALE_TIME,
      ...options,
    }
  )

  return {
    guardianContractsList,
    isFetchGuardianContractsListLoading,
    isFetchGuardianContractsListError,
    isFetchGuardianContractsListFetched,
    refetchGuardianContractsList,
    isRefetchingGuardianContractsList,
  }
}
