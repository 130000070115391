import type { CheckoutCombinableReceivable } from 'src/escolas/contexts/checkoutContext'
import { ReceivableStatuses } from 'src/shared/interfaces'
import { dateToOnlyDateISO, isDayBeforeToday } from 'src/shared/utils'

export const getReceivableStatus = (receivable: CheckoutCombinableReceivable): string => {
  if (isDayBeforeToday(dateToOnlyDateISO(receivable.due_date))) {
    return ReceivableStatuses.OVERDUE
  }
  return receivable.status
}
