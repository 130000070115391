import { fontSize, fontWeight, spacing } from '@gravity/tokens'
import styled from 'styled-components'
import { Text } from '@gravity/text'
import { Typography } from '@olaisaac/design-system'

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4]};
`

export const TitleText = styled(Text)`
  font-size: ${fontSize.lg};
  font-weight: ${fontWeight.medium};
`

export const DescriptionText = styled(Typography)`
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.normal};
`
