import { FC } from 'react'
import { ButtonGroup, Button } from '@olaisaac/design-system'

import * as S from './styles'
import type { FooterProps } from './types'

const Footer: FC<FooterProps> = ({
  enableSubmit = false,
  onClickGoBack,
  onClick,
  isLoading,
  type = 'submit',
  hideBackButton = false,
  label = 'Registrar recebimento',
}) => {
  return (
    <S.Wrapper>
      <ButtonGroup>
        {!hideBackButton && (
          <Button variation="ghost" onClick={onClickGoBack}>
            Voltar
          </Button>
        )}
        <Button
          type={type}
          disabled={!enableSubmit}
          loading={isLoading}
          variation="primary"
          onClick={onClick}
        >
          {label}
        </Button>
      </ButtonGroup>
    </S.Wrapper>
  )
}

export default Footer
