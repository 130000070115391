import styled from 'styled-components'

import theme from '@/shared/theme'

type ItemTextProps = {
  color?: string
}

const DEFAULT_COLOR = theme.primitiveTokens.colors.gray[80]

export const ItemText = styled.p<ItemTextProps>`
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 700;
  color: ${DEFAULT_COLOR};
`
