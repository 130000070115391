import ContractsYear from 'src/escolas/components/contract/ContractsYear'
import Contracts2021 from 'src/escolas/components/contract/2021'
import { assoc } from 'ramda'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useNavigation } from '@/escolas/hooks'
import { useQuery } from '@/shared/hooks/useQuery'
import { ContractStatus, ContractStatusLabel } from 'src/shared/interfaces'
import { PageRouteProps } from 'src/escolas/router'
import { TableFilterOptions } from 'src/escolas/components/TableFilter'
import { useApi, useJWT } from 'src/shared/hooks'
import { buildRoute } from '@/utils/router/utils'

import { ContractFilters } from '..'
import { formatGuardianAddress } from 'src/shared/utils'
import ConfirmationDialog from 'src/escolas/components/modal/ConfirmationDialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {
  DefaultGuardianType,
  NewContractFormDefaults,
} from 'src/escolas/components/contract/create/types'
import { HotjarEvents, useHotjar } from 'src/shared/hooks/useHotjar'

const NAME_QUERY_PARAM = 'name'

const filterOptions: Array<TableFilterOptions> = [
  {
    filterName: 'status',
    filterTitle: 'Status do contrato',
    labelOptions: ContractStatusLabel,
  },
]

export type EventHandlers = {
  filterEventHandler: () => void
  searchEventHandler: () => void
}

const Page = () => {
  const { api } = useApi()
  const { query, setOnQueryParam } = useQuery()
  const { sendHotjarEvent } = useHotjar()
  const [name, setName] = useState<string>(query.get(NAME_QUERY_PARAM) || '')
  const { schoolId, schoolSlug, getNavigationUrl } = useNavigation()
  const history = useHistory<NewContractFormDefaults>()
  const { isAdmin } = useJWT()
  const [filters, setFilters] = useState<ContractFilters>({
    name: { value: name },
    status: isAdmin ? null : { label: ContractStatusLabel.OPEN, value: ContractStatus.OPEN },
  })
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [
    isContractCreationBlockedDialogOpen,
    setIsContractCreationBlockedDialogOpen,
  ] = useState<boolean>(false)
  const [isIP, setIsIP] = useState<boolean>(false)
  const [loadingIPData, setLoadingIPData] = useState<boolean>(true)

  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleOpenContractCreationBlockedDialog = () => {
    setIsContractCreationBlockedDialogOpen(true)
  }

  const handleCloseContractCreationBlockedDialog = () => {
    setIsContractCreationBlockedDialogOpen(false)
  }

  const handleRowClick = (contract_id: any) => {
    return !contract_id || contract_id === '00000000-0000-0000-0000-000000000000'
      ? handleOpenDialog()
      : history.push(getNavigationUrl({ path: `/contratos/2021/${contract_id}` }))
  }

  const eventHandlers = {
    searchEventHandler: (): null => null,
    filterEventHandler: (): null => null,
  }

  const addContract = (
    referral: string,
    studentName: string,
    guardian: DefaultGuardianType,
    referenceYear: string,
    previousYearContractID: uuid
  ) => {
    const defaultAddress = {
      additional_information: '',
      city: '',
      number: '',
      state_code: '',
      street: '',
      zip: '',
    }

    sendHotjarEvent(HotjarEvents.ADD_CONTRACT)

    if (!isIP && referenceYear === '2021') {
      return handleOpenContractCreationBlockedDialog()
    }

    history.push(getNavigationUrl({ path: `/contratos/${referenceYear}/novo` }), {
      guardian: {
        ...guardian,
        address: guardian?.address
          ? isIP
            ? guardian.address
            : formatGuardianAddress(guardian.address)
          : defaultAddress,
      },
      previousYearContractID,
      referenceYear,
      student: {
        name: studentName,
        referral,
        guardian_id: guardian?.id,
      },
    })
  }

  const search = () => {
    if (name === filters.name.value) {
      return
    }
    setFilters(assoc('name', { value: name }, filters))

    setOnQueryParam(name, NAME_QUERY_PARAM)
  }

  const is_ip_query = async () =>
    await api.contracts
      .fetchByStudent({
        schoolId,
        referenceYear: '2021',
        filter: {
          contractStatus: [ContractStatus.OPEN],
        },
        pagination: {
          per_page: 1,
        },
      })
      .then(({ data }) => data.length && setIsIP(true))
      .finally(() => {
        setLoadingIPData(false)
      })

  useEffect(() => {
    setLoadingIPData(true)
    is_ip_query()
  }, [])

  const dialogContent =
    'Esta escola não opera pelo isaac pagamentos. Para adicionar um contrato 2021, utilize o ERP da escola.'

  const PageMainContent = () => {
    return isIP ? (
      <ContractsYear
        schoolId={schoolId}
        handleRowClick={handleRowClick}
        filters={filters}
        filterOptions={filterOptions}
        name={name}
        setName={setName}
        setFilters={setFilters}
        search={search}
      />
    ) : (
      <Contracts2021
        isAdmin={isAdmin}
        schoolId={schoolId}
        schoolSlug={schoolSlug}
        handleRowClick={handleRowClick}
        filters={filters}
        filterOptions={filterOptions}
        addContract={addContract}
        name={name}
        setName={setName}
        setFilters={setFilters}
        search={search}
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
        eventHandlers={eventHandlers}
        hideCreateContractButton
      />
    )
  }

  return (
    <>
      {loadingIPData ? (
        <Box
          top={300}
          left={100}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ConfirmationDialog
            isVisible={isContractCreationBlockedDialogOpen}
            onClose={handleCloseContractCreationBlockedDialog}
            submitHandler={handleCloseContractCreationBlockedDialog}
            title="Não é possível adicionar um contrato 2021"
            buttonLabel="Ok, entendi"
          >
            <Typography variant="body2" color="textSecondary">
              {dialogContent}
            </Typography>
          </ConfirmationDialog>
          {PageMainContent()}
        </>
      )}
    </>
  )
}

const getPathname = ({ path }: PageRouteProps) => `${path}`
const buildBreadcrumbs = ({ breadcrumbs }: PageRouteProps) => breadcrumbs
const PageRoute = buildRoute(Page, getPathname, buildBreadcrumbs)
export default PageRoute
