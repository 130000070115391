import { NetworkMode } from '@tanstack/react-query'

export const REALMS = {
  ADMIN_REALM: 'backoffice',
  SCHOOL_REALM: 'isaac',
  GUARDIAN_REALM: 'isaac',
  MULTITENACY_REALM: 'isaac',
} as const

export const AZP = {
  SCHOOL_AZP: 'plataforma',
  ADMIN_AZP: 'backoffice',
  GUARDIAN_AZP: 'dashboard',
} as const

export const ROLES = {
  PAYOUT_REPORT_ACCESS: 'payout_report_access',
  PROFILE_MANTAINER: 'profile_mantainer',
  PROFILE_SECRETARY: 'profile_secretary',
  GROUP_MANAGER: 'visualizar_consolidado_financeiro',
} as const

export const ROLES_ISAAC = {
  [ROLES.PROFILE_MANTAINER]: 'Administração',
  [ROLES.PROFILE_SECRETARY]: 'Operação',
  [ROLES.GROUP_MANAGER]: 'Administração de grupo escolar',
} as const

export const FILES = {
  zip: {
    extension: 'zip',
    contentType: 'application/zip',
  },
  pdf: {
    extension: 'pdf',
    contentType: 'application/pdf',
  },
  xlsx: {
    extension: 'xlsx',
    contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
}

export const EVENT_GROUPS = {
  GUARDIANS_GROUP: 'guardians',
  SCHOOLS_GROUP: 'schools',
}

export const EVENT_CATEGORIES = {
  ENROLLMENT_CATEGORY: 'enrollment',
  GENERAL_CATEGORY: 'general',
  INVOICE_CATEGORY: 'invoice',
  REPORT_CATEGORY: 'report',
  STATEMENT_CATEGORY: 'statement',
  CAMPAIGN_CATEGORY: 'campaign',
  CHECKOUT_CATEGORY: 'checkout',
  MANUAL_LIQUIDATION_CATEGORY: 'manual-liquidation',
}

const networkMode: NetworkMode = 'offlineFirst'

export const SOFTCACHE_OPTIONS_BASE = {
  networkMode: networkMode,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: false,
  retry: false,
  staleTime: 10 * 60 * 1000,
}
