import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks/useApi'

import { queryClient } from '@/modules/app/contexts/GlobalProviders/ReactQuery'
import { userAccessService } from '@/modules/access-management/services/user-access'

import type {
  FetchUsersRequest,
  FetchUsersResponse,
  UpdateUserAccessRequest,
  UpdateUserAccessResponse,
  CheckCreateUserAccessRequest,
  CheckCreateUserAccessResponse,
  CreateUserAccessResponse,
  CreateUserAccessRequest,
} from '@/modules/access-management/services/user-access/types'

export const useUserAccess = (
  { schoolId, sortBy, sortOrder }: FetchUsersRequest,
  options?: UseQueryOptions<FetchUsersResponse>
) => {
  const { apiClient } = useApi()

  const service = userAccessService(apiClient.getClients().bffApi)

  return useQuery<FetchUsersResponse>(
    ['access-management', 'users', schoolId, JSON.stringify({ sortBy, sortOrder })],
    async () => {
      const response = await service.fetchUsers({
        schoolId,
        sortBy,
        sortOrder,
      })

      return response
    },
    options
  )
}

export const useMutateOnCreateUserAccess = (
  options?: UseMutationOptions<CreateUserAccessResponse, unknown, CreateUserAccessRequest, unknown>
) => {
  const { apiClient } = useApi()

  const service = userAccessService(apiClient.getClients().bffApi)

  return useMutation(service.createUserAccess, options)
}

export const useMutateOnRemoveAccess = () => {
  const { apiClient } = useApi()

  const service = userAccessService(apiClient.getClients().bffApi)

  return useMutation(service.removeUserAccess, {
    onSuccess: response => {
      queryClient.invalidateQueries(['access-management', 'users'])

      return response
    },
  })
}

export const useMutateOnUpdateUserAccess = (
  options?: UseMutationOptions<UpdateUserAccessResponse, unknown, UpdateUserAccessRequest, unknown>
) => {
  const { apiClient } = useApi()

  const service = userAccessService(apiClient.getClients().bffApi)

  return useMutation(service.updateUserAccess, options)
}

export const useCheckCreateUserAccess = (
  { schoolId, email }: CheckCreateUserAccessRequest,
  options?: UseQueryOptions<CheckCreateUserAccessResponse>
) => {
  const { apiClient } = useApi()

  const service = userAccessService(apiClient.getClients().bffApi)

  return useQuery<CheckCreateUserAccessResponse>(
    ['access-management', 'users', schoolId, email],
    async () => {
      const response = await service.checkCreateUserAccess({
        schoolId,
        email,
      })

      return response
    },
    {
      retry: 2,
      staleTime: 0,
      ...options,
    }
  )
}
