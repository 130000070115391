import { spacing } from '@gravity/tokens'
import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  margin-top: ${spacing[4]};
  margin-bottom: ${spacing[8]};
`
