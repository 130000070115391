import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'

export const StyledTypography = styled(Typography).attrs({ withoutMargin: true })`
  overflow-wrap: break-word;
`
export const StyledBodyLargeTypography = styled(StyledTypography).attrs({
  variation: 'bodyLarge',
})``

export const ConfirmationHeader = styled(Box)`
  margin-bottom: 24px;
  margin-top: 24px;
`
export const GuardianFormTitle = styled(StyledTypography)`
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #414141;
  margin-bottom: 4px;
`
export const GuardianFormSubtitle = styled(StyledTypography)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #6d6d6d;
  margin-bottom: 24px;
`
