import { MoreVert } from '@material-ui/icons'
import { IconButton } from '@olaisaac/design-system'
import { MoreOptionsProps } from './types'

export const MoreOptions: React.FC<MoreOptionsProps> = ({ contract, handleClick }) => {
  return (
    <IconButton variation="ghost" onClick={e => handleClick(e, contract)}>
      <MoreVert />
    </IconButton>
  )
}
