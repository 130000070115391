import { useRouteMatch } from 'react-router-dom'

import { useJWT } from '@/shared/hooks/useJWT'

import { routes } from './routes'

import type { ModuleDefinition } from '@/shared/core/module'

export const useRegisterReportModule: ModuleDefinition = module => {
  const { path: basePath } = useRouteMatch()
  const { hasPayoutReportAccess } = useJWT()

  module.registerRoutes(hasPayoutReportAccess ? routes : [], { basePath })
}
