export const countSelectedFilters = <T extends unknown>(data: Record<string, T | T[]>) => {
  const count = Object.entries(data).reduce((acc, [, value]) => {
    if (!value) return acc

    if (!Array.isArray(value)) {
      return acc + 1
    }

    return acc + value.length
  }, 0)

  return count
}
