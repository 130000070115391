import { rest } from 'msw'

import config from '@/config'
import schoolsGroupMock from './schoolGroupMock'

const bffApi = path => {
  return new URL(`/${path}`, config.BFF_API_URL).toString()
}

const userSchoolsGroup = rest.get(bffApi('school-groups/schools'), (req, res, ctx) =>
  res(ctx.json(schoolsGroupMock()))
)

export const bffApiMocks = [userSchoolsGroup]
