import { Box } from '@material-ui/core'
import { AddRounded } from '@material-ui/icons'
import styled from 'styled-components'

export const WrapperList = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const WrapperIcon = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledAddIcon = styled(AddRounded)`
  background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[3]};
  border: 15px solid ${({ theme }) => theme.primitiveTokens.colors.gray[3]};
  border-radius: 50%;
  margin-top: -21px;
  margin-bottom: -21px;
  box-sizing: initial;
`
