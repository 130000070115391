import { ReactNode, useEffect, useState } from 'react'
import { Box, Collapse } from '@material-ui/core'
import { ExpandLess, ExpandMore, SvgIconComponent } from '@material-ui/icons'
import { SystemButton, Typography } from '@olaisaac/design-system'

import { formatCurrencyValue, formatNumberMicroCentsToReal } from '@/shared/utils/numberFormatters'

import theme from '@/shared/theme'

import { ItemText } from './styles'

type PayoutTransfersSectionRootProps = {
  children?: ReactNode
  description: string
  emptySectionMessage: string
  icon: SvgIconComponent
  iconColor: string
  isEmpty?: boolean
  label: string
  startOpened?: boolean
  totalAmount: number
  totalLabel: string
}

const BORDER_COLOR = theme.primitiveTokens.colors.gray[10]

export const PayoutTransfersSectionRoot = ({
  icon: Icon,
  iconColor,
  totalLabel,
  label,
  description,
  totalAmount,
  startOpened = false,
  children,
  isEmpty,
  emptySectionMessage,
}: PayoutTransfersSectionRootProps) => {
  const [isOpen, setIsOpen] = useState(() => startOpened)

  useEffect(() => setIsOpen(startOpened), [startOpened])

  const formatAmountValue = (amount: number) => {
    const decimalAmount = formatNumberMicroCentsToReal(amount)

    return formatCurrencyValue(decimalAmount)
  }

  return (
    <Box p="2rem 1.5rem" borderBottom={`1px solid ${BORDER_COLOR}`}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Icon style={{ fontSize: '1.5rem', color: iconColor }} data-testid="icon-element" />

          <Box ml="0.5rem">
            <Typography variation="subtitleDesktopLarge" withoutMargin data-testid="section-title">
              {label}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <SystemButton
            onClick={() => setIsOpen(prevState => !prevState)}
            data-testid="expand-button"
          >
            {isOpen ? (
              <ExpandLess fontSize="small" data-testid="expand-less-icon" />
            ) : (
              <ExpandMore fontSize="small" data-testid="expand-more-icon" />
            )}
          </SystemButton>
        </Box>
      </Box>

      <Collapse in={isOpen}>
        <Box pt="0.5rem">
          <Typography variation="bodySmall" color="secondary" data-testid="description-element">
            {description}
          </Typography>
        </Box>

        {isEmpty && (
          <Box pt="1rem">
            <Typography variation="bodySmall" color="secondary" data-testid="description-element">
              {emptySectionMessage}
            </Typography>
          </Box>
        )}

        {children}
      </Collapse>

      <Box display="flex" justifyContent="space-between" mt="1rem">
        <ItemText>{totalLabel}</ItemText>

        <ItemText>{formatAmountValue(totalAmount)}</ItemText>
      </Box>
    </Box>
  )
}
