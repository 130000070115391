import styled, { css } from 'styled-components'

import theme from '@/shared/theme'

const BORDER_COLOR = theme.primitiveTokens.colors.gray[5]

export const Container = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  height: 96px;
  border-bottom: 1px solid ${BORDER_COLOR};
  z-index: 2;

  .select-container {
    width: 20rem;
    margin-left: ${props => props.theme.gravity.spacing[6]};
  }

  .controllers-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: ${props => props.theme.gravity.spacing[4]};
  }
`

type NotificationContainerProps = {
  hideDivider?: boolean
  iconSize?: number
}

const BUTTON_SIZE = theme.primitiveTokens.spacing[10]
const BUTTON_BACKGROUND = theme.primitiveTokens.colors.gray[0]
const BUTTON_HOVER_BACKGROUND = theme.primitiveTokens.colors.gray[50]
const NOTIFICATION_CONTAINER_BORDER_COLOR = theme.primitiveTokens.colors.gray[10]

export const NotificationContainer = styled.div<NotificationContainerProps>`
  display: flex;
  border-right: 1px solid ${NOTIFICATION_CONTAINER_BORDER_COLOR};

  ${props =>
    props.hideDivider &&
    css`
      border-right: 0;
    `}

  button.--ghost {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: ${BUTTON_SIZE};
    min-width: ${BUTTON_SIZE};
    padding: 0;

    svg {
      width: 24px;

      ${props =>
        props.iconSize &&
        css`
          width: ${props.iconSize}px;
        `}
    }

    &:hover {
      background: ${BUTTON_BACKGROUND};
    }

    &:not(:hover) svg {
      fill: ${BUTTON_HOVER_BACKGROUND};
    }
  }
`
