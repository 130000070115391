export enum REPORT_NAME {
  'campanha-de-rematricula' = 'campanha-de-rematricula',
  'regularizacao-das-familias' = 'regularizacao-das-familias',
  'visao-de-recebimentos' = 'visao-de-recebimentos',
  'visao-de-vendas-do-material-didatico' = 'visao-de-vendas-do-material-didatico',
}

export const TRACKED_REPORTS_WITH_PAGE_VIEW = {
  'Relatório de regularização das famílias': `/:schoolSlug/relatorio/${REPORT_NAME['regularizacao-das-familias']}`,
}
