import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { dissoc, drop, map, propEq, times } from 'ramda'
import dayjs from 'dayjs'
import { Typography, Button, TextField } from '@olaisaac/design-system'
import AddIcon from '@material-ui/icons/Add'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { Discount } from '@/shared/interfaces'
import { useContract, useNavigation, usePagedDrawer } from '@/escolas/hooks'
import { UnleashFlags, useApi, useJWT, useUnleashFlag, useSnackbar } from '@/shared/hooks'
import { date2PTFormat, formatCentsToReal, formatDate, validateEditReason } from '@/shared/utils'
import PagedDrawer from '@/escolas/components/PagedDrawer'
import { useEditDiscountsInfo } from '@/escolas/hooks/useEditDiscountsInfo'
import { DEFAULT_DISCOUNTS_OPTIONS } from '@/shared/components/DiscountsListForm/components/DiscountFormFields/constants'
import { EditGuardianFormSection } from '@/escolas/router/responsavel/[guardianId]/contratos/constants'

import { InstallmentChange } from './ContractEditDueDayDrawer'
import { AddDiscountFormType } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/AddDiscountContent'
import DiscountForm from '../../DiscountForm'
import { EditAvailabilityWarning } from './components/EditAvailabilityWarning/EditAvailabilityWarning'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

const UpdateZipCodeBtn = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3d4ed7;
  cursor: pointer;
`

const StyledBodyLargeTypography = styled(Typography).attrs({
  variation: 'bodyLarge',
  withoutMargin: true,
})``

export type ContractEditDiscountsForm = {
  discounts: AddDiscountFormType[]
  edit_reason?: string
  installment_id?: uuid
}

export type ContractEditDiscountsDrawerProps = {
  availableInstallments: InstallmentChange[]
  callbackEdits?: Dispatch<SetStateAction<boolean>>
  isContractInFlexiblePeriod?: boolean
  isOpen: boolean
  onClose: () => void
}

const ContractEditDiscountsDrawer = ({
  availableInstallments,
  isContractInFlexiblePeriod,
  isOpen,
  onClose,
  callbackEdits,
}: ContractEditDiscountsDrawerProps) => {
  const { api } = useApi()
  const { contract } = useContract()
  const history = useHistory()
  const params = new URLSearchParams()
  const form = useForm<ContractEditDiscountsForm>({
    mode: 'onChange',
    defaultValues: {
      installment_id: '',
      edit_reason: '',
    },
  })
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    trigger,
    formState: { isSubmitting, isValid, isDirty, touchedFields },
    setValue,
  } = form

  watch(['installment_id', 'discounts', 'edit_reason'])
  const { installment_id, discounts, edit_reason } = getValues()

  const { currentPage, setCurrentPage } = usePagedDrawer()
  const { setContract } = useContract()
  const { schoolId } = useNavigation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const prevDiscountsLength = useRef(0)
  const [discountsToAppendQueue, setDiscountsToAppendQueue] = useState<Discount[]>([])
  const [appendEmptyDiscount, setAppendEmptyDiscount] = useState(false)

  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()

  const { fields: discountFields, append, remove } = useFieldArray({
    control,
    name: 'discounts',
  })

  const { isAdmin } = useJWT()

  const isNewContractEditRulesEnabled =
    useUnleashFlag(UnleashFlags.ENABLE_NEW_RULES_FOR_SCHOOL_CONTRACT_EDITS) && !isAdmin

  const shouldShowSelectInstallmentsStep = availableInstallments?.length > 1

  useEffect(() => {
    if (!shouldShowSelectInstallmentsStep) {
      setValue('installment_id', availableInstallments?.[0]?.id)
    }
  }, [availableInstallments])

  const submitHandler = async () => {
    return await api.contracts
      .bulkEditDiscountsCreate(
        contract?.id,
        {
          installment_id,
          edit_reason,
          discounts: map<AddDiscountFormType, Discount>(dissoc('isFinished'), discounts),
        },
        schoolId
      )
      .then(contractData => {
        setContract(contractData)
        setSnackbarMessage('Descontos alterados com sucesso')
        setSnackbarIsOpen(true)
        onClose()
        if (callbackEdits) {
          callbackEdits(true)
        }
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          if (isInitialized) {
            eventDispatcherClient.sendEvent({
              scope: EventDispatcherEventScopes.DUE_DATE_DRAWER,
              name: EventDispatcherEvents.MODAL_VIEWED,
              action: 'component_view',
              customProperties: {
                $button_name: 'Confirmar',
                $modal_name: 'Mensagem de erro edição desconto',
              },
            })
          }
          onClose()
          if (status === 422 && errors[0].message === 'Zip Code error') {
            setSnackbarIsOpen(true, {
              variation: 'error',
              title: 'Atualize o CEP do responsável',
              description:
                'Não foi possível alterar o desconto, pois identificamos que o CEP no cadastro está desatualizado',
              link: (
                <UpdateZipCodeBtn
                  onClick={() => {
                    params.append('abrirCadastro', 'true')
                    params.append(EditGuardianFormSection.ADDRESS_SECTION, 'true')
                    history.push({ search: params.toString() })
                  }}
                >
                  Atualizar CEP
                </UpdateZipCodeBtn>
              ),
            })
          } else {
            setSnackbarIsOpen(true, {
              variation: 'error',
              title: 'Não foi possível alterar os descontos',
              description:
                'Tente repetir a operação novamente, ou entre em contato com o nosso time de atendimento',
            })
          }
        }
      )
  }

  const {
    installmentsToEditDiscounts: installmentChanges,
    isFetching,
    refetch,
  } = useEditDiscountsInfo(
    {
      contractId: contract?.id,
      installment_id,
      discounts: discounts
        ? map<AddDiscountFormType, Discount>(dissoc('isFinished'), discounts)
        : [],
    },
    false
  )

  const getInfo = async () => {
    await refetch()
    setCurrentPage(currentPage + 1)
  }

  const selectedInstallment = availableInstallments?.find(propEq('id', installment_id))
  const orderReference = selectedInstallment?.orderReference
  const receivable = selectedInstallment?.receivable

  const validateSelectInstallmentStep = () => {
    return !installment_id || !validateEditReason(edit_reason)
  }

  let isNextPageDisabled

  const quantityOfDiscountsChanged = prevDiscountsLength.current !== discounts?.length
  const hasNotChanged = Object.keys(touchedFields).length === 0 && !quantityOfDiscountsChanged

  if (currentPage === 0) {
    isNextPageDisabled = shouldShowSelectInstallmentsStep
      ? validateSelectInstallmentStep()
      : !installment_id || !isValid || !isDirty
  } else if (currentPage === 1) {
    isNextPageDisabled = !isValid || !isDirty || hasNotChanged
  }

  const removeDiscount = (index: number) => remove(index)

  const appendDiscount = (discount: Discount & Partial<AddDiscountFormType>) => {
    append({
      amount: discount?.amount,
      days_before_due_date: discount?.days_before_due_date,
      label_days_before_due_date: DEFAULT_DISCOUNTS_OPTIONS.find(
        option => option.value === discount?.days_before_due_date
      )?.name,
      description: discount?.description,
      isFinished: discount?.isFinished || false,
    })
  }

  const appendNewDiscount = () => {
    appendDiscount({
      amount: null,
      days_before_due_date: null,
      description: '',
    })
  }

  useEffect(() => {
    const allDiscounts = discountFields?.map((value, index) => index)
    remove(allDiscounts)
    if (!receivable?.discounts) return

    if (receivable?.discounts?.length === 0) return setAppendEmptyDiscount(true)

    setDiscountsToAppendQueue(receivable?.discounts)
    prevDiscountsLength.current = receivable?.discounts?.length
  }, [receivable?.discounts])

  useEffect(() => {
    if (!discountsToAppendQueue.length) {
      times(index => trigger(`discounts.${index}.amount`), discountFields.length)
      return
    }

    appendDiscount({ ...discountsToAppendQueue[0], isFinished: true })
    setDiscountsToAppendQueue(drop(1, discountsToAppendQueue))
  }, [discountsToAppendQueue])

  useEffect(() => {
    if (!appendEmptyDiscount) return

    appendNewDiscount()
    setAppendEmptyDiscount(false)
  }, [appendEmptyDiscount])

  const page2 = (
    <React.Fragment key={1}>
      <EditAvailabilityWarning
        isContractInFlexiblePeriod={isContractInFlexiblePeriod}
        isNewContractEditRulesEnabled={isNewContractEditRulesEnabled}
      />
      <Box mt={3} mb={2.5}>
        <Typography variation="subtitleDesktopLarge">
          Selecione a partir de qual parcela os descontos serão editados:
        </Typography>
      </Box>
      <Box mb={2.5}>
        <Typography color="secondary">
          {isContractInFlexiblePeriod
            ? 'Aplicável a parcelas vencidas ou com vencimento no mês atual.'
            : 'Não se aplica a parcelas vencidas, negociadas ou com vencimento no mês atual.'}
        </Typography>
      </Box>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="installment_id">Número da parcela</InputLabel>
        <Controller
          rules={{ required: !!shouldShowSelectInstallmentsStep }}
          control={control}
          name="installment_id"
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <Select
              labelId="installment_id"
              label="Número da parcela"
              value={value}
              onChange={e => onChange(e.target.value)}
              fullWidth
            >
              {availableInstallments?.map(({ id, index, current_amount, due_month }) => (
                <MenuItem key={id} value={id}>
                  {`${index + 1}. ${formatDate(due_month, 'MMM YYYY')} - ${formatCentsToReal(
                    current_amount
                  )}`}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      {installment_id && (
        <Box mt={4}>
          <StyledBodyLargeTypography>
            A edição será feita a partir da seguinte parcela:
          </StyledBodyLargeTypography>
          <Box mt={2.5} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">Parcela</StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {orderReference}
            </StyledBodyLargeTypography>
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">
              Valor da parcela
            </StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {formatCentsToReal(receivable?.current_amount)}
            </StyledBodyLargeTypography>
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">Vencimento</StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {date2PTFormat(dayjs(receivable?.due_date).toISOString())}
            </StyledBodyLargeTypography>
          </Box>
        </Box>
      )}
      <>
        <Box mt={4}>
          <Typography variation="subtitleDesktopLarge">
            Qual é o motivo da edição de desconto?
          </Typography>
        </Box>
        <Box mt={2}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{
                required: !!shouldShowSelectInstallmentsStep,
                maxLength: 100,
                minLength: 5,
                validate: shouldShowSelectInstallmentsStep && validateEditReason,
              }}
              name="edit_reason"
              control={control}
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  label="Motivo"
                  id="edit_reason-input"
                  value={value}
                  error={Boolean(error)}
                  helperText={
                    error ? 'Deve ter entre 5 e 100 caracteres (somente letras e números)' : ''
                  }
                />
              )}
            />
          </FormControl>
        </Box>
      </>
    </React.Fragment>
  )

  const page3 = (
    <React.Fragment key={2}>
      <EditAvailabilityWarning
        isContractInFlexiblePeriod={isContractInFlexiblePeriod}
        isNewContractEditRulesEnabled={isNewContractEditRulesEnabled}
      />
      <Box mt={2} mb={2}>
        <Typography variation="subtitleDesktopLarge">
          São permitidos até 3 descontos por parcela:
        </Typography>
      </Box>

      {discountFields?.map((data, index) => (
        <DiscountForm
          key={data.id}
          idPrefix="tuition"
          data={data}
          index={index}
          form={form}
          totalAmount={receivable?.original_amount}
          removeDiscount={removeDiscount}
        />
      ))}

      <Button
        variation="ghost"
        startIcon={<AddIcon />}
        fullWidth
        onClick={appendNewDiscount}
        disabled={discountFields.length >= 3}
      >
        Novo desconto
      </Button>
    </React.Fragment>
  )
  const page4 = (
    <React.Fragment key={3}>
      <Box mt={3} mb={3}>
        <Typography variation="subtitleDesktopLarge">
          Revise as alterações de descontos, antes de confirmar:
        </Typography>
      </Box>
      {installmentChanges?.map(({ orderReference, newValue, originalValue }, index) => (
        <Box key={index}>
          <Box mt={3} display="flex" justifyContent="space-between">
            <Typography variation="subtitleDesktopLarge" style={{ fontWeight: 700 }}>
              Parcela
            </Typography>
            <Typography variation="subtitleDesktopLarge" style={{ fontWeight: 700 }}>
              {orderReference}
            </Typography>
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between">
            <Typography color="secondary">Valor anterior</Typography>
            <Typography color="secondary">{formatCentsToReal(originalValue)}</Typography>
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between">
            <Typography color="secondary">Novo valor</Typography>
            <Typography color="secondary">{formatCentsToReal(newValue)}</Typography>
          </Box>
        </Box>
      ))}
    </React.Fragment>
  )

  const pages: React.ReactFragment[] = shouldShowSelectInstallmentsStep
    ? [page2, page3, page4]
    : [page3, page4]

  const defaultHandleClickMap = {
    2: handleSubmit(submitHandler),
    1: getInfo,
  }

  const uniqueInstallmentHandleClickMap = {
    1: handleSubmit(submitHandler),
    0: getInfo,
  }

  return (
    <>
      <PagedDrawer
        isLoading={isFetching || isSubmitting}
        isNextPageDisabled={isNextPageDisabled}
        pages={pages}
        handleClickMap={
          shouldShowSelectInstallmentsStep ? defaultHandleClickMap : uniqueInstallmentHandleClickMap
        }
        isOpen={isOpen}
        onClose={() => {
          onClose()
          reset()
        }}
        title="Editar descontos"
      />
    </>
  )
}

export default ContractEditDiscountsDrawer
