import { Table } from '@gravity/table'

export const Loading = () => (
  <>
    {Array.from(Array(4), (_, i) => i).map(row => (
      <Table.Row key={row}>
        <Table.LoadingCell />
        <Table.LoadingCell />
        <Table.LoadingCell />
      </Table.Row>
    ))}
  </>
)
