import React, { useRef, useState } from 'react'
import { Root, Trigger, Portal } from '@radix-ui/react-popover'
import { debounce } from 'throttle-debounce'
import { Link } from '@gravity/link'
import { Text } from '@gravity/text'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useSearch } from '@/modules/school-home/hooks/queries/school-home-search'

import { InputSearch } from './components/InputSearch'
import { ResultStoredSection } from './components/ResultStoredSection'
import { ResultSection } from './components/ResultSection'
import { ResultSectionPlaceholder } from './components/ResultSectionPlaceholder'
import { FiltersSearch } from './components/FiltersSearch'
import { Container, Content, HeaderTitle, ResultSearch } from './styles'

import { convertSearchTypeToArray } from '../../utils/convertSearchTypeToArray'

import { SEARCH_TYPES } from '@/modules/school-home/constants/SEARCH_TYPES'
import {
  MAX_GUARDIAN_SEARCH_ITEMS_TO_DISPLAY,
  MAX_SEARCH_ITEMS_IN_STORAGE,
  MAX_STUDENT_SEARCH_ITEMS_TO_DISPLAY,
} from '@/modules/school-home/constants/MAX_SEARCH_ITEMS'
import { KEY_SEARCH_RESULT_LOCALSTORAGE } from '@/modules/school-home/constants/KEY_SEARCH_RESULT_LOCALSTORAGE'
import type { StorageItemType } from '@/modules/school-home/types/StorageItem'
import type { SearchType } from '@/modules/school-home/types/SearchType'
import type {
  FetchSearchResponse,
  GuardianItem,
  StudentItem,
} from '@/modules/school-home/services/school-home-search/types'

export const HomeSearch = () => {
  const { school } = useSelectedSchool()
  const [openResults, setOpenResults] = useState(false)
  const [inputSearch, setInputSearch] = useState('')
  const [inputWithDebounce, setInputWithDebounce] = useState('')
  const [storedSearch, setStoredSearch] = useState<StorageItemType[]>([])
  const [selectedFilter, setSelectedFilter] = useState<SearchType>(SEARCH_TYPES.ALL)

  const isEmptySearch = storedSearch.length === 0

  const inputRef = useRef<HTMLInputElement>(null)
  const schoolId = school?.id ?? ''

  const { data, isFetching } = useSearch(
    {
      schoolId,
      search: inputWithDebounce,
      type: convertSearchTypeToArray(selectedFilter),
    },
    {
      enabled: !!schoolId && !!inputWithDebounce,
    }
  )

  const setInputDebounce = debounce(300, input => setInputWithDebounce(input))

  const remainingSearchDataToRender = (() => {
    if ((!data?.students?.results.length && !data?.guardians?.results.length) || !inputSearch)
      return undefined

    const searchData: FetchSearchResponse = {
      students: undefined,
      guardians: undefined,
    }

    const maxStudentsItems =
      selectedFilter === SEARCH_TYPES.ALL
        ? MAX_STUDENT_SEARCH_ITEMS_TO_DISPLAY
        : MAX_SEARCH_ITEMS_IN_STORAGE

    const maxGuardianItems =
      selectedFilter === SEARCH_TYPES.ALL
        ? MAX_GUARDIAN_SEARCH_ITEMS_TO_DISPLAY
        : MAX_SEARCH_ITEMS_IN_STORAGE

    if (data?.students) {
      searchData.students = {
        ...data.students,
        results: data.students.results.slice(0, maxStudentsItems) as StudentItem[],
      }
    }

    if (data?.guardians) {
      searchData.guardians = {
        ...data.guardians,
        results: data.guardians.results.slice(0, maxGuardianItems) as GuardianItem[],
      }
    }

    return searchData
  })()

  const isEmptyStudentsResultForSearch = !isFetching && !data?.students?.results?.length
  const isEmptyGuardiansResultForSearch = !isFetching && !data?.guardians?.results?.length

  const isEmptyResultForSearchStored = !isFetching && !storedSearch?.length

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Space' && openResults) {
      e.preventDefault()

      if (inputRef.current) {
        inputRef.current.focus()
        setInputSearch(props => props + ' ')
      }
    }
  }

  const getStorageSearches = (type?: SearchType, input?: string) => {
    const search = JSON.parse(localStorage.getItem(KEY_SEARCH_RESULT_LOCALSTORAGE) ?? '[]')

    const schoolSearches = Array.isArray(search?.[schoolId]) ? search[schoolId] : []

    if (!schoolSearches.length) return []

    if (type) {
      const inputLower = input?.toLowerCase()

      return schoolSearches.filter(
        (item: StorageItemType) =>
          item.type === type && (!inputLower || item.name.toLowerCase().includes(inputLower))
      )
    }

    return schoolSearches
  }

  const handleChangeFilter = (type: SearchType) => {
    setSelectedFilter(type)
  }

  const handleChangeInputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    let recentSearches = []

    if (!event.target.value) {
      recentSearches = getStorageSearches()
    }

    setStoredSearch(recentSearches)
    setInputSearch(event.target.value)
    setInputDebounce(event.target.value)
  }

  const handleOpenSearch = () => {
    if (!inputSearch) {
      const recentSearches = getStorageSearches()

      setStoredSearch(recentSearches)
    }
  }

  const handleOnClickInInput = (event: React.MouseEvent<HTMLElement>) => {
    if (openResults) {
      event.stopPropagation()
    }
  }

  const handleClearStorage = () => {
    localStorage.removeItem(KEY_SEARCH_RESULT_LOCALSTORAGE)

    setStoredSearch([])
  }

  const handleRemoveItem = (id: string) => {
    const search = JSON.parse(localStorage.getItem(KEY_SEARCH_RESULT_LOCALSTORAGE) ?? '[]')

    if (search[schoolId]) {
      const searchSchool = search[schoolId] as StorageItemType[]

      const storage = {
        ...search,
        [schoolId]: searchSchool.filter(search => search.id !== id),
      }

      localStorage.setItem(KEY_SEARCH_RESULT_LOCALSTORAGE, JSON.stringify(storage))
    }

    setStoredSearch(props => props.filter(search => search.id !== id))
  }

  const handleAddItemInStorage = (item: StorageItemType) => {
    const search = JSON.parse(localStorage.getItem(KEY_SEARCH_RESULT_LOCALSTORAGE) ?? '[]')

    if (!search || !search[schoolId]) {
      const storage = {
        ...search,
        [schoolId]: [item],
      }

      return localStorage.setItem(KEY_SEARCH_RESULT_LOCALSTORAGE, JSON.stringify(storage))
    }

    const searchSchool = search[schoolId] as StorageItemType[]
    const hasItem = searchSchool.find(storageItem => storageItem.id === item.id)

    if (!hasItem) {
      const storageItens =
        searchSchool.length < MAX_SEARCH_ITEMS_IN_STORAGE ? searchSchool : searchSchool.slice(1)

      const storage = {
        ...search,
        [schoolId]: [...storageItens, item],
      }

      localStorage.setItem(KEY_SEARCH_RESULT_LOCALSTORAGE, JSON.stringify(storage))
    }
  }

  const translationFilter = {
    guardians: 'responsáveis',
    students: 'alunos',
    all: ['alunos', 'responsáveis'],
  }

  return (
    <Container extend={openResults} tabIndex={0} data-testid="container-search">
      <Root
        open={openResults}
        onOpenChange={isOpen => {
          setOpenResults(isOpen)
        }}
      >
        <Trigger className="trigger">
          <InputSearch
            ref={inputRef}
            data-testid="input-search"
            value={inputSearch}
            onChange={handleChangeInputSearch}
            onKeyDown={handleKeyDown}
            onFocus={handleOpenSearch}
            onClick={handleOnClickInInput}
          />
        </Trigger>
        <Portal>
          <Content onOpenAutoFocus={e => e.preventDefault()} data-testid="content-search">
            <section data-testid="content-header">
              {!!inputSearch && (
                <>
                  <FiltersSearch
                    onSelectedFilter={handleChangeFilter}
                    selectedFilter={selectedFilter}
                  />
                </>
              )}

              {!inputSearch && !isFetching && (
                <HeaderTitle data-testid="header-title-when-input-empty">
                  <Text variant="caption-regular" className="caption-search">
                    Buscas recentes
                  </Text>

                  {!isEmptySearch && (
                    <Link
                      data-testid="clear-history"
                      size={1}
                      underline="none"
                      weight="light"
                      onClick={handleClearStorage}
                    >
                      Limpar histórico
                    </Link>
                  )}
                </HeaderTitle>
              )}
            </section>

            <ResultSearch data-testid="result-search">
              {!inputSearch.length ? (
                isFetching ? (
                  <ResultSectionPlaceholder />
                ) : (
                  <ResultStoredSection
                    inputSearch={inputSearch}
                    handleAddItemInStorage={handleAddItemInStorage}
                    handleRemoveItem={handleRemoveItem}
                    storedSearch={storedSearch}
                    isEmptyResultForSearchStored={isEmptyResultForSearchStored}
                  />
                )
              ) : null}

              {!!inputSearch.length && (
                <>
                  {selectedFilter === SEARCH_TYPES.ALL && (
                    <>
                      {isFetching ? (
                        <>
                          <ResultSectionPlaceholder items={3} />
                          <ResultSectionPlaceholder items={2} />
                        </>
                      ) : (
                        <>
                          <ResultSection
                            inputSearch={inputSearch}
                            isLoading={isFetching}
                            filterName={translationFilter[selectedFilter][0]}
                            isEmptyResultForSearch={isEmptyStudentsResultForSearch}
                            handleAddItemInStorage={handleAddItemInStorage}
                            results={remainingSearchDataToRender?.students?.results}
                            type="students"
                          />

                          <ResultSection
                            inputSearch={inputSearch}
                            isLoading={isFetching}
                            filterName={translationFilter[selectedFilter][1]}
                            isEmptyResultForSearch={isEmptyGuardiansResultForSearch}
                            handleAddItemInStorage={handleAddItemInStorage}
                            results={remainingSearchDataToRender?.guardians?.results}
                            type="guardians"
                          />
                        </>
                      )}
                    </>
                  )}

                  {selectedFilter === SEARCH_TYPES.STUDENTS && (
                    <>
                      {isFetching ? (
                        <ResultSectionPlaceholder items={6} />
                      ) : (
                        <ResultSection
                          inputSearch={inputSearch}
                          isLoading={isFetching}
                          filterName={translationFilter[selectedFilter]}
                          isEmptyResultForSearch={isEmptyStudentsResultForSearch}
                          handleAddItemInStorage={handleAddItemInStorage}
                          results={remainingSearchDataToRender?.students?.results}
                          type="students"
                        />
                      )}
                    </>
                  )}

                  {selectedFilter === SEARCH_TYPES.GUARDIANS && (
                    <>
                      {isFetching ? (
                        <ResultSectionPlaceholder items={6} />
                      ) : (
                        <ResultSection
                          inputSearch={inputSearch}
                          isLoading={isFetching}
                          filterName={translationFilter[selectedFilter]}
                          isEmptyResultForSearch={isEmptyGuardiansResultForSearch}
                          handleAddItemInStorage={handleAddItemInStorage}
                          results={remainingSearchDataToRender?.guardians?.results}
                          type="guardians"
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </ResultSearch>
          </Content>
        </Portal>
      </Root>
    </Container>
  )
}
