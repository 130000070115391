import styled from 'styled-components'
import { Text } from '@gravity/text'

import { colors, spacing } from '@gravity/tokens'

export const InstallmentDrawerHeaderTitleWrapper = styled.div`
  margin-top: 6px;
  margin-bottom: ${spacing[3]};
`

export const InstallmentDrawerHeaderInfo = styled(Text)`
  color: ${colors['colors-text-main-3']};
  font-size: 14px;
`
