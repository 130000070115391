import type { UseFormReturn } from 'react-hook-form'

export enum installmentDueDateRule {
  FIXED_DAY = 'FIXED_DAY',
  WORKING_DAY = 'WORKING_DAY',
}

export type Discount = {
  amount: number | null
  days_before_due_date: string | null
  description: string
  due_date_rule: string | null
}

export type FormProps = {
  custom_monthly_amount: number
  discounts: Discount[]
  due_date: null | Date
  due_date_month: null | Date
  due_day: number | null
  duration_months: string
  installment_due_date_rule: installmentDueDateRule | null
}

export type InstallmentFormProps = {
  form: UseFormReturn<FormProps>
  referenceYear: number
  renderDiscountBtn?: DiscountFieldsProps['renderDiscountBtn']
}

export type DiscountFieldsProps = {
  form: UseFormReturn<FormProps>
  renderDiscountBtn?: (disabled: boolean, onClick: () => void) => JSX.Element
}
