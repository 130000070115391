import { Chip as MUIChip, createStyles, makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import theme from '@/shared/theme'

import type { ChipProps } from './types'

export const { spacing, colors } = theme.primitiveTokens

const { color, variation } = theme.humanizedTokens.components.typography

export const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      maxWidth: 'none',
      background: '#616161E5',
    },
  })
)

export const StyledButton = styled.button`
  display: grid;
  grid-gap: ${spacing[2]};
  text-align: unset;
  cursor: pointer;
  color: ${color.primary.color};
  padding: ${spacing[4]};
  border: 1px solid ${colors.gray[10]};
  border-radius: 8px;
  background: ${colors.gray[0]};
  min-height: 121px;
  height: fit-content;

  &:hover:not(:disabled):not(:active):not(.active) {
    background: ${colors.gray[3]};
  }

  &:active:not(:disabled),
  &.active {
    border-color: ${colors.isaacBlue[30]};
    background: ${colors.isaacBlue[10]};
  }

  &:focus-visible:not(:disabled) {
    outline: ${colors.isaacBlue[30]} solid 2px;
    outline-offset: 1px;
  }

  &:disabled {
    cursor: default;
  }
`

export const Title = styled.span`
  font-family: ${variation.bodyLarge.fontFamily};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.15px;
`

export const Chip = styled(MUIChip)<ChipProps>`
  width: fit-content;
  border: 1px solid ${colors.isaacBlue[30]};

  .MuiChip-deleteIcon {
    color: ${colors.isaacBlue[50]};
  }
`

export const ChipLabel = styled.span`
  font-family: ${variation.bodyLarge.fontFamily};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.38462;
  letter-spacing: 0.16px;
`
