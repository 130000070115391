import { Table } from '@gravity/table'

import { COLUMN_HEADERS } from '../..'

export const Empty = () => (
  <Table.Row>
    <Table.TextCell colSpan={COLUMN_HEADERS.length} align="center">
      Nenhum usuário encontrado.
    </Table.TextCell>
  </Table.Row>
)
