import { useEffect, useState } from 'react'
import {
  Button,
  DialogContent,
  DialogActions,
  SystemButton,
  Typography,
  Notification,
} from '@olaisaac/design-system'
import Dialog from '@material-ui/core/Dialog'
import { CloseRounded } from '@material-ui/icons'
import { Box, Collapse } from '@material-ui/core'

import { StatusFeedbackCheckout } from '../FeedbackCheckout'
import * as Styled from '../styles'
import { useCheckoutFallbackVisibility } from './hooks/useCheckoutFallbackVisibility'
import { useEvents } from './hooks/useEvents'
import { NotificationCheckoutDialog } from '../NotificationCheckoutDialog'

interface Props {
  isVisible: boolean
  onClickOtherPaymentOptions?: () => void
  onClose: () => void
  optionMessages?: Array<React.ReactNode>
  shortIds?: string[]
}

export const OnHoldCheckoutDialog = ({
  shortIds,
  isVisible,
  onClose,
  optionMessages,
  onClickOtherPaymentOptions,
}: Props) => {
  const [isChecked, setIsChecked] = useState(false)

  const fallbackStatus = useCheckoutFallbackVisibility()

  const { sendEventCheckboxClick } = useEvents(fallbackStatus.visible)

  const valueStatus = StatusFeedbackCheckout.on_hold

  const handleCheckboxClick = () => {
    sendEventCheckboxClick()
    setIsChecked(prev => !prev)
  }

  useEffect(() => {
    if (isVisible) {
      const timeoutId = fallbackStatus.show(60)
      return () => clearTimeout(timeoutId)
    }

    fallbackStatus.hide()
  }, [isVisible])

  return (
    <Dialog open={isVisible} maxWidth="sm" fullWidth>
      <Styled.BoxCloseButton display="flex" justifyContent="flex-end">
        <SystemButton onClick={onClose} aria-label="Fechar">
          <CloseRounded />
        </SystemButton>
      </Styled.BoxCloseButton>

      <DialogContent>
        <Box display="flex" justifyContent="center">
          <Styled.Image
            withButton={StatusFeedbackCheckout.on_hold.showClose}
            src={valueStatus.image}
          />
        </Box>

        <Styled.Box display="flex" justifyContent="center" textAlign="center">
          <Typography style={{ padding: '16px 0' }} variation="headlineDesktopSmall">
            {valueStatus.content}
          </Typography>
        </Styled.Box>

        <Collapse in={fallbackStatus.visible}>
          <Box width="100%" paddingX={2} mt={2} mb={2}>
            <Notification
              variation="warning"
              title="A conexão com a maquininha está demorando mais do que o normal"
              description="Você pode tentar outras opções de pagamento."
            />

            <Styled.WarningBox display="flex" alignItems="flex-start" py={2} pl={3}>
              <Styled.Checkbox checked={isChecked} onChange={handleCheckboxClick} />
              <Styled.FallbackWarningText>
                Confirmo que não recebi o pagamento através da maquininha e quero ver outras opções
                de pagamento.
              </Styled.FallbackWarningText>
            </Styled.WarningBox>
          </Box>
        </Collapse>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          paddingX={2}
          marginBottom={2}
        >
          {optionMessages
            ? optionMessages.map((message, index) => (
                <Typography
                  variation="subtitleDesktopMedium"
                  color="secondary"
                  key={`option-message-${index}`}
                  withoutMargin
                >
                  {message}
                </Typography>
              ))
            : null}
        </Box>

        <NotificationCheckoutDialog shortIds={shortIds} />
      </DialogContent>

      {fallbackStatus.visible && (
        <DialogActions>
          <Button variation="primary" disabled={!isChecked} onClick={onClickOtherPaymentOptions}>
            Outras opções de pagamento
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
