import { Typography, Box, Link } from '@material-ui/core'
import { Contract } from 'src/shared/interfaces'
import { date2PTFormat, formatCPF } from 'src/shared/utils'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'

export type MainInformationProps = {
  contract: Contract
}

export const MainInformation = ({ contract }: MainInformationProps) => {
  const getContractListRoute = () => location.href.split('/').slice(0, -1).join('/')
  return (
    <>
      <Typography variant="overline" color="textSecondary">
        Aluno
      </Typography>
      <Box mb={1.5}>
        <Typography className="fs-mask" variant="h6" color="textPrimary">
          {prettifyFullName(contract?.student?.name)}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography display="inline" variant="caption">
          CPF:{' '}
        </Typography>
        <Typography className="fs-mask" display="inline" variant="body2">
          {formatCPF(contract?.guardian?.tax_id)}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography display="inline" variant="caption">
          Responsável:{' '}
        </Typography>
        <Link rel="noreferrer" href={`${getContractListRoute()}/?name=${contract?.guardian?.name}`}>
          <Typography className="fs-mask" display="inline" variant="body2">
            {prettifyFullName(contract?.guardian?.name)}
          </Typography>
        </Link>
      </Box>
      <Box mb={1}>
        <Typography display="block" variant="caption">
          Produto:{' '}
          <Typography display="inline" variant="body2">
            {contract?.product?.name}
          </Typography>
        </Typography>
      </Box>
      <Typography display="block" variant="caption">
        Contrato:{' '}
        <Typography display="inline" variant="body2">
          {date2PTFormat(contract?.start_month)}
        </Typography>
      </Typography>
    </>
  )
}

export default MainInformation
