import { Box, Grid } from '@material-ui/core'
import { ContainerCards } from '../../components/ContainerCards'
import { ResponsiveContainer } from './styles'
import { HeaderHome } from '../../components/HeaderHome'
import { HomeTemplateProps } from './types'

export const HomeTemplate = ({
  title,
  cardDescription,
  mainCardDescription,
  isEligible,
  cards,
  bottomInfo,
  onClickButton,
  orderLock,
  isInMaintenance,
}: HomeTemplateProps) => {
  return (
    <>
      <HeaderHome
        description={cardDescription}
        isEligible={isEligible}
        title={title}
        onClickButton={onClickButton}
        orderLock={orderLock}
        isInMaintenance={isInMaintenance}
      />
      <Box ml={8} mr={8} mt={6} mb={6}>
        <ResponsiveContainer>
          <ContainerCards cards={cards} title={mainCardDescription} />
          <Box mt={6}>
            <Grid container spacing={3}>
              {bottomInfo}
            </Grid>
          </Box>
        </ResponsiveContainer>
      </Box>
    </>
  )
}
