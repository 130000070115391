import { Error } from '../Error'
import { Loader } from '../Loader'

import * as Styled from './styles'

export const PageWrapper = ({ status, children }) => {
  if (status === 'error') return <Error />
  if (status === 'loading') return <Loader />

  return <Styled.Container>{children}</Styled.Container>
}
