import { InstallmentV2 } from '@/modules/guardians/services/types'
import { MixpanelEventsEnum, mixpanel } from '@/shared/integrations'
import { Receivable } from '@/shared/interfaces'
import dayjs from 'dayjs'
import { getIdFromUrn } from '../helper'

export const sendEvent = (
  receivableid,
  receivableStatus,
  event: MixpanelEventsEnum,
  properties?: Record<`$${string}`, any>
) => {
  mixpanel.trackEvent({
    name: event,
    properties: {
      ...properties,
      $receivableId: receivableid,
      $status: receivableStatus,
    },
  })
}
export const sendEditManualLiquidationEvents = (formValues, receivable: Receivable, status) => {
  sendEvent(receivable?.id, status, MixpanelEventsEnum.METHODS_INCOMING_EDIT_REASON_SAVED, {
    $editReason: formValues.edit_reason,
  })

  if (receivable?.payment_source !== formValues.source) {
    sendEvent(
      receivable?.id,
      status,
      MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_ACCOUNT_CHOSEN_SAVED,
      {
        $account: formValues.source,
        $oldAccount: receivable?.payment_source,
      }
    )
  }
  if (receivable?.payment_method !== formValues.payment_method) {
    sendEvent(
      receivable?.id,
      status,
      MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PAYOUT_METHOD_CHOSEN_SAVED,
      {
        $paymentMethod: formValues.payment_method,
        $oldPaymentMethod: receivable?.payment_method,
      }
    )
  }
  const newPaidDate = dayjs(formValues.paid_date)
  const diffTime = Math.abs(Date.now() - Number(newPaidDate))
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  if (!newPaidDate.isSame(dayjs(receivable?.paid_date))) {
    sendEvent(
      receivable?.id,
      status,
      MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_DATE_CHOSEN_SAVED,
      {
        $paidDate: newPaidDate.toString(),
        $oldPaidDate: receivable?.paid_date?.toString(),
        $differenceBetweenTodayAndPaidDate: diffDays - 1,
      }
    )
  }
}

export const sendEditManualLiquidationEventsByInstallment = (
  formValues,
  installment: InstallmentV2,
  status
) => {
  const receivableId = getIdFromUrn(installment?.urn)
  sendEvent(receivableId, status, MixpanelEventsEnum.METHODS_INCOMING_EDIT_REASON_SAVED, {
    $editReason: formValues.edit_reason,
  })

  if (installment?.paid_payment_source !== formValues.source) {
    sendEvent(
      receivableId,
      status,
      MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_ACCOUNT_CHOSEN_SAVED,
      {
        $account: formValues.source,
        $oldAccount: installment?.paid_payment_source,
      }
    )
  }
  if (installment?.paid_payment_method !== formValues.payment_method) {
    sendEvent(
      receivableId,
      status,
      MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PAYOUT_METHOD_CHOSEN_SAVED,
      {
        $paymentMethod: formValues.payment_method,
        $oldPaymentMethod: installment?.paid_payment_method,
      }
    )
  }
  const newPaidDate = dayjs(formValues.paid_date)
  const diffTime = Math.abs(Date.now() - Number(newPaidDate))
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  if (!newPaidDate.isSame(dayjs(installment?.paid_date))) {
    sendEvent(
      receivableId,
      status,
      MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_DATE_CHOSEN_SAVED,
      {
        $paidDate: newPaidDate.toString(),
        $oldPaidDate: installment?.paid_date?.toString(),
        $differenceBetweenTodayAndPaidDate: diffDays - 1,
      }
    )
  }
}
