import styled from 'styled-components'
import { spacing } from '@gravity/tokens'

export const Container = styled.div`
  display: flex;
  padding: ${spacing[6]} 0 ${spacing[8]} 0;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[2]};

  position: relative;
  margin: 0 -${spacing[16]};
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0 ${spacing[16]};
`

export const CardsContainer = styled.div`
  display: flex;
  gap: ${spacing[4]};

  display: flex;
  align-items: flex-start;

  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`
