import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useApi } from '@/shared/hooks'
import {
  ProductStudentsRequest,
  ProductStudentsResponse,
} from '@/modules/enrollment/services/campaigns/types'
import { enrollmentCampaignService } from '@/modules/enrollment/services/campaigns'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { ToggleValues } from '@/modules/enrollment/pages/Campaigns/NewCampaign/components/StudentsStep/components/ToggleButton'

export const useFetchStudentsByProduct = (
  params: ProductStudentsRequest,
  options?: UseQueryOptions<ProductStudentsResponse>
) => {
  const { school } = useSelectedSchool()
  const { apiClient } = useApi()

  const showParticipatingStudents = params.isAbleCampaign
    ? ToggleValues.INCLUDED
    : ToggleValues.NOT_INCLUDED
  const campaignStudentsByProductKey = `campaign-${showParticipatingStudents}-students-by-product`

  const service = enrollmentCampaignService(apiClient.privateApi)

  return useQuery<ProductStudentsResponse>(
    [campaignStudentsByProductKey, params],
    () => service.fetchStudentsByProduct(school.id, params),
    {
      enabled: !!params.inProductIds.length,
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}
