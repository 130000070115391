import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import { Radio, FormControlLabel, Search } from '@olaisaac/design-system'
import useSearchByStudentOrGuardian, {
  StatusSearch,
} from 'src/escolas/hooks/useSearchByStudentOrGuardian'
import { debounce } from 'throttle-debounce'
import Grid from '@material-ui/core/Grid'

export enum Placeholder {
  GUARDIAN = 'Nome ou CPF do Responsável',
  STUDENT = 'Nome do Aluno',
}

type SearchByStudentOrGuardianProps = {
  debouceValue?: number
  handleChange: (name: string, isCpf: boolean) => void
  onClick: () => void
  search: (statusSearch: StatusSearch) => void
  searchValue: string
}

const SearchByStudentOrGuardian = ({
  searchValue,
  handleChange,
  onClick,
  search,
  debouceValue = 900,
}: SearchByStudentOrGuardianProps) => {
  const { statusSearch, handleStatusSearch, changeTypeSearch } = useSearchByStudentOrGuardian(
    searchValue,
    handleChange,
    search
  )

  const { isGuardian } = statusSearch

  return (
    <Grid container alignItems="center">
      <Grid item xs={3}>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="Pesquisar por Responsável ou Aluno"
            defaultValue="student"
            value={isGuardian ? 'guardian' : 'student'}
            name="radio-buttons-group"
          >
            <FormControlLabel
              label="Responsável"
              value="guardian"
              onClick={() => changeTypeSearch(true)}
              control={<Radio />}
              style={{ marginBottom: 0 }}
            />

            <FormControlLabel
              label="Aluno"
              value="student"
              onClick={() => changeTypeSearch(false)}
              control={<Radio />}
              style={{ marginBottom: 0 }}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <Search
          initialValue={searchValue}
          placeholder={statusSearch.isGuardian ? Placeholder.GUARDIAN : Placeholder.STUDENT}
          onChange={debounce(debouceValue, value => {
            handleStatusSearch('searchText', value)
          })}
          onClick={onClick}
          onClear={() => handleStatusSearch('searchText', '')}
        />
      </Grid>
    </Grid>
  )
}

export default SearchByStudentOrGuardian
