export const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return ''
  }
  return phoneNumber.replace(/[^\d]/g, '').replace(/(\d{2})(\d{4,})(\d{4})/, '($1) $2-$3')
}

export const BRAZIL_COUNTRY_CODE = '55'
export const appendBrazilCountryCode = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return ''
  }
  if (phoneNumber.startsWith(BRAZIL_COUNTRY_CODE)) {
    return phoneNumber
  }
  return `${BRAZIL_COUNTRY_CODE}${phoneNumber}`
}
