import { Box } from '@material-ui/core'

import theme from '@/shared/theme'
import type { StatusBadgeProps } from './types'

const { colors, spacing } = theme.primitiveTokens

export const StatusBadge = (props: StatusBadgeProps) => {
  const { children, variation } = props

  return (
    <Box display="flex" alignItems="center" gridGap={spacing[2]}>
      <Box
        data-testid="badge"
        bgcolor={colors[variation][70]}
        borderRadius="100%"
        height={8}
        width={8}
      />
      {children}
    </Box>
  )
}
