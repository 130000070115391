import { Box, FormControl, Grid } from '@material-ui/core'
import { RadioGroup, RadioGroupItem } from '@gravity/radio'
import { Typography } from '@olaisaac/design-system'
import { Controller } from 'react-hook-form'
import { CURRENT_YEAR } from 'src/escolas/components/contract/constants'
import { ContractSelectYearProps } from './types'
import { ENROLLMENT_YEAR } from '@/modules/enrollment/constants/enrollment'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useSchoolEnrollmentCycles } from '@/modules/enrollment/hooks/useSchoolEnrollmentCycles'
import { ChangeEvent } from 'react'

const getAvailableYears = (): number[] => {
  // hey matriculas team, this is a temporary solution, I hope to see a better one in 2024.
  return Array.from(new Set([CURRENT_YEAR, ENROLLMENT_YEAR])).sort()
}

const ContractSelectYear = ({ setReferenceYear, form, referenceYear }: ContractSelectYearProps) => {
  const isSelectCyclesEnabled = useUnleashFlag(UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES)
  const { enabledEnrollmentCyclesYears } = useSchoolEnrollmentCycles()
  const availableYears = isSelectCyclesEnabled ? enabledEnrollmentCyclesYears : getAvailableYears()

  const { control } = form
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  return (
    <Box mb={4}>
      <Box mb={2}>
        <Typography variation="headlineDesktopXsmall" withoutMargin>
          O contrato será referente a qual ano letivo?
        </Typography>
        <Typography variation="bodyLarge" color="secondary" withoutMargin>
          Escolha o ano a que este contrato pertencerá.
        </Typography>
      </Box>
      <Grid item xs={4}>
        <Controller
          rules={{ required: true }}
          control={control}
          name="reference_year"
          render={({ field: { onChange }, fieldState: { error } }) => (
            <FormControl fullWidth variant="outlined" error={Boolean(error)}>
              <RadioGroup
                aria-label="Test options"
                name="radio-buttons-group"
                defaultValue={referenceYear}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  onChange(event)
                  setReferenceYear(event.target.value)
                  isInitialized &&
                    eventDispatcherClient.sendEvent({
                      scope: EventDispatcherEventScopes.ADD_NEW_CONTRACT_PAGE,
                      name: EnrollmentEventDispatcherEvents.CLICKED,
                      action: 'click',
                      customProperties: {
                        $Radio_button_name: 'ANO_LETIVO',
                        $value: event.target.value,
                      },
                    })
                }}
              >
                {availableYears?.map(year => (
                  <RadioGroupItem
                    size={2}
                    key={String(year)}
                    id={String(year)}
                    value={String(year)}
                    label={String(year)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid>
    </Box>
  )
}

export default ContractSelectYear
