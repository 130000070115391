import styled from 'styled-components'

import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircle from '@material-ui/icons/CheckCircle'

import type { WrapperProps } from './types'

export const Wrapper = styled.button<WrapperProps>`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  padding: 15px 0;
  background-color: transparent;
  border-width: 0px;
  border-top: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};

  ${({ active, theme }) =>
    active &&
    `
      border-top-color: ${theme.primitiveTokens.colors.isaacBlue[60]};
    `}

  :hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.7)};
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 18px;
`

export const RadioButtonUncheckedIcon = styled(RadioButtonUnchecked)`
  color: ${({ theme }) => theme.primitiveTokens.colors.gray[30]};
  margin-right: 14px;
`

export const CheckCircleIcon = styled(CheckCircle)`
  color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
  margin-right: 14px;
`

export const CounterIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecedfb;
  width: 24px;
  height: 24px;
  margin-right: 14px;
  border-radius: 50%;
`
