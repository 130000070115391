import { useEffect } from 'react'
import { Card } from '@olaisaac/design-system'
import { ChosenOfferCardProps } from './types'
import {
  ColumnContainer,
  CommentText,
  ContainerCard,
  KeyText,
  RowContainer,
  SmallValueText,
  StyledCancelButton,
  ValueText,
} from './styles'
import ModalCustomDialog from '../Modal'
import { useOrderDropout } from '../../hooks/queries/useOrderDropout'
import { useContextCreditOrderId } from '../../contexts/CreditDataContext'
import { useHistory } from 'react-router-dom'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { SeparatorLine } from '../SeparatorLine'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'

export const ChosenOfferCard = ({ creditValue, offer, canCancel }: ChosenOfferCardProps) => {
  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()
  const { mutateAsync } = useOrderDropout()
  const { creditOrderId } = useContextCreditOrderId()
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const redirect = () => {
    if (typeof window !== undefined) {
      window.location.reload()
    } else {
      history.push(`/${schoolSlug}/credito`)
    }
  }

  useEffect(() => {
    sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
      $page_name: CreditEventComponentNames.CREDIT_SUMMARY_PAGE,
      $modality_type: offer.category,
    })
  }, [])

  return (
    <RowContainer>
      <Card>
        <ContainerCard>
          <KeyText>{`Resumo da oferta solicitada em ${offer.chosenOfferDate}`}</KeyText>
          <ValueText>Crédito de {creditValue}</ValueText>

          <SeparatorLine />

          <KeyText>Número de parcelas</KeyText>
          <SmallValueText>{`${offer.totalInstallments} parcelas de ${offer.installmentValue}`}</SmallValueText>

          <SeparatorLine />

          <RowContainer>
            <ColumnContainer>
              <KeyText>Forma de pagamento</KeyText>
              <SmallValueText>Desconto no repasse</SmallValueText>
            </ColumnContainer>
            <ColumnContainer>
              <KeyText>Comece a pagar em</KeyText>
              <SmallValueText>{offer.firstDiscountDate}</SmallValueText>
            </ColumnContainer>
          </RowContainer>

          <SeparatorLine />

          <RowContainer>
            <ColumnContainer>
              <KeyText>Taxa de juros mensal</KeyText>
              <SmallValueText>{`${offer.monthlyFee} % a.m`}</SmallValueText>
            </ColumnContainer>
            <ColumnContainer>
              <KeyText>Juros e IOF anuais (CET)</KeyText>
              <SmallValueText>{`${offer.annualFee} % a.a`}</SmallValueText>
            </ColumnContainer>
          </RowContainer>

          <SeparatorLine />

          <KeyText>Total a pagar</KeyText>
          <SmallValueText>{offer.totalAmount}</SmallValueText>

          <CommentText>
            O valor da parcela acima é aproximado e pode sofrer alterações pela data de desembolso.
            Condições da oferta válidas para os próximos 7 dias a partir da data da solicitação.
          </CommentText>

          <ModalCustomDialog
            size={2}
            style={{ width: '509px' }} // TODO: remove this field when fix the DS sizes
            confirmButton={{ label: 'Cancelar solicitação', variation: 'solid', color: 'error' }}
            backButton={{ label: 'Manter solicitação', variation: 'ghost' }}
            onClose={() => {
              sendCreditEventToMixpanel(
                EventDispatcherEvents.BUTTON_CLICKED,
                MixpanelEventActions.CLICK,
                {
                  $button_name: 'Manter solicitação',
                }
              )
            }}
            title="Cancelar solicitação de crédito?"
            submitHandler={(value: string) => {
              sendCreditEventToMixpanel(
                EventDispatcherEvents.BUTTON_CLICKED,
                MixpanelEventActions.CLICK,
                {
                  $button_name: 'Cancelar solicitação',
                }
              )

              mutateAsync({
                creditOrderId: creditOrderId,
                type: 'cancel',
                message: value,
              }).finally(() => redirect())
            }}
            labelReason="Qual foi o principal motivo para cancelar a solicitação?"
            description={`Cancelar a solicitação não resultará em custos adicionais, no entanto,  \n a oferta será perdida e as condições podem variar.`}
          >
            {canCancel ? (
              <StyledCancelButton color="error" variant="ghost" fullWidth>
                Cancelar solicitação
              </StyledCancelButton>
            ) : null}
          </ModalCustomDialog>
        </ContainerCard>
      </Card>
    </RowContainer>
  )
}
