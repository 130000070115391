import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { notificationsService } from '../services'
import type { NotificationRequestDTO } from '../services/types'

const notificationsApi = notificationsService()

export const useNotificationsCountQuery = (
  queryParams: NotificationRequestDTO,
  options?: UseQueryOptions<number>
) =>
  useQuery<number>(
    ['useNotificationsCountQuery', JSON.stringify(queryParams)],
    () => notificationsApi.fetchNotificationsCount(queryParams),
    {
      retry: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      ...options,
    }
  )
