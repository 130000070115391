import { Box } from '@material-ui/core'
import { Divider, Typography } from '@olaisaac/design-system'
import { ReactNode, useEffect, useState } from 'react'
import { useSelectPos, usePaymentCreditCard } from 'src/escolas/hooks'
import { PaymentPlan, SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import { getOptionInformations } from '../../helpers/OptionInformationText'
import BankSlipPixForm from '../Forms/BankSlipPixForm'
import CreditCardForm from '../Forms/CreditCardForm'
import CardForm from '../Forms/CardForm'
import type { MethodsPropsWithAgreement } from './types'
import { RadioGroupStyled } from '@/modules/guardians/Negotiation/EntryNegotiation/steps/SelectPaymentMethod/styles'
import OptionCard from '@/modules/guardians/Negotiation/components/OptionCard/OptionCard'
import { useAgreement } from '@/escolas/contexts/agreementContext'
import CalloutPaymentLink from '@/modules/guardians/Negotiation/components/CalloutPaymentLink'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

const findPlan = (plan: PaymentPlan, option: SimulationPaymentMethod) => {
  if (
    option === SimulationPaymentMethod.DEBIT_CARD ||
    option === SimulationPaymentMethod.CREDIT_CARD
  ) {
    return plan.payment_method === SimulationPaymentMethod.CREDIT_CARD
  }
  return plan.payment_method === option
}

const defaultListOptions: Record<SimulationPaymentMethod, ReactNode> = {
  [SimulationPaymentMethod.BANK_SLIP]: null,
  [SimulationPaymentMethod.CREDIT_CARD]: null,
  [SimulationPaymentMethod.DEBIT_CARD]: null,
  [SimulationPaymentMethod.PIX]: null,
  [SimulationPaymentMethod.POS]: null,
}

const OtherMethod = ({ handleValidate, agreement, handleValue }: MethodsPropsWithAgreement) => {
  const [selectOption, setSelectOption] = useState<SimulationPaymentMethod>()
  const [listOptions, setListOptions] = useState<Record<SimulationPaymentMethod, ReactNode>>(
    defaultListOptions
  )

  const { listPos } = useSelectPos()

  const { paymentFee } = usePaymentCreditCard()
  const {
    setSelectedSimulationPaymentMethod,
    agreementSimulations,
    positionAgreement,
  } = useAgreement()

  const creditCardFee = agreement?.credit_card_fee ?? 0
  const isEnabledCreditCardFee = paymentFee?.enabled && creditCardFee > 0
  const currentTotalAmount = isEnabledCreditCardFee
    ? agreement.total_amount + creditCardFee
    : agreement.total_amount

  const setSelectOptionPaymentMethod = (option: SimulationPaymentMethod) => {
    setSelectOption(option)
    setSelectedSimulationPaymentMethod(option)
  }

  const isDisableOtherPaymentOptions = useUnleashFlag(
    UnleashFlags.REG_337_DISABLE_OTHER_PAYMENT_OPTIONS
  )

  const disableEnrollmentBankSlipOption =
    isDisableOtherPaymentOptions &&
    (agreement.type === 'down_payment' ||
      agreement?.receivables?.every(receivable => receivable.receivable_type === 'ENROLLMENT'))

  const calloutData = {
    installmentId: agreement?.receivables[0]?.installment_id,
    link: agreement?.receivables[0]?.invoice_link,
    receivableType: agreement?.receivables[0]?.receivable_type,
    type: agreement?.receivables[0]?.type,
    showToastDescription:
      agreementSimulations.length > 1 && positionAgreement < agreementSimulations.length - 1,
  }

  useEffect(() => {
    const list = { ...defaultListOptions }

    agreement.payment_plans.forEach(plan => {
      const { payment_method } = plan
      if (
        payment_method === SimulationPaymentMethod.BANK_SLIP &&
        !disableEnrollmentBankSlipOption
      ) {
        list[SimulationPaymentMethod.BANK_SLIP] = (
          <BankSlipPixForm
            handleValidate={handleValidate}
            handleValue={handleValue}
            agreement={agreement}
            paymentPlan={plan}
            paymentOption={SimulationPaymentMethod.BANK_SLIP}
          />
        )
      } else if (payment_method === SimulationPaymentMethod.CREDIT_CARD) {
        agreement.type === 'down_payment'
          ? (list[SimulationPaymentMethod.CREDIT_CARD] = (
              <CardForm
                handleValidate={handleValidate}
                handleValue={handleValue}
                paymentPlan={plan}
                paymentOption={SimulationPaymentMethod.CREDIT_CARD}
                maxInstallment={1}
                totalAmount={agreement.total_amount}
              />
            ))
          : (list[SimulationPaymentMethod.CREDIT_CARD] = (
              <CreditCardForm
                handleValidate={handleValidate}
                handleValue={handleValue}
                agreement={{
                  ...agreement,
                  total_amount: currentTotalAmount,
                }}
                paymentPlan={plan}
                paymentOption={SimulationPaymentMethod.CREDIT_CARD}
                disablePaymentLink={disableEnrollmentBankSlipOption}
              />
            ))
        if (listPos.length > 0) {
          list[SimulationPaymentMethod.DEBIT_CARD] = (
            <CardForm
              handleValidate={handleValidate}
              handleValue={handleValue}
              paymentPlan={plan}
              paymentOption={SimulationPaymentMethod.DEBIT_CARD}
              maxInstallment={1}
              totalAmount={agreement.total_amount}
            />
          )
        }
      }
    })
    setListOptions(list)
    setSelectOption(undefined)
  }, [agreement])

  return (
    <Box display="flex" flexDirection="column" marginBottom={17}>
      <Box marginTop={6} marginBottom={3}>
        <Typography variation="subtitleDesktopLarge" withoutMargin color="primary">
          Selecione a forma de pagamento
        </Typography>
      </Box>
      <RadioGroupStyled
        style={{ display: 'flex' }}
        aria-label="form of payment"
        name="radio-buttons-form-of-payment"
      >
        {Object.entries(listOptions).map(([key, value], index, array) => {
          if (!value) return null
          const option = SimulationPaymentMethod[key as SimulationPaymentMethod]
          const plan = agreement.payment_plans.find(plan => findPlan(plan, option))
          if (!plan) return null
          return (
            <Box key={option} width="100%">
              <OptionCard
                informationText={getOptionInformations(
                  SimulationPaymentMethod[option],
                  key === SimulationPaymentMethod.CREDIT_CARD
                    ? currentTotalAmount
                    : agreement.total_amount,
                  plan,
                  {
                    ...paymentFee,
                    value: creditCardFee,
                  }
                )}
                type={option}
                isPaymentFeeEnabled={isEnabledCreditCardFee}
                isSelected={selectOption === option}
                onSelect={setSelectOptionPaymentMethod}
                key={option}
              >
                {value}
              </OptionCard>
              {index !== array.length - 1 && <Divider style={{ margin: 0 }} />}
            </Box>
          )
        })}
      </RadioGroupStyled>
      {disableEnrollmentBankSlipOption && (
        <Box marginTop={3}>
          <CalloutPaymentLink
            installmentId={calloutData.installmentId}
            link={calloutData.link}
            receivableType={calloutData.receivableType}
            type={calloutData.type}
            showToastDescription={calloutData.showToastDescription}
          />
        </Box>
      )}
    </Box>
  )
}

export default OtherMethod
