import { formatCentsToReal } from 'src/shared/utils'

export const getNotification = (
  minAmount: cents,
  totalAmount: number
): { description: string; variation: 'success' | 'warning' | 'error' | 'information' } => {
  const isOneLumpPayment = totalAmount === minAmount

  return {
    description: isOneLumpPayment
      ? 'Para esse acordo, o pagamento com boleto só pode ser à vista.'
      : `O valor da entrada deve ser no mínimo de ${formatCentsToReal(minAmount)}`,
    variation: isOneLumpPayment ? 'warning' : 'information',
  }
}
