import { PreContractEdit } from 'src/escolas/pages/PreContractEdit'
import { PageRouteProps } from 'src/escolas/router'
import { buildRoute } from '@/utils/router/utils'

const getPathname = ({ path }: PageRouteProps) => `${path}/:contractId/editar-contrato`
const buildBreadcrumbs = ({ breadcrumbs }: PageRouteProps, path: string) => [
  ...breadcrumbs,
  { path, name: 'Editar Contrato' },
]
const PreContractEditRoute = buildRoute(PreContractEdit, getPathname, buildBreadcrumbs)
export default PreContractEditRoute
