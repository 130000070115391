import styled from 'styled-components'

import type { ContainerProps } from './types'

export const Container = styled.nav`
  & ~ & {
    margin-top: ${props => props.theme.gravity.spacing[8]};
  }

  .title {
    display: flex;
    align-items: center;

    svg {
      margin-right: ${props => props.theme.gravity.spacing[2]};
    }
  }
`

export const InfoContainer = styled.ul<ContainerProps>`
  display: grid;
  grid-template-columns: ${({ $columnsCount, $width }) =>
    `repeat( ${$columnsCount}, ${$width ?? '1fr'})`};
  grid-gap: ${props =>
    props.$width ? props.theme.gravity.spacing[6] : props.theme.gravity.spacing[8]};
  margin-top: ${props => props.theme.gravity.spacing[6]};

  li {
    list-style: none;
  }
`
