import { Skeleton } from '@gravity/skeleton'
import styled from 'styled-components'
import { fontSize, fontWeight, letterSpacing, lineHeight, spacing } from '@gravity/tokens'
import { Typography } from '@olaisaac/design-system'

export const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]};
`

export const RowContainer = styled.div`
  max-width: 1366px;
  @media screen and (min-width: 1440px) {
    margin-left: auto;
    margin-right: auto;
  }
`

export const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[2]};
  margin-bottom: 25px;
  justify-content: space-between;
`

export const TitleSkeletonContainer = styled(ColumnContainer)`
  margin-top: 33px;
  margin-bottom: 53px;
`

export const PairSkeletonContainer = styled(ColumnContainer)`
  margin-bottom: ${spacing[3]};
`

export const GroupSkeletonContainer = styled(ColumnContainer)`
  margin-top: ${spacing[6]};
  gap: ${spacing[4]};
`

export const SkeletonStyled = styled(Skeleton)`
  margin-top: ${spacing[6]};
  margin-bottom: ${spacing[10]};
`

export const TitleText = styled(Typography)`
  font-size: ${fontSize['2xl']};
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.normal};
  letter-spacing: ${letterSpacing.normal};
  margin-bottom: ${spacing[4]};
  margin-top: ${spacing[6]};
`

export const ContainerCard = styled.div`
  margin: ${spacing[4]};
  gap: ${spacing[3]};
`

export const SkeletonTitlePage = styled(Skeleton)`
  margin-bottom: 16px;
`

export const RowFlexEndContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[2]};
  margin-bottom: 25px;
  justify-content: right;
`

export const BackNavigator = styled.div`
  margin-bottom: ${spacing[6]};
  margin-top: ${spacing[6]};
`

export const HeaderSkeletonForm = styled.div`
  margin-bottom: ${spacing[6]};
  margin-top: ${spacing[6]};
`

export const RowGroupInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[2]};
  margin-bottom: ${spacing[6]};
  margin-top: ${spacing[6]};
`
