import styled from 'styled-components'
import { spacing, colors, borderRadius } from '@gravity/tokens'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing[2]};
`

export const Content = styled.div`
  display: flex;
  gap: ${spacing[3]};

  display: flex;
  align-items: flex-start;
`

interface DotProps {
  selected?: boolean
}

export const Dot = styled.div<DotProps>`
  width: ${({ selected }) => (selected ? spacing[4] : spacing[2])};
  height: ${spacing[2]};

  background-color: ${colors['colors-interaction-primary-1']};
  border-radius: ${borderRadius.full};
  opacity: ${({ selected }) => (selected ? 1 : 0.14)};

  cursor: pointer;
`
