import { MixpanelEventsEnum, mixpanel } from '@/shared/integrations'
import { ContractStatus, DebtStatus } from '@/shared/interfaces'

import type { StudentsContractsFilterType } from '@/escolas/hooks/useContractsFilter/types'

export const trackFilterEvent = (data: StudentsContractsFilterType) => {
  const selectedOptions = Object.values(data).flat()

  if (selectedOptions.length !== 1) {
    return
  }

  switch (selectedOptions[0]) {
    case ContractStatus.OPEN:
      mixpanel.trackEvent({ name: MixpanelEventsEnum.ACTIVE_STUDENTS_FILTER })
      break
    case DebtStatus.OVERDUE:
      mixpanel.trackEvent({ name: MixpanelEventsEnum.STUDENTS_FINANCIAL_DEBT_FILTER })
      break
    case DebtStatus.WARNING:
      mixpanel.trackEvent({ name: MixpanelEventsEnum.STUDENTS_FINANCIAL_WARNING_FILTER })
      break
    default:
      break
  }
}
