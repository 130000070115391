import type { Dispatch, SetStateAction } from 'react'
import { Table } from '@gravity/table'
import { mdiCheckCircle } from '@mdi/js'
import Icon from '@mdi/react'

import { useFetchCampaignStudents } from '@/modules/enrollment/hooks/queries/useCampaignQueries'
import { usePagination } from '@/shared/hooks/usePagination'
import { statusParamsDict } from '@/modules/enrollment/components/StatusBadge'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import { useTableData } from './hooks/useTableData'
import { useTableActions } from './hooks/useTableActions'
import * as Styled from './styles'
import type { TableRowProps } from './types'
import { StudentsTableActions } from '../StudentsTableActions'
import type { CloseCampaignState, AddTuitionToCampaignState } from '../../types'

const DEFAULT_ITEMS_PER_PAGE = 25

interface StudentsTableProps {
  campaignId: string
  hasPaymentPlan: boolean
  isLoading: boolean
  noDataMessage?: string
  setAddTuitionDialogState: Dispatch<SetStateAction<AddTuitionToCampaignState>>
  setCloseCampaignDialogState: Dispatch<SetStateAction<CloseCampaignState>>
}

export const StudentsTable = ({
  hasPaymentPlan,
  campaignId,
  noDataMessage,
  isLoading: isLoadingCampaignList = false,
  setCloseCampaignDialogState,
  setAddTuitionDialogState,
}: StudentsTableProps) => {
  const { pagination, updatePaginationValue } = usePagination(1, DEFAULT_ITEMS_PER_PAGE)

  const isCampaignAddTuitionEnabled = useUnleashFlag(
    UnleashFlags.MAT_474_ENABLE_CAMPAIGN_ADD_INSTALLMENTS
  )

  const { data: campaignStudentsData, isLoading: isLoadingStudents } = useFetchCampaignStudents({
    campaignId,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  })
  const campaignStudents = campaignStudentsData?.data?.students ?? []

  const { rows, columnHeaders } = useTableData(campaignStudents, hasPaymentPlan)

  const { handleRowClick } = useTableActions()

  if (!rows?.length && !isLoadingStudents) {
    return <Styled.EmptyStateContainer>{noDataMessage}</Styled.EmptyStateContainer>
  }

  const getRowStatus = (row: TableRowProps) => {
    if (row.isCampaignProposalCanceled) return 'Proposta cancelada'
    return statusParamsDict[row.reenrollmentStatus]?.text ?? ''
  }
  return (
    <>
      <Styled.TableWrapper>
        <Table.Root>
          <Table.Head>
            {columnHeaders.map(({ label, key, minWidth }) => (
              <Styled.TableHeaderCell key={key} name={key} style={{ minWidth }}>
                {label}
              </Styled.TableHeaderCell>
            ))}
          </Table.Head>
          <Table.Body>
            {isLoadingCampaignList || isLoadingStudents ? (
              <>
                <Table.Row>
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  {hasPaymentPlan && <Table.LoadingCell />}
                  {isCampaignAddTuitionEnabled && <Table.LoadingCell />}
                </Table.Row>

                <Table.Row>
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  {hasPaymentPlan && <Table.LoadingCell />}
                  {isCampaignAddTuitionEnabled && <Table.LoadingCell />}
                </Table.Row>

                <Table.Row>
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  {hasPaymentPlan && <Table.LoadingCell />}
                  {isCampaignAddTuitionEnabled && <Table.LoadingCell />}
                </Table.Row>
              </>
            ) : (
              rows.map(row => (
                <Styled.TableRow
                  disabled={row.isCampaignProposalCanceled}
                  key={row.key}
                  onClick={handleRowClick(row.key)}
                >
                  <Table.TextCell
                    disabled={row.isCampaignProposalCanceled}
                    subtext={getRowStatus(row)}
                  >
                    {row.columns.student_name}
                  </Table.TextCell>
                  <Table.TextCell
                    disabled={row.isCampaignProposalCanceled}
                    subtext={row.guardianEmail}
                  >
                    {row.columns.guardian_name}
                  </Table.TextCell>
                  <Table.CustomCell>
                    {row.columns.has_open_email === '1' ? (
                      <Icon size={1} path={mdiCheckCircle} />
                    ) : null}
                  </Table.CustomCell>
                  <Table.CustomCell>
                    {row.columns.is_engaged === '1' ? (
                      <Icon size={1} path={mdiCheckCircle} />
                    ) : null}
                  </Table.CustomCell>
                  {hasPaymentPlan && (
                    <Table.CustomCell>
                      {row.columns.paid_enrollment === '1' ? (
                        <Icon size={1} path={mdiCheckCircle} />
                      ) : null}
                    </Table.CustomCell>
                  )}
                  {isCampaignAddTuitionEnabled && (
                    <Table.CustomCell>
                      {row.columns.has_tuition === '1' ? (
                        <Icon size={1} path={mdiCheckCircle} />
                      ) : null}
                    </Table.CustomCell>
                  )}
                  <StudentsTableActions
                    enrollmentID={row.enrollmentID}
                    studentName={row.columns.student_name}
                    showRemoveStudentFromCampaignOption={
                      !row.isCampaignProposalCanceled &&
                      !Number(row.columns.paid_enrollment) &&
                      !Number(row.columns.has_tuition)
                    }
                    setCloseCampaignDialogState={setCloseCampaignDialogState}
                    showAddCampaignTuitionOption={
                      !row.isCampaignProposalCanceled &&
                      isCampaignAddTuitionEnabled &&
                      !Number(row.columns.has_tuition)
                    }
                    setAddTuitionDialogState={setAddTuitionDialogState}
                  />
                </Styled.TableRow>
              ))
            )}
          </Table.Body>
          {campaignStudentsData?.pagination?.total && (
            <Table.Pagination
              page={pagination.page}
              itemsPerPage={pagination.itemsPerPage}
              total={campaignStudentsData?.pagination.total}
              onChangePage={newPage => {
                updatePaginationValue('page', newPage, true)
                window.scrollTo(0, 0)
              }}
              onChangeItemsPerPage={newItensPerPage => {
                updatePaginationValue('itemsPerPage', newItensPerPage, true)
              }}
            />
          )}
        </Table.Root>
      </Styled.TableWrapper>
    </>
  )
}
