import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGuardianContractsQuery } from './hooks/useGuardianContractsQuery'
import { InstallmentsProvider } from '@/shared/contexts/installmentsContext'
import { GuardianDetailsContractsTable } from './components/GuardianDetailsContractsTable/GuardianDetailsContractsTable'
import { GuardianDetailsContractsNewTable } from './components/GuardianDetailsContractsNewTable'
import { ContractsDrawerContainer } from '../ContractsDrawerContainer/ContractsDrawerContainer'
import { useQuery } from '@/shared/hooks/useQuery'
import { GuardianDetailsContractsFilter } from './components/GuardianDetailsContractsFilter/GuardianDetailsContractsFilter'
import { useGuardianContractsQueryParams } from './hooks/useGuardianContractsQueryParams'
import { useGuardianFiltersOptionsQuery } from '../GuardianDetails/hooks/useGuardianFiltersOptionsQuery'
import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@olaisaac/design-system'
import { HelpRounded } from '@material-ui/icons'
import { TypesOfContractGlossaryDrawer } from './components/TypesOfContractGlossaryDrawer'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { ContractDetailsDrawerState } from '@/escolas/components/contract/ContractDetailsDrawer/constants'
import { useGuardianContractsListQuery } from '../GuardianDetails/hooks/useGuardianContractsListQuery'

export const GuardianDetailsContractsTab = () => {
  const { school } = useSelectedSchool()
  const { guardianId } = useParams<{ guardianId: string }>()
  const { query, setOnQueryParam } = useQuery()
  const queryClient = useQueryClient()
  const { filters, updateFilters } = useGuardianContractsQueryParams()
  const [currentContractId, setCurrentContractId] = useState<uuid>()
  const [showContractDetailsDrawer, setShowContractDetailsDrawer] = useState(false)
  const [showTypesOfContractDrawer, setShowTypesOfContractDrawer] = useState(false)
  const [drawerState, setDrawerState] = useState<ContractDetailsDrawerState>(
    ContractDetailsDrawerState.DEFAULT
  )

  const isEnableNewContractPage = useUnleashFlag(UnleashFlags.REG_61_ENABLE_CONTRACT_PAGE)

  const handleOpenContractDetails = (contractId: uuid) => {
    setDrawerState(ContractDetailsDrawerState.DEFAULT)
    setCurrentContractId(contractId)
    setShowContractDetailsDrawer(true)
  }

  const handleOpenCancelContractDrawer = (contractId: uuid) => {
    handleOpenContractDetails(contractId)
    setDrawerState(ContractDetailsDrawerState.CANCEL_CONTRACT)
  }

  const handleChangeContractOwnership = (contractId: uuid) => {
    handleOpenContractDetails(contractId)
    setDrawerState(ContractDetailsDrawerState.CHANGE_CONTRACT_OWNERSHIP)
  }

  const handleGeneratePaymentStatement = (contractId: uuid) => {
    handleOpenContractDetails(contractId)
    setDrawerState(ContractDetailsDrawerState.PAYMENT_STATEMENT)
  }

  const {
    contracts,
    refetchGuardianContracts,
    isFetchGuardianContractsLoading,
    isRefetchingGuardianContracts,
  } = useGuardianContractsQuery({
    filter: filters,
    guardianId,
    schoolId: school.id,
  })

  const {
    filterOptions,
    isFiltersOptionsFetched,
    refetchFiltersOptions,
  } = useGuardianFiltersOptionsQuery({
    guardianId,
    schoolId: school.id,
  })

  const {
    guardianContractsList,
    isFetchGuardianContractsListLoading,
  } = useGuardianContractsListQuery({
    guardianId,
    schoolId: school.id,
  })

  const shouldRefetch = Boolean(query.get('refetch'))

  useEffect(() => {
    if (shouldRefetch) {
      setOnQueryParam(null, 'refetch')
      refetchFiltersOptions()
      refetchGuardianContracts()
    }
  }, [shouldRefetch])

  const isLoadingContracts =
    isFetchGuardianContractsLoading ||
    isRefetchingGuardianContracts ||
    isFetchGuardianContractsListLoading

  const contractInfoButtonLabel = isEnableNewContractPage
    ? 'O que é situação do contrato?'
    : 'O que é tipo de contrato?'

  return (
    <>
      <GuardianDetailsContractsFilter
        filters={filters}
        updateFilters={updateFilters}
        filtersOptions={filterOptions?.data}
        isFiltersOptionsFetched={isFiltersOptionsFetched}
        typeOfContractGlossaryButton={
          <Button
            style={{ maxWidth: 'fit-content' }}
            variation="ghost"
            title={contractInfoButtonLabel}
            onClick={() => setShowTypesOfContractDrawer(true)}
            startIcon={<HelpRounded />}
          >
            {contractInfoButtonLabel}
          </Button>
        }
      />
      {isEnableNewContractPage ? (
        <GuardianDetailsContractsNewTable
          contracts={contracts?.data}
          contractsData={guardianContractsList}
          isLoading={isLoadingContracts}
          openContractDetails={contractId => handleOpenContractDetails(contractId)}
          openCancelContractDrawer={contractId => handleOpenCancelContractDrawer(contractId)}
          onChangeContractOwnership={contractId => handleChangeContractOwnership(contractId)}
          onGeneratePaymentStatement={contractId => handleGeneratePaymentStatement(contractId)}
        />
      ) : (
        <GuardianDetailsContractsTable
          contracts={contracts?.data}
          isLoading={isLoadingContracts}
          openContractDetails={contractId => handleOpenContractDetails(contractId)}
        />
      )}
      {showContractDetailsDrawer && currentContractId && (
        <InstallmentsProvider>
          <ContractsDrawerContainer
            state={drawerState}
            contractId={currentContractId}
            refetchGuardianContracts={() => {
              queryClient.invalidateQueries(['guardian-legacy-contracts'])
              setOnQueryParam('true', 'refetch')

              refetchGuardianContracts()
            }}
            onClose={() => setShowContractDetailsDrawer(false)}
          />
        </InstallmentsProvider>
      )}
      <TypesOfContractGlossaryDrawer
        open={showTypesOfContractDrawer}
        onClose={() => setShowTypesOfContractDrawer(false)}
      />
    </>
  )
}
