import { FC } from 'react'
import { Button, Typography } from '@olaisaac/design-system'
import dayjs from 'dayjs'
import { Box } from '@material-ui/core'
import copy from 'copy-to-clipboard'

import StatusBadge, { StatusBadgeColor } from 'src/escolas/components/StatusBadge'
import PrintReceiptButton from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/PrintReceiptButton'
import { SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import { formatCentsToReal, getStartOfToday } from 'src/shared/utils'
import { useSendCheckoutEvent } from '@/modules/guardians/Negotiation/hooks/useSendCheckoutEvents'
import { useSnackbar } from '@/shared/hooks'
import { InvoiceStatus, PaymentRowProps } from './types'
import * as S from './styles'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'

const PaymentRow: FC<PaymentRowProps> = ({
  installmentLabel,
  idForReceipt,
  due_date,
  amount,
  formOfPaymentLabel,
  paymentMethod,
  paymentLink,
  invoiceStatus,
  paid_date,
  onSuccess,
}) => {
  const sendCheckoutEvent = useSendCheckoutEvent()

  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const paymentStatus = (function (): { badgeColor: StatusBadgeColor; label: string } {
    switch (invoiceStatus) {
      case InvoiceStatus.AWAITING_PAYMENT:
        return {
          badgeColor: 'primary',
          label: 'A vencer',
        }
      case InvoiceStatus.PAID:
        return {
          badgeColor: 'success',
          label: 'Paga',
        }
    }
  })()

  const showSnackbar = (variation, message) => {
    setSnackbarVariation(variation)
    setSnackbarMessage(message)
    setSnackbarIsOpen(true)
  }

  function copyInvoiceLink() {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_SUCCESSFUL_TRANSACTION_COPY_LINK, {
      name: 'Copiar link de cobrança',
    })

    copy(paymentLink)
    showSnackbar('success', 'Link copiado com sucesso')
  }

  const textPaidDate = paid_date
    ? dayjs(paid_date).utc().format('DD/MM/YYYY')
    : `Hoje, ${getStartOfToday().format('D MMMM')}`

  return (
    <S.Wrapper>
      <S.Column>
        <Typography withoutMargin variation="caption" color="secondary">
          {installmentLabel}
        </Typography>
        <Typography withoutMargin variation="bodyLarge">
          {formatCentsToReal(amount)}
        </Typography>
      </S.Column>

      <S.Column>
        <Typography withoutMargin variation="caption" color="secondary">
          Meio de pagamento
        </Typography>
        <Typography withoutMargin variation="bodyLarge">
          {formOfPaymentLabel}
        </Typography>
      </S.Column>

      {paymentMethod === SimulationPaymentMethod.POS || invoiceStatus === InvoiceStatus.PAID ? (
        <S.Column>
          <Typography withoutMargin variation="caption" color="secondary">
            Data de pagamento
          </Typography>
          <Typography withoutMargin variation="bodyLarge">
            {textPaidDate}
          </Typography>
        </S.Column>
      ) : (
        <S.Column>
          <Typography withoutMargin variation="caption" color="secondary">
            Data de vencimento
          </Typography>
          <Typography withoutMargin variation="bodyLarge">
            {dayjs(due_date).utc().format('D [de] MMMM')}
          </Typography>
        </S.Column>
      )}

      <S.Column>
        <Typography withoutMargin variation="caption" color="secondary">
          Situação
        </Typography>
        <Box flexDirection="row" alignItems="center" display="flex">
          <StatusBadge color={paymentStatus.badgeColor} />

          <Box ml={1}>
            <Typography withoutMargin variation="bodyLarge">
              {paymentStatus.label}
            </Typography>
          </Box>
        </Box>
      </S.Column>
      <S.Column>
        <Typography withoutMargin variation="caption" color="secondary">
          Ações
        </Typography>
        <Box display="flex">
          {paymentMethod === SimulationPaymentMethod.POS || invoiceStatus === InvoiceStatus.PAID ? (
            <PrintReceiptButton
              buttonVariation="ghost"
              receivableId={idForReceipt}
              onSuccess={onSuccess}
              withoutMarginAndPadding
            />
          ) : (
            <Button
              variation="ghost"
              style={{ height: '32px', padding: 0, marginRight: 20 }}
              onClick={copyInvoiceLink}
            >
              Copiar link
            </Button>
          )}
        </Box>
      </S.Column>
    </S.Wrapper>
  )
}

export default PaymentRow
