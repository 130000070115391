import { Box } from '@material-ui/core'

import {
  WarningRounded as WarningRoundedIcon,
  AssignmentRounded as AssignmentRoundedIcon,
} from '@material-ui/icons'

import taskCategoryIcon from '../../assets/task-category-white-icon.svg'

import type { CategoryTagProps } from './types'

import theme from '@/shared/theme'

const { colors, spacing } = theme.primitiveTokens

export const badge = {
  alertas: { color: 'yellow', icon: <WarningRoundedIcon fontSize="inherit" color="inherit" /> },
  informes: { color: 'gray', icon: <AssignmentRoundedIcon fontSize="inherit" color="inherit" /> },
  tarefas: { color: 'isaacBlue', icon: <img src={taskCategoryIcon} alt="" /> },
} as const

export const CategoryTag = (props: CategoryTagProps) => {
  const { children, variation, inactive } = props

  const color = inactive ? colors.gray[5] : colors.gray[0]
  const bgColor = inactive ? colors.gray[30] : colors[badge[variation].color][80]
  const Icon = badge[variation].icon

  return (
    <Box display="flex" alignItems="center" gridGap={spacing[2]}>
      <Box
        data-testid="icon-container"
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize={12}
        color={color}
        bgcolor={bgColor}
        borderRadius="100%"
        padding="6px"
      >
        {Icon}
      </Box>
      {children}
    </Box>
  )
}
