import { useRouteMatch } from 'react-router-dom'

import { Text } from '@gravity/text'

import { Container } from './styles'
import { ItemGuardianSearch } from '../ItemGuardianSearch'
import { ItemStudentSearch } from '../ItemStudentSearch'
import { HeaderTitle } from '../../styles'

import { GuardianItem, StudentItem } from '@/modules/school-home/services/school-home-search/types'
import { StorageItemType } from '@/modules/school-home/types/StorageItem'

// TODO: substitute for the type in services/types.ts
type SearchCategories = 'students' | 'guardians'

type Result = {
  guardians: GuardianItem[]
  students: StudentItem[]
}

export type ResultSectionProps<T extends SearchCategories> = {
  filterName: string
  handleAddItemInStorage: (item: StorageItemType) => void
  inputSearch: string
  isEmptyResultForSearch: boolean
  isLoading: boolean
  results: Result[T] | undefined
  type: T
}

export const ResultSection = <T extends SearchCategories>({
  inputSearch,
  isEmptyResultForSearch,
  filterName,
  isLoading,
  type,
  results,
  handleAddItemInStorage,
}: ResultSectionProps<T>) => {
  const { url } = useRouteMatch()

  return (
    <Container data-testid="result-search-section">
      {!isLoading && !!inputSearch && (
        <>
          <HeaderTitle data-testid="header-title">
            <Text variant="caption-regular" className="caption-search">
              Resultados de {filterName}
            </Text>
          </HeaderTitle>

          {(isEmptyResultForSearch || !results) && (
            <Text variant="body-1-regular">Não há resultados que correspondem à sua busca</Text>
          )}

          {!!results && (
            <div>
              {type === 'guardians' &&
                (results as GuardianItem[]).map(guardian => (
                  <ItemGuardianSearch
                    id={guardian.id}
                    onClick={handleAddItemInStorage}
                    type="search"
                    key={guardian.id}
                    name={guardian.name}
                    students={guardian.students}
                    url={url}
                    inputSearch={inputSearch}
                  />
                ))}

              {type === 'students' &&
                (results as StudentItem[]).map(student => (
                  <ItemStudentSearch
                    id={student.id}
                    onClick={handleAddItemInStorage}
                    type="search"
                    key={student.id}
                    mainProduct={student?.main_product}
                    name={student.name}
                    guardianName={student.guardian_name}
                    url={url}
                    inputSearch={inputSearch}
                  />
                ))}
            </div>
          )}
        </>
      )}
    </Container>
  )
}
