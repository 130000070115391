import styled from 'styled-components'

import theme from '@/shared/theme'

const COLOR = theme.primitiveTokens.colors.gray[80]

export const Title = styled.strong`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${COLOR};
`
