import { Box } from '@material-ui/core'

import { Typography } from '@olaisaac/design-system'

import { Label } from './styles'

type InfoItemProps = {
  label: string
  labelWeight?: 'normal' | 'bold'
  secondaryInfo?: string
  value?: string
}

export const InfoItem = ({ label, labelWeight, value, secondaryInfo }: InfoItemProps) => {
  return (
    <Box>
      <Box mb="0.25rem" display="flex" justifyContent="space-between" alignItems="center">
        <Label weight={labelWeight} color="secondary">
          {label}
        </Label>

        {value && <Label weight="bold">{value}</Label>}
      </Box>

      {secondaryInfo && (
        <Typography variation="bodySmall" color="secondary">
          {secondaryInfo}
        </Typography>
      )}
    </Box>
  )
}
