const singularToPluralMap: Record<string, string> = {
  botão: 'botões',
  'pendência concluída': 'pendências concluídas',
}

/**
 * Pluralize a word.
 * Check the "singularToPluralMap" if an edge case must be considered
 *
 * @param word The word to pluralize
 * @param count How many of the word exist
 * @param numberPrefix Whether to prefix with the number (ex: 3 parcelas)
 * @returns Word in the plural form
 */
export const pluralize = (word: string, count: number, numberPrefix = true): string => {
  const plural = count > 1 ? singularToPluralMap[word] || `${word}s` : word

  return numberPrefix ? `${count} ${plural}` : plural
}
