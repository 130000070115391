import styled from 'styled-components'
import { spacing, fontWeight, fontSize, colors, borderRadius } from '@gravity/tokens'
import { Status, StyledStepItemProps } from './types'

const colorMapping = {
  [Status.COMPLETED]: {
    backgroundColor: '#e6f6eb',
    borderColor: (props: StyledStepItemProps) =>
      props.active ? colors['colors-text-semantic-colors-success-1'] : '#e6f6eb',
    color: colors['colors-text-semantic-colors-success-1'],
  },
  [Status.ACTIVE]: {
    backgroundColor: colors['colors-background-neutral-1'],
    borderColor: colors['colors-background-accent-1'],
    color: colors['colors-text-main-1'],
  },
  [Status.ENABLED]: {
    backgroundColor: colors['colors-background-neutral-1'],
    borderColor: colors['colors-background-neutral-1'],
    color: colors['colors-text-main-2'],
  },
  [Status.DISABLED]: {
    backgroundColor: colors['colors-background-neutral-1'],
    borderColor: colors['colors-background-neutral-1'],
    color: colors['colors-text-main-4'],
  },
}

export const StepItem = styled.div<StyledStepItemProps>`
  background-color: ${props => colorMapping[props.status].backgroundColor};
  border-radius: ${borderRadius[2]};
  border: 1px solid ${props => colorMapping[props.status].borderColor};
  color: ${props => colorMapping[props.status].color};
  cursor: ${props => (props.status !== Status.DISABLED ? 'pointer' : 'default')};
  display: flex;
  flex-direction: row;
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.medium};
  justify-content: space-between;
  margin: ${spacing[2]} 0;
  padding: ${spacing[4]};
  width: 100%;
`
