import styled from 'styled-components'

import theme from '@/shared/theme'

const TITLE_COLOR = theme.primitiveTokens.colors.gray[80]

type TitleProps = {
  color?: string
}

export const Title = styled.p<TitleProps>`
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 700;
  color: ${props => props.color ?? TITLE_COLOR};
`
