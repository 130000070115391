import { Today, CompareArrows, ChatBubble, LibraryBooks } from '@material-ui/icons'
import { Drawer, ActionDrawerHeader, Typography } from '@olaisaac/design-system'

import { DrawerContent, InfoItem, ContactInfo, Link } from './styles'

type IsaacPayPayoutReportOnboardingDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

/**
 * Onboarding drawer for isaacPay payout report page
 *
 * @param props
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.onClose Callback function called on request close
 */
export const IsaacPayPayoutReportOnboardingDrawer = ({
  isOpen,
  onClose,
}: IsaacPayPayoutReportOnboardingDrawerProps) => {
  const handleNavigateToFaq = () => {
    window.open('https://centraldeajuda.olaisaac.io/repasse', '_blank') // TODO: Move URL to env var
  }

  return (
    <Drawer open={isOpen} appearance="secondary">
      <ActionDrawerHeader title="Entenda seu repasse" appearance="secondary" onClose={onClose} />

      <DrawerContent>
        <InfoItem>
          <Today />
          <h2>Transferência diária</h2>
          <p>
            <strong>Todo dia</strong>, o isaac vai repassar os valores referentes aos pagamentos
            realizados pelas famílias.
          </p>
          <p>
            Aos finais de semana o repasse será feito na segunda-feira. Nos feriados, o repasse será
            feito no dia útil seguinte.
          </p>
        </InfoItem>

        <InfoItem>
          <CompareArrows />
          <h2>Parcelas pagas pelas famílias</h2>
          <p>
            Seu repasse é{' '}
            <strong>feito somente após o pagamento das faturas pelas famílias.</strong>
          </p>
        </InfoItem>

        <InfoItem data-testid="isaac-pay-disclaimer-section">
          <Typography variation="bodySmall" withoutMargin>
            Nos dias em que nenhuma parcela for paga pelas famílias, não haverá transferência. Caso
            seu repasse tenha valor total negativo, ele será descontado do seu próximo dia.
          </Typography>
        </InfoItem>

        <InfoItem data-testid="glossary-section">
          <LibraryBooks />
          <h2>Glossário do repasse</h2>
          <p>
            Não entendeu algum termo ou conceito do seu repasse? Não se preocupe,{' '}
            <Link onClick={handleNavigateToFaq}>acesse o glossário do repasse</Link> e veja as
            definições!
          </p>
        </InfoItem>

        <ContactInfo>
          <ChatBubble />
          <h2>Ainda tem dúvidas?</h2>
          <p className="label">Entre em contato com o Apoio isaac</p>
          <p className="contact">WhatsApp: (11) 97876-5797</p>
          <p className="contact">falecom@isaac.com.br</p>
        </ContactInfo>
      </DrawerContent>
    </Drawer>
  )
}
