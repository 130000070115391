import { useState } from 'react'

import InstallmentDrawer from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/escolas/components/modal/ConfirmationDialog'
import { DrawerState } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/constants'

export type ManualLiquidationDrawerProps = {
  contractCreatedAt: string
  isOpen: boolean
  onClose: () => void
  onSuccess?: () => void
  orderReference: string
  receivableId: uuid
}

export const ManualLiquidationDrawer = (props: ManualLiquidationDrawerProps) => {
  const { isOpen, orderReference, receivableId, onClose, onSuccess } = props

  const [, setSelectedReceivableId] = useState<string>(receivableId)
  const [resetManualLiquidationForm, setResetManualLiquidationForm] = useState<boolean>(false)
  const [isFailureFeedbackOpen, setIsFailureFeedbackOpen] = useState<boolean>(false)

  const handleInstallmentDrawerClose = () => {
    setSelectedReceivableId(null)
    onClose()
  }

  const resetManualLiquidationFormErrors = () => {
    setResetManualLiquidationForm(true)
    setIsFailureFeedbackOpen(false)
  }

  return (
    <>
      <InstallmentDrawer
        selectedInstallmentHasError={false}
        setShowInvoicesNotGeneratedDialog={() => null}
        isOpen={isOpen && !!receivableId}
        onClose={handleInstallmentDrawerClose}
        selectedReceivableId={receivableId}
        orderReference={orderReference}
        setSelectedReceivableId={setSelectedReceivableId}
        resetManualLiquidationForm={resetManualLiquidationForm}
        setIsFailureFeedbackOpen={setIsFailureFeedbackOpen}
        initialDrawerState={DrawerState.MANUAL_LIQUIDATION}
        onSuccess={onSuccess}
      />
      <FailureFeedbackDialog
        buttonLabel="Voltar"
        isVisible={isFailureFeedbackOpen}
        onClose={resetManualLiquidationFormErrors}
        submitHandler={resetManualLiquidationFormErrors}
        title="Volte e registre novamente"
        centered
      >
        <FailureFeedbackContent message="Houve uma falha inesperada e não conseguimos registrar o recebimento." />
      </FailureFeedbackDialog>
    </>
  )
}
