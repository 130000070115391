import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'
import theme from 'src/shared/theme'

const { spacing } = theme.primitiveTokens
const { color, variation } = theme.humanizedTokens.components.typography

export const Container = styled.div`
  position: absolute;
  width: 100vw;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 150px;
  align-items: center;
  height: 100vh;
`

export const InfoContainer = styled.div`
  text-align: center;

  .MuiButtonBase-root {
    width: fit-content;
    max-width: 315px;
    min-width: 168px;
    white-space: nowrap;
  }
`

export const Title = styled(Typography)`
  ${variation.headlineDesktopSmall};
  ${color.primary}
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0;
  margin: ${spacing[10]} ${spacing[4]} ${spacing[2]} ${spacing[4]};
`

export const Explanation = styled(Typography)`
  ${variation.bodyLarge};
  ${color.secondary}
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: ${spacing[10]};
  padding: 0 10%;
`
