import styled from 'styled-components'
import Container from '@material-ui/core/Container'

export const StyledMain = styled.main`
  background-color: white;
  height: 100vh;
  position: relative;
  overflow: hidden;
`

export const StyledGraphism = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  @media (max-width: 600px) {
    bottom: -150px;
  }
`

export const StyledContainer = styled(Container)`
  padding-left: 24px;
  padding-right: 24px;
`
