import React from 'react'
import * as Styled from './styles'
import { Badge } from '@gravity/badge'
import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'
import { formatCentsToReal } from '@/shared/utils'
import { mdiInformationOutline } from '@mdi/js'
import { IconButton } from '@gravity/icon-button'

type InfoMouseOverPopoverProps = {
  totalOverdueAmount: number
  totalUpToDateAmount: number
}

export const InfoMouseOverPopover: React.FC<InfoMouseOverPopoverProps> = ({
  totalOverdueAmount,
  totalUpToDateAmount,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Styled.Wrapper>
      <IconButton
        aria-owns={open ? 'info-mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        size={1}
        variant="ghost"
      >
        <Styled.Icon path={mdiInformationOutline} />
      </IconButton>

      <Styled.Container id="info-mouse-over-popover" open={open}>
        <Styled.Content>
          <Badge color="error" variant="soft" size={1}>
            Vencida
          </Badge>
          <Text variant="subtitle-medium">{formatCentsToReal(totalOverdueAmount)}</Text>
        </Styled.Content>
        <Separator color="neutral-2" orientation="horizontal" />
        <Styled.Content>
          <Badge color="accent" variant="soft" size={1}>
            A vencer
          </Badge>
          <Text variant="subtitle-medium">{formatCentsToReal(totalUpToDateAmount)}</Text>
        </Styled.Content>
      </Styled.Container>
    </Styled.Wrapper>
  )
}
