import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const useIsPageEnabled = () => {
  const isAddInstallmentEnabled = useUnleashFlag(
    UnleashFlags.MAT_474_ENABLE_CAMPAIGN_ADD_INSTALLMENTS
  )

  const history = useHistory()

  const { schoolSlug } = useSelectedSchool()

  const redirect = () => {
    history.replace(`/${schoolSlug}/matriculas`)
  }

  useEffect(() => {
    if (!isAddInstallmentEnabled) {
      redirect()
    }
  }, [isAddInstallmentEnabled])
}
