import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import styled from 'styled-components'

export const PreContractEditSectionBox = styled(Box)`
  margin: 24px 0;
`

export const PreContractEditGrid = styled(Grid).attrs({
  mb: 48,
  container: true,
  spacing: 10,
})``

export const PreContractEditGridItem = styled(Grid).attrs({ item: true, xs: 8 })``

export const PreContractEditGridDrawer = styled(Grid).attrs({ item: true, xs: 4 })``
