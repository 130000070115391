import { Switch, useRouteMatch } from 'react-router-dom'
import ContractListRoute2021 from 'src/escolas/router/[schoolSlug]/contratos/2021'
import ContractListRoute2021Checkout from 'src/escolas/router/[schoolSlug]/contratos/2021/checkout'
import ContractDetailsRoute from 'src/escolas/router/[schoolSlug]/contratos/[contractId]'
import NewContractPageRoute from 'src/escolas/router/[schoolSlug]/contratos/novo'
import { useJWT } from 'src/shared/hooks'
import { Breadcrumb } from 'src/escolas/contexts/BreadcrumbsContext'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'

export default () => {
  const { path, url } = useRouteMatch()
  const { isAdmin } = useJWT()

  const isCheckoutForBackofficeEnabled = useUnleashFlag(
    UnleashFlags.PAYMENTS_PAYM377_ENABLE_CHECKOUT_FOR_BACKOFFICE
  )

  const breadcrumbs: Breadcrumb[] = [{ path: url, name: 'Consultar contratos' }]

  const ContractListRoute =
    !isAdmin || (isAdmin && isCheckoutForBackofficeEnabled)
      ? ContractListRoute2021Checkout({ path, breadcrumbs })
      : ContractListRoute2021({ path, breadcrumbs })

  return (
    <Switch>
      {/* This is not the same as <ContractDetailsRoute path={path} /> dont try to change it 😅
     More information: https://github.com/OlaIsaac/backoffice/pull/71 */}
      {NewContractPageRoute({ path, breadcrumbs })}
      {ContractListRoute}
      {ContractDetailsRoute({ path, breadcrumbs })}
    </Switch>
  )
}
