import { colors } from '@gravity/tokens'
import styled from 'styled-components'

const StyledSpan = styled.span`
  height: 8px;
  width: 8px;
  flex-shrink: 0;
  border-radius: 50%;
  display: inline-block;
  &.success {
    background-color: ${({ theme }) => theme.palette.success.main};
  }
  &.error {
    background-color: ${({ theme }) => theme.palette.error.main};
  }
  &.primary {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
  &.warning {
    background-color: ${({ theme }) => theme.palette.warning.main};
  }
  &.grey {
    background-color: ${colors['colors-background-neutral-4']};
  }
  &.darkGrey {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[80]};
  }
`

export type StatusBadgeColor = 'success' | 'error' | 'primary' | 'warning' | 'grey' | 'darkGrey'

export type StatusBadgeProps = {
  color: StatusBadgeColor
}

const StatusBadge = ({ color }: StatusBadgeProps) => (
  <StyledSpan className={color} data-testid="status-badge" />
)

export default StatusBadge
