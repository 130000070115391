import { useApi } from '@/shared/hooks'
import { creditService } from '@/shared/services/credit'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { Eligibility } from '@/shared/services/credit/types'

export const creditEligibilityQueryKey = 'credit-elegibility'

export const useGetSchoolEligibility = (
  schoolId: string,
  options?: UseQueryOptions<Eligibility>
) => {
  const { apiClient } = useApi()
  const service = creditService(apiClient.getClients().bffApi)

  return useQuery<Eligibility>(
    [creditEligibilityQueryKey, schoolId],
    async () => {
      const { data } = await service.getSchoolEligibility(schoolId)
      return data
    },
    options
  )
}
