import { Box } from '@material-ui/core'
import { Notification } from '@olaisaac/design-system'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import InputDueDate from '@/modules/guardians/Negotiation/components/Inputs/InputDueDate'
import InputInstallments from '@/modules/guardians/Negotiation/components/Inputs/InputInstallments'
import InputMinDown from '@/modules/guardians/Negotiation/components/Inputs/InputMinDown'
import { SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import { getEndOfToday, getStartOfToday } from 'src/shared/utils'
import { getNotification } from '@/modules/guardians/Negotiation/helpers/getNotification'

import { FormPropsWithAgreement } from './types'
import { CustomChip } from './styles'

const BankSlipPixForm = ({
  handleValidate,
  agreement,
  handleValue,
  paymentPlan,
}: FormPropsWithAgreement) => {
  const [minDownPayment, setMinDownPayment] = useState<number>(agreement.total_amount)

  const [installments, setInstallments] = useState<number>(0)

  const [paymentType, setPaymentType] = useState<'ONE_LUMP_SUM' | 'INSTALLMENTS'>('ONE_LUMP_SUM')

  const minDate = getStartOfToday().add(1, 'day').toDate()
  const maxDate = getStartOfToday().add(7, 'day').toDate()

  const [dueDate, setDueDate] = useState<Date | null>(minDate)

  useEffect(() => {
    let isValid = true
    if (
      Number.isNaN(minDownPayment) ||
      minDownPayment === undefined ||
      minDownPayment < paymentPlan.min_down_payment ||
      minDownPayment > agreement.total_amount
    ) {
      isValid = false
    }

    if (installments > paymentPlan.max_installments_allowed || installments < 0) {
      isValid = false
    }

    if (
      !dayjs(dueDate).isValid() ||
      dayjs(dueDate).isBefore(getEndOfToday()) ||
      dayjs(dueDate).isAfter(getEndOfToday().add(7, 'day').toDate())
    ) {
      isValid = false
    }

    if (isValid) {
      handleValue({
        dueDate,
        installments,
        minDownPayment,
        paymentOption: SimulationPaymentMethod.BANK_SLIP,
      })
    }
    handleValidate(isValid)
  }, [minDownPayment, installments, dueDate])

  const getResultantAmount = () => {
    if (
      !minDownPayment ||
      minDownPayment > agreement.total_amount ||
      minDownPayment < paymentPlan.min_down_payment
    )
      return 0

    return Number(agreement.total_amount - minDownPayment)
  }

  const canPayInInstallments = Number(agreement.total_amount - paymentPlan.min_down_payment) > 0

  const showInstallment = canPayInInstallments
    ? paymentType === 'INSTALLMENTS'
    : canPayInInstallments

  const firstPaymentInputTitle = showInstallment ? 'Defina o valor de entrada' : 'Valor Total'
  const firstPaymentInputLabel = showInstallment ? 'Valor da entrada' : ''
  const dueDateInputLabel = showInstallment ? 'Data de vencimento da entrada' : 'Data de vencimento'

  const valueNotification = getNotification(paymentPlan.min_down_payment, agreement.total_amount)

  const isOneLumpPayment = agreement.total_amount === paymentPlan.min_down_payment

  const maxInstallmentAllowed = paymentPlan.max_installments_allowed - 1

  const shouldShowNotification = !canPayInInstallments

  const handlePaymentTypeChange = (type: 'ONE_LUMP_SUM' | 'INSTALLMENTS') => {
    setPaymentType(type)

    if (type === 'ONE_LUMP_SUM') {
      setInstallments(0)
      setMinDownPayment(agreement.total_amount)
    } else {
      setInstallments(1)
      setMinDownPayment(paymentPlan.min_down_payment)
    }
  }

  return (
    <Box display="flex" flexWrap="wrap" marginLeft={8}>
      {shouldShowNotification && (
        <Box marginTop={1} width="100%">
          <Notification {...valueNotification} />
        </Box>
      )}

      {canPayInInstallments && (
        <Box display="flex" flexDirection="row" mt={3} mb={0} width="100%">
          <CustomChip
            label="Pagamento à vista"
            clickable
            variant={paymentType === 'ONE_LUMP_SUM' ? 'default' : 'outlined'}
            onClick={() => handlePaymentTypeChange('ONE_LUMP_SUM')}
          />
          <Box mr={2} />
          <CustomChip
            label="Pagamento parcelado"
            variant={paymentType === 'INSTALLMENTS' ? 'default' : 'outlined'}
            clickable
            onClick={() => handlePaymentTypeChange('INSTALLMENTS')}
          />
        </Box>
      )}

      <InputMinDown
        title={firstPaymentInputTitle}
        label={firstPaymentInputLabel}
        handleValue={setMinDownPayment}
        selectedValue={minDownPayment}
        minAmount={paymentPlan.min_down_payment}
        maxAmount={agreement.total_amount}
        disabled={isOneLumpPayment || paymentType === 'ONE_LUMP_SUM'}
      />
      <InputDueDate
        label={dueDateInputLabel}
        maxDate={maxDate}
        minDate={minDate}
        selectedValue={dueDate}
        handleValue={(date: Dayjs) => setDueDate(date?.toDate() || null)}
      />
      {showInstallment && (
        <InputInstallments
          maxInstallment={maxInstallmentAllowed}
          totalAmount={getResultantAmount()}
          handleValue={setInstallments}
          selectedValue={installments}
        />
      )}
    </Box>
  )
}

export default BankSlipPixForm
