import { PayoutTransfersSectionRoot } from './components/PayoutTransfersSectionRoot'
import { PayoutTransfersSectionCategoryTitle } from './components/PayoutTransfersSectionCategoryTitle'
import { PayoutTransfersSectionSubCategory } from './components/PayoutTransfersSectionSubCategory'
import { PayoutTransferSectionDivider } from './components/PayoutTransferSectionDivider'

/**
 * Component that represents a payout transfer section
 */
export const PayoutTransfersSection = {
  Root: PayoutTransfersSectionRoot,
  CategoryTitle: PayoutTransfersSectionCategoryTitle,
  SubCategory: PayoutTransfersSectionSubCategory,
  Divider: PayoutTransferSectionDivider,
}
