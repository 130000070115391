import styled from 'styled-components'

import { Box } from '@material-ui/core'

export const StyledWrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
