import { InstallmentType } from '../interfaces'
import { BufferInstallmentResponse } from '../interfaces/bufferInstallment'

const DIA = 1000 * 60 * 60 * 24

export const buffer_installment: BufferInstallmentResponse = {
  amount: 100000,
  current_due_payment_discount: 0,
  current_early_payment_discount: 0,
  current_perpetual_discount: 0,
  discounts: [],
  due_date: new Date(new Date().getTime() + 3 * DIA).toISOString(),
  id: '1402b44d-6629-485a-bdc1-58c854de534c',
  original_amount: 100000,
  type: InstallmentType.TUITION,
}
