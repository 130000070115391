import { useApi } from '@/shared/hooks'
import { useMutation } from '@tanstack/react-query'
import { ChooseOfferRequest } from '@/shared/services/credit/types'
import { creditService } from '@/shared/services/credit'

export const chooseOfferQueryKey = 'choose-offer'

export const useChooseOffer = () => {
  const { apiClient } = useApi()
  const service = creditService(apiClient.getClients().bffApi)

  return useMutation([chooseOfferQueryKey], async (body: ChooseOfferRequest) =>
    service.chooseOffer(body)
  )
}
