import { useEffect } from 'react'
import RouterBreadcrumbs from '@/escolas/components/RouterBreadcrumbs'
import { useBreadcrumbs } from '@/escolas/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { ENROLLMENT_PAGE_BASE_PATH } from '@/modules/enrollment/constants/enrollment'

type PageBreadcrumbsProps = {
  studentName: string
}

export const PageBreadcrumbs = ({ studentName }: PageBreadcrumbsProps) => {
  const { schoolSlug } = useSelectedSchool()
  const { updateBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    updateBreadcrumbs([
      {
        name: `Matrículas`,
        path: `/${schoolSlug}/${ENROLLMENT_PAGE_BASE_PATH}`,
      },
      {
        name: studentName,
        path: '',
      },
    ])
  }, [])

  return <RouterBreadcrumbs />
}
