import {
  FailureFeedbackDialog,
  FailureFeedbackContent,
} from '@/escolas/components/modal/ConfirmationDialog'
import StatusDialog from '@/modules/guardians/Negotiation/components/StatusDialog'

const variantStatus = {
  starting_duplicate: {
    title: 'Carregando informações de pagamento da entrada',
  },
  starting_checkout: {
    title: 'Comunicando com a maquininha',
  },
  starting_checkout_v2: {
    title: 'Gerando fatura para o pagamento',
  },
}

interface EntryStatusDialogProps {
  closeErrorDialog: () => void
  guardianName: string
  isOpen: boolean
  posName: string
  shortId?: string
  showErrorDialog: boolean
  totalAmount: number
  variant: keyof typeof variantStatus
}

const EntryStatusDialog = ({
  showErrorDialog,
  closeErrorDialog,
  shortId,
  variant,
  ...statusDialogProps
}: EntryStatusDialogProps) => {
  const isStartingCheckout = variant !== 'starting_duplicate'

  return (
    <>
      <StatusDialog
        {...statusDialogProps}
        {...variantStatus[variant]}
        isTextVisible={isStartingCheckout}
        shortIds={[shortId]}
        isOverdue={false}
      />
      <FailureFeedbackDialog
        isVisible={showErrorDialog}
        buttonLabel="Tentar novamente"
        onClose={() => closeErrorDialog()}
        submitHandler={() => closeErrorDialog()}
      >
        <FailureFeedbackContent />
      </FailureFeedbackDialog>
    </>
  )
}

export default EntryStatusDialog
