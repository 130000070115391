import { Skeleton } from '@gravity/skeleton'

import { Container } from './styles'

export const LoadingState = () => {
  return (
    <Container>
      <div className="controllers">
        <Skeleton width={102} height={38} />
        <Skeleton width={280} height={38} />
      </div>

      <div className="profile">
        <Skeleton width={24} height={24} radius="full" />

        <div className="user-group">
          <Skeleton width={120} height={16} />
          <Skeleton width={137} height={16} />
        </div>

        <Skeleton width={40} height={40} />
      </div>
    </Container>
  )
}
