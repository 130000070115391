import { DebtStatus } from '@/shared/interfaces'

export interface DebtStatusProps {
  status: DebtStatus
}
export const debtStatusVariant = {
  WARNING: { color: 'warning' as const, text: 'Atenção' },
  UP_TO_DATE: { color: 'success' as const, text: 'Em dia' },
  PENDING: { color: 'accent' as const, text: 'Aguardando' },
  OVERDUE: { color: 'error' as const, text: 'Com pendências' },
}
