export function ProviderComposer({
  children,
  providers,
}: {
  children: React.ReactNode
  providers: Array<React.ComponentType<any>>
}) {
  return (
    <>
      {providers.reduceRight((componentTree, Provider) => {
        return <Provider>{componentTree}</Provider>
      }, children)}
    </>
  )
}
