import { spacing } from '@gravity/tokens'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing[2]};
  margin-top: ${spacing[4]};
`
