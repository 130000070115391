import styled from 'styled-components'

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: end;
`

export const TableFooterContainer = styled.div`
  display: flex;
  gap: 32px;
  justify-content: end;
`
