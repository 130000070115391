import { ChoiceChip } from '@gravity/chip'
import { Container } from './styles'
import { Control, useFieldArray } from 'react-hook-form'
import { filterForm } from '../../form/filtersSchema'

interface Props {
  control: Control<filterForm>
  name: 'category' | 'status'
}

export const Filters = ({ control, name }: Props) => {
  const { fields, update } = useFieldArray({
    control,
    name,
  })

  const handleChoiceSelected = (index: number) => {
    fields.forEach((value, idx) => {
      update(idx, {
        ...fields[idx],
        selected: idx === index && !fields[idx].selected,
      })
    })
  }

  return (
    <Container>
      {fields.map((field, index) => (
        <ChoiceChip
          key={field.value}
          text={field.text}
          value={field.value}
          selected={field.selected}
          onSelected={() => handleChoiceSelected(index)}
        />
      ))}
    </Container>
  )
}
