import { GuardianFinanceSummaryStatus } from '@/modules/guardians/GuardianDetails/constants'

export enum ValidColors {
  ACCENT = 'accent',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export const cardInfos = (installmentQuantity: number, currentMonth: string) => ({
  badgeText: {
    IN_NEGOTIATION: 'Negociação',
    OPEN: 'A vencer',
    OVERDUE: 'Vencida',
    PAID: 'Pago',
  },
  badgeColor: {
    IN_NEGOTIATION: ValidColors.WARNING,
    OPEN: ValidColors.ACCENT,
    OVERDUE: ValidColors.ERROR,
    PAID: ValidColors.SUCCESS,
  },
  cardDescription: {
    IN_NEGOTIATION:
      installmentQuantity > 1
        ? `${installmentQuantity} Faturas de entrada de negociação estão aguardando pagamento`
        : `${installmentQuantity} Fatura de entrada de negociação está aguardando pagamento`,
    OPEN:
      installmentQuantity > 1
        ? `${installmentQuantity} Faturas que vencem em ${currentMonth}`
        : `${installmentQuantity} Fatura que vence em ${currentMonth}`,
    OVERDUE:
      installmentQuantity > 1
        ? `${installmentQuantity}  Faturas vencidas e acumuladas para ${currentMonth}`
        : `${installmentQuantity}  Fatura vencida para ${currentMonth}`,
    PAID:
      installmentQuantity > 1
        ? `${installmentQuantity} Faturas de ${currentMonth} pagas`
        : `${installmentQuantity} Fatura de ${currentMonth} paga`,
  },
})

export type GuardianInstallmentFilterObjectKeys = 'installmentStatus'

export type GuardianInstallmentFilterQueryParams = 'installment_status'

export type GuardianInstallmentFilterType = {
  installmentStatus: GuardianFinanceSummaryStatus[]
}
