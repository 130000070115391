import { PreContractStatuses as ContractStatusType } from '@/shared/interfaces'
import { Typography } from '@olaisaac/design-system'
import { Tooltip } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { contractStatusDict } from './constants'
import { Container } from './styles'

export const ContractStatusBadge: React.FC<{
  enableTooltip?: boolean
  status: ContractStatusType
}> = ({ status, enableTooltip = true }) => {
  return (
    <Tooltip disableHoverListener={!enableTooltip} title={`${contractStatusDict[status]?.label}`}>
      <Container className={`${contractStatusDict[status]?.color}`}>
        <Typography variation="caption"> {contractStatusDict[status]?.text}</Typography>
        {enableTooltip && <InfoOutlinedIcon fontSize="small" />}
      </Container>
    </Tooltip>
  )
}
