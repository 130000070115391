import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'
import { mdiPencil, mdiTrashCan } from '@mdi/js'
import { Icon } from '@mdi/react'

type RowActionsProps = {
  onRequestEdit: () => void
  onRequestRemove: () => void
}

export const RowActions = ({ onRequestEdit, onRequestRemove }: RowActionsProps) => {
  return (
    <>
      <Tooltip text="Editar">
        <IconButton variant="ghost" size={2} onClick={() => onRequestEdit()} data-testid="edit">
          <Icon path={mdiPencil} />
        </IconButton>
      </Tooltip>

      <Tooltip text="Excluir">
        <IconButton variant="ghost" size={2} onClick={() => onRequestRemove()} data-testid="remove">
          <Icon path={mdiTrashCan} />
        </IconButton>
      </Tooltip>
    </>
  )
}
