import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { Divider as RootDivider } from '@olaisaac/design-system'

import { spacing } from '@gravity/tokens'

export const Divider = styled(RootDivider)`
  margin: 0;
`

export const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4]};
`

export const HeaderContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${spacing[3]};
`

export const HeaderSkeletonGroup = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${spacing[1]};
`

export const ContentWrapper = styled(Box)`
  padding: ${spacing[6]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[6]};
`

export const FooterWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${spacing[3]};
`

export const RowWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: ${spacing[3]};
`
