import theme from '@/shared/theme'
import styled from 'styled-components'

const accentColor = theme.humanizedTokens.components.typography.color.accent.color

export const Button = styled.button`
  display: flex;
  align-items: center;
  color: ${accentColor};
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;

  > p {
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: 500;
  }

  > svg {
    margin-left: 0.5rem;
  }
`
