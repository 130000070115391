import React from 'react'

import { IconButton } from '@olaisaac/design-system'
import { Menu, MenuItem, Tooltip, Box } from '@material-ui/core'
import { mdiDotsVertical, mdiInformationOutline } from '@mdi/js'
import { Icon } from '@mdi/react'

import { Text } from '@gravity/text'

interface InstallmentDrawerActionsItemProps {
  disabled?: boolean
  helpDescription?: string
  hidden?: boolean
  onClick: () => void
  title: string
}

interface InstallmentDrawerActionsProps {
  items: InstallmentDrawerActionsItemProps[]
}

export const InstallmentDrawerMenu = ({ items }: InstallmentDrawerActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const visibleMenuItems = items.filter(({ hidden }) => !hidden)

  if (visibleMenuItems.length <= 0) {
    return null
  }

  return (
    <>
      <IconButton
        variation="ghost"
        aria-label="mais ações"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        data-testid="more-actions"
      >
        <Icon size={2} path={mdiDotsVertical} />
      </IconButton>
      <Menu id="long-menu" keepMounted anchorEl={anchorEl} open={open} onClose={handleClose}>
        {visibleMenuItems.map(({ title, disabled, onClick, helpDescription }, key) => {
          if (helpDescription) {
            return (
              <Tooltip key={key} title={helpDescription}>
                <Box>
                  <MenuItem disabled={disabled} onClick={onClick}>
                    <Box display="flex" flex={1} justifyContent="space-between">
                      <Text>{title}</Text>
                      <Icon size={0.9} path={mdiInformationOutline} />
                    </Box>
                  </MenuItem>
                </Box>
              </Tooltip>
            )
          }

          return (
            <MenuItem key={key} disabled={disabled} onClick={onClick}>
              <Text>{title}</Text>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
