import { useState } from 'react'

import { Installment } from '@/modules/contract/services/types'
import { EditAmountForm } from '../components/EditAmountDialog'
import { applyPercentageDiscount } from '../utils'
import { AddDiscountForm } from '../components/AddDiscountDialog'
import dayjs from 'dayjs'
import { InstallmentStatuses } from '@/shared/interfaces'

export function useUpdateInstallments(originalInstallments: Installment[]) {
  const [selectedInstallmentIds, setSelectedInstallmentIds] = useState<uuid[]>([])
  const [updatedInstallments, setUpdatedInstallments] = useState(originalInstallments)

  const updateAmounts = (values: EditAmountForm) => {
    const newInstallments = updatedInstallments.map(installment => {
      if (selectedInstallmentIds.includes(installment.backoffice_installment_id)) {
        const isInstallmentOverdue = installment.status === InstallmentStatuses.OVERDUE

        return {
          ...installment,
          amount: values.amount,
          due_date: isInstallmentOverdue
            ? dayjs().add(1, 'day').toISOString()
            : installment.due_date,
          status: isInstallmentOverdue ? InstallmentStatuses.OPEN : installment.status,
        }
      }

      return installment
    })

    setUpdatedInstallments(newInstallments)
  }

  const addDiscounts = (values: AddDiscountForm, percentMode: boolean) => {
    const newInstallments = updatedInstallments.map(installment => {
      if (selectedInstallmentIds.includes(installment.backoffice_installment_id)) {
        const isInstallmentOverdue = installment.status === InstallmentStatuses.OVERDUE

        return {
          ...installment,
          discounts: [
            ...installment.discounts,
            {
              ...values,
              id: null,
              amount: percentMode
                ? applyPercentageDiscount(values.percentage, installment.amount)
                : values.amount,
              days_before_due_date: Number(values.days_before_due_date),
              type: values.discountRule,
            },
          ],
          due_date: isInstallmentOverdue
            ? dayjs().add(1, 'day').toISOString()
            : installment.due_date,
          status: isInstallmentOverdue ? InstallmentStatuses.OPEN : installment.status,
        }
      }

      return installment
    })

    setUpdatedInstallments(newInstallments)
  }

  const removeDiscounts = (installmentId: uuid, discountIndexToDelete: number) => {
    const newInstallments = updatedInstallments.map(installment => {
      if (installment.backoffice_installment_id !== installmentId) {
        return installment
      }

      return {
        ...installment,
        discounts: installment.discounts.filter((_, index) => index !== discountIndexToDelete),
      }
    })

    setUpdatedInstallments(newInstallments)
  }

  const updateDueDates = (dueDates: datestring[]) => {
    const mapIDToDueDate: Record<uuid, datestring> = {}
    for (let i = 0; i < selectedInstallmentIds.length; i++) {
      mapIDToDueDate[selectedInstallmentIds[i]] = dueDates[i]
    }

    const newInstallments = updatedInstallments.map(installment => {
      if (installment.backoffice_installment_id in mapIDToDueDate) {
        return {
          ...installment,
          due_date: mapIDToDueDate[installment.backoffice_installment_id],
        }
      }

      return installment
    })

    setUpdatedInstallments(newInstallments)
  }

  return {
    setUpdatedInstallments,
    selectedInstallmentIds,
    setSelectedInstallmentIds,
    updatedInstallments,
    updateAmounts,
    removeDiscounts,
    updateDueDates,
    addDiscounts,
  }
}
