import { LoadingBox } from '@/shared/components/LoadingBox'
import { useParams } from 'react-router-dom'
import ManualLiquidation from './ManualLiquidation'
import { CircularProgress } from '@material-ui/core'
import useGuardiansInstallmentsByUrnQuery from '../hooks/manualLiquidation/useGuardianInstallmentsQueryByUrn'
import { useProducts } from '@/modules/products/hooks/queries/products'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { Product } from '@/modules/products/models/Product'
import { InstallmentV2 } from '../../services/types'

const ManualLiquidationAdd = () => {
  const { guardianId, urn } = useParams<{ guardianId: uuid; urn: uuid }>()
  const { school } = useSelectedSchool()

  const {
    guardiansInstallments,
    isFetchGuardiansInstallmentsLoading,
  } = useGuardiansInstallmentsByUrnQuery({
    guardianId,
    urns: [urn],
  })

  const installment = guardiansInstallments?.data?.[0]
  const productIds = installment?.products.map(p => p.id)

  const { data: productsData, isLoading: productsLoading } = useProducts({
    schoolId: school?.id || '',
    pagination: { page: 1, per_page: 100 },
    ids: productIds,
  })

  const product = productsData?.data?.[0]

  const isLoading = isFetchGuardiansInstallmentsLoading || productsLoading

  return (
    <>
      {isLoading ? (
        <LoadingBox>
          <CircularProgress />
        </LoadingBox>
      ) : (
        <ManualLiquidation
          product={product as Product}
          installment={installment as InstallmentV2}
          isFetchGuardiansInstallmentsLoading={isLoading}
        />
      )}
    </>
  )
}

export default ManualLiquidationAdd
