import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { Installment } from '../GuardianDetails/types'
import { compose, prop, sortBy, toLower } from 'ramda'
import { MIN_CART_ITEMS } from '../GuardianDetails/constants'

type CheckoutCartContextType = {
  addInstallment: (installment: Installment) => void
  addInstallments: (installments: Installment[]) => void
  checkoutCart: Installment[]
  clearCheckoutCart: () => void
  removeInstallment: (installmentId: uuid) => void
  totalAmount: cents
}

export const CheckoutCartStore = createContext<CheckoutCartContextType>(
  {} as CheckoutCartContextType
)

CheckoutCartStore.displayName = 'Checkout Cart Store'

const sortInstallmentByKey = (key: string) => sortBy(compose(toLower, prop(key)))

export const useCheckoutCartContext = () => {
  const [checkoutCart, setCheckoutCart] = useState<Installment[]>([])
  const [totalAmount, setTotalAmount] = useState<cents>(0)

  const addInstallments = useCallback(
    (installments: Installment[] = []) => {
      setCheckoutCart(sortInstallmentByKey('due_date')([...installments]))
    },
    [checkoutCart]
  )

  const addInstallment = useCallback(
    (installment: Installment) => {
      if (
        checkoutCart.findIndex(
          ({ backoffice_installment_id }) =>
            backoffice_installment_id === installment.backoffice_installment_id
        ) > -1
      )
        return

      if (checkoutCart && checkoutCart.length >= MIN_CART_ITEMS) {
        setCheckoutCart(checkoutCart =>
          sortInstallmentByKey('due_date')([...checkoutCart, installment])
        )
      } else {
        setCheckoutCart([installment])
      }
    },
    [checkoutCart]
  )

  const removeInstallment = useCallback(
    (installmentId: uuid) => {
      setCheckoutCart(checkoutCart?.filter(i => i.backoffice_installment_id !== installmentId))
    },
    [checkoutCart]
  )

  const clearCheckoutCart = useCallback(() => {
    setCheckoutCart([])
  }, [setCheckoutCart])

  useEffect(() => {
    if (checkoutCart?.length) {
      setTotalAmount(checkoutCart?.reduce((acc, { amount }) => amount + acc, 0))
    } else {
      setTotalAmount(0)
    }
  }, [checkoutCart])

  return {
    addInstallments,
    addInstallment,
    checkoutCart,
    clearCheckoutCart,
    removeInstallment,
    totalAmount,
  }
}

export const CheckoutCartProvider = ({ children }) => {
  const {
    addInstallments,
    addInstallment,
    checkoutCart,
    clearCheckoutCart,
    removeInstallment,
    totalAmount,
  } = useCheckoutCartContext()

  const contextValue = useMemo(
    () => ({
      addInstallments,
      addInstallment,
      checkoutCart,
      clearCheckoutCart,
      removeInstallment,
      totalAmount,
    }),
    [
      addInstallments,
      addInstallment,
      checkoutCart,
      clearCheckoutCart,
      removeInstallment,
      totalAmount,
    ]
  )

  return <CheckoutCartStore.Provider value={contextValue}>{children}</CheckoutCartStore.Provider>
}
