import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { School } from '@/shared/models/School'

export const useIsaacPayOutsourcedPaymentProvider = (school?: School) => {
  const isFeatureFlagOutsourcedProviderEnabled = useUnleashFlag(
    UnleashFlags.ISAAC_PAY_102_ENABLE_EXTERNAL_PAYOUT_TABLE
  )

  return (
    (school?.school_charge_config?.is_outsourced_provider ?? false) &&
    isFeatureFlagOutsourcedProviderEnabled
  )
}
