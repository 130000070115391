import { FC } from 'react'
import { formatCentsToReal } from '@/shared/utils'
import * as S from './styles'
import { HelpButton } from '../HelpButton'
import { StepFooterProps } from './types'
import { Typography, Grid, ButtonGroup, Button } from '@olaisaac/design-system'

const StepFooter: FC<StepFooterProps> = ({
  showHelpButton = false,
  stepName,
  totalAmount,
  goBack,
  isLoading,
  onFinish,
  disableBack,
  disableConfirm,
  helpButtonEntry,
}) => (
  <S.Wrapper>
    {showHelpButton && (
      <HelpButton eventLocationName={stepName} helpButtonEntry={helpButtonEntry} />
    )}
    {totalAmount > 0 && (
      <Grid container justifyContent="center">
        <Grid item xs={4} sm={5} lg={6}>
          <S.FooterContent>
            <Typography
              withoutMargin
              variation="subtitleDesktopLarge"
              style={{ textAlign: 'start' }}
            >
              Valor a pagar:
            </Typography>
            <Typography withoutMargin variation="subtitleDesktopLarge" style={{ textAlign: 'end' }}>
              {formatCentsToReal(totalAmount)}
            </Typography>
          </S.FooterContent>
        </Grid>
      </Grid>
    )}

    <S.FooterBtnWrapper>
      <ButtonGroup>
        <Button variation="ghost" disabled={disableBack} onClick={goBack}>
          Voltar
        </Button>
        <Button
          variation="primary"
          disabled={disableConfirm}
          loading={isLoading}
          onClick={onFinish}
        >
          Continuar
        </Button>
      </ButtonGroup>
    </S.FooterBtnWrapper>
  </S.Wrapper>
)

export default StepFooter
