import type {
  FilterQueryParams as TuitionFilterQueryParams,
  Filter as TuitionPayoutReportFilter,
} from '@/modules/report/hooks/useTuitionPayoutReportFilter/types'
import type {
  FilterQueryParams as EnrollmentFilterQueryParams,
  Filter as EnrollmentPayoutReportFilter,
} from '@/modules/report/hooks/useEnrollmentPayoutReportFilter/types'

export const FILTER_KEY_TO_FILTER_QUERY_PARAMS: Record<
  keyof TuitionPayoutReportFilter | keyof EnrollmentPayoutReportFilter,
  TuitionFilterQueryParams | EnrollmentFilterQueryParams
> = {
  amountSigns: 'amount_signs',
  events: 'events',
  products: 'products',
  referenceYears: 'reference_years',
}
