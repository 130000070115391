import styled from 'styled-components'
import { spacing } from '@gravity/tokens'

export const NegotiationTableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  margin-bottom: ${spacing[6]};

  padding: 1px;

  align-items: start;
`
