export enum EventIdentifierName {
  CLOSE_PAYMENT_DISPUTE_ITEM_DENY_MODAL = 'fechar_modal_negar_cobranca_indevida',
  COPY_LINK_BANK_SLIP_PAYMENT = 'copiar_link_boleto',
  FORWARD_PAYMENT_STEP = 'pular_pagamento',
  MODAL_CANCEL_NEGOTIATION = 'modal_cancelar_negociacao',
  PAYMENT_DISPUTE_DENY = 'negar_cobranca_indevida',
  PAYMENT_DISPUTE_REGISTER_PAYMENT = 'registrar_recebimento_cobranca_indevida',
  PAYMENT_DISPUTES_PAGE = 'pagina_cobrancas_indevidas',
  SEE_PAYMENT_DISPUTE_DETAILS = 'ver_detalhes_cobranca_indevida',
  SEE_PAYMENT_DISPUTE_RECEIPT = 'ver_anexo_cobranca_indevida',
  SEE_PAYMENT_DISPUTES_PAGE = 'ver_cobrancas_indevidas',
  SHOWED_PAYMENT_DISPUTES_CALLOUT = 'exibiu_callout_cobrancas_indevidas',
  SUBMIT_PAYMENT_DISPUTE_ITEM_DENY_MODAL = 'salvar_negacao_cobranca_indevida',
}
