import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const StatusContainer = styled.div`
  padding-left: 16px;
  &:hover {
    cursor: pointer;
  }
`
