import { useRef } from 'react'

/**
 * Helper hook that returns a boolean the indicates if is the component first render
 */
export const useIsFirstRender = () => {
  const isFirstRender = useRef(true)

  if (isFirstRender.current) {
    isFirstRender.current = false

    return true
  }

  return isFirstRender.current
}
