import { Dayjs } from 'dayjs'
import { CommonMetadata } from './metadata'
import { Discount, Receivable } from './receivable'
import { Contract } from './contract'

export enum InstallmentType {
  ENROLLMENT = 'ENROLLMENT',
  ISAACPAY = 'ISAAC_PAY',
  NEGOTIATION = 'NEGOTIATION',
  TUITION = 'TUITION',
}

export enum InstallmentTypeLabel {
  ENROLLMENT = 'Pré-matrícula',
  NEGOTIATION = 'Negociação',
  TUITION = 'Mensalidade',
}

export enum InstallmentOverviewStatus {
  ALL_PAID = 'ALL_PAID',
  NONE_PENDING = 'NONE_PENDING',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
}

export type Installment = CommonMetadata & {
  amount: cents
  contract?: Contract
  contract_id: uuid
  due_date: datestring
  order_reference?: string
  receivables?: Array<Receivable>
  status: InstallmentStatuses
  type?: InstallmentType
}

export interface TuitionInstallments {
  discounts: Discount[]
  due_day: number
  duration_months: number
  monthly_amount: number
  pre_contract_buffer_id?: uuid
  start_month: datestring
  use_working_dates: boolean
}

export enum InstallmentStatuses {
  AGGLUTINATED = 'AGGLUTINATED',
  CANCELED = 'CANCELED',
  DUE_TODAY = 'DUE_TODAY',
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PAID_AND_CANCELED = 'PAID_AND_CANCELED',
  PENDING = 'PENDING',
  RENEGOTIATED = 'RENEGOTIATED',
}

export type ProcessedInstallment = {
  amount: cents
  children?: ProcessedInstallment[]
  due_date: datestring
  due_month: Dayjs
  id: uuid
  installment_id: uuid
  key: string
  orderReference: string
  status: string
  type?: string
}
