import { useState } from 'react'

export const useDialogVisibility = () => {
  const [dialogVisible, setDialogVisible] = useState(false)
  const closeDialog = () => setDialogVisible(false)
  const openDialog = () => setDialogVisible(true)

  const handleDialogOpenChange = (open: boolean) => {
    if (!open && dialogVisible) closeDialog()
  }

  return {
    dialogVisible,
    closeDialog,
    openDialog,
    handleDialogOpenChange,
  }
}
