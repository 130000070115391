import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { Button, Typography } from '@olaisaac/design-system'

export const StyledButton = styled(Button)``

export const Form = styled.form`
  margin-top: ${({ theme }) => theme.spacing(5)}px;
`

export const TextButton = styled(Typography)`
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
`

export const TextSeePaymentDetail = styled(Typography)`
  text-decoration: underline;
`

export const Wrapper = styled(Grid).attrs(props => ({
  ...props,
  container: true,
  justifyContent: 'center',
}))`
  min-width: 752px;
  margin-bottom: 150px;
`
