import { RadioGroup } from '@material-ui/core'
import { useState } from 'react'
import BoxFooter from '../../BoxFooter/BoxFooter'
import type { AccountFieldProps } from '../types'
import * as S from '../styles'
import { FormControlLabel, Typography } from '@olaisaac/design-system'
import { LiquidationSource } from '@/shared/interfaces'

const AccountField = ({ onSave, onCancel, value }: AccountFieldProps) => {
  const [selectedValue, setSelectedValue] = useState(value)

  const isValid = !!selectedValue
  return (
    <S.StyledBox>
      <S.StyledContent>
        <RadioGroup aria-label="account" name="radio-buttons-account">
          <FormControlLabel
            value={LiquidationSource.ISAAC_ACCOUNT}
            label=""
            control={
              <S.WrapperRadio>
                <S.StyledRadio
                  checked={selectedValue === LiquidationSource.ISAAC_ACCOUNT}
                  onClick={() => setSelectedValue(LiquidationSource.ISAAC_ACCOUNT)}
                />
                <S.TextRadio>
                  <Typography variation="bodyLarge">Conta do isaac</Typography>
                  <Typography variation="bodySmall" color="secondary">
                    O recebimento foi na maquininha isaac, e o valor está na conta do isaac.
                  </Typography>
                </S.TextRadio>
              </S.WrapperRadio>
            }
          />
          <FormControlLabel
            value={LiquidationSource.SCHOOL_ACCOUNT}
            control={
              <S.WrapperRadio>
                <S.StyledRadio
                  checked={selectedValue === LiquidationSource.SCHOOL_ACCOUNT}
                  onClick={() => setSelectedValue(LiquidationSource.SCHOOL_ACCOUNT)}
                />
                <S.TextRadio>
                  <Typography variation="bodyLarge">Conta da Escola</Typography>
                  <Typography variation="bodySmall" color="secondary">
                    O recebimento foi feito na maquininha escola, em dinheiro ou transferência e o
                    valor está na conta da escola.
                  </Typography>
                </S.TextRadio>
              </S.WrapperRadio>
            }
            label=""
          />
        </RadioGroup>
      </S.StyledContent>
      <BoxFooter isValid={isValid} onCancel={onCancel} onSave={() => onSave(selectedValue)} />
    </S.StyledBox>
  )
}

export default AccountField
