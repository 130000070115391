import { useEffect, useState } from 'react'

import { useQuery } from '@/shared/hooks/useQuery'
import { GuardiansInstallmentsQueryParamsNameEnum } from '../constants'
import { InstallmentsFiltersType } from '../types'
import { ContractStatus, InstallmentType } from '@/shared/interfaces'
import { InstallmentStatus } from '../../GuardianDetails/constants'

const parseArrayToFilter = <T>(filter: string, defaultValue?: T[]) => {
  if (!filter || filter === '') {
    if (defaultValue && defaultValue.length > 0) {
      return defaultValue
    }
    return []
  }
  return filter.split(',') as T[]
}

/**
 * @description Hook to handle query params for guardian installments tab
 */
export const useGuardiansInstallmentsQueryParams = (hasDefaultFilters?: boolean) => {
  const defaultFilters: InstallmentsFiltersType = {
    installment_statuses: [],
    installment_types: [],
    reference_years: [],
    student_ids: [],
    product_ids: [],
    contract_status: null,
    negotiation_id: null,
  }

  const { query, setMultipleQueryParams } = useQuery()

  const [filters, setFilters] = useState<InstallmentsFiltersType>({
    installment_statuses: parseArrayToFilter<InstallmentStatus>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.STATUSES),
      hasDefaultFilters ? defaultFilters?.installment_statuses : null
    ),
    installment_types: parseArrayToFilter<InstallmentType>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.TYPES),
      hasDefaultFilters ? defaultFilters?.installment_types : null
    ),
    reference_years: parseArrayToFilter<string>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.YEARS),
      hasDefaultFilters ? defaultFilters?.reference_years : null
    ),
    student_ids: parseArrayToFilter<string>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.STUDENT_IDS),
      hasDefaultFilters ? defaultFilters?.student_ids : null
    ),
    product_ids: parseArrayToFilter<string>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.PRODUCT_IDS),
      hasDefaultFilters ? defaultFilters?.product_ids : null
    ),
    contract_status: (query.get(GuardiansInstallmentsQueryParamsNameEnum.CONTRACT_STATUS) ??
    hasDefaultFilters
      ? defaultFilters?.contract_status
      : null) as ContractStatus,
    negotiation_id: query.get(GuardiansInstallmentsQueryParamsNameEnum.NEGOTIATION_ID),
  })

  const clearFilters = () => {
    setFilters({
      installment_statuses: [],
      installment_types: [],
      reference_years: [],
      student_ids: [],
      product_ids: [],
      contract_status: null,
      negotiation_id: null,
    })
  }

  const updateFilters = (data: InstallmentsFiltersType) => {
    setFilters(data)
  }

  const filtersToQueryParams = (filters: InstallmentsFiltersType) =>
    Object.entries(filters).reduce((prev, [name, value]) => {
      prev.push({ name, value: value && value?.length ? value : null })
      return prev
    }, [])

  useEffect(() => {
    setMultipleQueryParams([...filtersToQueryParams(filters)])
  }, [filters])

  return {
    filters,
    clearFilters,
    updateFilters,
  }
}
