import { Box } from '@material-ui/core'
import { DefaultSkeleton as Skeleton } from './styles'
import { TitleContainer } from '../../styles'

type LoadingStateProps = {
  shouldShowToggle?: boolean
}
/**
 * Loading state for payout report sub header card component
 */
export const LoadingState = ({ shouldShowToggle }: LoadingStateProps) => {
  return (
    <Box display="flex" justifyContent="space-between" data-testid="loading-state">
      <Box>
        <Box display="flex" alignItems="center">
          <Skeleton width={76} height={22} />
        </Box>
        <TitleContainer style={{ marginTop: '0.5rem' }}>
          <Skeleton width={229} height={24} />
        </TitleContainer>
        <Skeleton width={492} height={40} />
      </Box>

      {shouldShowToggle && (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Skeleton width={181} height={32} />
        </Box>
      )}
    </Box>
  )
}
