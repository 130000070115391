import { convertToUTCDate } from '@/shared/utils/convertDateToUTC'

import type { PayoutSummary } from '@/modules/report/models/PayoutSummary'

export const findPayoutByDate = (date: Date, payoutsSummary: PayoutSummary[]) => {
  const payout = payoutsSummary.find(
    item =>
      convertToUTCDate(item.payout_due_date).format('YYYY-MM') ===
      convertToUTCDate(date).format('YYYY-MM')
  )

  return payout
}
