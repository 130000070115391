import { Box } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled(Box)`
  display: flex;
  border-radius: 24px;
  padding: 3px 4px 3px 8px;
  align-items: center;
  width: max-content;

  p {
    margin-right: 4px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &.created {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.yellow[20]};

    p,
    svg {
      color: ${({ theme }) => theme.primitiveTokens.colors.yellow[90]};
    }
  }

  &.effected {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.green[20]};

    p,
    svg {
      color: ${({ theme }) => theme.primitiveTokens.colors.green[90]};
    }
  }
`
