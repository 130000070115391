import { FilterOptionsType } from '../../types'
import {
  ListGuardiansFilterFormType,
  ListGuardiansFilterType,
} from '../components/ListGuardiansFilter/types'

/**
 *
 * @param activeFilters Filters selected by the user.
 * @returns Filters to be shown in the ActiveFilters component.
 */
export const filtersToShow = (activeFilters: ListGuardiansFilterFormType) => {
  return Object.keys(activeFilters).reduce((acc, key) => {
    const filter = activeFilters[key as keyof ListGuardiansFilterType]

    if (filter.length === 0) return acc

    return [
      ...acc,
      ...filter.map(item => ({
        label: item.label,
        value: item.value,
      })),
    ]
  }, [] as FilterOptionsType[])
}
