import { guardiansList, studentList } from '@/shared/mocks'
import { DebtStatus } from '@/shared/interfaces'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants'
import { UseGuardiansQueryResponse } from '../../services/types'

export const getGuardiansMock = (): UseGuardiansQueryResponse => {
  const guardianStudents = studentList.map(student => ({ name: student.name }))
  const guardians = guardiansList.map(guardian => ({
    id: guardian.id,
    name: guardian.name,
    tax_id: guardian.tax_id,
    debt_status: DebtStatus.UP_TO_DATE,
    students: guardianStudents,
  }))

  return {
    data: guardians,
    pagination: {
      page: DEFAULT_PAGE,
      per_page: DEFAULT_PER_PAGE,
      total: guardiansList.length,
    },
  }
}
