import { Box } from '@material-ui/core'
import { CreditCard } from '@material-ui/icons'
import styled from 'styled-components'

export const Card = styled(Box)`
  display: flex;
  padding: 24px 32px 24px 34px;
  flex-direction: column;
`

export const CircleIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecedfb;
  width: 56px;
  min-width: 56px;
  height: 56px;
  margin-right: 32px;
  margin-left: 34px;
  border-radius: 50%;
`

export const StyledCreditCard = styled(CreditCard)`
  color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
`
