import { Skeleton } from '@gravity/skeleton'
import { Box, Grid } from '@material-ui/core'
import {
  RowContainer,
  TitleSkeletonContainer,
  PairSkeletonContainer,
  RowFlexContainer,
  ColumnContainer,
} from './styles'

export const LoadingSimulation = () => {
  return (
    <RowContainer>
      <Box mt={5}>
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <Skeleton radius={8} width={78} />
            <TitleSkeletonContainer>
              <Skeleton radius={8} width={278} />
            </TitleSkeletonContainer>
            <PairSkeletonContainer>
              <Skeleton radius={8} fullWidth />
              <Skeleton radius={8} fullWidth />
            </PairSkeletonContainer>
            <PairSkeletonContainer>
              <Skeleton radius={8} fullWidth />
              <Skeleton radius={8} fullWidth />
            </PairSkeletonContainer>

            <RowFlexContainer>
              <ColumnContainer>
                <Skeleton radius={8} fullWidth />
                <Skeleton radius={8} fullWidth />
              </ColumnContainer>
              <ColumnContainer>
                <Skeleton radius={8} fullWidth />
                <Skeleton radius={8} fullWidth />
              </ColumnContainer>
            </RowFlexContainer>

            <RowFlexContainer>
              <ColumnContainer>
                <Skeleton radius={8} fullWidth />
                <Skeleton radius={8} fullWidth />
              </ColumnContainer>
              <ColumnContainer>
                <Skeleton radius={8} fullWidth />
                <Skeleton radius={8} fullWidth />
              </ColumnContainer>
            </RowFlexContainer>

            <PairSkeletonContainer>
              <Skeleton radius={8} fullWidth />
              <Skeleton radius={8} fullWidth />
            </PairSkeletonContainer>

            <Box mt={6} mb={8}>
              <Skeleton radius={8} fullWidth />
            </Box>

            <RowFlexContainer>
              <Skeleton radius={8} fullWidth />
              <Skeleton radius={8} fullWidth />
            </RowFlexContainer>
          </Grid>
        </Grid>
      </Box>
    </RowContainer>
  )
}
