import { Text } from '@gravity/text'

import { ButtonLinkProps } from './types'
import { Icon, Link } from './styles'

export const ButtonLink = (props: ButtonLinkProps) => {
  const { icon, title, forcedReload = false, ...remainingProps } = props

  return (
    <Link aria-label={title} forcedReload={forcedReload} {...remainingProps}>
      <Icon>{icon}</Icon>
      <Text variant="subtitle-medium" className="title">
        {title}
      </Text>
    </Link>
  )
}
