import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'
import { spacing, colors, fontWeight, fontSize } from '@gravity/tokens'

export const FooterContainer = styled.div`
  margin-top: auto;
`

export const FooterText = styled(Typography)`
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.normal};
  color: ${colors['colors-text-main-3']};
  margin-bottom: ${spacing[3]}; ;
`
