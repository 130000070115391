import { FormEventHandler, useState } from 'react'
import ChangeGuardianHeader from './components/ChangeGuardianHeader'
import { useForm } from 'react-hook-form'
import { GuardianBasicFormInput } from './components/GuardianBasicFormInput'
import { GuardianAddressFormInput } from './components/GuardianAddressFormInput'
import { GuardianEditReasonFormInput } from './components/GuardianEditReasonFormInput'
import { GuardianConditionsFormInput } from './components/GuardianConditionsFormInput'
import ChangeGuardianResume from './components/ChangeGuardianResume'
import { useGetContract } from '@/escolas/hooks/useGetContract'
import { LoadingBox } from '@/shared/components/LoadingBox'
import CircularProgress from '@material-ui/core/CircularProgress'
import { PathParams } from '@/shared/interfaces'
import { useHistory, useParams } from 'react-router-dom'
import { ButtonsBox, ChangeGuardianBox } from './styles'
import { Button, NotificationProps } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'
import { useNavigation } from '@/escolas/hooks'
import {
  changeContractOwnershipResponse,
  useChangeContractOwnership,
} from '@/escolas/hooks/useChangeContractOwnership'
import { formatGuardianPayload } from './utils/formatGuardianPayload'
import ChangeGuardianBanner from './components/ChangeGuardianBanner'
import LoadingIllustration from 'src/escolas/assets/change-contract-ownership-loading-illustration.svg'
import { validateForm } from './utils/validateForm'
import { useSnackbar } from '@/shared/hooks'

export type ChangeGuardianFormValues = {
  additionalInfo: string
  addressNumber: string
  city: string
  email: string
  name: string
  phoneNumber: string
  reason: string
  state: string
  street: string
  taxId: string
  zipCode: string
}

export const ChangeGuardian = () => {
  const { setIsOpen: setSnackbarIsOpen } = useSnackbar()
  const { getNavigationUrl } = useNavigation()
  const { contractId } = useParams<PathParams>()
  const { contract } = useGetContract(contractId)

  const {
    changeOwnership,
    isChangeOwnershipLoading,
    isChangeOwnershipError,
    isChangeOwnershipSuccess,
  } = useChangeContractOwnership(contractId)

  const changeGuardianForm = useForm<ChangeGuardianFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues: {
      taxId: '',
      name: '',
    },
  })

  const [userIsAware, setUserIsAware] = useState<boolean>(false)
  const [shouldShowLoadingBanner, setShouldShowLoadingBanner] = useState(true)
  const [backendInputError, setBackendInputError] = useState(false)
  const [updatedGuardianId, setUpdatedGuardianId] = useState<string>('')
  const [redirectButtonLabel, setRedirectButtonLabel] = useState<string>('')
  const {
    control,
    trigger,
    formState: { errors },
    getValues,
  } = changeGuardianForm

  const history = useHistory()

  const handleCancel = () => {
    history.goBack()
  }

  const triggerFormValidation = async () => await trigger()

  const isSameTaxId = () => {
    const formTaxId = getValues('taxId')?.match(/\d/g)?.join('')
    const currentTaxId = contract?.guardian?.tax_id

    return formTaxId === currentTaxId
  }

  const displaySnackBar = (
    title: string,
    variation: NotificationProps['variation'],
    description?: string
  ) => {
    setSnackbarIsOpen(true, {
      title,
      ...(description && { description }),
      variation,
    })
  }

  const redirectToGuardianContracts = (guardianId: uuid) => {
    if (backendInputError) {
      setShouldShowLoadingBanner(false)
      return
    }

    history.push(
      getNavigationUrl({
        path: `responsaveis/${guardianId}/contratos`,
      })
    )
  }

  const updateOwnership = async () => {
    setShouldShowLoadingBanner(true)
    const formValues = getValues()
    const reason = formValues?.reason

    const new_guardian = formatGuardianPayload(formValues)

    await changeOwnership({
      reason,
      new_guardian,
    })
      .then((guardian: changeContractOwnershipResponse) => {
        setUpdatedGuardianId(guardian?.new_guardian_id)
        setRedirectButtonLabel('Ver novo contrato')
        displaySnackBar('A alteração do titular do contrato foi realizada com sucesso', 'success')
        setBackendInputError(false)
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          if (status === 422 && errors[0].message === 'Zip Code error') {
            setRedirectButtonLabel('Voltar para o formulário')
            displaySnackBar(
              'Não foi possível realizar a alteração do titular do contrato, pois o CEP cadastrado é inválido. Por favor, revise o CEP informado.',
              'error'
            )
            changeGuardianForm.setError('zipCode', { type: 'invalid' })
            setBackendInputError(true)
            return
          }
          setUpdatedGuardianId(contract?.guardian?.id)
          setRedirectButtonLabel('Voltar para a página do responsável')
          displaySnackBar('Não foi possível realizar a alteração do titular do contrato', 'error')
        }
      )
  }

  const handlerChangeGuardianSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault()

    const isTaxIdEqual = isSameTaxId()
    const formValidation = validateForm(userIsAware, errors, isTaxIdEqual)

    const { isValid: isFormValuesValid, error: formErrors } = formValidation

    if (!isFormValuesValid) {
      displaySnackBar(formErrors?.title, 'error', formErrors?.description)
      return
    }

    await updateOwnership()
  }

  if (!contract) {
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    )
  }

  const showLoadingBanner =
    shouldShowLoadingBanner &&
    (isChangeOwnershipLoading || isChangeOwnershipSuccess || isChangeOwnershipError)

  if (showLoadingBanner) {
    return (
      <ChangeGuardianBanner
        buttonLabel={redirectButtonLabel}
        imageSrc={LoadingIllustration}
        isLoading={isChangeOwnershipLoading}
        buttonOnClick={() => redirectToGuardianContracts(updatedGuardianId)}
      />
    )
  }

  return (
    <ChangeGuardianBox data-testid="change-guardian-container">
      <ChangeGuardianHeader />
      <form onSubmit={handlerChangeGuardianSubmit} data-testid="change-guardian-form-container">
        <GuardianBasicFormInput control={control} isSameTaxId={isSameTaxId} />
        <GuardianAddressFormInput form={changeGuardianForm} />
        <GuardianEditReasonFormInput control={control} />
        <GuardianConditionsFormInput onChange={setUserIsAware} value={userIsAware} />
        <ButtonsBox>
          <Button onClick={handleCancel} variation="ghost">
            Cancelar
          </Button>
          <Box ml={1}>
            <Button variation="primary" type="submit" onClick={triggerFormValidation}>
              Concluir alteração
            </Button>
          </Box>
        </ButtonsBox>
      </form>
      <ChangeGuardianResume />
    </ChangeGuardianBox>
  )
}
