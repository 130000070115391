import { FilterOptionsType } from '../../../types'

export const DEBT_STATUS_OPTIONS: FilterOptionsType[] = [
  {
    label: 'Em dia',
    value: 'UP_TO_DATE',
  },
  {
    label: 'Com pendência',
    value: 'OVERDUE',
  },
  {
    label: 'Atenção',
    value: 'WARNING',
  },
]

export const CONTRACT_STATUS_OPTIONS: FilterOptionsType[] = [
  {
    label: 'Ativo',
    value: 'OPEN',
  },
  {
    label: 'Inativo',
    value: 'CANCELED',
  },
]
