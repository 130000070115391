import { Controller, useFieldArray } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useEffect } from 'react'
import { DatePicker, Checkbox, FormControlLabel, Divider } from '@olaisaac/design-system'
import { FormHelperText, InputLabel, Select } from '@material-ui/core'

import {
  formatCentsToReal,
  DATE_FORMAT,
  isDayTodayOrAfter,
  isCurrentMonthOrAfter,
  renderNumericSelectOptions,
  makeIsCurrentCycleOrAfter,
} from 'src/shared/utils'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Switch from '@material-ui/core/Switch'

import { omit } from 'ramda'
import styled from 'styled-components'
import { EnrollmentProps } from './types'
import SelectDueDay from '../ContractEditDrawer/components/SelectNewDueDay/SelectNewDueDay'

import { AddDiscountFormType } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/AddDiscountContent'
import { Discount } from 'src/shared/interfaces'
import DiscountForm from '../../DiscountForm'
import { CURRENT_YEAR } from '../constants'
import dayjs from 'dayjs'
import { useMaxInstallmentValidation } from './hooks/useMaxInstallmentValidation'
import { Callout } from '@gravity/callout'

export const StyledTypography = styled(Typography).attrs({ withoutMargin: true })`
  overflow-wrap: break-word;
`

export const ArithmeticOperator = styled(StyledTypography)`
  display: flex;
  align-items: center;
  margin: 0 12px;
  font-weight: 500;
  font-size: 18px;
  color: #414141;
`
export const EnrollmentStartMonthTitle = styled(StyledTypography)`
  line-height: 24px;
  margin-bottom: 4px;
  color: #414141;
`
export const EnrollmentStartMonthSubtitle = styled(StyledTypography)`
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  color: #6d6d6d;
`
export const EnrollmentStartMonthBox = styled(Box)`
  margin: 34px 0;
`
export const SelectOverDueTitle = styled(StyledTypography)`
  line-height: 24px;
  margin-bottom: 4px;
  color: #414141;
`
export const SelectOverDueSubtitle = styled(StyledTypography)`
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  color: #6d6d6d;
`
export const OverDueHeader = styled(Box)`
  margin: 34px 0 24px;
`
export const EnrollmentTitle = styled(StyledTypography)`
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  color: #414141;
`
export const EnrollmentSubtitle = styled(StyledTypography)`
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  color: #6d6d6d;
`

const StyledButton = styled(Button)`
  text-transform: none;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  width: 70%;
`

const StyledDivider = styled(Divider)`
  margin: 60px 0 0;
`

const EnrollmentForm = ({
  form,
  enrollmentForm,
  handleEnrollmentFormFieldsChange,
  isEnrollmentEnabled,
  setEnrollmentEnabled,
}: EnrollmentProps) => {
  const { control, formState, getValues, setValue, trigger } = form
  const formValues = getValues()
  const enrollmentInstallments = +getValues('enrollment_duration_months')
  const enrollmentStartMonth = getValues('enrollment_start_month')
  const isCurrentCycleOrAfter = makeIsCurrentCycleOrAfter(formValues.reference_year)
  const enrollmentTotalAmount = formValues.custom_enrollment_monthly_amount * enrollmentInstallments

  const {
    maxEnrollmentInstallments,
    minEnrollmentInstallments,
    isSelectedEnrollmentDurationMonthsValid,
    enrollmentDurationMonthsValidationText,
    enrollmentDurationMonthsValidation,
  } = useMaxInstallmentValidation({
    form,
  })

  const hasMultipleIEnrollmentInstallments = enrollmentInstallments > 1
  const isEnrollmentStartMonthInvalid = Boolean(formState.errors.enrollment_start_month)

  const {
    control: enrollmentDiscountsFormControl,
    getValues: enrollmentDiscountsFormGetValues,
  } = enrollmentForm

  const enrollmentDiscountsFormValues = enrollmentDiscountsFormGetValues()
  const enrollmentDiscounts = enrollmentDiscountsFormValues?.discounts

  const { fields: enrollmentDiscountFields, append, remove } = useFieldArray({
    control: enrollmentDiscountsFormControl,
    name: 'discounts',
  })
  const helperTextCycle =
    Number(formValues.reference_year) >= CURRENT_YEAR
      ? `Selecione um mês e ano entre ${dayjs().month() + 1}/${CURRENT_YEAR} e 12/${
          formValues.reference_year
        }`
      : `Selecione um mês e ano entre ${dayjs().month() + 1}/${CURRENT_YEAR} e 12/${CURRENT_YEAR}`
  const canAddDiscount = isEnrollmentEnabled && enrollmentDiscounts?.length < 3

  const removeDiscount = (index: number) => remove(index)

  const appendDiscount = (enrollmentDiscount: Discount & Partial<AddDiscountFormType>) => {
    append({
      amount: enrollmentDiscount?.amount,
      days_before_due_date: enrollmentDiscount?.days_before_due_date,
      description: enrollmentDiscount?.description,
      isFinished: false,
      label_days_before_due_date: enrollmentDiscount?.label_days_before_due_date,
    })
  }

  const appendNewDiscount = () => {
    appendDiscount({
      amount: null,
      days_before_due_date: null,
      description: '',
    })
  }

  function resetDiscounts() {
    handleEnrollmentFormFieldsChange({
      discounts: [],
    })
  }

  function toggleEnableEnrollmentHandler() {
    resetDiscounts()
    setEnrollmentEnabled(!isEnrollmentEnabled)
  }

  useEffect(() => {
    setValue('enrollment_start_month', null)
    setValue('enrollment_due_day', null)
  }, [hasMultipleIEnrollmentInstallments])

  useEffect(() => {
    if (enrollmentInstallments && enrollmentStartMonth) {
      trigger('enrollment_duration_months')
    }
  }, [enrollmentInstallments, enrollmentStartMonth])

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mt="60px"
      >
        <EnrollmentTitle>Pré-matrícula</EnrollmentTitle>
        <FormControlLabel
          control={
            <Switch
              checked={isEnrollmentEnabled}
              onChange={toggleEnableEnrollmentHandler}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label="Solicitar pré-matrícula"
        />
      </Box>
      {isEnrollmentEnabled && (
        <>
          <EnrollmentSubtitle>
            O valor da parcela é definido a partir das configurações do produto. Caso queira, você
            pode alterar o valor da parcela abaixo.
          </EnrollmentSubtitle>

          <Callout text="Para vencimento informado no passado, atualizamos o vencimento da primeira parcela em 4 dias. Funciona assim: se você está no dia 10/01 e informa o vencimento para 05/01, a primeira parcela vai ter vencimento para 14/01." />

          <Box display="flex" justifyContent="space-between" alignItems="bottom" mt={4}>
            <FormControl fullWidth variant="outlined" error={enrollmentDurationMonthsValidation}>
              <InputLabel id="enrollment_duration-label" data-testid="enrollment_duration-label">
                Parcelas
              </InputLabel>
              <Controller
                name="enrollment_duration_months"
                control={control}
                rules={{
                  required: true,
                  validate: value => isSelectedEnrollmentDurationMonthsValid(value),
                }}
                render={({ field: { ...rest } }) => (
                  <Select
                    {...rest}
                    data-testid="enrollment_duration-select"
                    labelId="enrollment_duration-label"
                    id="enrollment_duration-select"
                    label="Parcelas"
                    error={Boolean(formState.errors.enrollment_duration_months)}
                  >
                    {renderNumericSelectOptions({
                      start: minEnrollmentInstallments,
                      end: maxEnrollmentInstallments,
                      name: 'duration_months-option',
                    })}
                  </Select>
                )}
              />
              {enrollmentDurationMonthsValidation && (
                <FormHelperText>{enrollmentDurationMonthsValidationText}</FormHelperText>
              )}
            </FormControl>
            <ArithmeticOperator>x</ArithmeticOperator>
            <FormControl variant="outlined" fullWidth>
              <Controller
                rules={{ required: true, validate: v => v >= 100 }}
                control={control}
                name="custom_enrollment_monthly_amount"
                render={({ field: { onChange, value, ref } }) => (
                  <NumberFormat
                    id="custom_enrollment_monthly_amount-input"
                    inputRef={ref}
                    onValueChange={({ floatValue }) => {
                      onChange(floatValue || '')
                    }}
                    customInput={TextField}
                    variant="outlined"
                    label="Valor da parcela"
                    format={formatCentsToReal}
                    InputProps={{
                      inputProps: { min: 100 },
                    }}
                    value={value}
                    error={Boolean(formState.errors.custom_enrollment_monthly_amount)}
                    helperText={
                      formState.errors.custom_enrollment_monthly_amount
                        ? 'Insira um valor válido'
                        : ''
                    }
                  />
                )}
              />
            </FormControl>
            <ArithmeticOperator>=</ArithmeticOperator>
            <FormControl fullWidth disabled variant="outlined">
              <NumberFormat
                disabled
                value={enrollmentTotalAmount}
                placeholder="R$ 0,00"
                customInput={TextField}
                variant="outlined"
                label="Valor da pré-matrícula"
                format={formatCentsToReal}
                id="enrollment-total-amount"
              />
            </FormControl>
          </Box>
          <EnrollmentStartMonthBox>
            <EnrollmentStartMonthTitle>
              {hasMultipleIEnrollmentInstallments ? 'Início das parcelas' : 'Data de vencimento'}
            </EnrollmentStartMonthTitle>
            <EnrollmentStartMonthSubtitle>
              {hasMultipleIEnrollmentInstallments
                ? 'Selecione o mês de início das parcelas'
                : 'Selecione o dia de vencimento da pré-matrícula'}
            </EnrollmentStartMonthSubtitle>
            <FormControl variant="outlined">
              {hasMultipleIEnrollmentInstallments ? (
                <Controller
                  rules={{
                    required: isEnrollmentEnabled,
                    validate: isEnrollmentEnabled
                      ? v => isCurrentCycleOrAfter(v) && isCurrentMonthOrAfter(v)
                      : undefined,
                  }}
                  name="enrollment_start_month"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      views={['year', 'month']}
                      disabled={!isEnrollmentEnabled}
                      label="Mês"
                      placeholder="MM/YYYY"
                      format="MM/YYYY"
                      disablePast
                      maxDate={
                        Number(formValues.reference_year) >= CURRENT_YEAR
                          ? new Date(Number(formValues.reference_year), 11, 31)
                          : new Date(Number(CURRENT_YEAR), 11, 31)
                      }
                      minDateMessage="A data não pode ser anterior a hoje"
                      invalidDateMessage="Formato de data inválido"
                      {...omit(['ref'], field)}
                      error={isEnrollmentStartMonthInvalid}
                      helperText={isEnrollmentStartMonthInvalid && helperTextCycle}
                    />
                  )}
                />
              ) : (
                <Controller
                  rules={{
                    required: isEnrollmentEnabled,
                    validate: isEnrollmentEnabled
                      ? v => isCurrentCycleOrAfter(v) && isDayTodayOrAfter(v)
                      : undefined,
                  }}
                  name="enrollment_start_month"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      disabled={!isEnrollmentEnabled}
                      label="Dia"
                      placeholder={DATE_FORMAT}
                      format={DATE_FORMAT}
                      disablePast
                      maxDate={
                        Number(formValues.reference_year) >= CURRENT_YEAR
                          ? new Date(Number(formValues.reference_year), 11, 31)
                          : new Date(Number(CURRENT_YEAR), 11, 31)
                      }
                      minDateMessage="A data não pode ser anterior a hoje"
                      invalidDateMessage="Formato de data inválido"
                      {...omit(['ref'], field)}
                      error={isEnrollmentStartMonthInvalid}
                      helperText={isEnrollmentStartMonthInvalid && helperTextCycle}
                    />
                  )}
                />
              )}
            </FormControl>
          </EnrollmentStartMonthBox>
          {hasMultipleIEnrollmentInstallments && (
            <Box mb={3} mt={4}>
              <OverDueHeader>
                <SelectOverDueTitle>Data de vencimento</SelectOverDueTitle>
                <SelectOverDueSubtitle>
                  Selecione a regra de dia de vencimento das parcelas
                </SelectOverDueSubtitle>
              </OverDueHeader>
              <SelectDueDay
                form={form}
                inputName="enrollment_due_day"
                radioName="use_working_dates_enrollment"
              />
            </Box>
          )}

          {enrollmentDiscounts?.length ? (
            <>
              <Box mb={1} mt={2}>
                <Typography>Descontos</Typography>
              </Box>
              <Box mb={2}>
                <Typography>Cadastre os descontos que entrarão na pré-matrícula</Typography>
              </Box>
            </>
          ) : null}

          {enrollmentDiscountFields?.map((data, index) => (
            <Box key={data.id} width="70%">
              <DiscountForm
                data={data}
                index={index}
                form={enrollmentForm}
                idPrefix="enrollment"
                totalAmount={formValues.custom_enrollment_monthly_amount}
                removeDiscount={removeDiscount}
              />
            </Box>
          ))}
          {canAddDiscount && (
            <Box mt={4} mb={4}>
              <StyledButton
                variant="outlined"
                color="primary"
                aria-label="adicionar desconto na pré-matrícula"
                size="large"
                fullWidth
                endIcon={<AddIcon />}
                onClick={() => appendNewDiscount()}
              >
                Adicionar desconto na pré-matrícula
              </StyledButton>
            </Box>
          )}
          <Box mt={3}>
            <Controller
              name="send_first_enrollment_installment_message"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checkedLink={undefined}
                        valueLink={undefined}
                        {...rest}
                        checked={value}
                      />
                    }
                    label="Enviar automaticamente a primeira cobrança pelo isaac ao salvar o contrato"
                  />
                  <Box pl={4}>
                    <Typography display="inline" variant="body2" color="textSecondary">
                      Assim que o contrato for salvo, o isaac enviará automaticamente a primeira
                      cobrança para o responsável. Caso não queira que o isaac faça esse envio,
                      desmarque essa opção. Independentemente disso, a cobrança estará sempre
                      disponível na Plataforma isaac.
                    </Typography>
                  </Box>
                </>
              )}
            />
          </Box>
        </>
      )}
      <StyledDivider />
    </>
  )
}

export default EnrollmentForm
