import { FC } from 'react'
import { Button, Notification } from '@olaisaac/design-system'

import { StepComponentProps } from '@/modules/guardians/Negotiation/types'
import { Footer } from '@/modules/guardians/Negotiation/components/Footer'
import { CheckoutStatus } from 'src/shared/interfaces'

import PaymentRow from './components/PaymentRow'
import * as S from './styles'
import { useSendCheckoutEvent } from '../../hooks/useSendCheckoutEvents'
import { HelpButton } from '../../components/HelpButton'
import { Box } from '@material-ui/core'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'

const Conclusion: FC<StepComponentProps> = ({ onFinish, conclusionInfo, helpButtonEntry }) => {
  if (!conclusionInfo.receivableId) return null

  const sendCheckoutEvent = useSendCheckoutEvent()

  const sendGenerateReceiptEvent = () => {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_SUCCESSFUL_TRANSACTION_GENERATE_RECEIPT, {
      name: 'Gerar recibo',
      location: null,
    })
  }
  return (
    <S.Wrapper>
      <Notification description="Pagamento realizado com sucesso!" variation="success" />
      <PaymentRow
        idForReceipt={conclusionInfo.receivableId}
        amount={conclusionInfo.amount}
        paymentMethod={conclusionInfo.paymentMethod}
        status={CheckoutStatus.FINISHED}
        onSuccess={sendGenerateReceiptEvent}
        installmentsNumber={conclusionInfo.installmentsNumberSelected}
      />
      <Footer
        button={
          <Box width="100%" display="flex">
            <HelpButton eventLocationName="conclusion" helpButtonEntry={helpButtonEntry} />
          </Box>
        }
      >
        <Button variation="primary" onClick={onFinish}>
          Concluir
        </Button>
      </Footer>
    </S.Wrapper>
  )
}

export default Conclusion
