import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { guardianService } from '@/modules/guardians/services'
import {
  GuardianHistoryQueryProps,
  GuardianHistoryQueryResponse,
} from '@/modules/guardians/services/types'

export const useGuardianHistoryQuery = (
  props: GuardianHistoryQueryProps,
  options?: UseQueryOptions<GuardianHistoryQueryResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApi()

  const service = guardianService(apiClient.privateApiV2)

  const queryKey = ['guardian-history', props.guardianId, props.schoolId, props.period]

  const { data: guardianHistory, isLoading, isRefetching, isError, isSuccess } = useQuery<
    GuardianHistoryQueryResponse,
    XMLHttpRequest
  >(
    queryKey,
    () =>
      service.fetchGuardianNegotiationsHistory({
        guardianId: props.guardianId,
        schoolId: props.schoolId,
        period: props.period,
      }),
    {
      ...SOFTCACHE_OPTIONS_BASE,
      retry: false,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: true,
      ...options,
    }
  )

  return {
    guardianHistory,
    isLoading,
    isRefetching,
    isError,
    isSuccess,
  }
}
