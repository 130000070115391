import { CommunicationSection } from '@/modules/school-home/pages/SchoolHome/components/CommunicationSection'

import { MainInfoSection } from '@/modules/school-home/pages/SchoolHome/components/MainInfoSection'
import { QuickAccessSection } from './components/QuickAccessSection'

import { useJWT } from '@/shared/hooks/useJWT'
import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { isIsaacPaySchool } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'

import { Container } from './styles'

export const Home = () => {
  const { hasPayoutReportAccess } = useJWT()
  const { school } = useSelectedSchool()

  const isIsaacPay = isIsaacPaySchool(school)
  const showMainInfoSection = hasPayoutReportAccess && !isIsaacPay

  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Página inicial',
  })

  return (
    <Container>
      <CommunicationSection />
      <QuickAccessSection />
      {showMainInfoSection && <MainInfoSection />}
    </Container>
  )
}
