import { Table } from '@gravity/table'
import { CircularProgress } from '@material-ui/core'
import { mdiTrashCan, mdiUndo } from '@mdi/js'
import Icon from '@mdi/react'
import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'

import { StatusBadge } from '@/modules/enrollment/components/StatusBadge'
import { PaginationType } from '@/shared/hooks/usePagination/types'

import { TableRowProps, ColumnProps, RowActionTypes } from './types'
import * as Styled from './styles'

interface StudentsTableProps {
  disableActions: boolean
  disabledRows: string[]
  isLoading: boolean
  onActionRow: (rowKey: string, action: RowActionTypes) => void
  pagination: PaginationType
  rows: TableRowProps[]
  totalStudents: number
  updatePaginationValue: (key: keyof PaginationType, value: number, replaceRoute?: boolean) => void
}

interface ActionButtonProps {
  icon: string
  onClick: () => void
  text: string
}

const COLUMN_HEADERS = [
  { key: 'student_column', label: 'Aluno' },
  { key: 'main_guardian_column', label: 'Responsável' },
  { key: 'product_column', label: 'Produto de origem' },
  { key: 'student_status_column', label: 'Situação' },
]

export const StudentsTable = ({
  rows,
  isLoading,
  disabledRows,
  disableActions,
  onActionRow,
  pagination,
  totalStudents,
  updatePaginationValue,
}: StudentsTableProps) => {
  if (isLoading) {
    return (
      <Styled.LoadingContainer>
        <CircularProgress />
      </Styled.LoadingContainer>
    )
  }

  if (!rows?.length) {
    return <Styled.EmptyStateContainer>Nenhum aluno encontrado</Styled.EmptyStateContainer>
  }

  const isRowDisabled = (rowKey: string) => disabledRows.includes(rowKey)

  const ActionButton = ({ onClick, icon, text }: ActionButtonProps) => (
    <Tooltip text={text} position="left">
      <IconButton onClick={onClick} variant="ghost">
        <Icon path={icon} />
      </IconButton>
    </Tooltip>
  )

  return (
    <Table.Root>
      <Table.Head>
        {COLUMN_HEADERS.map(({ key, label }: ColumnProps) => (
          <Table.HeaderCell key={key} name={key}>
            {label}
          </Table.HeaderCell>
        ))}
      </Table.Head>
      <Table.Body>
        {rows.map(row => {
          const disabled = isRowDisabled(row.key)
          return (
            <Table.Row key={row.key}>
              <Table.TextCell disabled={disabled}>{row.columns[0]}</Table.TextCell>
              <Table.TextCell disabled={disabled}>{row.columns[1]}</Table.TextCell>
              <Table.TextCell disabled={disabled}>{row.columns[2]}</Table.TextCell>
              <Table.CustomCell disabled={disabled}>
                <StatusBadge status={row.columns[3]} disabled={isRowDisabled(row.key)} />
              </Table.CustomCell>
              {!disableActions && (
                <Table.ActionCell
                  actions={
                    <ActionButton
                      onClick={() =>
                        onActionRow(
                          row.key,
                          isRowDisabled(row.key) ? RowActionTypes.UNDO : RowActionTypes.REMOVE
                        )
                      }
                      icon={isRowDisabled(row.key) ? mdiUndo : mdiTrashCan}
                      text={isRowDisabled(row.key) ? 'Desfazer' : 'Remover'}
                    />
                  }
                />
              )}
            </Table.Row>
          )
        })}
      </Table.Body>
      <Table.Pagination
        page={pagination.page}
        itemsPerPage={pagination.itemsPerPage}
        total={totalStudents}
        onChangePage={newPage => {
          updatePaginationValue('page', newPage, true)
          window.scrollTo(0, 0)
        }}
        onChangeItemsPerPage={newItensPerPage => {
          updatePaginationValue('itemsPerPage', newItensPerPage, true)
        }}
      />
    </Table.Root>
  )
}
