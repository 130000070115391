import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import { Button } from '@olaisaac/design-system'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  padding-left: ${({ theme }) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
  border-radius: 8px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
`

export const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const StyledButton = styled(Button)`
  padding-left: -20px;
  height: auto;
  background-color: black;
`
