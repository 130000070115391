interface GenericProduct {
  name: string
}

const sortDesc = <T extends GenericProduct>(a: T, b: T): number => {
  return a.name > b.name ? -1 : 1
}

export const sortProducts = <T extends GenericProduct>(items: T[]): T[] => {
  const regex = /^\d{4}/g
  const itemsWithYearPrefix = items.filter(item => item.name.match(regex))
  const itemsWithoutYearPrefix = items.filter(item => !item.name.match(regex))

  return [...itemsWithYearPrefix.sort(sortDesc), ...itemsWithoutYearPrefix.sort(sortDesc).reverse()]
}
