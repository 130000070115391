import type { ReactNode } from 'react'
import { FlagProvider, UnleashClient } from '@unleash/proxy-client-react'

import envConfig from '@/config'

type UnleashProviderProps = {
  children: ReactNode
}

const unleashClient = new UnleashClient({
  url: envConfig.UNLEASH.PROXY_URL,
  clientKey: envConfig.UNLEASH.PROXY_KEY,
  appName: 'backoffice',
  environment: envConfig.ENV,
  refreshInterval: Number(envConfig.UNLEASH.REFRESH_INTERVAL),
})

/**
 * Custom provider to setup Unleash
 *
 * @param children Components that will be able to access the context
 *
 * @returns Context provider
 */
export const UnleashProvider = ({ children }: UnleashProviderProps) => {
  return (
    <FlagProvider unleashClient={unleashClient} startClient>
      {children}
    </FlagProvider>
  )
}
