export type SortOrder = 'ASC' | 'DESC'

export type Ordering<T extends string = string> = {
  sortBy: T | undefined
  sortOrder: SortOrder | undefined
}

export type OrderingQueryParamNames = 'sort_by' | 'sort_order'

export enum OrderingQueryParamsNameEnum {
  sortBy = 'sort_by',
  sortOrder = 'sort_order',
}

export type OrderingHookType<T extends string> = {
  ordering: Ordering<T>
  updateOrdering: (data: Ordering<T>) => void
  updateOrderingValue: <K extends keyof Ordering<T>>(key: K, value: Ordering<T>[K]) => void
}
