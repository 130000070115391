import type { AxiosInstance } from 'axios'
import type { FetchSearchRequest, FetchSearchResponse } from './types'

export const useSchoolHomeSearchService = (fetcher: AxiosInstance) => {
  return {
    search: async ({
      schoolId,
      search,
      type,
    }: FetchSearchRequest): Promise<FetchSearchResponse> => {
      const { data } = await fetcher.get<{ data: FetchSearchResponse }>('school-home/search', {
        params: {
          school_id: schoolId,
          search,
          type,
        },
      })

      return data?.data
    },
  }
}
