import { InstallmentType } from './installment'
import { Discount } from './receivable'

export enum Status {
  CANCELED = 'CANCELED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
}

export type BufferInstallmentResponse = {
  amount: cents
  current_due_payment_discount: cents
  current_early_payment_discount: cents
  current_perpetual_discount: cents
  discounts: Discount[]
  due_date: datestring
  id?: uuid
  key?: string
  original_amount: cents
  status?: Status
  type: InstallmentType
}

export enum BufferInstallmentStatuses {
  ON_HOLD = 'ON_HOLD',
}
