import { Guardian } from '@/shared/interfaces'
import { validateZip } from './addressHelpers'
import { validateCpf, validateEmail } from './formHelpers'

export enum GuardianErrorResponse {
  ADDRESS_NUMBER = 'Número do endereço não preenchido',
  ADDRESS_ZIP = 'CEP inválido',
  EMAIL = 'E-mail possui formato incorreto',
  TAX_ID = 'CPF possui formato incorreto',
}

export type GuardianValidationError = {
  name: keyof GuardianErrorResponse
  response: GuardianErrorResponse
}

export const validateGuardianInfo = async (
  guardian: Guardian
): Promise<Array<GuardianValidationError>> => {
  const errors = []
  if (!validateEmail(guardian?.email))
    errors.push({ name: 'EMAIL', response: GuardianErrorResponse.EMAIL })
  if (!validateCpf(guardian?.tax_id))
    errors.push({ name: 'TAX_ID', response: GuardianErrorResponse.TAX_ID })
  if (!guardian?.address?.number)
    errors.push({ name: 'ADDRESS_NUMBER', response: GuardianErrorResponse.ADDRESS_NUMBER })
  if (!(await validateZip(guardian?.address?.zip)))
    errors.push({ name: 'ADDRESS_ZIP', response: GuardianErrorResponse.ADDRESS_ZIP })

  return errors.length === 0 ? null : errors
}
