import dayjs from 'dayjs'
import { AgglutinationProcessedInstallment } from 'src/escolas/contexts/checkoutContext'
import {
  Contract,
  ContractStatus,
  PreContractStatuses,
  Product,
  Student,
} from 'src/shared/interfaces'
import {
  BufferInstallmentResponse,
  BufferInstallmentStatuses,
} from 'src/shared/interfaces/bufferInstallment'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'

const formatBufferInstallment = (
  bufferInstallment: BufferInstallmentResponse,
  product: Product,
  student: Student,
  orderReference: string,
  contractId?: uuid
): AgglutinationProcessedInstallment => {
  return {
    id: bufferInstallment.id || 'x', // a non falsy value is required so checkbox is not rendered
    amount: bufferInstallment.amount,
    original_amount: bufferInstallment.original_amount,
    current_due_payment_discount: bufferInstallment.current_due_payment_discount,
    current_early_payment_discount: bufferInstallment.current_early_payment_discount,
    current_perpetual_discount: bufferInstallment.current_perpetual_discount,
    due_date: bufferInstallment.due_date,
    status: BufferInstallmentStatuses.ON_HOLD,
    type: bufferInstallment.type,
    competence: [dayjs(bufferInstallment.due_date).format('MMMM')],
    contractID: contractId || null,
    due_month: dayjs(bufferInstallment.due_date),
    installment_id: null,
    key: null,
    orderReference: orderReference,
    productID: product.id,
    productName: product.name,
    studentID: student.id,
    studentName: prettifyFullName(student.name),
    discounts: bufferInstallment.discounts,
  }
}

const formatOrderReference = (index: number, total: number): string => `${index + 1} de ${total}`

export const formatBufferInstallments = (
  bufferInstallments: BufferInstallmentResponse[],
  product: Product,
  student: Student,
  contractId?: uuid
): AgglutinationProcessedInstallment[] => {
  return bufferInstallments.map((bufferInstallment, index) =>
    formatBufferInstallment(
      bufferInstallment,
      product,
      student,
      formatOrderReference(index, bufferInstallments.length),
      contractId
    )
  )
}

export const getAllBufferInstallments = (contracts: Contract[]) => {
  const allBufferInstallments = contracts.reduce((allBufferInstallments, contract) => {
    if (
      !contract.pre_contract_installments ||
      contract.pre_contract_status === PreContractStatuses.EFFECTED_PCS ||
      contract.status === ContractStatus.CANCELED
    ) {
      return allBufferInstallments
    }

    return [
      ...allBufferInstallments,
      ...(contract.pre_contract_installments &&
        formatBufferInstallments(
          contract.pre_contract_installments,
          contract.product,
          contract.student,
          contract.id
        )),
    ]
  }, [])

  const allBufferInstallmentsWithKeys = allBufferInstallments.map((bufferInstallment, index) => ({
    ...bufferInstallment,
    key: index,
  }))

  return allBufferInstallmentsWithKeys
}

export const getAllBufferInstallmentsMatriculasApi = (contracts: Contract[]) => {
  const allBufferInstallments = contracts.reduce((allBufferInstallments, contract) => {
    if (!contract.pre_contract_installments) {
      return allBufferInstallments
    }
    return [
      ...allBufferInstallments,
      ...(contract.pre_contract_installments &&
        formatBufferInstallments(
          contract.pre_contract_installments,
          contract.product,
          contract.student,
          contract.id
        )),
    ]
  }, [])

  const allBufferInstallmentsWithKeys = allBufferInstallments.map((bufferInstallment, index) => ({
    ...bufferInstallment,
    key: index,
  }))

  return allBufferInstallmentsWithKeys
}
