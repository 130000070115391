import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { NotificationEventPageNamesEnum } from '../../models/enums/NotificationEventPageNames.enum'
import { NotificationEventIdentifierNamesEnum } from '../../models/enums/NotificationEventIdentifierNames.enum'

export const useTrackNotificationModalEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const trackModalClose = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.MODAL_CLOSE,
        pageName: NotificationEventPageNamesEnum.NOTIFICATIONS_HOME,
        identifierName: NotificationEventIdentifierNamesEnum.NOTIFICATIONS_MODAL,
        customProperties: {
          $modal: 'Notification modal',
        },
      })
    }
  }

  const trackActionButtonClick = (notificationTitle: string) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.BUTTON_CLICK,
        pageName: NotificationEventPageNamesEnum.NOTIFICATIONS_HOME,
        identifierName: NotificationEventIdentifierNamesEnum.NOTIFICATION_ACTION_BUTTON,
        customProperties: {
          $modal: 'Notification modal',
          $title: notificationTitle,
          $button: 'Internal redirect click',
        },
      })
    }
  }

  return {
    trackModalClose,
    trackActionButtonClick,
  }
}
