import { Tabs, Tab } from '@material-ui/core'
import { TableControlBar as IsaacTableControlBar } from '@olaisaac/design-system'
import styled from 'styled-components'

export const StyledTabs = styled(Tabs)`
  width: 100%;
  border-bottom: 1px solid #dadada;
  margin-top: 2rem;
`

export const StyledTab = styled(Tab)`
  color: #999999;
  font-size: 0.875rem;
  text-transform: none;
  width: 230px;
  height: 68px;
  font-weight: 400;
  border-bottom: 2px solid transparent;
  transition: border 0.25s ease-in-out;

  &.Mui-selected {
    color: #414141;
  }

  &:hover {
    border-bottom: 2px solid ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
  }
`

export const TableControlBar = styled(IsaacTableControlBar)`
  margin-top: 3rem;
  border-top: 1px solid #dadada;
  padding: 1.5rem;
`
