import { Badge, BadgeProps } from '@gravity/badge'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Skeleton } from '@gravity/skeleton'

import { Card, CardInfoWrapper } from './styles'

export type PaymentDisputeSummaryCardProps = {
  loading?: boolean
  status: 'in_review' | 'pending' | 'finish'
  total?: number
}

type CardItemProps = {
  color: BadgeProps['color']
  description: string
  title: string
}

const cardInfoMapping: Record<PaymentDisputeSummaryCardProps['status'], CardItemProps> = {
  in_review: {
    color: 'warning',
    title: 'Iniciada',
    description: 'aguardando conclusão',
  },
  pending: {
    color: 'error',
    title: 'Não iniciada',
    description: 'não analisadas',
  },
  finish: {
    color: 'neutral',
    title: 'Concluída',
    description: 'finalizadas',
  },
}

export const PaymentDisputeSummaryCard = ({
  status,
  total,
  loading,
}: PaymentDisputeSummaryCardProps) => {
  const { color, title, description } = cardInfoMapping[status]

  if (loading) {
    return (
      <Card>
        <Skeleton height={24} width={105} />
        <Skeleton height={24} width={338} />
      </Card>
    )
  }

  return (
    <Card>
      <div>
        <Badge variant="soft" color={color}>
          {title}
        </Badge>
      </div>

      <CardInfoWrapper>
        <Heading variant="heading-h3-medium">{String(total)}</Heading>
        <Text variant="body-1-regular">{description}</Text>
      </CardInfoWrapper>
    </Card>
  )
}
