import { AxiosInstance } from 'axios'
import { DownloadPaymentStatusRequest } from './types'

export const accountReportsService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for download payment status report
     *
     * @param data.schoolId School id
     * @param data.period Period of the report
     *
     * @returns A blob with the report to be downloaded
     */
    downloadPaymentStatusReportByApi: async ({
      schoolId,
      period,
    }: DownloadPaymentStatusRequest): Promise<Blob> => {
      const { data } = await fetcher.get<Blob>('school/report/payment', {
        params: {
          school_id: schoolId,
          period,
        },
        responseType: 'blob',
      })

      return data
    },
    /**
     * Function responsible for download payment status report through bff
     *
     * @param data.schoolId School id
     * @param data.schoolName School name
     * @param data.schoolSlug School slug
     * @param data.period Period of the report
     *
     * @returns A blob with the report to be downloaded
     */
    downloadPaymentStatusReportByBff: async ({
      schoolId,
      period,
      schoolName,
      schoolSlug,
    }: DownloadPaymentStatusRequest): Promise<Blob> => {
      const { data } = await fetcher.get<Blob>(`report/payment`, {
        params: {
          schoolId,
          schoolName,
          schoolSlug,
          period,
        },
        responseType: 'blob',
      })

      return data
    },
  }
}
