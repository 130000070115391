import { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { debounce } from 'throttle-debounce'
import { Controller, UseFormReturn } from 'react-hook-form'
import { propEq } from 'ramda'
import { Box } from '@material-ui/core'
import { ProductItem } from '@/modules/enrollment/services/campaigns/types'
import Icon from '@mdi/react'
import { mdiPlus } from '@mdi/js'
import * as Styled from './styles'

export type SelectAutocompleteProps = {
  form: UseFormReturn<FormProps>
  onOptionAction: () => void
  onOptionChange: (option: ProductItem | null) => void
  options: ProductItem[]
  referenceYear: number
}

export type FormProps = {
  option_id: string
  option_name: string
}

export const SelectAutocomplete = ({
  form,
  referenceYear,
  options,
  onOptionChange,
  onOptionAction,
}: SelectAutocompleteProps) => {
  const [isSearchingOptions, setIsSearchingOptions] = useState<boolean>(false)

  const noOptionsText = isSearchingOptions
    ? 'Nenhum produto com esse nome foi encontrado'
    : 'Comece a digitar para buscar produtos'

  const inputDebounce = debounce(500, () => setIsSearchingOptions(true))
  const formValues = form.getValues()

  const resetField = () => {
    form.reset({ ...formValues, option_name: '', option_id: '' }, { keepErrors: true })
  }

  const handleOptionSelectChange = (option: ProductItem) => {
    if (!option.id) {
      return resetField()
    }
    const selectedProduct = options?.find(propEq('id', option.id))
    form.setValue('option_name', selectedProduct.name)
    onOptionChange(option)
  }

  const handleOptionAction = () => {
    resetField()
    onOptionAction()
  }

  return (
    <>
      <FormControl fullWidth variant="outlined">
        <Controller
          control={form.control}
          name="option_id"
          render={({ field: { onChange, value, ...rest } }) => (
            <Autocomplete
              {...rest}
              data-testid="select-autocomplete"
              value={value || null}
              forcePopupIcon
              disableClearable
              getOptionSelected={(option, value) => option?.id === (value?.id || '')}
              getOptionLabel={option => (option ? option.name || '' : '')}
              renderOption={option => <Box>{option?.name}</Box>}
              options={options}
              onChange={(_, data, reason) => {
                if (!data || reason === 'clear') {
                  onOptionChange(undefined)
                  return resetField()
                }
                onChange(data as ProductItem)
                handleOptionSelectChange(data as ProductItem)
              }}
              onInputChange={inputDebounce}
              noOptionsText={noOptionsText}
              renderInput={params => (
                <Styled.TextFieldWrapper>
                  <Styled.TextField
                    {...params}
                    hiddenLabel
                    placeholder={`Selecione ou procure um produto ${referenceYear}`}
                    variant="outlined"
                  />
                  <Styled.Button
                    type="button"
                    onClick={handleOptionAction}
                    variant="outline"
                    iconStart={<Icon path={mdiPlus} />}
                  >
                    Adicionar
                  </Styled.Button>
                </Styled.TextFieldWrapper>
              )}
            />
          )}
        />
      </FormControl>
    </>
  )
}
