import { InstallmentStatus } from '../../GuardianDetails/constants'
import { Installment } from '../../GuardianDetails/types'

export const getUniqueBufferInstallmentsByStudent = (installments: Installment[]) => {
  const flags = {}
  const uniqueBufferInstallments = installments?.filter(entry => {
    if (entry.status !== InstallmentStatus.PENDING) return false

    if (flags[entry.student.id]) {
      return false
    }
    flags[entry.student.id] = true
    return true
  })

  return uniqueBufferInstallments
}
