import { Skeleton } from '@gravity/skeleton'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'

import type { BannerProps } from './types'

import { Container, ExplanationPlaceholder, InfoContainer, TitlePlaceholder } from './styles'

export const Banner = (props: BannerProps) => {
  const {
    title,
    titleSize,
    explanation,
    buttonLabel,
    imageSrc,
    buttonOnClick,
    backgroundColor,
    color,
  } = props

  const headingVariant = (size: string): 'heading-h3-medium' | 'heading-h4-medium' => {
    switch (size) {
      case 'large':
        return 'heading-h3-medium'
      default:
        return 'heading-h4-medium'
    }
  }

  return (
    <Container backgroundColor={backgroundColor} color={color} className="banner">
      <img src={imageSrc} alt="" width={262} height={174.92} />
      <InfoContainer>
        <Heading variant={headingVariant(titleSize)} className="banner-title">
          {title}
        </Heading>
        <Text variant="body-1-regular" className="banner-subtitle">
          {explanation}
        </Text>
        <div>
          <Button onClick={buttonOnClick}>{buttonLabel}</Button>
        </div>
      </InfoContainer>
    </Container>
  )
}

export const BannerPlaceholder = () => (
  <Container className="skeleton banner">
    <Skeleton width={262} height={174.92} radius={2} variant="neutral" />
    <InfoContainer>
      <TitlePlaceholder fullWidth height={20} radius={2} variant="neutral" />
      <Skeleton fullWidth height={20} radius={2} variant="neutral" />
      <ExplanationPlaceholder fullWidth height={20} radius={2} variant="neutral" />
      <Skeleton width={132} height={48} variant="neutral" />
    </InfoContainer>
  </Container>
)
