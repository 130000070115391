import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import Icon from '@mdi/react'
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js'
import { Accordion } from '@gravity/accordion'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'

import { NegotiationCard } from './components/NegotiationCard'
import { NegotiationEmpty } from './components/NegotiationEmpty'

import { useGuardianHistory } from './hooks/useGuardianHistory'
import { useGuardianHistoryNegotiation } from './hooks/useGuardianHistoryNegotiation'

import {
  Wrapper,
  WrapperSeparator,
  DatepickerWrapper,
  DatepickerTrigger,
  Heading,
  IconButton,
} from './styles'

export const GuardianDetailsHistoryTab = () => {
  const [negotiationId, setNegotiationId] = useState<string | null>(null)

  const {
    now,
    lastPeriod,
    nextPeriod,
    selectedPeriod,
    handleApplyFilter,
    isSameDate,
    guardianHistory,
    isLoading,
    isEmpty,
    isCurrentMonthSelected,
    handleEventDatepicker,
    handleEventDateNavigation,
    handleEventCollapsible,
  } = useGuardianHistory()

  const {
    guardianHistoryNegotiation,
    isError: isNegotiationError,
    isLoading: isNegotiationLoading,
  } = useGuardianHistoryNegotiation(negotiationId)

  const handleOnValueChange = (selectedNegotiationId: string | null) => {
    setNegotiationId(selectedNegotiationId)
    handleEventCollapsible()
  }

  useEffect(() => {
    if (isNegotiationError) {
      setNegotiationId(null)
    }
  })

  return (
    <Accordion.Root
      disabled={isLoading}
      type="single"
      value={negotiationId}
      onValueChange={handleOnValueChange}
    >
      <Wrapper>
        <DatepickerWrapper>
          <Datepicker.Root>
            <DatepickerTrigger variant="surface" size={3} onClick={handleEventDatepicker}>
              {selectedPeriod ? dayjs(selectedPeriod).format('MMMM YYYY') : 'Selecione o mês'}
            </DatepickerTrigger>
            <Datepicker.Calendar
              minDetail="month"
              maxDetail="year"
              maxDate={now.toDate()}
              value={selectedPeriod}
              onChange={value => {
                handleApplyFilter(value as Date)
              }}
            />
          </Datepicker.Root>

          <WrapperSeparator />

          <IconButton
            side="left"
            variant="outline"
            disabled={isLoading}
            onClick={() => {
              handleApplyFilter(lastPeriod.toDate())
              handleEventDateNavigation('left')
            }}
          >
            <Icon path={mdiArrowLeft} size={1} />
          </IconButton>
          <IconButton
            side="right"
            variant="outline"
            disabled={isLoading || isCurrentMonthSelected}
            onClick={() => {
              handleApplyFilter(nextPeriod.toDate())
              handleEventDateNavigation('right')
            }}
          >
            <Icon path={mdiArrowRight} size={1} />
          </IconButton>
        </DatepickerWrapper>

        {isLoading &&
          Array.from({ length: 3 }).map((_, index) => (
            <NegotiationCard key={index} isLoading={isLoading} />
          ))}

        {isEmpty && <NegotiationEmpty />}

        {!isEmpty && !isLoading && (
          <>
            <Heading variant="body-1-medium">
              {`Negociações realizadas em ${dayjs(selectedPeriod).format('MMMM [de] YYYY')}`}
            </Heading>

            {guardianHistory?.map((info, ind) => (
              <NegotiationCard
                key={info.id}
                isLoading={isLoading}
                isNegotiationLoading={info.id === negotiationId && isNegotiationLoading}
                info={info}
                negotiation={guardianHistoryNegotiation}
                showDateSeparator={isSameDate(
                  info.created_at,
                  guardianHistory?.[ind - 1]?.created_at
                )}
                onClick={() => {
                  if (negotiationId === info.id) {
                    setNegotiationId(null)
                  }
                }}
              />
            ))}
          </>
        )}
      </Wrapper>
    </Accordion.Root>
  )
}
