import React, { useState, useEffect } from 'react'
import { NetworkError } from '../Errors'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './ErrorFallback'
import { Props } from './types'
import * as Sentry from '@sentry/react'

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const handleOffline = () => {
    setIsOnline(false)
  }

  useEffect(() => {
    window.addEventListener('offline', handleOffline)
  }, [])

  if (!isOnline) {
    return <NetworkError />
  }

  return (
    <ReactErrorBoundary
      FallbackComponent={props => <ErrorFallback {...props} />}
      onError={(error, _errorInfo) => {
        Sentry.captureException(error, { level: 'error' })
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
