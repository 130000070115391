import type { APIResponse } from '@/shared/interfaces/api'
import {
  Contract as FullContract,
  ContractCancellationReason,
  Discount,
  InstallmentStatuses,
  InstallmentType,
} from '@/shared/interfaces'

export type FetchInstallmentsToUpdateRequest = {
  contractId: uuid
  schoolId: uuid
}

export type FetchInstallmentsToUpdateResponse = APIResponse<
  Contract & { can_cancel_contract: boolean; is_installments_left_without_canceling: boolean }
>

export type Contract = {
  created_at: string
  guardian_id: uuid
  guardian_name: string
  id: uuid
  installments: Installment[]
  product_id: uuid
  product_name: string
  reference_year: string
  student_id: uuid
  student_name: string
}

export enum NoEditReason {
  CANCELED = 'CANCELED',
  ENROLLMENT = 'ENROLLMENT',
  HAS_PAID_PAYOUT = 'HAS_PAID_PAYOUT',
  NEGOTIATION = 'NEGOTIATION',
  OTHER = 'OTHER',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
}

export type Installment = {
  amount: number
  backoffice_installment_id: string
  can_edit: boolean
  competence: datestring
  competence_date: string[]
  current_fine: number
  current_interest: number
  discounts: Discount[]
  due_date: string
  no_edit_reason: NoEditReason | ''
  order_reference: string
  overdue_for_days: number
  paid_date: string
  receivable_type: InstallmentType
  status: InstallmentStatuses
}

export type UpdateInstallmentParams = {
  change_actions: {
    update_base_amount: {
      new_value: number
      should_update: boolean
    }
    update_discounts: {
      discounts: Discount[]
      should_update: boolean
    }
    update_due_date: {
      new_date: datestring
      should_update: boolean
    }
  }
  installment_id: uuid
  status: InstallmentStatuses
}

export type UpdateInstallmentsRequest = UpdateInstallmentParams[]

export type UpdateInstallmentsResponse = {
  data: {
    applied_base_amount: Record<uuid, boolean>
    applied_discounts: Record<uuid, boolean>
    applied_due_date: Record<uuid, boolean>
  }
}

export type CancelInstallmentsRequest = {
  contractId: uuid
  correlationID: uuid
  installmentIDs: uuid[]
  schoolId: string
}

export type CancelContractRequest = {
  contractId: uuid
  correlationID: uuid
  description: string
  firstInstallmentId: uuid
  reason: ContractCancellationReason
  schoolId: string
  triggeredBy: string
}

export type ContractDetailsRequest = {
  contractId: uuid
}

export type ContractDetailsResponse = APIResponse<Pick<FullContract, 'created_at' | 'status'>>
