import { useEffect } from 'react'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

export const useEvents = (fallbackOptIsVisible: boolean) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  function sendEventCheckboxClick() {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.CHECKOUT_FALLBACK,
        name: EventDispatcherEvents.CHECKBOX_SELECTED,
        action: 'click',
        customProperties: { $Checkbox_Name: 'Confirmação' },
      })
  }

  useEffect(() => {
    if (fallbackOptIsVisible && isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.CHECKOUT_FALLBACK,
        name: EventDispatcherEvents.ALERT_VIEWED,
        action: 'component_view',
        customProperties: { $Alert_Name: 'Conexão demorando' },
      })
    }
  }, [isInitialized, fallbackOptIsVisible])

  return { sendEventCheckboxClick }
}
