import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'

import { Divider } from '@material-ui/core'

import {
  InstallmentDrawerHeader,
  InfoRow,
  InstallmentDrawerActions,
  InfoText,
  InfoWrapper,
  InstallmentContentWrapper,
  FooterContainer,
  InstallmentDrawerActionWrapper,
  ContentWrapper,
} from '@/modules/guardians/InstallmentsDrawerContainer/components'
import { formatter } from '@/modules/guardians/GuardianDetailsInstallmentsTab/utils/formatter'
import { Notification } from '@olaisaac/design-system'
import { Discount } from '../../components/Discount/Discount'
import { InstallmentDrawerProps } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import { DrawerVariationsProps } from '@/modules/guardians/InstallmentsDrawerContainer/variations'
import { useInstallmentDrawer } from '@/modules/guardians/InstallmentsDrawerContainer/variations/Installment/hooks/useInstallmentDrawer'

export const installment = ({
  installment: installmentInfo,
  onCloseDrawer,
}: InstallmentDrawerProps): DrawerVariationsProps => {
  const {
    discounts,
    student_name,
    product_name,
    original_amount,
    current_amount,
    current_interest,
    current_fine,
    credit_card_fee,
    is_from_credit_card_flow,
  } = installmentInfo

  const { headerTitle, headerStatusInfo, notification, footerMessage } = useInstallmentDrawer({
    installment: installmentInfo,
  })

  const header = (
    <InstallmentDrawerHeader
      installment={installmentInfo}
      title={headerTitle}
      statusInfo={headerStatusInfo}
      onClose={onCloseDrawer}
    />
  )

  const content = (
    <>
      {notification && (
        <Notification description={notification.description} variation={notification.variation} />
      )}

      <ContentWrapper>
        <InfoWrapper>
          <InfoText variant="body-2-regular">{student_name}</InfoText>
          <Heading variant="heading-h4-medium">{product_name}</Heading>
        </InfoWrapper>

        <Divider />

        <InstallmentContentWrapper>
          <InfoRow label="Valor integral" value={formatter.amount(original_amount)} />

          {discounts?.map(({ type, is_active, total_amount, discounts: items }, key) => (
            <Discount
              key={key}
              type={type}
              isActive={is_active}
              totalAmount={total_amount}
              items={items}
            />
          ))}

          {is_from_credit_card_flow && (
            <InfoRow label="Taxa do cartão" value={`+ ${formatter.amount(credit_card_fee)}`} />
          )}

          {current_interest > 0 && (
            <InfoRow label="Juros" value={`+ ${formatter.amount(current_interest)}`} />
          )}

          {current_fine > 0 && (
            <InfoRow label="Multa" value={`+ ${formatter.amount(current_fine)}`} />
          )}
        </InstallmentContentWrapper>

        <Divider />

        <InfoRow label="Valor a pagar" value={formatter.amount(current_amount)} />
      </ContentWrapper>
    </>
  )

  const footer = (
    <FooterContainer>
      <Text variant="body-1-regular">{footerMessage}</Text>
      <InstallmentDrawerActionWrapper>
        <InstallmentDrawerActions onCloseDrawer={onCloseDrawer} />
      </InstallmentDrawerActionWrapper>
    </FooterContainer>
  )

  return {
    header,
    content,
    footer,
  }
}
