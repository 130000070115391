import { formatCentsToReal } from 'src/shared/utils'

export const formatTextMaxInstallmentsAndValue = (maxInstallments, totalAmount) =>
  maxInstallments === 1
    ? `${formatCentsToReal(totalAmount)} à vista sem juros`
    : `Até ${maxInstallments}x de ${formatCentsToReal(totalAmount / maxInstallments)} - sem juros`

export const formatInstallmentTextCount = (
  overdueCount: number,
  ondueCount: number,
  totalAmount: number
) => {
  let needPlural = overdueCount === 1 ? '' : 's'
  if (overdueCount === 0) {
    needPlural = ondueCount === 1 ? '' : 's'
    return `${ondueCount} parcela${needPlural} a vencer - ${formatCentsToReal(totalAmount)}`
  }
  if (ondueCount === 0) {
    return `${overdueCount} parcela${needPlural} vencida${needPlural} - ${formatCentsToReal(
      totalAmount
    )}`
  }
  return `${overdueCount} parcela${needPlural} vencida${needPlural} e ${ondueCount} a vencer - ${formatCentsToReal(
    totalAmount
  )}`
}
