import { DialogProps } from '@gravity/dialog'
import { CancelModalVariants } from '.'
import { pluralize } from '@/shared/utils/pluralize'

type CancelContractModalTitleProps = {
  hasOverdueInstallments: boolean
  installmentsQuantity?: number
  is_installments_left_without_canceling: boolean
  variant: CancelModalVariants
}

export const formatCancelContractModalTitle = ({
  hasOverdueInstallments,
  is_installments_left_without_canceling,
  variant,
  installmentsQuantity,
}: CancelContractModalTitleProps): DialogProps['title'] => {
  if (variant === 'overdue-act' || variant === 'overdue-acknowledge') {
    if (hasOverdueInstallments && is_installments_left_without_canceling) {
      return 'O que acontece com parcelas pagas, negociadas e vencidas'
    }

    if (hasOverdueInstallments) {
      return `Este contrato tem ${pluralize('parcela', installmentsQuantity, true)} ${pluralize(
        'vencida',
        installmentsQuantity,
        false
      )}`
    }
    return 'O que acontece com parcelas pagas e negociadas'
  }

  if (variant === 'non-cancellable') {
    return 'Não é possível cancelar este contrato'
  }

  return 'Cancelamento de contrato'
}
