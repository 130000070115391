import { Box } from '@material-ui/core'
import { FilterListRounded } from '@material-ui/icons'
import { Button, Chip } from '@olaisaac/design-system'
import { FilterButtonProps } from './types'
import theme from '@/shared/theme'

const StyledChip = {
  color: theme.primitiveTokens.colors.isaacBlue[60],
  backgroundColor: theme.primitiveTokens.colors.isaacBlue[10],
}

export const FilterButton: React.FC<FilterButtonProps> = ({
  onClick,
  selectedFiltersCount,
  ...props
}) => {
  return (
    <Button onClick={onClick} variation="ghost" startIcon={<FilterListRounded />} {...props}>
      Filtrar
      {selectedFiltersCount > 0 && (
        <Box ml="0.5rem">
          <Chip label={`${selectedFiltersCount}`} variation="gray" style={StyledChip} />
        </Box>
      )}
    </Button>
  )
}
