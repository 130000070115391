import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { UseInstallmentsQueryProps, UseInstallmentsQueryResponse } from '../../services/types'
import { useApi } from '@/shared/hooks'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { guardianService } from '../../services'

export const useGuardiansInstallmentsQuery = (
  props: UseInstallmentsQueryProps,
  options?: UseQueryOptions<UseInstallmentsQueryResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApi()

  const service = guardianService(apiClient.privateApiV2)

  const queryKey = ['guardian-details-installments', props.guardianId, props.filter]

  const {
    data: guardiansInstallments,
    isLoading: isFetchGuardiansInstallmentsLoading,
    isError: isFetchGuardiansInstallmentsError,
    isFetched: isFetchGuardiansInstallmentsFetched,
    refetch: refetchGuardiansInstallments,
    isRefetching: isRefetchingGuardiansInstallments,
  } = useQuery<UseInstallmentsQueryResponse, XMLHttpRequest>(
    queryKey,
    () => {
      return service.fetchInstallments({
        guardianId: props.guardianId,
        filter: props.filter,
        schoolId: props.schoolId,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      retry: false,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: true,
      ...options,
    }
  )

  return {
    guardiansInstallments,
    isFetchGuardiansInstallmentsLoading,
    isFetchGuardiansInstallmentsError,
    isFetchGuardiansInstallmentsFetched,
    isRefetchingGuardiansInstallments,
    refetchGuardiansInstallments,
  }
}
