import styled from 'styled-components'
import MenuBook from '@material-ui/icons/MenuBook'
import { Typography } from '@olaisaac/design-system'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.spacing(3)}px;
`

export const MenuBookIcon = styled(MenuBook)`
  color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
`

export const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

export const Label = styled(Typography).attrs(props => ({
  ...props,
  withoutMargin: true,
  color: 'secondary',
  variation: 'bodySmall',
}))`
  padding-right: ${({ theme }) => theme.spacing(8)}px;
`
