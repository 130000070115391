export const HEADER_CELL = [
  {
    name: 'category',
    label: 'Categoria',
  },
  {
    name: 'date',
    label: 'Data',
  },
  {
    name: 'status',
    label: 'Status',
  },
  {
    name: 'subject',
    label: 'Assunto',
  },
  {
    name: 'notification',
    label: 'Aviso',
  },
]
