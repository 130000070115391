import { Box } from '@material-ui/core'
import { Notification } from '@olaisaac/design-system'

type NotificationCheckoutDialogProps = {
  isOverdue?: boolean
  shortIds?: string[]
}

export const NotificationCheckoutDialog = ({
  isOverdue = true,
  shortIds,
}: NotificationCheckoutDialogProps) => {
  if (!shortIds || shortIds?.filter(Boolean).length === 0) return null

  const getShortIds = () => {
    const shortIdsLength = shortIds.length
    return shortIds.reduce((acc, curr, index) => {
      const separator = shortIdsLength > 1 && index === shortIdsLength - 1 ? ' e' : ','
      return acc ? `${acc}${separator} #${curr}` : `#${curr}`
    }, '')
  }

  let title =
    shortIds.length > 1
      ? 'O código identificador das faturas vencidas mudaram'
      : 'O código identificador da fatura vencida mudou'

  if (!isOverdue) {
    title =
      shortIds.length > 1
        ? 'O código identificador das faturas mudaram'
        : 'O código identificador da fatura mudou'
  }

  const description =
    shortIds.length > 1
      ? `As faturas agora serão identificadas como ${getShortIds()}`
      : `A fatura agora será identificada como ${getShortIds()}`

  return (
    <Box width="100%">
      <Notification variation="information" title={title} description={description} />
    </Box>
  )
}
