import { Button, ButtonDocker } from '@olaisaac/design-system'

type ManualSignDrawerActionsProps = {
  submitHandler: () => void
}

export const ManualSignDrawerActions = ({ submitHandler }: ManualSignDrawerActionsProps) => (
  <ButtonDocker>
    <Button variation="primary" fullWidth onClick={submitHandler}>
      Confirmar assinatura
    </Button>
  </ButtonDocker>
)
