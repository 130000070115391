import { IconButton } from '@material-ui/core'
import styled from 'styled-components'
import theme from '@/shared/theme'

const { spacing } = theme.primitiveTokens

export const StyledIconButton = styled(IconButton)`
  border-radius: 8px;
  padding: ${spacing[1]};
`
