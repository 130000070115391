import { useEffect, useState } from 'react'
import { ManualLiquidationFields } from '../../types'
import { getFields } from '../../helper'

const useManageFields = (realm: string, isEdition = false) => {
  const [fieldsStatus, setFieldsStatus] = useState(getFields(realm, isEdition))

  useEffect(() => {
    setFieldsStatus(getFields(realm, isEdition))
  }, [realm])

  const getOpenedField = () =>
    Object.entries(fieldsStatus).find(([, oldSelectedField]) => oldSelectedField.isOpen)

  const openField = (selectField: ManualLiquidationFields) => {
    setFieldsStatus({
      ...fieldsStatus,
      [selectField]: {
        ...fieldsStatus[selectField],
        isOpen: true,
        isDirty: true,
      },
    })
  }

  const closeField = (selectField: ManualLiquidationFields) => {
    setFieldsStatus({
      ...fieldsStatus,
      [selectField]: {
        ...fieldsStatus[selectField],
        isOpen: false,
        isDirty: true,
      },
    })
  }

  const closeOldAndOpenSelectedField = (
    oldSelectedField: ManualLiquidationFields,
    selectField: ManualLiquidationFields
  ) => {
    const oldFieldStatus = fieldsStatus[oldSelectedField]
    setFieldsStatus({
      ...fieldsStatus,
      [oldSelectedField]: {
        ...oldFieldStatus,
        isOpen: false,
      },
      [selectField]: {
        ...fieldsStatus[selectField],
        isOpen: true,
        isDirty: true,
      },
    })
  }

  const openNextIfNeed = (
    openedField: ManualLiquidationFields,
    nextField: ManualLiquidationFields
  ) => {
    const needOpen = !fieldsStatus[nextField].isDirty

    if (needOpen) {
      closeOldAndOpenSelectedField(openedField, nextField)
      return
    }
    closeField(openedField)
  }

  const toggleField = (field: ManualLiquidationFields, nextField?: ManualLiquidationFields) => {
    const openedField = getOpenedField()

    if (nextField) {
      openNextIfNeed(field, nextField)
      return
    }

    if (!openedField) {
      openField(field)
      return
    }

    const [key] = openedField

    if (key === field) {
      closeField(field)
      return
    }

    closeOldAndOpenSelectedField(ManualLiquidationFields[key], field)
  }

  const isFieldDisabled = (field: ManualLiquidationFields) => {
    if (fieldsStatus[field].isOpen) {
      return false
    }

    return Object.values(fieldsStatus).some(fieldStatus => fieldStatus.isOpen)
  }

  return { fieldsStatus, toggleField, isFieldDisabled }
}

export default useManageFields
