import styled from 'styled-components'

import theme from '@/shared/theme'

export const SIDE_MENU_WIDTH = '96px'
const HEADER_CONTAINER_BACKGROUND = theme.primitiveTokens.colors.gray[0]

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`

export const SideMenuContainer = styled.div`
  width: ${SIDE_MENU_WIDTH};
  flex: none;
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const PageContent = styled.main`
  flex: 1;
`

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${HEADER_CONTAINER_BACKGROUND};
`
