import { Fragment, ReactNode } from 'react'
import { Box, CircularProgress } from '@material-ui/core'

import { formatCentsToReal } from 'src/shared/utils'
import { NotificationCheckoutDialog } from 'src/escolas/components/modal/NotificationCheckoutDialog'

import { Message, StyledBoxLoading, StyledDialog, StyledDialogContent, Title } from './styles'

interface StatusDialogProps {
  children?: ReactNode
  guardianName: string
  isOpen: boolean
  isOverdue?: boolean
  isTextVisible?: boolean
  posName: string
  shortIds?: string[]
  title?: string
  totalAmount: number
}

const StatusDialog = ({
  children,
  guardianName,
  isOpen,
  isOverdue = true,
  isTextVisible,
  posName,
  totalAmount,
  shortIds,
  title = 'Comunicando com a maquininha',
}: StatusDialogProps) => {
  const createListMessage = () => {
    if (isTextVisible) {
      return [
        <Fragment key="code">
          <b>Nome do responsável: </b>
          {guardianName}
        </Fragment>,
        <Fragment key="total-amount">
          <b>Valor a pagar: </b>
          {formatCentsToReal(totalAmount)}
        </Fragment>,
        <Fragment key="pos">
          <b>Maquininha: </b>
          {posName}
        </Fragment>,
      ]
    }
  }

  return (
    <StyledDialog open={isOpen}>
      <StyledDialogContent>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          paddingX={2}
        >
          <StyledBoxLoading>
            <CircularProgress style={{ width: '120px', height: 'auto' }} />
          </StyledBoxLoading>
          <Title>{title}</Title>
          <Box width="100%" display="flex" flexDirection="column" justifyContent="start" margin={2}>
            {createListMessage()?.map(message => (
              <Message key={`option-message-${message.key}`} withoutMargin>
                {message}
              </Message>
            ))}
          </Box>
        </Box>
        {children}
        {isTextVisible && <NotificationCheckoutDialog shortIds={shortIds} isOverdue={isOverdue} />}
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default StatusDialog
