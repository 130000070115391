import { colors } from "@gravity/tokens";
import { ScoreNumber } from "./types";

export const taxIdLength = 11

export const scoreTextMapping  = {
  1: "Risco muito alto",
  2: "Risco alto",
  3: "Risco moderado",
  4: "Risco baixo",
  5: "Risco muito baixo",
// eslint-disable-next-line prettier/prettier
} as const satisfies Record<ScoreNumber,string>

export const scoreDescriptionMapping  = {
  1: "O responsável financeiro apresenta um risco muito alto de inadimplência, com histórico frequente de dívidas no mercado e uma chance significativa de não realizar o pagamento parcial ou integral.",
  2: "O responsável financeiro tem um histórico de algumas inadimplências no mercado, apresentando risco de atrasos significativos e possível não pagamento.",
  3: "O responsável financeiro tem um histórico de pagamentos razoável, com alguns atrasos eventuais, mas sem inadimplências recorrentes ou de valor significativo.",
  4: "O responsável financeiro tem um bom perfil, com poucos atrasos ou nenhuma inadimplência, sendo um pagador confiável e de baixo risco de inadimplência.",
  5: "O responsável financeiro tem um ótimo histórico de pagamento, sem inadimplências ou atrasos significativos, demonstrando um comportamento financeiro estável e confiável.",
// eslint-disable-next-line prettier/prettier
} as const satisfies Record<ScoreNumber,string>


export const scoreColorMapping  = {
  1: colors["colors-background-semantic-colors-error-1"],
  2: colors["colors-background-semantic-colors-error-1"],
  3: colors["colors-background-semantic-colors-warning-1"],
  4: colors["colors-background-semantic-colors-success-1"],
  5: colors["colors-background-semantic-colors-success-1"],
// eslint-disable-next-line prettier/prettier
} as const satisfies Record<ScoreNumber,string>