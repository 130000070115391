import { FC } from 'react'
import InstallmentDetail from '../../../components/InstallmentDetail'
import { EntryDetailsProps } from './types'
import { adaptEntryDetails } from '../../helper/adaptEntryDetails'
import { useSelectPos } from '@/escolas/hooks'
import { HelpButtonEntryProps } from '../../../components/HelpButton/types'

const EntryDetails: FC<EntryDetailsProps> = ({ goBack, onFinish, entryInformation }) => {
  const { isLoading } = useSelectPos()

  const helpButtonEntry: HelpButtonEntryProps = {
    productName: entryInformation.product[0].name,
    studentName: entryInformation.student.name,
    amount: entryInformation.value,
    orderReference: entryInformation.orderReference,
  }
  return (
    <InstallmentDetail
      goBack={goBack}
      installmentsByStudents={adaptEntryDetails(entryInformation)}
      isLoading={isLoading}
      onFinish={onFinish}
      totalAmount={entryInformation?.value}
      stepName="entry-details"
      hideDiscount
      helpButtonEntry={helpButtonEntry}
    />
  )
}

export default EntryDetails
