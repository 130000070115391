import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useApi } from '@/shared/hooks'
import { GetSchoolEnrollmentCyclesResponse } from '../../../services/types'
import { schoolsEnrollmentCycleService } from '@/modules/enrollment/services'

export const useGetSchoolEnrollmentCycles = (
  schoolId: string,
  options?: UseQueryOptions<GetSchoolEnrollmentCyclesResponse>
) => {
  const { apiClient } = useApi()

  const service = schoolsEnrollmentCycleService(apiClient.privateApi)

  return useQuery<GetSchoolEnrollmentCyclesResponse>(
    ['school-enrollment-cycle', schoolId],
    () => service.getSchoolEnrollmentCycles(schoolId),
    options
  )
}
