import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: baseline;

  padding-top: ${props => props.theme.gravity.spacing[2]};

  .currency {
    margin-right: ${props => props.theme.gravity.spacing[1]};
  }
`
