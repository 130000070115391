import styled from 'styled-components'
import { Link as OlaisaacLink } from '@olaisaac/design-system'

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1.5rem 1.5rem 0;
  overflow-y: auto;

  h2 {
    font-weight: 500;
    margin: 0.5rem 0;
    font-size: 1.25rem;
    font-family: 'Roboto';
    color: #414141;
  }

  p {
    margin: 0;
    font-family: 'Roboto';
    font-size: 1rem;
    line-height: 1.5rem;

    & + p {
      margin-top: 1.5rem;
    }
  }

  svg {
    opacity: 0.6;
  }
`

export const InfoItem = styled.div`
  margin-bottom: 2rem;
  border-bottom: 1px solid #dadada;
  padding-bottom: 2rem;

  .date-info-title {
    margin-top: 0;
  }
`

export const DateInfo = styled.div`
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 2rem;
  }

  p {
    margin: 0.5rem 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6d6d6d;
  }

  time {
    font-family: 'Roboto';
    color: #414141;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export const ContactInfo = styled.div`
  margin-bottom: 2.625rem;

  h2 {
    margin-bottom: 0;
  }

  .label {
    margin: 0.5rem 0 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6d6d6d;
  }

  .contact {
    margin: 0.5rem 0 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export const Link = styled(OlaisaacLink)`
  color: #414141;
  font-size: 1rem;
  text-decoration: underline;
`
