import { UnleashFlags, useUnleashFlag } from './useUnleashFlag'
import { UseJWTResponse, useJWT } from './useJWT'
import { ROLES, ROLES_ISAAC } from '../constants'
import { useSelectedSchool } from './useSelectedSchool'
import { useState } from 'react'

const getRoleType = (jwtResponse: UseJWTResponse) => {
  const { hasPayoutReportAccess, isGroupManager } = jwtResponse

  if (isGroupManager) {
    return ROLES_ISAAC[ROLES.GROUP_MANAGER]
  }

  if (hasPayoutReportAccess) {
    return 'Mantenedor'
  }

  return 'Secretaria'
}

export const getSegmentFilters = (jwtResponse: UseJWTResponse) => {
  const { school } = useSelectedSchool()
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const { email } = jwtResponse

  const roleType = getRoleType(jwtResponse)
  const schoolType = isIntegratedSchool ? 'Integradas' : 'Não Integradas'
  const enrollmentStatus = 'Matrícula'

  const filter1 = `${roleType} + ${schoolType}`
  const filter2 = `${roleType} + ${enrollmentStatus}`
  const filter3 = `${roleType} + ${schoolType} + ${enrollmentStatus}`
  const accumulatedFilters = `${filter1};${filter2};${filter3}`

  return `${school?.slug};${email};${roleType};${accumulatedFilters}`
}

const appendConfigScript = (segmentFilters: string, userId: string, schoolId: string) => {
  const configScript = document.createElement('script')

  const config = document.createTextNode(`
  var beamer_config = {
    language: 'PT-br',
    product_id: 'FiCVaAyF56437',
    selector: '#beamerButton',
    right: '-2',
    top: '-2',
    display: 'popup',
    display_position: 'bottom',
    filter: '${segmentFilters}',
    user_id: '${userId}',
    user_firstname: '${schoolId}',
    user_lastname: ' ',
  }
  `)
  configScript.appendChild(config)

  return document.head.appendChild(configScript)
}

const appendBeamerScript = () => {
  const beamerScript = document.createElement('script')
  beamerScript.setAttribute('type', 'text/javascript')
  beamerScript.setAttribute('src', 'https://app.getbeamer.com/js/beamer-embed.js')
  beamerScript.setAttribute('defer', 'defer')

  return document.head.appendChild(beamerScript)
}

export const useBeamer = () => {
  const [configScript, setConfigScript] = useState<HTMLScriptElement>(null)
  const [beamerScript, setBeamerScript] = useState<HTMLScriptElement>(null)

  const jwt = useJWT()
  const { school } = useSelectedSchool()

  const segmentFilters = getSegmentFilters(jwt)

  return {
    init: () => {
      if (configScript) {
        configScript.remove()
        setConfigScript(null)
      }

      const configScriptNode = appendConfigScript(segmentFilters, jwt.userId, school.id)
      setConfigScript(configScriptNode)

      if (!beamerScript) {
        const beamerScriptNode = appendBeamerScript()
        setBeamerScript(beamerScriptNode)
      }

      // update notification count badge
      const beamer = (window as any).Beamer
      beamer?.clearAlert()
      beamer?.appendAlert(true, true)
    },
  }
}
