import { useProfileDetails } from '@/modules/school-home/hooks/queries/enrollment-request/useProfileDetails'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { useApi } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { APIResponse, TicketsSummaryList } from '@/shared/interfaces'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export const useGetListTicketSummary = (
  options?: UseQueryOptions<APIResponse<TicketsSummaryList>>
) => {
  const { api } = useApi()
  const { school } = useSelectedSchool()
  const { isMaintainer } = useProfileDetails()

  return useQuery<APIResponse<TicketsSummaryList>>(
    ['tickets-summary', 'list', school?.id],
    () => api.ticketsSummary.getList(school?.id),
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: !!school && isMaintainer,
      retry: 2,
      ...options,
    }
  )
}
