import { Link } from 'react-router-dom'
import { Link as DSLink } from '@gravity/link'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'

import IsaacLogo from '@/shared/assets/isaac-logo.svg'
import Graphism from '@/shared/assets/graphism.svg'

import { Container, Content, Logo } from './styles'

export const NotFound = () => {
  return (
    <Container>
      <Content>
        <img src={IsaacLogo} alt="isaac" className="logo-isaac" />

        <Heading variant="heading-h3-regular" className="title">
          Página não encontrada
        </Heading>

        <Text variant="body-2-regular">Não foi possível encontrar a página solicitada.</Text>

        <Link to="/" component={DSLink}>
          Voltar para o início
        </Link>
      </Content>

      <Logo src={Graphism} alt="Graphism" />
    </Container>
  )
}
