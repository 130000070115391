import { Button } from '@olaisaac/design-system'
import { UpdateEnrollmentStatusProps } from './types'
import { timestampToMMSS } from '../../utils/timestampToMMSS'
import { useEffect, useState } from 'react'
import { UnleashFlags, useUnleashFlag, useSnackbar } from '@/shared/hooks'
import { Tooltip } from '@material-ui/core'
import { AutorenewRounded } from '@material-ui/icons'
import { useRefreshStudentSituation } from './hooks/useRefreshStudentSituation'
import { LOCALSTORAGE_TIMESTAMP_KEY, RETRY_TIMEOUT_MS } from './constants'
import { useCountdown } from './hooks/useCountdown'

export const UpdateEnrollmentStatus: React.FC<UpdateEnrollmentStatusProps> = ({
  schoolId,
  studentIds,
  isLoadingInstallments,
}) => {
  const [installmentsHaveBeenUpdated, setInstallmentsHaveBeenUpdated] = useState<boolean>(false)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const { setIsOpen, setMessage, setVariation, setKeepOpen } = useSnackbar()

  const hasBufferInstallments = studentIds?.length > 0

  const { handleRefreshStudentsSituation, isLoading } = useRefreshStudentSituation(
    schoolId,
    studentIds
  )

  const { setExpTimestamp, shouldWait, timestampDiff } = useCountdown(setShowTooltip)

  const showStudentSituationSuccess = () => {
    setMessage('A situação das parcelas foi atualizada com sucesso')
    setVariation('success')
    setIsOpen(true)
  }

  if (!hasBufferInstallments && installmentsHaveBeenUpdated) {
    showStudentSituationSuccess()
  }

  useEffect(() => {
    setKeepOpen(true)

    return () => {
      setKeepOpen(false)
    }
  }, [])

  const showStudentSituationWarning = () => {
    setMessage(
      'Se algum pagamento foi feito pode ser que ele ainda não tenha sido compensado, tente novamente mais tarde.'
    )
    setVariation('warning')
    setIsOpen(true, {
      title: 'Não houve mudanças na situação da parcela.',
    })
  }

  const handleButtonClick = async () => {
    setShowTooltip(false)
    await handleRefreshStudentsSituation()
    setInstallmentsHaveBeenUpdated(true)
  }

  useEffect(() => {
    if (!isLoadingInstallments && installmentsHaveBeenUpdated) {
      const exp = Date.now() + RETRY_TIMEOUT_MS
      localStorage.setItem(LOCALSTORAGE_TIMESTAMP_KEY, exp.toString())
      setExpTimestamp(exp)

      showStudentSituationWarning()
      setInstallmentsHaveBeenUpdated(false)
    }
  }, [isLoadingInstallments])

  const isLoadingInstallmentsOrLoadingSituation = isLoadingInstallments || isLoading

  return (
    hasBufferInstallments &&
    !isIntegratedSchool && (
      <Tooltip
        title="Utilize caso tenha recebido algum pagamento que pode alterar a situação de parcela do aluno. Para pagamentos em boleto, o tempo de compensação pode ser até 3 dias."
        open={showTooltip && !shouldWait && !isLoadingInstallmentsOrLoadingSituation}
        placement="bottom"
      >
        <Button
          variation="ghost"
          startIcon={shouldWait ? undefined : <AutorenewRounded />}
          onClick={handleButtonClick}
          loading={isLoadingInstallmentsOrLoadingSituation}
          disabled={shouldWait || isLoadingInstallments}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {shouldWait
            ? `Atualização disponível em ${timestampToMMSS(timestampDiff)}`
            : 'Atualizar situação'}
        </Button>
      </Tooltip>
    )
  )
}
