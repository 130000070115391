import { Typography } from '@olaisaac/design-system'
import CircularProgress from '@material-ui/core/CircularProgress'

import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(10)}px;
`

export const Separator = styled.div`
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  height: 1px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const FullAmount = styled(Typography).attrs(props => ({
  ...props,
  color: 'secondary',
}))`
  text-decoration: line-through;
  font-size: 20px;
  font-weight: normal;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`
export const StyledLoading = styled(CircularProgress)`
  width: 20px;
  height: 20px;
`
