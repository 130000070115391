import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'

const useDownPaymentFlow = () => {
  const { checkoutCart } = useCheckoutCart()
  const downPayments = checkoutCart?.filter(
    i =>
      (i.type === 'DOWN_PAYMENT' || i.is_from_credit_card_flow) &&
      i.urn.includes('urn:down-payment')
  )
  return {
    downPayment: downPayments && downPayments.length > 0 ? downPayments[0] : [],
    isDownPaymentEnabled: downPayments?.length === 1,
  }
}

export default useDownPaymentFlow
