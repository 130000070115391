import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

const StyledOverlay = styled.div`
  opacity: 0.87;
  position: absolute;
  background: #fff;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadingProgress = () => {
  return (
    <StyledOverlay>
      <CircularProgress />
    </StyledOverlay>
  )
}

export default LoadingProgress
