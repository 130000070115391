import { FILES } from '@/shared/constants'

/**
 * Function to download a file
 *
 * @param fileName File name
 * @param type File type
 * @param source Promise that returns the file blob
 */
export const downloadFile = async (
  fileName: string,
  type: keyof typeof FILES,
  source: () => Promise<Blob>
) => {
  const fileType = FILES[type]
  const link = document.createElement('a')

  const response = await source()

  const file = new Blob([response], { type: fileType.contentType })
  const fileURL = URL.createObjectURL(file)

  link.href = fileURL
  link.setAttribute('download', fileName)
  document.body.appendChild(link)

  link.click()

  document.body.removeChild(link)
}
