import styled from 'styled-components'

import theme from '@/shared/theme'

type LabelProps = {
  color?: 'primary' | 'secondary'
  weight?: 'normal' | 'bold'
}

const SECONDARY_COLOR = theme.primitiveTokens.colors.gray[60]
const PRIMARY_COLOR = theme.primitiveTokens.colors.gray[80]

export const Label = styled.p<LabelProps>`
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: ${props => (props.weight === 'bold' ? 700 : 400)};
  color: ${props => (props.color === 'secondary' ? SECONDARY_COLOR : PRIMARY_COLOR)};
`
