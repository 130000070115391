import {
  Installment,
  UpdateInstallmentParams,
  UpdateInstallmentsRequest,
} from '@/modules/contract/services/types'

function getInstallmentPayload(
  originalInstallment: Installment,
  updatedInstallment: Installment
): UpdateInstallmentParams {
  return {
    status: updatedInstallment.status,
    installment_id: updatedInstallment.backoffice_installment_id,
    change_actions: {
      update_base_amount: {
        new_value: updatedInstallment.amount,
        should_update: originalInstallment.amount !== updatedInstallment.amount,
      },
      update_discounts: {
        discounts: updatedInstallment.discounts,
        should_update: originalInstallment.discounts !== updatedInstallment.discounts,
      },
      update_due_date: {
        should_update: originalInstallment.due_date !== updatedInstallment.due_date,
        new_date: updatedInstallment.due_date,
      },
    },
  }
}

function filterByUnchangedInstallments(updateInstallmentParams: UpdateInstallmentParams) {
  type ChangeActionItemsKey = keyof Pick<
    UpdateInstallmentParams['change_actions'],
    'update_base_amount' | 'update_discounts' | 'update_due_date'
  >

  const changeActionsKey: Array<ChangeActionItemsKey> = [
    'update_base_amount',
    'update_discounts',
    'update_due_date',
  ]
  return changeActionsKey.some(
    changeActionKey => updateInstallmentParams.change_actions[changeActionKey].should_update
  )
}

export function createUpdateInstallmentsPayload(
  originalInstallments: Installment[],
  updatedInstallments: Installment[]
): UpdateInstallmentsRequest {
  return originalInstallments
    .map((originalInstallment, index) => {
      return getInstallmentPayload(originalInstallment, updatedInstallments[index])
    })
    .filter(filterByUnchangedInstallments)
}
