import { SCORE_SCHOOL_QUERY_KEY } from '@/modules/enrollment/constants/score'
import { scoreService } from '@/modules/enrollment/services/scoreService'
import {
  SchoolScoreRequest,
  SchoolScoreResponse,
} from '@/modules/enrollment/services/scoreService/types'

import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { useApi } from '@/shared/hooks'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export const useFetchSchoolScore = (
  props: SchoolScoreRequest,
  options?: UseQueryOptions<SchoolScoreResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApi()

  const service = scoreService(apiClient.privateApi)

  return useQuery<SchoolScoreResponse, XMLHttpRequest>(
    [SCORE_SCHOOL_QUERY_KEY, props],
    () => {
      return service.fetchSchoolScore(props)
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}
