import styled from 'styled-components'
import { Box } from '@material-ui/core'

import { spacing } from '@gravity/tokens'

export const InfoRowWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: ${spacing[1]};
`
