import type { ReactNode } from 'react'
import { View } from './view'

type Props = {
  description: string
  isLoading: boolean
  onClick?: () => void
  title: string | ReactNode
  value: string
}
export const DetailsBigNumber = ({
  title,
  value,
  description,
  onClick,
  isLoading = false,
}: Props) => {
  return (
    <View
      title={title}
      value={value}
      description={description}
      onClick={onClick}
      isLoading={isLoading}
    />
  )
}
