import styled from 'styled-components'
import theme from 'src/shared/theme'

const { spacing, colors } = theme.primitiveTokens

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  width: 480px;
  align-self: center;
  margin: 0 auto;
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[10]};
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const MainText = styled.h2`
  text-align: center;
  color: ${colors.gray[80]};
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 38.4px;
  letter-spacing: -0.5px;
  white-space: nowrap;
`

export const SecondaryText = styled.h4`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
`
