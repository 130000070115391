import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'
import { Card } from '@gravity/card'
import { Button } from '@gravity/button'
import { spacing, colors, fontWeight, fontSize } from '@gravity/tokens'

export const ContainerHeader = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 184px;
`
export const ContainerActualizationPeriod = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${spacing[2]};
`

export const PayoutCardContainer = styled(Card)`
  display: flex;
  min-width: 556px;
  height: 318px;
  padding: ${spacing[8]};
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
`

export const Amount = styled.p`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize['3xl']};
  font-style: normal;
  font-weight: ${fontWeight.medium};
  line-height: 120%; /* 48px */
`

export const Title = styled(Typography)`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.lg};
  font-style: normal;
  font-weight: ${fontWeight.medium};
  line-height: 125%; /* 25px */
`

export const DescriptionAmount = styled(Typography)`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.sm};
  font-style: normal;
  font-weight: ${fontWeight.normal};
  line-height: 150%; /* 21px */
  letter-spacing: 0.25px;
`

export const StrongDescription = styled.strong`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.sm};
  font-style: normal;
  font-weight: ${fontWeight.medium};
  line-height: 150%;
  letter-spacing: 0.25px;
`

export const AnticipationButton = styled(Button)`
  display: flex;
  padding: ${spacing[3]};
  justify-content: center;
  align-items: center;
  gap: ${spacing[2]};
  align-self: stretch;
  width: 200px;
`

export const TitleContainer = styled.div`
  display: flex;
  width: 29.75rem;
  height: ${spacing[10]};
  align-items: center;
  margin-top: ${spacing[2]};
`
