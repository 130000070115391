import type { UserguidingUser } from './types'

function initUserGuiding(window, document, userGuidingId, globalVarName, e) {
  window[e] = window[e] || []
  const f = document.getElementsByTagName('script')[0]
  const k = document.createElement('script')
  k.async = true
  k.src = 'https://static.userguiding.com/media/user-guiding-' + userGuidingId + '-embedded.js'
  f.parentNode.insertBefore(k, f)

  if (window[globalVarName]) return

  const ug: any = (window[globalVarName] = { q: [] })
  ug.c = function (n) {
    return function () {
      // eslint-disable-next-line prefer-rest-params
      ug.q.push([n, arguments])
    }
  }

  const m = [
    'previewGuide',
    'finishPreview',
    'track',
    'identify',
    'triggerNps',
    'hideChecklist',
    'launchChecklist',
  ]
  for (let j = 0; j < m.length; j += 1) {
    ug[m[j]] = ug.c(m[j])
  }
}

function identifyUserguidingUser(userParams: UserguidingUser) {
  const { id } = userParams
  window.userGuiding.identify(id, userParams)
}

function hasLoaded() {
  let times = 0

  return new Promise((resolve, reject) => {
    if (typeof userGuiding !== 'undefined') {
      return resolve(userGuiding)
    }

    const intervalID = window.setInterval(() => {
      times++

      if (typeof userGuiding !== 'undefined') {
        resolve(userGuiding)
      }

      if (times === 50) {
        window.clearInterval(intervalID)
        reject(new Error('Userguiding lib took too long to load'))
      }
    }, 100)
  })
}

function init(
  userParams: UserguidingUser,
  window,
  document,
  userGuidingId,
  globalVarName = 'userGuiding',
  e = 'userGuidingLayer'
) {
  return new Promise((resolve, reject) => {
    initUserGuiding(window, document, userGuidingId, globalVarName, e)
    hasLoaded()
      .then(() => {
        identifyUserguidingUser(userParams)
        resolve(userGuiding)
      })
      .catch(reject)
  })
}

export default {
  init,
  hasLoaded,
}
