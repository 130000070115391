import { pluralize } from '@/shared/utils/pluralize'
import { Typography } from '@olaisaac/design-system'

export const SelectedInstallmentsText = ({ count }: { count: number }) => (
  <Typography variation="bodySmall">{`${pluralize('parcela', count)} ${pluralize(
    'selecionada',
    count,
    false
  )} `}</Typography>
)
