import { useEffect, useState } from 'react'

import { EnabledEnrollmentCycle } from '@/modules/enrollment/services/types'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

export const useSelectCycle = (enabledCycles: EnabledEnrollmentCycle[]) => {
  const [selectedCycleYear, setSelectedCycleYear] = useState(0)
  const isSelectCyclesEnabled = useUnleashFlag(UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES)

  const selectedCycle = enabledCycles.find(cycle => cycle.reference_year === selectedCycleYear)

  const shouldShowSelectCycleYearDropdown =
    isSelectCyclesEnabled && selectedCycle && enabledCycles.length > 1

  useEffect(() => {
    if (selectedCycleYear === 0 && enabledCycles.length) {
      setSelectedCycleYear(enabledCycles[0].reference_year)
    }
  }, [enabledCycles])

  return {
    setSelectedCycleYear,
    shouldShowSelectCycleYearDropdown,
    selectedCycle,
  }
}
