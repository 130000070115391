import { formatCentsToReal } from '@/shared/utils'

const installmentInfoText = (
  numberOfInstallments: number,
  totalAmount: number,
  minDownPayment: number,
  isPaymentFeeEnabled?: boolean
) => {
  return numberOfInstallments === 2
    ? `e uma parcela de ${formatCentsToReal(totalAmount - minDownPayment)}${
        isPaymentFeeEnabled ? '' : ' sem juros'
      }`
    : `até ${numberOfInstallments - 1}x de ${formatCentsToReal(
        (totalAmount - minDownPayment) / (numberOfInstallments - 1)
      )}${isPaymentFeeEnabled ? '' : ' sem juros'}`
}

export const formatBankSlipInfoText = (
  numberOfInstallments: number,
  totalAmount: number,
  minDownPayment: number,
  isPaymentFeeEnabled?: boolean
) => {
  const infoText =
    totalAmount !== minDownPayment
      ? `entrada mínima de ${formatCentsToReal(minDownPayment)} ${installmentInfoText(
          numberOfInstallments,
          totalAmount,
          minDownPayment,
          isPaymentFeeEnabled
        )}`
      : `à vista de ${formatCentsToReal(totalAmount)}${isPaymentFeeEnabled ? '' : ' sem juros'}`

  return infoText
}

export const formatBankSlipTextOptionCard = (
  numberOfInstallments: number,
  totalAmount: number,
  minDownPayment: number,
  isPaymentFeeEnabled?: boolean
) => {
  const infoText =
    totalAmount !== minDownPayment
      ? `Entrada mínima de ${formatCentsToReal(minDownPayment)} ${installmentInfoText(
          numberOfInstallments,
          totalAmount,
          minDownPayment,
          isPaymentFeeEnabled
        )}`
      : `${formatCentsToReal(totalAmount)} à vista${isPaymentFeeEnabled ? '' : ' sem juros'}`

  return infoText
}
