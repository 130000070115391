export const CREDIT_SERVICE_WORKER_URL = '/serviceWorker.js'

export const useServiceWorker = (scriptUrl: string) => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(scriptUrl)
        .then(registration => {
          console.log('ServiceWorker registrado com sucesso: ', registration)
        })
        .catch(registrationError => {
          console.log('Falha ao registrar o ServiceWorker: ', registrationError)
        })
    })
  }
}
