import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import NumberFormat from 'react-number-format'
import { TextField } from '@olaisaac/design-system'

export const CustomFormControl = styled(FormControl).attrs(() => ({
  fullWidth: true,
  variant: 'outlined',
}))``

export const DaysBeforeDueDateInput = styled(NumberFormat).attrs(() => ({
  variant: 'outlined',
  customInput: TextField,
  label: 'Dias antes do vencimento',
  InputProps: {
    inputProps: { min: 1 },
  },
}))``
