import { Link } from '@olaisaac/design-system'
import { ViewConditionsLinkProps } from './types'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  justify-content: 'space-evenly';

  &.disabled {
    color: ${({ theme }) => theme.primitiveTokens.colors.gray[40]};
    text-decoration: none;
  }
`

export const ViewConditionsLink = ({ handleClick, disabled = false }: ViewConditionsLinkProps) => {
  return (
    <StyledLink
      className={`${disabled && 'disabled'}`}
      style={{}}
      onClick={() => !disabled && handleClick()}
    >
      Ver condições do contrato
    </StyledLink>
  )
}
