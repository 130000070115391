import { useState } from 'react'
import { SaveAlt, Help } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import { Drawer, ActionDrawerHeader, Divider, ButtonDocker, Button } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useApi, useSnackbar } from '@/shared/hooks'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { useNavigation } from '@/escolas/hooks'

import { convertToUTCDate, downloadFile, formatMicroCentsToReal } from '@/shared/utils'

import { REPORT_PAGE_NAMES } from '@/modules/report/constants/REPORT_PAGE_NAMES'

import {
  BankTransfer,
  FinancialDetails,
  PayoutVersion,
  TransactionEventType,
} from '@/modules/report/models/report'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { ENROLLMENT_TRANSACTIONS_IN_ORDER } from '../EnrollmentReportTableRowDetailDrawer/utils/transactionsInOrder'

import { Content, SummaryItem as SummaryInfoItem, ButtonContainer, TooltipText } from './styles'

type EnrollmentSummaryInfo = {
  enrollment_end_date: Date
  payoutBankTransfers?: BankTransfer[]
  payoutId: string
  payoutVersion: PayoutVersion
}

type SummaryDrawerProps = {
  financialDetails?: FinancialDetails
  isOpen: boolean
  isPayoutOpen: boolean
  onClose: () => void
  payoutData: EnrollmentSummaryInfo
  summaryPeriod?: 'mensal' | 'semanal'
}

/**
 * TODO:
 * - Get colors from useTheme
 * - Receive drawer content from data structure
 * - Review payoutData attributes names
 *
 * Component to display enrollment payout summary data
 *
 * @param props
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.isPayoutOpen Represents the payout status
 * @param props.onClose Callback function called on request close
 * @param props.payoutData Payout summary data
 * @param props.summaryPeriod Enrollment summary period type
 */
export const EnrollmentSummaryDrawer = ({
  financialDetails,
  payoutData,
  summaryPeriod = 'semanal',
  isPayoutOpen,
  isOpen,
  onClose,
}: SummaryDrawerProps) => {
  const { api } = useApi()
  const { schoolId } = useNavigation()
  const { setMessage, setVariation, setIsOpen } = useSnackbar()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const showDownloadButtonForEnrollmentSummary = useUnleashFlag(
    UnleashFlags.SHOW_DOWNLOAD_BUTTON_FOR_ENROLLMENT_SUMMARY
  )

  const [isLoadingAction, setIsLoadingAction] = useState(false)

  const handleDownloadReport = async (payoutId: string, filename: string) => {
    try {
      setIsLoadingAction(true)
      await downloadFile(filename, 'pdf', () =>
        api.report.fetchUnguaranteedReportFile({ schoolId, id: payoutId, extension: 'pdf' })
      )
    } catch {
      setMessage('Falha ao baixar arquivo. Tente novamente mais tarde.')
      setVariation('error')
      setIsOpen(true)
    } finally {
      setIsLoadingAction(false)

      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
          name: EventDispatcherEvents.ENROLLMENT_PAYOUT_DOWNLOAD_SUMMARY,
          action: 'file_download',
          customProperties: {
            $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
          },
        })
    }
  }

  const formatDate = (date: Date) => {
    const formatter = new Intl.DateTimeFormat('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

    return formatter.format(date)
  }

  const getValueColor = (value: number) => (value < 0 ? '#AB1207' : '#156320')

  const values = {
    ...financialDetails?.events,
    total_base_amount: financialDetails?.total_base_amount,
  }

  const total = financialDetails?.total - (values[TransactionEventType.BALANCE_TRANSFER] || 0)
  const total_fee = -1 * financialDetails?.total_fee

  const bankTransfers = payoutData?.payoutBankTransfers || []

  return (
    <Drawer open={isOpen}>
      <ActionDrawerHeader title="Resumo" onClose={onClose} />

      <Content>
        {Object.keys(ENROLLMENT_TRANSACTIONS_IN_ORDER).map(
          key =>
            !!values[key] && (
              <SummaryInfoItem key={key} variation="primary" color={getValueColor(values[key])}>
                <p className="label">
                  {ENROLLMENT_TRANSACTIONS_IN_ORDER[key].label}

                  {ENROLLMENT_TRANSACTIONS_IN_ORDER[key].description && (
                    <Tooltip
                      title={
                        <TooltipText>
                          {ENROLLMENT_TRANSACTIONS_IN_ORDER[key].description}
                        </TooltipText>
                      }
                    >
                      <Help />
                    </Tooltip>
                  )}
                </p>

                <span>{formatMicroCentsToReal(values[key])}</span>
              </SummaryInfoItem>
            )
        )}

        <Divider variation="secondary" />

        <SummaryInfoItem variation="primary" color={getValueColor(total_fee)}>
          <p className="label">
            Taxa isaac
            <Tooltip title="Valor referente à prestação de serviços isaac">
              <Help />
            </Tooltip>
          </p>

          <span>{formatMicroCentsToReal(total_fee)}</span>
        </SummaryInfoItem>

        <Divider variation="secondary" />

        <SummaryInfoItem variation="secondary">
          <p className="label">
            {total < 0
              ? 'Valor pendente a ser descontado do repasse de mensalidades'
              : 'Valor total'}
          </p>

          <span>{formatMicroCentsToReal(total)}</span>
        </SummaryInfoItem>

        <Divider variation="primary" />

        <SummaryInfoItem variation="tertiary">
          <p className="label">Matrículas pagas até</p>

          <span>{formatDate(payoutData.enrollment_end_date)}</span>
        </SummaryInfoItem>

        {bankTransfers.map((item, index) => {
          return (
            <SummaryInfoItem key={index} variation="tertiary">
              <p className="label">Transferência programada</p>

              <span>{convertToUTCDate(item.date).format('DD/MM/YYYY')}</span>
            </SummaryInfoItem>
          )
        })}
      </Content>

      {showDownloadButtonForEnrollmentSummary && (
        <ButtonDocker>
          <Tooltip
            title={
              isPayoutOpen
                ? 'Disponível apenas para repasses fechados'
                : 'Baixar o arquivo .pdf do período'
            }
          >
            <ButtonContainer>
              <Button
                fullWidth
                startIcon={<SaveAlt />}
                onClick={() => handleDownloadReport(payoutData.payoutId, 'resumo')}
                loading={isLoadingAction}
                disabled={isPayoutOpen}
                data-testid="download-button"
              >
                {summaryPeriod === 'mensal' ? 'Baixar resumo' : 'Baixar extrato'}
              </Button>
            </ButtonContainer>
          </Tooltip>
        </ButtonDocker>
      )}
    </Drawer>
  )
}
