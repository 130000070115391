import { Typography } from '@olaisaac/design-system'
import type { PaymentPlanMethodsProps } from './types'
import BankSlip from 'src/escolas/assets/bankSlip.svg'
import { AvailableMethod, StyledImgWithPadding } from './styles'
import { formatBankSlipInfoText } from '@/modules/guardians/Negotiation/helpers/textBankSlip'

export const InfoPaymentPlanBankSlip = ({
  paymentPlan,
  totalAmount,
  isPaymentFeeEnabled,
}: PaymentPlanMethodsProps) => {
  const { max_installments_allowed, min_down_payment } = paymentPlan
  const numberOfInstallments = max_installments_allowed

  const infoText = formatBankSlipInfoText(
    numberOfInstallments,
    totalAmount,
    min_down_payment,
    isPaymentFeeEnabled
  )

  return (
    <AvailableMethod>
      <StyledImgWithPadding src={BankSlip} />
      <Typography variation="bodySmall" color="secondary">
        <b>Boleto/PIX:</b> {infoText}
      </Typography>
    </AvailableMethod>
  )
}
