import theme from '@/shared/theme'
import styled from 'styled-components'

const { spacing } = theme.primitiveTokens

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 20px 0 10px;
`

export const ActiveFiltersContainer = styled.div<{ haveAppliedFilters: boolean }>`
  flex-basis: 100%;
  padding-left: ${spacing[4]};
  height: ${({ haveAppliedFilters }) => (haveAppliedFilters ? 'auto' : '0')};
  margin-bottom: ${({ haveAppliedFilters }) => (haveAppliedFilters ? '10px' : spacing[8])};
`
