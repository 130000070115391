import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'

export const ModalTypography = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  margin: 16px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`
