import { Button } from '@gravity/button'
import { useHistory } from 'react-router-dom'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import DefaultARTemplate from '@/modules/credit/templates/DefaultARTemplate'
import {
  BaseText,
  BigValueText,
  BoldKeyText,
  BoldValueText,
  ButtonGroup,
  ContainerCard,
  HeaderContainer,
  KeyText,
  ResultAnticipationContainer,
  RowContainer,
  ValueText,
} from './styles'
import {
  useContextChosenOffer,
  useContextSimulation,
} from '@/modules/credit/contexts/PayoutAnticipationContext'
import { dateToFormattedString, formatChosenOfferDate } from '@/modules/credit/utils/formatDate'
import { useEffect, useState } from 'react'
import { useGetOrderStatus } from '@/modules/credit/hooks/queries/useGetOrderStatus'
import { AnticipationSimulationResponse, CreditType } from '@/shared/services/credit/types'
import { LoadingStatus } from './LoadingStatus'
import { Separator } from '@gravity/separator'
import useMixpanelEventSender from '@/modules/credit/utils/useMixpanelEventSender'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '@/modules/credit/constants/MixpanelEventActions.enum'
import { CreditEventComponentNames } from '@/modules/credit/constants/CreditEventComponentNames.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { Link } from '@gravity/link'

export const PayoutAnticipationConfirmedSummary = () => {
  const { push } = useHistory()
  const { school, schoolSlug } = useSelectedSchool()
  const { chosenOffer, setChosenOffer } = useContextChosenOffer()
  const { requestValue, setRequestValue } = useContextSimulation()
  const [loadingStatus, setLoadingStatus] = useState<boolean>(true)
  const schooldID = school?.id ?? ''
  const { data: creditOrder, isLoading } = useGetOrderStatus<AnticipationSimulationResponse>(
    schooldID,
    CreditType.AR
  )

  useEffect(() => {
    if (!isLoading) {
      setLoadingStatus(false)
      if (creditOrder?.data?.offer) {
        setChosenOffer(creditOrder.data.offer)
      }
      if (creditOrder?.data?.offer?.requestValue) {
        setRequestValue(creditOrder.data.offer.requestValue)
      }
    }
  }, [creditOrder])

  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  useEffect(() => {
    sendCreditEventToMixpanel(
      EventDispatcherEvents.PAGE_VIEWED,
      MixpanelEventActions.PAGE_VIEW,
      {
        $page_name: CreditEventComponentNames.ANTICIPATION_CONFIRMED,
      },
      EventDispatcherEventScopes.PAYOUT_ANTICIPATION
    )
  }, [])

  if (loadingStatus) return <LoadingStatus />

  return (
    <DefaultARTemplate
      hasBackButton={false}
      title="Antecipação de repasse confirmada"
      child={
        <>
          <BaseText>
            Em até 1 dia útil, a equipe de crédito entrará em contato para avançar com a
            contratação. Veja os documentos necessários{' '}
            <Link
              href="https://centraldeajuda.olaisaac.io/repasse/o-que-e-antecipacao-de-repasse-no-isaac#documentos-necessrios-para-contratar-antecipao-de-repasse-no-isaac-1"
              target="_blank"
              rel="noreferrer"
              size={3}
            >
              aqui
            </Link>
            .
          </BaseText>
          <ContainerCard>
            <HeaderContainer>
              <RowContainer>
                <BoldKeyText>
                  Antecipação solicitada em {formatChosenOfferDate(chosenOffer.chosenOfferDate)}
                </BoldKeyText>
              </RowContainer>
            </HeaderContainer>
            <Separator color="neutral-2" orientation="horizontal" />

            <ResultAnticipationContainer>
              <RowContainer>
                <BoldKeyText>Valor a receber</BoldKeyText>
                <BigValueText>R$ {requestValue}</BigValueText>
              </RowContainer>

              <RowContainer>
                <KeyText>Data de recebimento</KeyText>
                <ValueText>{chosenOffer.disbursementDate}</ValueText>
              </RowContainer>

              <RowContainer>
                <KeyText> {`Deságio (${chosenOffer.appliedFee}% a.m.)`}</KeyText>
                <ValueText>{chosenOffer.feeValue}</ValueText>
              </RowContainer>

              <RowContainer>
                <KeyText>Valor que será descontado</KeyText>
                <ValueText>{chosenOffer?.totalAmount ?? 0}</ValueText>
              </RowContainer>

              <RowContainer>
                <BoldKeyText>Valor descontado no repasse de</BoldKeyText>
                <BoldValueText>{dateToFormattedString(chosenOffer.discountDate)}</BoldValueText>
              </RowContainer>
            </ResultAnticipationContainer>
          </ContainerCard>
          <ButtonGroup>
            <Button
              onClick={() => {
                push(`/${schoolSlug}/repasses`)
              }}
            >
              Voltar para início
            </Button>
          </ButtonGroup>
        </>
      }
    />
  )
}
