import { Typography } from '@olaisaac/design-system'
import { Text } from '@gravity/text'
import { IconButton } from '@gravity/icon-button'
import styled from 'styled-components'
import Icon from '@mdi/react'
import { mdiAccountSchool } from '@mdi/js'
import { VisibilityOff, Visibility, SchoolRounded } from '@material-ui/icons'

import dayjs from 'dayjs'
import { useState } from 'react'
import { useUnleashFlag, UnleashFlags } from '@/shared/hooks'

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`
const IconColor = styled(Icon)`
  color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
  background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[10]};
  border-radius: ${({ theme }) => theme.gravity.borderRadius[1]};
  margin-right: 9px;
  spacing: ${({ theme }) => theme.gravity.spacing[7]};
`

const IconColorBeforeEFI248 = styled(SchoolRounded)`
  color: ${({ theme }) => theme.primitiveTokens.colors.gray[60]};
  margin-right: 9px;
`

type StudentCardProps = {
  birthDate?: datestring
  studentName: string
  taxId?: string
}

export const StudentInfo = ({ studentName, birthDate, taxId }: StudentCardProps) => {
  const [visibility, setVisibilityState] = useState(false)
  const studentEditing = useUnleashFlag(UnleashFlags.EFI_248_STUDENT_EDITING)
  const birthDateFormated = birthDate ? dayjs(birthDate).add(1, 'day').format('DD/MM/YYYY') : ''

  return (
    <div>
      {studentEditing ? (
        <>
          <TitleContainer>
            <IconColor path={mdiAccountSchool} size={2} />
            <Typography variation="headlineDesktopMedium">{studentName}</Typography>
            <IconButton
              data-testid="visibilityControl"
              variant="ghost"
              onClick={() => setVisibilityState(!visibility)}
              style={{ marginLeft: 8 }}
            >
              {visibility ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </TitleContainer>
          <div style={{ display: 'flex', gap: 32 }}>
            <Text variant="body-1-regular">
              Data de nascimento:{' '}
              {!!birthDate && birthDate !== '0001-01-01T00:00:00Z'
                ? birthDateFormated
                : 'Não informado'}
            </Text>
            <Text variant="body-1-regular">
              CPF:{' '}
              {taxId ? (visibility ? taxId : `***.***.***-${taxId.slice(-2)}`) : 'Não informado'}
            </Text>
          </div>
        </>
      ) : (
        <>
          <TitleContainer>
            <IconColorBeforeEFI248 />
            <Typography variation="bodySmall" color="secondary">
              Aluno(a)
            </Typography>
          </TitleContainer>
          <Typography variation="headlineDesktopMedium">{studentName}</Typography>
        </>
      )}
    </div>
  )
}
