import { SchoolReportingPeriod } from '@/shared/models/SchoolChargeOperation'

export const ISAAC_PAY_SCHOOL_CHARGE_CONFIG_ITEMS = {
  [SchoolReportingPeriod.weekly]: 'Semanal',
  [SchoolReportingPeriod.daily]: 'Diário',
  monthly: {
    label: 'Mensal',
    key: 'monthly',
  },
}
