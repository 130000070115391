import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[2]};

  padding-bottom: ${props => props.theme.gravity.spacing[4]};

  svg {
    box-sizing: content-box;
  }
`
