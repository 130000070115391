import { validateSpecialCharactery } from '@/shared/utils'
import { Box, FormControl } from '@material-ui/core'
import { TextField } from '@olaisaac/design-system'
import { Controller } from 'react-hook-form'
import { GuardianFormTitle } from '../../styles'

export const GuardianEditReasonFormInput = ({ control }) => {
  return (
    <Box mb={5}>
      <GuardianFormTitle>Motivo</GuardianFormTitle>
      <Box>
        <FormControl fullWidth variant="outlined">
          <Controller
            control={control}
            rules={{
              required: true,
              maxLength: 100,
              minLength: 5,
              validate: validateSpecialCharactery,
            }}
            name="reason"
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                id="reason"
                label="Motivo"
                error={Boolean(error?.type)}
                helperText={error?.type ? 'Insira um motivo' : ''}
              />
            )}
          />
        </FormControl>
      </Box>
    </Box>
  )
}
