import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};

  .item-skeleton {
    display: flex;
    padding: ${props => props.theme.gravity.spacing[2]};

    .avatar {
      width: ${props => props.theme.gravity.spacing[20]};
      margin-right: ${props => props.theme.gravity.spacing[2]};
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.gravity.spacing[2]};
    }
  }
`
