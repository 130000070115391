import { Chip } from '@material-ui/core'
import styled from 'styled-components'

type ChipButtonProps = {
  $isActive: boolean
}

export const ChipButton = styled(Chip)<ChipButtonProps>`
  border: 1px solid ${props => props.theme.primitiveTokens.colors.gray[10]};
  border-color: ${props => props.$isActive && props.theme.primitiveTokens.colors.isaacBlue[60]};

  background-color: ${props => props.theme.primitiveTokens.colors.gray[0]};
  background-color: ${props => props.$isActive && props.theme.primitiveTokens.colors.isaacBlue[20]};

  color: ${props => props.$isActive && props.theme.primitiveTokens.colors.isaacBlue[60]};

  :hover,
  :focus {
    background-color: ${props =>
      props.$isActive
        ? props.theme.primitiveTokens.colors.isaacBlue[20]
        : props.theme.primitiveTokens.colors.gray[0]};
  }
`
