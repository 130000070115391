import { Heading } from '@gravity/heading'

import Icon from '@mdi/react'
import { mdiClockOutline } from '@mdi/js'
import monetizationOnOutline from '../../assets/monetizationOnOutline.svg'

import * as Styled from './styles'

type ScoreHistoryProps = {
  earliestAcademicCycle: number
  isCurrentlyDefault: boolean
}

export const ScoreHistory = ({ earliestAcademicCycle, isCurrentlyDefault }: ScoreHistoryProps) => {
  return (
    <>
      <Heading variant="heading-h4-medium">Histórico isaac</Heading>
      <Styled.Wrapper>
        <Styled.CardWrapper>
          <Icon path={mdiClockOutline} size={1} />
          {`Consta na base isaac desde ${earliestAcademicCycle}.`}
        </Styled.CardWrapper>

        <Styled.CardWrapper>
          <Styled.IconWrapper src={monetizationOnOutline} alt="Dívidas" />
          {isCurrentlyDefault ? 'Possui dívidas em aberto.' : 'Não possui dívidas em aberto.'}
        </Styled.CardWrapper>
      </Styled.Wrapper>
    </>
  )
}
