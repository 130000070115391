import { CheckoutInstallmentsSimulationParams } from '@/modules/guardians/GuardianDetails/features/GuardianDetailsFooter/types'
import { useQuery } from '@tanstack/react-query'

import { useApi } from 'src/shared/hooks'
import type { AgreementSimulationParams } from '@/modules/guardians/models/agreement'

export default function useAgreementSimulationQuery(
  params: AgreementSimulationParams | CheckoutInstallmentsSimulationParams
) {
  const { api } = useApi()
  const queryKey = ['agreement-simulation', params]

  return useQuery(queryKey, () => api.agreement.createSimulation(params), {
    networkMode: 'offlineFirst',
    staleTime: 10 * 60 * 1000,
    enabled: false,
  })
}
