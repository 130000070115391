import styled, { css } from 'styled-components'

import { SIDE_MENU_WIDTH } from '@/modules/app/components/Layout/styles'

type ContentProps = {
  drawerWidth: number
  isCollapsed: boolean
}

// INFO: Needed in order the non-flex parent
const HEADER_HEIGHT = '96px'

export const Container = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  height: calc(100vh - ${HEADER_HEIGHT});
  max-height: 100vh;

  transition: max-width 0.3s cubic-bezier(0.42, 0.29, 0.39, 0.83);

  ${props =>
    props.isCollapsed &&
    css`
      max-width: calc(100vw - ${SIDE_MENU_WIDTH} - ${props.drawerWidth}px);
    `}

  .new-product-button {
    display: flex;
    justify-content: right;
    padding: 2rem 4rem 0rem;
  }
`

export const Content = styled.div`
  padding: 2rem 4rem;
  flex: 1;
`
