import dayjs from 'dayjs'

import { ChosenPaymentPlan, SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import { Negotiation } from '@/modules/guardians/services/types'

const hasCreditCardPayment = (originNegotiation?: Negotiation) =>
  originNegotiation?.down_payment_enabled_payment_methods?.includes('CREDIT_CARD')

const getPaymentMethod = (originNegotiation?: Negotiation) => {
  if (hasCreditCardPayment(originNegotiation)) return SimulationPaymentMethod.CREDIT_CARD

  return SimulationPaymentMethod.BANK_SLIP
}

export const getDuplicateAgreementPaymentPlan = (
  originNegotiation?: Negotiation
): ChosenPaymentPlan => {
  const dueDate = dayjs(originNegotiation?.down_payment_due_date).isAfter(dayjs(), 'day')
    ? originNegotiation?.down_payment_due_date
    : dayjs().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

  const paymentMethod = getPaymentMethod(originNegotiation)

  return {
    number_of_installments:
      paymentMethod === SimulationPaymentMethod.CREDIT_CARD
        ? originNegotiation?.chosen_payment_plans?.[0]?.max_installments_credit_card
        : originNegotiation?.chosen_payment_plans?.length,
    down_payment_amount: originNegotiation?.down_payment_amount,
    payment_method: paymentMethod,
    due_date: dueDate,
  }
}
