import envConfig from '@/config'

export enum FeatureFlags {
  MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG = 'MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG',
}

/**
 * @deprecated Use useUnleashFlag instead
 */
export const useFeatureFlag = (feature: FeatureFlags) => {
  if (!envConfig[feature]) {
    return {
      value: false,
      config: [''],
    }
  }

  const values: string[] = envConfig[feature].split(',')

  return {
    value: values[0] === 'ON',
    config: values.slice(1),
  }
}
