import { keys } from 'ramda'
import { fieldsErrorMapping } from '../constants'
import { EnrollmentFormFields, GuardianFormType } from '../types'

export const getWrongFieldNames = (errors?): Array<string> => {
  const findError = (errorName: string) => {
    return errors?.response?.data?.errors?.find(item => item?.field === errorName)
  }
  return keys(fieldsErrorMapping)
    .map(k => (errors?.[k] || findError(k) ? fieldsErrorMapping[k] : null))
    .filter(Boolean)
}

export const getDefaultEnrollmentFormFields = (monthlyAmount: number): EnrollmentFormFields => {
  return { discounts: [], monthlyAmount, durationMonths: '1' }
}

export const newEmptyGuardian = (): GuardianFormType => {
  return {
    address: {
      additional_information: '',
      city: '',
      number: '',
      state_code: '',
      street: '',
      zip: '',
    },
    email: '',
    id: null,
    name: '',
    phone_number: '',
    tax_id: '',
  }
}
