import { Badge } from '@gravity/badge'
import { IrregularStudentStatus, irregularStatusTranslationDict } from '../types'

type IrregularStatusFilterCardProps = {
  isSelected: boolean
  status: IrregularStudentStatus
}

export const IrregularStatusFilterCard = ({
  status,
  isSelected,
}: IrregularStatusFilterCardProps) => {
  return (
    <Badge variant={isSelected ? 'solid' : 'outline'} color="accent" size={1}>
      {irregularStatusTranslationDict[status]}
    </Badge>
  )
}
