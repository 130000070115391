import React from 'react'
import { DebtStatusProps, debtStatusVariant } from './types'
import { Badge } from '@gravity/badge'

export const DebtStatus: React.FC<DebtStatusProps> = ({ status }) => {
  return (
    <Badge color={debtStatusVariant[status].color} variant="soft" size={1}>
      {debtStatusVariant[status].text}
    </Badge>
  )
}
