import { spacing } from '@gravity/tokens'
import styled from 'styled-components'

export const CustomizationStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: ${spacing[6]};
  h3 {
    margin-bottom: ${spacing[4]};
  }
`
export const AddInstallmentBtnWrapper = styled.div`
  margin-top: ${spacing[8]};
  display: flex;
  justify-content: end;
`
