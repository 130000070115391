import { Card } from '@gravity/card'
import { colors, fontSize, fontWeight, spacing, borderRadius } from '@gravity/tokens'
import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

const BaseRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledCard = styled(Card)`
  background-color: ${colors['colors-background-neutral-2']};
  border-radius: ${borderRadius[8]};
  border-style: none;
`
export const GrayTitleCard = styled(Typography)`
  font-size: ${fontSize['2xl']};
  font-weight: ${fontWeight.medium};
  margin-bottom: ${spacing[6]};
`

export const RowContainer = styled(BaseRowContainer)`
  gap: ${spacing[3]};
  margin-bottom: ${spacing[4]};
`

export const SubtitleGrayCard = styled(Typography)`
  font-size: ${fontSize.lg};
  font-weight: ${fontWeight.medium};
`

export const RowContainerSpaceBetween = styled(BaseRowContainer)`
  justify-content: space-between;
  align-items: center;
`
