import { useLocation } from 'react-router-dom'
import { GuardianDetailsTabs } from '@/modules/guardians/GuardianDetails/constants'
import { useGuardianNavigationStore } from '@/modules/guardians/store/guardianNavigationStore'
export const useGuardianNavigation = () => {
  const location = useLocation()

  const { pageToReturn, setPageToReturn } = useGuardianNavigationStore()

  const handleReturnPage = () => {
    const fullPathWithQuery = `${location.pathname}${location.search}`
    setPageToReturn(fullPathWithQuery)
  }

  return {
    defaultGuardianEntryPage: GuardianDetailsTabs.RESUMO,
    pageToReturn,
    setPageToReturn: handleReturnPage,
  }
}
