import { Skeleton } from '@gravity/skeleton'
import { Box, Grid } from '@material-ui/core'
import {
  RowContainer,
  PairSkeletonContainer,
  RowFlexContainer,
  ContainerCard,
  GroupSkeletonContainer,
  SkeletonStyled,
  SkeletonTitlePage,
  RowFlexEndContainer,
  HeaderSkeletonForm,
  RowGroupInput,
  BackNavigator,
} from './styles'
import { Card } from '@olaisaac/design-system'
import { Separator } from '@gravity/separator'

export const LoadingSimulation = () => {
  return (
    <RowContainer>
      <Box mt={5}>
        <Grid container justifyContent="center" spacing={3} style={{ padding: '0 64px' }}>
          <Grid item xs={6}>
            <SkeletonTitlePage radius={8} width={400} height={40} />

            <RowContainer>
              <Card>
                <ContainerCard>
                  <PairSkeletonContainer>
                    <Skeleton radius={8} fullWidth />
                    <Skeleton radius={8} fullWidth />
                  </PairSkeletonContainer>

                  <Separator color="neutral-2" orientation="horizontal" />

                  <GroupSkeletonContainer>
                    <Skeleton radius={8} fullWidth />
                    <Skeleton radius={8} fullWidth />
                    <Skeleton radius={8} fullWidth />
                    <Skeleton radius={8} fullWidth />
                    <Skeleton radius={8} fullWidth />
                  </GroupSkeletonContainer>
                </ContainerCard>
              </Card>
            </RowContainer>

            <SkeletonStyled radius={8} width={453} />

            <RowFlexContainer>
              <Skeleton radius={8} width={179} height={40} />
              <Skeleton radius={8} width={195} height={40} />
            </RowFlexContainer>
          </Grid>
        </Grid>
      </Box>
    </RowContainer>
  )
}

export const LoadingSimulationForm = () => (
  <RowContainer>
    <Box mt={5}>
      <Grid container justifyContent="center" spacing={3} style={{ padding: '0 64px' }}>
        <Grid item xs={6}>
          <BackNavigator>
            <Skeleton radius={8} width={79} height={24} />
          </BackNavigator>
          <HeaderSkeletonForm>
            <SkeletonTitlePage radius={8} width={343} height={40} />
            <Skeleton radius={8} fullWidth height={48} />
          </HeaderSkeletonForm>
          <RowContainer>
            <RowGroupInput>
              <Skeleton radius={8} width={330} />
              <Skeleton radius={8} fullWidth height={40} />
            </RowGroupInput>
            <RowGroupInput>
              <Skeleton radius={8} width={263} />
              <Skeleton radius={8} fullWidth height={40} />
              <Skeleton radius={8} width={388} height={20} />
            </RowGroupInput>

            <RowFlexEndContainer>
              <Skeleton radius={8} width={85} height={40} />
            </RowFlexEndContainer>
          </RowContainer>
        </Grid>
      </Grid>
    </Box>
  </RowContainer>
)
