import { Contract, MatriculasApiContractStatus } from '@/shared/interfaces'
import { AgglutinationProcessedInstallment } from '@/escolas/contexts/checkoutContext'
import { fillMatriculasApiContracts } from './fillMatriculasApiContracts'
import {
  getAllBufferInstallments,
  getAllBufferInstallmentsMatriculasApi,
} from './formatBufferInstallments'
import { getInstallmentsByContract } from '../../../checkoutInstallments'
import { uniqBy, prop } from 'ramda'

interface getContractsReturn {
  allBufferInstallments: AgglutinationProcessedInstallment[]
  allContracts: Contract[]
  installmentsByContract: AgglutinationProcessedInstallment[]
}

export async function getContractsFromMatriculasApi(
  api,
  guardianId,
  referenceYear,
  schoolId,
  status = MatriculasApiContractStatus.PENDING
): Promise<getContractsReturn> {
  let [newContracts, matriculasApiContracts]: [Contract[], Contract[]] = await Promise.all([
    api.guardians.getContracts(guardianId, referenceYear, schoolId),
    api.guardians.getMatriculasApiLegacyEnrollments(guardianId, referenceYear, schoolId, status),
  ])

  matriculasApiContracts = await fillMatriculasApiContracts(api, matriculasApiContracts)

  const allContracts = uniqBy(prop('id'), [...newContracts, ...matriculasApiContracts])

  if (!allContracts)
    return {
      allBufferInstallments: [],
      allContracts: [],
      installmentsByContract: [],
    }

  const allBufferInstallments = [
    ...getAllBufferInstallments(newContracts),
    ...getAllBufferInstallmentsMatriculasApi(matriculasApiContracts),
  ]

  const installmentsByContract = uniqBy(prop('id'), [
    ...allBufferInstallments,
    ...getInstallmentsByContract(allContracts),
  ])

  return {
    allBufferInstallments,
    allContracts,
    installmentsByContract,
  }
}

export async function getContracts(
  api,
  guardianId,
  referenceYear,
  schoolId
): Promise<getContractsReturn> {
  return await getContractsFromMatriculasApi(api, guardianId, referenceYear, schoolId)
}
