import { Box, TableSortLabel } from '@material-ui/core'
import { Typography as TypographyDS } from '@olaisaac/design-system'
import styled from 'styled-components'

export const StyledTableHeader = styled(TypographyDS).attrs({
  component: 'span',
  color: 'secondary',
  variation: 'subtitleDesktopSmall',
})`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  cursor: default;
`
export const TableContainer = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[5]};

  table {
    cursor: pointer;
    thead {
      cursor: default;
      tr {
        cursor: default;
        th {
          cursor: default;
        }
      }
    }
  }
`
export const StyledStudentsList = styled(TypographyDS)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledStudentsListContainer = styled(Box)`
  max-width: 240px;
`

export const StyledTableSortLabel = styled(TableSortLabel)`
  width: max-content;
  height: 48px;
`
