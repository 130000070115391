import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from 'src/shared/hooks'

import {
  FetchContractsByStudentResponseDTO,
  FetchContractsByGuardiansResponseDTO,
  FetchContractNeverPaidResponseDTO,
  FetchContractNeverPaidDetailResponseDTO,
} from 'src/escolas/services/contracts/types'
import {
  ContractNeverPaidDetailQueryProps,
  ContractNeverPaidQueryProps,
  ContractsByGuardiansQueryProps,
  ContractsByStudentsQueryProps,
} from './types'

import { SOFTCACHE_OPTIONS_BASE } from 'src/shared/constants'
import { ContractNeverPaidInstallment } from '@/shared/interfaces'

export const useContractsByStudents = (
  { schoolId, referenceYear, filter, pagination }: ContractsByStudentsQueryProps,
  options?: UseQueryOptions<FetchContractsByStudentResponseDTO>
) => {
  const { api } = useApi()

  const queryKey = [
    'contracts',
    'students',
    JSON.stringify({ schoolId, referenceYear, ...filter, ...pagination }),
  ]

  return useQuery<FetchContractsByStudentResponseDTO>(
    queryKey,
    async () => {
      return await api.contracts.fetchByStudent({
        schoolId,
        referenceYear,
        filter,
        pagination,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}

export const useContractsByGuardians = (
  { schoolId, referenceYear, filter, pagination }: ContractsByGuardiansQueryProps,
  options?: UseQueryOptions<FetchContractsByGuardiansResponseDTO>
) => {
  const { api } = useApi()

  const queryKey = [
    'contracts',
    'guardians',
    JSON.stringify({ schoolId, referenceYear, ...filter, ...pagination }),
  ]

  return useQuery<FetchContractsByGuardiansResponseDTO>(
    queryKey,
    async () => {
      return await api.contracts.fetchByGuardian({
        schoolId,
        referenceYear,
        filter,
        pagination,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}

export const useContractNeverPaidQuery = (
  { schoolId, referenceYear, installmentType, pagination }: ContractNeverPaidQueryProps,
  options?: UseQueryOptions<FetchContractNeverPaidResponseDTO>
) => {
  const { api } = useApi()

  const queryKey = [
    'contracts',
    'neverPaid',
    JSON.stringify({ schoolId, referenceYear, installmentType, ...pagination }),
  ]

  return useQuery<FetchContractNeverPaidResponseDTO>(
    queryKey,
    () => api.contracts.fetchNeverPaid(schoolId, referenceYear, installmentType, pagination),
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}

export const useContractNeverPaidDetailQuery = (
  { schoolId, uniqueKey, referenceYear }: ContractNeverPaidDetailQueryProps,
  options?: UseQueryOptions<FetchContractNeverPaidDetailResponseDTO>
) => {
  const { api } = useApi()

  const queryKey = [
    'contracts',
    'neverPaid',
    'detail',
    JSON.stringify({ schoolId, uniqueKey, referenceYear }),
  ]

  return useQuery<FetchContractNeverPaidDetailResponseDTO>(
    queryKey,
    async () => api.contracts.fetchNeverPaidDetail(schoolId, referenceYear, uniqueKey),
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}

type ConfirmNeverPaidMutationVariables = {
  schoolId: string
  uniquekeys: string[]
}

export const useConfirmNeverPaidMutation = () => {
  const { api } = useApi()

  return useMutation<ContractNeverPaidInstallment[], void, ConfirmNeverPaidMutationVariables>(
    async params => api.contracts.confirmNeverPaid(params.schoolId, params.uniquekeys)
  )
}

type ConfirmNeverPaidBulkMutationVariables = {
  installmentIds: string[]
  schoolId: string
}

export const useConfirmNeverPaidBulkMutation = () => {
  const { api } = useApi()

  return useMutation<ContractNeverPaidInstallment[], void, ConfirmNeverPaidBulkMutationVariables>(
    async params => {
      return await api.contracts.confirmNeverPaidBulk(params.schoolId, params.installmentIds)
    }
  )
}
