import { CheckoutStatus, CheckoutType } from './checkout'
import { CommonMetadata } from './metadata'
import { PaymentMethod } from './paymentMethod'

type cents = number

export enum UnifiedInvoicePaymentMethod {
  POS_CREDIT_CARD = 'pos_credit_card',
  POS_DEBIT_CARD = 'pos_debit_card',
}

export type UnifiedInvoiceRequestBody = {
  item_urns: uuid[]
  payment_methods: UnifiedInvoicePaymentMethod[]
  pos: {
    id: uuid
    information_title: string
    installments_count: number
    type: CheckoutType
  }
}

export type UnifiedInvoiceCheckout = CommonMetadata & {
  amount: cents
  external_transaction_id: uuid
  installments_count: number
  payment_method: PaymentMethod
  status: CheckoutStatus
  type: CheckoutType
}

export type UnifiedInvoice = {
  amount: cents
  checkout: UnifiedInvoiceCheckout
  id: uuid
}
