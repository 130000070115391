import { Box, Dialog } from '@material-ui/core'
import { DialogContent, Typography } from '@olaisaac/design-system'
import styled from 'styled-components'
import theme from '@/shared/theme'

const { spacing } = theme.primitiveTokens
const { variation, color } = theme.humanizedTokens.components.typography

export const StyledDialog = styled(Dialog)`
  div[role='dialog'] {
    width: 35rem;
  }
`

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing[6]} ${spacing[6]};
`

export const StyledBoxLoading = styled(Box)`
  padding: ${spacing[6]};
`

export const Title = styled(Typography)`
  ${variation.headlineDesktopSmall}
  text-align: center;
`

export const Message = styled(Typography)`
  ${variation.subtitleDesktopMedium}
  ${color.secondary}
`
