import styled from 'styled-components'
import { TableCell, TableRow } from '@material-ui/core'

import theme from '@/shared/theme'

export const { spacing, colors } = theme.primitiveTokens

const { table } = theme.humanizedTokens.components

export const StyledTableCell = styled(TableCell)`
  transition: height ${theme.humanizedTokens.generic.transition.small};
  color: inherit;
  width: ${props => props.width};
  height: ${props => props.height};
  overflow: hidden;

  &.MuiTableCell-head {
    border-top: none;
  }
`

export const StyledMuiTableRow = styled(TableRow)`
  cursor: pointer;

  &.MuiTableRow-hover:hover {
    background-color: ${table.hover.background_color};
  }

  &:active {
    background-color: ${table.selected.background_color};
  }

  &:focus-visible {
    outline: 1px solid ${table.focus.border_color};
  }
`
