/**
 * Utility function for string normalization
 *
 * @example
 *
 * normalizeText('ção'); // cao
 *
 * @param text String to be formatted
 *
 * @returns Normalized string
 */
export const normalizeText = (text: string) => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}
