import { useForm } from 'react-hook-form'
import { useEffect } from 'react'

import { LiquidationSource } from '@/shared/interfaces'

import type { ManualLiquidationForm } from './types'
import { getStartOfToday } from '@/shared/utils'
import dayjs from 'dayjs'
import { InstallmentV2 } from '@/modules/guardians/services/types'

const useManualLiquidationForm = (isAdmin: boolean, installment: InstallmentV2 = null) => {
  const form = useForm<ManualLiquidationForm>({
    mode: 'onBlur',
    defaultValues: {
      payment_method: installment?.paid_payment_method || '',
      paid_date: installment?.paid_date
        ? dayjs(installment?.paid_date).utc()
        : getStartOfToday().utc(),
      source: isAdmin ? installment?.paid_payment_source || null : LiquidationSource.SCHOOL_ACCOUNT,
      edit_reason: '',
    },
  })

  useEffect(() => {
    form.register('paid_date', { required: true })
    form.register('edit_reason', { required: false })
    form.register('payment_method', { required: false })
    form.register('source', { required: true })
  }, [form, form.register])

  return form
}

export default useManualLiquidationForm
