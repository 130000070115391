import theme from '@/shared/theme'

import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { Button } from '@gravity/button'

import ErrorImage from '../../assets/algo-deu-errado.svg'

import { Container, SubContainer } from './styles'

type ErrorProps = {
  onRetry: () => void
}

export const GenericError = ({ onRetry }: ErrorProps) => {
  return (
    <Container>
      <SubContainer>
        <img src={ErrorImage} alt="Imagem de erro" style={{ marginBottom: '40px' }} />

        <Heading variant="heading-h2-medium" style={{ marginBottom: theme.gravity.spacing[4] }}>
          Algo deu errado
        </Heading>

        <Text
          variant="subtitle-regular"
          style={{ marginBottom: theme.gravity.spacing[8], textAlign: 'center' }}
        >
          Por favor, verifique sua conexão com a internet ou aguarde um momento e tente novamente.
        </Text>

        <Button size={2} onClick={onRetry}>
          Tentar novamente
        </Button>
      </SubContainer>
    </Container>
  )
}
