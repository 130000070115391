import { month2String } from '@/shared/utils/dateFormatters'
import { formatCurrencyValue } from '@/shared/utils/numberFormatters'
import { Link } from '@gravity/link'
import { SmallText } from './styles'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { REPORT_BUTTON_NAMES } from '@/modules/report/constants/REPORT_BUTTON_NAMES'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useContextEligibility } from '@/modules/report/contexts/PayoutAnticipationContext'

export const AnticipationCaption = ({
  isSelectedOlderMonth,
  isLoadingEligibility,
  hasErrorMessage,
  pageName,
}: {
  hasErrorMessage: boolean
  isLoadingEligibility: boolean
  isSelectedOlderMonth: boolean
  pageName: string
}) => {
  const { eligibility } = useContextEligibility()
  const eligibleForAR = eligibility?.eligibility?.eligible?.AR ?? false

  if (hasErrorMessage) {
    return <SmallText>Antecipação de repasse indisponível. Tente novamente mais tarde.</SmallText>
  }
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const clickAnticaptionLink = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_ANTICIPATION,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $page_name: pageName,
          $button_name: REPORT_BUTTON_NAMES.FIND_OUT_MORE,
        },
      })
  }

  if (!eligibleForAR) {
    return (
      <SmallText>
        Antecipação de repasse indisponível.{' '}
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://centraldeajuda.olaisaac.io/repasse/o-que-e-antecipacao-de-repasse-no-isaac#requisitos-mnimos-de-elegibilidade-da-antecipao-de-repasse-1"
          onClick={clickAnticaptionLink}
        >
          Saiba mais.
        </Link>
      </SmallText>
    )
  }

  return (
    <SmallText>
      {isSelectedOlderMonth
        ? 'Disponível para antecipar: '
        : `Disponível para antecipar${
            !isLoadingEligibility && !!eligibility?.gracePeriod.AR
              ? ` de ${month2String(eligibility?.gracePeriod.AR[0]?.toString())}`
              : ''
          }: `}
      <strong>
        {isSelectedOlderMonth ? '0,00' : formatCurrencyValue(eligibility?.eligibility?.pa?.AR ?? 0)}
      </strong>
    </SmallText>
  )
}
