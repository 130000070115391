import styled from 'styled-components'

export const DialogContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[6]};

  .content-bottom-text {
    margin-top: ${props => props.theme.gravity.spacing[2]};
  }
`
