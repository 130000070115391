import { useEffect, useState } from 'react'

import { useLoadPayouts } from '@/modules/school-home/hooks/queries/enrollment-request/useLoadPayouts'
import { useReportsQuery } from '@/escolas/hooks/queries/report'

import {
  getElapsedPayouts,
  getFormattedPayoutDate,
  getPayoutValue,
  getPayoutsAccumulatedValue,
} from '@/modules/school-home/utils/payouts'
import {
  getLastEnrollment,
  getEnrollmentValue,
  getEnrollmentDates,
} from '@/modules/school-home/utils/enrollments'
import { getFinancialInfo } from '@/modules/school-home/utils/financialInfo'

import type { Info } from '@/modules/school-home/types/Info'

export const useLoadFinancialInfo = (schoolId: string, enabled: boolean) => {
  const [accumulatedPayoutInfo, setAccumulatedPayoutInfo] = useState<Info>(null)
  const [nextPayoutInfo, setNextPayoutInfo] = useState<Info>(null)
  const [enrollmentInfo, setEnrollmentInfo] = useState<Info>(null)

  const {
    isLoading: isLoadingPayouts,
    isError: isErrorPayouts,
    refetch: refetchPayouts,
    data: payoutData,
  } = useLoadPayouts(schoolId, enabled)

  const {
    data: enrollmentsData,
    isFetching: isLoadindEnrollments,
    isError: isErrorEnrollments,
    refetch: refetchEnrollments,
  } = useReportsQuery({
    schoolId,
    options: {
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    },
  })

  useEffect(() => {
    if (!payoutData.nextPayout?.data?.payout) {
      return
    }

    const { data } = payoutData.nextPayout
    const nextPayoutValue = getPayoutValue(data)
    const payoutDate = getFormattedPayoutDate(data.payout.due_date)

    setNextPayoutInfo({
      value: nextPayoutValue,
      query: `?date=${payoutDate}&id=${data.payout_id}&version=${data.payout.version}&page=1`,
    })
  }, [payoutData.nextPayout])

  useEffect(() => {
    const elapsedPayouts = getElapsedPayouts(payoutData.payouts)
    const accumulatedPayoutValue = getPayoutsAccumulatedValue(elapsedPayouts)

    setAccumulatedPayoutInfo({
      value: accumulatedPayoutValue,
      query: '',
    })
  }, [payoutData.payouts])

  useEffect(() => {
    if (enrollmentsData) {
      const hasEnrollments = enrollmentsData.length >= 1

      if (hasEnrollments) {
        const lastEnrollment = getLastEnrollment(enrollmentsData)
        const lastEnrollmentValue = getEnrollmentValue(lastEnrollment)
        const { startDate, endDate } = getEnrollmentDates(lastEnrollment)

        setEnrollmentInfo({
          value: lastEnrollmentValue,
          query: `?id=${lastEnrollment.payout_id}&startDate=${startDate}&endDate=${endDate}&page=1`,
        })
      } else {
        // When there is no enrollment related to the school
        setEnrollmentInfo({
          value: NaN,
          query: '',
        })
      }
    }
  }, [enrollmentsData])

  // Refetch only requests with error to avoid unnecessary requests
  const refetch = () => {
    if (isErrorPayouts) {
      refetchPayouts()
    }

    if (isErrorEnrollments) {
      refetchEnrollments()
    }
  }

  return {
    isLoading: isLoadingPayouts || isLoadindEnrollments,
    isError: isErrorPayouts || isErrorEnrollments,
    info: getFinancialInfo(accumulatedPayoutInfo, nextPayoutInfo, enrollmentInfo),
    refetch,
  }
}
