import Box from '@material-ui/core/Box'
import {
  ActionDrawerHeader,
  Drawer,
  Typography,
  DialogContent,
  Button,
} from '@olaisaac/design-system'
import styled from 'styled-components'

export const PreContractEditSummaryHeader = styled(ActionDrawerHeader)`
  margin-top: ${({ theme }) => theme.spacing(5)}px; ;
`

export const PreContractEditSummaryContainer = styled(Drawer).attrs({
  open: true,
})``

export const DialogContentContainer = styled(DialogContent)`
  padding: 0;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
`

export const SectionContainer = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
})`
  padding: ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(3)}px;
`
export const SectionTitle = styled(Typography).attrs({
  variation: 'subtitleDesktopLarge',
  withoutMargin: true,
})`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`

export const SectionSubtitle = styled(Typography).attrs({
  variation: 'subtitleDesktopLarge',
  withoutMargin: true,
})`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

export const SectionText = styled(Typography).attrs({
  variation: 'bodySmall',
  color: 'secondary',
})`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
`

export const StyledSectionText = styled(Typography).attrs({
  variation: 'bodySmall',
})`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
`

export const SectionContentContainer = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
})``

export const SubmitButton = styled(Button).attrs({
  type: 'submit',
  variation: 'primary',
  fullWidth: true,
})``
