const brazilianLayout = 'pt-BR'
const monthYearFormatter = new Intl.DateTimeFormat(brazilianLayout, {
  month: 'long',
  year: 'numeric',
})
export function parseBrazilianDate(dateStr: string): Date | null {
  const parts = dateStr.split('/')
  if (parts.length === 3) {
    const [day, month, year] = parts.map(Number)
    return new Date(year, month - 1, day)
  }
  return null
}

/**
 * Converts a date or date string to a formatted string with optional slash separation.
 *
 * @param {Date|string} date - The date object or date string to be formatted.
 * @param {boolean} [useSlash=false] - Optional parameter to define whether the format should use slashes instead of the standard separator.
 *
 * @return {string} The formatted date string, either using spaces or slashes based on the useSlash parameter.
 */
export function dateToFormattedString(date: Date | string, useSlash = false): string {
  let dateObj: Date

  if (typeof date === 'string') {
    dateObj = parseBrazilianDate(date) || new Date(date)
  } else {
    dateObj = date
  }

  const formattedDate = monthYearFormatter.format(dateObj)
  const capitalizedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)

  if (useSlash) {
    return capitalizedDate.replace(' de ', '/')
  }

  return capitalizedDate
}

export function dateToFormattedStrings(dates?: string[]): string[] {
  return dates == null ? [] : dates.map(date => dateToFormattedString(date))
}

export const brazilianLayoutDate = 'pt-BR'
export const defaultTimezoneDate = { timeZone: 'UTC' }

export function formatChosenOfferDate(chosenOfferDate: string): string {
  return chosenOfferDate
    ? new Date(chosenOfferDate).toLocaleDateString(brazilianLayoutDate, defaultTimezoneDate)
    : new Date().toLocaleDateString(brazilianLayoutDate, defaultTimezoneDate)
}
