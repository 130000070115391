import { Dialog, withStyles, IconButton } from '@material-ui/core'

import styled from 'styled-components'

import theme from '@/shared/theme'
export const { colors, spacing } = theme.primitiveTokens

export const Modal = withStyles({
  paper: {
    height: 520,
    width: 786,
    borderRadius: 8,
  },
})(Dialog)

export const StyledIconButton = styled(IconButton)`
  border-radius: 8px;

  &:focus-visible {
    outline: 2px solid ${colors.isaacBlue[30]};
    outline-offset: 1px;
  }
`
