import { IrregularityBigNumbersCardProps } from './types'
import * as Styled from './styles'
import { IrregularStatusBadge } from '../IrregularStatusBadge'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { IrregularStudentStatus } from '../../types'
import { formatCurrencyValue } from '@/shared/utils'

const formatDescription1 = (status: IrregularStudentStatus, total_amount: number): string => {
  return status === IrregularStudentStatus.FINISHED
    ? `${formatCurrencyValue(total_amount / 100)} liberados pelas correções`
    : `${formatCurrencyValue(total_amount / 100)} pendentes.`
}

const formatDescription2 = (status: IrregularStudentStatus): string => {
  if (status === IrregularStudentStatus.FINISHED) {
    return 'concluídas. Bom trabalho!'
  }
  if (status === IrregularStudentStatus.IN_PROGRESS) {
    return 'Finalize as correções para liberação.'
  }
  return 'Inicie as correções para liberação.'
}

export const IrregularityBigNumbersCard = ({
  status,
  student_count,
  total_amount,
}: IrregularityBigNumbersCardProps) => {
  const studentsCountMessage =
    student_count === 1 ? `${student_count} aluno` : `${student_count} alunos`

  return (
    <Styled.Container>
      <Styled.BadgeContainer>
        <IrregularStatusBadge status={status} />
      </Styled.BadgeContainer>
      <Styled.TitleContainer>
        <Heading variant="heading-h3-regular">{`${studentsCountMessage}`}</Heading>
      </Styled.TitleContainer>
      <Text variant="body-1-regular">{formatDescription1(status, total_amount)}</Text>
      <br />
      <Text variant="body-1-regular">{formatDescription2(status)}</Text>
    </Styled.Container>
  )
}
