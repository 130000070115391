import { MenuItem, Tooltip, Box, ListItemIcon } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import styled, { useTheme } from 'styled-components'

export type ChangeContractOwnershipButtonProps = {
  canChangeContractOwnership: boolean
  changeContractOwnership: () => void
}

const ContractActionsMenuItem = styled(MenuItem).attrs({
  component: 'a',
  target: 'blank',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ContractActionsMenuItemIcon = styled(ListItemIcon)`
  max-width: 16px;
  min-width: 16px;
  margin-left: 8px;
`

const ChangeContractOwnershipButton = ({
  canChangeContractOwnership,
  changeContractOwnership,
}: ChangeContractOwnershipButtonProps) => {
  const theme = useTheme() as any
  const message =
    'Não é possível alterar a titularidade de contratos: quitados, inativos ou com parcelas vencidas, renegociadas ou aglutinadas.'

  return canChangeContractOwnership ? (
    <MenuItem onClick={changeContractOwnership} data-testid="change-contract-ownership">
      Alterar titularidade do contrato
    </MenuItem>
  ) : (
    <Tooltip title={message}>
      <Box>
        <ContractActionsMenuItem disabled data-testid="change-contract-ownership">
          Alterar titularidade do contrato
          <ContractActionsMenuItemIcon>
            <InfoOutlined style={{ color: theme.primitiveTokens.colors.gray[80] }} />
          </ContractActionsMenuItemIcon>
        </ContractActionsMenuItem>
      </Box>
    </Tooltip>
  )
}

export default ChangeContractOwnershipButton
