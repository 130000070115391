import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  min-height: 100%;
  max-height: 100vh;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding-bottom: 2rem;

  @media (max-width: 1120px) {
    grid-template-columns: 1fr;
  }
`
