import { Rows } from '../types'

const sortRowsByStudentName = (rows: Rows, order: 'ASC' | 'DESC') =>
  rows?.sort((a, b) => {
    if (order === 'DESC') [b, a] = [a, b]
    return a.student.name.localeCompare(b.student.name)
  })

const sortRowsByReferenceYear = (rows: Rows, order: 'ASC' | 'DESC') =>
  rows?.sort((a, b) => {
    if (order === 'DESC') [b, a] = [a, b]
    return parseInt(a.reference_year) - parseInt(b.reference_year)
  })

export const sortRowsBy = {
  student: sortRowsByStudentName,
  reference_year: sortRowsByReferenceYear,
}
