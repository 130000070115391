export const replaceTabInPathname = (pathname: string, tab: string) => {
  const pathParts = pathname.split('/')

  // Remove o último valor do array de partes do caminho
  pathParts.pop()

  // Adiciona a nova activeTab no final do array
  pathParts.push(tab)

  // Reconstroi o caminho com as partes atualizadas
  return pathParts.join('/')
}
