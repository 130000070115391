import { PaymentDispute } from '../pages/PaymentDispute'

import type { RouteDefinition } from '@/shared/core/router'

export const routes: RouteDefinition[] = [
  {
    key: 'PAYMENT_DISPUTE',
    path: '/cobrancas-indevidas',
    component: PaymentDispute,
    exact: true,
  },
]
