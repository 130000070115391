import { mdiCalendar, mdiBookOpenVariant, mdiMessageText, mdiSwapHorizontal } from '@mdi/js'
import { ActionDrawerHeader, Drawer, Typography } from '@olaisaac/design-system'

import { DrawerContent, InfoItem, ContactInfo, Link } from './styles'
import { Separator } from '@gravity/separator'
import Icon from '@mdi/react'

type EnrollmentPayoutReportOnboardingDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

/**
 * Onboarding drawer for enrollment payout report page
 *
 * @param props
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.onClose Callback function called on request close
 */
export const EnrollmentPayoutReportOnboardingDrawer = ({
  isOpen,
  onClose,
}: EnrollmentPayoutReportOnboardingDrawerProps) => {
  return (
    <Drawer open={isOpen} appearance="secondary">
      <ActionDrawerHeader
        title="Entenda o repasse de matrículas"
        appearance="secondary"
        onClose={onClose}
      />

      <DrawerContent>
        <InfoItem>
          <Icon path={mdiCalendar} size={1} />
          <h2>Transferência semanal</h2>
          <p>
            <strong>Toda terça-feira</strong>, o isaac vai repassar os valores referentes às
            matrículas pagas pelas famílias na semana anterior.
            <br />
            <br />
            Em caso de feriado, o repasse será feito no dia útil seguinte.
          </p>
        </InfoItem>
        <Separator color="neutral-2" orientation="horizontal" />
        <InfoItem>
          <Icon path={mdiSwapHorizontal} size={1} />
          <h2>Parcelas pagas pelas famílias</h2>
          <p>
            Ao contrário do repasse de mensalidades (no qual o isaac transfere os valores
            independente do pagamento pelas famílias), o repasse de matrículas é{' '}
            <strong>feito somente após o pagamento das faturas pelas famílias.</strong>
          </p>
        </InfoItem>
        <Separator color="neutral-2" orientation="horizontal" />
        <InfoItem data-testid="enrollment-disclaimer-section">
          <Typography variation="bodySmall" withoutMargin>
            Nas semanas em que nenhuma matrícula for paga pelas famílias, não haverá transferência.
            Caso seu repasse de matrículas de uma semana tenha valor total negativo, ele será
            descontado do seu próximo repasse de mensalidades.
          </Typography>
        </InfoItem>
        <Separator color="neutral-2" orientation="horizontal" />
        <InfoItem data-testid="glossary-section">
          <Icon path={mdiBookOpenVariant} size={1} />
          <h2>Glossário do repasse</h2>
          <p>
            Não entendeu algum termo ou conceito do seu repasse? Não se preocupe,{' '}
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://centraldeajuda.olaisaac.io/repasse"
            >
              acesse o glossário do repasse
            </Link>{' '}
            e veja as definições!
          </p>
        </InfoItem>

        <ContactInfo>
          <Icon path={mdiMessageText} size={1} />
          <h2>Ainda tem dúvidas?</h2>
          <p className="label">Entre em contato com o Apoio isaac</p>
          <p className="contact">WhatsApp: (11) 97876-5797</p>
          <p className="contact">falecom@isaac.com.br</p>
        </ContactInfo>
      </DrawerContent>
    </Drawer>
  )
}
