import { Typography } from '@olaisaac/design-system'
import { formatMicroCentsToReal } from 'src/shared/utils'
import styled from 'styled-components'

export const StyledBodyLargeTypography = styled(Typography).attrs({
  variation: 'bodyLarge',
  withoutMargin: true,
})``

const GreenTypography = styled(Typography).attrs({
  withoutMargin: true,
})(({ theme: { primitiveTokens: { colors: { green } } } }) => ({
  color: green[90],
}))

export const RedTypography = styled(Typography).attrs({
  withoutMargin: true,
})(({ theme: { primitiveTokens: { colors: { coral } } } }) => ({
  color: coral[90],
}))

export const MoneyTypography = ({ amount, ...props }) => {
  let MyTypography = styled(Typography).attrs({ withoutMargin: true })``

  if (amount > 0) {
    MyTypography = GreenTypography
  } else if (amount < 0) {
    MyTypography = RedTypography
  }

  return (
    <MyTypography {...props} style={{ whiteSpace: 'nowrap', ...props.style }}>
      {formatMicroCentsToReal(amount)}
    </MyTypography>
  )
}
