import { Button } from '@gravity/button'
import styled from 'styled-components'
import { Tooltip } from '@gravity/tooltip'
const StyledFooter = styled.div`
  padding: 1.5rem 0;
  margin-top: auto;
  display: flex;
  justify-content: end;
`

interface StepFooterProps {
  children?: React.ReactNode
  disabled?: boolean
  tooltipMessage?: string
}

export const StepFooter = ({
  children,
  disabled = false,
  tooltipMessage = '',
}: StepFooterProps) => {
  return (
    <StyledFooter>
      {children}
      {tooltipMessage ? (
        <Tooltip position="left" text={tooltipMessage}>
          <Button disabled={disabled}>Continuar</Button>
        </Tooltip>
      ) : (
        <Button disabled={disabled}>Continuar</Button>
      )}
    </StyledFooter>
  )
}
