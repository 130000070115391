import React, { useState } from 'react'
import Icon from '@mdi/react'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'

import { IconButton } from '@gravity/icon-button'

import { StudentsSliderProps } from './types'
import * as Styled from './styles'

import { v4 as uuidv4 } from 'uuid'

export const StudentsSlider = ({
  quantityDots,
  quantityItems,
  anchorEl,
  calcBase,
}: StudentsSliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleNext = () => {
    if (currentIndex < quantityItems - 1) {
      setCurrentIndex(currentIndex + 1)
      anchorEl.current.scrollLeft += calcBase
    }
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
      anchorEl.current.scrollLeft -= calcBase
    }
  }

  const handleDotClick = (index: number) => {
    setCurrentIndex(index)
    anchorEl.current.scrollLeft = index * calcBase
  }

  return (
    <Styled.Container>
      <IconButton variant="ghost" onClick={handlePrev} disabled={currentIndex === 0}>
        <Icon path={mdiChevronLeft} size={1} />
      </IconButton>
      <Styled.Content>
        {Array.from({ length: quantityDots }).map((_, index) => (
          <Styled.Dot
            key={`student-dot-${uuidv4()}`}
            selected={index === currentIndex}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </Styled.Content>
      <IconButton variant="ghost" onClick={handleNext} disabled={currentIndex === quantityDots - 1}>
        <Icon path={mdiChevronRight} size={1} />
      </IconButton>
    </Styled.Container>
  )
}
