import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  left: 96px;
  bottom: 0px;
  right: 0px;
  padding: ${({ theme }) => theme.spacing(4)}px ${({ theme }) => theme.spacing(8)}px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`

export const ContentWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing(7)}px;
`
