import { Container, StyledCard, TitleText } from './styled'
import { ContainerCardsProps } from './types'

export const ContainerCards = ({ cards, title }: ContainerCardsProps) => {
  return (
    <StyledCard>
      <TitleText>{title}</TitleText>
      <Container>
        {cards.map(card => {
          return card
        })}
      </Container>
    </StyledCard>
  )
}
