import styled from 'styled-components'

import theme from '@/shared/theme'

const VALUE_COLOR = theme.primitiveTokens.colors.gray[60]

export const TransferDates = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`

export const Value = styled.p`
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 700;
  color: ${VALUE_COLOR};
`
