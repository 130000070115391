import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Button, Typography } from '@olaisaac/design-system'

interface ConfirmFlexiblePeriodEditProps {
  handleClose: () => void
  handleSubmit: () => void
  isOpen: boolean
}

export const ConfirmFlexiblePeriodEdit = ({
  isOpen,
  handleClose,
  handleSubmit,
}: ConfirmFlexiblePeriodEditProps) => {
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle style={{ paddingTop: '24px' }}>
        <Typography variation="headlineDesktopSmall">
          Edição de desconto em parcela vencida
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Após confirmar da edição, uma nova fatura será emitida com vencimento no dia seguinte à
          data da edição.
        </Typography>
        <Typography style={{ marginTop: '1em' }}>Você deseja continuar com a edição?</Typography>
      </DialogContent>
      <DialogActions>
        <Button variation="ghost" onClick={handleClose}>
          Voltar
        </Button>
        <Button
          variation="primary"
          onClick={() => {
            handleSubmit()
          }}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
