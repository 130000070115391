export const CURRENT_DATE = new Date()
export const CURRENT_MONTH_NUMBER = new Intl.DateTimeFormat('pt-BR', { month: '2-digit' }).format(
  CURRENT_DATE
)
export const CURRENT_MONTH_TEXT = new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(
  CURRENT_DATE
)
export const DECEMBER_MONTH_NUMBER = '12'
export const CURRENT_YEAR = CURRENT_DATE.getFullYear()

export const NEXT_DATE = new Date(CURRENT_YEAR, CURRENT_DATE.getMonth() + 1, 1)
export const NEXT_MONTH_NUMBER = new Intl.DateTimeFormat('pt-BR', { month: '2-digit' }).format(
  NEXT_DATE
)
export const NEXT_MONTH_TEXT = new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(NEXT_DATE)
