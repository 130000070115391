import type { FinancialCategoryItemsTypes } from '@/modules/report/models/FinancialCategoryItemsTypes'

/**
 * Constant that defines the labels for each financial category item type
 */
export const FINANCIAL_CATEGORY_ITEM_TYPES_LABELS: Record<
  FinancialCategoryItemsTypes,
  string | undefined
> = {
  NEW_CONTRACT: 'Novo contrato',
  APPLIED_DISCOUNT: 'Edição de desconto',
  AGGLUTINATION_DISCOUNT: 'Desconto por aglutinação de parcelas',
  LOST_DISCOUNT: 'Adicional desc. antecipação',
  CANCEL: 'Cancelado',
  SCHOOL_LIQUIDATION: 'Recebido na escola',
  ORIGINAL_AMOUNT_CHANGED: 'Edição de valor',
  CORRECTION: 'Correção pontual de repasse',
  AJUSTE_OPS_REPASSE: 'Adequação de repasses anteriores',
  AJUSTE_OPS_REPASSE_ATUAL: 'Adequação de repasse atual',
  ESTORNO_MATRICULA: 'Estorno matrícula',
  REPASSE_LINK_PAGAMENTO: 'Ajuste de pagamento realizado via link ERP',
  STONE_TRANSACTION: 'Maquininha isaac',
  BONUS_MATRICULA: 'Bônus Matrícula',
  BONUS_PROSPECCAO: 'Bônus Comercial',
  BONUS_RETENCAO: 'Bônus Retenção',
  INCENTIVO_COMERCIAL_TEMPORARIO_CASHBACK: 'Incentivo comercial temporário - cashback',
  INCENTIVOS_COMERCIAIS_ERP: 'Reembolso Serviços ERP',
  INCENTIVOS_COMERCIAIS_OUTROS: 'Incentivos - isaac',
  INCENTIVOS_COMERCIAIS_AGENDA_DIGITAL: 'Incentivos comerciais - Agenda digital',
  ADIANTAMENTO_MATRICULA: 'Adiantamento acordado entre escola e isaac',
  ADIANTAMENTO_MESMA_COMPETENCIA: 'Adiantamento de mesma competência',
  ADIANTAMENTO_OPS_REPASSE: 'Ajustes de repasses anteriores',
  ADIANTAMENTO_OPS_RETENCAO: 'Adiantamento acordado entre escola e isaac',
  ADIANTAMENTO_PROSPECCAO: 'Adiantamento pontual acordado entre escola e isaac',
  ADIANTAMENTO_QITECH_B2B: 'Débito da parcela do crédito de curto prazo',
  ANTECIPACAO_REPASSE: 'Antecipação de repasse',
  AJUSTE_OPERACIONAL_ESCOLA: 'Adequação operacional da escola',
  AJUSTE_OPS_CREDITO: 'Ajuste operacional de crédito',
  AJUSTE_REMATRICULADOS_INADIMPLENTES: 'Ajuste de rematriculados inadimplentes',
  COBRANCAS_CUSTOS_JUDICIAIS: 'Cobrança de custos judiciais',
  COBRANCAS_VENCIDAS_ARCOPAY: 'Cobranças vencidas Arcopay',
  DEBITO_REPASSE_NEGATIVO: 'Débito de repasse negativo',
  DEBITO_REPASSE_NEGATIVO_MATRICULA: 'Repasses negativos de matrícula',
  DEBITO_TR_RETROATIVO: 'Valor retroativo da taxa isaac na parcela de matrícula',
  DIAGNOSTICO_ESCOLAR_CORUS: 'Diagnóstico Escolar',
  MIGRACAO_ISAAC_WP: 'Migração isaac - WP',
  MULTA_MEIO_PAGAMENTO_NAO_PERMITIDO: 'Multa por meio de pagamento não permitido',
  ORDEM_JUDICIAL: 'Retenção de ordem judicial',
  PAGAMENTO_ESCOLA: 'Dívida isaac',
  PARCELA_DIVIDA_MATERIAL_DIDATICO: 'Material didático',
  PARCELA_DIVIDA_SAS: 'Parcela dívida SAS',
  PENDENCIAS_INADIMPLENTES_REMATRICULADOS: 'Inadimplentes Rematriculados',
  REPASSE_ARCOPAY: 'Arcopay',
  TAXA_IMPLEMENTACAO: 'Serviços de implementação',
  TAXA_ISAAC_RECOMPENSA: 'isaac recompensa',
  BALANCE_TRANSFER: undefined,
  CORRECAO_OPERACIONAL: undefined,
}
