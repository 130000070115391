import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

export const useSendGuardianDetailsInvoiceEvent = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const sendEvent = (component: 'row' | 'button', options?: Record<string, unknown>) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.INSTALLMENT_DRAWER,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $component: component,
          ...options,
        },
      })
  }

  return sendEvent
}
