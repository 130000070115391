import { Typography } from '@olaisaac/design-system'
import { Skeleton } from '@material-ui/lab'
import * as Styled from './styles'
import { PersonalDataProps } from './types'

const PersonalDataItem = ({
  title,
  text,
  isLoading,
}: {
  isLoading: boolean
  text: string
  title: string
}) => {
  return (
    <Styled.ItemContainer>
      <Typography variation="bodySmall" color="secondary">
        {title}
      </Typography>
      {isLoading ? (
        <Skeleton height={32} width={96} />
      ) : (
        <Typography variation="bodyLarge">{text}</Typography>
      )}
    </Styled.ItemContainer>
  )
}

export const PersonalData = ({ data, isLoading }: PersonalDataProps) => {
  return (
    <div>
      <Typography variation="headlineDesktopXsmall">Produto e dados cadastrais</Typography>
      <Styled.PersonalDataContainer>
        <PersonalDataItem title="Período letivo" text={data.referenceYear} isLoading={isLoading} />
        <PersonalDataItem title="Produto" text={data.productName} isLoading={isLoading} />
        <PersonalDataItem title="Aluno" text={data.studentName} isLoading={isLoading} />
        <PersonalDataItem
          title="Responsável financeiro"
          text={data.guardianName}
          isLoading={isLoading}
        />
      </Styled.PersonalDataContainer>
    </div>
  )
}
