import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

export const StyledTypography = styled(Typography)`
  font-weight: 700;
`

export const TextScondaryTypography = styled(Typography).attrs(() => ({
  display: 'inline',
  color: 'textSecondary',
}))``
