import Cookies from 'universal-cookie'
import AuthorizationClient, {
  queryTreeBuilder,
  Client as SHClient,
  Realm as SHRealm,
  UserAuthorizationData,
  COOKIE_ACCESS_TOKEN,
} from '@olaisaac/sorting-hat'

import config from '@/config'

import type { Realm, Client, GetUserDataResponse, UserAuthData } from './types'

class SortingHatClient {
  private client: AuthorizationClient

  private cookies: Cookies

  constructor() {
    this.client = new AuthorizationClient({
      baseURL: config.SORTING_HAT.HOST ?? '',
      appName: config.SORTING_HAT.APP_AZP ?? '',
    })

    this.cookies = new Cookies()
  }

  public async getUserData(): Promise<GetUserDataResponse> {
    const credential = this.cookies.get(COOKIE_ACCESS_TOKEN)

    const requirements = {
      realm: config.SORTING_HAT.REALM as SHRealm,
      client: config.SORTING_HAT.APP_AZP as SHClient,
    }
    const queryTree = queryTreeBuilder(requirements)

    try {
      const { success, data } = await this.client.isAuthorized(credential, true, queryTree)

      if (!success || !data) {
        return { data: null, err: true }
      }

      return { data: this.parseUserAuthData(data), err: false }
    } catch {
      return { data: null, err: true }
    }
  }

  public logout() {
    this.clearCookies()
    this.redirectToLogin()
  }

  private parseUserAuthData(data: UserAuthorizationData): UserAuthData {
    const formattedData: UserAuthData = {
      realm: data.realm as Realm,
      client: data.client as Client,
      expires_at: data.expires_at,
      user: data.user,
      plain_roles: data.plain_roles,
      multi_tenant_roles: data.multi_tenant_roles as UserAuthData['multi_tenant_roles'],
      resources: data.resources,
    }

    return formattedData
  }

  private clearCookies() {
    document.cookie.split(';').forEach(cookie => {
      const [name] = cookie.split('=')
      if (name) {
        this.cookies.remove(name.trim())
      }
    })
  }

  private redirectToLogin() {
    const LOGIN_URL = `${config.SORTING_HAT.HOST}${config.SORTING_HAT.LOGIN_PATH}`
    window.location.replace(LOGIN_URL)
  }
}

export const client = new SortingHatClient()
