import { useParams } from 'react-router-dom'
import { PathParams } from '@/shared/interfaces'

const URL_REGEX_EXPRESSION = /=(https|http):\/\/.*\/SCHOOL_SLUG/

export const useFormatNotificationLink = (linkUrl: string) => {
  const { schoolSlug } = useParams<PathParams>()

  return {
    formattedLinkUrl: encodeURI(
      decodeURIComponent(linkUrl)?.replace(
        URL_REGEX_EXPRESSION,
        `=${window.location.origin}/${schoolSlug}`
      )
    ),
  }
}
