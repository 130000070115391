import CreditCard from 'src/escolas/assets/creditCard.svg'
import { Typography } from '@olaisaac/design-system'
import { formatCentsToReal } from 'src/shared/utils'
import type { PaymentPlanMethodsProps } from './types'
import { AvailableMethod, StyledImg } from './styles'

export const PaymentPlanDebitCard = ({
  totalAmount,
  isPaymentFeeEnabled,
}: PaymentPlanMethodsProps) => {
  const noFeeText = isPaymentFeeEnabled ? '' : ' sem juros'
  return (
    <AvailableMethod>
      <StyledImg src={CreditCard} />
      <Typography variation="bodySmall" color="secondary">
        <b>Cartão de débito:</b> {`à vista de ${formatCentsToReal(totalAmount)}${noFeeText}`}
      </Typography>
    </AvailableMethod>
  )
}
