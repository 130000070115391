import styled from 'styled-components'
import { Skeleton } from '@gravity/skeleton'
import { spacing, borderRadius } from '@gravity/tokens'

export const PayoutSkeleton = styled(Skeleton)`
  border-radius: ${borderRadius[6]};
`

export const TitleContainer = styled.div`
  margin-bottom: ${spacing[8]};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[1]};
`

export const AmountContainer = styled.div`
  margin-bottom: ${spacing[8]};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[2]};
`
