export const DEFAULT_PAGE = 1
export const DEFAULT_PER_PAGE = 20

export const DEFAULT_SORT_ORDER = 'ASC'
export const DEFAULT_SORT_BY = 'name'

export const DEFAULT_PER_PAGE_OPTIONS = [10, 20, 30, 40, 50]

export enum GuardiansQueryParamsNameEnum {
  debtStatus = 'debt_status',
  guide = 'guide',
  searchTerm = 'search_term',
}
