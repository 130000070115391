import styled from 'styled-components'
import { Table } from '@gravity/table'

export const Wrapper = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  padding: 1px;

  width: 100%;
  display: flex;
  align-items: start;
`

export const TableRow = styled(Table.Row)`
  :hover {
    cursor: pointer;
  }
`

type TableBadgeCellProps = {
  enableTooltip: boolean
}
export const TableBadgeCell = styled(Table.BadgeCell)<TableBadgeCellProps>`
  min-width: max-content;

  ${({ enableTooltip }) =>
    enableTooltip &&
    `
    pointer-events: auto;
    cursor: default;
  `}
`
