import styled from 'styled-components'
import { colors, spacing } from '@gravity/tokens'

type PageContainerProps = {
  isLoading: boolean
}
export const PageContainer = styled.div<PageContainerProps>`
  padding: ${spacing[12]} 0;
  ${({ isLoading }) =>
    isLoading &&
    `
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      gap: ${spacing[20]};
      & > :nth-child(2) {
        margin-top: -${spacing[10]};
      }
    `}
`

export const ComingSoonTextWrapper = styled.div`
  margin-top: ${spacing[20]};
  h3 {
    color: ${colors['colors-text-main-3']} !important;
  }
`
export const BigNumbersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${spacing[6]};
  margin-top: ${spacing[4]};
`
