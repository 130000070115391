import { Installment, InstallmentStatuses, InstallmentType } from '@/shared/interfaces'
import { openContract, openContract2 } from './contracts'

export const installment1: Installment = {
  amount: 100000,
  contract_id: openContract.id,
  created_at: '2021-01-27T17:29:18.701Z',
  due_date: '2021-12-02T16:50:26.393-03:00',
  id: '1402b44d-6629-485a-bdc1-58c854de534c',
  status: InstallmentStatuses.OPEN,
  type: InstallmentType.TUITION,
  order_reference: '1',
}

const DIA = 1000 * 60 * 60 * 24

export const installment1_2: Installment = {
  amount: 100000,
  contract_id: openContract.id,
  created_at: '2021-01-27T17:29:18.701Z',
  due_date: new Date(new Date().getTime() + 3 * DIA).toISOString(),
  id: '1402b44d-6629-485a-bdc1-58c854de534c',
  status: InstallmentStatuses.OPEN,
  type: InstallmentType.TUITION,
  order_reference: '1',
}

export const installment2: Installment = {
  amount: 100000,
  contract_id: openContract.id,
  created_at: '2021-01-27T17:29:18.701Z',
  due_date: '2022-01-02T16:50:26.393-03:00',
  id: '1402b44d-6620-485a-bdc1-58c854de534d',
  status: InstallmentStatuses.PAID,
  type: InstallmentType.TUITION,
  order_reference: '2',
}

export const installment3: Installment = {
  amount: 50000,
  contract_id: openContract.id,
  created_at: '2021-01-27T17:29:18.701Z',
  due_date: '2022-01-02T16:50:26.393-03:00',
  id: 'd238411d-d7b6-4f88-ad4b-5c7bd239aadb',
  status: InstallmentStatuses.OPEN,
  type: InstallmentType.TUITION,
  order_reference: '3',
}

export const installment4: Installment = {
  amount: 100000,
  contract_id: openContract.id,
  created_at: '2021-01-27T17:29:18.701Z',
  due_date: '2022-01-02T16:50:26.393-03:00',
  id: 'd238411d-d7b6-4f88-ad4b-5c7bd239aadb',
  status: InstallmentStatuses.OPEN,
  type: InstallmentType.TUITION,
  order_reference: '4',
}

export const installment5: Installment = {
  amount: 100000,
  contract_id: openContract.id,
  created_at: '2021-01-27T17:29:18.701Z',
  due_date: new Date(new Date().getTime() + 3 * DIA).toISOString(),
  id: 'd238411d-d7b6-4f88-ad4b-5c7bd239aadb',
  status: InstallmentStatuses.OPEN,
  type: InstallmentType.TUITION,
  order_reference: '5',
}

export const installment6: Installment = {
  amount: 100000,
  contract_id: openContract2.id,
  created_at: '2021-01-27T17:29:18.701Z',
  due_date: new Date(new Date().getTime() + 3 * DIA).toISOString(),
  id: '4c89ab6c-2a3f-11ed-a261-0242ac120002',
  status: InstallmentStatuses.OPEN,
  type: InstallmentType.TUITION,
  order_reference: '5',
}

export const canceledInstallment: Installment = {
  id: '2eef7b70-7abe-4c41-950d-4886c10aa0fd',
  contract_id: 'e2e84732-6ade-4d07-b150-f6f6446feb94',
  created_at: '2021-11-23T20:37:59.866245-03:00',
  updated_at: '2021-12-03T12:06:30.606236-03:00',
  amount: 12222,
  due_date: '2021-11-22T21:00:00-03:00',
  status: InstallmentStatuses.CANCELED,
  type: InstallmentType.ENROLLMENT,
  order_reference: '6',
}

export const agglutinatedInstallment: Installment = {
  id: 'af2e2dc7-502e-49ae-bec7-1b037867d24e',
  created_at: '2021-11-01T11:07:41.723384-03:00',
  updated_at: '2021-12-03T12:06:30.606236-03:00',
  contract_id: '7473163f-23b7-49f6-95f4-ce7b81215fbd',
  amount: 58000,
  due_date: '2022-05-04T21:00:00-03:00',
  status: InstallmentStatuses.OPEN,
  type: InstallmentType.TUITION,
  order_reference: '7',
}
export const enrollemnt_installment1: Installment = {
  amount: 100000,
  contract_id: openContract.id,
  created_at: '2021-01-27T17:29:18.701Z',
  due_date: '2021-12-02T16:50:26.393-03:00',
  id: 'b48c2198-33b5-11ed-a261-0242ac120002',
  status: InstallmentStatuses.OPEN,
  type: InstallmentType.ENROLLMENT,
  order_reference: '1',
}

export const isaac_pay_installment1: Installment = {
  amount: 121212,
  contract_id: openContract.id,
  created_at: '2021-01-27T17:29:18.701Z',
  due_date: '2021-12-02T16:50:26.393-03:00',
  id: '1402b44d-6629-485a-bdc1-58c854de222',
  status: InstallmentStatuses.OPEN,
  type: InstallmentType.ISAACPAY,
  order_reference: '7',
}
export const installmentsList = [installment1, installment2]
