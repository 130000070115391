import { addDiscount } from '@/modules/guardians/InstallmentsDrawerContainer/variations/AddDiscount/addDiscount'
import { contractEditEnrollmentDueDay } from '@/modules/guardians/InstallmentsDrawerContainer/variations/ContractEditEnrollmentDueDay/contractEditEnrollmentDueDay'
import { installment } from '@/modules/guardians/InstallmentsDrawerContainer/variations/Installment/installment'
import { loading } from '@/modules/guardians/InstallmentsDrawerContainer/variations/Loading/loading'
import { negotiation } from '@/modules/guardians/InstallmentsDrawerContainer/variations/Negotiation/negotiation'
import { renegotiation } from '@/modules/guardians/InstallmentsDrawerContainer/variations/Renegotiation/renegotiation'

export type DrawerVariationsProps = {
  content?: JSX.Element
  footer?: JSX.Element
  fullContent?: JSX.Element
  header: JSX.Element
}

export const drawerVariations = {
  addDiscount,
  contractEditEnrollmentDueDay,
  installment,
  loading,
  negotiation,
  renegotiation,
}
