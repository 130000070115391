import { Box } from '@material-ui/core'
import { Typography as DSTypography } from '@olaisaac/design-system'
import styled from 'styled-components'

export const TableContainer = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[5]};
  border-bottom: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[5]};
  margin-bottom: ${({ theme }) => theme.primitiveTokens.spacing[20]};

  // todo: remover quando atualizar table para @gravity/table
  > div:last-of-type {
    overflow-y: hidden;
    overflow-x: auto;
  }

  & tbody tr {
    cursor: pointer;
  }
`

export const StyledTableHeader = styled(DSTypography).attrs({
  component: 'span',
  color: 'secondary',
  variation: 'subtitleDesktopSmall',
})`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  cursor: default;
`
