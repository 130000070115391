import { Box, ListItemIcon } from '@material-ui/core'
import styled from 'styled-components'
import { InfoOutlined } from '@material-ui/icons'

import type { StyledInputMinDownProps } from './types'
export const StyledInput = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  min-width: 255px;
  justify-content: space-between;
`

export const StyledInputMinDown = styled(StyledInput)<StyledInputMinDownProps>`
  ${({ disabled, theme }) =>
    disabled &&
    `
      fieldset {
        border-color: ${theme.primitiveTokens.colors.gray[5]} !important;
      }
`}
`

export const StyledInputLabel = styled(Box)`
  min-height: 20px;
  margin-bottom: 16px;
`
export const MenuItemIcon = styled(ListItemIcon)`
  max-width: 13px;
  min-width: 13px;
  margin-left: 8px;
`

export const StyledInfoOutlined = styled(InfoOutlined)`
  ${({ theme }) =>
    `
    color: ${theme.primitiveTokens.colors.gray[90]};
  `}
`
