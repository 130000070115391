import { Dispatch, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import { ManualSignDrawerActions } from 'src/escolas/components/contract/ManualSignDrawer/ManualSignDrawerActions'
import { useApi, useSnackbar } from 'src/shared/hooks'
import { ContractDetailsDrawerState } from 'src/escolas/components/contract/ContractDetailsDrawer/constants'
import { Typography, DialogContent } from '@olaisaac/design-system'

export type ManualSignDrawerProps = {
  contractId: string | null
  isSignable: boolean
  setDrawerState: Dispatch<SetStateAction<ContractDetailsDrawerState>>
  updateContractStatus: () => void
}

const ManualSignDrawer = ({
  contractId,
  updateContractStatus,
  setDrawerState,
  isSignable,
}: ManualSignDrawerProps) => {
  const { api } = useApi()
  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    isOpen: isSnackbarOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const submitHandler = async () => {
    if (!contractId) return
    await api.contracts
      .manualSign(contractId)
      .then(() => {
        updateContractStatus()
        setSnackbarMessage('Contrato salvo como assinado')
        setSnackbarIsOpen(true)
        !isSnackbarOpen && setDrawerState(ContractDetailsDrawerState.DEFAULT)
      })
      .catch(_ => {
        setSnackbarVariation('error')
        setSnackbarMessage(
          'Erro ao assinar o contrato. Se o erro persistir, entre em contato com o suporte'
        )
        setSnackbarIsOpen(true)
      })
  }

  const chooseDrawerActions = (isSignable: boolean, submitHandler: () => void) =>
    isSignable ? <ManualSignDrawerActions submitHandler={submitHandler} /> : null

  const drawerActions = chooseDrawerActions(isSignable, submitHandler)

  return (
    <>
      <DialogContent>
        <Typography variation="bodyLarge">
          Ao confirmar, a escola assegura que o contrato foi{' '}
          <strong>assinado em via impressa</strong> por todas as partes.
        </Typography>
        <Box py={2}>
          <Typography variation="bodyLarge">
            O responsável não receberá mais lembretes de assinatura via e-mail.
          </Typography>
        </Box>
      </DialogContent>

      {drawerActions}
    </>
  )
}

export default ManualSignDrawer
