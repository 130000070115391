import { Box } from '@material-ui/core'

import styled from 'styled-components'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const Paragraph = styled.p`
  color: #6d6d6d;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
`

export const Title = styled.h1`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
`
