import * as Styled from './styles'
import { Text } from '@gravity/text'
import noPending from '@/modules/guardians/assets/noPending.svg'

export const TeachingMaterialLogisticsPage = () => {
  return (
    <Styled.NoPendingWrapper>
      <Styled.Image src={noPending} alt="sem pendências" />
      <Text variant="body-1-medium">Não existem materiais para ser entregue</Text>
      <Text>
        Informe aos responsáveis que os materiais didáticos já podem ser pagos através do aplicativo
        meu isaac
      </Text>
    </Styled.NoPendingWrapper>
  )
}
