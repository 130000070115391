import { ReactNode } from 'react'
import { useNavigation } from '@/escolas/hooks'
import {
  ENROLLMENT_PAGE_BASE_PATH,
  ENROLLMENT_YEAR,
} from '@/modules/enrollment/constants/enrollment'
import { School, People } from '@material-ui/icons'
import { Chip, Link, Typography } from '@olaisaac/design-system'
import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'

const ShortcutContainer = styled.div`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  display: flex;
  gap: 12px;
  max-width: 50%;
`

const ShortcutContentWrapper = styled.div`
  flex: 1;
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 16px;
  margin-left: auto;
  width: max-content;
`

const IconWrapper = styled.div`
  color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
`

const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

type ShortcutProps = {
  eventCardName: string
  eventName: string
  eventScope: EventDispatcherEventScopes
  href: string
  icon: ReactNode
  linkText: string
  showChip?: boolean
  text: string
  title: string
}

const Shortcut = ({
  title,
  text,
  icon,
  linkText,
  href,
  showChip = false,
  eventScope,
  eventName,
  eventCardName,
}: ShortcutProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  return (
    <ShortcutContainer>
      <IconWrapper>{icon}</IconWrapper>
      <ShortcutContentWrapper>
        <TitleWrapper>
          <Typography variation="bodyLarge" style={{ lineHeight: '24px' }}>
            <strong>{title}</strong>
          </Typography>
          {showChip && <Chip label="Novo" variation="blue" />}
        </TitleWrapper>

        <Typography variation="bodyLarge">{text}</Typography>
        <StyledLink
          onClick={() =>
            isInitialized &&
            eventDispatcherClient.sendEvent({
              scope: eventScope,
              name: EnrollmentEventDispatcherEvents.CLICKED,
              action: 'click',
              customProperties: { $Button_name: eventName, $Card_name: eventCardName },
            })
          }
          href={href}
        >
          {linkText} <ChevronRightIcon />
        </StyledLink>
      </ShortcutContentWrapper>
    </ShortcutContainer>
  )
}

const Container = styled.div`
  margin: 24px 16px;
  display: flex;
  gap: 16px;
  width: 100%;
`
/**
 * @deprecated The entire component will be deprecated after the EEMAT-604-ENABLE_MATRICULAS_SELECT_CYCLES rollout
 */
export const Shortcuts = () => {
  const { getNavigationUrl } = useNavigation()
  const isSelectCyclesEnabled = useUnleashFlag(UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES)

  return (
    <>
      {!isSelectCyclesEnabled && (
        <Container>
          <Shortcut
            icon={<School />}
            title={`Matrículas ${ENROLLMENT_YEAR}`}
            text={`Adicione novos contratos, inicie e acompanhe o processo de matrícula do período letivo ${ENROLLMENT_YEAR}.`}
            linkText={`Acessar a página de matrículas ${ENROLLMENT_YEAR}`}
            href={getNavigationUrl({ path: `/${ENROLLMENT_PAGE_BASE_PATH}` })}
            showChip
            eventScope={EventDispatcherEventScopes.CONTRACTS_PAGE}
            eventName="ACESSAR_PÁGINA_DE_MATRÍCULAS"
            eventCardName={`MATRICULAS_${ENROLLMENT_YEAR}`}
          />
          <Shortcut
            icon={<People />}
            title="Página de responsáveis"
            text="Veja a situação financeira de cada responsável, realize edições e pagamentos em contratos e parcelas."
            linkText="Acessar página de responsáveis"
            href={getNavigationUrl({ path: `/responsaveis` })}
            eventScope={EventDispatcherEventScopes.CONTRACTS_PAGE}
            eventName="ACESSAR_PÁGINA_DE_RESPONSÁVEIS"
            eventCardName={`MATRICULAS_${ENROLLMENT_YEAR}`}
          />
        </Container>
      )}
    </>
  )
}
