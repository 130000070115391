import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks/useApi'
import { useSchoolHomeSearchService } from '@/modules/school-home/services/school-home-search'

import type {
  FetchSearchRequest,
  FetchSearchResponse,
} from '@/modules/school-home/services/school-home-search/types'

export const useSearch = (
  { schoolId, search, type }: FetchSearchRequest,
  options?: UseQueryOptions<FetchSearchResponse>
) => {
  const { apiClient } = useApi()

  const service = useSchoolHomeSearchService(apiClient.getClients().bffApi)

  return useQuery<FetchSearchResponse>(
    ['search', search, schoolId, type],
    async () => {
      const response = await service.search({
        schoolId,
        search,
        type,
      })

      return response
    },
    options
  )
}
