import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { Button, IconButton } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'

export const StyledTypography = styled(Typography)`
  font-weight: 700;
`

export const CaptionScondaryTypography = styled(Typography).attrs(() => ({
  variant: 'caption',
  color: 'textSecondary',
}))``

export const DeleteButton = styled(IconButton).attrs(() => ({
  variation: 'ghost',
  size: 'small',
}))``

export const DeleteBox = styled(Box)`
  position: absolute;
  top: 14px;
  right: 16px;
`

export const EditButton = styled(IconButton).attrs(() => ({
  variation: 'ghost',
  size: 'small',
}))`
  position: absolute;
  top: 14px;
  right: 56px;
`

export const EditBox = styled(Box)`
  position: absolute;
  top: 14px;
  right: 56px;
`

export const SaveButton = styled(Button).attrs(() => ({
  variation: 'ghost',
  fullWidth: true,
}))`
  text-transform: unset;
  font-weight: 700;
  align-self: flex-end;
`

export const DiscountPaper = styled(Paper).attrs(() => ({
  variant: 'outlined',
}))`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 512px;
`
