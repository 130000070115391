import { Box, IconButton } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import { CloseRounded as CloseRoundedIcon } from '@material-ui/icons'

import { colors, getBoxProps, spacing } from './styles'
import { MouseEventHandler } from 'react'

type MenuHeaderProps = {
  onClose: MouseEventHandler<HTMLButtonElement>
}

export const MenuHeader = ({ onClose }: MenuHeaderProps) => {
  return (
    <Box
      {...getBoxProps({
        top: 0,
        marginBottom: spacing[3],
        borderBottom: `1px solid ${colors.gray[10]}`,
        component: 'header',
      })}
    >
      <Typography variation="headlineDesktopXsmall">Quadro de avisos</Typography>
      <IconButton onClick={onClose} size="small" aria-label="Fechar menu de avisos">
        <CloseRoundedIcon color="inherit" />
      </IconButton>
    </Box>
  )
}
