import { InstallmentType } from '@/shared/interfaces'
import { formatCentsToReal } from '@/shared/utils'
import { calculateCommomAmount, calculateTotalDiscount } from '../../utils'
import { Typography } from '@olaisaac/design-system'
import { Installment } from '@/modules/contract/services/types'
import * as Styled from './styles'
import { TotalItemProps } from './types'

const formatTotalDetail = (installments: Installment[]) => {
  const commomAmount = calculateCommomAmount(installments)

  let text = `em ${installments.length}x`

  if (commomAmount > 0) {
    text += ` de ${formatCentsToReal(commomAmount)}`
  }

  return text
}

const TotalItem = ({ title, amount, detail }: TotalItemProps) => {
  return (
    <Styled.ItemContainer role="group" aria-label={title}>
      <Typography variation="subtitleDesktopSmall" color="secondary">
        {title}
      </Typography>
      <Typography variation="headlineDesktopXsmall">{formatCentsToReal(amount)}</Typography>
      <Typography variation="caption" color="secondary">
        {detail}
      </Typography>
    </Styled.ItemContainer>
  )
}

type TableTotalsProps = {
  installments: Installment[]
}

export const TableTotals = ({ installments }: TableTotalsProps) => {
  const allDiscounts = installments.reduce(
    (allDiscounts, installment) => [...allDiscounts, ...installment.discounts],
    []
  )

  const enrollmentInstallments = installments.filter(
    i => i.receivable_type === InstallmentType.ENROLLMENT
  )
  const tuitionInstallments = installments.filter(
    i => i.receivable_type === InstallmentType.TUITION
  )

  return (
    <Styled.TableFooterContainer>
      <TotalItem
        title="Descontos aplicados"
        amount={calculateTotalDiscount(allDiscounts)}
        detail=""
      />
      <TotalItem
        title="Valor total da pré-matrícula"
        amount={enrollmentInstallments.reduce((a, b) => a + b.amount, 0)}
        detail={formatTotalDetail(enrollmentInstallments)}
      />
      <TotalItem
        title="Valor total das mensalidades"
        amount={tuitionInstallments.reduce((a, b) => a + b.amount, 0)}
        detail={formatTotalDetail(tuitionInstallments)}
      />
    </Styled.TableFooterContainer>
  )
}
