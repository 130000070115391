import styled from 'styled-components'
import { FormControlLabel, Typography } from '@olaisaac/design-system'

export const StyledTypography = styled(Typography).attrs({ withoutMargin: true })`
  overflow-wrap: break-word;
`
export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-bottom: 0px;
  }
`
