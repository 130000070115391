import { useState } from 'react'

export const useCheckoutFallbackVisibility = () => {
  const [visible, setVisible] = useState(false)

  const hide = () => setVisible(false)

  const show = (visibilityDelayInSec = 60) => {
    const showFallback = () => setVisible(true)
    return setTimeout(showFallback, visibilityDelayInSec * 1000)
  }

  return { visible, hide, show }
}
