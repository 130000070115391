import theme from '@/shared/theme'
import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

const { spacing } = theme.primitiveTokens
const { variation } = theme.humanizedTokens.components.typography

export const Wrapper = styled.div`
  text-align: center;
`
export const Title = styled.h1`
  ${variation.headlineDesktopMedium};
`

export const Subtitle = styled(Typography)`
  ${variation.bodyLarge};
  margin-bottom: ${spacing[6]};
`
