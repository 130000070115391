import { cleanSpecialCharacters } from '@/shared/utils'

export const validateCpf = (cpf: cpf | number): boolean => {
  if (!cpf) return false
  const value = cleanSpecialCharacters(`${cpf}`)
  const uniques = value.split('').slice(0, 9)
  const digitsAreAllEqual = new Set(value).size === 1

  if (digitsAreAllEqual) {
    return false
  }

  const [d10, d11] = value.split('').slice(9)

  let expectedD10 = uniques.reduce((acc, n, idx) => +n * (10 - idx) + acc, 0)
  expectedD10 = 11 - (expectedD10 % 11)
  if (expectedD10 > 9) expectedD10 = 0
  let expectedD11 = uniques.reduce((acc, n, idx) => +n * (11 - idx) + acc, 2 * expectedD10)
  expectedD11 = 11 - (expectedD11 % 11)
  if (expectedD11 > 9) expectedD11 = 0
  return +d10 === expectedD10 && +d11 === expectedD11
}

export const validateEmail = (email: email): boolean => {
  // eslint-disable-next-line security/detect-unsafe-regex
  const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return EMAIL_REGEX.test(email)
}

export const validatePhoneNumber = (phone: phone): boolean => {
  // eslint-disable-next-line security/detect-unsafe-regex
  const PHONE_REGEX = /^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/
  return PHONE_REGEX.test(phone)
}

export const validateSpecialCharactery = (text: string): boolean => {
  const REASON_REGEX = /^[a-záàâãéèêíïóôõöúçñ0-9 ]+$/i
  return REASON_REGEX.test(text)
}

export const validateEditReason = (reason: string): boolean => {
  if (!reason) {
    return false
  }

  if (reason.length < 5 || reason.length > 100) {
    return false
  }

  return validateSpecialCharactery(reason)
}

export const validateZipCode = (value: string): boolean => {
  const zip = value?.replace(/[^0-9]/g, '')
  return zip?.length === 8
}
