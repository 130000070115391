import styled from 'styled-components'

export const StyledSpan = styled.span`
  height: 8px;
  width: 8px;
  flex-shrink: 0;
  border-radius: 50%;
  display: inline-block;
  &.success {
    background-color: ${({ theme }) => theme.palette.success.main};
  }
  &.error {
    background-color: ${({ theme }) => theme.palette.error.main};
  }
  &.primary {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
  &.warning {
    background-color: ${({ theme }) => theme.palette.warning.main};
  }
  &.grey {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[80]};
  }
`
export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  text-wrap: nowrap;
`
