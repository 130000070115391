import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { SOFTCACHE_OPTIONS_BASE } from 'src/shared/constants'
import { useApi } from 'src/shared/hooks'
import { PathParams, School } from 'src/shared/interfaces'

export const useSchool = () => {
  const { schoolSlug } = useParams<PathParams>()
  const { api } = useApi()

  const { data: school, isError } = useQuery<School>(
    ['school', schoolSlug],
    () => api.schools.getBySlug(schoolSlug),
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: Boolean(schoolSlug),
      staleTime: 10 * 60 * 1000,
    }
  )

  return {
    school,
    isError,
  }
}
