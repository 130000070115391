import { InstallmentStatus, InstallmentType, NegotiationType } from '../GuardianDetails/constants'
import { NegotiationInstallment } from '../InstallmentsDrawerContainer/types'

export const negotiatedInstV1Mock = () => {
  return {
    contract_id: 'cdfcdfed-d819-421d-89a1-48838a79fbdb',
    current_amount: 100,
    current_fine: 10,
    current_interest: 10,
    discount_applied: null,
    due_date: '2023-06-04T06:00:00.00Z',
    enabled_payments_methods: ['BANK_SLIP', 'PIX'],
    origin_negotiation: {
      id: 'a1cd0d99-8ec2-458f-9bd3-817cd6a525a4',
      channel: 'PI-ESCOLA',
      negotiation_date: '2023-07-04T06:00:00.00Z',
      type: NegotiationType.NEGOTIATION_API,
      original_receivables: [
        {
          id: 'cdfcdfed-d819-421d-89a1-48838a79fbdb',
          current_amount: 100,
          competence_dates: ['2023-05'],
        },
        {
          id: 'a1552cec-084e-4d9b-b494-6c266b7a9557',
          current_amount: 100,
          competence_dates: ['2023-06'],
        },
        {
          id: '46f3c7ab-d9fd-4e2e-b0c6-b843bf16a9a4',
          current_amount: 100,
          competence_dates: ['2023-07'],
        },
        {
          id: '4537f8a3-12dd-45dc-9017-1430601bc43c',
          current_amount: 100,
          competence_dates: ['2023-08'],
        },
      ],
    },
    order_reference: '1/2',
    overdue_for_days: null,
    paid_date: null,
    product_id: 'cdfcdfed-d819-421d-89a1-48838a79fbdb',
    invoice: {
      payment_link: 'localhost',
    },
    status: InstallmentStatus.OPEN,
    type: InstallmentType.RECEIVABLE,
  } as NegotiationInstallment
}

export const negotiatedInstV2Mock = () => {
  return {
    contract_id: 'cdfcdfed-d819-421d-89a1-48838a79fbdb',
    current_amount: 100,
    current_fine: 10,
    current_interest: 10,
    discount_applied: null,
    due_date: '2023-06-04T06:00:00.00Z',
    enabled_payments_methods: ['BANK_SLIP', 'PIX'],
    origin_negotiation: {
      id: 'a1cd0d99-8ec2-458f-9bd3-817cd6a525a4',
      channel: 'PI-ESCOLA',
      negotiation_date: '2023-07-04T06:00:00.00Z',
      type: NegotiationType.NEGOTIATION_API_V2,
      original_receivables: [
        {
          id: 'cdfcdfed-d819-421d-89a1-48838a79fbdb',
          current_amount: 100,
          competence_dates: ['2023-05'],
        },
        {
          id: 'a1552cec-084e-4d9b-b494-6c266b7a9557',
          current_amount: 100,
          competence_dates: ['2023-06'],
        },
        {
          id: '46f3c7ab-d9fd-4e2e-b0c6-b843bf16a9a4',
          current_amount: 100,
          competence_dates: ['2023-07'],
        },
        {
          id: '4537f8a3-12dd-45dc-9017-1430601bc43c',
          current_amount: 100,
          competence_dates: ['2023-08'],
        },
      ],
    },
    order_reference: '1/1',
    overdue_for_days: null,
    paid_date: null,
    product_id: 'cdfcdfed-d819-421d-89a1-48838a79fbdb',
    invoice: {
      payment_link: 'localhost',
    },
    status: InstallmentStatus.OPEN,
    type: InstallmentType.DOWN_PAYMENT,
  } as NegotiationInstallment
}
