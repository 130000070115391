import { useEffect, ReactNode } from 'react'
import {
  EventDispatcherProvider as OlaisaacEventDispatcherProvider,
  useEventDispatcher,
  Environment,
  Realm,
} from '@olaisaac/event-dispatcher-sdk'

import { useJWT } from '@/shared/hooks/useJWT'

import { REALMS } from '@/shared/constants'

import envConfig from '@/config'

import { getUserRole } from '@/modules/app/utils/getUserRole'

type ComponentProps = { children: ReactNode }

const IS_MIXPANEL_ENABLED = envConfig.EVENT_DISPATCHER.MIXPANEL.ENABLED === 'true'

/**
 * Custom component to set Event Dispatcher default settings
 */
const EntryComponent = ({ children }: ComponentProps) => {
  const { userId, isAdmin, isGroupManager, hasPayoutReportAccess: isMaintainer } = useJWT()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  useEffect(() => {
    if (isInitialized) {
      eventDispatcherClient.identifyUser({ userId })
      eventDispatcherClient.setGlobalProperties({
        application: 'backoffice',
        environment: envConfig.ENV as Environment,
        realm: (isAdmin ? REALMS.ADMIN_REALM : REALMS.SCHOOL_REALM) as Realm,
        customProperties: {
          $role: getUserRole(isGroupManager, isMaintainer),
        },
      })
    }
  }, [isInitialized, userId, isAdmin, isGroupManager, isMaintainer])

  return <>{children}</>
}

/**
 * Custom provider to setup Event Dispatcher SDK
 */
export const EventDispatcherProvider = ({ children }: ComponentProps) => {
  return (
    <OlaisaacEventDispatcherProvider
      options={{
        mixpanelDebugMode: envConfig.EVENT_DISPATCHER.MIXPANEL.ENABLE_DEBUG_LOGS === 'true',
        mixpanelProjectToken: envConfig.EVENT_DISPATCHER.MIXPANEL.PROJECT_TOKEN,
      }}
      isMixpanelEnabled={IS_MIXPANEL_ENABLED}
    >
      <EntryComponent>{children}</EntryComponent>
    </OlaisaacEventDispatcherProvider>
  )
}
