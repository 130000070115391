import React, { useState, useRef } from 'react'
import { Grow, Paper, Popper, MenuItem, MenuList, ClickAwayListener, Box } from '@material-ui/core'
import { Button, ButtonGroup, Chip } from '@olaisaac/design-system'
import styled from 'styled-components'

type PopoverButtonOption = {
  handleOnClick: () => void
  label: string
  tag?: string
}

type PopoverButtonProps = {
  icon?: JSX.Element
  options: PopoverButtonOption[]
  title: string
}

const StyledMenuItem = styled(MenuItem)`
  font-size: 1rem;
  font-family: 'Roboto';
`

const StyledPopper = styled(Popper)`
  padding-bottom: 24px;
`

const PopoverButton = ({ title = '', options = [], icon }: PopoverButtonProps) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <Box>
      <ButtonGroup aria-label="split button" ref={anchorRef}>
        <Button
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {title} {icon}
        </Button>
      </ButtonGroup>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="top-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map(option => (
                    <StyledMenuItem key={option.label} onClick={option.handleOnClick}>
                      {option.label}{' '}
                      {option.tag && (
                        <Chip label={option.tag} variation="blue" style={{ marginLeft: '8px' }} />
                      )}
                    </StyledMenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </Box>
  )
}

export default PopoverButton
