import { Link, Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import {
  KeyboardArrowRightRounded as KeyboardArrowRightRoundedIcon,
  NotificationsActive as NotificationsActiveIcon,
} from '@material-ui/icons'

import { colors, getBoxProps } from './styles'

import type { MenuFooterProps } from './types'

export const MenuFooter = (props: MenuFooterProps) => {
  const { isLoading, isError, notificationsCount, onClick } = props

  const hasRemainingNotifications = notificationsCount > 0
  const notificationsText = notificationsCount > 1 ? 'avisos' : 'aviso'

  return (
    <Box
      {...getBoxProps({
        bottom: 0,
        borderTop: `1px solid ${colors.gray[10]}`,
        component: 'footer',
      })}
    >
      <Box display="flex" alignItems="center" gridGap={8} color={colors.gray[50]}>
        {isLoading ? (
          <Skeleton width={150} height={24} />
        ) : (
          hasRemainingNotifications && (
            <>
              <NotificationsActiveIcon width={24} htmlColor={colors.coral[70]} />
              <Typography
                variation="subtitleDesktopSmall"
                color="secondary"
                style={{ fontSize: '0.875rem' }}
              >
                Mais {notificationsCount} {notificationsText} para ler
              </Typography>
            </>
          )
        )}
      </Box>
      {!isError && (
        <Link onClick={onClick}>
          <Box
            display="inline-flex"
            alignItems="center"
            gridGap={4}
            style={{ textDecoration: 'inherit' }}
          >
            {isLoading ? (
              <Skeleton width={200} />
            ) : (
              <>
                Acessar Quadro de Avisos
                <KeyboardArrowRightRoundedIcon width={24} />
              </>
            )}
          </Box>
        </Link>
      )}
    </Box>
  )
}
