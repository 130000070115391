import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

/**
 * Blocks the user from navigating away from the current page.
 *
 * If the navigation was blocked, the "unblock" function must be called before using any method from the history api that changes the route.
 * Use the destinationPath to redirect the user to the intended path if the navigation was not blocked.
 *
 * @returns {BlockedNavigation}
 * @see https://v5.reactrouter.com/web/api/history
 */
export const useBlockNavigation = (): {
  destinationPath: string
  unblock: () => void
  userIsLeaving: boolean
} => {
  const history = useHistory()
  const [userIsLeaving, setIsUserLeaving] = useState(false)
  const destinationPath = useRef('')
  const navigationUnblock = useRef(() => null)

  useEffect(() => {
    const unblock = history.block(location => {
      destinationPath.current = location.pathname
      setIsUserLeaving(true)
      return false
    })

    navigationUnblock.current = unblock

    return () => unblock()
  }, [userIsLeaving])

  const unblock = () => {
    navigationUnblock.current()
    setIsUserLeaving(false)
  }

  return {
    userIsLeaving,
    unblock,
    destinationPath: destinationPath.current,
  }
}
