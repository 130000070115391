import styled from 'styled-components'
import { Grid, TableRow } from '@material-ui/core'
import TableCellMUI from '@material-ui/core/TableCell'
import TableHeadMUI from '@material-ui/core/TableHead'
import { Typography } from '@olaisaac/design-system'
import { TableRowProps } from './types'

export const Container = styled.div`
  padding: 16px 16px 0;
  border: 1px solid;
  border-bottom: 0;
  border-color: ${props => props.theme.primitiveTokens.colors.gray[10]};
  border-radius: 8px 8px 0 0;
  margin-top: 24px;
`

export const WarningContainer = styled(Grid)`
  padding: 8px;
  border-radius: 4px;
  margin: 16px 0;
  background-color: #f6f6f6;
`

export const TableContainer = styled.div`
  margin: 16px 0 0;
  padding: 8px 0;
  overflow-x: auto;
`

export const TableHead = styled(TableHeadMUI)`
  tr th {
    position: sticky;
    top: 0;
    background-color: ${props => props.theme.primitiveTokens.colors.gray[0]};
    box-shadow: inset 0 -1px ${props => props.theme.primitiveTokens.colors.gray[5]};
    z-index: 2;
  }
`

export const TableTitle = styled(Typography)`
  margin-bottom: 16px;
`

export const OrderReferenceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const BodyTableRow = styled(TableRow)<TableRowProps>`
  background-color: ${props => props.disabled && props.theme.primitiveTokens.colors.gray[2]};
  :hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

export const TableCell = styled(TableCellMUI)`
  height: 64px;
`

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  min-width: 164px;
`

export const Status = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  padding: 4px 0;
`

export const StatusText = styled(Typography)`
  padding: 0px 8px;
`

export const StatusMessage = styled.div`
  display: flex;
  padding-left: 20px;
`
