import { Dispatch, FC, SetStateAction, useState } from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import { useApi, useSnackbar } from 'src/shared/hooks'
import SelectedReceivableAgglutination from './SelectedReceivableAgglutination'
import AgglutinationDueDate from './AgglutionationDueDate'
import AgglutinationPayment from './AgglutinationPayment'
import AgglutinationDiscount from './AgglutinationDiscount'
import { CombinableReceivable } from 'src/escolas/contexts/receivablesAgglutinationContext'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from 'src/escolas/components/modal/ConfirmationDialog'
import { Contract, Agglutination } from 'src/shared/interfaces'
import { UseFormReturn } from 'react-hook-form'
import dayjs from 'dayjs'
import { formatCentsToReal } from 'src/shared/utils'
import {
  ActionDrawerHeader,
  Button,
  ButtonDocker,
  Divider,
  DialogContent,
  Drawer,
} from '@olaisaac/design-system'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'
import { Installment } from '@/modules/guardians/GuardianDetails/types'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'

const StyledDialogContent = styled(DialogContent)`
  position: relative;
`

export type AgglutinationDrawerProps = {
  callbackAgglutination?: Dispatch<SetStateAction<boolean>>
  disableChecks: () => void
  enableChecks?: () => void
  form: UseFormReturn<any>
  isOpen: boolean
  isProcessingAgglutination: boolean
  onClose: () => void
  orderReference?: string
  receivables?: CombinableReceivable[]
  selectedReceivableId?: uuid
  setContract?: (contract: Contract) => void
}

const AgglutinationDrawer: FC<AgglutinationDrawerProps> = ({
  callbackAgglutination,
  isOpen,
  onClose,
  form,
  setContract,
  disableChecks,
  isProcessingAgglutination,
  enableChecks,
}) => {
  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()
  const { checkoutCart, totalAmount: baseAmount } = useCheckoutCart()
  const { api } = useApi()
  const [apiError, setApiError] = useState(null)
  const [waitingApi, setWaitingApi] = useState<boolean>(false)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const combinedReceivables: CombinableReceivable[] = checkoutCart?.map(
    (installment: Installment) =>
      ({
        amount: installment.amount,
        due_date: installment.due_date,
        id: installment.receivable_id,
        orderReference: installment.order_reference,
      } as CombinableReceivable)
  )

  const finishAgglutination = ({ discount_amount, due_date, payment_methods, from }) => {
    const payload: Agglutination = {
      from,
      payment_methods,
      to: { discount_amount, due_date },
      channel: 'PI-BACKOFFICE',
    }
    setWaitingApi(true)
    disableChecks()
    api.receivables
      .agglutinate(payload)
      .then(contract => {
        setSnackbarMessage('Aglutinação realizada com sucesso')
        setSnackbarIsOpen(true)
        setContract && setContract(contract)
        onClose()

        if (callbackAgglutination) {
          callbackAgglutination(true)
        }
      })
      .catch(e => e && setApiError(e))
      .finally(() => {
        setWaitingApi(false)
        enableChecks && enableChecks()
      })
  }

  const { watch } = form
  watch(['discount'])

  const discounts = form.getValues('discount') ?? 0

  const calculateTotalAmount = () => {
    const totalAmount = combinedReceivables.reduce((accumulator, receivable) => {
      return accumulator + receivable.amount
    }, 0)
    return totalAmount - discounts
  }

  const getParameters = () => {
    const from = combinedReceivables?.map(x => x.id)
    const date = form.getValues('due_date')
    const dueDate = date ? dayjs(date).utc().set('hour', 0).set('minute', 0).set('second', 0) : null

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.AGGLUTINATE,
        entity: EventDispatcherEntities.AGGLUTINATION_FINISH,
        action: 'click',
        customProperties: {
          $name: 'Finalizar aglutinação',
          $receivables_ids: from,
          $total_amount: formatCentsToReal(calculateTotalAmount()),
          $payment_method: form.getValues('payment_method'),
          $due_date: dueDate.toISOString(),
        },
      })
    finishAgglutination({
      discount_amount: discounts,
      payment_methods: form.getValues('payment_method'),
      due_date: dueDate,
      from,
    })
  }

  const formHasErrors = Object.keys(form.formState?.errors).length > 0
  const canSubmit = combinedReceivables?.length > 1

  const description = canSubmit
    ? `Valor da nova parcela ${formatCentsToReal(baseAmount - discounts)}`
    : 'Selecione mais de uma parcela'

  return (
    <Drawer open={isOpen}>
      <ActionDrawerHeader onClose={onClose} title="Aglutinar parcelas" />

      <StyledDialogContent>
        <Box mt={4}>
          <SelectedReceivableAgglutination
            receivables={combinedReceivables}
            baseAmount={baseAmount}
          />
        </Box>

        <Divider />
        <AgglutinationDiscount
          totalAmountPaid={baseAmount}
          amountDefaultValue={0}
          form={form}
          disableForm={isProcessingAgglutination}
        />
        <Divider />
        <AgglutinationDueDate form={form} disableForm={isProcessingAgglutination} />
        <Divider />
        <AgglutinationPayment form={form} disableForm={isProcessingAgglutination} />
      </StyledDialogContent>

      <ButtonDocker description={description}>
        <Button
          variation="primary"
          fullWidth
          onClick={getParameters}
          disabled={formHasErrors || !canSubmit}
          loading={waitingApi}
        >
          Finalizar aglutinação
        </Button>
      </ButtonDocker>

      <FailureFeedbackDialog
        buttonLabel="Fechar"
        isVisible={!!apiError}
        onClose={() => setApiError(null)}
        submitHandler={() => setApiError(null)}
        centered
      >
        <FailureFeedbackContent />
      </FailureFeedbackDialog>
    </Drawer>
  )
}

export default AgglutinationDrawer
