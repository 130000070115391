import { useRouteMatch } from 'react-router-dom'
import type { ModuleDefinition } from '@/shared/core/module'

import { routes } from './routes'

import env from '@/config'

const IS_BACKOFFICE_REALM = env.SORTING_HAT.REALM === 'backoffice'

export const useRegisterAccessManagementModule: ModuleDefinition = module => {
  const { path: basePath } = useRouteMatch()

  if (!IS_BACKOFFICE_REALM) module.registerRoutes(routes, { basePath })
}
