import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import {
  formatCentsToReal,
  datePTToOnlyDateISO,
  formatCPF,
  dueDateFormatter,
} from 'src/shared/utils'
import {
  ActionDrawerHeader,
  Button,
  ButtonDocker,
  DialogContent,
  Divider,
  Drawer,
  Typography,
} from '@olaisaac/design-system'
import { sum, prop } from 'ramda'
import { SummaryProps } from './types'
import { SENT_BY } from 'src/shared/interfaces'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useToast } from '@gravity/toast'
import dayjs from 'dayjs'

const StyledSectionTitle = styled(Typography)`
  font-size: 10px;
`

const StyledInstallments = styled(Typography)`
  font-weight: bold;
  font-size: 1em;

  .title {
    font-size: 1.5em;
  }
`

const DiscountItem = styled(Typography)`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  & span {
    font-size: 15px;
  }
  font-weight: normal;
`

function ContractSummary({
  contractCreationProgress,
  envelopeTemplateReferralId,
  installments,
  discounts,
  formValues,
  isEnrollmentEnabled,
  installmentAmount,
  isLoading,
  enrollmentAmount,
  enrollmentDiscount,
  enrollmentInstallments,
  enrollmentFirstDueDay,
  firstDueDay,
  setIsEditing,
}: SummaryProps) {
  const enrollmentTotalAmount = isEnrollmentEnabled
    ? (enrollmentAmount ?? 0) * (enrollmentInstallments ?? 0)
    : 0
  const enrollmentInstallmentDiscount = isEnrollmentEnabled
    ? sum(enrollmentDiscount?.map(prop('amount')) || [0])
    : 0
  const enrollmentTotalDiscount = isEnrollmentEnabled
    ? enrollmentInstallmentDiscount * (enrollmentInstallments ?? 0)
    : 0
  const enrollmentInstallment = isEnrollmentEnabled
    ? (enrollmentAmount ?? 0) - enrollmentInstallmentDiscount
    : 0
  const enrollmentDiffTotal = isEnrollmentEnabled
    ? enrollmentTotalAmount - enrollmentTotalDiscount
    : 0

  const installmentsTotalDiscount =
    discounts?.length && sum(discounts.map(prop('amount'))) * installments
  const installmentsTotal = installmentAmount * installments || 0

  const installment = installments
    ? (installmentsTotal - installmentsTotalDiscount) / installments
    : 0

  const installmentTotal = installmentsTotal - installmentsTotalDiscount

  const tuition = isEnrollmentEnabled
    ? enrollmentTotalAmount + installmentsTotal
    : installmentsTotal
  const totalDiscount = installmentsTotalDiscount + enrollmentTotalDiscount
  const amountDiffTotal = enrollmentDiffTotal + installmentTotal
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const {
    student,
    product,
    due_day,
    enrollment_due_day,
    guardian: {
      name: guardianName,
      email: guardianEmail,
      address: guardianAddress,
      phone_number: guardianPhoneNumber,
      tax_id: guardianTaxId,
    },
  } = formValues

  const firstInstallment = firstDueDay ? datePTToOnlyDateISO(firstDueDay.utc()) : '--'
  const firstEnrollmentInstallment = enrollmentFirstDueDay
    ? datePTToOnlyDateISO(enrollmentFirstDueDay.utc())
    : '--'
  const dueDay = due_day || dueDateFormatter(firstDueDay ?? dayjs())
  const enrollmentDueDay = enrollment_due_day || dueDateFormatter(enrollmentFirstDueDay ?? dayjs())

  const { state_code, street, number, additional_information, city, zip } = guardianAddress ?? {}

  const formattedEnrollmentDiscounts = enrollmentDiscount?.length
    ? `- ${formatCentsToReal(enrollmentTotalDiscount)}`
    : `- ${formatCentsToReal(0)}`

  const hasMultipleInstallments = installments > 1
  const hasMultipleEnrollmentInstallments = (enrollmentInstallments ?? 0) > 1

  const { toast } = useToast()

  return (
    <Drawer open>
      <ActionDrawerHeader
        title="Resumo"
        subtitle="Confira abaixo se os dados do cadastro ao lado estão corretos para o contrato"
      />
      <DialogContent style={{ paddingTop: 16 }}>
        {isEnrollmentEnabled ? (
          <Box mb={4}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variation="bodySmall" color="secondary" withoutMargin>
                Total da pré-matrícula
              </Typography>
              <Typography variation="bodySmall" color="secondary" withoutMargin>
                {formatCentsToReal(enrollmentTotalAmount)}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between" my={1}>
              <DiscountItem variation="bodySmall" color="secondary" withoutMargin>
                Descontos na pré-matrícula
              </DiscountItem>
              <DiscountItem variation="bodySmall" color="secondary" withoutMargin>
                {formattedEnrollmentDiscounts}
              </DiscountItem>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <StyledInstallments variation="bodySmall" withoutMargin>
                {`${enrollmentInstallments === 1 ? 'Parcela' : 'Parcelas'} pré-matrícula`}
              </StyledInstallments>
              <StyledInstallments variation="bodySmall" withoutMargin>
                {`${enrollmentInstallments}x de ${
                  (enrollmentInstallments ?? 0) > 0
                    ? formatCentsToReal(enrollmentInstallment)
                    : formatCentsToReal(0)
                }`}
              </StyledInstallments>
            </Box>
          </Box>
        ) : null}
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variation="bodySmall" color="secondary" withoutMargin>
            Total das mensalidades
          </Typography>
          <Typography variation="bodySmall" color="secondary" withoutMargin>
            {formatCentsToReal(installmentsTotal)}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" my={1}>
          <DiscountItem variation="bodySmall" color="secondary" withoutMargin>
            Descontos na mensalidade
          </DiscountItem>
          <DiscountItem variation="bodySmall" color="secondary" withoutMargin>
            {`- ${formatCentsToReal(installmentsTotalDiscount)}`}
          </DiscountItem>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <StyledInstallments variation="bodySmall" withoutMargin>
            {installments === 1 ? 'Mensalidade' : 'Mensalidades'}
          </StyledInstallments>
          <StyledInstallments variation="bodySmall" withoutMargin>
            {`${installments}x de ${formatCentsToReal(installment)}`}
          </StyledInstallments>
        </Box>

        <Box my={4}>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variation="bodySmall" color="secondary" withoutMargin>
            Anuidade
          </Typography>
          <Typography
            id="total-value-product"
            variation="bodySmall"
            color="secondary"
            withoutMargin
          >
            {formatCentsToReal(tuition)}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" my={2}>
          <Typography variation="bodySmall" color="secondary" withoutMargin>
            Total de descontos
          </Typography>
          <Typography
            id="total-value-product"
            variation="bodySmall"
            color="secondary"
            withoutMargin
          >
            {`- ${formatCentsToReal(totalDiscount)}`}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <StyledInstallments variation="bodySmall" withoutMargin>
            Total com descontos
          </StyledInstallments>
          <StyledInstallments id="total-value-product" variation="bodySmall" withoutMargin>
            {formatCentsToReal(amountDiffTotal)}
          </StyledInstallments>
        </Box>
        {student ? (
          <>
            <Box my={4}>
              <Divider />
            </Box>
            <Box display="flex" justifyContent="space-between" flexDirection="column">
              <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
                ALUNO
              </StyledSectionTitle>
              <Typography variation="bodySmall" id="summary-student-name">
                {student?.name}
              </Typography>
              <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
                PRODUTO
              </StyledSectionTitle>
              {product && (
                <Typography variation="bodySmall" id="summary-product">
                  {product.name}
                </Typography>
              )}
            </Box>
          </>
        ) : null}
        {firstEnrollmentInstallment || firstInstallment ? (
          <>
            <Box my={4}>
              <Divider />
            </Box>
            <Box display="flex" justifyContent="space-between" flexDirection="column">
              {isEnrollmentEnabled ? (
                <>
                  <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
                    VENCIMENTO PRIMEIRA PARCELA - PRÉ-MATRÍCULA
                  </StyledSectionTitle>
                  <Typography variation="bodySmall">{firstEnrollmentInstallment}</Typography>
                  <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
                    PARCELAS
                  </StyledSectionTitle>
                  <Typography variation="bodySmall">{`${enrollmentInstallments}x`}</Typography>
                  {hasMultipleEnrollmentInstallments && (
                    <>
                      <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
                        DIA DE VENCIMENTO DAS DEMAIS PARCELAS
                      </StyledSectionTitle>
                      <Typography variation="bodySmall" id="summary-installments">
                        {enrollmentDueDay}
                      </Typography>
                    </>
                  )}
                </>
              ) : null}

              <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
                VENCIMENTO PRIMEIRA PARCELA - MENSALIDADE
              </StyledSectionTitle>
              <Typography
                variation="bodySmall"
                id="summary-installments-start_month"
                data-testid="summary-installments-start_month"
              >
                {firstInstallment}
              </Typography>
              <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
                PARCELAS
              </StyledSectionTitle>
              <Typography
                variation="bodySmall"
                id="summary-installments"
              >{`${installments}x`}</Typography>
              {hasMultipleInstallments && (
                <>
                  <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
                    DIA DE VENCIMENTO DAS DEMAIS MENSALIDADES
                  </StyledSectionTitle>
                  <Typography variation="bodySmall" id="summary-installments">
                    {dueDay}
                  </Typography>
                </>
              )}
            </Box>
          </>
        ) : null}

        {guardianName ? (
          <>
            <Box my={4}>
              <Divider />
            </Box>
            <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
              RESPONSÁVEL FINANCEIRO
            </StyledSectionTitle>
            <Typography variation="bodySmall" id="summary-guardian-name" withoutMargin>
              {guardianName}
            </Typography>
            <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
              CPF
            </StyledSectionTitle>
            <Typography variation="bodySmall" id="summary-guardian-tax_id" withoutMargin>
              {formatCPF(guardianTaxId)}
            </Typography>
            <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
              E-MAIL
            </StyledSectionTitle>
            <Typography variation="bodySmall" id="summary-guardian-email" withoutMargin>
              {guardianEmail}
            </Typography>
            <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
              CONTATO
            </StyledSectionTitle>
            <Typography variation="bodySmall" id="summary-guardian-phone_number" withoutMargin>
              {guardianPhoneNumber}
            </Typography>
            <StyledSectionTitle variation="overline" color="secondary" withoutMargin>
              ENDEREÇO
            </StyledSectionTitle>
            <Typography variation="bodySmall" id="summary-guardian-address" withoutMargin>
              {`${[street, number, additional_information, city].filter(Boolean).join(', ')}${
                state_code && `/${state_code}, `
              }${zip && `CEP ${zip}`}`}
            </Typography>
          </>
        ) : null}
        <>
          <Box my={4}>
            <Divider />
          </Box>
          {isEnrollmentEnabled && (
            <>
              <StyledSectionTitle variation="overline" color="secondary">
                Primeira cobrança
              </StyledSectionTitle>
              <Typography
                variation="bodySmall"
                id="summary-send-first-enrollment-installment-message"
              >
                {formValues.send_first_enrollment_installment_message
                  ? SENT_BY.ISAAC
                  : SENT_BY.SCHOOL}
              </Typography>
            </>
          )}
          {envelopeTemplateReferralId && (
            <>
              <StyledSectionTitle variation="overline" color="secondary">
                Envio do contrato
              </StyledSectionTitle>
              <Typography variation="bodySmall" id="summary-disable-send-signable-document">
                {formValues.disable_send_signable_document ? SENT_BY.SCHOOL : SENT_BY.ISAAC}
              </Typography>
            </>
          )}
        </>
      </DialogContent>

      <ButtonDocker description="Após salvar, o produto e as condições de pagamento do contrato não poderão ser editados">
        <Button
          type="submit"
          variation="primary"
          loading={isLoading}
          fullWidth
          onClick={() => {
            if (formValues.guardian.address.state_code === '') {
              setIsEditing(true)
              toast({
                type: 'error',
                title: 'UF não selecionado',
                description: 'Selecione um UF válido para salvar este contrato.',
              })
            }
            isInitialized &&
              eventDispatcherClient.sendEvent({
                scope: EventDispatcherEventScopes.ADD_NEW_CONTRACT_PAGE,
                name: EnrollmentEventDispatcherEvents.CLICKED,
                action: 'click',
                customProperties: { $Button_name: 'SALVAR' },
              })
          }}
        >
          Salvar
        </Button>
      </ButtonDocker>
      {isLoading && (
        <Typography
          variation="caption"
          color="secondary"
          id="summary-contract-creation-progress-caption"
          withoutMargin
          style={{ textAlign: 'center', marginTop: -16, marginBottom: 16 }}
        >
          {contractCreationProgress}
        </Typography>
      )}
    </Drawer>
  )
}

export default ContractSummary
