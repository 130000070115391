import { Card } from '@olaisaac/design-system'
import { useEffect, useRef, useState } from 'react'
import {
  ColumnContainer,
  CommentText,
  ContainerCard,
  KeyText,
  RowContainer,
  SmallValueText,
  StyledSelect,
  StyledSkeleton,
  ValueText,
} from './styles'
import { CreditSummaryProps } from './types'
import { Option } from '@gravity/select'
import { Skeleton } from '@gravity/skeleton'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import { SeparatorLine } from '../SeparatorLine'
import { Callout } from '@gravity/callout'
import { CreditType } from '@/shared/services/credit/types'

import { useReasonForChosenPolicy } from '../../hooks/useReasonForChosenPolicy'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'

const CreditSummaryCard = ({
  creditValue,
  offers,
  chosenOffer,
  setChosenOffer,
  reasonForChosenPolicyType,
}: CreditSummaryProps) => {
  const { getCalloutMessage } = useReasonForChosenPolicy()
  /**
   * //TODO: remove condition and filter after handing in the cpcr
   */

  const dropdownOptions: Option[] = offers.map(offer => ({
    label: `${
      offer.totalInstallments === 1 ? '1 parcela' : `${offer.totalInstallments} parcelas`
    } de ${offer.installmentValue}`,
    value: offer.totalInstallments.toString(),
  }))

  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const getIndexInitializeOption = () => {
    const primaryIndexCP = offers.findIndex(e => e.category === CreditType.CP)
    const optionSelected = dropdownOptions[primaryIndexCP > 0 ? primaryIndexCP : 0]
    return optionSelected
  }

  const [installment, setInstallment] = useState<Option>(getIndexInitializeOption())
  const [showSkeleton, setShowSkeleton] = useState(false)
  const isCategoryCPCR = useRef(false)

  useEffect(() => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
    const offer = offers.find(e => e.totalInstallments.toString() === installment.value)
    const isOfferCPCR = offer?.category === CreditType.CPCR
    if (offer) {
      setChosenOffer(offer)
    }
    isCategoryCPCR.current = isOfferCPCR
  }, [installment])

  const handleDropdownValueChange = (value: string) => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: CreditEventComponentNames.NUMBER_OF_INSTALLMENTS,
    })

    const installmentSelected = dropdownOptions.find(e => e?.value === value)
    if (installmentSelected) {
      setInstallment(installmentSelected)
    }
  }

  const enabledCallout = isCategoryCPCR.current

  return (
    <RowContainer>
      <Card>
        <ContainerCard>
          <KeyText>Valor simulado</KeyText>
          <ValueText>{creditValue}</ValueText>

          <SeparatorLine />

          <RowContainer>
            <KeyText>Número de parcelas</KeyText>
          </RowContainer>

          <StyledSelect
            size={3}
            variant="surface"
            options={dropdownOptions}
            value={installment.value}
            onValueChange={value => handleDropdownValueChange(value)}
          />

          {enabledCallout && (
            <Callout
              text={getCalloutMessage(reasonForChosenPolicyType)}
              href="https://centraldeajuda.olaisaac.io/credito-isaac/entenda-as-condicoes-especiais-do-credito-atrelado-a-renovacao-"
              color="gray"
            />
          )}

          <SeparatorLine />

          <RowContainer>
            <ColumnContainer>
              <KeyText>Forma de pagamento</KeyText>
              <SmallValueText>Desconto no repasse</SmallValueText>
            </ColumnContainer>
            <ColumnContainer>
              <KeyText>Comece a pagar em</KeyText>
              <SmallValueText>{chosenOffer.firstDiscountDate}</SmallValueText>
            </ColumnContainer>
          </RowContainer>

          <SeparatorLine />

          <RowContainer>
            <ColumnContainer>
              {showSkeleton ? (
                <>
                  <StyledSkeleton radius={8} fullWidth />
                  <Skeleton radius={8} fullWidth />
                </>
              ) : (
                <>
                  <KeyText>Taxa de juros mensal</KeyText>
                  <SmallValueText>{chosenOffer.monthlyFee + '% a.m'} </SmallValueText>
                </>
              )}
            </ColumnContainer>
            <ColumnContainer>
              {showSkeleton ? (
                <>
                  <StyledSkeleton radius={8} fullWidth />
                  <Skeleton radius={8} fullWidth />
                </>
              ) : (
                <>
                  <KeyText>Juros e IOF anuais (CET)</KeyText>
                  <SmallValueText>{chosenOffer.annualFee + '% a.a'} </SmallValueText>
                </>
              )}
            </ColumnContainer>
          </RowContainer>

          <SeparatorLine />

          {showSkeleton ? (
            <>
              <StyledSkeleton radius={8} fullWidth />
              <StyledSkeleton radius={8} fullWidth />
            </>
          ) : (
            <>
              <KeyText>Total a pagar</KeyText>
              <SmallValueText>{chosenOffer.totalAmount}</SmallValueText>
            </>
          )}

          <CommentText>
            O valor da parcela acima é aproximado e pode sofrer alterações pela data de desembolso.
            Condições da oferta simulada válidas para a data de hoje.
          </CommentText>
        </ContainerCard>
      </Card>
    </RowContainer>
  )
}

export default CreditSummaryCard
