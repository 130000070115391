import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { DialogPrimitives } from '@gravity/dialog'

import { useCloseCampaignMutation } from '@/modules/enrollment/hooks/queries/useCampaignQueries'
import { useToast } from '@gravity/toast'

interface Props {
  campaignId: string
  closeDialog: () => void
  hasPaymentPlan: boolean
  onFinish?: (success: boolean) => void
  visible: boolean
}

export const CloseCampaignDialog = ({
  visible,
  closeDialog,
  campaignId,
  hasPaymentPlan,
  onFinish = () => ({}),
}: Props) => {
  const { toast } = useToast()
  const finishCampaignMutation = useCloseCampaignMutation()

  const finishCampaign = async () => {
    try {
      await finishCampaignMutation.mutateAsync({
        campaignId: campaignId,
        closed_type: 'MANUALLY',
      })
      toast({
        title: 'Campanha encerrada com sucesso!',
        type: 'success',
      })
      onFinish(true)
    } catch (error) {
      toast({
        title: 'Ocorreu um erro ao cancelar a campanha',
        type: 'error',
      })
      onFinish(false)
    } finally {
      closeDialog()
    }
  }

  const handleDialogOpenChange = (open: boolean) => {
    if (!open && visible) closeDialog()
  }

  return (
    <DialogPrimitives.Root open={visible} onOpenChange={handleDialogOpenChange}>
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Content
        size={1}
        title="Encerrar campanha"
        actionButton={
          <Button
            color="error"
            variant="solid"
            loading={finishCampaignMutation.isLoading}
            onClick={finishCampaign}
          >
            Encerrar
          </Button>
        }
        cancelButton={
          <Button variant="ghost" onClick={closeDialog}>
            Cancelar
          </Button>
        }
      >
        <div>
          {hasPaymentPlan ? (
            <>
              <Text variant="body-2-regular">
                Após o encerramento da campanha de matrícula, os responsáveis não poderão demonstrar
                interesse nem acessar as condições através do meu isaac.
              </Text>
              <br />
              <br />
              <Text variant="body-2-regular">
                Parcelas de pré-matrícula já pagas não serão canceladas automaticamente.
              </Text>
            </>
          ) : (
            <Text variant="body-2-regular">
              Após o encerramento da campanha de matrícula, os responsáveis serão notificados por
              e-mail e não poderão demonstrar interesse através do meu isaac.
            </Text>
          )}
        </div>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )
}
