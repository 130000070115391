import { FC } from 'react'
import { Typography } from '@olaisaac/design-system'

import type { InstallmentCardProps } from '@/modules/guardians/Negotiation/types'

import * as S from './styles'

const InstallmentCard: FC<InstallmentCardProps> = ({
  productName,
  installmentsLabel,
  installmentsPrice,
  total,
  finesAndInterest,
  discount,
  hideDiscount,
}) => {
  const discountLabel = (function () {
    return 'Descontos'
  })()

  return (
    <S.Wrapper>
      <S.MenuBookIcon />

      <S.Content>
        <Typography withoutMargin variation="subtitleDesktopMedium" style={{ marginBottom: 26 }}>
          {productName}
        </Typography>
        <S.PriceRow>
          <S.Label>{installmentsLabel}</S.Label>
          <Typography withoutMargin color="secondary" variation="bodySmall">
            {installmentsPrice}
          </Typography>
        </S.PriceRow>

        <S.PriceRow>
          <S.Label>Encargos</S.Label>
          <Typography withoutMargin color="secondary" variation="bodySmall">
            {finesAndInterest}
          </Typography>
        </S.PriceRow>

        {!hideDiscount && (
          <S.PriceRow>
            <S.Label>{discountLabel}</S.Label>
            <Typography withoutMargin color="secondary" variation="bodySmall">
              {discount}
            </Typography>
          </S.PriceRow>
        )}

        <S.PriceRow>
          <span />
          <Typography withoutMargin variation="bodySmall" style={{ fontWeight: 500 }}>
            {total}
          </Typography>
        </S.PriceRow>
      </S.Content>
    </S.Wrapper>
  )
}

export default InstallmentCard
