import { Table } from '@gravity/table'

export const NotificationsPlaceholder = () => (
  <>
    {[0, 1, 2, 3, 4].map(row => (
      <Table.Row key={row}>
        <Table.LoadingCell />
        <Table.LoadingCell />
        <Table.LoadingCell />
        <Table.LoadingCell />
        <Table.LoadingCell />
      </Table.Row>
    ))}
  </>
)
