import { FC } from 'react'
import { Button, Notification, NotificationProps } from '@olaisaac/design-system'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { Footer } from '@/modules/guardians/Negotiation/components/Footer'
import { AgreementInvoice, SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { InvoiceStatus } from './components/PaymentRow/types'
import PaymentRow from './components/PaymentRow'
import type { AgreementConclusionStepProps } from './types'
import * as S from './styles'
import { useSendCheckoutEvent } from '../../hooks/useSendCheckoutEvents'
import { HelpButton } from '../../components/HelpButton'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { useGuardianNavigation } from '@/modules/guardians/hooks/useGuardianNavigation'
import { PaymentTable } from './components/PaymentTable'

const AgreementConclusionStep: FC<AgreementConclusionStepProps> = ({
  id,
  goToPayAgreementStep,
  isNegotiationDownPayment,
}) => {
  const history = useHistory()
  const sendCheckoutEvent = useSendCheckoutEvent()
  const { pageToReturn } = useGuardianNavigation()

  const {
    agreementSimulations,
    agreementStatus,
    positionAgreement,
    setPositionAgreement,
  } = useAgreement()

  const currentAgreementStatus = agreementStatus[positionAgreement]

  const nextAgreementIndex = positionAgreement + 1
  const nextAgreement = agreementSimulations?.[nextAgreementIndex]

  const isNegotiationWithoutPOS =
    isNegotiationDownPayment &&
    !agreementStatus?.[positionAgreement].invoices.find(
      invoice => invoice.payment_method === SimulationPaymentMethod.POS
    )

  function handleFinishBtnClick() {
    if (nextAgreement) {
      setPositionAgreement(nextAgreementIndex)
      goToPayAgreementStep()
    } else {
      sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_LAST_STEP, {
        name: 'Concluir negociação',
      })

      history.push(pageToReturn)
    }
  }

  const getFinishBtnLabel = () => {
    if (!nextAgreement) return 'Concluir negociação'
    return `Ir para ${nextAgreementIndex + 1}º pagamento`
  }

  function getNotificationProps(): NotificationProps {
    const firstInvoicePaymentMethod = currentAgreementStatus.invoices?.[0]?.payment_method

    switch (firstInvoicePaymentMethod) {
      case SimulationPaymentMethod.CREDIT_CARD:
        return {
          title: 'Aguardando pagamento',
          description:
            'Copie e envie o link para que o responsável financeiro consiga fazer o pagamento pelo cartão',
          variation: 'warning',
        }

      case SimulationPaymentMethod.BANK_SLIP:
      case SimulationPaymentMethod.PIX:
        return {
          title: 'Aguardando pagamento do boleto de entrada',
          description:
            'Caso tenha recebido em dinheiro ou cheque alguma da(s) parcela(s), não esqueça de registrar o recebimento',
          variation: 'warning',
        }

      case SimulationPaymentMethod.POS:
        return {
          description: 'Pagamento realizado com sucesso!',
          variation: 'success',
        }
    }
  }

  function getInstallmentLabel(index: number) {
    const totalOfInvoices = currentAgreementStatus.invoices.length
    if (totalOfInvoices === 1) return 'Parcela 1 de 1'

    if (index === 0) {
      return 'Entrada'
    }

    return `Parcela ${index} de ${totalOfInvoices - 1}`
  }

  function getFormOfPaymentLabel(invoice: AgreementInvoice) {
    switch (invoice.payment_method) {
      case SimulationPaymentMethod.BANK_SLIP:
      case SimulationPaymentMethod.PIX:
        return 'Boleto ou pix'

      case SimulationPaymentMethod.POS:
        return invoice.instalment_credit_card > 1
          ? `${invoice.instalment_credit_card}x na maquininha`
          : 'À vista na maquininha'

      case SimulationPaymentMethod.CREDIT_CARD:
        return invoice.instalment_credit_card > 1
          ? `${invoice.instalment_credit_card}x no cartão`
          : 'À vista no cartão'
    }
  }
  const sendGenerateReceiptEvent = () => {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_SUCCESSFUL_TRANSACTION_GENERATE_RECEIPT, {
      name: 'Gerar recibo',
      location: id,
    })
  }
  return (
    <S.Wrapper large={isNegotiationDownPayment}>
      <Notification {...getNotificationProps()} />
      {!isNegotiationWithoutPOS && (
        <div>
          {currentAgreementStatus.invoices.map((invoice, index) => (
            <PaymentRow
              installmentLabel={getInstallmentLabel(index)}
              formOfPaymentLabel={getFormOfPaymentLabel(invoice)}
              key={invoice.id}
              idForReceipt={invoice.receivable_id}
              amount={invoice.amount}
              paymentMethod={invoice.payment_method}
              due_date={invoice.due_date}
              paymentLink={invoice.payment_link}
              invoiceStatus={
                invoice.payment_method === SimulationPaymentMethod.POS
                  ? InvoiceStatus.PAID
                  : InvoiceStatus.AWAITING_PAYMENT
              }
              onSuccess={sendGenerateReceiptEvent}
            />
          ))}
        </div>
      )}
      {isNegotiationWithoutPOS && (
        <PaymentTable agreementStatus={agreementStatus?.[positionAgreement]} />
      )}
      <Footer
        button={
          <Box width="100%" display="flex">
            <HelpButton eventLocationName={id} />
          </Box>
        }
      >
        <Button variation="primary" onClick={handleFinishBtnClick}>
          {getFinishBtnLabel()}
        </Button>
      </Footer>
    </S.Wrapper>
  )
}

export default AgreementConclusionStep
