import { spacing } from '@gravity/tokens'
import styled from 'styled-components'

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[3]};
`

export const FooterRightContainer = styled(ColumnContainer)`
  flex: 1;
  height: 556px;
  margin-left: ${spacing[10]};
  margin-top: ${spacing[7]};
  gap: ${spacing[2]};
`

export const ResponsiveContainer = styled.div`
  @media screen and (min-width: 1440px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1216px;
  }
`
