import { FormOfPayment } from '@/modules/guardians/models/agreement'

export const formOfPaymentTextMap: Record<
  FormOfPayment,
  { subtitle: string; subtitleWithFee?: string; title: string }
> = {
  [FormOfPayment.INSTALLMENTS]: {
    title: 'Parcelado no cartão de crédito ou boleto',
    subtitle: 'Cartão de crédito ou boleto com entrada',
    subtitleWithFee: 'Cartão de crédito <strong>(com taxa)</strong> ou boleto com entrada',
  },
  [FormOfPayment.ONE_LUMP_SUM]: {
    title: 'À vista Boleto/Pix ou cartão de crédito via link',
    subtitle: 'Pagamento em Boleto/PIX ou cartão de crédito via link de pagamento',
    subtitleWithFee:
      'Pagamento em Boleto/PIX ou cartão de crédito <strong>(com taxa)</strong> via link de pagamento',
  },
  [FormOfPayment.POS]: {
    title: 'À vista no cartão com a maquininha',
    subtitle: 'Pagamento em cartão de crédito ou débito presencial na escola',
    subtitleWithFee:
      'Pagamento em cartão de crédito <strong>(com taxa)</strong> ou débito presencial na escola',
  },
  [FormOfPayment.POS_V2]: {
    title: 'Pagar valor total à vista na maquininha',
    subtitle: 'Cartão de crédito ou débito presencial na escola',
    subtitleWithFee: 'Cartão de crédito <strong>(com taxa)</strong> ou débito presencial na escola',
  },
  [FormOfPayment.OTHER]: {
    title: 'Ver outras opções de pagamento disponíveis',
    subtitle: 'Boleto/pix, cartão de crédito e opções de parcelamento, se houver',
    subtitleWithFee:
      'Boleto/pix, cartão de crédito <strong>(com taxa)</strong> e opções de parcelamento, se houver',
  },
}
