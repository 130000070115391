import { usePersistState, PersistedStatePrefix } from '@/shared/hooks/usePersistState'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { HOUR_IN_MILLIS, MINUTE_IN_MILLIS } from '@/shared/utils'
import { createContext, Dispatch, ReactNode, SetStateAction, useContext } from 'react'
import {
  Eligibility,
  CreditReason,
  AnticipationOffer,
  CreateAnticipationSimulationRequest,
  AnticipationSimulation,
} from '@/shared/services/credit/types'

export type PayoutAnticipationContext = {
  chosenOffer: AnticipationOffer
  eligibility: Eligibility
  note: string
  reason: CreditReason
  requestSimulation: CreateAnticipationSimulationRequest
  requestValue: string
  responseSimulation: AnticipationSimulation
  setChosenOffer: Dispatch<SetStateAction<AnticipationOffer>>
  setEligibility: Dispatch<SetStateAction<Eligibility>>
  setNote: Dispatch<SetStateAction<string>>
  setReason: Dispatch<SetStateAction<CreditReason>>
  setRequestSimulation: Dispatch<SetStateAction<CreateAnticipationSimulationRequest>>
  setRequestValue: Dispatch<SetStateAction<string>>
  setResponseSimulation: Dispatch<SetStateAction<AnticipationSimulation>>
}

export const PayoutAnticipationContext = createContext<PayoutAnticipationContext>(null)

type PayoutAnticipationProviderProps = { children: ReactNode }

export const PayoutAnticipationProvider = ({ children }: PayoutAnticipationProviderProps) => {
  const { schoolSlug } = useSelectedSchool()

  const [eligibility, setEligibility] = usePersistState<Eligibility>(
    `${PersistedStatePrefix.PayoutAnticipationSimulation}:${schoolSlug}:eligibility`,
    null,
    { TTL_ms: HOUR_IN_MILLIS }
  )
  const [
    requestSimulation,
    setRequestSimulation,
  ] = usePersistState<CreateAnticipationSimulationRequest>(
    `${PersistedStatePrefix.PayoutAnticipationSimulation}:${schoolSlug}:simulation-request`,
    null,
    { TTL_ms: MINUTE_IN_MILLIS }
  )
  const [responseSimulation, setResponseSimulation] = usePersistState<AnticipationSimulation>(
    `${PersistedStatePrefix.PayoutAnticipationSimulation}:${schoolSlug}:simulation-response`,
    null,
    { TTL_ms: HOUR_IN_MILLIS }
  )

  // Simulation context
  const [requestValue, setRequestValue] = usePersistState<string>(
    `${PersistedStatePrefix.PayoutAnticipationSimulation}:${schoolSlug}:simulation-value`,
    '0,00',
    { TTL_ms: HOUR_IN_MILLIS }
  )
  const [reason, setReason] = usePersistState<CreditReason>(
    `${PersistedStatePrefix.PayoutAnticipationSimulation}:${schoolSlug}:simulation-reason`,
    null,
    { TTL_ms: HOUR_IN_MILLIS }
  )
  const [note, setNote] = usePersistState<string>(
    `${PersistedStatePrefix.PayoutAnticipationSimulation}:${schoolSlug}:simulation-reason-note`,
    '',
    { TTL_ms: HOUR_IN_MILLIS }
  )
  const [chosenOffer, setChosenOffer] = usePersistState<AnticipationOffer>(
    `${PersistedStatePrefix.PayoutAnticipationSimulation}:${schoolSlug}:anticipation-chosen-offer`,
    null,
    { TTL_ms: MINUTE_IN_MILLIS }
  )

  return (
    <PayoutAnticipationContext.Provider
      value={{
        requestValue,
        note,
        reason,
        eligibility,
        setEligibility,
        responseSimulation,
        chosenOffer,
        setChosenOffer,
        requestSimulation,
        setRequestValue,
        setNote,
        setReason,
        setRequestSimulation,
        setResponseSimulation,
      }}
    >
      {children}
    </PayoutAnticipationContext.Provider>
  )
}

export const useContextEligibility = () => {
  const { eligibility, setEligibility } = useContext(PayoutAnticipationContext)
  return { eligibility, setEligibility }
}

export const useContextSimulation = () => {
  const { note, setNote, reason, setReason, requestValue, setRequestValue } = useContext(
    PayoutAnticipationContext
  )
  return { note, setNote, reason, setReason, requestValue, setRequestValue }
}

export const useContextRequestSimulation = () => {
  const { requestSimulation, setRequestSimulation } = useContext(PayoutAnticipationContext)
  return { requestSimulation, setRequestSimulation }
}

export const useContextResponseSimulation = () => {
  const { responseSimulation, setResponseSimulation } = useContext(PayoutAnticipationContext)
  return { responseSimulation, setResponseSimulation }
}

export const useContextChosenOffer = () => {
  const { chosenOffer, setChosenOffer } = useContext(PayoutAnticipationContext)
  return { chosenOffer, setChosenOffer }
}
