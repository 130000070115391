import { PayoutTransfersSection } from '../PayoutTransfersSection'

import type { PayoutTransfersSectionGroupProps } from './types'

/**
 * Component to display a transfers section group
 *
 * @param props
 * @param props.title Text to be displayed as group title
 * @param props.tooltip Text to be displayed as group tooltip
 * @param props.categories Group categories
 * @param props.description Optional section description
 */
export const PayoutTransfersSectionGroup = ({
  categoryTitle,
  categoryTooltip,
  categories,
  description,
}: PayoutTransfersSectionGroupProps) => {
  if (categories.length === 0) return <></>

  return (
    <>
      <PayoutTransfersSection.CategoryTitle tooltip={categoryTooltip} description={description}>
        {categoryTitle}
      </PayoutTransfersSection.CategoryTitle>

      {categories.map(category => (
        <PayoutTransfersSection.SubCategory
          key={category.key}
          title={category.label}
          tooltip={category.tooltip}
          total={category.total}
          secondaryInfo={category.secondaryInfo}
          items={category.items}
        />
      ))}

      <PayoutTransfersSection.Divider />
    </>
  )
}
