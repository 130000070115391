import { Button } from '@olaisaac/design-system'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useBlockNavigation } from '@/shared/hooks/useBlockNavigation'
import { ConfirmQuitDialog } from '../ConfirmQuitDialog'
import { useEvents } from '../../hooks/eventContext'

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 4rem;
  right: 0;
  border-top: 1px solid;
  border-color: ${props => props.theme.primitiveTokens.colors.gray[10]};
  padding: 24px 80px;
  display: flex;
  justify-content: end;
  gap: 24px;
  background-color: ${props => props.theme.primitiveTokens.colors.gray[0]};
`

export const PageFooter = ({
  hasUnsavedChanges,
  isSubmitting,
  isNotificationOpen,
  handleSubmit,
}: {
  handleSubmit: () => void
  hasUnsavedChanges: boolean
  isNotificationOpen: boolean
  isSubmitting: boolean
}) => {
  const events = useEvents()
  const [isConfirmQuitDialogOpen, setIsConfirmQuitDialogOpen] = useState(false)
  const history = useHistory()

  const { userIsLeaving, unblock, destinationPath } = useBlockNavigation()

  const handleConfirmLeaving = () => {
    unblock()

    userIsLeaving ? history.push(destinationPath) : history.goBack()
  }

  useEffect(() => {
    if (!userIsLeaving) {
      return
    }

    if (hasUnsavedChanges) {
      setIsConfirmQuitDialogOpen(true)
    } else {
      handleConfirmLeaving()
    }
  }, [userIsLeaving])

  return (
    <>
      <Footer>
        <Button
          variation="ghost"
          onClick={() => {
            events.cancelEditContract()

            if (!hasUnsavedChanges) {
              history.goBack()
              return
            }
            setIsConfirmQuitDialogOpen(true)
          }}
        >
          Cancelar edição
        </Button>
        <Button
          onClick={() => {
            events.saveEdits()
            handleSubmit()
          }}
          loading={isSubmitting}
          disabled={isNotificationOpen || !hasUnsavedChanges}
          style={{ minWidth: 160 }}
        >
          Salvar edições
        </Button>
      </Footer>
      <ConfirmQuitDialog
        open={isConfirmQuitDialogOpen}
        onConfirm={handleConfirmLeaving}
        onClose={() => {
          setIsConfirmQuitDialogOpen(false)
          unblock()
        }}
      />
    </>
  )
}
