import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Skeleton } from '@gravity/skeleton'

import { Info, InfoPlaceholder } from '../Info'

import type { NumericInfoProps } from './types'

import { Container } from './styles'

export const FRACTION_DIGITS_NUMBER = 2

export const NumericInfo = (props: NumericInfoProps) => {
  const { value, isCurrency = false, currencyLocale = 'pt-BR', ...remainingProps } = props

  const isNegative = value < 0

  const formattedValue = isCurrency
    ? new Intl.NumberFormat(currencyLocale, {
        minimumFractionDigits: FRACTION_DIGITS_NUMBER,
        maximumFractionDigits: FRACTION_DIGITS_NUMBER,
      }).format(Math.abs(value))
    : value

  const currency = isNegative ? '-' : ''

  return (
    <Info {...remainingProps}>
      <Container>
        {isCurrency && (
          <Text variant="body-1-regular" className="currency content">
            {currency + ' R$'}
          </Text>
        )}
        <Heading variant="heading-h2-medium" className="content">
          {String(formattedValue)}
        </Heading>
      </Container>
    </Info>
  )
}

export const NumericInfoPlaceholder = () => (
  <InfoPlaceholder>
    <Skeleton variant="neutral" fullWidth />
  </InfoPlaceholder>
)
