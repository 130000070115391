import { createContext, Dispatch, SetStateAction, useState } from 'react'

export type PagedDrawerContext = {
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
}

export const PagedDrawerContext = createContext<PagedDrawerContext>(null)

type PagedDrawerProviderProps = { children: React.ReactNode }

export const PagedDrawerProvider = ({ children }: PagedDrawerProviderProps) => {
  const [currentPage, setCurrentPage] = useState(0)
  return (
    <PagedDrawerContext.Provider value={{ currentPage, setCurrentPage }}>
      {children}
    </PagedDrawerContext.Provider>
  )
}
