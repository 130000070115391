import { Skeleton } from '@gravity/skeleton'
import { CancelModalVariants } from '.'
import { Text } from '@gravity/text'
import styled from 'styled-components'

type CancelContractModalDescriptionProps = {
  variant: CancelModalVariants
}

const SkeletonWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`

export const CancelContractModalDescription = ({
  variant,
}: CancelContractModalDescriptionProps) => {
  if (variant === 'loading') {
    return (
      <SkeletonWrapper>
        <Skeleton fullWidth height={24} />
        <Skeleton fullWidth height={24} />
      </SkeletonWrapper>
    )
  }

  if (variant === 'non-cancellable') {
    return null
  }

  if (variant === 'overdue-act' || variant === 'overdue-acknowledge') {
    return null
  }

  if (variant === 'reason') {
    return (
      <Text>
        Essa ação é irreversível e pode alterar o repasse. Ao cancelar o contrato, ele ficará
        inativo e suas parcelas serão removidas dos próximos repasses.
      </Text>
    )
  }
}
