import styled from 'styled-components'

export const SearchContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2.5rem;
`

export const CalloutContainer = styled.div`
  margin-top: 2.5rem;
`
