import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

import { spacing, fontWeight, fontSize, lineHeight, letterSpacing } from '@gravity/tokens'

export const RowContainer = styled.div`
  max-width: 1366px;
  @media screen and (min-width: 1440px) {
    margin-left: auto;
    margin-right: auto;
  }
`

export const TitleText = styled(Typography)`
  font-size: ${fontSize['2xl']};
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.normal};
  letter-spacing: ${letterSpacing.normal};
  margin-bottom: ${spacing[4]};
  margin-top: ${spacing[6]};
`

export const Text = styled(Typography)`
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.normal};
  line-height: ${lineHeight.normal};
  letter-spacing: ${letterSpacing.normal};
`
