import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks/useApi'

import { CreditType, Eligibility } from '@/shared/services/credit/types'

export const creditEligibilityQueryKey = 'anticipation-elegibility'

export const useAnticipationEligibility = (
  schoolId: string,
  options?: UseQueryOptions<Eligibility>
) => {
  const { api } = useApi()

  return useQuery<Eligibility>(
    [creditEligibilityQueryKey, schoolId],
    async () => {
      const { data } = await api.credit.getSchoolEligibility(schoolId, CreditType.AR)
      return data
    },
    options
  )
}
