import { useMemo } from 'react'

import { useNavigation } from '@/escolas/hooks'
import { useSchoolEnrollmentCycleContext } from '@/modules/enrollment/contexts/SchoolEnrollmentContext'
import {
  INITIAL_SUPPORTED_CYCLE_YEAR,
  LEGACY_CYCLE_YEARS,
} from '@/modules/enrollment/constants/enrollment'

export const useSelectYear = () => {
  const { navigateTo } = useNavigation()
  const {
    schoolEnrollmentCycles,
    updateSelectedEnrollmentCycle,
    selectedEnrollmentCycle,
  } = useSchoolEnrollmentCycleContext()

  const selectedYear = selectedEnrollmentCycle?.reference_year ?? 0

  const years = useMemo(() => {
    const yearsSet = new Set(LEGACY_CYCLE_YEARS)

    if (schoolEnrollmentCycles) {
      schoolEnrollmentCycles.map(enrollmentCycle => yearsSet.add(enrollmentCycle.reference_year))
    }

    return Array.from(yearsSet).sort((a, b) => b - a)
  }, [schoolEnrollmentCycles])

  const handleChangeSelectedYear = (year: number) => {
    if (year < INITIAL_SUPPORTED_CYCLE_YEAR) {
      return navigateTo({ path: `/contratos/${year}` })
    }

    const selectedEnrollment = schoolEnrollmentCycles.find(
      schoolEnrollmentCycle => schoolEnrollmentCycle.reference_year === year
    )
    updateSelectedEnrollmentCycle(selectedEnrollment)
  }

  return { handleChangeSelectedYear, selectedYear, years }
}
