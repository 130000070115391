import { FC } from 'react'

import { StyledSpan } from './styles'

type StatusBadgeColor = 'success' | 'error' | 'primary' | 'warning' | 'grey'

type StatusBadgeProps = {
  color: StatusBadgeColor
}

export const StatusBadge: FC<StatusBadgeProps> = ({ color }) => (
  <StyledSpan className={color} data-testid="status-badge" />
)
