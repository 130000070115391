import styled from 'styled-components'

export const NotificationHeading = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gravity.spacing[6]};
  margin-bottom: ${props => props.theme.gravity.spacing[6]};

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.gravity.colors['colors-interaction-secondary-2']};
    padding: ${props => props.theme.gravity.spacing[2]};
    border-radius: 50%;
  }

  .metadata {
    display: flex;
    flex-direction: column;

    .notification-date {
      color: ${props => props.theme.gravity.colors['colors-text-main-2']};
    }
  }
`

export const RichTextContainer = styled.div`
  p,
  div,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.gravity.colors['colors-text-main-3']};
    line-height: 1.5;
  }

  p + *,
  div + * {
    margin-top: ${props => props.theme.gravity.spacing[4]};
  }

  ol,
  ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin: ${props => `${props.theme.gravity.spacing[6]} 0 0 ${props.theme.gravity.spacing[3]}`};
  }

  li {
    list-style-type: disc;
    margin-left: ${props => props.theme.gravity.spacing[5]};
  }

  b {
    font-weight: 500;
  }

  a {
    color: ${props => props.theme.gravity.colors['colors-background-accent-1']};
  }
`
