import { FC } from 'react'
import { DialogTitle, DialogContent, DialogActions, Typography } from '@olaisaac/design-system'
import { Button } from '@gravity/button'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import * as Styled from './styles'
import { SelectedInstallmentsText } from '../SelectedInstallmentsText'
import { Installment } from '@/modules/contract/services/types'
import { InstallmentStatuses, InstallmentType } from '@/shared/interfaces/installment'
import { formatCentsToReal } from '@/shared/utils/numberFormatters'
import { calculateTotalDiscount, canCancelInstallment } from '../../utils'

type CancellingDialogProps = {
  installments: Installment[]
  onClose: () => void
  onConfirm: (selectedInstallmentIds: string[]) => void
  selectedInstallments: Installment[]
}

enum statusesOrTypeCases {
  // If it is needed to add more, please add if it has one or more type and status i.e.: OPEN_PAID_OVERDUE, ISAACPAY_OPEN_OVERDUE
  ENROLLMENT_ONLY_PAID = 'ENROLLMENT_ONLY_PAID',
  ENROLLMENT_OVERDUE_PAID = 'ENROLLMENT_OVERDUE_PAID',
  ONLY_OPEN = 'ONLY_OPEN',
  ONLY_OVERDUE = 'ONLY_OVERDUE',
  ONLY_PAID = 'ONLY_PAID',
  OVERDUE_PAID = 'OVERDUE_PAID',
  TYPE_ISAACPAY = 'TYPE_ISAACPAY',
}

const checkSelectedStatusesOrType = (selectedInstallments: Installment[]): statusesOrTypeCases => {
  if (
    selectedInstallments.every(
      (installment: Installment) => installment.receivable_type === InstallmentType.TUITION
    )
  ) {
    if (
      selectedInstallments.every(
        (installment: Installment) =>
          installment.status === InstallmentStatuses.PAID &&
          installment.receivable_type === InstallmentType.TUITION
      )
    )
      return statusesOrTypeCases.ONLY_PAID
    if (
      selectedInstallments.every(
        installment =>
          installment.status === InstallmentStatuses.OPEN &&
          installment.receivable_type === InstallmentType.TUITION
      )
    )
      return statusesOrTypeCases.ONLY_OPEN
    if (
      selectedInstallments.every(
        installment =>
          installment.status === InstallmentStatuses.OVERDUE &&
          installment.receivable_type === InstallmentType.TUITION
      )
    )
      return statusesOrTypeCases.ONLY_OVERDUE
  }

  if (
    selectedInstallments.some(
      (installment: Installment) =>
        installment.status === InstallmentStatuses.OVERDUE &&
        installment.receivable_type === InstallmentType.TUITION
    ) &&
    selectedInstallments.some(
      (installment: Installment) =>
        installment.status === InstallmentStatuses.OPEN &&
        installment.receivable_type === InstallmentType.TUITION
    )
  )
    return statusesOrTypeCases.OVERDUE_PAID

  if (
    selectedInstallments.some(
      installment => installment.receivable_type === InstallmentType.ISAACPAY
    )
  ) {
    return statusesOrTypeCases.TYPE_ISAACPAY
  }

  if (
    selectedInstallments.every(
      installment => installment.receivable_type === InstallmentType.ENROLLMENT
    )
  ) {
    if (
      selectedInstallments.some(
        installment => installment.status === InstallmentStatuses.OVERDUE
      ) &&
      selectedInstallments.some(installment => installment.status === InstallmentStatuses.PAID)
    )
      return statusesOrTypeCases.ENROLLMENT_OVERDUE_PAID
    if (selectedInstallments.some(installment => installment.status === InstallmentStatuses.PAID))
      return statusesOrTypeCases.ENROLLMENT_ONLY_PAID
  }
}

type MessageToDisplayProps = {
  selectedInstallments: Installment[]
}

const MessageToDisplay = ({ selectedInstallments }: MessageToDisplayProps) => {
  const isMultipleInstallments = selectedInstallments.length > 1

  const sumFineAndInterest = selectedInstallments.reduce((acc, installment) => {
    if (installment.status === InstallmentStatuses.OVERDUE) {
      acc += Number(installment.current_fine) + Number(installment.current_interest)
    }
    return acc
  }, 0)

  const sumSelectedInstallments = selectedInstallments.reduce((acc, installment) => {
    const discountsAmount = calculateTotalDiscount(installment.discounts)

    if (installment.status === InstallmentStatuses.OVERDUE) {
      acc += Number(installment.amount) - discountsAmount
    }
    return acc
  }, 0)

  const totalAmountToBeDiscounted = sumSelectedInstallments + sumFineAndInterest

  const openInstallmentMessage = (
    <Styled.List>
      <ul>
        <li>
          <Typography>
            O valor {isMultipleInstallments ? `das parcelas` : `da parcela`} será zerado;
          </Typography>
        </li>
        <li>
          <Typography>
            {isMultipleInstallments
              ? `Os responsáveis financeiros não receberão`
              : `O responsável financeiro não receberá`}{' '}
            nenhuma cobrança relacionada à parcela.
          </Typography>
        </li>
      </ul>
    </Styled.List>
  )

  const overdueInstallmentMessage = (
    <>
      <li>
        <Typography>
          Valor das mensalidades: {formatCentsToReal(sumSelectedInstallments)}
        </Typography>
      </li>
      <li>
        <Typography>
          Valores de multa e juros em atraso: {formatCentsToReal(sumFineAndInterest)}
        </Typography>
      </li>
    </>
  )

  const selectedStatuses = checkSelectedStatusesOrType(selectedInstallments)

  switch (selectedStatuses) {
    case statusesOrTypeCases.TYPE_ISAACPAY:
      return openInstallmentMessage
    case statusesOrTypeCases.ONLY_OPEN:
      return openInstallmentMessage
    case statusesOrTypeCases.ONLY_OVERDUE:
      return (
        <>
          <Typography style={{ marginBottom: '24px' }}>
            Ao cancelar essa parcela vencida, será descontado no próximo repasse:
          </Typography>
          <Styled.List>
            <ul>
              {overdueInstallmentMessage}
              <Typography>
                <b>Valor total a ser descontado: {formatCentsToReal(totalAmountToBeDiscounted)}</b>
              </Typography>
            </ul>
          </Styled.List>
        </>
      )
    case statusesOrTypeCases.OVERDUE_PAID:
      return (
        <>
          <Typography>
            Ao cancelar essas parcelas vencidas, será deduzido o{' '}
            <b>valor de {formatCentsToReal(totalAmountToBeDiscounted)}</b> no próximo repasse. Este
            montante é composto por:
          </Typography>
          <Styled.List>
            <ul>{overdueInstallmentMessage}</ul>
          </Styled.List>
          <Typography>Para as parcelas a vencer:</Typography>
          {openInstallmentMessage}
        </>
      )
    case statusesOrTypeCases.ENROLLMENT_ONLY_PAID:
      return (
        <>
          <Typography style={{ marginBottom: '24px' }}>
            <span style={{ color: 'red' }}>Atenção:</span> esta parcela já foi paga e o estorno do
            pagamento para o responsável deverá ser feito pela escola.
          </Typography>
        </>
      )
    case statusesOrTypeCases.ENROLLMENT_OVERDUE_PAID:
      return (
        <>
          <Typography style={{ marginBottom: '20px' }}>
            Ao cancelar parcelas(s) paga(s): O estorno do pagamento para o responsável deverá ser
            feito pela escola.
          </Typography>
          <Typography>Ao cancelar parcela(s) vencida(s):</Typography>
          <Styled.List>
            <ul>
              <li>
                <Typography>O valor das parcelas será zerado;</Typography>
              </li>
              <li>
                <Typography>
                  O responsáveis financeiros não receberão nenhuma cobrança relacionada à parcela.
                </Typography>
              </li>
            </ul>
          </Styled.List>
        </>
      )
    default:
      return openInstallmentMessage
  }
}

export const CancellingDialog: FC<CancellingDialogProps> = ({
  installments,
  onClose,
  selectedInstallments,
  onConfirm,
}) => {
  const isEveryInstallmentSelectedAndOverdue = () => {
    const cancellableInstallmentIDs = installments
      .filter(i => canCancelInstallment(i))
      .map(i => i.backoffice_installment_id)
    const selectedEveryCancellableInstallment = cancellableInstallmentIDs.every((i: uuid) =>
      selectedInstallments.map(instalmment => instalmment.backoffice_installment_id).includes(i)
    )

    return (
      selectedEveryCancellableInstallment &&
      selectedInstallments.every(instalmment => instalmment.status === InstallmentStatuses.OVERDUE)
    )
  }
  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box>Cancelar parcelas</Box>
        <SelectedInstallmentsText count={selectedInstallments.length} />
      </DialogTitle>
      <DialogContent>
        <MessageToDisplay selectedInstallments={selectedInstallments} />
        <Typography style={{ marginBottom: '24px' }}>
          Você só pode reverter o cancelamento da parcela antes de salvar a edição do contrato.
          Depois disso, é uma ação irreversível.
        </Typography>
        {isEveryInstallmentSelectedAndOverdue() && (
          <Typography variation="subtitleDesktopSmall">
            O cancelamento das parcelas não altera o status do contrato: ele permanecerá ativo.
          </Typography>
        )}
      </DialogContent>
      <DialogActions className="centered">
        <Button variant="ghost" onClick={onClose}>
          Voltar
        </Button>
        <Button
          variant="solid"
          color="error"
          onClick={() =>
            onConfirm(
              selectedInstallments.map(instalmment => instalmment.backoffice_installment_id)
            )
          }
        >
          Continuar cancelamento
        </Button>
      </DialogActions>
    </Dialog>
  )
}
