import React from 'react'
import CancelContractMenuItem from './CancelContractMenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import { SystemButton } from '@olaisaac/design-system'
import { useJWT } from 'src/shared/hooks'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import useInstallments from 'src/escolas/hooks/useInstallments'
import { InstallmentStatuses } from 'src/shared/interfaces'
import { HotjarEvents, useHotjar } from 'src/shared/hooks/useHotjar'

export type CardMenuProps = {
  isProcessingAgglutination?: boolean
  openCancellationDrawer: () => void
}
export const CardMenu = ({ isProcessingAgglutination, openCancellationDrawer }: CardMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { isAdmin } = useJWT()
  const { processedInstallments } = useInstallments()
  const { sendHotjarEvent } = useHotjar()

  const isRevokedCancelContract = useUnleashFlag(UnleashFlags.B2BCOR_193_BLOCK_CONTRACT_REVOKE)

  /**
   * Caso o usuário não seja admin mas a escola tem permissão para cancelar contratos.
   */
  const isCancelContractEnabled = !isAdmin && !isRevokedCancelContract

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    sendHotjarEvent(HotjarEvents.EDIT_GUARDIAN)
    setAnchorEl(null)
  }

  const openInstallments = processedInstallments?.filter(
    installment => installment.status !== InstallmentStatuses.CANCELED
  )

  const hasOpenInstallments = openInstallments?.length > 0

  return (
    isCancelContractEnabled && (
      <>
        <SystemButton
          data-testid="guardian-card-more-actions-btn"
          onClick={handleClick}
          disabled={isProcessingAgglutination}
        >
          <MoreVertIcon />
        </SystemButton>
        <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
          <div>
            {/*  Extra div to avoid Material UI forwardRef warning */}
            {/* <EditGuardianMenuItem onClick={handleClose} /> */}
            {/* Deverá renderizar a opção de cancelar somente se isFeatureFlagEnabled */}
            <CancelContractMenuItem
              disabled={!hasOpenInstallments}
              cancelRevoked={isCancelContractEnabled}
              onClick={handleClose}
              openCancellationDrawer={openCancellationDrawer}
            />
          </div>
        </Menu>
      </>
    )
  )
}
