import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks'
import { guardianService } from '@/modules/guardians/services'
import { GuardianHistoryNegotiationQueryResponse } from '@/modules/guardians/services/types'

export const useGuardianHistoryNegotiationQuery = (
  props: { id?: string },
  options?: UseQueryOptions<GuardianHistoryNegotiationQueryResponse, XMLHttpRequest>
): {
  guardianHistoryNegotiation?: GuardianHistoryNegotiationQueryResponse
  isError?: boolean
  isLoading?: boolean
  isRefetching?: boolean
  isSuccess?: boolean
} => {
  const { apiClient } = useApi()

  const service = guardianService(apiClient.privateApiV2)

  const queryKey = ['guardian-history-negotiation', props.id]

  const {
    data: guardianHistoryNegotiation,
    isLoading,
    isRefetching,
    isError,
    isSuccess,
  } = useQuery<GuardianHistoryNegotiationQueryResponse, XMLHttpRequest>(
    queryKey,
    () => service.fetchGuardianNegotiationDetails(props?.id),
    {
      enabled: !!props.id,
      retry: false,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: true,
      ...options,
    }
  )

  return {
    guardianHistoryNegotiation,
    isLoading,
    isRefetching,
    isError,
    isSuccess,
  }
}
