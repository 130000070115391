import { useMemo } from 'react'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useSchoolEnrollmentCycleContext } from '@/modules/enrollment/contexts/SchoolEnrollmentContext'

import { useCampaignsList } from '../../CampaignsList/hooks/useCampaignsList'

export const useCampaignDetails = (campaignId: uuid) => {
  const { selectedEnrollmentCycle } = useSchoolEnrollmentCycleContext()
  const { school } = useSelectedSchool()

  const schoolId = selectedEnrollmentCycle?.school_id ?? school?.id

  const schoolEnrollmentCycleId = selectedEnrollmentCycle?.school_enrollment_cycle_id ?? ''

  const CampaignParams = {
    campaignId,
    schoolId,
    schoolEnrollmentCycleId,
    pagination: {
      page: 1,
      per_page: 1,
    },
  }
  const {
    campaigns,
    isLoading: isLoadingCampaignDetails,
    refetch: refetchCampaignList,
  } = useCampaignsList(CampaignParams)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const campaignDetails = useMemo(() => {
    const firstCampaign = campaigns?.[0]
    return {
      id: firstCampaign?.id,
      name: firstCampaign?.name,
      productName: firstCampaign?.product_name,
      status: firstCampaign?.status,
      endDate: firstCampaign?.end_date,
      hasPaymentPlan: firstCampaign?.has_payment_plan,
      paymentPlans: firstCampaign?.payment_plans,
      engagedStudentsCount: firstCampaign?.engaged_students_count,
      emailRead: firstCampaign?.total_of_campaign_emails_read,
      studentsCount: firstCampaign?.students_count,
      paidEnrollmentsCount: firstCampaign?.paid_enrollments_count,
      closedType: firstCampaign?.closed_type,
    }
  }, [campaigns, refetchCampaignList])

  return { campaignDetails, isLoadingCampaignDetails }
}
