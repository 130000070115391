import dayjs, { extend } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { formatCentsToReal } from '@/shared/utils'
import { Row } from '@/modules/guardians/types'
import {
  INSTALLMENT_ROW_TYPES,
  NEW_INSTALLMENT_ROW_TYPES,
} from '@/modules/guardians/GuardianDetailsFinancialSummaryTab/constants'

dayjs.locale('pt-br')
extend(utc)

const fullDate = (value: Row['due_date']) => dayjs.utc(dayjs(value)).format('DD/MM/YYYY')

const amount = (value: Row['amount']) => formatCentsToReal(value)

const orderReference = (value: Row['installment_order']['order_reference']) =>
  value.split('/').join(' de ')

const installmentType = (value: Row['installment_order']['row_type']) =>
  INSTALLMENT_ROW_TYPES[value]

const newInstallmentType = (value: Row['installment_order']['row_type']) =>
  NEW_INSTALLMENT_ROW_TYPES[value]

export const formatter = {
  fullDate,
  amount,
  orderReference,
  installmentType,
  newInstallmentType,
}
