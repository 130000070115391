import { useState } from 'react'

import { BadgeProps } from '@gravity/badge'
import { mdiCashCheck, mdiClose, mdiPaperclip } from '@mdi/js'

import { PaymentDisputeItemState } from '@/modules/payment-dispute/models/PaymentDisputeItemState.enum'
import { useGuardianNavigation } from '@/modules/guardians/hooks/useGuardianNavigation'
import { PaymentDisputeState } from '@/modules/payment-dispute/models/PaymentDisputeState.enum'
import { PaymentDisputeSummary } from '@/modules/payment-dispute/models/PaymentDisputeSummary'
import { PaymentDisputeItem } from '@/modules/payment-dispute/models/PaymentDisputeItem'
import { ActionTypes } from '@/modules/payment-dispute/pages/PaymentDispute/components/PaymentDisputeAccordionContent/variations/PreviousPayment/components/Actions'
import useManualLiquidationNavigation from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/hooks/useManualLiquidationNavigation'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@/shared/models/enums/EventPageName.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'

type PaymentDisputeStatusProps = {
  color: BadgeProps['color']
  title: string
}

type usePaymentDisputeItemTableRowProps = {
  paymentDispute: PaymentDisputeSummary
  paymentDisputeItem: PaymentDisputeItem
}

const paymentDisputeItemStatusMapping: Record<
  PaymentDisputeItemState,
  PaymentDisputeStatusProps
> = {
  [PaymentDisputeState.PENDING]: {
    color: 'warning',
    title: 'Aguardando análise',
  },
  [PaymentDisputeState.ACCEPTED]: {
    color: 'neutral',
    title: 'Resolvido',
  },
  [PaymentDisputeState.DENIED]: {
    color: 'neutral',
    title: 'Negado',
  },
}

type CreatePaymentDisputeActions = {
  onDenyDispute: () => void
  onRegisterPayment: () => void
  onSeeReceipt: () => void
}

const createPaymentDisputeActions = ({
  onSeeReceipt,
  onDenyDispute,
  onRegisterPayment,
}: CreatePaymentDisputeActions) => ({
  [PaymentDisputeState.PENDING]: [
    {
      iconPath: mdiPaperclip,
      onClick: onSeeReceipt,
      text: 'Ver comprovante',
      type: ActionTypes.SEE_RECEIPT,
    },
    {
      iconPath: mdiClose,
      onClick: onDenyDispute,
      text: 'Negar contestação',
      type: ActionTypes.DENY_DISPUTE,
    },
    {
      iconPath: mdiCashCheck,
      onClick: onRegisterPayment,
      text: 'Registrar recebimento',
      type: ActionTypes.REGISTER_PAYMENT,
    },
  ],
  [PaymentDisputeState.ACCEPTED]: [
    {
      iconPath: mdiPaperclip,
      onClick: onSeeReceipt,
      text: 'Ver comprovante',
      type: ActionTypes.SEE_RECEIPT,
    },
  ],
  [PaymentDisputeState.DENIED]: [
    {
      iconPath: mdiPaperclip,
      onClick: onSeeReceipt,
      text: 'Ver comprovante',
      type: ActionTypes.SEE_RECEIPT,
    },
  ],
})

export const usePaymentDisputeItemTableRow = ({
  paymentDispute,
  paymentDisputeItem,
}: usePaymentDisputeItemTableRowProps) => {
  const { status, receipt_link } = paymentDisputeItem
  const [openPaymentDisputeDeny, setOpenPaymentDisputeDeny] = useState(false)
  const { setPageToReturn } = useGuardianNavigation()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { goToManualLiquidation } = useManualLiquidationNavigation({
    urn: paymentDisputeItem.receivable.urn,
    guardianId: paymentDispute.guardian?.id,
  })

  const handleSendEvent = (eventName: EventIdentifierName, buttonName: string) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYMENT_DISPUTE,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.PAYMENT_DISPUTE,
        identifierName: eventName,
        customProperties: {
          $type: paymentDispute?.type,
          $status: paymentDispute?.status,
          $button_name: buttonName,
          $payment_dispute_id: paymentDispute?.id,
          $payment_dispute_item_id: paymentDisputeItem?.id,
        },
      })
    }
  }

  const handleSeeReceipt = () => {
    handleSendEvent(EventIdentifierName.SEE_PAYMENT_DISPUTE_RECEIPT, 'Ver anexo')

    const newWindow = window.open(receipt_link, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleDenyDispute = () => {
    handleSendEvent(EventIdentifierName.PAYMENT_DISPUTE_DENY, 'Negar contestação')

    setOpenPaymentDisputeDeny(!openPaymentDisputeDeny)
  }

  const handleRegisterPayment = () => {
    handleSendEvent(EventIdentifierName.PAYMENT_DISPUTE_REGISTER_PAYMENT, 'Registrar recebimento')

    setPageToReturn()
    goToManualLiquidation()
  }

  const paymentDisputeItemActionsMapping = createPaymentDisputeActions({
    onSeeReceipt: handleSeeReceipt,
    onDenyDispute: handleDenyDispute,
    onRegisterPayment: handleRegisterPayment,
  })

  const actions = receipt_link
    ? paymentDisputeItemActionsMapping[status]
    : paymentDisputeItemActionsMapping[status].filter(
        ({ type }) => type !== ActionTypes.SEE_RECEIPT
      )

  return {
    actions,
    openPaymentDisputeDeny,
    ...paymentDisputeItemStatusMapping[status],
    handleSeeReceipt,
    handleDenyDispute,
    handleRegisterPayment,
    handleSendEvent,
  }
}
