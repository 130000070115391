import { Box, Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { Typography } from '@olaisaac/design-system'

import { formatCurrencyAmount } from '@/modules/report/utils/formatCurrencyAmount'

import theme from '@/shared/theme'

import { Title } from '../PayoutTransfersSectionCategoryTitle/styles'
import { Item, ItemsContainer, Value } from './styles'

type PayoutTransfersSectionSubCategoryProps = {
  items?: Array<{ label: string; secondaryInfo?: string; value: number }>
  secondaryInfo?: string
  title: string
  tooltip?: string
  total?: number
}

const ICON_COLOR = theme.primitiveTokens.colors.gray[50]
const TITLE_COLOR = theme.primitiveTokens.colors.gray[60]

export const PayoutTransfersSectionSubCategory = ({
  title,
  tooltip,
  secondaryInfo,
  items = [],
  total,
}: PayoutTransfersSectionSubCategoryProps) => {
  return (
    <Box mt="1rem" paddingBottom="1rem">
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box display="flex" alignItems="center">
          <Title color={TITLE_COLOR}>
            {title}

            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoOutlined
                  style={{ fontSize: '1rem', color: ICON_COLOR, marginLeft: '0.25rem' }}
                />
              </Tooltip>
            )}
          </Title>
        </Box>

        {total !== undefined && <Value>{formatCurrencyAmount(total)}</Value>}
      </Box>

      {!!secondaryInfo && (
        <Box mt="0.25rem">
          <Typography variation="bodySmall" color="secondary">
            {secondaryInfo}
          </Typography>
        </Box>
      )}

      {items.length > 0 && (
        <ItemsContainer>
          {items.map(({ label, value, secondaryInfo }) => (
            // TODO: after upgrade to React 18, replace key by useId hook
            <Item key={`${label}-${String(value)}-${secondaryInfo}`}>
              <Box>
                <Typography
                  variation="bodyLarge"
                  color="secondary"
                  style={{ lineHeight: '1.5rem' }}
                >
                  {label}
                </Typography>

                {!!secondaryInfo && (
                  <Box mt="0.25rem">
                    <Typography variation="bodySmall" color="secondary">
                      {secondaryInfo}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Value>{formatCurrencyAmount(value)}</Value>
            </Item>
          ))}
        </ItemsContainer>
      )}
    </Box>
  )
}
