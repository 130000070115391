import { DiscountType } from '../interfaces/receivable'
import { Discount } from '../interfaces'

export const discount: Discount = {
  amount: 4000,
  days_before_due_date: 5,
  description: 'nome de teste',
  type: DiscountType.EARLY_PAYMENT,
}

export const perpetualDiscount: Discount = {
  amount: 4000,
  days_before_due_date: -1,
  description: 'Bolsa',
  type: DiscountType.PERPETUAL,
}

export const duePaymentDiscount: Discount = {
  amount: 2000,
  days_before_due_date: 0,
  description: 'Pagamento em dia',
  type: DiscountType.DUE_PAYMENT,
}

export const earlyPaymentDiscount: Discount = {
  amount: 5000,
  days_before_due_date: 5,
  description: 'Pagamento adiantado',
  type: DiscountType.EARLY_PAYMENT,
}

export const additionalDiscount: Discount = {
  amount: 1000,
  days_before_due_date: -3,
  description: 'Campanha quitação',
  type: DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION,
}
