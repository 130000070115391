import { Box } from '@material-ui/core'
import { Chip, Link } from '@olaisaac/design-system'
import { ActiveFiltersProps } from './types'
import {
  ActiveFiltersContainer,
  ActiveFiltersText,
  ClearFiltersContainer,
  Container,
} from './styles'
import {
  updatedFiltersToRemove,
  filtersToShow,
} from '@/escolas/pages/responsaveis/ListGuardians/utils'

export const ActiveFilters: React.FC<ActiveFiltersProps> = ({
  filters,
  activeFilters,
  updateFilters,
  clearFilters,
}) => {
  const activeFiltersArray = filtersToShow(activeFilters)

  const handleRemoveFilter = (value: string) => {
    const updatedFilters = updatedFiltersToRemove(filters, value)

    updateFilters(updatedFilters)
  }

  return (
    <Container>
      <ActiveFiltersText>Filtros ativos:</ActiveFiltersText>
      <ActiveFiltersContainer>
        {activeFiltersArray?.map(({ label, value }) => (
          <Box key={value} mx={1}>
            <Chip variation="blue" label={label} onDelete={() => handleRemoveFilter(value)} />
          </Box>
        ))}
      </ActiveFiltersContainer>
      <ClearFiltersContainer>
        <Link onClick={() => clearFilters()}>Limpar</Link>
      </ClearFiltersContainer>
    </Container>
  )
}
