import { Installment } from '../../../types'

export const sameContractInstallments = (installments: Installment[]) => {
  const contractList: uuid[] = []
  for (const item of installments) {
    if (contractList.length === 0) {
      contractList.push(item.contract_id)
    } else {
      const checkAlreadyExists = contractList.find(x => x === item.contract_id)
      if (!checkAlreadyExists) {
        return false
      }
    }
  }
  return true
}
