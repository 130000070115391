import styled from 'styled-components'

export const TextContainer = styled.div`
  margin-bottom: 24px;
`

export const SectionWrapper = styled.section`
  margin: 24px 0;
`

export const DayModeWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`
