import Chip from '@material-ui/core/Chip'
import styled from 'styled-components'

import theme from 'src/shared/theme'

export const CustomChip = styled(Chip)`
  margin: ${theme.spacing(1)}px ${theme.spacing(1)}px;

  ${({ variant, theme }) =>
    (!variant || variant === 'default') &&
    `
  border: 1px solid ${theme.primitiveTokens.colors.isaacBlue[70]};
  color: ${theme.primitiveTokens.colors.isaacBlue[60]};
  background: ${theme.primitiveTokens.colors.isaacBlue[10]};
`}

  ${({ variant, theme }) =>
    variant === 'outlined' &&
    `
  border: 1px solid ${theme.primitiveTokens.colors.gray[3]};
  color: ${theme.primitiveTokens.colors.gray[80]};
  background: ${theme.primitiveTokens.colors.gray[3]};
`}

:hover {
    border: 1px solid ${theme.primitiveTokens.colors.gray[20]};
    color: ${theme.primitiveTokens.colors.gray[80]};
    background: ${theme.primitiveTokens.colors.isaacBlue[20]};
  }
`
