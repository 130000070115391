import styled from 'styled-components'

export const Container = styled.div`
  .MuiTable-root {
    table-layout: fixed;

    th {
      word-wrap: break-word;

      > span {
        width: auto;
      }
    }
  }
`
