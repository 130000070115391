import styled from 'styled-components'
import { colors, borderRadius, spacing, fontSize } from '@gravity/tokens'

type RootProps = {
  backgroundColor: string
}
export const Root = styled.div<RootProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.backgroundColor || colors['colors-background-neutral-2']};

  border: 1px solid ${colors['colors-interaction-primary-6']};
  border-radius: ${borderRadius[2]};
  gap: ${spacing[2]};
  padding: ${spacing[6]};
`

export const Title = styled.div`
  font-size: ${fontSize.lg};
`
