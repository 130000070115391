import { Typography } from '@olaisaac/design-system'

import type { StepItemStatus } from './types'
import * as S from './styles'

interface StepperProps {
  activeStepIndex: number
  disabled: boolean
  index: number
  label: string
  onClick: (index: number) => void
}

const StepItem = ({ activeStepIndex, label, index, onClick, disabled }: StepperProps) => {
  const status: StepItemStatus = (function () {
    if (index < activeStepIndex) return 'completed'
    if (index === activeStepIndex) return 'current'
    return 'incomplete'
  })()

  function handleClick() {
    onClick(index)
  }

  function renderIcon() {
    switch (status) {
      case 'completed':
        return <S.CheckCircleIcon />
      case 'current':
        return (
          <S.CounterIcon>
            <Typography withoutMargin color="accent" variation="bodySmall">
              {index + 1}
            </Typography>
          </S.CounterIcon>
        )
      default:
        return <S.RadioButtonUncheckedIcon />
    }
  }

  return (
    <S.Wrapper active={status === 'completed'} onClick={handleClick} disabled={disabled}>
      <S.LabelWrapper>
        {renderIcon()}
        <Typography
          color={status === 'completed' || status === 'current' ? 'accent' : 'secondary'}
          variation="bodySmall"
        >
          {label}
        </Typography>
      </S.LabelWrapper>
    </S.Wrapper>
  )
}

export default StepItem
