import { FC } from 'react'
import { Button, ButtonDocker } from '@olaisaac/design-system'

type ConfirmManualLiquidationDrawerActionsProps = {
  submitHandler: () => Promise<void>
}

export const ConfirmManualLiquidationDrawerActions: FC<ConfirmManualLiquidationDrawerActionsProps> = ({
  submitHandler,
}) => (
  <ButtonDocker>
    <Button variation="primary" fullWidth onClick={submitHandler}>
      Registrar recebimento
    </Button>
  </ButtonDocker>
)
