import styled from 'styled-components'

import * as ToggleGroup from '@radix-ui/react-toggle-group'

// ToggleGroup styles
export const ToggleGroupSyled = styled(ToggleGroup.Root)`
  display: inline-flex;
  outline: none;
  background-color: var(--mauve-6);
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(218, 218, 218, 0.4);
`
// ToggleGroupItem styles
export const ToggleGroupItemStyled = styled(ToggleGroup.Item)`
  background-color: white;
  outline: none;
  color: var(--mauve-11);
  height: 35px;
  width: 86px;
  display: flex;
  font-size: 15px;
  line-height: 1;
  border: 0;
  align-items: center;
  justify-content: center;

  &:first-child {
    margin-left: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(218, 218, 218, 0.12);
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(218, 218, 218, 0.4);
  }

  &[data-state='on'] {
    background-color: rgba(236, 237, 251, 1);
    color: rgba(61, 78, 215, 1);
    outline: none;
  }

  &:focus {
    position: relative;
    box-shadow: 0 0 0 1px blue;
  }
`
