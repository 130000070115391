import styled from 'styled-components'
import { spacing } from '@gravity/tokens'

export const Container = styled.div`
  max-width: calc(1698px + 3rem);
  margin: 0 auto;
  padding: ${spacing[4]} ${spacing[6]};
  height: 100%;
`

export const NewCampaignWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
  min-height: 720px;
`

export const ContentContainer = styled.div`
  flex: 3.5;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  justify-content: space-between;
`
