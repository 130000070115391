import { useEffect, useState } from 'react'

import { useQuery } from '@/shared/hooks/useQuery'
import { usePagination } from 'src/shared/hooks/usePagination'
import { DebtStatus } from 'src/shared/interfaces'

import {
  DEFAULT_SORT_ORDER,
  DEFAULT_SORT_BY,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  GuardiansQueryParamsNameEnum,
} from '../constants'
import { FiltersType, UseGuardiansQueryParamsProps } from '../types'
import { useOrdering } from '@/shared/hooks/useOrdering'

const parseArrayToFilter = <T>(filter: string | undefined) => {
  if (!filter) return []

  return filter.split(',') as T[]
}

/**
 * Hook to handle query params for guardians pages
 */
export const useGuardiansQueryParams = ({
  page = DEFAULT_PAGE,
  itemsPerPage = DEFAULT_PER_PAGE,
  sortOrder = DEFAULT_SORT_ORDER,
  sortBy = DEFAULT_SORT_BY,
}: UseGuardiansQueryParamsProps) => {
  const { query, setMultipleQueryParams } = useQuery()
  const { pagination } = usePagination(page, itemsPerPage)
  const { ordering, updateOrderingValue } = useOrdering({ sortBy, sortOrder })

  const [searchTerm, setSearchTerm] = useState<string | undefined>(
    query.get(GuardiansQueryParamsNameEnum.searchTerm) ?? undefined
  )
  const [filters, setFilters] = useState<FiltersType>({
    debt_status: parseArrayToFilter<DebtStatus>(
      query.get(GuardiansQueryParamsNameEnum.debtStatus) ?? undefined
    ),
  })

  useEffect(() => {
    const filtersToQueryParams = (filters: FiltersType) =>
      Object.entries(filters).reduce((prev, [name, value]) => {
        prev.push({ name, value: value && value.length ? value : null })
        return prev
      }, [])

    setMultipleQueryParams([
      { name: GuardiansQueryParamsNameEnum.searchTerm, value: searchTerm },
      ...filtersToQueryParams(filters),
    ])
  }, [searchTerm, filters])

  const updateFilters = (data: FiltersType) => setFilters(data)

  return {
    filters,
    searchTerm,
    page: pagination.page,
    itemsPerPage: pagination.itemsPerPage,
    sortOrder: ordering.sortOrder,
    sortBy: ordering.sortBy,
    updateOrderingValue,
    updateFilters,
    setSearchTerm,
  }
}
