export const CategoryType = {
  ALERT: 'alertas',
  TASKS: 'tarefas',
  REPORTS: 'informes',
}

export const StatusType = {
  READ: 'lido',
  UNREAD: 'Não lido',
}

export const categoryOptions = [
  {
    text: 'Alertas',
    value: 'alertas',
    selected: false,
  },
  {
    text: 'Tarefas',
    value: 'tarefas',
    selected: false,
  },
  {
    text: 'Informes',
    value: 'informes',
    selected: false,
  },
]

export const statusOptions = [
  {
    text: 'Lidos',
    value: 'lido',
    selected: false,
  },
  {
    text: 'Não lidos',
    value: 'Não lido',
    selected: false,
  },
]
