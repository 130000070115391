import { LiquidationSource, Receivable } from '@/shared/interfaces'
import {
  DetailTransactions,
  FinancialDetails,
  TransactionEventType,
  FinancialTransactionProps,
  ReferralInfo,
  TransactionEvents,
} from '@/modules/report/models/report'

import { formatDate } from '@/shared/utils'

export const formatPayoutFromExternalPayments = (item: Receivable): FinancialTransactionProps => {
  const referral: ReferralInfo = {
    installment_id: item.installment.id,
    installment_order_reference: item.installment.order_reference,
    student_name: item.installment.contract.student.name,
    product_name: item.installment.contract.product.name,
    contract_reference_year: item.installment.contract.reference_year,
    last_receivable_paid_date: item.paid_date,
    contract_id: item.installment.contract.id,
    guardian_id: item.installment.contract.student.guardian_id,
    installment_type: item.installment.type,
    payment_schedule_description: '',
    payment_schedule_id: '',
    product_id: item.installment.contract.product.id,
    student_id: item.installment.contract.student.id,
  }

  const events: TransactionEvents = {}
  if (
    item.payment_source === LiquidationSource.ISAAC_ACCOUNT ||
    item.payment_source === LiquidationSource.SCHOOL_ACCOUNT
  ) {
    events[TransactionEventType.SCHOOL_LIQUIDATION] = 1
  }

  const financialDetails: FinancialDetails = {
    total: item.total_amount_paid * 10000,
    events,
    total_fee: undefined,
    total_base_amount: undefined,
    total_expected_base_amount: item.total_amount_paid * 10000,
  }

  const detailTransactions: DetailTransactions[] = [
    {
      BaseAmount: item.original_amount * 10000,
      created_at: item.created_at,
      Fee: 0,
      transaction_event: {},
    },
  ]

  return {
    id: item.installment.id,
    key: item.installment.id,
    referrals: referral,
    financial_detail: financialDetails,
    reference_month: formatDate(item.due_date, 'YYYY-MM-DD'),
    last_modified_at: formatDate(item.paid_date, 'YYYY-MM-DD'),
    detail_transactions: detailTransactions,
  }
}
