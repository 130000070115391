import styled from 'styled-components'

import { SIDE_MENU_WIDTH } from '@/modules/app/components/Layout/styles'

export const Container = styled.div`
  position: relative;
  width: calc(100vw - ${SIDE_MENU_WIDTH});
  height: 100vh;
`

export const BackgroundImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
`
