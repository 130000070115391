import {
  ContractOverview,
  GuardianContractOverviewStatus,
  StudentSchoolMatch,
} from '@/shared/interfaces'

import { school1, school2 } from './schools'
import { openContract, canceledContract } from './contracts'
import { student, bob, william } from './students'

const overview1: ContractOverview = {
  contract_id: openContract.id,
  installments_count: 5,
  next_installment_due_date: '2022-01-02T16:50:26.393-03:00',
  next_installment_final_amount: 1000,
  next_installment_numeration: 3,
  product_name: openContract.product.name,
  status: GuardianContractOverviewStatus.WAITING_PAYMENT,
}

const overview2: ContractOverview = {
  contract_id: canceledContract.id,
  installments_count: 8,
  next_installment_due_date: '2022-01-02T16:50:26.393-03:00',
  next_installment_final_amount: 2000,
  next_installment_numeration: 7,
  product_name: canceledContract.product.name,
  status: GuardianContractOverviewStatus.NONE_PENDING,
}

export const contractsOverviewsList = [overview1, overview2]

const match1: StudentSchoolMatch = {
  contracts_status: GuardianContractOverviewStatus.CANCELED,
  school_id: school1.id,
  school_name: school1.name,
  student_id: student.id,
  student_name: student.name,
}

const match2: StudentSchoolMatch = {
  contracts_status: GuardianContractOverviewStatus.NONE_PENDING,
  school_id: school1.id,
  school_name: school1.name,
  student_id: bob.id,
  student_name: bob.name,
}

const match3: StudentSchoolMatch = {
  contracts_status: GuardianContractOverviewStatus.WAITING_PAYMENT,
  school_id: school2.id,
  school_name: school2.name,
  student_id: william.id,
  student_name: william.name,
}

export const guardiansStudentSchoolMatchesList = [match1, match2, match3]
