import { useRef, useEffect } from 'react'

export default (fn, watchables) => {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      return fn()
    } else {
      didMountRef.current = true
    }
  }, watchables)
}
