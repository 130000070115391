import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  CreditSimulation,
  Eligibility,
  Offer,
  DataReasonForChosenPolicyType,
  RequestSimulation,
  StepperMap,
} from '@/shared/services/credit/types'
import { PersistedStatePrefix, usePersistState } from '@/shared/hooks/usePersistState'
import { HOUR_IN_MILLIS } from '@/shared/utils'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export type CreditContext = {
  chosenOffer: Offer
  creditOrderId: string
  creditSimulation: CreditSimulation
  creditValue: string
  eligibility: Eligibility
  reasonForChosenPolicyType: DataReasonForChosenPolicyType
  requestSimulation: RequestSimulation
  setChosenOffer: Dispatch<SetStateAction<Offer>>
  setCreditOrderId: Dispatch<SetStateAction<string>>
  setCreditSimulation: Dispatch<SetStateAction<CreditSimulation>>
  setCreditValue: Dispatch<SetStateAction<string>>
  setEligibility: Dispatch<SetStateAction<Eligibility>>
  setReasonForChosenPolicyType: Dispatch<SetStateAction<DataReasonForChosenPolicyType>>
  setRequestSimulation: Dispatch<SetStateAction<RequestSimulation>>
  setStepperMap: Dispatch<SetStateAction<StepperMap>>
  stepperMap: StepperMap
}

export const CreditContext = createContext<CreditContext>(null)

type CreditProviderProps = { children: ReactNode }

export const CreditProvider: FC<CreditProviderProps> = ({ children }) => {
  const { schoolSlug } = useSelectedSchool()

  const [eligibility, setEligibility] = usePersistState<Eligibility>(
    `${PersistedStatePrefix.CreditSimulation}:${schoolSlug}:eligibility`,
    null,
    { TTL_ms: HOUR_IN_MILLIS }
  )
  const [creditSimulation, setCreditSimulation] = useState<CreditSimulation>(null)
  const [chosenOffer, setChosenOffer] = useState<Offer>(null)
  const [creditValue, setCreditValue] = useState<string>('')
  const [stepperMap, setStepperMap] = useState<StepperMap>(null)
  const [creditOrderId, setCreditOrderId] = useState<string>('')
  const [requestSimulation, setRequestSimulation] = useState<RequestSimulation>(null)
  const [
    reasonForChosenPolicyType,
    setReasonForChosenPolicyType,
  ] = useState<DataReasonForChosenPolicyType>(null)

  useEffect(() => {
    if (chosenOffer === null && creditSimulation !== null) {
      setChosenOffer(creditSimulation.offers[0])
      setCreditValue(creditSimulation.creditValue)
    }
  }, [creditSimulation])

  return (
    <CreditContext.Provider
      value={{
        creditSimulation,
        setCreditSimulation,
        eligibility,
        setEligibility,
        chosenOffer,
        setChosenOffer,
        creditValue,
        setCreditValue,
        stepperMap,
        setStepperMap,
        creditOrderId,
        setCreditOrderId,
        requestSimulation,
        setRequestSimulation,
        reasonForChosenPolicyType,
        setReasonForChosenPolicyType,
      }}
    >
      {children}
    </CreditContext.Provider>
  )
}

export const useContextEligibility = () => {
  const { eligibility, setEligibility } = useContext(CreditContext)
  return { eligibility, setEligibility }
}

export const useContextCreditSimulation = () => {
  const { creditSimulation, setCreditSimulation } = useContext(CreditContext)
  return { creditSimulation, setCreditSimulation }
}

export const useContextChosenOffer = () => {
  const { chosenOffer, setChosenOffer } = useContext(CreditContext)
  return { chosenOffer, setChosenOffer }
}

export const useContextCreditValue = () => {
  const { creditValue, setCreditValue } = useContext(CreditContext)
  return { creditValue, setCreditValue }
}

export const useContextStepperMap = () => {
  const { stepperMap, setStepperMap } = useContext(CreditContext)
  return { stepperMap, setStepperMap }
}

export const useContextCreditOrderId = () => {
  const { creditOrderId, setCreditOrderId } = useContext(CreditContext)
  return { creditOrderId, setCreditOrderId }
}

export const useContextRequestSimulation = () => {
  const { requestSimulation, setRequestSimulation } = useContext(CreditContext)
  return { requestSimulation, setRequestSimulation }
}

export const useReasonForChosenPolicyType = () => {
  const { reasonForChosenPolicyType, setReasonForChosenPolicyType } = useContext(CreditContext)
  return { reasonForChosenPolicyType, setReasonForChosenPolicyType }
}
