import { ActionDrawerHeader, Drawer } from '@olaisaac/design-system'
import { ChangeEvent, ReactFragment, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { TypesOfContractsGlossaryProps } from './types'
import { Accordion, AccordionDetails, AccordionSummary } from './styles'
import { ContractStatusBadge } from '../ContractStatusBadge/ContractStatusBadge'
import { PreContractStatuses as ContractStatusType } from '@/shared/interfaces'

export const TypesOfContractGlossaryDrawer = ({ open, onClose }: TypesOfContractsGlossaryProps) => {
  const [expanded, setExpanded] = useState<ContractStatusType>(null)
  const handleChange = (panel: ContractStatusType) => (
    _: ChangeEvent<any>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : null)
  }

  const typesList: { description: ReactFragment; type: ContractStatusType }[] = [
    {
      type: ContractStatusType.EFFECTED_PCS,
      description: (
        <>
          Contrato com parcela de pré-matrícula paga (se houver) e sem débitos de anos anteriores.
          Parcelas de mensalidades geradas e garantidas pelo isaac.
        </>
      ),
    },
    {
      type: ContractStatusType.CREATED_PCS,
      description: (
        <>
          Contrato com parcela de pré-matrícula a vencer e/ou é necessário regularizar o pagamento
          das parcelas vencidas no(s) ano(s) anterior(es). Parcelas de mensalidades ainda não foram
          geradas e não estão garantidas pelo isaac.
        </>
      ),
    },
  ]

  return (
    <Drawer open={open}>
      <ActionDrawerHeader
        onClose={onClose}
        title="Tipo de contrato"
        subtitle="Os tipos de contrato indicam quais edições podem ser realizadas."
      />

      {typesList.map(value => {
        return (
          <Accordion
            key={value.type}
            expanded={expanded === value.type}
            onChange={handleChange(value.type)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls={value.type}
              id={value.type}
            >
              <ContractStatusBadge status={value.type} enableTooltip={false} />
            </AccordionSummary>
            <AccordionDetails>{value.description}</AccordionDetails>
          </Accordion>
        )
      })}
    </Drawer>
  )
}
