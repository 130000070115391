import { Installment } from '@/modules/guardians/GuardianDetails/types'
import { ReceivableType } from '@/modules/guardians/GuardianDetails/constants'
import { InstallmentType } from '@/shared/interfaces'
import { AgreementSimulationParams } from '@/modules/guardians/models/agreement'

export default function adaptCombinedReceivablesToAgreementParam(
  installments: Installment[],
  guardianId: string
): AgreementSimulationParams {
  const formatedInstallments = installments.map(installment => ({
    current_due_payment_discount: installment.current_due_payment_discount,
    current_early_payment_discount: installment.current_early_payment_discount,
    current_perpetual_discount: installment.current_perpetual_discount,
    original_amount: installment.original_amount,
    total_amount: installment.amount,
    contract_id: installment.contract_id,
    due_date: installment.due_date,
    id: installment.receivable_id,
    installment_type:
      installment.receivable_type === ReceivableType.ENROLLMENT
        ? InstallmentType.ENROLLMENT
        : InstallmentType.TUITION,
  }))

  return {
    guardian_id: guardianId,
    receivables: formatedInstallments,
  }
}
