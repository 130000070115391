import { ForwardRefRenderFunction, forwardRef } from 'react'
import { IconButton } from '@olaisaac/design-system'
import { ExpandMore } from '@material-ui/icons'

import { DateText } from './styles'

export type LabelSize = 'small' | 'large'

type StandardPickerButtonProps = {
  label: string
  labelSize: LabelSize
  onClick: () => void
}

const StandardPickerButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  StandardPickerButtonProps
> = ({ label, labelSize, onClick }, ref) => {
  return (
    <>
      <DateText size={labelSize}>{label}</DateText>

      <IconButton
        size="small"
        ref={ref}
        variation="ghost"
        aria-label="Abrir calendário"
        onClick={onClick}
      >
        <ExpandMore />
      </IconButton>
    </>
  )
}

/**
 * Standard variation for week picker
 *
 * @param props
 * @param props.ref Component reference
 * @param props.label Button label
 * @param props.onClick On click callback function
 */
export const StandardPicker = forwardRef(StandardPickerButton)
