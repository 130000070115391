import { NotificationProps } from '@olaisaac/design-system'
import { useContext } from 'react'

import { SnackbarContext } from '@/shared/contexts/snackbarContext'

export const useSnackbar = () => {
  const context = useContext(SnackbarContext)

  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }

  const setSnackBar = (message: string, variation: NotificationProps['variation']) => {
    context.setMessage(message)
    context.setVariation(variation)
    context.setIsOpen(true)
  }

  return {
    ...context,
    setSnackBar,
  }
}

export default useSnackbar
