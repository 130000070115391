import { useEffect, useState } from 'react'

import { Table, TableColumns, Typography } from '@olaisaac/design-system'

import theme from '@/shared/theme'
import { StyledTableHeader, TableContainer } from './styles'
import { renderDebtStatus } from '@/escolas/components/contract/ContractDetails'

import { mapDataToRows } from './utils/mapDataToRows'

import { GuardianDetailsContractsTableProps, Rows } from './types'
import { MenuItem, Popover } from '@material-ui/core'
import { useNavigation } from '@/escolas/hooks'
import { Contract } from '../../../GuardianDetails/types'
import { ViewConditionsLink } from '../ViewConditionsLink/ViewConditionsLink'
import { ContractStatus } from '../ContractStatus/ContractStatus'
import { MoreOptions } from '../MoreOptions/MoreOptions'
import { ENROLLMENT_PAGE_BASE_PATH } from '@/modules/enrollment/constants/enrollment'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { GuardiansInstallmentsQueryParamsNameEnum } from '../../../GuardianDetailsInstallmentsTab/constants'
import { sortRowsBy } from './utils/sortRowsBy'
import { DebtStatus } from '@/shared/interfaces'
import { ContractStatusBadge } from '../ContractStatusBadge/ContractStatusBadge'

const NODES_NOT_ALLOWED_CLICK = ['A', 'BUTTON']

export const GuardianDetailsContractsTable = ({
  contracts,
  isLoading,
  openContractDetails,
}: GuardianDetailsContractsTableProps) => {
  const [contract, setContract] = useState<Contract>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [studentId, setStudentId] = useState<string>(null)
  const [orderBy, setOrderBy] = useState<{
    column: string
    order: 'ASC' | 'DESC'
    paramName: keyof typeof sortRowsBy
  } | null>(null)
  const [rows, setRows] = useState<Rows>([])
  const { navigateTo, newTabNavigateTo, guardian } = useNavigation()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleClickMoreOptions = (event: React.MouseEvent<HTMLElement>, contract: Contract) => {
    setAnchorEl(event.currentTarget)
    setContract(contract)
    setStudentId(contract.student.id)
  }

  const handleClickViewConditions = (contractId: uuid) => {
    openContractDetails(contractId)
  }

  const sendEvent = (
    scope: EventDispatcherEventScopes,
    name: EventDispatcherEvents,
    options?: Record<string, unknown>
  ) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope,
        name,
        action: 'click',
        ...options,
      })
  }

  const handleRedirectStudentPage = () => {
    sendEvent(EventDispatcherEventScopes.GUARDIANS, EventDispatcherEvents.VIEW_STUDENT_PAGE)

    setAnchorEl(null)
    newTabNavigateTo({
      path: `${ENROLLMENT_PAGE_BASE_PATH}/${studentId}`,
    })
  }

  const handleClickToSeeInstallments = (rowContract?: Contract, isRow?: boolean) => {
    sendEvent(
      EventDispatcherEventScopes.GUARDIANS,
      EventDispatcherEvents.GUARDIAN_CONTRACT_INSTALLMENTS_LIST,
      { customProperties: { $component: isRow ? 'row' : 'button' } }
    )

    const currentContract = contract || rowContract

    const params = new URLSearchParams()
    params.append(GuardiansInstallmentsQueryParamsNameEnum.YEARS, currentContract.reference_year)
    params.append(GuardiansInstallmentsQueryParamsNameEnum.PRODUCT_IDS, currentContract?.product.id)
    params.append(GuardiansInstallmentsQueryParamsNameEnum.STUDENT_IDS, currentContract?.student.id)
    params.append(GuardiansInstallmentsQueryParamsNameEnum.CONTRACT_STATUS, currentContract?.status)

    setAnchorEl(null)
    navigateTo({
      path: `responsaveis/${guardian.id}/faturas?${params.toString()}`,
    })
  }

  const columns: TableColumns = [
    {
      headerName: <StyledTableHeader>Status</StyledTableHeader>,
      field: 'status',
      renderCell: value => <ContractStatus status={value} />,
      small: true,
    },
    {
      headerName: <StyledTableHeader>Ano</StyledTableHeader>,
      field: 'reference_year',
      renderCell: value => <Typography variation="bodySmall">{value}</Typography>,
      small: true,
      enableSorting: true,
    },
    {
      headerName: <StyledTableHeader>Aluno</StyledTableHeader>,
      field: 'student',
      renderCell: value => <Typography variation="bodySmall">{value.name}</Typography>,
      enableSorting: true,
    },
    {
      headerName: <StyledTableHeader>Produto</StyledTableHeader>,
      field: 'product',
      renderCell: value => <Typography variation="bodySmall">{value.name}</Typography>,
    },
    {
      headerName: <StyledTableHeader>Situação financeira</StyledTableHeader>,
      field: 'debt_status',
      renderCell: value => renderDebtStatus(value),
      small: true,
    },
    {
      headerName: <StyledTableHeader>Tipo de contrato</StyledTableHeader>,
      field: 'pre_contract_status',
      renderCell: value => <ContractStatusBadge status={value} />,
      small: true,
    },
    {
      field: 'contract',
      renderCell: value => (
        <ViewConditionsLink
          handleClick={() => handleClickViewConditions(value.id)}
          disabled={value.debt_status === DebtStatus.PENDING}
        />
      ),
      small: true,
    },
    {
      field: 'contract',
      renderCell: value => (
        <MoreOptions
          contract={value}
          handleClick={handleClickMoreOptions}
          aria-label="more-options-btn"
        />
      ),
      small: true,
    },
  ]

  const TableEmptyState = () => (
    <Typography variation="subtitleDesktopMedium" color="secondary">
      Nenhum contrato encontrado
    </Typography>
  )

  useEffect(() => {
    if (!isLoading && contracts) {
      setRows(mapDataToRows(contracts))
    }
  }, [isLoading, contracts])

  useEffect(() => {
    if (orderBy) {
      const sorted = sortRowsBy[orderBy.paramName](rows, orderBy.order) as Rows
      setRows([...sorted])
    }
  }, [orderBy])

  return (
    <>
      <TableContainer theme={theme}>
        <Table
          data-testid="guardianContractsTable"
          columns={columns}
          emptyRender={TableEmptyState()}
          isLoading={isLoading}
          rows={rows || []}
          onRowClick={(params, e) => {
            !NODES_NOT_ALLOWED_CLICK.includes(e.target.nodeName) &&
              handleClickToSeeInstallments(params.row, true)
          }}
          sorted
          orderBy={orderBy?.column}
          sortingHandler={value => {
            const paramName = value as keyof typeof sortRowsBy
            if (!paramName) {
              setOrderBy({ column: null, paramName: orderBy.paramName, order: 'DESC' })
              return
            }

            setOrderBy({ column: value, paramName, order: 'ASC' })
          }}
        />
      </TableContainer>
      <Popover
        anchorEl={anchorEl}
        keepMounted={false}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleRedirectStudentPage()}>Ver situação de matrícula</MenuItem>
        <MenuItem onClick={() => handleClickToSeeInstallments()}>Ver parcelas</MenuItem>
      </Popover>
    </>
  )
}
