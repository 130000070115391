import { DEFAULT_DISCOUNTS_OPTIONS } from 'src/shared/components/DiscountsListForm/components/DiscountFormFields/constants'
import { PreContract } from 'src/shared/interfaces'
import { DiscountTuitionEditForm } from '../types'

export const getDiscounts = (contract: PreContract): DiscountTuitionEditForm[] => {
  const {
    tuition_installments: { discounts },
  } = contract

  return (
    discounts?.map(d => ({
      ...d,
      label_days_before_due_date:
        DEFAULT_DISCOUNTS_OPTIONS.find(option => option.value === d?.days_before_due_date)?.name ||
        DEFAULT_DISCOUNTS_OPTIONS[2].name,
      is_finished: true,
    })) ?? []
  )
}
