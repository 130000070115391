import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import Icon from '@mdi/react'
import { mdiTrayArrowDown } from '@mdi/js'
import { CircularProgress } from '@material-ui/core'
import { Button } from '@gravity/button'
import { DatepickerPrimitives as Datepicker, formatDateToMonthYear } from '@gravity/datepicker'
import { Skeleton } from '@gravity/skeleton'

import { ReportCard } from '../ReportCard'
import { CardFooter, StyledDatepickerTrigger } from './styles'

import { useGetListTicketSummary } from '../../hooks/queries/listTicketSummary'

import { convertToUTCDate } from '@/shared/utils'

import { useDownloadTicketsSummaryReport } from '../../hooks/useDownloadTicketsSummaryReport'

export const TicketsSummaryReportCard = () => {
  const { downloadTicketSummaryReport, isLoading } = useDownloadTicketsSummaryReport()

  const { data: listTicketsSummary, isFetching } = useGetListTicketSummary({
    refetchOnWindowFocus: false,
  })

  const [selectedDateForTicketSummary, setSelectedDateForTicketSummary] = useState<Date>(
    dayjs().utc().toDate()
  )

  const availableMonths = listTicketsSummary?.data?.available_months || []
  const hasAvailableMonths = availableMonths.length > 0

  useEffect(() => {
    if (hasAvailableMonths) {
      setSelectedDateForTicketSummary(dayjs(availableMonths[0]).utc().toDate())
    }
  }, [availableMonths])

  if (!hasAvailableMonths) {
    return null
  }

  const minDate = availableMonths[availableMonths.length - 1]
  const maxDate = availableMonths[0]
  const minDateUTCForTicketSummary = convertToUTCDate(minDate).format('YYYY-MM')
  const maxDateUTCForTicketSummary = convertToUTCDate(maxDate).format('YYYY-MM')

  const handleDownloadTicketsSummaryReport = async (selectedYearAndMonth: Date) => {
    await downloadTicketSummaryReport(selectedYearAndMonth)
  }

  return (
    <ReportCard
      title="Relatório de atendimento"
      description="Relatórios com principais informações sobre a qualidade do atendimento isaac prestado às famílias."
    >
      {!isFetching ? (
        <CardFooter>
          <Datepicker.Root>
            <StyledDatepickerTrigger size={3}>
              {formatDateToMonthYear(selectedDateForTicketSummary)}
            </StyledDatepickerTrigger>
            <Datepicker.Calendar
              value={selectedDateForTicketSummary}
              minDate={dayjs(minDateUTCForTicketSummary).toDate()}
              maxDate={dayjs(maxDateUTCForTicketSummary).toDate()}
              maxDetail="year"
              minDetail="decade"
              onChange={month => setSelectedDateForTicketSummary(month as Date)}
            />
          </Datepicker.Root>
          <Button
            variant="ghost"
            iconEnd={isLoading ? <CircularProgress /> : <Icon path={mdiTrayArrowDown} />}
            onClick={() => handleDownloadTicketsSummaryReport(selectedDateForTicketSummary)}
            disabled={isLoading}
          >
            Baixar
          </Button>
        </CardFooter>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.75rem' }}>
          <Skeleton height={40} width={275} />
          <Skeleton height={40} width={100} />
        </div>
      )}
    </ReportCard>
  )
}
