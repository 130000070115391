import type { Dispatch, SetStateAction } from 'react'
import { useForm } from 'react-hook-form'
import type { UseFormReturn } from 'react-hook-form'

import type { FormProps } from '@/modules/enrollment/pages/Campaigns/CampaignDetails/components/InstallmentForm/types'
import type { AddTuitionToCampaignState } from '@/modules/enrollment/pages/Campaigns/CampaignDetails/types'
import type { School } from '@/shared/models/School'

import { useAddTuitionToCampaign } from './useAddTuitionToCampaign'

type Props = {
  campaignId: uuid
  school?: School
  setAddTuitionDialogState: Dispatch<SetStateAction<AddTuitionToCampaignState>>
}
export const useAddTuitionToOneCampaignStudent = ({
  campaignId,
  setAddTuitionDialogState,
  school,
}: Props) => {
  const form = useForm<FormProps>({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      custom_monthly_amount: 0,
      duration_months: '',
      installment_due_date_rule: null,
      due_day: undefined,
      due_date: null,
      due_date_month: null,
      discounts: [],
    },
  })

  const { addTuition, isLoading: isLoadingAddTuitionMutation } = useAddTuitionToCampaign({
    campaignId,
    school,
  })

  const handleSubmitAddTuitionToProposal = async (
    enrollmentID: uuid,
    formData: UseFormReturn<FormProps>
  ) => {
    addTuition({
      formData: formData.getValues(),
      enrollmentId: enrollmentID,
      excludedEnrollmentIds: [],
    })
    form.reset()
    setAddTuitionDialogState({ enrollmentID: '', studentName: '', openDialog: false })
  }
  const handleCloseDialogForm = (form: UseFormReturn<FormProps>) => {
    setAddTuitionDialogState({ enrollmentID: '', studentName: '', openDialog: false })
    form.reset()
  }
  return {
    isLoadingAddTuitionMutation,
    handleSubmitAddTuitionToProposal,
    form,
    handleCloseDialogForm,
  }
}
