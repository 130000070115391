import { Select } from '@gravity/select'
import styled from 'styled-components'

export const CardFooter = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gravity.spacing[3]};
`

export const StyledSelect = styled(Select)`
  width: 275px;
`
