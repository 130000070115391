import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.gravity.spacing[4]} ${({ theme }) => theme.gravity.spacing[2]};
  > svg {
    box-sizing: content-box;
  }
`
