export type ErrorResponse = {
  message: string
  tag: string
}

export type PaginationRequest<T extends string = string> = Omit<Pagination<T>, 'total'>

export type Pagination<T extends string = string> = Partial<{
  page: number
  per_page: number
  sort_by: T
  sort_order: 'ASC' | 'DESC'
  total: number
}>

export type APIResponse<T = any, P extends string = string> = {
  data: T
  errors?: ErrorResponse[]
  pagination?: Pagination<P>
}

export type RequestPresignedURL = {
  extension: string
  file_name?: string
  method?: 'PUT' | 'GET'
}

export type PresignedURL = {
  file_id: uuid
  url: string
}

export type PathParams = {
  contractId: string
  guardianId?: uuid
  payoutId: string
  schoolSlug: string
  urn?: uuid
}

export enum RESPONSE_STATUS {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}

export enum API_NAMED_ERRORS {
  CONTRACT_IS_DUPLICATED = 'CONTRACT_DUPLICATED_VIA_PI',
  DENIED_GATE_ONE_VALIDATION = 'denied_gate_validation',
  IDENTITY_TAX_ID_VALIDATION_FAILED = 'identity error: tax_id validation failed',
  LOCKED_RESOURCE = 'locked_resource',
  STUDENT_HAS_CAMPAIGN_ENROLLMENT = 'STUDENT_HAS_CAMPAIGN_ENROLLMENT',
  UNABLE_TO_EFFECT_CONTRACT = 'unable_to_effect_contract',
}
