import { useState } from 'react'

export type DrawerType = 'onboarding' | 'summary' | 'filter' | 'studentDetails'

type HookValue = {
  closeAllDrawers: () => void
  hasOpenDrawer: boolean
  isPayoutFilterDrawerOpen: boolean
  isPayoutOnboardingDrawerOpen: boolean
  isPayoutSummaryDrawerOpen: boolean
  isStudentDetailsDrawerOpen: boolean
  setDrawerOpen: (drawer: DrawerType, isOpen: boolean) => void
}

export function useIsaacPayPayoutReportDrawers(): HookValue {
  const [isPayoutOnboardingDrawerOpen, setIsPayoutOnboardingDrawerOpen] = useState(false)
  const [isPayoutSummaryDrawerOpen, setIsPayoutSummaryDrawerOpen] = useState(false)
  const [isPayoutFilterDrawerOpen, setIsPayoutFilterDrawerOpen] = useState(false)
  const [isStudentDetailsDrawerOpen, setIsStudentDetailsDrawerOpen] = useState(false)

  function setDrawerOpen(drawer: DrawerType, isOpen: boolean) {
    if (drawer === 'onboarding') setIsPayoutOnboardingDrawerOpen(isOpen)
    if (drawer === 'summary') setIsPayoutSummaryDrawerOpen(isOpen)
    if (drawer === 'filter') setIsPayoutFilterDrawerOpen(isOpen)
    if (drawer === 'studentDetails') setIsStudentDetailsDrawerOpen(isOpen)
  }

  function closeAllDrawers() {
    setIsPayoutOnboardingDrawerOpen(false)
    setIsPayoutSummaryDrawerOpen(false)
    setIsPayoutFilterDrawerOpen(false)
    setIsStudentDetailsDrawerOpen(false)
  }

  const hasOpenDrawer =
    isPayoutOnboardingDrawerOpen ||
    isPayoutSummaryDrawerOpen ||
    isPayoutFilterDrawerOpen ||
    isStudentDetailsDrawerOpen

  return {
    isPayoutFilterDrawerOpen,
    isPayoutOnboardingDrawerOpen,
    isPayoutSummaryDrawerOpen,
    isStudentDetailsDrawerOpen,
    setDrawerOpen,
    hasOpenDrawer,
    closeAllDrawers,
  }
}
