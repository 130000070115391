import {
  CheckoutCombinableReceivable,
  CheckoutReceivables,
} from '@/escolas/contexts/checkoutContext'
import { Checkout, CheckoutStatus, CheckoutType, PaymentMethod, Pos } from '@/shared/interfaces'

export const mockCheckout: Checkout = {
  created_at: '2021-01-27T17:29:18.701Z',
  updated_at: '2021-01-27T17:29:18.701Z',
  id: '259261f8-a056-4789-921d-a3270114aeba' as uuid,
  amount: 100,
  external_transaction_id: '12dc3398-d7d2-4c63-8ad8-772be07a0bbf' as uuid,
  installments_count: 3,
  keep_due_payment_discounts: true,
  keep_early_payment_discounts: false,
  keep_fine_and_interest: true,
  payment_methods: [PaymentMethod.CREDIT_CARD_PAYMENT_METHOD],
  school_id: 'bbc1aa59-4894-41c5-895d-e2f13b61ed72' as uuid,
  status: CheckoutStatus.ON_HOLD,
  type: CheckoutType.STONE_CHECKOUT,
}

export const mockPosSerialNumber = '1A111111'
export const mockPosSerialNumber2 = '1A111112'

export const mockPos: Pos = {
  pos_id: 'f29bb1c1-bc5a-498c-94f5-ccfbd18151a9',
  pdv_name: '1-Pdv-Name',
  pos_serial_number: mockPosSerialNumber,
}

export const mockPos2: Pos = {
  pos_id: '122f937c-2898-4cb3-b420-07539fe82e57',
  pdv_name: '2-Pdv-Name',
  pos_serial_number: mockPosSerialNumber2,
}

export const mockCheckoutProcess: Checkout = {
  ...mockCheckout,
  status: CheckoutStatus.PROCESSING,
}

export const mockCheckoutFinish: Checkout = {
  ...mockCheckout,
  status: CheckoutStatus.FINISHED,
}

export const mockCheckoutAbort: Checkout = {
  ...mockCheckout,
  status: CheckoutStatus.ABORTED,
}

export const someReceivableCheckout: CheckoutCombinableReceivable = {
  amount: 31050,
  due_date: 'datestring_1',
  id: 'abcdef',
  orderReference: '04/05',
  contract_id: '95f8e0e5-952a-498a-84d6-7bbb3d25c299',
  children: [],
  current_interest: 1000,
  current_fine: 1000,
  discounts: [],
  lost_due_payment_discount: 0,
  lost_early_payment_discount: 10000,
  product: 'Nome Produto 1',
  product_id: 'ba927b82-3768-4301-b215-76039fe12f83',
  status: 'OPEN',
  student: 'Aluno 1',
  student_id: 'feda1063-a3e0-4ab2-a6ec-25710bfefd0c',
}
export const anotherReceivableCheckout: CheckoutCombinableReceivable = {
  amount: 50000,
  due_date: 'datestring_2',
  id: 'ghijklm',
  orderReference: '03/05',
  contract_id: 'bf58888e-26ff-4a13-b2c0-351f1116c527',
  children: [],
  current_interest: 2000,
  current_fine: 0,
  discounts: [],
  lost_due_payment_discount: 11000,
  lost_early_payment_discount: 0,
  product: 'Nome Produto 2',
  product_id: 'bf5444c5-208e-4e67-aad9-5ee95d8ad8ab',
  status: 'OPEN',
  student: 'Aluno 2',
  student_id: '92c15f2b-1cfc-4866-b3f9-f4860fea70c9',
}

export const mockFinalCheckoutList: CheckoutReceivables[] = [
  {
    student_id: '705b4d54-f5a9-4283-a2cb-69b71059410a',
    student_name: 'Caroline Rezende Silva',
    products: [
      {
        product_id: '0e7a8070-74d5-4f84-b0d3-0b68acb5573e',
        product_name: 'Ano 5',
        receivables: [
          {
            amount: 27626,
            contract_id: '381cc759-f28d-4f28-9c22-bd4ff1a6182a',
            competenceDate: '2022-09-15T21:00:00-03:00',
            current_early_payment_discount: 0,
            current_perpetual_discount: 7000,
            current_due_payment_discount: 0,
            current_fine: 533,
            current_interest: 760,
            discounts: [],
            due_date: '2022-07-16T21:00:00-03:00',
            id: '736fa749-71a9-4deb-a0f7-eec900eb489e',
            installment_id: 'c8e04bf9-ba32-4dba-ac6a-2667ba31dfa9',
            lost_due_payment_discount: 4999,
            lost_early_payment_discount: 3333,
            orderReference: '3 de 13',
            original_amount: 33333,
            product: 'Ano 5',
            product_id: '0e7a8070-74d5-4f84-b0d3-0b68acb5573e',
            status: 'OPEN',
            student: 'Caroline Rezende Silva',
            student_id: '705b4d54-f5a9-4283-a2cb-69b71059410a',
            isEnrollment: false,
          },
          {
            amount: 27712,
            contract_id: '381cc759-f28d-4f28-9c22-bd4ff1a6182a',
            competenceDate: '2022-10-15T21:00:00-03:00',
            current_early_payment_discount: 0,
            current_perpetual_discount: 6666,
            current_due_payment_discount: 0,
            current_fine: 512,
            current_interest: 533,
            discounts: [],
            due_date: '2022-08-15T21:00:00-03:00',
            id: '3f2e3dc1-3fb5-4a42-8e97-d9049fcacb08',
            installment_id: 'b1c8acdd-faa5-42cc-beae-4320d852622c',
            lost_due_payment_discount: 4999,
            lost_early_payment_discount: 3333,
            orderReference: '4 de 13',
            original_amount: 33333,
            product: 'Ano 5',
            product_id: '0e7a8070-74d5-4f84-b0d3-0b68acb5573e',
            status: 'OPEN',
            student: 'Caroline Rezende Silva',
            student_id: '705b4d54-f5a9-4283-a2cb-69b71059410a',
            isEnrollment: false,
          },
        ],
      },
    ],
  },
]
