import type { RouteDefinition } from '@/shared/core/router'
import { Router } from '@/shared/core/router'
import { CreditHome } from '../pages/CreditHome'
import { Simulation } from '../pages/Simulation'
import { useRouteMatch } from 'react-router-dom'
import { ModuleRegister } from '@/shared/core/module'
import { CreditProvider } from '../contexts/CreditDataContext'
import { CreditSummary } from '../pages/CreditSummary'
import { CreditStatus } from '../pages/CreditStatus'
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary'
import { PayoutAnticipationSummary } from '../pages/PayoutAnticipationPages/Summary'
import { PayoutAnticipationConfirmedSummary } from '../pages/PayoutAnticipationPages/Status'
import { PayoutAnticipationSimulation } from '../pages/PayoutAnticipationPages/Simulation'
import { PayoutAnticipationProvider } from '../contexts/PayoutAnticipationContext'

export const creditInternalRoutes: RouteDefinition[] = [
  {
    key: 'CREDIT_HOME',
    path: '/',
    component: CreditHome,
    exact: true,
  },
  {
    key: 'SIMULATION',
    path: '/simulacao',
    component: Simulation,
    exact: true,
  },
  {
    key: 'SUMMARY',
    path: '/resumo',
    component: CreditSummary,
    exact: true,
  },
  {
    key: 'STATUS',
    path: '/estado',
    component: CreditStatus,
    exact: true,
  },
]

const CreditModuleInternalRoutesWrapper = () => {
  const { path: basePath } = useRouteMatch()

  const moduleRegister = new ModuleRegister()
  moduleRegister.registerRoutes(creditInternalRoutes, { basePath })

  return (
    <ErrorBoundary>
      <CreditProvider>
        <Router routes={moduleRegister.getRoutes()} loadingComponent={<p>loading</p>} />
      </CreditProvider>
    </ErrorBoundary>
  )
}

export const anticipationInternalRoutes: RouteDefinition[] = [
  {
    key: 'PAYOUT_ANTICIPATION_SUMMARY',
    path: '/resumo',
    component: PayoutAnticipationSummary,
    exact: true,
  },
  {
    key: 'PAYOUT_ANTICIPATION_CONFIRMED_SUMMARY',
    path: '/status',
    component: PayoutAnticipationConfirmedSummary,
    exact: true,
  },
  {
    key: 'PAYOUT_ANTICIPATION_SIMULATION',
    path: '/simulacao',
    component: PayoutAnticipationSimulation,
    exact: true,
  },
]

const AnticipationModuleInternalRoutesWrapper = () => {
  const { path: basePath } = useRouteMatch()

  const moduleRegister = new ModuleRegister()
  moduleRegister.registerRoutes(anticipationInternalRoutes, { basePath })

  return (
    <ErrorBoundary>
      <PayoutAnticipationProvider>
        <Router routes={moduleRegister.getRoutes()} loadingComponent={<p>loading</p>} />
      </PayoutAnticipationProvider>
    </ErrorBoundary>
  )
}

export const routes: RouteDefinition[] = [
  {
    key: 'CREDIT_ROUTES',
    path: '/credito',
    component: CreditModuleInternalRoutesWrapper,
    exact: false,
  },
  {
    key: 'PAYOUT_ANTICIPATION',
    path: '/antecipacao/repasse',
    component: AnticipationModuleInternalRoutesWrapper,
    exact: false,
  },
]
