import { ReactNode, createContext, useCallback, useMemo, useState } from 'react'

import type { MenuItem } from '@/shared/core/module'
import type { LayoutContextType, LayoutProviderProps, LayoutOptionsData } from './types'

export const LayoutContext = createContext({} as LayoutContextType)

/**
 * Custom provider to wrap the application on the layout component
 *
 * @param props.children
 */
export const LayoutProvider = ({ children, LayoutComponent: Layout }: LayoutProviderProps) => {
  const [layoutOptions, setLayoutOptions] = useState<LayoutOptionsData>({
    sideMenu: true,
    header: false,
  })
  const [headerTitle, setHeaderTitle] = useState<string | ReactNode>('')
  const [menuItems, setMenuItems] = useState<MenuItem[]>([])

  const updateLayoutOptions = useCallback(
    (data: LayoutOptionsData) => {
      setLayoutOptions(data)
    },
    [setLayoutOptions]
  )

  const updateHeaderTitle = useCallback(
    (title: string | ReactNode) => {
      setHeaderTitle(title)
    },
    [setHeaderTitle]
  )

  const updateMenuItems = useCallback(
    (items: MenuItem[]) => {
      setMenuItems(items)
    },
    [setMenuItems]
  )

  const contextValue: LayoutContextType = useMemo(
    () => ({
      layoutOptions,
      headerTitle,
      menuItems,
      updateLayoutOptions,
      updateHeaderTitle,
      updateMenuItems,
    }),
    [layoutOptions, updateLayoutOptions]
  )

  return (
    <LayoutContext.Provider value={contextValue}>
      <Layout
        title={headerTitle}
        isSideMenuVisible={layoutOptions.sideMenu}
        isHeaderVisible={layoutOptions.header}
      >
        {children}
      </Layout>
    </LayoutContext.Provider>
  )
}
