import { useState } from 'react'
import { TextField } from '@gravity/text-field'
import { mdiClose, mdiMagnify } from '@mdi/js'
import Icon from '@mdi/react'
import { IconButton } from '@gravity/icon-button'
import { debounce } from 'throttle-debounce'

const DEBOUNCE_DELAY = 900

type SearchProps = {
  placeholder: string
  setValue: (v: string) => void
  value: string
}

export const Search = ({ value, setValue, placeholder }: SearchProps) => {
  const [localValue, setLocalValue] = useState(value)

  const debouncedSetValue = debounce(DEBOUNCE_DELAY, (newValue: string) => {
    setValue(newValue)
  })

  return (
    <TextField
      name="Search"
      aria-label={placeholder}
      placeholder={placeholder}
      iconStart={<Icon path={mdiMagnify} />}
      iconButton={
        <IconButton
          onClick={() => {
            setLocalValue('')
            setValue('')
          }}
        >
          <Icon path={mdiClose} />
        </IconButton>
      }
      onChange={e => {
        const newValue = (e.target as HTMLInputElement).value
        setLocalValue(newValue)
        debouncedSetValue(newValue)
      }}
      value={localValue}
      size={3}
    />
  )
}
