import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'
import { mdiPencil, mdiTrashCan } from '@mdi/js'
import Icon from '@mdi/react'

type RowActionsProps = {
  enableEdit?: boolean
  enableRemove?: boolean
  onRequestEdit: () => void
  onRequestRemove: () => void
}

export const RowActions = ({
  enableEdit = false,
  enableRemove = false,
  onRequestEdit,
  onRequestRemove,
}: RowActionsProps) => {
  return (
    <>
      <Tooltip text="Editar acesso">
        <IconButton
          variant="ghost"
          size={2}
          onClick={onRequestEdit}
          disabled={!enableEdit}
          data-testid="edit"
        >
          <Icon path={mdiPencil} />
        </IconButton>
      </Tooltip>

      <Tooltip text="Excluir acesso">
        <IconButton
          variant="ghost"
          size={2}
          onClick={onRequestRemove}
          disabled={!enableRemove}
          data-testid="remove"
        >
          <Icon path={mdiTrashCan} />
        </IconButton>
      </Tooltip>
    </>
  )
}
