import { useMemo } from 'react'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useQuery } from '@/shared/hooks/useQuery'
import { usePagination } from '@/shared/hooks/usePagination'

import { useTuitionPayoutReportFilter } from '@/modules/report/hooks/useTuitionPayoutReportFilter'
import { useOrdering } from '@/shared/hooks/useOrdering'
import { FetchPayoutDetailsResponseDTO } from '@/escolas/services/report/types'
import { QueryObserverResult } from '@tanstack/react-query'
import { useEnrollmentPayoutReports } from '@/modules/report/hooks/queries/useEnrollmentPayoutReports'
import { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'
import { usePayoutDetailsQuery } from '@/escolas/hooks/queries'

type EnrollmentPayoutReportDataValue = {
  enrollmentPayoutReportDetails: FetchPayoutDetailsResponseDTO<'enrollment'> | undefined
  enrollmentPayoutReportList: UnguaranteedReport[]
  hasErrorOnFetch: boolean
  isFetchingEnrollmentPayoutReportDetails: boolean
  isFetchingEnrollmentPayoutReportList: boolean
  refetch:
    | (() => Promise<QueryObserverResult<UnguaranteedReport[], unknown>>)
    | (() => Promise<QueryObserverResult<FetchPayoutDetailsResponseDTO<'enrollment'>, unknown>>)
}

/**
 * Custom hook that exports enrollment payout report page data
 */
export const useEnrollmentPayoutReportData = (): EnrollmentPayoutReportDataValue => {
  const { query } = useQuery()
  const { school } = useSelectedSchool()
  const { filter } = useTuitionPayoutReportFilter()
  const { pagination } = usePagination(1, 25)
  const { ordering } = useOrdering()

  const {
    data: enrollmentPayoutReportList,
    isFetching: isFetchingEnrollmentPayoutReportList,
    isError: hasErrorOnFetchEnrollmentPayoutReportList,
    refetch: refetchEnrollmentPayoutReportList,
  } = useEnrollmentPayoutReports(school?.id ?? '', {
    enabled: !!school?.id,
  })

  const payoutId = query.get('id') ?? ''
  const nameFilter = query.get('name') ?? ''

  const {
    data: enrollmentPayoutReportDetails,
    isFetching: isFetchingEnrollmentPayoutReportDetails,
    isError: hasErrorOnFetchEnrollmentPayoutReportDetails,
    refetch: refetchEnrollmentPayoutReportDetails,
  } = usePayoutDetailsQuery(
    'enrollment',
    {
      payoutId,
      filter: {
        name: nameFilter,
        ...filter,
      },
      pagination: {
        page: pagination.page,
        per_page: pagination.itemsPerPage,
        sort_by: ordering.sortBy,
        sort_order: ordering.sortOrder,
      },
    },
    { keepPreviousData: true }
  )

  const hookValue = useMemo<EnrollmentPayoutReportDataValue>(() => {
    return {
      enrollmentPayoutReportList: enrollmentPayoutReportList ?? [],
      isFetchingEnrollmentPayoutReportList,
      enrollmentPayoutReportDetails,
      isFetchingEnrollmentPayoutReportDetails:
        isFetchingEnrollmentPayoutReportList || isFetchingEnrollmentPayoutReportDetails,
      hasErrorOnFetch:
        hasErrorOnFetchEnrollmentPayoutReportList || hasErrorOnFetchEnrollmentPayoutReportDetails,
      refetch: hasErrorOnFetchEnrollmentPayoutReportList
        ? refetchEnrollmentPayoutReportList
        : refetchEnrollmentPayoutReportDetails,
    }
  }, [
    enrollmentPayoutReportList,
    isFetchingEnrollmentPayoutReportList,
    enrollmentPayoutReportDetails,
    isFetchingEnrollmentPayoutReportDetails,
    hasErrorOnFetchEnrollmentPayoutReportList,
    hasErrorOnFetchEnrollmentPayoutReportDetails,
    refetchEnrollmentPayoutReportList,
    refetchEnrollmentPayoutReportDetails,
  ])

  return hookValue
}
