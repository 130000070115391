import { Button } from '@gravity/button'
import { Callout } from '@gravity/callout'
import { Dialog } from '@gravity/dialog'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { TextField } from '@gravity/text-field'
import { mdiClose, mdiMagnify, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'
import { useMemo, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'

import { GoBackButton } from '@/shared/components/GoBackButton'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'

import { useSchoolEnrollmentCycleContext } from '../../contexts/SchoolEnrollmentContext'
import { useDialogVisibility } from '../../hooks/useDialogVisibility'
import { ScoreCard } from './components/ScoreCard'
import { taxIdLength } from './constants'
import * as Styled from './styles'
import { EventIdentifierName } from '../../models/EventIdentifierName.enum'
import { useEnrollmentMixpanelEvents } from '../../hooks/useEnrollmentMixpanelEvents'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { ScoreHistory } from './components/ScoreHistory'
import { useScore } from './hooks/useScore'
import { useFetchSchoolScore } from '../../hooks/queries/useFetchSchoolScore'
import { Skeleton } from '@gravity/skeleton'
import { formatCPF, validateCpf } from '@/shared/utils'
import { IconButton } from '@gravity/icon-button'
import { colors } from '@gravity/tokens'

export const PersonScore = () => {
  useSetPageTitle('Score - isaac')
  const { school } = useSelectedSchool()
  const history = useHistory()

  const { selectedEnrollmentCycle } = useSchoolEnrollmentCycleContext()
  const { dialogVisible, handleDialogOpenChange, openDialog, closeDialog } = useDialogVisibility()

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const { sendFeedbackButtonClickEvent, sendButtonClickEvent } = useEnrollmentMixpanelEvents()

  const { data: schoolScoreResponse } = useFetchSchoolScore({
    school_id: school?.id,
  })
  const schoolScore = schoolScoreResponse?.data?.school_average_score

  const [taxIdValue, setTaxIdValue] = useState('')
  const [validTaxId, setValidTaxId] = useState(undefined)

  const goToContractPage = () => {
    if (!selectedEnrollmentCycle || !school) return
    sendButtonClickEvent(
      'Matricular',
      EventDispatcherEventScopes.SCORE_ISAAC,
      EventIdentifierName.SCORE_ISAAC_ENROLL
    )
    history.push({
      pathname: `/${school.slug}/contratos/${selectedEnrollmentCycle.reference_year}/novo`,
      search: '',
      state: {
        personTaxId: validTaxId,
      },
    })
  }

  const isValidTaxId = useMemo(() => {
    if (taxIdValue.length !== taxIdLength) return false

    const taxIdValidation = validateCpf(taxIdValue)

    if (taxIdValidation) {
      setValidTaxId(taxIdValue)
    }

    sendFeedbackButtonClickEvent(
      'Pesquisar score',
      taxIdValidation.toString(),
      EventDispatcherEventScopes.SCORE_ISAAC,
      EventIdentifierName.SCORE_ISAAC_SEARCH
    )

    return taxIdValidation
  }, [taxIdValue])

  const { earliestAcademicCycle, isCurrentlyDefault, personScore, isError, isFetching } = useScore({
    schoolId: school?.id,
    taxId: validTaxId,
  })

  const getErrorMessage = () => {
    if (!isValidTaxId) {
      return 'Insira um CPF válido'
    }
    if (isValidTaxId && isError) {
      return `O CPF ${formatCPF(taxIdValue)} não consta na nossa base de dados.`
    }
    return ''
  }

  return (
    <Styled.PageContainer>
      <Styled.PageContent>
        <Styled.HeadingWrapper>
          <Styled.BackButtonWrapper>
            <GoBackButton />
          </Styled.BackButtonWrapper>

          <Styled.TitleWrapper>
            <Heading variant="heading-h2-medium">Score isaac</Heading>
          </Styled.TitleWrapper>

          <Text>
            Agora você pode consultar o score de risco dos responsáveis financeiros diretamente na
            nossa plataforma, facilitando decisões mais seguras na aprovação de novas matrículas.
            <br />
            <br />
            <strong>Importante</strong>: Essa informação é confidencial e, em nenhuma circunstância,
            deve ser compartilhada com o responsável. Esse dado não representa uma recomendação do
            isaac sobre aceitar ou negar a matrícula; a decisão cabe à escola. Em caso de dúvidas, a
            escola pode solicitar informações adicionais ao responsável, como comprovante de renda
            ou quitação da escola anterior.
          </Text>
        </Styled.HeadingWrapper>
        <Callout
          text={`O score médio dos responsáveis da sua escola é de: ${schoolScore}`}
          linkLabel="Saiba mais"
          href="#"
          onLinkClick={() => {
            sendButtonClickEvent(
              'Saiba mais',
              EventDispatcherEventScopes.SCORE_ISAAC,
              EventIdentifierName.SCORE_ISAAC_LEARN_MORE
            )
            openDialog()
          }}
        />
        <Dialog
          onOpenChange={handleDialogOpenChange}
          open={dialogVisible}
          title="Quais são os scores isaac?"
          backdrop
          size={2}
          actionButton={<></>}
          cancelButton={
            <Button size={2} onClick={closeDialog}>
              Fechar
            </Button>
          }
          content={
            <div>
              <Text>
                O score Isaac é uma nota que considera o nível de risco dos responsáveis,
                classificada de 1 a 5:
                <br />
                <br />
                1: Risco muito alto <br />
                2: Risco alto <br />
                3: Risco moderado <br />
                4: Risco baixo <br />
                5: Risco muito baixo <br />
                <br />O score médio reflete a avaliação dos responsáveis já cadastrados na
                plataforma Isaac.
              </Text>
            </div>
          }
        />

        <NumberFormat
          type="tel"
          value={taxIdValue}
          onValueChange={currentValue => setTaxIdValue(currentValue.value)}
          format="###.###.###-##"
          customInput={TextField}
          variant="outlined"
          size={3}
          placeholder="Digite apenas os números, sem pontos ou traços"
          label="Pesquise o responsável por CPF"
          data-testid="tax-id-input"
          icon
          iconStart={
            <Icon size={1} color={colors['colors-background-neutral-5']} path={mdiMagnify} />
          }
          iconButton={
            <IconButton size={1} onClick={() => setTaxIdValue('')}>
              <Icon path={mdiClose} />
            </IconButton>
          }
          error={(!isValidTaxId || isError) && taxIdValue.length === taxIdLength}
          errorMessage={getErrorMessage()}
        />

        {isFetching ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              gap: '48px',
            }}
          >
            <Skeleton height={296} fullWidth radius={6} />
            <Skeleton height={40} width={160} radius={6} />
          </div>
        ) : (
          !isError &&
          isValidTaxId && (
            <>
              <ScoreCard taxId={taxIdValue} score={personScore} />

              {earliestAcademicCycle && (
                <ScoreHistory
                  earliestAcademicCycle={earliestAcademicCycle}
                  isCurrentlyDefault={isCurrentlyDefault}
                />
              )}

              <Styled.Footer>
                {!isIntegratedSchool && (
                  <Button onClick={goToContractPage} iconStart={<Icon path={mdiPlus} />}>
                    Matricular
                  </Button>
                )}
              </Styled.Footer>
            </>
          )
        )}
      </Styled.PageContent>
    </Styled.PageContainer>
  )
}
