import { createTheme } from '@material-ui/core/styles'
import {
  borderRadius,
  boxShadow,
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  spacing,
} from '@gravity/tokens'
import {
  brandMaterialTheme,
  brandPrimitiveTokens,
  brandHumanizedTokens,
} from '@olaisaac/design-system'

/**
 * @deprecated
 */
export const materialTheme = createTheme(brandMaterialTheme)

export const theme = {
  ...materialTheme,
  primitiveTokens: brandPrimitiveTokens,
  humanizedTokens: brandHumanizedTokens,
  gravity: {
    borderRadius,
    boxShadow,
    colors,
    fontFamily,
    fontSize,
    fontWeight,
    letterSpacing,
    lineHeight,
    spacing,
  },
}

/**
 * TODO: Remove export default
 */
export default theme
