import { Redirect, useRouteMatch } from 'react-router-dom'

import { ContractListRoute2021, ContractListRoute } from 'src/escolas/router/[schoolSlug]/contratos'
import useRouterReferenceYear from 'src/escolas/hooks/useRouterReferenceYear'
import { useUserGuidingTrigger } from '@/shared/hooks/useUserGuidingTrigger'
import { INITIAL_SUPPORTED_CYCLE_YEAR } from '@/modules/enrollment/constants/enrollment'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

export const FIRST_AVAILABLE_YEAR = 2021

export default () => {
  useUserGuidingTrigger()

  const { isExact } = useRouteMatch()

  const { schoolSlug } = useSelectedSchool()

  const referenceYearParam = useRouterReferenceYear()
  const referenceYear = referenceYearParam?.match(/^(20)\d{2}$/)?.[0]
  const referenceYearNumber = Number(referenceYear)

  const isFlexibleEnrollmentCycleEnabled = useUnleashFlag(
    UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES
  )
  if (
    isFlexibleEnrollmentCycleEnabled &&
    isExact &&
    referenceYearNumber >= INITIAL_SUPPORTED_CYCLE_YEAR
  ) {
    return <Redirect to={{ pathname: `/${schoolSlug}/matriculas` }} />
  }

  if (referenceYearNumber === FIRST_AVAILABLE_YEAR) {
    return <ContractListRoute2021 />
  }

  if (referenceYearNumber) {
    return <ContractListRoute />
  }

  return null
}
