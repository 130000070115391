import type { Roles } from '@/shared/contexts/Auth'

export const getRolesBasedOnEnabledFeatureFlag = (
  roles: Set<Roles>,
  enabledBasicOperation?: boolean
) => {
  if (roles.has('atribuir_acesso_operador_basico') && !enabledBasicOperation) {
    roles.delete('atribuir_acesso_operador_basico')
  }

  if (roles.has('remover_acesso_operador_basico') && !enabledBasicOperation) {
    roles.delete('remover_acesso_operador_basico')
  }

  return roles
}
