import styled from 'styled-components'

export const PopoverContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  margin-top: 8px;
  div {
    margin-bottom: 16px;
  }
`

export const Container = styled.div`
  display: flex;
  gap: 8px;
`
