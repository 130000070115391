import theme from '@/shared/theme'
import styled from 'styled-components'

const { colors } = theme.primitiveTokens

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  height: 100vh;
  width: 100%;
`

export const StyledTextGoiaba = styled.span`
  color: ${colors.coral[60]};
`

export const StyledBox = styled.div`
  width: 540px;
`
