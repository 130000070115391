import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks/useApi'

import type { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'

export const useEnrollmentPayoutReports = (
  schoolId: string,
  options?: UseQueryOptions<UnguaranteedReport[]>
) => {
  const { api } = useApi()

  return useQuery<UnguaranteedReport[]>(
    ['reports', schoolId, 'enrollment'],
    async () => {
      const { data } = await api.report.fetchReportList({
        schoolId,
        reportType: 'UNGUARANTEED',
      })

      return data
    },
    options
  )
}
