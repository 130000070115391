import { useEffect, useState } from 'react'

const DRAWER_QUERY_SELECTOR = '.MuiDrawer-paper'

/**
 * Helper hook to get material drawer width
 *
 * @returns Object containing the drawer width
 */
export const useDrawerWidth = () => {
  const [drawerWidth, setDrawerWidth] = useState(0)

  useEffect(() => {
    const updateDrawerWidth = () => {
      const width = document.querySelector<HTMLDivElement>(DRAWER_QUERY_SELECTOR)?.offsetWidth
      setDrawerWidth(width)
    }

    updateDrawerWidth()

    window.addEventListener('resize', updateDrawerWidth)

    return () => {
      window.removeEventListener('resize', updateDrawerWidth)
    }
  }, [])

  return { drawerWidth }
}
