import { Button } from '@gravity/button'
import { colors, spacing } from '@gravity/tokens'
import styled from 'styled-components'

export const AddDiscountBtn = styled(Button)`
  margin-top: ${spacing[8]};
  max-width: 50%;
`

export const Separator = styled.div`
  margin: ${spacing[8]} 0;
  background: ${colors['colors-border-neutral-2']};
  width: 100%;
  height: 1px;
`

export const DiscountList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[8]};
`
