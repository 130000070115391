import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import { ProviderComposer } from '@/shared/core/providers'
import { Router } from '@/shared/core/router'

import { AppWrapper } from './components/AppWrapper'
import { SentryWrapper } from './components/SentryWrapper'
import { CircularLoadingPlaceholder } from './components/CircularLoadingPlaceholder'

import { GlobalProviders } from './contexts/GlobalProviders'
import { ApiProvider } from '@/shared/contexts/apiContext'

import { hotjarInit, mixpanel } from '@/shared/integrations'

import { routes } from './routes'

import envConfig from '@/config'

dayjs.extend(isSameOrBefore)

if (envConfig.API_MOCK) {
  const { worker } = require('../../mocks/browser')
  worker.start()
}

export const App = () => {
  mixpanel.initMixpanel()

  if (envConfig.HOTJAR_ID && envConfig.HOTJAR_SNIPPET_VERSION) {
    hotjarInit()
  }

  return (
    <ProviderComposer providers={[GlobalProviders, ApiProvider]}>
      <SentryWrapper>
        {/* REVIEW */}
        <AppWrapper>
          <Router routes={routes} loadingComponent={<CircularLoadingPlaceholder />} />
        </AppWrapper>
      </SentryWrapper>
    </ProviderComposer>
  )
}
