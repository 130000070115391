import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import Icon from '@mdi/react'

export enum ActionTypes {
  DENY_DISPUTE = 'deny-dispute',
  REGISTER_PAYMENT = 'register-payment',
  SEE_RECEIPT = 'see-receipt',
}

type ActionProps = {
  iconPath: string
  onClick: () => void
  text: string
  type: ActionTypes
}

export const Actions = ({ actions }: { actions: ActionProps[] }) => {
  return (
    <>
      {actions.map(({ iconPath, text, onClick }, key) => (
        <Tooltip key={key} text={text} position="left">
          <IconButton onClick={onClick} variant="ghost">
            <Icon path={iconPath} size={16} />
          </IconButton>
        </Tooltip>
      ))}
    </>
  )
}
