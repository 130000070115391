import { z } from 'zod'

export const updatePaymentDisputeItemStatusSchema = z
  .object({
    id: z.string(),
    payment_dispute_item_id: z.string(),
    status: z.enum(['accepted', 'denied']),
    denial_reason: z.string().min(1),
  })
  .required()

export type UpdatePaymentDisputeItemStatusSchemaForm = z.infer<
  typeof updatePaymentDisputeItemStatusSchema
>
