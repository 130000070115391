import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Box, CircularProgress } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'

import { Typography, Chip, TableControlBar, Button } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { Header, RequestError } from '../PendingCharges/components'
import { Table } from './components'

import { SelectedRowsProps } from './types'

import {
  useConfirmNeverPaidBulkMutation,
  useContractNeverPaidDetailQuery,
} from '@/escolas/hooks/queries/contracts'
import { useContract, useNavigation } from '@/escolas/hooks'
import { LoadingBox } from '@/shared/components/LoadingBox'
import { ModalConfirmation } from './components/ModalConfirmation'
import { useQueryClient } from '@tanstack/react-query'

import { ContractCancellationDrawer } from './CancellationDrawer'
import { ManualLiquidationDrawer } from './ManualLiquidationDrawer'
import { Contract } from '@/shared/interfaces'
import { useApi, useSnackbar } from '@/shared/hooks'
import { prettifyFullName } from '@/shared/utils'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export function PendingChargesDetails() {
  const { api } = useApi()
  const { contract, setContract } = useContract()
  const history = useHistory()

  const { schoolId, schoolSlug, navigateTo, getNavigationUrl } = useNavigation()
  const queryClient = useQueryClient()
  const { setMessage, setVariation, setIsOpen } = useSnackbar()

  const [contracts, setContracts] = useState<Contract[]>([])
  const [contractsIsLoading, setContractsIsLoading] = useState<boolean>(true)
  const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false)
  const [selectedRows, setSelectedRows] = useState<SelectedRowsProps>(null)

  const [selectedReceivableId, setSelectedReceivableId] = useState<string>(null)
  const [orderReference, setOrderReference] = useState<string>(null)

  const [isLiquidationDrawerOpen, setLiquidationDrawerOpen] = useState<boolean>(false)
  const [isCancellationDrawerOpen, setCancellationDrawerOpen] = useState<boolean>(false)

  const currentYear = new Date().getFullYear()

  const { uniqueKey } = useParams<{ uniqueKey: string }>()

  const { isLoading, isError, data: result, refetch } = useContractNeverPaidDetailQuery({
    schoolId,
    uniqueKey,
    referenceYear: currentYear.toString(),
  })

  useEffect(() => {
    async function getData() {
      if (!result || result.data.guardian_id === null) return

      const newContracts: Contract[] = await api.guardians.getContracts(
        result.data.guardian_id,
        currentYear.toString(),
        schoolId
      )

      setContracts(newContracts)
      setContractsIsLoading(false)
    }

    getData()
  }, [result?.data.guardian_id])

  const confirmNeverPaidBulk = useConfirmNeverPaidBulkMutation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleConfirmCharges = () => {
    setIsOpenModalConfirmation(true)

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NEVER_PAID,
        name: EventDispatcherEvents.NEVER_PAID_CONFIRM_CLICK,
        action: 'click',
        customProperties: {
          $origin: 'Tela de detalhes - Botão "Manter cobranças" do footer',
        },
      })
    }
  }

  const handleSubmitConfirmation = () => {
    const installmentIds = Object.keys(selectedRows).map(key => {
      return selectedRows[key].map(row => row.installment_id)
    })

    confirmNeverPaidBulk.mutate(
      {
        schoolId,
        installmentIds: installmentIds.flat(),
      },
      {
        onSuccess: () => {
          setIsOpenModalConfirmation(false)
          setSelectedRows(null)

          queryClient.refetchQueries({
            queryKey: ['contracts', 'neverPaid'],
          })

          if (isInitialized) {
            eventDispatcherClient.sendEvent({
              scope: EventDispatcherEventScopes.NEVER_PAID,
              name: EventDispatcherEvents.NEVER_PAID_CONFIRM_FINISH,
              action: 'click',
              customProperties: {
                $origin: 'Tela de detalhes - Botão "Confirmar" da modal',
              },
            })
          }
        },
        onError: () => {
          setIsOpenModalConfirmation(false)
          setMessage('Ocorreu um erro ao confirmar a(s) parcela(s). Tente novamente mais tarde.')
          setVariation('error')
          setIsOpen(true)
        },
      }
    )
  }

  if (isLoading || contractsIsLoading) {
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    )
  }

  if (isError || !result) return <RequestError onRefetch={refetch} />

  if (result.data.total_installments === 0 || result.data.total_products === 0) {
    history.replace(getNavigationUrl({ slug: schoolSlug, path: `/validacao-de-inadimplencia` }))
    return null
  }

  const isAllSelectedFromSameContract =
    selectedRows &&
    Object.keys(selectedRows)
      .map(key => {
        if (selectedRows[key]?.length === 0 || !result.data?.contracts) return false

        return selectedRows[key]?.length === result.data?.contracts[0].installments.length || 0
      })
      .includes(true)

  const totalItemsSelected = selectedRows
    ? Object.keys(selectedRows)
        .map(key => selectedRows[key].length)
        .reduce((a, b) => a + b, 0)
    : 0

  return (
    <Box display="flex" flexDirection="column" marginTop="3rem" minHeight="calc(100vh - 3rem)">
      <Header
        onClickBack={() => {
          navigateTo({ slug: schoolSlug, path: `/validacao-de-inadimplencia` })
        }}
      />

      <Box
        display="flex"
        justifyContent="space-between"
        marginTop="2rem"
        paddingBottom="1rem"
        borderBottom="1px solid #DADADA"
      >
        <Box>
          <Box display="flex" gridGap="0.75rem">
            <PersonIcon />
            <Typography variation="subtitleDesktopLarge">
              {prettifyFullName(result.data.student_name)}
            </Typography>
          </Box>

          <Typography variation="bodyLarge" color="secondary">
            Responsável: {prettifyFullName(result.data.guardian_name)}
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="center"
          gridGap="1rem"
        >
          <Chip label="Nenhuma parcela paga" />

          <Typography variation="caption" color="secondary">
            {result.data.total_installments} parcela(s) em aberto | {result.data.total_products}{' '}
            produto(s)
          </Typography>
        </Box>
      </Box>

      <Box paddingBottom="6rem">
        {result.data.contracts.map(contract => {
          if (contract && contract.installments) {
            return (
              <Table
                key={contract.contract_id}
                contract={contract}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onManualLiquidationClick={value => {
                  const findContract = contracts.find(c => c.id === contract.contract_id)

                  setContract(findContract)
                  setLiquidationDrawerOpen(true)
                  setOrderReference(value?.installment_order_reference)
                  setSelectedReceivableId(value?.receivable_id)

                  if (isInitialized) {
                    eventDispatcherClient.sendEvent({
                      scope: EventDispatcherEventScopes.NEVER_PAID,
                      name: EventDispatcherEvents.NEVER_PAID_PAYMENT_REGISTER_CLICK,
                      action: 'click',
                    })
                  }
                }}
              />
            )
          }

          return null
        })}
      </Box>

      <Box position="fixed" bottom="0" left="6rem" right="0">
        <TableControlBar>
          <Box display="flex" flex="1" justifyContent="flex-end">
            <Button
              variation="ghost"
              disabled={!isAllSelectedFromSameContract}
              style={{ marginRight: '1rem' }}
              onClick={() => {
                const contractId = selectedRows
                  ? Object.keys(selectedRows)
                      .map(key => {
                        return selectedRows[key].length ===
                          result.data.contracts[0].installments.length
                          ? key
                          : null
                      })
                      .filter(item => item !== null)[0]
                  : null

                const findContract = contracts.find(c => c.id === contractId)

                setContract(findContract)
                setCancellationDrawerOpen(true)

                if (isInitialized) {
                  eventDispatcherClient.sendEvent({
                    scope: EventDispatcherEventScopes.NEVER_PAID,
                    name: EventDispatcherEvents.NEVER_PAID_CANCEL_CONTRACT_CLICK,
                    action: 'click',
                  })
                }
              }}
            >
              Cancelar contrato
            </Button>

            <Button
              variation="primary"
              disabled={!selectedRows}
              loading={confirmNeverPaidBulk.isLoading}
              onClick={handleConfirmCharges}
            >
              Manter cobranças
            </Button>
          </Box>
        </TableControlBar>
      </Box>

      <ModalConfirmation
        isOpen={isOpenModalConfirmation}
        onClose={() => setIsOpenModalConfirmation(false)}
        onConfirm={handleSubmitConfirmation}
        studentName={result.data.student_name}
        isLoading={confirmNeverPaidBulk.isLoading}
        totalItemsSelected={totalItemsSelected}
      />

      <ManualLiquidationDrawer
        isOpen={isLiquidationDrawerOpen}
        onClose={() => setLiquidationDrawerOpen(false)}
        orderReference={orderReference}
        receivableId={selectedReceivableId}
        contractCreatedAt={result.data.contracts[0].created_at}
        onSuccess={() => {
          setLiquidationDrawerOpen(false)
          queryClient.refetchQueries({
            queryKey: ['contracts', 'neverPaid'],
          })

          if (isInitialized) {
            eventDispatcherClient.sendEvent({
              scope: EventDispatcherEventScopes.NEVER_PAID,
              name: EventDispatcherEvents.NEVER_PAID_PAYMENT_REGISTER_CONFIRM,
              action: 'click',
            })
          }
        }}
      />

      {contract && (
        <ContractCancellationDrawer
          isOpen={isCancellationDrawerOpen}
          onClose={() => setCancellationDrawerOpen(false)}
          contract={contract}
        />
      )}
    </Box>
  )
}
