import { useRouteMatch } from 'react-router-dom'
import dayjs from 'dayjs'

import { ButtonLink } from './components/ButtonLink'
import { Section } from '../Section'
import { Subsection } from '../Subsection'

import { useProfileDetails } from '@/modules/school-home/hooks/queries/enrollment-request/useProfileDetails'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useQuickAccessSectionLinks } from './useQuickAccessSectionLinks'
import { isIsaacPaySchool } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventIdentifierName } from '@/escolas/models/EventIdentifierName.enum'
import { EventPageName } from '@/escolas/models/EventPageName.enum'

export function InnerContent({
  isMaintainer,
  isSecretary,
  isIntegratedSchool,
  currentYear,
  currentDate,
  isIsaacPay,
  url,
}: {
  currentDate: string
  currentYear: number
  isIntegratedSchool: boolean
  isIsaacPay: boolean
  isMaintainer: boolean
  isSecretary: boolean
  url: string
}) {
  const {
    isInitialized: isEventDispatcherInitialized,
    eventDispatcherClient,
  } = useEventDispatcher()

  const handleClickOption = (title: string) => () => {
    if (isEventDispatcherInitialized) {
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICK,
        scope: EventDispatcherEventScopes.HOME,
        identifierName: EventIdentifierName.QUICK_ACCESS,
        pageName: EventPageName.HOME,
        customProperties: {
          $origin: 'Home',
          $button: title,
        },
      })
    }
  }

  const links = useQuickAccessSectionLinks({
    isMaintainer,
    isSecretary,
    isIntegratedSchool,
    currentYear,
    currentDate,
    isIsaacPay,
    url,
  })

  return (
    <Section title="Acesso rápido" variantText="subtitle-regular" className="quick-access">
      <Subsection columnsCount={links.length} width="240px">
        {links.map(link => (
          <li key={link.title}>
            <ButtonLink
              $variant="secondary"
              icon={link.icon}
              href={link.href}
              title={link.title}
              onClick={handleClickOption(link.title)}
            />
          </li>
        ))}
      </Subsection>
    </Section>
  )
}

export const QuickAccessSection = () => {
  const { isMaintainer, isSecretary, isIntegratedSchool } = useProfileDetails()
  const { school } = useSelectedSchool()
  const isIsaacPay = isIsaacPaySchool(school)

  const { url } = useRouteMatch()

  const date = dayjs()
  const currentDate = date.format('YYYY-MM')
  const currentYear = date.year()

  return (
    <InnerContent
      currentDate={currentDate}
      isMaintainer={isMaintainer}
      url={url}
      currentYear={currentYear}
      isSecretary={isSecretary}
      isIntegratedSchool={isIntegratedSchool}
      isIsaacPay={isIsaacPay}
    />
  )
}
