import { Box } from '@material-ui/core'
import { Button, Typography } from '@olaisaac/design-system'

import type { NotificationErrorProps } from './types'

import theme from '@/shared/theme'

const { spacing } = theme.primitiveTokens

export const NotificationError = ({ onRetry }: NotificationErrorProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      padding={spacing[8]}
      height={349}
    >
      <Box display="grid" gridGap={spacing[1]} width={450} marginBottom={spacing[4]}>
        <Typography variation="headlineDesktopXsmall" style={{ fontSize: '1.125rem' }}>
          Opa, não foi possível exibir avisos agora
        </Typography>
        <Typography variation="bodyLarge" style={{ lineHeight: 1.5 }}>
          Alguma coisa aconteceu por aqui, mas podemos tentar de novo! Vamos lá?
        </Typography>
      </Box>
      <Button onClick={onRetry} style={{ width: 'fit-content' }}>
        Tentar ler de novo
      </Button>
    </Box>
  )
}
