import { useEffect, useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { DialogPrimitives } from '@gravity/dialog'
import { Button } from '@gravity/button'
import { Select, Option } from '@gravity/select'
import { useToast } from '@gravity/toast'

import { useMutateOnRemoveAccess } from '@/modules/access-management/hooks/queries/user-access'

import type { User } from '@/modules/access-management/models/User'
import type { School } from '@/shared/models/School'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventIdentifierName } from '@/modules/access-management/models/EventIdentifierName.enum'
import { EventPageName } from '@/modules/access-management/models/EventPageName.enum'

type RemoveUserAccessDialogProps = {
  currentSchool?: School
  isOpen: boolean
  onClose: () => void
  user?: User
}

export const OPTIONS: Option[] = [
  {
    label: 'Não é mais funcionário',
    value: 'NOT_AN_EMPLOYEE',
  },
  {
    label: 'Trocou de unidade',
    value: 'SCHOOL_CHANGED',
  },
  {
    label: 'Mudou de área',
    value: 'FIELD_CHANGED',
  },
  {
    label: 'Outros',
    value: 'OTHER',
  },
]

export const RemoveUserAccessDialog = ({
  currentSchool,
  user,
  isOpen,
  onClose,
}: RemoveUserAccessDialogProps) => {
  const { mutateAsync, isLoading } = useMutateOnRemoveAccess()
  const { toast } = useToast()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const [selectedReason, setSelectedReason] = useState<Option>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setSelectedReason(undefined)
    }
  }, [isOpen])

  const handleSubmit = async () => {
    if (!selectedReason || !user || !currentSchool) return

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
        identifierName: EventIdentifierName.REMOVE_ACCESS,
        pageName: EventPageName.USER_ACCESS,
        customProperties: {
          $button_name: 'Excluir acesso',
          $exclusion_reason: selectedReason.label,
        },
      })

    let success = true

    try {
      setIsSubmitting(true)

      await mutateAsync({ schoolId: currentSchool.id, userId: user.id })
    } catch {
      success = false
    } finally {
      const title = success ? 'Acesso excluído' : 'Erro na exclusão do acesso'

      isInitialized &&
        eventDispatcherClient.sendEvent({
          name: EventDispatcherEvents.TOAST_VIEWED,
          scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
          identifierName: success
            ? EventIdentifierName.TOAST_REMOVE_ACCESS_SUCCESS
            : EventIdentifierName.TOAST_REMOVE_ACCESS_ERROR,
          pageName: EventPageName.USER_ACCESS,
          customProperties: {
            $toast_name: title,
          },
        })

      toast({
        type: success ? 'success' : 'error',
        title,
      })

      setIsSubmitting(false)
      setSelectedReason(undefined)
      onClose()
    }
  }

  return (
    <DialogPrimitives.Root open={isOpen} onOpenChange={open => !open && !isLoading && onClose()}>
      <DialogPrimitives.Portal>
        <DialogPrimitives.Overlay backdrop />

        <DialogPrimitives.Content
          size={2}
          title={`Tem certeza que deseja excluir o acesso de ${user?.first_name} ${user?.last_name}?`}
          description={
            user?.has_access_to_multiple_schools
              ? `Este usuário possui acesso a outras Unidades, esta exclusão se refere a Unidade ${currentSchool?.name}.`
              : ''
          }
          aria-describedby={undefined}
          actionButton={
            <Button
              color="error"
              size={2}
              loading={isSubmitting}
              disabled={!selectedReason}
              onClick={handleSubmit}
            >
              Sim, excluir
            </Button>
          }
          cancelButton={
            <Button variant="ghost" size={2} disabled={isLoading}>
              Voltar
            </Button>
          }
        >
          <Select
            label="Motivo da exclusão"
            placeholder="Selecione o motivo"
            size={3}
            fullWidth
            options={OPTIONS}
            value={selectedReason?.value}
            onValueChange={value => {
              const reason = OPTIONS.find(item => item.value === value)

              if (!reason) return

              setSelectedReason(reason)
            }}
          />
        </DialogPrimitives.Content>
      </DialogPrimitives.Portal>
    </DialogPrimitives.Root>
  )
}
