import { useQuery } from '@tanstack/react-query'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useApi } from '@/shared/hooks'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const useGetFidcReport = () => {
  const { school } = useSelectedSchool()
  const { api } = useApi()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  return useQuery({
    queryKey: ['fidc-report', school.id],
    queryFn: async () => {
      const { data } = await api.report.fetchFidcReport({ id: school.id })

      return {
        url: `${data.url}#font=Roboto&bordered=false&titled=false`,
      }
    },
    onSuccess: () => {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.FIDC_REPORT,
          name: EventDispatcherEvents.VIEW_FIDC_REPORT,
          action: 'file_download',
        })
    },
    onError: () => {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.FIDC_REPORT,
          name: EventDispatcherEvents.ERROR_LOADING_FIDC_REPORT,
          action: 'callback',
        })
    },
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}
