import { Box } from '@material-ui/core'
import { Chip, Link } from '@olaisaac/design-system'
import { SelectedFiltersProps } from './types'
import {
  ActiveFiltersContainer,
  ActiveFiltersText,
  ClearFiltersContainer,
  Container,
} from './styles'
import { filtersToShow } from '../../utils/filtersToShow'
import { updatedFiltersToRemove } from '../../utils/updatedFiltersToRemove'

const SHOW_MORE_CHIP_COUNT = 8

export const ActiveFilters: React.FC<SelectedFiltersProps> = ({
  filters,
  activeFilters,
  updateFilters,
  clearFilters,
}) => {
  const activeFiltersArray = filtersToShow(activeFilters)

  const handleRemoveFilter = (value: string) => {
    const updatedFilters = updatedFiltersToRemove(filters, value)

    updateFilters(updatedFilters)
  }

  return (
    <Container>
      <ActiveFiltersText>Filtros ativos:</ActiveFiltersText>
      <ActiveFiltersContainer>
        {activeFiltersArray?.slice(0, SHOW_MORE_CHIP_COUNT)?.map(({ label, value }) => (
          <Box key={value} mx={1}>
            <Chip variation="blue" label={label} onDelete={() => handleRemoveFilter(value)} />
          </Box>
        ))}

        {activeFiltersArray?.length > SHOW_MORE_CHIP_COUNT && (
          <Box>
            <Chip
              variation="blue"
              label={`+${activeFiltersArray?.length - SHOW_MORE_CHIP_COUNT}`}
            />
          </Box>
        )}
      </ActiveFiltersContainer>
      <ClearFiltersContainer>
        <Link onClick={() => clearFilters()}>Limpar</Link>
      </ClearFiltersContainer>
    </Container>
  )
}
