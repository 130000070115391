import { isIsaacPaySchool } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const useIsCampaignEnabled = () => {
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const isCampaignFFEnabled = useUnleashFlag(UnleashFlags.ENABLE_MATRICULAS_CAMPAIGN)

  const { school } = useSelectedSchool()
  const isIsaacPay = isIsaacPaySchool(school)

  return !isIsaacPay && !isIntegratedSchool && isCampaignFFEnabled
}
