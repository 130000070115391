import styled from 'styled-components'
import { Box, Typography } from '@material-ui/core'
import { colors, fontSize, fontWeight, lineHeight, spacing } from '@gravity/tokens'

export const CurrencySign = styled.span`
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 700;
  color: inherit;
  padding-right: 0.5rem;
`

export const Amount = styled(Typography)`
  font-size: ${fontSize['2xl']};
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.tight};
`

export const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${spacing[8]};
  border: 1px solid ${colors['colors-border-neutral-3']};
  border-radius: ${spacing[4]};
  gap: ${spacing[14]};
`

export const BoxValueStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacing[2]};
`

export const ValueLabelStyled = styled(Typography)`
  font-size: ${fontSize.sm};
  line-height: ${lineHeight.normal};
  color: ${colors['colors-text-main-3']};
`

export const TitleStyled = styled(Typography)`
  font-size: ${fontSize.lg};
  line-height: ${lineHeight.snug};
  font-weight: ${fontWeight.medium};
`
