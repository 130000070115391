import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[6]};
  padding: ${props => props.theme.gravity.spacing[6]};
  background-color: ${props => props.theme.gravity.colors['colors-background-neutral-1']};
  border: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-3']};
  border-radius: ${props => props.theme.gravity.borderRadius[3]};

  .header {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gravity.spacing[6]};
  }

  .text-content .title {
    display: inline-block;
    margin-bottom: ${props => props.theme.gravity.spacing[2]};
  }

  .color-secondary {
    color: ${props => props.theme.gravity.colors['colors-text-main-3']};
  }
`

export const RichTextContainer = styled.div`
  p,
  div,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.gravity.colors['colors-text-main-3']};
    line-height: 1.5;
  }

  p + *,
  div + * {
    margin-top: ${props => props.theme.gravity.spacing[4]};
  }

  ol,
  ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin: ${props => `${props.theme.gravity.spacing[6]} 0 0 ${props.theme.gravity.spacing[3]}`};
  }

  li {
    list-style-type: disc;
    margin-left: ${props => props.theme.gravity.spacing[5]};
  }

  b {
    font-weight: 500;
  }

  a {
    color: ${props => props.theme.gravity.colors['colors-background-accent-1']};
  }
`
