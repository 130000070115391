import { Box, Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { Typography } from '@olaisaac/design-system'

import theme from '@/shared/theme'

import { Title } from './styles'

type PayoutTransfersSectionCategoryTitleProps = {
  children: string
  description?: string
  tooltip?: string
}

const ICON_COLOR = theme.primitiveTokens.colors.gray[50]

export const PayoutTransfersSectionCategoryTitle = ({
  children,
  tooltip,
  description,
}: PayoutTransfersSectionCategoryTitleProps) => {
  return (
    <Box margin="2rem 0" maxWidth="15rem">
      <Title>
        {children}

        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoOutlined style={{ fontSize: '1rem', color: ICON_COLOR, marginLeft: '0.25rem' }} />
          </Tooltip>
        )}
      </Title>

      {description && (
        <Box mt="0.25rem">
          <Typography variation="bodySmall" color="secondary">
            {description}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
