import styled from 'styled-components'
import { FormControlLabel, Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'

export const StyledTypography = styled(Typography).attrs({ withoutMargin: true })`
  overflow-wrap: break-word;
`

export const GuardianFormTitle = styled(StyledTypography)`
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #414141;
  margin-bottom: 24px;
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-items: start;
  margin-left: 6px;
`

export const ButtonsBox = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 44px;
  border-top: 1px solid #f0f0f0;
  margin-right: -44px;
  margin-left: -65px;
`

export const ChangeGuardianBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: calc(0.666 * 100%);
`
