import { useEffect } from 'react'
import { useNotificationsQuery, useUnreadNotificationsCountQuery } from '../../hooks'
import { NotificationRequestDTO } from '../../services/types'

export const useLoadNotifications = (queryParams: NotificationRequestDTO, isEnabled: boolean) => {
  const {
    data: notifications,
    isFetching: isNotificationsLoading,
    isError: isNotificationsError,
    refetch: refetchNotifications,
  } = useNotificationsQuery(queryParams, {
    enabled: false,
    cacheTime: 0, // It always requests when menu popover is open
  })

  const {
    data: unreadNotificationsCount,
    isFetching: isCountLoading,
    isError: isCountError,
    refetch: refetchUnreadNotificationsCount,
  } = useUnreadNotificationsCountQuery(queryParams, {
    enabled: false,
    cacheTime: 0, // It always requests when menu popover is open
  })

  const remainingNotificationsCount =
    unreadNotificationsCount && notifications ? unreadNotificationsCount - notifications?.length : 0

  // Refetch only requests with error to avoid unnecessary requests
  const refetch = () => {
    if (isNotificationsError) {
      refetchNotifications()
    }

    if (isCountError) {
      refetchUnreadNotificationsCount()
    }
  }

  useEffect(() => {
    if (isEnabled) {
      refetchNotifications()
      refetchUnreadNotificationsCount()
    }
  }, [isEnabled])

  return {
    data: {
      notifications,
      remainingNotificationsCount,
    },
    isLoading: isNotificationsLoading || isCountLoading,
    isError: isCountError || isNotificationsError,
    refetch,
  }
}
