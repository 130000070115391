import { useContext } from 'react'
import { ReceivablesAgglutinationStore } from 'src/escolas/contexts/receivablesAgglutinationContext'

const useAgglutination = () => {
  const {
    addReceivable,
    baseAmount,
    clearCombinedReceivables,
    combinedReceivables,
    discount,
    dueDate,
    finalAmount,
    isCombining,
    removeReceivable,
    setDiscount,
    setDueDate,
    setIsCombining,
  } = useContext(ReceivablesAgglutinationStore)
  return {
    addReceivable,
    baseAmount,
    clearCombinedReceivables,
    combinedReceivables,
    discount,
    dueDate,
    finalAmount,
    isCombining,
    removeReceivable,
    setDiscount,
    setDueDate,
    setIsCombining,
  }
}

export default useAgglutination
