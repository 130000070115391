import { Redirect } from 'react-router-dom'

type RestrictedRouteProps = {
  canAccess: () => boolean
  Component?: React.ComponentType<any>
}

export const RestrictedRoute = ({ canAccess, Component }: RestrictedRouteProps) => {
  if (!canAccess()) {
    return <Redirect to="/404" />
  }

  return Component ? <Component /> : null
}
