import { Table } from '@gravity/table'
import * as Styled from './styles'
import type { CustomizationTableViewProps, TableRowProps } from './types'
import { CustomizationStepActionTable } from '../CustomizationStepActionTable'

export const View = ({
  campaignStudentsData,
  columnHeaders,
  rows,
  isLoading,
  setAddCustomTuitionDialogState,
  pagination,
  updatePaginationValue,
  excludedEnrollmentIds,
  noDataMessage,
}: CustomizationTableViewProps) => {
  if (!rows?.length && !isLoading) {
    return <Styled.EmptyStateContainer>{noDataMessage}</Styled.EmptyStateContainer>
  }
  return (
    <>
      <Styled.TableWrapper>
        <Table.Root>
          <Table.Head>
            {columnHeaders.map(({ label, key, minWidth }) => (
              <Styled.TableHeaderCell key={key} name={key} style={{ minWidth }}>
                {label}
              </Styled.TableHeaderCell>
            ))}
          </Table.Head>
          <Table.Body>
            {isLoading ? (
              <>
                <Table.Row>
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                </Table.Row>

                <Table.Row>
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                </Table.Row>

                <Table.Row>
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                  <Table.LoadingCell />
                </Table.Row>
              </>
            ) : (
              rows.map((row: TableRowProps) => {
                const isDisabled = excludedEnrollmentIds.includes(row.enrollmentID)
                return (
                  <Styled.TableRow disabled={false} key={row.key}>
                    <Table.TextCell disabled={isDisabled}>
                      {row.columns.student_name}
                    </Table.TextCell>
                    <Table.TextCell disabled={isDisabled}>
                      {row.columns.tuition_quantity}
                    </Table.TextCell>
                    <Table.TextCell disabled={isDisabled}>
                      {row.columns.tuition_amount_with_discount}
                    </Table.TextCell>
                    <Table.TextCell disabled={isDisabled}>
                      {row.columns.total_amount}
                    </Table.TextCell>
                    <CustomizationStepActionTable
                      enrollmentID={row.enrollmentID}
                      isStudentRemovedFromBulkAdd={excludedEnrollmentIds.includes(row.enrollmentID)}
                      setAddCustomTuitionDialogState={setAddCustomTuitionDialogState}
                      studentName={row.columns.student_name}
                    />
                  </Styled.TableRow>
                )
              })
            )}
          </Table.Body>
          {campaignStudentsData?.pagination?.total && (
            <Table.Pagination
              page={pagination.page}
              itemsPerPage={pagination.itemsPerPage}
              total={campaignStudentsData?.pagination.total}
              onChangePage={newPage => {
                updatePaginationValue('page', newPage, true)
                window.scrollTo(0, 0)
              }}
              onChangeItemsPerPage={newItensPerPage => {
                updatePaginationValue('itemsPerPage', newItensPerPage, true)
              }}
            />
          )}
        </Table.Root>
      </Styled.TableWrapper>
    </>
  )
}
