import { Box } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import ContractYearPopover from 'src/escolas/components/contract/ContractYearPopover'
import { TitleProps } from './types'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'

export const Title = (props: TitleProps) => {
  const isSelectCyclesEnabled = useUnleashFlag(UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES)

  const { referenceYear, boxAlignItems = 'end' } = props

  const label = isSelectCyclesEnabled ? 'Contratos' : `Contratos ${referenceYear}`

  return (
    <Box display="flex" justifyContent="space-between" alignItems={boxAlignItems}>
      <Box display="flex" justifyContent="left" alignItems="center">
        <Typography variation="headlineDesktopXsmall">{label}</Typography>

        {!isSelectCyclesEnabled && <ContractYearPopover />}
      </Box>
    </Box>
  )
}
