import styled from 'styled-components'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import { Text } from '@gravity/text'
import { IconButton as IconButtonPrimitive } from '@gravity/icon-button'
import { colors, fontSize, spacing } from '@gravity/tokens'

export const Wrapper = styled.div`
  padding: ${spacing[8]} 0;
  display: flex;
  flex-direction: column;
  gap: ${spacing[4]};
`

export const WrapperSeparator = styled.span`
  flex: 1;
`

export const DatepickerWrapper = styled.div`
  display: flex;
`

export const DatepickerTrigger = styled(Datepicker.Trigger)`
  display: inline-flex;
  flex-direction: row-reverse;
  cursor: pointer;
  min-width: unset;

  > span {
    color: ${colors['colors-text-main-1']};
    letter-spacing: 1px;
  }

  // calendar icon
  > span:first-of-type {
    margin-left: 0;
    margin-right: ${spacing[2]};

    > svg {
      color: #00259ecb;
    }
  }

  // hide arrow icon
  > span:last-of-type {
    display: none;
  }

  outline-width: 2px;
`

export const Heading = styled(Text)`
  margin-top: ${spacing[4]};
  font-size: ${fontSize.lg};
`

type IconButtonProps = {
  disabled?: boolean
  side?: 'left' | 'right'
}

export const IconButton = styled(IconButtonPrimitive)<IconButtonProps>`
  ${props =>
    props.side === 'left' &&
    `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `}

  ${props =>
    props.side === 'right' &&
    `
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `}

  ${props =>
    props.side !== 'left' &&
    props.side !== 'right' &&
    `
    border-radius: 0;
  `}
   
  :focus {
    z-index: 1;
  }

  & + & {
    margin-left: -2px;
  }

  &[disabled] {
    z-index: -1;
  }
`
