import { Text } from '@gravity/text'
import { ReactElement } from 'react'
import styled from 'styled-components'

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`

const TitleIconContainer = styled.div`
  padding-right: 16px;
`

const MainText = styled.div`
  display: flex;
  flex-direction: column;
`

export const IrregularityTableTitle = ({ icon, title }: { icon: ReactElement; title: string }) => {
  return (
    <TitleContainer>
      <TitleIconContainer>{icon}</TitleIconContainer>
      <Text variant="subtitle-medium">{title}</Text>
    </TitleContainer>
  )
}

export const TextWithDescription = ({
  description,
  text,
}: {
  description: string
  text: string
}) => {
  return (
    <MainText>
      <Text variant="body-1-regular">{text}</Text>
      <Text variant="body-2-regular">{description}</Text>
    </MainText>
  )
}
