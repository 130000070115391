import { FC } from 'react'
import {
  Pagination,
  Table,
  TableColumns,
  TableRowParams,
  Typography,
} from '@olaisaac/design-system'
import { usePagination } from 'src/shared/hooks/usePagination'
import { ListGuardiansTableProps, Row, Rows } from './types'
import { renderDebtStatus } from '@/escolas/components/contract/ContractDetails'
import { mapDataToRows } from './utils/mapDataToRows'
import { formatCPF } from '@/shared/utils'
import { useNavigation } from 'src/escolas/hooks'
import { useHistory } from 'react-router-dom'
import { StyledTableHeader, StyledTableSortLabel, TableContainer } from './styles'
import { renderStudents } from './utils/renderStudents'
import { DEFAULT_PER_PAGE_OPTIONS } from '../../../constants'
import Box from '@material-ui/core/Box'
import theme from '@/shared/theme'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { ArrowDropUpRounded } from '@material-ui/icons'
import { SortOrderEnum } from '../../../types'
import { useGuardianNavigation } from '@/modules/guardians/hooks/useGuardianNavigation'

const ListGuardiansTable: FC<ListGuardiansTableProps> = ({
  guardians,
  isLoading,
  total,
  sortOrder,
  setSortOrder,
}) => {
  const history = useHistory()
  const { getNavigationUrl } = useNavigation()
  const { defaultGuardianEntryPage } = useGuardianNavigation()

  const { pagination, updatePaginationValue } = usePagination(1, 20)

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleSortOrder = () => {
    setSortOrder?.(sortOrder === SortOrderEnum.ASC ? SortOrderEnum.DESC : SortOrderEnum.ASC)
  }

  const columns: TableColumns = [
    {
      headerName: (
        <StyledTableSortLabel
          active
          direction={sortOrder === SortOrderEnum.ASC ? 'asc' : 'desc'}
          IconComponent={ArrowDropUpRounded}
          onClick={() => handleSortOrder()}
        >
          <StyledTableHeader style={{ cursor: 'pointer' }}>Responsável</StyledTableHeader>
        </StyledTableSortLabel>
      ),
      field: 'name',
      renderCell: value => <Typography variation="bodySmall">{value}</Typography>,
    },
    {
      headerName: <StyledTableHeader>CPF</StyledTableHeader>,
      field: 'tax_id',
      valueFormatter: value => formatCPF(value),
      renderCell: value => <Typography variation="bodySmall">{value}</Typography>,
    },
    {
      headerName: <StyledTableHeader>Aluno</StyledTableHeader>,
      field: 'students',
      renderCell: value => renderStudents(value),
    },
    {
      headerName: <StyledTableHeader>Situação financeira</StyledTableHeader>,
      field: 'debt_status',
      renderCell: value => renderDebtStatus(value),
    },
  ]

  const rows: Rows = isLoading ? [] : mapDataToRows(guardians)

  const TableEmptyState = () => (
    <Typography variation="subtitleDesktopMedium" color="secondary">
      Nenhum responsável encontrado
    </Typography>
  )

  const redirectToGuardianPage = (params: TableRowParams<Row>) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.SIDE_MENU,
        name: EventDispatcherEvents.ACCESS_GUARDIAN_PAGE,
        action: 'click',
      })

    history.push(
      getNavigationUrl({
        path: `/responsaveis/${params.row.id}/${defaultGuardianEntryPage}`,
      })
    )
  }

  return (
    <>
      <TableContainer theme={theme}>
        <Table
          data-testid="guardiansListTable"
          columns={columns}
          emptyRender={TableEmptyState()}
          isLoading={isLoading}
          onRowClick={redirectToGuardianPage}
          rows={rows || []}
        />
      </TableContainer>

      <Box my={2} px={2}>
        <Pagination
          data-test-id="guardiansListTablePagination"
          currentPage={pagination.page}
          itensPerPage={pagination.itemsPerPage}
          itensPerPageOptions={DEFAULT_PER_PAGE_OPTIONS}
          totalItens={total ?? 0}
          onPageChange={newPage => {
            updatePaginationValue('page', newPage)
          }}
          onItensPerChangeChange={newItemsPerPage => {
            updatePaginationValue('itemsPerPage', newItemsPerPage)
          }}
        />
      </Box>
    </>
  )
}

export default ListGuardiansTable
