import { normalizeText } from '@/shared/utils/normalizeText'

import type { FilterOptions as TuitionPayoutReportFilterOptions } from '@/modules/report/pages/TuitionPayoutReport/components/TuitionPayoutReportFilterDrawer/types'
import type { FilterOptions as IsaacPayPayoutReportFilterOptions } from '../../types'
import type { OptionType } from '@/escolas/components/CollapsibleFilterGroup/types'

/**
 * Utility function to filter options by text
 *
 * @param list List to be filtered
 * @param text Text term to be used
 *
 * @return Filtered options
 */
const filterOptions = (list: OptionType[], text: string) => {
  return list.filter(({ label }) => {
    const normalizedLabel = normalizeText(label)
    const normalizedText = normalizeText(text)

    return normalizedLabel.includes(normalizedText)
  })
}

export const filterOptionsByText = <
  T extends TuitionPayoutReportFilterOptions | IsaacPayPayoutReportFilterOptions
>(
  searchText: string,
  filters: T
) => {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: filterOptions(value, searchText),
    }
  }, {} as T)
}
