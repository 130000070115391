import { FiltersContractsFormType } from '../components/GuardianDetailsContractsFilter/types'
import { AllFiltersOptionsType, ContractsFiltersType } from '../types'

/**
 * Transform a group of filters by the API to be used in ActiveFilters component.
 *
 * @param filters Filters by the API.
 * @param allFiltersOptions Options of filters by the API.
 *
 * @returns Filters in the format { label, value }.
 */
export const transformFilters = (
  filters: Partial<ContractsFiltersType>,
  allFiltersOptions: AllFiltersOptionsType
): FiltersContractsFormType => {
  const transformedFilters: FiltersContractsFormType = {
    debt_statuses: [],
    status: undefined,
    product_ids: [],
    reference_years: [],
    negotiation_id: undefined,
  }

  if (filters.status) {
    transformedFilters.status = allFiltersOptions.contractStatus.find(
      option => option.value === filters.status
    )
  }

  if (filters.debt_statuses) {
    transformedFilters.debt_statuses = filters.debt_statuses.map(value => {
      const option = allFiltersOptions.debtStatus.find(option => option.value === value)
      return option || { label: '', value }
    })
  }

  if (filters.reference_years) {
    transformedFilters.reference_years = filters.reference_years.map(value => {
      const option = allFiltersOptions.year.find(option => option.value === value)
      return option || { label: '', value }
    })
  }

  if (filters.product_ids) {
    transformedFilters.product_ids = filters.product_ids.map(value => {
      const option = allFiltersOptions.products.find(option => option.value === value)
      return option || { label: '', value }
    })
  }

  if (filters.negotiation_id) {
    const label = `Negociação #${filters.negotiation_id.substring(0, 6).toUpperCase()}`
    transformedFilters.negotiation_id = {
      label,
      value: filters.negotiation_id,
    }
  }

  return transformedFilters
}
