import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import envConfig from '@/config'
import MuiTypography from '@material-ui/core/Typography'
import ConfirmationDialog, {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/escolas/components/modal/ConfirmationDialog'
import {
  formatCentsToReal,
  date2PTFormat,
  isDayBeforeToday,
  isDayToday,
  dateToOnlyDateISO,
  formatDate,
} from '@/shared/utils'
import {
  Contract,
  Receivable,
  ReceivableStatuses,
  PathParams,
  ProcessedInstallment,
  DebtStatus,
  Installment,
  InstallmentType,
  InstallmentTypeLabel,
  InstallmentStatuses,
  ContractCancellationReason,
  ContractStatus,
  ContractResponse,
} from '@/shared/interfaces'
import { UnleashFlags, useApi, useJWT, useUnleashFlag, useSnackbar } from '@/shared/hooks'
import { assoc, includes } from 'ramda'
import GuardianCard from '@/escolas/components/contract/GuardianCard'
import StatusBadge, { StatusBadgeColor } from '@/escolas/components/StatusBadge'
import Table, { TableColumn, TableColumns, TableRowParams } from '@/escolas/components/Table'
import { makeAgglutinationCheckbox as makeCheckbox } from '@/escolas/components/Table/AgglutinationCheckbox'
import ContractTableActions from '@/escolas/components/contract/ContractTableActions'
import { useAgglutination, useContract, useNavigation } from '@/escolas/hooks'
import { Button, ButtonGroup, Typography } from '@olaisaac/design-system'
import ContractDetailsFailureDialog from './ContractDetailsFailureDialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import AgglutinationDrawer from '@/modules/guardians/InstallmentsDrawerContainer/AgglutinationDrawer/AgglutinationDrawer'
import { useForm } from 'react-hook-form'
import { formOfPaymentOptions } from '@/modules/guardians/InstallmentsDrawerContainer/AgglutinationDrawer/AgglutinationPayment'
import CancellationDrawer from '@/escolas/components/contract/CancellationDrawer'
import {
  ContractEditDiscountsDrawer,
  ContractEditDueDayDrawer,
} from '@/escolas/components/contract/ContractEditDrawer'

import { InstallmentChange } from './ContractEditDrawer/ContractEditDueDayDrawer'
import TableFilter, { TableFilterOptions, TableFilterParam } from '@/escolas/components/TableFilter'
import { parseUpdatedInstallments } from './ContractEditDrawer/utils'
import styled from 'styled-components'
import RouterBreadcrumbs from '@/escolas/components/RouterBreadcrumbs'
import useInstallments from '@/escolas/hooks/useInstallments'
import { PUBLIC_DOMAIN } from '@/utils/api'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { ContractCancellationForm } from './CancellationDrawer/CancellationDrawerDefaultContent'
import { BufferInstallmentStatuses } from '@/shared/interfaces/bufferInstallment'
import { SchoolName } from '@/escolas/components/SchoolName'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

const ContainerWithActionDrawer = styled(Grid)<{ $isActionDrawerOpen?: boolean }>(
  ({ $isActionDrawerOpen }) => ({
    transition:
      'flex-basis 0.3s cubic-bezier(0.42,0.29,0.39,0.83), max-width 0.3s cubic-bezier(0.42,0.29,0.39,0.83)',
    ...($isActionDrawerOpen
      ? { flexGrow: 0, maxWidth: '66.666667%', flexBasis: '66.666667%' }
      : {}),
  })
)

export const paramsDict: PartialRecord<
  ReceivableStatuses | BufferInstallmentStatuses,
  { color: StatusBadgeColor; text: string }
> = {
  AGGLUTINATED: { color: 'grey', text: 'Aglutinada' },
  CANCELED: { color: 'grey', text: 'Cancelada' },
  DUE_TODAY: { color: 'warning', text: 'Vence hoje' },
  OPEN: { color: 'primary', text: 'A vencer' },
  OVERDUE: { color: 'error', text: 'Vencida' },
  PAID: { color: 'success', text: 'Paga' },
  PAID_AND_CANCELED: { color: 'grey', text: 'Paga e cancelada' },
  RENEGOTIATED: { color: 'grey', text: 'Renegociada' },
  ON_HOLD: { color: 'grey', text: 'Aguardando' },
}

export const renderStatus = (status: ReceivableStatuses) => (
  <>
    <StatusBadge color={paramsDict[status]?.color} />
    <Box ml={1}>
      <MuiTypography variant="body2">{paramsDict[status]?.text}</MuiTypography>
    </Box>
  </>
)

export const debtParamsDict: PartialRecord<
  DebtStatus,
  { color: StatusBadgeColor; text: string }
> = {
  WARNING: { color: 'warning', text: 'Atenção' },
  UP_TO_DATE: { color: 'success', text: 'Em dia' },
  PENDING: { color: 'grey', text: 'Aguardando' },
  OVERDUE: { color: 'error', text: 'Com pendências' },
}

export const renderDebtStatus = (status: DebtStatus) => {
  const text = debtParamsDict[status]?.text ?? '-'
  return (
    <>
      <StatusBadge color={debtParamsDict[status]?.color} />
      <Box ml={1}>
        <Typography variation="bodySmall" color="secondary">
          {text}
        </Typography>
      </Box>
    </>
  )
}

export const contractStatusParamsDict: PartialRecord<
  ContractResponse['status'],
  { color: StatusBadgeColor; text: string }
> = {
  CANCELED: { color: 'error', text: 'Inativo' },
  OPEN: { color: 'success', text: 'Ativo' },
  PENDING_GUARDIAN_APPROVAL: { color: 'grey', text: 'Pendente' },
}

export const renderContractStatus = (status: ContractStatus) => {
  return (
    <>
      <StatusBadge color={contractStatusParamsDict[status]?.color} />
      <Box ml={1}>
        <Typography variation="bodySmall" color="secondary">
          {contractStatusParamsDict[status]?.text}
        </Typography>
      </Box>
    </>
  )
}

const StyledTypography = styled(MuiTypography)`
  margin-top: 15px;
`

const getReceivableStatus = (
  receivable: Receivable,
  installmentStatus: InstallmentStatuses
): ReceivableStatuses => {
  if (
    receivable.status === ReceivableStatuses.PAID &&
    installmentStatus === InstallmentStatuses.CANCELED
  ) {
    return ReceivableStatuses.PAID_AND_CANCELED
  }
  if (receivable.status !== ReceivableStatuses.OPEN) {
    return receivable.status
  }
  if (isDayBeforeToday(dateToOnlyDateISO(receivable.due_date))) {
    return ReceivableStatuses.OVERDUE
  }
  if (isDayToday(dateToOnlyDateISO(receivable.due_date))) {
    return ReceivableStatuses.DUE_TODAY
  }
  return receivable.status
}

const getProcessedInstallmentId = (activeReceivable: Receivable, isSplitAgglutinated: boolean) => {
  if (isSplitAgglutinated) {
    return `installment::${activeReceivable?.installment_id}`
  } else {
    return activeReceivable?.id
  }
}

const buildProcessedInstallment = (
  receivable: Receivable,
  index: number,
  array: any[],
  children?: ProcessedInstallment[],
  key?: string
): ProcessedInstallment => {
  return {
    amount: receivable?.current_amount,
    children,
    due_date: receivable?.due_date,
    due_month: dayjs(receivable?.installment?.due_date).utc(),
    id: receivable?.id,
    installment_id: receivable?.installment_id,
    key: key || receivable?.id,
    orderReference: `${index + 1} de ${array.length}`,
    status: receivable?.status,
    type: receivable?.installment?.type,
  }
}

const recurBuildProcessedInstallment = (
  receivable: Receivable,
  processedReceivables: Receivable[],
  idx: number,
  arr: any[],
  isSplitAgglutinated?: boolean
): ProcessedInstallment => {
  const id = getProcessedInstallmentId(receivable, isSplitAgglutinated)

  return buildProcessedInstallment(
    {
      ...receivable,
      id,
    },
    idx,
    arr,
    processedReceivables
      ?.filter(filterReceivable =>
        filterReceivable?.original_receivables?.some(someReceivable => {
          if (!receivable) {
            return false
          }
          let isCheckoutPaid = false

          if (
            someReceivable.status === ReceivableStatuses.CHECKOUTED &&
            filterReceivable.status === ReceivableStatuses.PAID
          ) {
            const checkoutedReceivable = processedReceivables.find(
              pr => pr.id === someReceivable.id
            )
            if (
              checkoutedReceivable &&
              checkoutedReceivable.original_receivables &&
              checkoutedReceivable.original_receivables.length > 0
            ) {
              isCheckoutPaid = checkoutedReceivable.original_receivables.some(
                or => or.id === receivable.id
              )
            }
          }

          return (
            (someReceivable.id === receivable.id || isCheckoutPaid) &&
            filterReceivable.status !== ReceivableStatuses.CHECKOUTED
          )
        })
      )
      .map((mapReceivable, mapIndex, mapArr) =>
        recurBuildProcessedInstallment(mapReceivable, processedReceivables, mapIndex, mapArr)
      ),
    `${receivable?.id}::split::${idx}`
  )
}

const isReceivableNotRenegotiatedOrAgglutinated = (receivable: Receivable): boolean => {
  return ![ReceivableStatuses.RENEGOTIATED, ReceivableStatuses.AGGLUTINATED].includes(
    receivable.status
  )
}

const isReceivableRenegotiatedOrAgglutinated = (receivable: Receivable): boolean => {
  return [ReceivableStatuses.RENEGOTIATED, ReceivableStatuses.AGGLUTINATED].includes(
    receivable.status
  )
}

const makeProcessedInstallment = (
  { receivables, status }: { receivables?: Receivable[]; status?: InstallmentStatuses },
  idx: number,
  arr: Installment[]
): ProcessedInstallment | ProcessedInstallment[] => {
  let processedReceivables = receivables
    ?.filter(rec => {
      if (rec.base_amount < 0) {
        return false
      }
      return includes(rec.status, [
        ReceivableStatuses.OPEN,
        ReceivableStatuses.PAID,
        ReceivableStatuses.OVERDUE,
        ReceivableStatuses.DUE_TODAY,
        ReceivableStatuses.AGGLUTINATED,
        ReceivableStatuses.RENEGOTIATED,
        ReceivableStatuses.CHECKOUTED,
      ])
    })
    ?.map(rec => assoc('status', getReceivableStatus(rec, status), rec))

  const activeReceivables = processedReceivables?.filter(({ status }) =>
    includes(status, [
      ReceivableStatuses.OPEN,
      ReceivableStatuses.PAID,
      ReceivableStatuses.OVERDUE,
      ReceivableStatuses.DUE_TODAY,
      ReceivableStatuses.PAID_AND_CANCELED,
    ])
  )

  const isCanceledWithNoActiveReceivables =
    status === InstallmentStatuses.CANCELED && activeReceivables.length === 0

  if (isCanceledWithNoActiveReceivables) {
    processedReceivables = [
      receivables
        .filter(rec => rec.status === ReceivableStatuses.CANCELED)
        .sort((a, b) => (dayjs(b.created_at).isBefore(dayjs(a.created_at)) ? -1 : 1))[0], // get the most recent receivable only
    ]
  }

  const agglutinatedReceivableIndex = processedReceivables?.findIndex(
    r => r?.status === ReceivableStatuses.AGGLUTINATED
  )

  const hasAgglutinatedReceivable = agglutinatedReceivableIndex !== -1
  const isSplitReceivable = activeReceivables?.length > 1

  const sortedProcessedReceivables = [...processedReceivables]?.sort((a, b) =>
    dayjs(b.created_at).isBefore(dayjs(a.created_at)) ? 1 : -1
  )
  const oldestRenegotiatedReceivableIndex = sortedProcessedReceivables?.findIndex(
    r => r?.status === ReceivableStatuses.RENEGOTIATED
  )
  const hasRenegotiatedReceivable = agglutinatedReceivableIndex !== -1

  const isSimpleRenegotiation =
    processedReceivables.length >= 2 &&
    processedReceivables.filter(isReceivableNotRenegotiatedOrAgglutinated).length === 1 &&
    processedReceivables.filter(isReceivableRenegotiatedOrAgglutinated).length ===
      processedReceivables.length - 1
  const hasMultipleReceivablesWithNoRenegotiation = isSplitReceivable && !hasRenegotiatedReceivable
  if (isSimpleRenegotiation || hasMultipleReceivablesWithNoRenegotiation) {
    return processedReceivables.map(receivable => buildProcessedInstallment(receivable, idx, arr))
  }

  const isSplitAgglutinated = isSplitReceivable && hasAgglutinatedReceivable

  if (hasAgglutinatedReceivable) {
    // Case: split simples (split dividido em uma parcela) que foi aglutinado com uma parcela de fora
    if (processedReceivables[agglutinatedReceivableIndex]?.original_receivables?.length > 0) {
      const original = processedReceivables[agglutinatedReceivableIndex]?.original_receivables[0]

      const hasOpen = activeReceivables?.length > 0

      if (original?.status === ReceivableStatuses.RENEGOTIATED && !hasOpen) {
        return buildProcessedInstallment(
          processedReceivables[agglutinatedReceivableIndex],
          idx,
          arr
        )
      }
    }
  }

  if (hasAgglutinatedReceivable && processedReceivables.length === 1) {
    // Problema: ao incluir receivables aglutinados em activeReceivables,
    // a funcao entenderia que o receivable que recebeu a aglutinacao eh um split (L176).
    // Ainda, ao nao inclui-los nessa lista, caso o receivable fosse um aglutinado,
    // ele nao mostraria o receivable, ja que a funcao usa o activeReceivables como default pra pegar os dados.
    return buildProcessedInstallment(processedReceivables[agglutinatedReceivableIndex], idx, arr)
  }

  const activeReceivable = isCanceledWithNoActiveReceivables
    ? processedReceivables?.[0]
    : activeReceivables?.[0]

  if (isSplitReceivable) {
    return recurBuildProcessedInstallment(
      sortedProcessedReceivables[oldestRenegotiatedReceivableIndex],
      processedReceivables,
      idx,
      arr,
      isSplitAgglutinated
    )
  }

  return buildProcessedInstallment(activeReceivable, idx, arr)
}

export const processInstallments = (resp: Contract): ProcessedInstallment[] =>
  resp?.installments?.map(makeProcessedInstallment).flat()

export let installmentsTableColumns: TableColumn[]

const makeInstallmentsTableColumns = () => {
  const InstallmentTypeMap = {
    [InstallmentType.ENROLLMENT]: InstallmentTypeLabel.ENROLLMENT,
    [InstallmentType.TUITION]: InstallmentTypeLabel.TUITION,
    // Isaac pay deve ser lido como mensalidade
    [InstallmentType.ISAACPAY]: InstallmentTypeLabel.TUITION,
  }

  installmentsTableColumns = [
    { headerName: 'Parcela', field: 'orderReference' },
    {
      headerName: 'Competência',
      field: 'due_month',
      valueFormatter: (value: string) => formatDate(value, 'MMM YYYY'),
    },
    {
      headerName: 'Vencimento',
      field: 'due_date',
      valueFormatter: (value: string) => date2PTFormat(dayjs(value).toISOString()),
    },
    {
      headerName: 'Valor',
      field: 'amount',
      valueFormatter: (value: string) => formatCentsToReal(value),
    },
    {
      headerName: 'Tipo',
      field: 'type',
      valueFormatter: (value: string) =>
        InstallmentTypeMap[value as keyof typeof InstallmentTypeMap],
    },
    {
      headerName: 'Situação',
      field: 'status',
      renderCell: (value: string) => renderStatus(ReceivableStatuses[value as ReceivableStatuses]),
    },
  ]

  return installmentsTableColumns
}

export const tableHeaderCheckboxColumn: TableColumn = { field: '', isComponent: true, small: true }
export type AgglutinationMetaData = {
  discount: cents
  due_date: Dayjs
  payment_method: string[]
}

export type InstallmentsFilters = {
  name: TableFilterParam<string>
  status: TableFilterParam<InstallmentType>
}

const defaultFilters: InstallmentsFilters = {
  name: { value: '' },
  status: { label: null, value: null },
}

const NoInstallmentsMessage = () => (
  <Typography
    style={{
      display: 'flex',
      fontSize: '24px',
      fontWeight: 400,
      justifyContent: 'center',
      marginTop: '64px',
    }}
  >
    Este contrato não possui parcelas criadas
  </Typography>
)

const ContractDetails = () => {
  const { isAdmin } = useJWT()
  const { schoolId } = useNavigation()
  const { api } = useApi()
  const { sendHotjarEvent } = useHotjar()
  const {
    addReceivable,
    clearCombinedReceivables,
    combinedReceivables,
    isCombining,
    removeReceivable,
    setIsCombining,
  } = useAgglutination()

  const isRevokedCancelContract = useUnleashFlag(UnleashFlags.B2BCOR_193_BLOCK_CONTRACT_REVOKE)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  /**
   * Caso o usuário não seja admin mas a escola tem permissão para cancelar contratos.
   */
  const isCancelContractEnabled = !isAdmin && !isRevokedCancelContract
  const [selectedReceivableId, setSelectedReceivableId] = useState<string>(null)
  const [orderReference, setOrderReference] = useState<string>(null)
  const { contract, setContract } = useContract()
  const {
    processedInstallments,
    setProcessedInstallments,
    setSelectedProcessedInstallment,
  } = useInstallments()

  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const [installmentsToChangeDueDay, setInstallmentsToChangeDueDay] = useState<
    Array<InstallmentChange>
  >([])
  const [installmentsToCancelContract, setInstallmentsToCancelContract] = useState<
    Array<Installment>
  >([])

  const [installmentsToEditDiscounts, setInstallmentsToEditDiscounts] = useState<
    Array<InstallmentChange>
  >([])

  const { contractId, schoolSlug } = useParams<PathParams>()
  const [tableColumns, setTableColumns] = useState<TableColumns>(installmentsTableColumns)
  const [showContractDrawer, setShowContractDrawer] = useState<boolean>(false)
  const [showCancellationDrawer, setShowCancellationDrawer] = useState<boolean>(false)
  const [showCancellationErrorMessage, setShowCancellationErrorMessage] = useState<boolean>(false)
  const [showConfirmCancellationMessage, setShowConfirmCancellationMessage] = useState<boolean>(
    false
  )
  const [isOnTheFirstPage, setIsOnTheFirstPage] = useState<boolean>(true)

  const [showEditContractDrawer, setShowEditContractDrawer] = useState<boolean>(false)
  const [showContractEditDiscountsDrawer, setShowContractEditDiscountsDrawer] = useState<boolean>(
    false
  )

  const [isFailureDialogOpen, setIsFailureDialogOpen] = useState<boolean>(false)
  const [isFailureFeedbackOpen, setIsFailureFeedbackOpen] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [filters, setFilters] = useState<InstallmentsFilters>(defaultFilters)
  const [filteredAndProcessedInstallments, setFilteredAndProcessedInstallments] = useState(
    processedInstallments
  )

  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(false)
  const [isPrintSlipsDialogVisible, setIsPrintSlipsDialogVisible] = useState<boolean>(false)

  const filterOptions: Array<TableFilterOptions> = [
    {
      filterName: 'status',
      filterTitle: 'tipo de cobrança',
      labelOptions: InstallmentTypeLabel,
    },
  ]

  const cancelContractForm = useForm<ContractCancellationForm>({
    mode: 'all',
    defaultValues: {
      installment_id: '',
      cancellation_description: '',
    },
  })

  const {
    handleSubmit: cancelContractHandleSubmit,
    reset: cancelContractReset,
  } = cancelContractForm

  const formAgglutination = useForm<AgglutinationMetaData>({
    mode: 'onChange',
  })
  const getContract = () => {
    api.contracts
      .get(contractId, {
        include_guardian: true,
        include_installments: true,
        include_invoice: true,
        include_product: true,
        include_receivables: true,
        include_signable_document: true,
        include_student: true,
      })
      .then(setContract)
      .then(() => setIsLoading(false))
  }

  const getEditDueDayInstallments = () => {
    if (!contract) return
    const dueDay = contract.due_day ? contract.due_day : contract.enrollment_due_day
    api.contracts
      .changeDueDayInfo(contract.id, {
        change_due_month: false,
        due_day: +dueDay,
        installment_id: contract.installments[0].id,
        start_month: dayjs().hour(0).minute(0).second(0).millisecond(0).utc().hour(0).format(),
      })
      .then(data => setInstallmentsToChangeDueDay(parseUpdatedInstallments(data)))
  }

  const getCancelContractInstallments = () => {
    if (!contract) return
    api.contracts.cancelContractInfo(contract.id).then(setInstallmentsToCancelContract)
  }

  const getEditDiscountsInstallments = () => {
    if (!contract) return
    api.contracts
      .bulkEditDiscountsInfo(contract.id, {
        installment_id: contract.installments[0].id,
        get_current_amount: true,
        discounts: [],
      })
      .then(data => setInstallmentsToEditDiscounts(parseUpdatedInstallments(data)))
  }

  useEffect(() => {
    setContract(null)
    setProcessedInstallments([])
    if (!contractId) return
    getContract()
  }, [contractId])

  useEffect(() => {
    if (contract?.installments.length === 0) return
    const processedInstallments = processInstallments(contract)
    setProcessedInstallments(processedInstallments)
    getEditDueDayInstallments()
    getEditDiscountsInstallments()
    getCancelContractInstallments()
  }, [contract])

  const {
    status: { value },
  } = filters

  useEffect(() => {
    const filteredInstallments = value
      ? processedInstallments?.filter(installment => installment.type === value)
      : processedInstallments
    setFilteredAndProcessedInstallments(filteredInstallments)
  }, [processedInstallments, value])

  const {
    HeaderCheckbox,
    RowCheckbox,
    rowDisabledWhileAgglutinating,
    isSelectable,
    isProcessingAgglutination,
    setProcessingAgglutination,
  } = makeCheckbox({
    addReceivable,
    clearCombinedReceivables,
    combinedReceivables,
    installments: contract?.installments,
    isCombining,
    processedInstallments,
    removeReceivable,
  })

  const handleAgglutinationRowClick = (row: ProcessedInstallment) => {
    const receivable = combinedReceivables.find(r => r?.id === row?.id)
    if (!receivable) {
      const isRowSelectable = isSelectable(row)()

      if (isRowSelectable) {
        addReceivable(row)
        return
      }
    }
    removeReceivable(row)
  }

  const handleInstallmentDrawerClose = () => {
    setSelectedReceivableId(null)
  }

  const handleAgglutinationClose = () => {
    clearCombinedReceivables()
    formAgglutination.reset({
      due_date: dayjs().add(1, 'day').toISOString(),
      payment_method: formOfPaymentOptions.filter(option => option.key === 'PIX_BANKSLIP')[0].value,
      discount: 0,
    })
    setIsCombining(false)
    setSelectedReceivableId(null)
  }

  const handleContractDrawerClose = () => {
    setShowContractDrawer(false)
  }

  const handleCancellationDrawerClose = () => {
    setShowCancellationDrawer(false)
  }

  const handleEditContractDrawerClose = () => {
    setShowEditContractDrawer(false)
  }

  const handleContractEditDiscountsDrawerClose = () => {
    setShowContractEditDiscountsDrawer(false)
  }

  const closeAllDrawers = () => {
    handleCancellationDrawerClose()
    handleInstallmentDrawerClose()
    handleAgglutinationClose()
    handleContractDrawerClose()
    handleEditContractDrawerClose()
    handleContractEditDiscountsDrawerClose()
  }
  const hasInstallmentsToCancelContract = installmentsToCancelContract?.length > 0

  const canCancelContract = isAdmin || (isCancelContractEnabled && hasInstallmentsToCancelContract)

  const handleCancellationDrawerOpen = () => {
    closeAllDrawers()
    canCancelContract ? setShowCancellationDrawer(true) : setShowCancellationErrorMessage(true)
  }

  const resetManualLiquidationFormErrors = () => {
    setIsFailureFeedbackOpen(false)
  }

  const handleRowClick = (
    params: TableRowParams<ProcessedInstallment>,
    event: React.MouseEvent<HTMLInputElement> = null
  ) => {
    const status = params.row.status as ReceivableStatuses
    if (
      (event?.target instanceof HTMLInputElement && event?.target?.type === 'checkbox') ||
      [ReceivableStatuses.CANCELED, ReceivableStatuses.PAID_AND_CANCELED].includes(
        ReceivableStatuses[status]
      )
    )
      return
    if (isProcessingAgglutination) return
    if (isCombining) {
      handleAgglutinationRowClick(params?.row)
      return
    }

    closeAllDrawers()
    setSelectedReceivableId(params?.row?.id)
    setOrderReference(params?.row?.orderReference)
    setSelectedProcessedInstallment(params?.row)
  }

  const handleManageContractClick = () => {
    sendHotjarEvent(HotjarEvents.CONTRACT_DETAILS)
    closeAllDrawers()
    setShowContractDrawer(true)
  }

  const handleEditContractDrawerClick = () => {
    sendHotjarEvent(HotjarEvents.EDIT_CONTRACT_DUE_DAY)
    closeAllDrawers()
    setShowEditContractDrawer(true)
  }

  const handleContractEditDiscountsDrawerClick = () => {
    sendHotjarEvent(HotjarEvents.EDIT_CONTRACT_DISCOUNTS)
    closeAllDrawers()
    setShowContractEditDiscountsDrawer(true)
  }

  const handleAgglutinationClick = () => {
    sendHotjarEvent(HotjarEvents.AGGLUTINATE_INSTALLMENTS)
    closeAllDrawers()
    setIsCombining(true)
  }

  const handlePrintSlipsClick = () => {
    const openInstallments = processedInstallments.filter(
      installment =>
        installment.status === InstallmentStatuses.OPEN ||
        installment.status === InstallmentStatuses.DUE_TODAY ||
        installment.status === InstallmentStatuses.OVERDUE
    )

    if (openInstallments.length > 0) {
      setIsPrintSlipsDialogVisible(true)
    } else {
      setSnackbarVariation('warning')
      setSnackbarMessage(
        'A impressão está indisponível pois não existem parcelas em aberto neste contrato'
      )
      setSnackbarIsOpen(true, {
        title: 'Este contrato não possui parcelas abertas',
      })
    }
    closeAllDrawers()
  }

  const showAgglutinationDrawer = isCombining && combinedReceivables?.length >= 1
  useEffect(() => {
    const formattedTableColumns = makeInstallmentsTableColumns()
    if (!isCombining) {
      setTableColumns(formattedTableColumns)
    } else {
      setTableColumns([tableHeaderCheckboxColumn, ...formattedTableColumns])
    }
  }, [isCombining, contract])
  useEffect(() => {
    if (!combinedReceivables?.length) {
      formAgglutination.reset({
        due_date: dayjs().add(1, 'day').toISOString(),
        payment_method: formOfPaymentOptions.filter(option => option.key === 'PIX_BANKSLIP')[0]
          .value,
        discount: 0,
      })
    }
  }, [combinedReceivables])

  const showStatement = () => {
    window.open(`/${schoolSlug}/contrato/${contract.id}/demonstrativo-pagos`)
    setIsConfirmationDialogVisible(false)
  }

  const printContractURL = `${PUBLIC_DOMAIN}/contract/${contractId}/print-slips`

  const openPrintSlipsTab = () => {
    window.open(printContractURL, '_blank')
    setIsPrintSlipsDialogVisible(false)
  }

  const getDaysSinceContractCreation = () => {
    const contractCreationDate = dayjs(contract?.created_at).utc().format()
    const today = dayjs().utc().format()

    return Number(dayjs(today).diff(contractCreationDate, 'day'))
  }

  const hasInstallmentsToChangeDueDay = installmentsToChangeDueDay.length > 0

  const maxDaysToChangeContractDueDay = Number(envConfig.MAX_DAYS_TO_CHANGE_CONTRACT_DUE_DAY)
  const maxDaysToChangeContractDueDayIsEnabled = maxDaysToChangeContractDueDay >= 0

  const canEditDueDay = () => {
    if (isAdmin || !maxDaysToChangeContractDueDayIsEnabled) {
      return hasInstallmentsToChangeDueDay
    }

    return (
      hasInstallmentsToChangeDueDay &&
      getDaysSinceContractCreation() <= maxDaysToChangeContractDueDay
    )
  }

  const isAnyDrawerOpen =
    Boolean(selectedReceivableId) ||
    showAgglutinationDrawer ||
    showContractDrawer ||
    showEditContractDrawer ||
    showContractEditDiscountsDrawer ||
    showCancellationDrawer

  const onCancellationSuccess = () => {
    getContract()
    setShowConfirmCancellationMessage(false)
    handleCancellationDrawerClose()
  }

  const cancelContractSubmitHandler = (form: ContractCancellationForm) => {
    const { cancellation_reason, installment_id, cancellation_description } = form

    const snackbarErrorMessage = isCancelContractEnabled
      ? 'Antes de cancelar, é necessário renegociar as parcelas vencidas deste contrato.'
      : 'Ocorreu um erro ao cancelar o contrato'

    return api.contracts
      .revoke(
        contract?.id,
        {
          cancellation_reason: isCancelContractEnabled
            ? ContractCancellationReason.OTHER
            : cancellation_reason,
          installment_id,
          cancellation_description,
        },
        schoolId,
        contract
      )
      .then(() => {
        setSnackbarVariation('success')
        setSnackbarMessage('Contrato cancelado com sucesso.')
        setSnackbarIsOpen(true)
        onCancellationSuccess()
        setIsOnTheFirstPage(true)
        cancelContractReset()
      })
      .catch(() => {
        setSnackbarVariation('error')
        setSnackbarMessage(snackbarErrorMessage)
        setSnackbarIsOpen(true, {
          title: 'Esse contrato não pode ser cancelado',
        })
        handleCancellationDrawerClose()
        setShowConfirmCancellationMessage(false)
        setIsOnTheFirstPage(true)
        cancelContractReset()
        if (isInitialized) {
          eventDispatcherClient.sendEvent({
            scope: EventDispatcherEventScopes.CANCEL_DRAWER,
            name: EventDispatcherEvents.MODAL_VIEWED,
            action: 'click',
            customProperties: {
              $button_name: 'Cancelar contrato',
              $modal_name: 'Mensagem de erro cancelar contrato',
            },
          })
        }
      })
  }

  const contractHasAnyInstallments = contract?.installments.length > 0

  return (
    <>
      <SchoolName />
      <RouterBreadcrumbs />

      <Grid container>
        {isLoading && (
          <Box
            top={300}
            left={50}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
        <ContainerWithActionDrawer item xs={12} $isActionDrawerOpen={isAnyDrawerOpen}>
          <ContractDetailsFailureDialog
            isVisible={isFailureDialogOpen}
            setIsFailureDialogOpen={setIsFailureDialogOpen}
          />
          <Box>
            <Box py={2} textAlign="right">
              <ButtonGroup>
                <Button
                  disabled={isProcessingAgglutination}
                  variation="ghost"
                  onClick={handleManageContractClick}
                  style={{ marginTop: '-58px' }}
                >
                  Ver contrato
                </Button>
              </ButtonGroup>
            </Box>
            <Box pb={6}>
              <GuardianCard
                isProcessingAgglutination={isProcessingAgglutination}
                openCancellationDrawer={handleCancellationDrawerOpen}
              />
            </Box>
            {contractHasAnyInstallments && (
              <>
                <Box pb={2} display="flex" justifyContent="flex-end" alignItems="center">
                  <TableFilter
                    filters={filters}
                    filtersSetter={setFilters}
                    filterOptions={filterOptions}
                  />
                </Box>
                <Box position="relative" pb={4}>
                  <Table<ProcessedInstallment>
                    columns={tableColumns}
                    rows={filteredAndProcessedInstallments}
                    onRowClick={(params: TableRowParams<ProcessedInstallment>, event: unknown) =>
                      handleRowClick(params, event as React.MouseEvent<HTMLInputElement>)
                    }
                    rowsSelected={isCombining ? combinedReceivables : [selectedReceivableId]}
                    RowActionComponent={RowCheckbox}
                    HeaderActionComponent={HeaderCheckbox}
                    rowDisabled={rowDisabledWhileAgglutinating}
                    rowTextDisabled={({ status }) =>
                      [ReceivableStatuses.CANCELED, ReceivableStatuses.PAID_AND_CANCELED].includes(
                        ReceivableStatuses[status as ReceivableStatuses]
                      )
                    }
                  />
                  <ContractTableActions
                    contractId={contractId}
                    guardianId={contract?.guardian_id}
                    isAgglutination={isCombining}
                    canEditDueDay={canEditDueDay}
                    hasInstallmentsToChangeDueDay={hasInstallmentsToChangeDueDay}
                    canEditDiscounts={installmentsToEditDiscounts.length > 0}
                    openDueDayEdition={handleEditContractDrawerClick}
                    openDiscountsEdition={handleContractEditDiscountsDrawerClick}
                    openAgglutination={handleAgglutinationClick}
                    openPrintSlips={handlePrintSlipsClick}
                    closeAgglutination={handleAgglutinationClose}
                    isProcessingAgglutination={isProcessingAgglutination}
                  />
                </Box>
              </>
            )}
            {contract && !contractHasAnyInstallments && <NoInstallmentsMessage />}
          </Box>
        </ContainerWithActionDrawer>
      </Grid>

      <div>
        <AgglutinationDrawer
          isOpen={showAgglutinationDrawer}
          onClose={handleAgglutinationClose}
          selectedReceivableId={selectedReceivableId}
          orderReference={orderReference}
          receivables={combinedReceivables}
          form={formAgglutination}
          setContract={setContract}
          isProcessingAgglutination={isProcessingAgglutination}
          disableChecks={() => setProcessingAgglutination(true)}
          enableChecks={() => setProcessingAgglutination(false)}
        />
        <ContractEditDueDayDrawer
          isOpen={showEditContractDrawer}
          onClose={handleEditContractDrawerClose}
          availableInstallments={installmentsToChangeDueDay}
        />

        <ContractEditDiscountsDrawer
          isOpen={showContractEditDiscountsDrawer}
          onClose={handleContractEditDiscountsDrawerClose}
          availableInstallments={installmentsToEditDiscounts}
        />
        <CancellationDrawer
          isOpen={showCancellationDrawer}
          onClose={handleCancellationDrawerClose}
          processedInstallments={processedInstallments}
          isOnTheFirstPage={isOnTheFirstPage}
          setIsOnTheFirstPage={setIsOnTheFirstPage}
          form={cancelContractForm}
          setShowConfirmCancellationMessage={setShowConfirmCancellationMessage}
          availableInstallments={installmentsToCancelContract}
        />
        <ConfirmationDialog
          isVisible={isConfirmationDialogVisible}
          onClose={() => setIsConfirmationDialogVisible(false)}
          submitHandler={showStatement}
          buttonLabel="Ok, gerar demonstrativo"
          title="Aviso"
        >
          <MuiTypography>
            O demonstrativo só apresenta valores pagos que são de competência do isaac, ou seja,
            valores anteriores à parceria não constam no demonstrativo.
          </MuiTypography>
          <StyledTypography>
            Este informativo também poderá ser utilizado para declaração de imposto de renda.
          </StyledTypography>
        </ConfirmationDialog>
        <ConfirmationDialog
          closeIcon
          isVisible={isPrintSlipsDialogVisible}
          onClose={() => setIsPrintSlipsDialogVisible(false)}
          submitHandler={openPrintSlipsTab}
          buttonLabel="Ok, entendi"
          title="Aviso"
        >
          Apenas parcelas a vencer estão disponibilizadas para a impressão. As parcelas vencidas
          precisam ser renegociadas pelo responsável financeiro com o time de atendimento do isaac.
        </ConfirmationDialog>
        {isCancelContractEnabled && (
          <ConfirmationDialog
            isVisible={showCancellationErrorMessage}
            onClose={() => setShowCancellationErrorMessage(false)}
            submitHandler={() => setShowCancellationErrorMessage(false)}
            title="Esse contrato não pode ser cancelado"
          >
            <MuiTypography>
              Contratos com parcelas vencidas ou renegociadas não podem ser cancelados. Entre em
              contato com o atendimento.
            </MuiTypography>
          </ConfirmationDialog>
        )}
        <ConfirmationDialog
          isVisible={showConfirmCancellationMessage}
          onClose={() => setShowConfirmCancellationMessage(false)}
          submitHandler={cancelContractHandleSubmit(cancelContractSubmitHandler)}
          backButtonLabel="Voltar"
          buttonLabel="Cancelar contrato"
          title="Atenção"
        >
          <MuiTypography>
            O cancelamento de contrato é irreversível e altera os próximos repasses.
          </MuiTypography>
        </ConfirmationDialog>
        <FailureFeedbackDialog
          buttonLabel="Voltar"
          isVisible={isFailureFeedbackOpen}
          onClose={resetManualLiquidationFormErrors}
          submitHandler={resetManualLiquidationFormErrors}
          title="Volte e registre novamente"
          centered
        >
          <FailureFeedbackContent message="Houve uma falha inesperada e não conseguimos registrar o recebimento." />
        </FailureFeedbackDialog>
      </div>
    </>
  )
}

export default ContractDetails
