import qs from 'qs'
import { Box } from '@material-ui/core'
import { Drawer, ActionDrawerHeader, Button } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { InfoItem } from './components/InfoItem'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { formatDate, formatMicroCentsToReal, prettifyFullName } from '@/shared/utils'

import theme from '@/shared/theme'

import { REPORT_PAGE_NAMES } from '@/modules/report/constants/REPORT_PAGE_NAMES'
import { PAYOUT_TRANSACTION_EVENTS_LABEL } from '@/modules/report/constants/PAYOUT_TRANSACTION_EVENTS_LABEL'

import type { PayoutTransactionEvents } from '@/modules/report/models/PayoutTransactionEvents'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { Content } from './styles'

type Event = {
  event: PayoutTransactionEvents
  reference_date: Date
  total: number
}

type TuitionPayoutReportInstallmentDetailsDrawerProps = {
  contractReferenceYear?: string
  events: Event[]
  feeAmount: number
  guardianId: string
  isOpen: boolean
  onClose: () => void
  product: {
    id: string
    name: string
  }
  referenceDate: Date
  student: {
    id: string
    name: string
  }
  totalAmount: number
  totalExpectedBaseAmount: number
}

const DIVIDER_COLOR = theme.primitiveTokens.colors.gray[10]

/**
 * Tuition payout installment drawer component
 *
 * @param props
 * @param props.contractReferenceYear Installment contract reference year
 * @param props.guardianId Indicates the installment guardian ID
 * @param props.student Object containing student data
 * @param props.product Object containing product data
 * @param props.referenceDate Installment referente date
 * @param props.feeAmount Defines the installment fee value
 * @param props.totalAmount Defines the installment total net value
 * @param props.totalExpectedBaseAmount Defines the installment expected total gross value
 * @param props.events Defines the events presents on the installment
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.onClose Callback function called on request close
 */
export const TuitionPayoutReportInstallmentDetailsDrawer = ({
  contractReferenceYear,
  guardianId,
  student,
  product,
  referenceDate,
  feeAmount,
  totalAmount,
  totalExpectedBaseAmount,
  events,
  isOpen,
  onClose,
}: TuitionPayoutReportInstallmentDetailsDrawerProps) => {
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleNavigateToContract = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_REPORT,
        name: EventDispatcherEvents.TUITION_PAYOUT_VIEW_CONTRACT,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
        },
      })

    const route = `/${school?.slug}/responsaveis/${guardianId}/faturas`

    const filtersQueryParams = qs.stringify({
      student_ids: student.id,
      product_ids: product.id,
      reference_years: contractReferenceYear,
    })

    window.open(`${route}?${filtersQueryParams}`, '_blank')
  }

  const filteredEvents = events.reduce<Array<Record<'label' | 'referenceDate' | 'value', string>>>(
    (acc, { event, total, reference_date }) => {
      const label = PAYOUT_TRANSACTION_EVENTS_LABEL[event]

      if (!label || total === 0) return acc

      const value = formatMicroCentsToReal(total)
      const referenceDate = formatDate(reference_date, 'DD/MM/YYYY')

      return [
        ...acc,
        {
          label,
          value,
          referenceDate,
        },
      ]
    },
    []
  )

  const shouldDisplayBaseAmount = totalExpectedBaseAmount > 0
  const shouldDisplayEvents = filteredEvents.length > 0
  const shouldDisplayFee = Math.abs(feeAmount) > 0

  const hasAtLeastOneSection = shouldDisplayBaseAmount || shouldDisplayEvents || shouldDisplayFee

  return (
    <Drawer open={isOpen}>
      <ActionDrawerHeader
        title={prettifyFullName(student.name)}
        subtitle={product.name}
        onClose={onClose}
      />

      <Box display="flex" flex="1" flexDirection="column" p="1.5rem" overflow="auto">
        <Box pb="2rem" borderBottom={`1px solid ${DIVIDER_COLOR}`}>
          <InfoItem label="Competência" value={formatDate(referenceDate, 'MMMM [de] YYYY')} />
        </Box>

        {hasAtLeastOneSection && (
          <Content>
            {shouldDisplayBaseAmount && (
              <Box>
                <InfoItem
                  label="Mensalidade"
                  labelWeight="bold"
                  value={formatMicroCentsToReal(totalExpectedBaseAmount)}
                />
              </Box>
            )}

            {shouldDisplayEvents && (
              <Box>
                <Box mb="1rem">
                  <InfoItem label="Mudanças em mensalidade" labelWeight="bold" />
                </Box>

                {filteredEvents.map(({ label, value, referenceDate }, index) => (
                  <Box key={label} mt={index === 0 ? 0 : '1rem'}>
                    <InfoItem
                      label={label}
                      value={value}
                      secondaryInfo={`Data da mudança: ${referenceDate}`}
                    />
                  </Box>
                ))}
              </Box>
            )}

            {shouldDisplayFee && (
              <InfoItem
                label="Taxa isaac"
                labelWeight="bold"
                value={formatMicroCentsToReal(feeAmount)}
              />
            )}
          </Content>
        )}

        <Box py="2rem">
          <InfoItem
            label="Valor final"
            labelWeight="bold"
            value={formatMicroCentsToReal(totalAmount)}
          />
        </Box>
      </Box>

      <Box p="2rem 1rem" borderTop={`1px solid ${DIVIDER_COLOR}`}>
        <Button onClick={handleNavigateToContract} fullWidth>
          Ir para contrato
        </Button>
      </Box>
    </Drawer>
  )
}
