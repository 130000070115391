import { StatusSummaryQuery } from '@/modules/enrollment/hooks/queries/enrollment'
import { ENROLLMENT_CYCLE_QUERY_KEY } from '@/modules/enrollment/constants/enrollment'
import type { Situation } from '@/modules/enrollment/services/enrollmentService/types'
import { useApi } from '@/shared/hooks'
import type { ReenrollmentStatus } from '@/shared/interfaces'
import { useQueryClient } from '@tanstack/react-query'
import { studentEnrollmentCycleKey } from './queries/useGetStudentEnrollmentCycle'
import { useToast } from '@gravity/toast'
import * as Sentry from '@sentry/react'

export const useRefreshSituation = (
  callback: (
    data: Situation & {
      studentID: uuid
    }
  ) => void
) => {
  const { api } = useApi()
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const refreshSituation = async (
    schoolID: uuid,
    studentID: uuid,
    enrollmentCycle: number,
    currentStatus: ReenrollmentStatus,
    studentName: string
  ) => {
    try {
      const data = (
        await api.enrollment.updateEnrollmentSituation(schoolID, studentID, enrollmentCycle)
      ).data
      const statusHasChanged = data.status !== currentStatus

      const dataWithStudentID = { ...data, studentID }
      if (dataWithStudentID) {
        callback(dataWithStudentID)
      }

      queryClient.invalidateQueries([ENROLLMENT_CYCLE_QUERY_KEY])
      queryClient.invalidateQueries([StatusSummaryQuery])
      queryClient.invalidateQueries([studentEnrollmentCycleKey])

      toast({
        title: statusHasChanged
          ? `${studentName} teve sua situação de matrícula atualizada!`
          : `${studentName} não teve mudanças na situação de matrícula. Se houve algum pagamento pode ser que ele ainda não tenha sido compensado, tente novamente mais tarde.`,
        type: statusHasChanged ? 'success' : 'info',
      })
    } catch (e) {
      Sentry.captureException(e)
      toast({
        title: 'Erro ao tentar atualizar situação de matrícula.',
        type: 'error',
      })
    }
  }

  return refreshSituation
}
