import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'

import { useSchoolBySlug } from '@/shared/hooks/queries/schools'

import type { School } from '@/shared/models/School'

type SelectedSchoolContextType = {
  error: unknown
  hasErrorOnFetchSchool: boolean
  isLoadingSchool: boolean
  retryFetchSchool: () => Promise<void>
  school?: School
  updateSchoolSlug: (schoolSlug: string) => void
}

type SelectedSchoolProviderProps = {
  children: ReactNode
}

export const SelectedSchoolContext = createContext({} as SelectedSchoolContextType)

/**
 * Context that gives access to selected school
 *
 * @param children Components that will be able to access the context
 *
 * @returns Context provider
 */
export const SelectedSchoolProvider = ({ children }: SelectedSchoolProviderProps) => {
  const [schoolSlug, setSchoolSlug] = useState<string>()

  const { data: response, isFetching, isError, error, refetch } = useSchoolBySlug(
    schoolSlug ?? '',
    {
      enabled: !!schoolSlug,
    }
  )

  const updateSchoolSlug = useCallback(
    (updatedSchoolSlug: string) => setSchoolSlug(updatedSchoolSlug),
    [schoolSlug, setSchoolSlug]
  )

  const contextValue = useMemo<SelectedSchoolContextType>(
    () => ({
      school: response?.data,
      isLoadingSchool: isFetching,
      hasErrorOnFetchSchool: isError,
      error,
      retryFetchSchool: async () => {
        await refetch()
      },
      updateSchoolSlug,
    }),
    [response, isFetching, isError, error, updateSchoolSlug]
  )

  return (
    <SelectedSchoolContext.Provider value={contextValue}>{children}</SelectedSchoolContext.Provider>
  )
}
