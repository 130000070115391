import { useState } from 'react'
import dayjs from 'dayjs'

import { Button } from '@gravity/button'
import { DatepickerPrimitives as Datepicker, formatDateToYear } from '@gravity/datepicker'
import Icon from '@mdi/react'
import { mdiTrayArrowDown } from '@mdi/js'

import { ReportCard } from '../ReportCard'
import { CardFooter, StyledDatepickerTrigger } from './styles'

import { convertToUTCDate } from '@/shared/utils'
import { useDownloadIRReport } from '../../hooks/useDownloadIRReport'
import { CircularProgress } from '@material-ui/core'

export const DemonstrativeIRReportCard = () => {
  const { downloadIRReport, isLoading } = useDownloadIRReport()

  const [selectedDateForIR, setSelectedDateForIR] = useState<Date>(dayjs('2023').utc().toDate())

  const minDateUTCForIR = convertToUTCDate('2023-01-01').format('YYYY')
  const maxDateUTCForIR = convertToUTCDate('2023-12-31').format('YYYY')

  const handleDownloadIR = async () => {
    await downloadIRReport()
  }

  return (
    <ReportCard
      title="Demonstrativo de valores pagos"
      description="Demonstrativo referente às despesas educacionais em 2023. Na senha, utilize o CNPJ da escola (somente números)."
    >
      <CardFooter>
        <Datepicker.Root>
          <StyledDatepickerTrigger size={3}>
            {formatDateToYear(selectedDateForIR)}
          </StyledDatepickerTrigger>
          <Datepicker.Calendar
            value={selectedDateForIR}
            minDate={dayjs(minDateUTCForIR).toDate()}
            maxDate={dayjs(maxDateUTCForIR).toDate()}
            maxDetail="decade"
            onChange={year => setSelectedDateForIR(year as Date)}
          />
        </Datepicker.Root>
        <Button
          variant="ghost"
          iconEnd={isLoading ? <CircularProgress /> : <Icon path={mdiTrayArrowDown} />}
          onClick={handleDownloadIR}
          disabled={isLoading}
        >
          Baixar
        </Button>
      </CardFooter>
    </ReportCard>
  )
}
