import { Select } from '@material-ui/core'
import styled from 'styled-components'

export const PageContainer = styled.div`
  padding-bottom: 40px;
  margin-top: 40px;
`

export const InfoContainerBeforeEFI248 = styled.div`
  display: flex;
  gap: 32px;
  margin: 40px 0 48px;
  align-items: center;
`

export const InfoContainer = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 40px;
  align-items: flex-start;
  justify-content: space-between;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`

export const TableFiltersContainer = styled.div`
  display: flex;
  margin: 24px 0 48px;
  gap: 32px;
  align-items: end;
  justify-content: space-between;
`

export const SearchContainer = styled.div`
  flex: 1;
  max-width: 480px;
`

export const SelectYear = styled(Select).attrs(() => ({
  disableUnderline: true,
  MenuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  },
}))`
  border-bottom: none;
  border-radius: ${({ theme }) => theme.primitiveTokens.spacing[2]};
  padding: 2px ${({ theme }) => theme.primitiveTokens.spacing[2]};
  margin-left: ${({ theme }) => theme.primitiveTokens.spacing[1]};
  text-align: center;

  &.Mui-focused,
  &:hover {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]}14;
  }

  .MuiInput-input {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
  }

  .MuiSelect-icon {
    color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
  }

  .MuiSelect-selectMenu {
    display: flex;
  }
`
