import { setToClipboard } from '@/shared/utils'
import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@material-ui/core'
import { mdiContentCopy } from '@mdi/js'
import Icon from '@mdi/react'
import { useState } from 'react'
import styled from 'styled-components'

type CopyToClipboardButtonProps = {
  copyableText: string
  onClick?: () => void
  tooltipText: string
}

const StyledIconButton = styled(IconButton)`
  height: 20px;
  width: 20px;
  margin-left: 8px;
`
const tooltipDelayMs = 1500

export const CopyToClipboardButton = ({
  copyableText,
  tooltipText,
  onClick,
}: CopyToClipboardButtonProps) => {
  const [tooltip, setTooltip] = useState('')

  return (
    <Tooltip title={tooltip} interactive leaveDelay={tooltipDelayMs}>
      <StyledIconButton
        variant="ghost"
        onClick={e => {
          e.stopPropagation()
          setToClipboard(copyableText)
          setTooltip(tooltipText)
          setTimeout(() => {
            setTooltip('')
          }, tooltipDelayMs)
          if (onClick) onClick()
        }}
      >
        <Icon path={mdiContentCopy} />
      </StyledIconButton>
    </Tooltip>
  )
}
