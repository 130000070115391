import { Box } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { TextField } from '@olaisaac/design-system'
import styled from 'styled-components'

export const StyledTextField = styled(TextField)`
  ${({
    theme: {
      primitiveTokens: { spacing },
    },
  }) => `
  margin-bottom:  ${spacing[6]};
  & input {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`}
`

export const StyledBox = styled(Box)`
  padding: 4px 22px;
`

export const ExpandLess = styled(Icons.ExpandLess)`
  ${({
    theme: {
      primitiveTokens: {
        colors: { isaacBlue },
      },
    },
  }) => `
  color: ${isaacBlue[60]}
`}
`

export const ExpandMore = styled(Icons.ExpandMore)`
  ${({
    theme: {
      primitiveTokens: {
        colors: { isaacBlue },
      },
    },
  }) => `
  color: ${isaacBlue[60]}
`}
`
