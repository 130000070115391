import { convertJSONBToJSON } from '@/escolas/hooks/utils'
import { formatInvoiceError } from './formatInvoicesError'
import { InvoiceErrors, invoicesMappedErrors } from '../constants'

export const getInvoiceError = additional_informations => {
  const invoiceError = additional_informations?.provider_response.Content
  const errorObject = invoiceError?.split('err=')[1]
  const hasErrorProp = errorObject?.includes('errors') || null

  if (hasErrorProp) {
    const parsedError = convertJSONBToJSON(invoiceError.split('err=')[1])?.errors
    const isMappedError = invoicesMappedErrors.includes(formatInvoiceError(parsedError))

    if (parsedError && isMappedError) {
      return parsedError
    }
  }

  return { [InvoiceErrors.DEFAULT]: InvoiceErrors.DEFAULT }
}
