import { useMemo } from 'react'

import Icon from '@mdi/react'
import { mdiSchool, mdiCurrencyUsd, mdiAccountMultiple, mdiWallet } from '@mdi/js'

type Props = {
  currentDate: string
  currentYear: number
  isIntegratedSchool: boolean
  isIsaacPay: boolean
  isMaintainer: boolean
  isSecretary: boolean
  url: string
}

export function useQuickAccessSectionLinks({
  url,
  currentYear,
  currentDate,
  isSecretary,
  isIsaacPay,
  isMaintainer,
  isIntegratedSchool,
}: Props) {
  const value = useMemo(() => {
    const payoutReportUrl = `${url}/repasses/mensalidades`

    const links: Array<{
      href: string
      icon: JSX.Element
      title: string
    }> = []

    if (isMaintainer) {
      if (!isIsaacPay) {
        links.push({
          icon: <Icon path={mdiCurrencyUsd} size={1} />,
          href: payoutReportUrl,
          title: 'Repasse do mês',
        })
      }

      links.push({
        icon: <Icon path={mdiSchool} size={1} />,
        href: `${url}/matriculas`,
        title: 'Matrículas',
      })

      links.push({
        icon: <Icon path={mdiAccountMultiple} size={1} />,
        href: `${url}/responsaveis`,
        title: 'Responsáveis',
      })
    } else {
      links.push({
        icon: <Icon path={mdiSchool} size={1} />,
        href: `${url}/matriculas`,
        title: 'Matrículas',
      })

      links.push({
        icon: <Icon path={mdiWallet} size={1} />,
        href: `${url}/responsaveis?debt_status=OVERDUE`,
        title: 'Negociar parcelas',
      })

      links.push({
        icon: <Icon path={mdiAccountMultiple} size={1} />,
        href: `${url}/responsaveis`,
        title: 'Responsáveis',
      })
    }

    return links
  }, [url, currentYear, currentDate, isSecretary, isIsaacPay, isMaintainer, isIntegratedSchool])

  return value
}
