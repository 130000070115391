import { ContainerTuitionMainContent, PayoutCardContainer } from '../../styles'
import { TitleContainer, PayoutSkeleton, AmountContainer } from './styles'
import { ColumnReceipts, ReceiptsCardContainer, ReceiptsContainer } from '../PaymentReporter/styles'

type LoadingTuitionSubHeaderProps = {
  isNewVersionPayout?: boolean
}

/**
 * Loading state for payout report sub header card component
 *
 * @param props
 * @param props.isNewVersionPayout Indicates if the payout report is for the new version.
 */
export const LoadingTuitionSubHeader = ({ isNewVersionPayout }: LoadingTuitionSubHeaderProps) => {
  const Contents = () => (
    <>
      <ColumnReceipts>
        <PayoutSkeleton width={24} height={24} />
        <PayoutSkeleton width={130} height={25} />
        <PayoutSkeleton width={160} height={25} />
      </ColumnReceipts>
      <PayoutSkeleton width={40} height={40} />
    </>
  )
  return (
    <ContainerTuitionMainContent $maxWidth={isNewVersionPayout}>
      <PayoutCardContainer>
        <TitleContainer>
          <PayoutSkeleton width={76} height={22} />
          <PayoutSkeleton fullWidth height={40} />
        </TitleContainer>
        <AmountContainer>
          <PayoutSkeleton width={208} height={21} />
          <PayoutSkeleton width={252} height={38} />
          <PayoutSkeleton fullWidth height={21} />
        </AmountContainer>
        <PayoutSkeleton width={197} height={40} />
      </PayoutCardContainer>

      {isNewVersionPayout && (
        <ReceiptsContainer>
          <ReceiptsCardContainer style={{ marginBottom: '16px' }}>
            <Contents />
          </ReceiptsCardContainer>
          <ReceiptsCardContainer>
            <Contents />
          </ReceiptsCardContainer>
        </ReceiptsContainer>
      )}
    </ContainerTuitionMainContent>
  )
}
