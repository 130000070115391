import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { formatCentsToReal } from 'src/shared/utils'
import { useContract } from 'src/escolas/hooks'
import { propEq } from 'ramda'
import { Installment, Receivable, ReceivableStatuses } from 'src/shared/interfaces'

const StyledTypography = styled(Typography)``
type ProcessedReceivable = Receivable & { orderReference: string }

const findInstallment = (receivableId: string, installments: Installment[]) => {
  return installments?.find(installment =>
    installment?.receivables.some(propEq('id', receivableId))
  )
}
const getProperReceivable = (receivables: Receivable[]) => {
  const hasAgglutinated = receivables?.find(r => r?.status === ReceivableStatuses.AGGLUTINATED)
  if (hasAgglutinated) return hasAgglutinated
  return receivables?.find(r => r?.status === ReceivableStatuses.PAID)
}
const processOriginalReceivables = (
  originalReceivables: Receivable[],
  installments: Installment[]
) => {
  const processedOriginalReceivables: any[] = []
  let partialTotalAmount = 0
  originalReceivables?.forEach(r => {
    const index = installments?.findIndex(installment => installment?.id === r?.installment_id)
    // So we can have the current_amount to display.
    const installment = installments?.find(installment => {
      if (!installment.receivables) return false
      return installment?.receivables.some(propEq('id', r?.id))
    })
    const receivables = installment?.receivables.filter(
      r => r?.status === ReceivableStatuses.AGGLUTINATED || r?.status === ReceivableStatuses.PAID
    )
    const receivable = getProperReceivable(receivables)

    const orderReference = `${index + 1} de ${installments?.length}`

    processedOriginalReceivables.push({ ...receivable, orderReference })
    partialTotalAmount += receivable?.current_amount
  })
  return { processedOriginalReceivables, partialTotalAmount }
}

const getReceivableFromReceivableId = (selectedId: string, installments: Installment[]) => {
  const installment = findInstallment(selectedId, installments)

  const receivable = installment?.receivables?.find(r => r?.id === selectedId)

  return receivable
}

const getReceivableFromInstallmentId = (installmentId: string, installments: Installment[]) => {
  const installment = installments?.find(i => i?.id === installmentId)
  // Check if the originals where agglutinated
  const originalReceivables = installment?.receivables.find(receivable => {
    if (!receivable.original_receivables) return false
    return receivable?.original_receivables.some(
      r => r?.status === ReceivableStatuses.AGGLUTINATED || r?.status === ReceivableStatuses.PAID
    )
  })
  // Return the root node of the agglutination tree
  return originalReceivables
}

const getInstallmentIdFromSelectedId = (selectedId: string) => {
  return selectedId.split('installment::')[1]
}
const getIncludesInstallment = (selectedId: string) => {
  return selectedId?.includes('installment::')
}
const getOriginalReceivables = (selectedId: string, installments: Installment[]) => {
  const isSplitReceivable = getIncludesInstallment(selectedId)
  if (isSplitReceivable) {
    const installmentId = getInstallmentIdFromSelectedId(selectedId)
    const agglutinationRoot = getReceivableFromInstallmentId(installmentId, installments)
    const originalReceivables = agglutinationRoot?.original_receivables
    return { originalReceivables, receivable: agglutinationRoot, isSplit: true }
  }

  const receivable = getReceivableFromReceivableId(selectedId, installments)
  const originalReceivables = receivable?.original_receivables

  return { originalReceivables, receivable, isSplit: false }
}

const checkAgglutinatedParent = (selectedId: string, installments: Installment[]) => {
  // Dont check splits
  if (getIncludesInstallment(selectedId)) return
  // Idea: check if the installment that's binded to receivable
  // has another receivable that was agglutinated at some point in time
  const installment = findInstallment(selectedId, installments)
  const key = `installment::${installment?.id}`
  const { receivable } = getOriginalReceivables(key, installments)
  // Return if it has an agglutinated parent
  return Boolean(receivable)
}

export type CombinedReceivablesHeaderProps = {
  selectedReceivableId: uuid
  setCurrentValue?: (value: number) => void
  setIsAgglutinated: (value: boolean) => void
  setReceivable: Dispatch<SetStateAction<Receivable>>
}

type ProcessedReceivables = {
  partialTotalAmount: number
  processedOriginalReceivables: any[]
}

const CombinedReceivable = (receivable: ProcessedReceivable) => {
  return (
    <Box pb={1} mt={1} mb={1} display="flex" justifyContent="space-between" key={receivable?.id}>
      <StyledTypography variant="body2" style={{ fontWeight: 400 }}>
        Parcela {receivable?.orderReference}
      </StyledTypography>
      <StyledTypography variant="body2" style={{ fontWeight: 700 }}>
        {formatCentsToReal(receivable?.current_amount)}
      </StyledTypography>
    </Box>
  )
}

const CombinedReceivablesHeader: FC<CombinedReceivablesHeaderProps> = ({
  selectedReceivableId,
  setIsAgglutinated,
  setReceivable,
}) => {
  const { contract } = useContract()
  const [processedReceivables, setProcessedReceivables] = useState<ProcessedReceivables>(null)
  const [agglutinationDiscount, setAgglutinationDiscount] = useState(0)

  const hasAgglutinatedParent = checkAgglutinatedParent(
    selectedReceivableId,
    contract?.installments
  )

  const { originalReceivables, receivable, isSplit } = getOriginalReceivables(
    selectedReceivableId,
    contract?.installments
  )

  const noOriginalReceivables = !originalReceivables || originalReceivables?.length <= 1

  useEffect(() => {
    if (hasAgglutinatedParent) {
      setIsAgglutinated(true)
    }

    if (isSplit) {
      setReceivable(() => ({ ...receivable }))
    }

    if (noOriginalReceivables && !hasAgglutinatedParent) {
      return setIsAgglutinated(false)
    }

    const { processedOriginalReceivables, partialTotalAmount } = processOriginalReceivables(
      originalReceivables,
      contract?.installments
    )
    setProcessedReceivables({ processedOriginalReceivables, partialTotalAmount })
    setAgglutinationDiscount(receivable?.base_amount - partialTotalAmount)
    setIsAgglutinated(true)
  }, [hasAgglutinatedParent, isSplit, originalReceivables])

  return (
    <>
      {noOriginalReceivables ? null : (
        <>
          <Box mb={2}>
            <StyledTypography variant="body1" style={{ fontWeight: 400 }}>
              Essa parcela é uma aglutinação de:
            </StyledTypography>
          </Box>
          {processedReceivables?.processedOriginalReceivables?.map(CombinedReceivable)}
          <Divider />
          <Box mt={2}>
            <Box pb={1} mt={2} mb={1} display="flex" justifyContent="space-between">
              <StyledTypography variant="body2" style={{ fontWeight: 400 }}>
                Total parcial
              </StyledTypography>
              <StyledTypography variant="body2" style={{ fontWeight: 700 }}>
                {formatCentsToReal(processedReceivables?.partialTotalAmount)}
              </StyledTypography>
            </Box>
            <Box mt={1} mb={2} display="flex" justifyContent="space-between">
              <StyledTypography variant="body2" color="error" style={{ fontWeight: 400 }}>
                Desconto da negociação
              </StyledTypography>
              <StyledTypography variant="body2" style={{ fontWeight: 700 }}>
                {formatCentsToReal(agglutinationDiscount)}
              </StyledTypography>
            </Box>
          </Box>
          <Divider />
        </>
      )}
    </>
  )
}

export default CombinedReceivablesHeader
