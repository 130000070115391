import styled from 'styled-components'
import { Link as OlaisaacLink } from '@gravity/link'
import { fontSize, fontWeight, lineHeight, spacing } from '@gravity/tokens'

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${spacing[6]} ${spacing[6]} 0;
  overflow-y: auto;
  gap: ${spacing[8]};

  h2 {
    font-weight: ${fontWeight.medium};
    font-size: ${fontSize.base};

    line-height: ${lineHeight.normal};
  }

  p {
    font-size: ${fontSize.sm};
    line-height: 1.5rem;
  }
`

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]};
`

export const DateInfo = styled.div`
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 2rem;
  }

  p {
    margin: 0.5rem 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6d6d6d;
  }

  time {
    font-family: 'Roboto';
    color: #414141;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export const ContactInfo = styled.div`
  margin-bottom: 2.625rem;

  h2 {
    margin-bottom: 0;
  }

  .label {
    margin: 0.5rem 0 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6d6d6d;
  }

  .contact {
    margin: 0.5rem 0 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export const Link = styled(OlaisaacLink)`
  font-size: ${fontSize.sm};
`
