import { AgreementSimulationCombined } from '@/modules/guardians/models/agreement'

export const calculateAgreementFlags = (agreements: AgreementSimulationCombined[]) => {
  const flags = {
    keepFineAndInterest: true,
    keepDuePaymentDiscounts: true,
    keepEarlyPaymentDiscounts: false,
  }

  agreements.some(agreement => {
    if (agreement.exempted_interest + agreement.exempted_fine > 0) flags.keepFineAndInterest = false
    if (agreement.due_payment_discount_applied > 0) flags.keepDuePaymentDiscounts = true
    if (agreement.early_payment_discount_applied > 0) flags.keepEarlyPaymentDiscounts = true
    return false
  })

  return flags
}
