import styled, { css } from 'styled-components'
import type { SectionContainerProps } from './types'

export const Container = styled.section<SectionContainerProps>`
  margin: ${props => props.theme.gravity.spacing[8]} -${props => props.theme.gravity.spacing[16]};
  padding-top: ${props => props.theme.gravity.spacing[8]};
  padding-left: ${props => props.theme.gravity.spacing[16]};
  padding-bottom: ${props => props.theme.gravity.spacing[8]};
  padding-right: ${props => props.theme.gravity.spacing[16]};
  background: ${props => props.theme.gravity.colors['colors-background-accent-3']};

  ${props =>
    props.$variation === 'primary' &&
    css`
      background: ${props => props.theme.gravity.colors['colors-background-neutral-1']};
      margin: 0 -${props => props.theme.gravity.spacing[16]};
      padding-top: 0;
    `}

  &:not(:first-of-type) {
    padding: ${props => props.theme.gravity.spacing[8]} ${props => props.theme.gravity.spacing[16]};
  }

  .description {
    display: flex;

    line-height: 1.5;
    margin: ${props => props.theme.gravity.spacing[2]} 0 ${props => props.theme.gravity.spacing[8]}
      0;
    color: ${props => props.theme.gravity.colors['colors-text-main-3']};
  }

  &.skeleton {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.gravity.spacing[4]};
  }
`
