import type { Discount } from '@/modules/enrollment/pages/Campaigns/CampaignDetails/components/InstallmentForm/types'

export const useCalculateAmount = () => {
  const calculateTotalAmount = (monthlyAmount: number, durationMonths: number) =>
    monthlyAmount * durationMonths

  const calculateTuitionAmountWithDiscount = (
    monthlyAmount: number,
    discounts: Discount[]
  ): number => {
    const totalDiscount = discounts.reduce((acc, discount) => acc + discount.amount, 0)
    return monthlyAmount - totalDiscount
  }

  return {
    calculateTotalAmount,
    calculateTuitionAmountWithDiscount,
  }
}
