import { Typography } from '@olaisaac/design-system'
import { useNavigation } from 'src/escolas/hooks'

export const SchoolName = () => {
  const { school } = useNavigation()

  return (
    <Typography
      variation="subtitleDesktopMedium"
      color="secondary"
      style={{ marginTop: 40, marginBottom: 12 }}
    >
      {school.name}
    </Typography>
  )
}
