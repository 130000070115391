import {
  mdiHeartOutline,
  mdiCalendar,
  mdiHandshakeOutline,
  mdiCalendarClock,
  mdiTagOutline,
  mdiPercentOutline,
  mdiCheck,
  mdiArrowTopRight,
} from '@mdi/js'
import { InfoCard } from '../../components/InfoCard'
import Icon from '@mdi/react'
import { HomeTemplate } from '../../templates/HomeTemplate'
import { Box, Grid } from '@material-ui/core'
import {
  GrayTitleCard,
  RowContainer,
  RowContainerSpaceBetween,
  StyledCard,
  SubtitleGrayCard,
} from './styles'
import { DescriptionText } from '../../components/InfoCard/styles'
import { Typography } from '@olaisaac/design-system'
import { IconButton } from '@gravity/icon-button'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'

export const NoCreditAvailable = () => {
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()
  const isInMaintenance = useUnleashFlag(UnleashFlags.CRD_166_DISABLE_CREDIT_FLOWS)

  const cards = [
    <InfoCard
      key={1}
      icon={<Icon path={mdiHeartOutline} size={1} />}
      title="Tenha uma operação saudável com o isaac"
      description="Mantenha a taxa de recebimentos manuais em zero, instruindo o responsável financeiro a efetuar os pagamentos através do meu isaac."
    />,
    <InfoCard
      key={2}
      icon={<Icon path={mdiCalendar} size={1} />}
      title="Tenha as contas em dia"
      description="Um critério importante para ter mais crédito é pagar as contas sem atraso. Os CNPJs e CPFs dos sócios da escola não podem ter restrições."
    />,
    <InfoCard
      key={3}
      icon={<Icon path={mdiHandshakeOutline} size={1} />}
      title="Busque renegociar dívidas antigas"
      description="Contratar crédito agora pode comprometer a saúde financeira da escola. Busque renegociar dívidas antigas para que o crédito caiba no orçamento."
    />,
  ]

  return (
    <HomeTemplate
      title={'Saiba como aumentar suas chances  \n de ter crédito no isaac'}
      cardDescription={
        'Sua escola não possui ofertas disponíveis no momento,  \n mas preparamos algumas dicas de como melhorar sua análise de crédito.'
      }
      isEligible={false}
      cards={cards}
      mainCardDescription="Como melhorar a análise de crédito"
      bottomInfo={
        <>
          <Grid item xs={9}>
            <StyledCard>
              <Box my={1} mx={5}>
                <GrayTitleCard>Conheça o crédito isaac</GrayTitleCard>
                <RowContainer>
                  <Icon path={mdiCalendarClock} size={1} />
                  <DescriptionText>
                    Número de parcelas máximo condicionado ao contrato vigente com o isaac
                  </DescriptionText>
                </RowContainer>

                <RowContainer>
                  <Icon path={mdiTagOutline} size={1} />
                  <DescriptionText>
                    Campanhas com condições especiais ao longo do ano
                  </DescriptionText>
                </RowContainer>

                <RowContainer>
                  <Icon path={mdiPercentOutline} size={1} />
                  <DescriptionText>Taxas competitivas com o mercado</DescriptionText>
                </RowContainer>

                <RowContainer>
                  <Icon path={mdiCheck} size={1} />
                  <DescriptionText>Elegível a partir do segundo mês de operação</DescriptionText>
                </RowContainer>
                <Typography style={{ marginTop: '97px' }}>
                  A disponibilidade das ofertas estão sujeitas a análise de crédito.
                </Typography>
              </Box>
            </StyledCard>
          </Grid>
          <Grid item xs={3}>
            <StyledCard>
              <Box m={1}>
                <SubtitleGrayCard>
                  O isaac está aqui <br /> para te apoiar
                </SubtitleGrayCard>
                <Box mt={1}>
                  <DescriptionText>
                    Você pode entrar <br /> em contato com seu consultor para entender <br /> todas
                    as possibilidades <br /> do crédito isaac.
                  </DescriptionText>
                </Box>
              </Box>
            </StyledCard>
            <StyledCard style={{ marginTop: '1.5rem' }}>
              <Box m={1}>
                <RowContainerSpaceBetween>
                  <SubtitleGrayCard>
                    Dúvidas <br /> frequentes
                  </SubtitleGrayCard>
                  <IconButton
                    size={2}
                    onClick={() => {
                      sendCreditEventToMixpanel(
                        EventDispatcherEvents.BUTTON_CLICKED,
                        MixpanelEventActions.CLICK,
                        {
                          $page_name: CreditEventComponentNames.FAQ,
                        }
                      )
                      window.open(
                        'https://centraldeajuda.olaisaac.io/credito-isaac/elegibilidade-do-credito-isaac',
                        '_blank'
                      )
                    }}
                  >
                    <Icon path={mdiArrowTopRight} />
                  </IconButton>
                </RowContainerSpaceBetween>
              </Box>
            </StyledCard>
          </Grid>
        </>
      }
      isInMaintenance={isInMaintenance}
    />
  )
}
