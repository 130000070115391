import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'

import { Container } from './styles'

export const NegotiationEmpty = () => {
  return (
    <Container>
      <Heading variant="heading-h4-medium">Não há registro de manipulações neste mês</Heading>
      <Text>Se novos registros surgirem, eles ficarão disponíveis aqui.</Text>
    </Container>
  )
}
