import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@/shared/hooks/useQuery'

import {
  Ordering,
  OrderingQueryParamNames,
  OrderingQueryParamsNameEnum,
  OrderingHookType,
  SortOrder,
} from './types'

/**
 * Custom hook to handle ordination query params
 */
export const useOrdering = <T extends string = string>(
  initialValue?: Ordering<T>
): OrderingHookType<T> => {
  const { query, setOnQueryParam, setMultipleQueryParams } = useQuery()

  const getOrdinationFromURL = (): Ordering<T> => {
    const getUrlValue = <
      K extends OrderingQueryParamNames,
      V = { sort_by: T; sort_order: SortOrder }[K]
    >(
      paramKey: K,
      defaultValue?: V
    ) => {
      const paramValue = query.get(paramKey)

      return (paramValue as V) ?? defaultValue
    }

    const data: Ordering<T> = {
      sortBy: getUrlValue('sort_by', initialValue?.sortBy),
      sortOrder: getUrlValue('sort_order', initialValue?.sortOrder),
    }

    return data
  }

  const [ordering, setOrdering] = useState<Ordering<T>>(() => getOrdinationFromURL())

  useEffect(() => {
    const sortBy = query.get('sort_by')
    const sortOrder = query.get('sort_order')

    if (!sortBy && !sortOrder && initialValue) {
      const data: Array<{ name: OrderingQueryParamNames; value: string }> = [
        { name: 'sort_by', value: String(initialValue.sortBy) },
        { name: 'sort_order', value: String(initialValue.sortOrder) },
      ]

      setMultipleQueryParams(data, 'replace')
    }
  }, [])

  useEffect(() => {
    const sortBy = query.get('sort_by')
    const sortOrder = query.get('sort_order')

    const hasChanges = sortBy !== ordering.sortBy || sortOrder !== ordering.sortOrder

    hasChanges && setOrdering(getOrdinationFromURL())
  }, [query])

  const updateOrdering = useCallback(
    ({ sortBy, sortOrder }: Ordering<T>) => {
      const data: Array<{ name: OrderingQueryParamNames; value: string }> = [
        { name: 'sort_by', value: String(sortBy) },
        { name: 'sort_order', value: String(sortOrder) },
      ]

      setMultipleQueryParams(data, 'replace')
    },
    [setMultipleQueryParams]
  )

  const updateOrderingValue = useCallback(
    <K extends keyof Ordering<T>>(key: K, value: Ordering<T>[K]) => {
      setOnQueryParam(String(value), OrderingQueryParamsNameEnum[key], 'replace')
    },
    [setOnQueryParam]
  )

  const orderingHookValue = useMemo(
    () => ({
      ordering,
      updateOrdering,
      updateOrderingValue,
    }),
    [ordering, updateOrdering, updateOrderingValue]
  )

  return orderingHookValue
}
