import { useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { ENROLLMENT_PAGE_BASE_PATH } from '@/modules/enrollment/constants/enrollment'

import { routesMap as managementRoutesMap } from '@/modules/management/routes'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { TRACKED_REPORTS_WITH_PAGE_VIEW } from '@/modules/account-reports/constants'

/** Maps a "page name" to a "page path". Only those pages will emit the PAGE_VIEW event */
const PAGES_TO_TRACK = {
  HOME: '/:schoolSlug',
  PAGINA_DE_CONTRATOS: '/:schoolSlug/contratos/:referenceYear',
  SCORE_ISAAC: `/:schoolSlug/${ENROLLMENT_PAGE_BASE_PATH}/score`,
  PAGINA_DE_MATRICULAS: `/:schoolSlug/${ENROLLMENT_PAGE_BASE_PATH}`,
  PAGINA_DE_ALUNOS: `/:schoolSlug/${ENROLLMENT_PAGE_BASE_PATH}/:studentId`,
  CRIACAO_DE_CONTRATOS: '/:schoolSlug/contratos/:referenceYear/novo',
  QUADRO_DE_AVISOS: '/:schoolSlug/avisos',
  Relatórios: '/:schoolSlug/relatorios',
  PRODUCTS_HOME: '/:schoolSlug/produtos',
  ACOMPANHAMENTO: '/:schoolSlug/campanha/:campaignId',
  CAMPANHAS_REMATRICULA: '/:schoolSlug/campanhas/:year',
  ...TRACKED_REPORTS_WITH_PAGE_VIEW,
  ...managementRoutesMap,
}

/**
 * Hook responsible for tracking page views from specified paths.
 */
export const useTrackPageView = () => {
  const location = useLocation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  useEffect(() => {
    for (const [page, path] of Object.entries(PAGES_TO_TRACK)) {
      if (matchPath(location.pathname, path)?.isExact) {
        isInitialized &&
          eventDispatcherClient.sendEvent({
            scope: page, // TODO: Support different scopes
            name: EventDispatcherEvents.PAGE_VIEWED,
            action: 'page_view',
            pageName: page,
          })
        break
      }
    }
  }, [isInitialized, location.pathname])
}
