import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Button } from '@gravity/button'
import { DialogPrimitives } from '@gravity/dialog'
import { TextField } from '@gravity/text-field'
import { Select } from '@gravity/select'
import { useToast } from '@gravity/toast'

import { useMutateOnUpdateUserAccess } from '@/modules/access-management/hooks/queries/user-access'
import { useMutateOnUpdateUserProfile } from '@/modules/access-management/hooks/queries/user-profile'

import { queryClient } from '@/modules/app/contexts/GlobalProviders/ReactQuery'

import { UpdateUserAccessForm, updateUserAccessSchema } from './schema'
import { getAvailableOptions } from './utils/getAvailableOptions'

import type { User } from '@/modules/access-management/models/User'
import type { School } from '@/shared/models/School'
import type { UserGroupNames } from '@/shared/integrations/sorting-hat/types'
import type { Roles } from '@/shared/contexts/Auth'

import * as Styled from './styles'

interface Props {
  actionUserId: string
  currentSchool?: Pick<School, 'id' | 'name'>
  isOpen: boolean
  loggedUserGroupName?: UserGroupNames
  onClose: () => void
  user?: User
  userRoles: Set<Roles>
}

export const AccessEditDialog = ({
  userRoles,
  user,
  actionUserId,
  loggedUserGroupName,
  currentSchool,
  isOpen,
  onClose,
}: Props) => {
  const { toast } = useToast()

  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    reset,
  } = useForm<UpdateUserAccessForm>({
    mode: 'all',
    resolver: zodResolver(updateUserAccessSchema),
    defaultValues: {
      school: {
        name: currentSchool?.name ?? '',
        id: currentSchool?.id ?? '',
      },
    },
  })

  useEffect(() => {
    if (!user) return

    setValue('userId', user.id)
    setValue('email', user.email)
    setValue('group', user.group_name)
    setValue('lastName', user.last_name)
    setValue('firstName', user.first_name)
  }, [user])

  const loggedUserId = actionUserId !== user?.id

  const {
    mutateAsync: mutateOnUpdateUserAccess,
    isLoading: isLoadingUpdateAccess,
  } = useMutateOnUpdateUserAccess({
    onSuccess() {
      queryClient.invalidateQueries(['access-management', 'users'])
      toast({ type: 'success', title: 'Alterações salvas' })
    },
    onError: () => toast({ type: 'error', title: 'Erro ao salvar alterações' }),
  })

  const {
    mutateAsync: mutateOnUpdateUserProfile,
    isLoading: isLoadingUpdateProfile,
  } = useMutateOnUpdateUserProfile({
    onSuccess() {
      queryClient.invalidateQueries(['access-management', 'users'])
      toast({ type: 'success', title: 'Alterações salvas' })
    },
    onError: () => toast({ type: 'error', title: 'Erro ao salvar alterações' }),
  })

  const { errors, isValid, isDirty } = formState

  const availableAccessOptions = getAvailableOptions({
    userRoles,
    loggedUserGroupName: loggedUserGroupName,
    userToBeEditedGroupName: user?.group_name as UserGroupNames,
  })

  const handleCloseDialog = () => {
    reset()
    onClose()
  }

  const handleOpenChange = (open: boolean, isLoading: boolean) => {
    if (open || isLoading) return

    handleCloseDialog()
  }

  const onSubmit: SubmitHandler<UpdateUserAccessForm> = async ({
    userId,
    group,
    school,
    lastName,
    firstName,
  }) => {
    if (loggedUserId) {
      await mutateOnUpdateUserAccess({ userId, group, schoolId: school.id })
    } else {
      await mutateOnUpdateUserProfile({ lastName, firstName })
    }
    handleCloseDialog()
  }

  return (
    <DialogPrimitives.Root
      open={isOpen}
      onOpenChange={open => handleOpenChange(open, isLoadingUpdateProfile || isLoadingUpdateAccess)}
    >
      <DialogPrimitives.Portal>
        <DialogPrimitives.Overlay backdrop />

        <div data-testid="access-create">
          <DialogPrimitives.Content
            style={{ width: '100%' }} // TODO: dialog components should have this style
            size={3}
            title={`Editar acesso de ${user?.first_name} ${user?.last_name}`}
            aria-describedby={undefined}
            actionButton={
              <Button
                disabled={!isValid || !isDirty}
                color="accent"
                variant="solid"
                data-testid="submit-access"
                loading={isLoadingUpdateAccess || isLoadingUpdateProfile}
                onClick={handleSubmit(onSubmit)}
              >
                Salvar alterações
              </Button>
            }
            cancelButton={
              <Button
                variant="ghost"
                disabled={isLoadingUpdateAccess || isLoadingUpdateProfile}
                onClick={handleCloseDialog}
              >
                Cancelar
              </Button>
            }
          >
            <Styled.Content>
              <div className="email">
                <TextField
                  label="E-mail do usuário"
                  aria-label="E-mail do usuário"
                  placeholder="Digite o e-mail do novo usuário"
                  size={3}
                  data-testid="email"
                  error={!!errors?.email}
                  errorMessage={errors?.email?.message}
                  disabled
                  {...register('email')}
                />
              </div>

              <div className="access-type">
                <Controller
                  control={control}
                  name="group"
                  render={({ field }) => (
                    <Select
                      variant="surface"
                      label="Nível de acesso"
                      size={3}
                      options={availableAccessOptions}
                      placeholder="Selecione o nível de acesso"
                      value={field.value}
                      data-testid="group"
                      onValueChange={value => field.onChange(value)}
                      fullWidth
                      disabled={!loggedUserId}
                    />
                  )}
                />
              </div>

              <div className="school">
                <TextField
                  label="Unidade da escola"
                  aria-label="Unidade da escola"
                  placeholder="Nome da Unidade"
                  size={3}
                  disabled
                  data-testid="school"
                  {...register('school.name')}
                />
              </div>

              <div className="first-name">
                <TextField
                  label="Primeiro nome"
                  aria-label="Primeiro nome"
                  placeholder="Digite o nome"
                  size={3}
                  data-testid="firstName"
                  disabled={loggedUserId}
                  {...register('firstName')}
                />
              </div>

              <div className="last-name">
                <TextField
                  label="Sobrenome"
                  aria-label="Primeiro nome"
                  placeholder="Digite o sobrenome"
                  size={3}
                  data-testid="lastName"
                  disabled={loggedUserId}
                  {...register('lastName')}
                />
              </div>
            </Styled.Content>
          </DialogPrimitives.Content>
        </div>
      </DialogPrimitives.Portal>
    </DialogPrimitives.Root>
  )
}
