import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'

export const GuardianHeader = styled.div`
  padding-top: 50px;
  margin-bottom: 32px;
`

export const HeaderBox = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
})``

export const TitleBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 24px;
`

export const Title = styled(Typography).attrs({
  variation: 'headlineDesktopMedium',
})`
  margin: 0;
`

export const Subtitle = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  color: #6d6d6d;
`
