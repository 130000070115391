import { isEmpty } from 'ramda'

export const validateForm = (userIsAware, errors, isSameTaxId) => {
  if (isSameTaxId) {
    return {
      isValid: false,
      error: {
        title: 'Não é possível alterar para o mesmo CPF',
        description:
          'Para prosseguir, registre um novo CPF. Não é possível realizar a alteração de titularidade para o mesmo CPF do titular atual.',
      },
    }
  }

  if (errors?.taxId?.type === 'validate') {
    return {
      isValid: false,
      error: {
        title: 'Informe um número de CPF válido para prosseguir',
      },
    }
  }

  if (!isEmpty(errors)) {
    return {
      isValid: false,
      error: {
        title: 'Preenchimento incompleto',
        description: 'Para prosseguir é necessário preencher todos os campos obrigatórios',
      },
    }
  }

  if (!userIsAware) {
    return {
      isValid: false,
      error: {
        title: 'Aceite as condições de alteração',
        description: 'Para prosseguir é necessário aceitar as condições para a alteração',
      },
    }
  }

  return {
    isValid: true,
    error: {},
  }
}
