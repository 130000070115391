import styled from 'styled-components'

import { Link as LinkEl } from '../Link'

export const Link = styled(LinkEl)`
  .title {
    color: ${props => props.theme.gravity.colors['colors-background-accent-1']};
  }

  &:hover {
    .title {
      color: ${props => props.theme.gravity.colors['colors-text-main-5']};
    }
  }
`

export const Icon = styled.div`
  height: ${props => props.theme.gravity.spacing[6]};
  margin-bottom: ${props => props.theme.gravity.spacing[1]};
`
