import styled from 'styled-components'

export const ProductsContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const OtherProductsContainer = styled.div`
  display: flex;
  align-items: center;
`
