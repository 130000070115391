import type { GetCampaignsResponse } from '@/modules/enrollment/services/enrollmentService/types'
import { useFetchCampaigns } from '@/modules/enrollment/hooks/queries/useCampaignQueries'
import type { PaginationRequest } from '@/shared/interfaces'
import * as Sentry from '@sentry/react'
import type { QueryObserverResult, UseQueryResult } from '@tanstack/react-query'

type Props = {
  campaignId?: string
  campaignsStatusFilter?: string[]
  pagination?: PaginationRequest
  schoolEnrollmentCycleId: string
  schoolId?: string
}
export function useCampaignsList({
  campaignId,
  campaignsStatusFilter,
  pagination,
  schoolEnrollmentCycleId,
  schoolId,
}: Props) {
  let result = {
    data: undefined,
    isSuccess: false,
    isLoading: false,
    refetch: () => Promise.resolve({} as QueryObserverResult<GetCampaignsResponse, unknown>),
  } as UseQueryResult<GetCampaignsResponse, unknown>

  try {
    result = useFetchCampaigns({
      campaignId,
      schoolEnrollmentCycleId: schoolEnrollmentCycleId,
      schoolId: schoolId,
      pagination: pagination,
      status: campaignsStatusFilter,
    })
  } catch (error) {
    Sentry.captureException(error)
  }
  const { data, isSuccess, isLoading, refetch } = result
  const campaigns = data?.data?.campaigns || []
  const tablePagination = data?.pagination || {}

  return {
    campaigns,
    tablePagination,
    isSuccess,
    isLoading,
    refetch,
  }
}
