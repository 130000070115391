import { useState } from 'react'
import styled from 'styled-components'
import { Button, DialogTitle, DialogContent, DialogActions } from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { useApi } from '@/shared/hooks'
import { Receivable } from '@/shared/interfaces'

const StyledTypography = styled(Typography)`
  font-size: 14px;
  line-height: 1.42857;
`

export type ChangePaymentDialogProps = {
  backButtonLabel?: string
  buttonLabel?: string
  centered?: boolean
  closeIcon?: boolean
  hideTitle?: boolean
  isConfirmDisabled?: () => boolean
  isPayableByCreditCard?: boolean
  isVisible: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  onClose: () => void
  receivableId: string
  replaceReceivables: (receivables: Array<Receivable>) => void
  setShowChangePaymentModal: (value: boolean) => void
  setShowFailedDialog: (value: boolean) => void
  submitHandler: () => void
  title?: string
}

export const ChangePaymentDialog = ({
  buttonLabel = 'Confirmar alteração',
  backButtonLabel,
  centered,
  closeIcon,
  isVisible,
  onClose,
  isPayableByCreditCard,
  setShowFailedDialog,
  title,
  isConfirmDisabled,
  hideTitle,
  maxWidth,
  receivableId,
  setShowChangePaymentModal,
  replaceReceivables,
}: ChangePaymentDialogProps) => {
  const { api } = useApi()
  const [successfulStep, setSuccessfulStep] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [newReceivable, setNewReceivable] = useState<Receivable>()

  const message = isPayableByCreditCard
    ? 'Ao alterar o pagamento para boleto, o responsável financeiro receberá a nova fatura por e-mail e poderá visualizá-la no aplicativo bancário. Além disso, um novo registro será gerado na conta da escola.'
    : 'Ao alterar o pagamento para cartão, a parcela selecionada só poderá ser paga por esse meio de pagamento. Após a confirmação, o boleto referente a essa parcela será automaticamente cancelado.'

  const messageFinalState = isPayableByCreditCard
    ? 'Você pode reverter essa alteração e gerar uma nova cobrança para pagamento em cartão para a parcela selecionada no menu anterior.'
    : 'Você pode reverter essa alteração e gerar um novo boleto para a parcela selecionada no menu anterior.'

  const secondaryMessage = 'Deseja confirmar a alteração?'

  const openFailedModal = () => {
    setShowFailedDialog(true)
    setShowChangePaymentModal(false)
  }

  const submitChangePaymentMethod = async () => {
    setIsLoading(true)
    return await api.receivables
      .changePaymentMethod(receivableId)
      .then(data => {
        setSuccessfulStep(true)
        setNewReceivable(data)
      })
      .catch(() => openFailedModal())
      .finally(() => setIsLoading(false))
  }
  const closeDialog = () => {
    replaceReceivables([newReceivable])
    setShowChangePaymentModal(false)
  }

  return (
    <Dialog
      data-testid="feedback-dialog"
      open={isVisible}
      onClose={onClose}
      maxWidth={maxWidth || 'sm'}
      fullWidth
    >
      {!hideTitle && (
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {successfulStep ? 'Link da cobrança gerado com sucesso' : title}
            {closeIcon && (
              <IconButton
                disabled={isLoading}
                onClick={onClose}
                data-testid="close-feedback-button"
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </DialogTitle>
      )}
      <DialogContent>
        <Box justifyContent="center" textAlign="initial">
          {successfulStep ? (
            <StyledTypography>{messageFinalState}</StyledTypography>
          ) : (
            <StyledTypography>{message}</StyledTypography>
          )}
          <br />
          {!successfulStep && <StyledTypography>{secondaryMessage}</StyledTypography>}
        </Box>
      </DialogContent>
      <DialogActions className={`${centered ? 'centered' : ''}`}>
        {backButtonLabel && !successfulStep && (
          <Button variation="ghost" onClick={onClose} disabled={isLoading}>
            {backButtonLabel}
          </Button>
        )}
        <Button
          loading={isLoading}
          variation="primary"
          onClick={!successfulStep ? submitChangePaymentMethod : closeDialog}
          disabled={isConfirmDisabled?.()}
        >
          {successfulStep ? 'Ok, entendi' : buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangePaymentDialog
