import { Table } from '@gravity/table'

import { formatCentsToReal, formatDate } from '@/shared/utils'

import { PaymentDisputeSummary } from '@/modules/payment-dispute/models/PaymentDisputeSummary'
import { PaymentDisputeItem } from '@/modules/payment-dispute/models/PaymentDisputeItem'

import { Actions } from '../Actions'
import { usePaymentDisputeItemTableRow } from './hooks/usePaymentDisputeItemTableRow'
import { PaymentDisputeItemDeny } from '@/modules/payment-dispute/pages/PaymentDispute/components/PaymentDisputeItemDeny'

type PaymentDisputeItemTableRowProps = {
  paymentDispute: PaymentDisputeSummary
  paymentDisputeItem: PaymentDisputeItem
}

export const PaymentDisputeItemTableRow = ({
  paymentDispute,
  paymentDisputeItem,
}: PaymentDisputeItemTableRowProps) => {
  const { id, execution_date, receivable, student, product } = paymentDisputeItem

  const {
    title,
    color,
    actions,
    openPaymentDisputeDeny,
    handleDenyDispute,
    handleSendEvent,
  } = usePaymentDisputeItemTableRow({
    paymentDisputeItem,
    paymentDispute,
  })

  return (
    <Table.Row key={id}>
      <Table.TextCell>{formatDate(receivable.due_date, 'DD/MM/YYYY')}</Table.TextCell>
      <Table.TextCell>{student.name}</Table.TextCell>
      <Table.TextCell>{product.name}</Table.TextCell>
      <Table.TextCell>{formatDate(execution_date, 'DD/MM/YYYY')}</Table.TextCell>
      <Table.TextCell>{formatCentsToReal(receivable.amount)}</Table.TextCell>
      <Table.BadgeCell badgeVariant="soft" badgeColor={color}>
        {title}
      </Table.BadgeCell>

      {actions.length > 0 && <Table.ActionCell actions={<Actions actions={actions} />} />}

      <PaymentDisputeItemDeny
        paymentDisputeId={paymentDispute.id}
        paymentDisputeItemId={id}
        open={openPaymentDisputeDeny}
        onClose={handleDenyDispute}
        onSendEvent={handleSendEvent}
      />
    </Table.Row>
  )
}
