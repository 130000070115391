import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { Text } from '@gravity/text'

import { colors, spacing } from '@gravity/tokens'

export const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${spacing[6]};
  gap: ${spacing[4]};
`

export const InfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${spacing[3]};
`

export const InfoText = styled(Text)`
  color: ${colors['colors-text-main-3']};
`

export const InstallmentContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4]};
`

export const InstallmentDrawerActionWrapper = styled(Box)`
  display: flex;
  gap: ${spacing[2]};
`

export const InfoRowWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: ${spacing[1]};
`

export const FooterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${spacing[3]};
`

export const ButtonGroup = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]};
`
