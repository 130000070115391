import { ManagementDashboardsTab } from './types'

export const MANAGEMENT_QUERY_KEY = 'management'

export enum MANAGEMENT_DASHBOARDS_TABS {
  SCHOOL_GROUP = 'grupo-escolar',
  SCHOOL_UNIT = 'unidade-escolar',
}

export const DASHBOARDS_TABS: Array<ManagementDashboardsTab> = [
  {
    label: 'Receita da unidade',
    value: MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT,
  },
  {
    label: 'Receita do grupo escolar',
    value: MANAGEMENT_DASHBOARDS_TABS.SCHOOL_GROUP,
  },
]
