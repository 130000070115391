import ContractDetails from 'src/escolas/components/contract/ContractDetails'
import { ContractProvider } from '@/shared/contexts/contractContext'
import { InstallmentsProvider } from '@/shared/contexts/installmentsContext'
import { PagedDrawerProvider } from 'src/escolas/contexts/pagedDrawerContext'
import { ReceivablesAgglutinationProvider } from 'src/escolas/contexts/receivablesAgglutinationContext'
import { PageRouteProps } from 'src/escolas/router'
import { buildRoute } from '@/utils/router/utils'

const Page = () => (
  <ContractProvider>
    <InstallmentsProvider>
      <ReceivablesAgglutinationProvider>
        <PagedDrawerProvider>
          <ContractDetails />
        </PagedDrawerProvider>
      </ReceivablesAgglutinationProvider>
    </InstallmentsProvider>
  </ContractProvider>
)

const getPathname = ({ path }: PageRouteProps) => `${path}/:contractId`
const buildBreadcrumbs = ({ breadcrumbs }: PageRouteProps, path: string) => [
  ...breadcrumbs,
  { path, name: 'Contrato' },
]
const PageRoute = buildRoute(Page, getPathname, buildBreadcrumbs)
export default PageRoute
