import styled from 'styled-components'
import { Button } from '@gravity/button'
import { spacing } from '@gravity/tokens'
import { Typography } from '@olaisaac/design-system'

export const FormStyled = styled.div`
  margin-top: ${spacing[2]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]};
  height: 500px;
`

type CaptionProps = {
  hasError?: boolean
}

export const Caption = styled(Typography)<CaptionProps>`
  margin-top: ${props => (props.hasError ? spacing[6] : spacing[2])};
`

export const BtnSimulation = styled(Button)`
  margin-top: ${spacing[8]};
  width: 85px;
  display: flex;
  align-self: flex-end;
`
