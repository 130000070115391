import type { FinancialCategories } from '@/modules/report/models/FinancialCategories'
import type { Category } from '../../components/PayoutTransfersSectionGroup/types'

const CATEGORIES_ORDER: FinancialCategories[] = [
  'TRANSACTIONS',
  'FEE',
  'EVENTS',
  'OPERATIONAL_ADJUSTMENTS',
  'UNRECONCILED_PAYMENTS',
  'BONUS_AND_INCENTIVES',
  'CREDIT',
  'CONTRACTUAL_AGREEMENT',
  'ADDITIONAL_INCOME',
  'ADDITIONAL_DEDUCTIONS',
]

/**
 * Utility function to sort the payout financial categories
 *
 * @param categories Categories to be sorted
 *
 * @returns Sorted categories
 */
export const orderCategories = (categories: Category[]): Category[] => {
  categories.sort((a, b) => {
    const keyAIndex = CATEGORIES_ORDER.findIndex(key => key === a.key)
    const keyBIndex = CATEGORIES_ORDER.findIndex(key => key === b.key)

    return keyAIndex > keyBIndex ? 1 : -1
  })

  return categories
}
