import { external } from '@/utils/api/external'
import { Address } from '../interfaces'

export const UFs = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

export const validateZip = async (zipCode: string): Promise<boolean> => {
  if (zipCode && zipCode.replace('-', '').length === 8) {
    try {
      const isValid = await external.zipCode.isValid(zipCode)

      return isValid
    } catch {
      return false
    }
  }

  return false
}

export const formatGuardianAddress = (
  address: Omit<Address, 'created_at' | 'updated_at' | 'id'>
) => {
  const [street, number, additional_information, city, state_code] = address.street
    .trim()
    .split(',')

  return {
    additional_information,
    city,
    number: number?.trim(),
    state_code: state_code?.trim(),
    street,
    zip: address.zip,
  }
}
