import dayjs from 'dayjs'
import { Controller, UseFormReturn } from 'react-hook-form'
import { propEq } from 'ramda'
import { Typography, TextField } from '@olaisaac/design-system'
import MenuItem from '@material-ui/core/MenuItem'
import { Box, FormControl, InputLabel, Select } from '@material-ui/core'

import { date2PTFormat, formatCentsToReal, formatDate, validateEditReason } from '@/shared/utils'
import { Contract } from '@/shared/interfaces'
import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'

import { ContractEditForm, InstallmentChange } from '../../ContractEditDueDayDrawer'
import { EditAvailabilityWarning } from '../EditAvailabilityWarning/EditAvailabilityWarning'
import { StyledBodyLargeTypography } from './style'

export type SelectInitialInstallmentProps = {
  availableInstallments: InstallmentChange[]
  contract: Contract
  form: UseFormReturn<ContractEditForm>
  isContractInFlexiblePeriod: boolean
}

const SelectInitialInstallment = ({
  form,
  contract,
  availableInstallments,
  isContractInFlexiblePeriod,
}: SelectInitialInstallmentProps) => {
  const { control, getValues } = form
  const { installment_id } = getValues()
  const installment = contract?.installments?.find(propEq('id', installment_id))
  const orderReference = availableInstallments?.find(propEq('id', installment_id))?.orderReference
  const currentDueDay = availableInstallments?.find(propEq('id', installment_id))?.originalDueDate

  const { isAdmin } = useJWT()

  const isNewContractEditRulesEnabled =
    useUnleashFlag(UnleashFlags.ENABLE_NEW_RULES_FOR_SCHOOL_CONTRACT_EDITS) && !isAdmin

  return (
    <>
      <EditAvailabilityWarning
        isContractInFlexiblePeriod={isContractInFlexiblePeriod}
        isNewContractEditRulesEnabled={isNewContractEditRulesEnabled}
      />
      <div data-testid="select-initial-installment-due-day">
        <Box mb={3} mt={3}>
          <Typography variation="subtitleDesktopLarge">
            Selecione a partir de qual parcela a data de vencimento será editada:
          </Typography>
        </Box>
        <Box mb={2.5}>
          <Typography color="secondary">
            {isContractInFlexiblePeriod
              ? 'Aplicável a parcelas vencidas ou com vencimento no mês atual.'
              : 'Não se aplica a parcelas vencidas, negociadas ou com vencimento no mês atual.'}
          </Typography>
        </Box>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="installment_id">Número da parcela</InputLabel>
          <Controller
            rules={{ required: true }}
            control={control}
            name="installment_id"
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <Select
                labelId="installment_id"
                label="Número da parcela"
                value={value}
                onChange={e => onChange(e.target.value)}
                fullWidth
              >
                {availableInstallments?.map(({ id, index, current_amount, due_month }) => (
                  <MenuItem key={id} value={id}>
                    {`${index + 1}. ${formatDate(due_month, 'MMM YYYY')} - ${formatCentsToReal(
                      current_amount
                    )}`}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        {installment_id && (
          <Box mt={4}>
            <Typography variation="subtitleDesktopLarge">
              A edição será feita a partir da seguinte parcela:
            </Typography>
            <Box mt={2.5} display="flex" justifyContent="space-between">
              <StyledBodyLargeTypography color="secondary">Parcela</StyledBodyLargeTypography>
              <StyledBodyLargeTypography color="secondary">
                {orderReference}
              </StyledBodyLargeTypography>
            </Box>
            <Box mt={1} display="flex" justifyContent="space-between">
              <StyledBodyLargeTypography color="secondary">
                Valor da parcela
              </StyledBodyLargeTypography>
              <StyledBodyLargeTypography color="secondary">
                {formatCentsToReal(installment?.amount)}
              </StyledBodyLargeTypography>
            </Box>
            <Box mt={1} display="flex" justifyContent="space-between">
              <StyledBodyLargeTypography color="secondary">Vencimento</StyledBodyLargeTypography>
              <StyledBodyLargeTypography color="secondary">
                {date2PTFormat(dayjs(currentDueDay).toISOString())}
              </StyledBodyLargeTypography>
            </Box>
          </Box>
        )}
        {installment_id ? (
          <>
            <Box mt={4}>
              <Typography variation="subtitleDesktopLarge">
                Qual é o motivo da edição de vencimento do contrato?
              </Typography>
            </Box>
            <Box mt={2}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  rules={{
                    required: true,
                    maxLength: 100,
                    minLength: 5,
                    validate: validateEditReason,
                  }}
                  name="change_reason_additional_information"
                  control={control}
                  render={({ field: { value, ...rest }, fieldState: { error } }) => (
                    <TextField
                      {...rest}
                      label="Motivo"
                      id="change_reason_additional_information-input"
                      data-testid="change_reason_additional_information-input"
                      value={value}
                      error={Boolean(error)}
                      helperText={
                        error ? 'Deve ter entre 5 e 100 caracteres (somente letras e números)' : ''
                      }
                    />
                  )}
                />
              </FormControl>
            </Box>
          </>
        ) : null}
      </div>
    </>
  )
}

export default SelectInitialInstallment
