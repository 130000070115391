import type { AxiosInstance } from 'axios'
import {
  PersonScoreRequest,
  PersonScoreResponse,
  SchoolScoreRequest,
  SchoolScoreResponse,
} from './types'

export const scoreService = (fetcher: AxiosInstance) => {
  return {
    /**
     *
     * Function responsible for fetch the person score data
     *
     * @param {PersonScoreRequest} request - The request parameters.
     *
     * @param {string} request.school_id - The school ID
     * @param {string} request.tax_id - The tax ID of the person
     *
     * @returns The person score data
     */
    fetchPersonScore: async (request: PersonScoreRequest): Promise<PersonScoreResponse> => {
      const { data } = await fetcher.get<PersonScoreResponse>(`/score/person/${request.tax_id}`, {
        params: {
          school_id: request.school_id,
        },
      })
      return data
    },

    /**
     *
     * Function responsible for fetch the school score data
     *
     * @param {SchoolScoreRequest} request - The request parameters.
     *
     * @param {string} request.school_id - The school ID
     *
     * @returns The school score data
     */
    fetchSchoolScore: async (request: SchoolScoreRequest): Promise<SchoolScoreResponse> => {
      const { data } = await fetcher.get<SchoolScoreResponse>(`/score/school/${request.school_id}`)

      return data
    },
  }
}
