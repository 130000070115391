import { useContext } from 'react'
import { CheckoutStore } from '../contexts/checkoutContext'

const useCheckout = () => {
  const {
    addAllAvailableReceivableCheckout,
    addReceivableCheckout,
    baseAmount,
    clearCombinedReceivablesCheckout,
    combinedReceivablesCheckout,
    finalAmount,
    finalCheckoutList,
    lostDuePaymentDiscountValue,
    lostEarlyPaymentDiscountValue,
    fineAndInterestValue,
    includeDuePaymentDiscount,
    includeEarlyPaymentDiscount,
    includeFeesAndFines,
    numberOfInstallments,
    paymentMethod,
    setLostDuePaymentDiscountValue,
    removeReceivableCheckout,
    setLostEarlyPaymentDiscountValue,
    setFineAndInterestValue,
    setIncludeDuePaymentDiscount,
    setIncludeEarlyPaymentDiscount,
    setIncludeFeesAndFines,
    setNumberOfInstallments,
    setPaymentMethod,
  } = useContext(CheckoutStore)
  return {
    addAllAvailableReceivableCheckout,
    addReceivableCheckout,
    baseAmount,
    clearCombinedReceivablesCheckout,
    combinedReceivablesCheckout,
    finalAmount,
    finalCheckoutList,
    fineAndInterestValue,
    lostDuePaymentDiscountValue,
    lostEarlyPaymentDiscountValue,
    includeDuePaymentDiscount,
    includeEarlyPaymentDiscount,
    includeFeesAndFines,
    numberOfInstallments,
    paymentMethod,
    removeReceivableCheckout,
    setFineAndInterestValue,
    setLostDuePaymentDiscountValue,
    setLostEarlyPaymentDiscountValue,
    setIncludeDuePaymentDiscount,
    setIncludeEarlyPaymentDiscount,
    setIncludeFeesAndFines,
    setNumberOfInstallments,
    setPaymentMethod,
  }
}

export default useCheckout
