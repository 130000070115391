import { CheckoutCombinableReceivable } from '@/escolas/contexts/checkoutContext'
import { InstallmentV2 } from '../../services/types'
import {
  AgreementSimulationCombined,
  SimulationPaymentMethod,
} from '@/modules/guardians/models/agreement'

export const parseDownPaymentsToAgreentSimulationsCombined = (
  downPayments: CheckoutCombinableReceivable[],
  installments: InstallmentV2[]
): AgreementSimulationCombined[] =>
  downPayments.map(downPayment => {
    const origin_negotiation = installments.find(installment =>
      installment.urn.includes(downPayment.id)
    )?.origin_negotiation

    return {
      due_date: downPayment.due_date,
      due_payment_discount_applied: 0,
      early_payment_discount_applied: 0,
      exempted_fine: 0,
      exempted_interest: 0,
      fine_applied: 0,
      interest_applied: 0,
      original_amount: downPayment.original_amount,
      original_fine: 0,
      original_interest: 0,
      payment_plans: [
        {
          max_installments_allowed: 1,
          min_down_payment: downPayment.amount,
          payment_method: SimulationPaymentMethod.CREDIT_CARD,
        },
        {
          max_installments_allowed: 1,
          min_down_payment: downPayment.amount,
          payment_method: SimulationPaymentMethod.DEBIT_CARD,
        },
      ],
      perpetual_discount_applied: 0,
      credit_card_fee: 0,
      credit_card_fee_percentage: 0,
      receivable_ids: [downPayment.id],
      short_id: downPayment.id.substring(0, 6).toUpperCase(),
      total_amount: downPayment.amount,
      receivables: [downPayment],
      origin_negotiation,
      type: 'down_payment',
    }
  })
