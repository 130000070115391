import type { ConclusionProps } from './types'
import * as S from './styles'
import PaymentRow from '@/modules/guardians/Negotiation/steps/AgreementConclusionStep/components/PaymentRow'
import { InvoiceStatus } from '@/modules/guardians/Negotiation/steps/AgreementConclusionStep/components/PaymentRow/types'
import { LiquidationPaymentMethodAdminLabel } from '@/shared/interfaces'
import { Notification } from '@olaisaac/design-system'
import { useEffect } from 'react'
import { mixpanel, MixpanelEventsEnum } from 'src/shared/integrations'
import { paramsDict } from 'src/escolas/components/contract/utils'

const Conclusion = ({ liquidationInfo, onSuccess }: ConclusionProps) => {
  useEffect(() => {
    mixpanel.trackEvent({
      name: MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_FLOW_FINISHED,
      properties: {
        $receivableId: liquidationInfo?.receivableId,
        $status: paramsDict.PAID,
      },
    })
  }, [])
  return (
    <S.Wrapper>
      <Notification description="Pagamento realizado com sucesso!" variation="success" />
      <PaymentRow
        amount={liquidationInfo.amount}
        due_date=""
        formOfPaymentLabel={
          liquidationInfo?.paymentMethod
            ? LiquidationPaymentMethodAdminLabel[liquidationInfo.paymentMethod]
            : 'Conta isaac'
        }
        idForReceipt={liquidationInfo.receivableId}
        installmentLabel={liquidationInfo.orderReference}
        invoiceStatus={InvoiceStatus.PAID}
        paymentLink=""
        paid_date={liquidationInfo.paidDate}
        onSuccess={onSuccess}
      />
    </S.Wrapper>
  )
}

export default Conclusion
