import { RefObject, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

/**
 * Hook that scrolls a given ref to the top everytime history changes,
 * using react router dom's history.listen feature
 *
 * @param ref Ref to be scrolled to the top
 */
export function useScrollToTop(ref?: RefObject<HTMLElement>) {
  const history = useHistory()
  const previousPathname = useRef(history.location.pathname)

  useEffect(() => {
    const unlisten = history.listen(location => {
      if (location.pathname !== previousPathname.current) {
        ref?.current?.scrollTo?.({ top: 0 })
        previousPathname.current = location.pathname
      }
    })

    return () => {
      unlisten()
    }
  }, [history, ref])
}
