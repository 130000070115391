import { fontSize, fontWeight, spacing, colors } from '@gravity/tokens'
import { StepContent, StepLabel } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

export const BaseStepLabel = styled(StepLabel)`
  font-size: ${fontSize.base};
  .MuiStepLabel-label {
    margin-left: ${spacing[8]};
  }
`

export const TitleStepLabel = styled(BaseStepLabel)`
  white-space: pre-line;
  .MuiStepLabel-label {
    font-size: ${fontSize['2xl']};
    font-weight: ${fontWeight.medium};
    line-height: 2.4rem;
    margin-bottom: ${spacing[2]};
    margin-left: 1.6rem;
  }
`

export const BaseStepContent = styled(StepContent)<{ isTitle: boolean }>`
  &.MuiStepContent-root {
    margin-top: ${props => (props.isTitle ? '-45px' : spacing[1])};
    padding-top: ${props => (props.isTitle ? '50px' : null)};
  }
`

export const StepDescription = styled(Typography)`
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.normal};
  margin-left: ${spacing[8]};
  white-space: pre-line;
`
export const StepLabelText = styled(Typography)`
  margin-top: ${spacing[1]};
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.medium};
`

export const CompletedIcon = styled(CheckCircle)`
  color: ${colors['colors-text-semantic-colors-success-1']};
`

export const CompletedIconMargin = styled(CompletedIcon)`
  margin-bottom: ${spacing[9]};
`
