import { ForwardRefRenderFunction, forwardRef } from 'react'
import { ExpandMore } from '@material-ui/icons'

import { Button } from './styles'

type GhostPickerButtonProps = {
  label: string
  onClick: () => void
}

const GhostPickerButton: ForwardRefRenderFunction<HTMLButtonElement, GhostPickerButtonProps> = (
  { label, onClick },
  ref
) => {
  return (
    <Button ref={ref} onClick={onClick}>
      <p>{label}</p>

      <ExpandMore />
    </Button>
  )
}

/**
 * Ghost variation for date picker
 *
 * @param props
 * @param props.ref Component reference
 * @param props.label Button label
 * @param props.onClick On click callback function
 */
export const GhostPicker = forwardRef(GhostPickerButton)
