import { createContext, Dispatch, ReactNode, SetStateAction, useContext } from 'react'
import { Eligibility } from '@/shared/services/credit/types'
import { PersistedStatePrefix, usePersistState } from '@/shared/hooks/usePersistState'
import { HOUR_IN_MILLIS } from '@/shared/utils'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

type OrderLock = {
  creditType: string
  isLocked: boolean
}

export type PayoutAnticipationContext = {
  eligibility: Eligibility
  historyPayoutDateSelected: string
  orderLock: OrderLock
  setEligibility: Dispatch<SetStateAction<Eligibility>>
  setHistoryPayoutDateSelected: Dispatch<SetStateAction<string>>
  setOrderLock: Dispatch<SetStateAction<OrderLock>>
}

export const PayoutAnticipationContext = createContext<PayoutAnticipationContext>(null)

type PayoutAnticipationProviderProps = { children: ReactNode }

export const PayoutAnticipationProvider = ({ children }: PayoutAnticipationProviderProps) => {
  const { schoolSlug } = useSelectedSchool()

  const [eligibility, setEligibility] = usePersistState<Eligibility>(
    `${PersistedStatePrefix.PayoutAnticipationSimulation}:${schoolSlug}:eligibility`,
    null,
    { TTL_ms: HOUR_IN_MILLIS }
  )

  const [orderLock, setOrderLock] = usePersistState<OrderLock>(
    `${PersistedStatePrefix.PayoutAnticipationSimulation}:${schoolSlug}:orderLock`,
    null,
    { TTL_ms: HOUR_IN_MILLIS }
  )

  const [historyPayoutDateSelected, setHistoryPayoutDateSelected] = usePersistState<string>(
    `${PersistedStatePrefix.PayoutAnticipationSimulation}:${schoolSlug}:historyPayoutDateSelected`,
    null,
    { TTL_ms: HOUR_IN_MILLIS }
  )

  return (
    <PayoutAnticipationContext.Provider
      value={{
        eligibility,
        setEligibility,
        orderLock,
        setOrderLock,
        historyPayoutDateSelected,
        setHistoryPayoutDateSelected,
      }}
    >
      {children}
    </PayoutAnticipationContext.Provider>
  )
}

export const useContextEligibility = () => {
  const { eligibility, setEligibility } = useContext(PayoutAnticipationContext)
  return { eligibility, setEligibility }
}

export const useContextOrderLock = () => {
  const { orderLock, setOrderLock } = useContext(PayoutAnticipationContext)
  return { orderLock, setOrderLock }
}

export const useContextHistoryPayoutDateSelected = () => {
  const { historyPayoutDateSelected, setHistoryPayoutDateSelected } = useContext(
    PayoutAnticipationContext
  )
  return { historyPayoutDateSelected, setHistoryPayoutDateSelected }
}
