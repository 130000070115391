import styled from 'styled-components'

import { colors, spacing } from '@gravity/tokens'
import { Button } from '@gravity/button'
import { Grid } from '@material-ui/core'

export const ButtonGroup = styled.div`
  display: flex;
  direction: column;
  align-items: center;
  margin-top: ${spacing[10]};
  gap: ${spacing[3]};
`

export const GhostButton = styled(Button)`
  min-width: 186px;
`

export const StyledGrid = styled(Grid)`
  width: 100%;
`

export const StyledOverlay = styled.div`
  opacity: 0.87;
  position: absolute;
  background: ${colors['colors-background-neutral-1']};
  top: ${spacing.none};
  right: ${spacing.none};
  left: ${spacing.none};
  bottom: ${spacing.none};
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
`
