import { TableColumns, Typography } from '@olaisaac/design-system'
import copy from 'copy-to-clipboard'
import { Tooltip } from '@material-ui/core'
import { OpenInNewRounded, InfoOutlined } from '@material-ui/icons'

import {
  Status,
  paramsDict,
} from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/Status/Status'
import { formatCentsToReal, formatDate } from '@/shared/utils'
import { ReceivableStatuses } from '@/shared/interfaces'
import { CheckoutCombinableReceivable } from '@/escolas/contexts/checkoutContext'
import { openPdf } from '@/modules/guardians/Negotiation/helpers/openPdf'
import ContentCopy from 'src/escolas/assets/contentCopy.svg'
import { useSnackbar } from '@/shared/hooks'

import { ActionButton, ButtonImg } from '../components/ActionButton'
import * as Styled from '../styles'
import { useEvents } from './useEvents'

function hasValidPaymentLink(receivable: CheckoutCombinableReceivable) {
  return !!receivable.invoice_link && receivable.status !== ReceivableStatuses.OVERDUE
}

export const useTableColumns = (receivables: CheckoutCombinableReceivable[]) => {
  const receivablesWithPaymentLink = receivables.filter(hasValidPaymentLink)
  const showCopyAllBtn = receivablesWithPaymentLink.length > 1

  const { setSnackBar } = useSnackbar()
  const {
    sendEventCopyAllPaymentLinks,
    sendEventCopyPaymentLink,
    sendEventOpenPaymentLink,
  } = useEvents()

  function getCopyTextForReceivable(receivable: CheckoutCombinableReceivable) {
    const amount = formatCentsToReal(receivable.amount)
    const status = paramsDict[receivable.status]?.text ?? receivable.status
    const dueDate = formatDate(receivable.due_date, 'DD/MM/YYYY')
    return `Valor: ${amount} | Situação: ${status} | Data de vencimento: ${dueDate} | Produto: ${receivable.product} | Aluno: ${receivable.student} | Link para pagamento: ${receivable.invoice_link}`
  }

  function copyLink(text: string) {
    copy(text)
    setSnackBar('Link copiado com sucesso', 'success')
  }

  function copyInvoiceLink(receivable: CheckoutCombinableReceivable) {
    const textToCopy = getCopyTextForReceivable(receivable)
    copyLink(textToCopy)
    sendEventCopyPaymentLink()
  }

  function copyAllInvoiceLinks() {
    const textToCopy = receivablesWithPaymentLink.map(getCopyTextForReceivable).join('\n')
    copyLink(textToCopy)
    sendEventCopyAllPaymentLinks()
  }

  function openPaymentLink(receivable: CheckoutCombinableReceivable) {
    sendEventOpenPaymentLink()
    openPdf(receivable.invoice_link)
  }

  function renderActionButtons(receivableId: string) {
    const receivable = receivables.find(receivable => receivable.id === receivableId)

    const paymentLinkComponent = hasValidPaymentLink(receivable) ? (
      <>
        <ActionButton onClick={() => openPaymentLink(receivable)}>
          Abrir fatura
          <OpenInNewRounded fontSize="small" color="primary" style={{ marginLeft: '8px' }} />
        </ActionButton>
        <ActionButton onClick={() => copyInvoiceLink(receivable)}>
          Copiar link
          <ButtonImg src={ContentCopy} />
        </ActionButton>
      </>
    ) : (
      <Tooltip title="A fatura está expirada e uma negociação deve ser realizada para que possa ser paga. Oriente o responsável a negociar via aplicativo Meu isaac ou Central de Apoio isaac.">
        <div>
          <ActionButton disabled>
            O link da fatura expirou
            <InfoOutlined fontSize="small" color="disabled" style={{ marginLeft: '8px' }} />
          </ActionButton>
        </div>
      </Tooltip>
    )
    return <Styled.AlignTableContent>{paymentLinkComponent}</Styled.AlignTableContent>
  }

  const columns: TableColumns = [
    {
      headerName: 'Valor',
      field: 'amount',
      renderCell: value => (
        <Typography variation="bodySmall">{formatCentsToReal(value)}</Typography>
      ),
      small: true,
    },
    {
      headerName: 'Situação',
      field: 'status',
      renderCell: value => (
        <Status
          status={value}
          renderText={text => <Typography variation="bodySmall">{text}</Typography>}
        />
      ),
      small: true,
    },
    {
      headerName: 'Data de vencimento',
      field: 'due_date',
      renderCell: value => (
        <Typography variation="bodySmall">{formatDate(value, 'DD/MM/YYYY')}</Typography>
      ),
      small: true,
    },
    {
      headerName: 'Produto',
      field: 'product',
      small: true,
    },
    {
      headerName: 'Aluno',
      field: 'student',

      small: true,
    },
    {
      headerName: showCopyAllBtn && (
        <ActionButton onClick={copyAllInvoiceLinks}>
          Copiar todos os links
          <ButtonImg src={ContentCopy} />
        </ActionButton>
      ),
      field: 'id',
      renderCell: renderActionButtons,
      small: false,
    },
  ]

  return columns
}
