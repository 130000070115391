import {
  DiscountType,
  LiquidationInfoResponse,
  Receivable,
  ReceivableStatuses,
} from '@/shared/interfaces'
import { dateToOnlyDateISO, formatCentsToReal, isDayBeforeToday, isDayToday } from '@/shared/utils'
import {
  DiscountFieldType,
  InfoLiquidationValues,
  ManualLiquidationDiscountPageForm,
} from './types'
import { useForm } from 'react-hook-form'
import dayjs, { Dayjs } from 'dayjs'
import { EDIT_FIELDS_STATUS, FIELDS_STATUS_ADMIN, FIELDS_STATUS_SCHOOL } from './constants'
import { REALMS } from '@/shared/constants'
import { MixpanelEventsEnum, mixpanel } from '@/shared/integrations'
import { Discount, InstallmentV2 } from '@/modules/guardians/services/types'

export const getDiscountWarningMessage = (liquidationInfo, isRenegotiated) => {
  let msg = ''
  const duePaymentDiscountChange =
    liquidationInfo?.lost_due_payment_discount - liquidationInfo?.previous_due_payment_discount
  const earlyPaymentDiscountChange =
    liquidationInfo?.lost_early_payment_discount - liquidationInfo?.previous_early_payment_discount

  if (!duePaymentDiscountChange && !earlyPaymentDiscountChange) {
    if (isRenegotiated) {
      msg = 'A parcela já foi renegociada e não pode receber desconto adicional.'
      return msg
    }
  }

  const getDifferLabel = change => (change > 0 ? 'aumentou' : 'diminui')

  const getDiscountMessage = (msg, change, discountType) =>
    `${msg.length > 0 ? ' e' : ''} ${getDifferLabel(change)} ${formatCentsToReal(
      change
    )} por perda ${discountType}`

  let discountChangeMessage = ''
  let discountChangeAmount = 0
  if (duePaymentDiscountChange && earlyPaymentDiscountChange) {
    discountChangeAmount = duePaymentDiscountChange + earlyPaymentDiscountChange
    discountChangeMessage = 'dos descontos'
  } else if (duePaymentDiscountChange) {
    discountChangeAmount = duePaymentDiscountChange
    discountChangeMessage = 'do desconto de pontualidade'
  } else if (earlyPaymentDiscountChange) {
    discountChangeMessage = 'do desconto de antecipação'
    discountChangeAmount = earlyPaymentDiscountChange
  }

  if (discountChangeMessage.length > 0) {
    msg += getDiscountMessage(msg, Math.abs(discountChangeAmount), discountChangeMessage)
  }
  if (msg.length > 0) {
    msg = `A mudança de data ${msg}. Adicionar outros descontos ao pagamento impacta o repasse.`
  }
  if (msg === '') {
    msg = 'Adicionar outros descontos ao pagamento impacta o repasse.'
  }

  return msg
}

export const discountsForm = receivable => {
  const mustApplyFinesAndInterest = receivable?.current_fine + receivable?.current_interest > 0
  const mustApplyEarlyPaymentDiscounts = Boolean(
    receivable?.discounts?.find(
      ({ type, days_before_due_date }) =>
        days_before_due_date > 0 && type === DiscountType.KEPT_ON_MANUAL_LIQUIDATION
    )
  )
  return useForm<ManualLiquidationDiscountPageForm>({
    mode: 'onChange',
    defaultValues: {
      additional_discount_amount: receivable?.current_additional_discount,
      apply_fines_and_interest_change: mustApplyFinesAndInterest,
      apply_lost_due_payment_discount: receivable?.current_due_payment_discount > 0,
      apply_lost_early_payment_discount: mustApplyEarlyPaymentDiscounts,
      apply_previous_fines_and_interest: mustApplyFinesAndInterest,
    },
  })
}
export const getReceivableStatus = receivable => {
  if (receivable?.status !== ReceivableStatuses.OPEN) {
    return receivable?.status
  }
  if (isDayBeforeToday(dateToOnlyDateISO(receivable?.due_date))) {
    return ReceivableStatuses.OVERDUE
  }
  if (isDayToday(dateToOnlyDateISO(receivable?.due_date))) {
    return ReceivableStatuses.DUE_TODAY
  }
  return receivable?.status
}

export const getFields = (realm: string, isEdition = false) => {
  if (isEdition) return EDIT_FIELDS_STATUS
  return realm === REALMS.SCHOOL_REALM ? FIELDS_STATUS_SCHOOL : FIELDS_STATUS_ADMIN
}

export const getAllDiscount = (receivable: Receivable) => {
  const getAllDiscountApplied = discounts =>
    discounts?.reduce(
      (acc, discount) => {
        acc[discount.type] = acc[discount.type] + discount.amount

        return acc
      },
      {
        [DiscountType.EARLY_PAYMENT]: 0,
        [DiscountType.DUE_PAYMENT]: 0,
        [DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION]: 0,
        [DiscountType.PERPETUAL]: 0,
        [DiscountType.KEPT_ON_MANUAL_LIQUIDATION]: 0,
      }
    )

  const listDiscount = getAllDiscountApplied(receivable?.discounts)

  const earlyDiscountAmount = listDiscount ? listDiscount[DiscountType.EARLY_PAYMENT] : 0
  const dueDiscountAmount = listDiscount ? listDiscount[DiscountType.DUE_PAYMENT] : 0
  const manualLiquidationDiscount = listDiscount
    ? listDiscount[DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION]
    : 0

  return {
    earlyDiscount: earlyDiscountAmount,
    manualLiquidationDiscount: manualLiquidationDiscount,
    dueDiscount: dueDiscountAmount,
  }
}

export const getFinalTotalDiscount = (
  liquidationInfo,
  totalDiscount,
  shouldRemoveDiscountButton
) => {
  return shouldRemoveDiscountButton ? liquidationInfo?.final_discount || 0 : totalDiscount
}

export const getFinalAmount = discountInfo => {
  return discountInfo?.finalAmount
}

export const getInformationForPaymentDetail = (
  receivable,
  liquidationInfo,
  discountInfo,
  totalDiscount,
  paidDate,
  shouldRemoveDiscountButton = true
) => {
  const isOverDuePaid = liquidationInfo?.fine_and_interest > 0

  const interest = !isOverDuePaid
    ? 0
    : liquidationInfo?.final_fine_and_interest - receivable?.current_fine

  const fine = !isOverDuePaid ? 0 : receivable?.current_fine

  const discountApplied = getAllDiscount(receivable)

  const liquidationValues: InfoLiquidationValues = {
    addedDiscounts: discountApplied?.manualLiquidationDiscount,
    dueDiscount: discountApplied?.dueDiscount - (liquidationInfo?.lost_due_payment_discount || 0),
    earlyDiscount:
      discountApplied?.earlyDiscount - (liquidationInfo?.lost_early_payment_discount || 0),
    fine: fine,
    interest: interest,
    originalAmount: liquidationInfo?.final_base_amount || 0,
    paidDate: paidDate,
  }

  const finalAmount = getFinalAmount(discountInfo)

  const finalTotalDiscount = getFinalTotalDiscount(
    liquidationInfo,
    totalDiscount,
    shouldRemoveDiscountButton
  )

  return { finalAmount, finalTotalDiscount, liquidationValues }
}

export const sendEvent = (
  receivableid,
  receivableStatus,
  event: MixpanelEventsEnum,
  properties?: Record<`$${string}`, any>
) => {
  mixpanel.trackEvent({
    name: event,
    properties: {
      ...properties,
      $receivableId: receivableid,
      $status: receivableStatus,
    },
  })
}
export const sendManualLiquidationEvents = (values, receivableId: string, status) => {
  sendEvent(
    receivableId,
    status,
    MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_ACCOUNT_CHOSEN_SAVED,
    {
      $account: values.source,
    }
  )
  sendEvent(
    receivableId,
    status,
    MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PAYOUT_METHOD_CHOSEN_SAVED,
    {
      $paymentMethod: values.payment_method,
    }
  )
  const paidDate = dayjs(values.paid_date)
  const diffTime = Math.abs(Date.now() - Number(paidDate))
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  sendEvent(
    receivableId,
    status,
    MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_DATE_CHOSEN_SAVED,
    {
      $paidDate: paidDate.toString(),
      $differenceBetweenTodayAndPaidDate: diffDays - 1,
    }
  )
}

export const getIdFromUrn = (urn?: string) => {
  const splittedUrn = urn?.split(':')
  if (splittedUrn?.length > 1) {
    return splittedUrn[2]
  }
  return urn
}

export const discountsFormByInstallment = (installment: InstallmentV2) => {
  const mustApplyFinesAndInterest = installment?.current_fine + installment?.current_interest > 0
  const mustApplyEarlyPaymentDiscounts = installment?.discounts?.some(
    ({ type, days_before_due_date }) =>
      days_before_due_date > 0 && type === DiscountType.KEPT_ON_MANUAL_LIQUIDATION
  )
  return useForm<ManualLiquidationDiscountPageForm>({
    mode: 'onChange',
    defaultValues: {
      additional_discount_amount: installment?.current_additional_discount,
      apply_fines_and_interest_change: mustApplyFinesAndInterest,
      apply_lost_due_payment_discount: installment?.current_due_payment_discount > 0,
      apply_lost_early_payment_discount: mustApplyEarlyPaymentDiscounts,
      apply_previous_fines_and_interest: mustApplyFinesAndInterest,
    },
  })
}

export const getReceivableStatusByInstallment = (installment: InstallmentV2) => {
  if (installment?.status !== ReceivableStatuses.OPEN) {
    return installment?.status as ReceivableStatuses
  }
  if (isDayBeforeToday(dateToOnlyDateISO(installment?.due_date))) {
    return ReceivableStatuses.OVERDUE
  }
  if (isDayToday(dateToOnlyDateISO(installment?.due_date))) {
    return ReceivableStatuses.DUE_TODAY
  }
  return installment?.status as ReceivableStatuses
}

export const getAllDiscountByInstallment = (installment: InstallmentV2) => {
  const getAllDiscountApplied = (discounts: Discount[]) =>
    discounts?.reduce(
      (acc, discount) => {
        acc[discount.type] = acc[discount.type] + discount.amount

        return acc
      },
      {
        [DiscountType.EARLY_PAYMENT]: 0,
        [DiscountType.DUE_PAYMENT]: 0,
        [DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION]: 0,
        [DiscountType.PERPETUAL]: 0,
        [DiscountType.KEPT_ON_MANUAL_LIQUIDATION]: 0,
      }
    )

  const listDiscount = getAllDiscountApplied(installment?.discounts)

  const earlyDiscountAmount = listDiscount ? listDiscount[DiscountType.EARLY_PAYMENT] : 0
  const dueDiscountAmount = listDiscount ? listDiscount[DiscountType.DUE_PAYMENT] : 0
  const manualLiquidationDiscount = listDiscount
    ? listDiscount[DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION]
    : 0

  return {
    earlyDiscount: earlyDiscountAmount,
    manualLiquidationDiscount: manualLiquidationDiscount,
    dueDiscount: dueDiscountAmount,
  }
}

export const getInformationForPaymentDetailByInstallment = ({
  discountInfo,
  installment,
  liquidationInfo,
  paidDate,
  shouldRemoveDiscountButton = true,
  totalDiscount,
}: {
  discountInfo: DiscountFieldType
  installment: InstallmentV2
  liquidationInfo: LiquidationInfoResponse
  paidDate: Dayjs
  shouldRemoveDiscountButton?: boolean
  totalDiscount: number
}) => {
  const isOverDuePaid = liquidationInfo?.fine_and_interest > 0

  const interest = !isOverDuePaid
    ? 0
    : liquidationInfo?.final_fine_and_interest - installment?.current_fine

  const fine = !isOverDuePaid ? 0 : installment?.current_fine

  const discountApplied = getAllDiscountByInstallment(installment)

  const liquidationValues: InfoLiquidationValues = {
    addedDiscounts: discountApplied?.manualLiquidationDiscount,
    dueDiscount: discountApplied?.dueDiscount - (liquidationInfo?.lost_due_payment_discount || 0),
    earlyDiscount:
      discountApplied?.earlyDiscount - (liquidationInfo?.lost_early_payment_discount || 0),
    fine: fine,
    interest: interest,
    originalAmount: liquidationInfo?.final_base_amount || 0,
    paidDate: paidDate,
  }

  const finalAmount = getFinalAmount(discountInfo)

  const finalTotalDiscount = getFinalTotalDiscount(
    liquidationInfo,
    totalDiscount,
    shouldRemoveDiscountButton
  )

  return { finalAmount, finalTotalDiscount, liquidationValues }
}
