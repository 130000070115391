import { Category } from '../../components/PayoutTransfersSectionGroup/types'

const INSTALLMENT_VALUES_GROUP = ['TRANSACTIONS', 'EVENTS', 'FEE']

/**
 * Utility function to separate categories between installment values and other values group
 *
 * @param categories List of categories to be processed
 *
 * @returns Two elements array that represents the categories groups
 */
export const groupCategoriesIntoInstallmentAndOtherValues = (
  categories: Category[]
): [Category[], Category[]] => {
  return categories.reduce<[Category[], Category[]]>(
    (acc, item) => {
      if (INSTALLMENT_VALUES_GROUP.includes(item.key)) {
        return [[...acc[0], item], acc[1]]
      }

      return [acc[0], [...acc[1], item]]
    },
    [[], []]
  )
}
