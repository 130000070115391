import styled from 'styled-components'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import MuiTableCell from '@material-ui/core/TableCell'
import theme from 'src/shared/theme'

export const A4Sheet = styled.div`
  width: 1100px;
  height: 297mm;
  margin: auto;
  padding: ${theme.spacing(2)}px ${theme.spacing(4)}px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const DetailsBox = styled(Box)`
  border: 1px solid ${theme.palette.grey[400]};
  border-radius: ${theme.spacing(2)}px;
  padding: ${theme.spacing(2)}px ${theme.spacing(4)}px;
  margin: ${theme.spacing(4)}px 0;
`

export const BusinessName = styled(Typography).attrs(() => ({
  variant: 'body1',
  color: 'secondary',
}))`
  font-weight: bold;
`

export const BusinessInfo = styled(Typography).attrs(() => ({
  variant: 'body2',
  color: 'textSecondary',
}))``

export const DateEmission = styled(Typography).attrs(() => ({
  variant: 'body2',
  color: 'textSecondary',
}))`
  font-size: 14px;
`

export const CompanyInfo = styled(Box)`
  padding-right: ${theme.spacing(6)}px;
  & + & {
    margin-left: ${theme.spacing(6)}px;
  }
`

export const TotalAmountInfo = styled(Typography).attrs(() => ({
  color: 'secondary',
}))`
  font-size: 18px;
  font-weight: bold;
`

export const DisclaimerText = styled(Typography).attrs(() => ({
  variant: 'caption',
  display: 'block',
}))`
  margin-bottom: 4px;
`

export const TableCell = styled(MuiTableCell)`
  border: none !important;
  color: ${theme.palette.grey[700]};
  padding: ${theme.spacing(1)}px;
  white-space: nowrap;
  font-size: 14px;
  min-width: 110px;

  &.lbrk {
    white-space: initial;
  }

  tfoot & {
    padding: ${theme.spacing(2)}px 0;
  }
  tfoot &:not(:empty) {
    border-top: 1px solid ${theme.palette.grey[300]} !important;
  }
`

export const TableCellWithNoBorder = styled(MuiTableCell)`
  border: none !important;
  color: ${theme.palette.grey[700]};
  padding: ${theme.spacing(1)}px;

  &.lbrk {
    white-space: initial;
  }

  tfoot & {
    padding: ${theme.spacing(2)}px ${theme.spacing(1)}px;
  }
`
