import { InfoCardProps } from './types'
import { Card } from '@gravity/card'
import { ColumnContainer, DescriptionText, TitleText } from './styles'
import { Box } from '@material-ui/core'

export const InfoCard = ({ icon, title, description }: InfoCardProps) => {
  return (
    <Card style={{ borderRadius: '2rem', border: 'none' }}>
      <Box m={1}>
        <ColumnContainer>
          {icon} <TitleText>{title}</TitleText> <DescriptionText>{description}</DescriptionText>
        </ColumnContainer>
      </Box>
    </Card>
  )
}
