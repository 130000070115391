import { useApi } from '@/shared/hooks'
import { useMutation } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'

type Params = {
  enrollmentID: uuid
}

export const useAcceptPaymentPlan = (waitTime: number) => {
  const { api } = useApi()

  return useMutation({
    mutationFn: async (params: Params) => {
      const res = await api.enrollment.acceptPaymentPlan(params.enrollmentID)

      // Installment creation is an async process. The business rule defined that
      // "waitTime" seconds must be waited before trying to get the installment.
      await new Promise(resolve => setTimeout(resolve, waitTime))

      const installment = await api.installments.get(res.data.installment_id, {
        params: {
          include_receivables: true,
          include_invoice: true,
        },
      })

      return installment
    },
    onError: error => {
      Sentry.captureException(error)
    },
  })
}
