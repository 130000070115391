import { ComponentProps, ReactNode, FC } from 'react'
import { Button } from '@olaisaac/design-system'

import * as Styled from './styles'

interface Props extends ComponentProps<typeof Button> {
  children: ReactNode
}

export const ActionButton: FC<Props> = ({ children, ...buttonProps }) => {
  return (
    <Styled.Wrapper>
      <Button variation="ghost" fullWidth {...buttonProps}>
        {children}
      </Button>
    </Styled.Wrapper>
  )
}

export { ButtonImg } from './styles'
