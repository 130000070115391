const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})

const noFractionDigitsCurrencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 0,
})

const twoFractionDigitsFormatter = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 })

const oneFractionDigitsFormatter = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 1 })

export const formatNumberMicroCentsToReal = (v: number): number => {
  if ((v !== 0 && !v) || Math.abs(v) < 10000) {
    return 0
  }

  return +v.toString().slice(0, -4) / 100
}

export const formatMicroCentsToReal = (v: number): string => {
  const value = formatNumberMicroCentsToReal(v)

  if (value === 0) {
    return noFractionDigitsCurrencyFormatter.format(0)
  }

  return (value < 0 ? '- ' : '') + currencyFormatter.format(Math.abs(value))
}

export const formatCentsToReal = (v: string | number, formatZero?: boolean): string => {
  if (formatZero && +v === 0) return currencyFormatter.format(+v)
  return +v === 0
    ? noFractionDigitsCurrencyFormatter.format(+v)
    : currencyFormatter.format(+v / 100)
}

export const formatNumberToTwoFractionDigits = (v: string | number): string =>
  twoFractionDigitsFormatter.format(+v)

export const formatNumberToOneFractionDigits = (v: string | number): string =>
  oneFractionDigitsFormatter.format(+v)

export const leftZeroPadding = (num: number, size: number) => {
  const s = `000000000000${num}`
  return s.substr(s.length - size)
}

export const cleanSpecialCharacters = (v: string) => v && v.replace(/[^a-zA-Z0-9]/g, '')

export const formatReal = (number: any) => currencyFormatter.format(number)

export const formatDecimal = (number: number) => formatReal(number).replace(/[R$]\s?/g, '')

export const formatRealToCents = (value: string) =>
  value
    ? (parseFloat(value.split(/\s+/)[1].replace(/\./g, '').replace(/,/g, '.')) * 100).toFixed(0)
    : '0'

export const roundCents = (value: cents) => Math.round(value)

/**
 * Utility function to parse a integer value into a decimal value
 *
 * @example
 *
 * parseCentsToDecimal(-1050); // -R$ 10,50
 *
 * @param value Integer value to be parsed
 *
 * @returns Parsed value
 */
export const parseCentsToDecimal = (value: number) => value / 100

/**
 * Utility function to format a decimal number into a currency value
 *
 * @example
 *
 * formatCurrencyValue(-1000.50); // -R$ 1.000,50
 *
 * @param value Decimal value to be parsed
 *
 * @returns Parsed value
 */
export const formatCurrencyValue = (value: number): string => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  return formatter.format(value)
}

/**
 * reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/floor#decimal_adjustment
 * Adjusts a number to the specified digit.
 *
 * Example: decimalAdjust('round', 55.55, -1) // 55.6
 * decimalAdjust('floor', 34848.487, 2) // 34800
 *
 * @param {"round" | "floor" | "ceil"} type The type of adjustment.
 * @param {number} value The number.
 * @param {number} exp The exponent (the 10 logarithm of the adjustment base).
 * @returns {number} The adjusted value.
 */
export const decimalAdjust = (type: string, value: any, exp: number): number => {
  type = String(type)
  if (!['round', 'floor', 'ceil'].includes(type)) {
    throw new TypeError(
      "The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'."
    )
  }
  exp = Number(exp)
  value = Number(value)
  if (exp % 1 !== 0 || Number.isNaN(value)) {
    return NaN
  } else if (exp === 0) {
    return (Math as any)[type](value)
  }
  const [magnitude, exponent = 0] = value.toString().split('e')
  const adjustedValue: string = (Math as any)[type](`${magnitude}e${exponent - exp}`)
  // Shift back
  const [newMagnitude, newExponent = 0] = adjustedValue.toString().split('e')
  return Number(`${newMagnitude}e${+newExponent + exp}`)
}
