import { AccordionHeaderProps } from './types'
import { SessionTitleTypography, AccordionSummaryBox, StepBox } from './style'

const AccordionHeader = ({ title, step }: AccordionHeaderProps) => {
  return (
    <AccordionSummaryBox data-testid={`accordion-header-${title}`}>
      {step ? (
        <StepBox>
          <SessionTitleTypography>{step}</SessionTitleTypography>
        </StepBox>
      ) : null}
      <SessionTitleTypography>{title}</SessionTitleTypography>
    </AccordionSummaryBox>
  )
}

export default AccordionHeader
