import styled from 'styled-components'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'

export const CardFooter = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gravity.spacing[3]};
`

export const StyledDatepickerTrigger = styled(Datepicker.Trigger)`
  width: 275px;
`
