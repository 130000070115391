import { useState, useEffect } from 'react'
import { Button, Chip } from '@olaisaac/design-system'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { CardMenu } from 'src/escolas/components/contract/GuardianCard/CardMenu'
import MainInformation from 'src/escolas/components/contract/GuardianCard/MainContent'
import ContactInformation from 'src/escolas/components/contract/GuardianCard/ContactInformation'
import styled from 'styled-components'
import { GuardianValidationError, validateGuardianInfo } from 'src/shared/utils'
import { useContract } from 'src/escolas/hooks'
import { useJWT } from 'src/shared/hooks'

const StyledHiddenButton = styled.button`
  &,
  &:active,
  &:visited,
  &:focus-visible,
  &:focus {
    background-color: transparent;
    outline: none;
    border: 0;
    cursor: unset;
    height: 20px;
    width: 20px;
  }
`

const StyledCard = styled(Card)(
  ({
    theme: {
      primitiveTokens: {
        colors: { gray },
      },
    },
  }) => `
  background-color: ${gray[3]};
  border: 1px solid ${gray[5]};
`
)

const StyledCardContent = styled(CardContent)`
  position: relative;
`
type GuardianCardProps = {
  isProcessingAgglutination?: boolean
  openCancellationDrawer: () => void
}

export const GuardianCard = ({
  isProcessingAgglutination,
  openCancellationDrawer,
}: GuardianCardProps) => {
  const [isCancellationButtonVisible, setIsCancellationButtonVisible] = useState(false)
  const [guardianErrors, setGuardianErrors] = useState<Array<GuardianValidationError>>(null)
  const { contract } = useContract()
  const { isAdmin } = useJWT()
  const installments = contract?.installments
  const shouldEnableCancellationButton = isAdmin && contract && installments?.length > 0

  useEffect(() => {
    const validate = async () => {
      if (!contract) return
      const validationError = await validateGuardianInfo(contract?.guardian)
      setGuardianErrors(validationError)
    }
    validate()
  }, [contract])

  return (
    <StyledCard elevation={0} data-testid="guardian-card">
      <StyledCardContent>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} lg={6}>
            <MainInformation contract={contract} />
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={6}
            justifyContent="flex-end"
            alignItems="flex-start"
            direction="column"
          >
            <Grid item>
              <Box pt={{ xs: 2, lg: 0 }}>
                {guardianErrors && (
                  <Tooltip
                    title={guardianErrors.map((error, i) => (
                      <Box display="block" key={i}>
                        {' '}
                        {error.response}
                      </Box>
                    ))}
                  >
                    <Chip label="Inconsistência de dados cadastrais" variation="coral" />
                  </Tooltip>
                )}
                <ContactInformation guardian={contract?.guardian} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {shouldEnableCancellationButton && (
          <>
            <StyledHiddenButton
              onKeyPress={({ key }) =>
                key === 'I' && setIsCancellationButtonVisible(!isCancellationButtonVisible)
              }
            />
            {isCancellationButtonVisible && (
              <Button
                onClick={openCancellationDrawer}
                variation="primary"
                startIcon={<DeleteOutlineIcon />}
              >
                Cancelar Contrato
              </Button>
            )}
          </>
        )}
        <Box position="absolute" top={16} right={8}>
          <CardMenu
            isProcessingAgglutination={isProcessingAgglutination}
            openCancellationDrawer={openCancellationDrawer}
          />
        </Box>
      </StyledCardContent>
    </StyledCard>
  )
}

export default GuardianCard
