import { Dispatch, SetStateAction } from 'react'
import { Button, ButtonDocker } from '@olaisaac/design-system'
import { ContractDetailsDrawerState } from 'src/escolas/components/contract/ContractDetailsDrawer/constants'

type ContractDetailsDrawerActionsProps = {
  isSignable: boolean
  setDrawerState: Dispatch<SetStateAction<ContractDetailsDrawerState>>
}

export const ContractDetailsDrawerActions = ({
  isSignable,
  setDrawerState,
}: ContractDetailsDrawerActionsProps) =>
  isSignable && (
    <ButtonDocker>
      <Button
        fullWidth
        onClick={() => setDrawerState(ContractDetailsDrawerState.CONFIRM_MANUAL_SIGN)}
      >
        Registrar assinatura
      </Button>
    </ButtonDocker>
  )
