import { Notification } from '@olaisaac/design-system'
import { EditNotificationProps } from './types'

export const EditNotification: React.FC<EditNotificationProps> = ({
  isEdit,
  isNewEditRfNameEnabled,
}) => {
  const description =
    isNewEditRfNameEnabled && !isEdit
      ? 'Essas informações são essenciais para garantirmos o contato com o responsável financeiro.'
      : 'Essas informações são essenciais para garantirmos o contato com o responsável financeiro. As alterações irão refletir em todos os contratos desse responsável.'

  const title = isNewEditRfNameEnabled
    ? 'Mantenha os dados atualizados'
    : 'Cadastre os dados corretamente'

  const variation = isNewEditRfNameEnabled ? 'information' : 'warning'

  return <Notification description={description} title={title} variation={variation} />
}
