import { InstallmentsFiltersType } from '../types'

/**
 * @description Remove a filter from filters used in API.
 * @param filters Actual filters.
 * @param value Filter to be removed.
 * @returns Filters to be used in API.
 */
export const updatedFiltersToRemove = (
  filters: Partial<InstallmentsFiltersType>,
  value: string
) => {
  return Object.keys(filters).reduce((acc, key) => {
    const filter = filters[key as keyof InstallmentsFiltersType]

    if (Array.isArray(filter)) {
      if (filter.length === 0) return acc

      return {
        ...acc,
        [key]: filter.filter(item => item !== value),
      }
    }

    if (!Array.isArray(filter) && !filter) return acc

    return {
      ...acc,
      [key]: value === filter ? undefined : filter,
    }
  }, {} as InstallmentsFiltersType)
}
