import { Button, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import styled from 'styled-components'

export const AddDiscountButton = styled(Button).attrs(() => ({
  variant: 'outlined',
  color: 'primary',
  size: 'large',
  fullWidth: true,
  endIcon: <AddIcon />,
}))`
  text-transform: none;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 512px;
  margin-bottom: 32px;
`

const StyledTypography = styled(Typography).attrs({ withoutMargin: true })`
  overflow-wrap: break-word;
`

export const FieldTitleTypography = styled(StyledTypography)`
  line-height: 24px;
  margin-bottom: 4px;
  color: #414141;
`

export const FieldLegendTypography = styled(StyledTypography)`
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #6d6d6d;
`
