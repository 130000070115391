import { IrregularStudentContentProps } from './types'
import { Select } from '@gravity/select'
import { Button } from '@gravity/button'
import * as Styled from './styles'
import { useEffect, useState } from 'react'
import { useProducts } from '@/modules/products/hooks/queries/products'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { ErrorDialog } from '@/modules/enrollment/components/ErrorDialog'
import { GuardianIrregularityTable } from '../GuardianIrregularityTable'
import { InstallmentIrregularityTable } from '../InstallmentIrregularityTable'
import { useIrregularStudentsEvents } from '../../hooks/useIrregularStudentsEvents'
import { LoadingState } from '../LoadingState'
import { UnleashFlags, useSnackbar, useUnleashFlag } from '@/shared/hooks'
import { CustomDialog } from '../CustomDialog'
import { useCancelOpenIrregularitiesMutation } from '../../hooks/useIrregularStudentsQuery'
import { IrregularStudentStatus } from '../../types'

export const IrregularStudentContent = ({ student }: IrregularStudentContentProps) => {
  const { school } = useSelectedSchool()
  const [productOption, setProductOption] = useState<string>('')
  const { sendClickSelectProductEvent } = useIrregularStudentsEvents()
  const isCancelButtonEnabled = useUnleashFlag(
    UnleashFlags.INT_43_ENABLE_CANCEL_IRREGULARITY_BUTTON
  )
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)
  const cancelOpenIrregularitiesMutation = useCancelOpenIrregularitiesMutation()
  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()

  const productCountMap = student.enrollment_requests.reduce(
    (map: Record<string, number>, item) => {
      map[item.product_id] = (map[item.product_id] || 0) + 1
      return map
    },
    {}
  )

  const { data: productData, isLoading, isError, isFetched } = useProducts({
    schoolId: school.id,
    pagination: { page: 1, per_page: 100 },
    ids: Object.keys(productCountMap),
  })

  const doesProductExist = productData?.data?.length > 0

  const productOptions =
    isFetched && doesProductExist
      ? student.enrollment_requests.map((item, index) => {
          const productName = productData.data.find(product => product.id === item.product_id).name
          const label =
            productCountMap[item.product_id] > 1
              ? `${productName} (responsável ${item?.guardian_request?.name})`
              : productName
          return {
            label: label,
            value: index.toString(),
          }
        })
      : []

  useEffect(() => {
    if (productOptions?.length > 0) setProductOption(productOptions[0].value)
  }, [productData])

  if (isLoading) {
    return <LoadingState />
  }

  const selectedIndex = Number(productOption)

  const guardianIrregularities =
    student.enrollment_requests[selectedIndex]?.guardian_request?.guardian_irregularities

  const hasGuardianIrregularities = guardianIrregularities?.length > 0

  const installmentIrregularities = student.enrollment_requests[
    selectedIndex
  ]?.installment_requests?.reduce((accumulator, currentValue) => {
    return [
      ...accumulator,
      ...(Array.isArray(currentValue.installment_irregularities)
        ? currentValue.installment_irregularities
        : []),
    ]
  }, [])
  const hasInstallmentIrregularities = installmentIrregularities?.length > 0

  const showCancelIrregularitiesButton =
    isCancelButtonEnabled &&
    student.enrollment_requests?.[selectedIndex]?.status?.toLowerCase() !==
      IrregularStudentStatus.FINISHED

  if (isFetched && !doesProductExist) {
    return <ErrorDialog isError={!doesProductExist} />
  }

  const handleConfirmCancel = async () => {
    setIsOpenCancelModal(false)
    cancelOpenIrregularitiesMutation
      .mutateAsync(student.enrollment_requests[selectedIndex].id)
      .then(() => {
        setSnackbarMessage('As pendências foram excluídas desta lista.')
        setSnackbarIsOpen(true, {
          title: 'Pendências excluídas com sucesso',
          variation: 'success',
        })

        setTimeout(() => {
          location.reload()
        }, 1500)
      })
      .catch(() => {
        setSnackbarMessage(
          'As pendências não foram excluídas. Você pode tentar novamente mais tarde.'
        )
        setSnackbarIsOpen(true, {
          title: 'Erro ao excluir pendências.',
          variation: 'error',
        })
      })
  }

  return (
    <Styled.ContentContainer>
      {isOpenCancelModal && (
        <CustomDialog
          submitHandler={handleConfirmCancel}
          isVisible={isOpenCancelModal}
          onClose={() => setIsOpenCancelModal(false)}
        />
      )}
      <ErrorDialog isError={isError} />
      <Styled.SelectProductContainer>
        <Select
          size={2}
          label="Produto"
          variant="surface"
          defaultValue={productOptions[0].value}
          options={productOptions}
          onValueChange={value => setProductOption(value)}
          onOpenChange={open => open && sendClickSelectProductEvent()}
        />
      </Styled.SelectProductContainer>
      <Styled.IrregularityTableContainer>
        {hasGuardianIrregularities && (
          <GuardianIrregularityTable
            externalId={student.enrollment_requests[selectedIndex]?.external_id}
            guardianName={student.enrollment_requests[selectedIndex]?.guardian_request?.name}
            irregularityList={guardianIrregularities}
            referenceLink={student.enrollment_requests[selectedIndex]?.reference_link}
          />
        )}
      </Styled.IrregularityTableContainer>
      {hasInstallmentIrregularities && (
        <InstallmentIrregularityTable
          installmentRequests={student.enrollment_requests[selectedIndex]?.installment_requests}
          externalId={student.external_id}
          referenceLink={student.reference_link}
        />
      )}

      {showCancelIrregularitiesButton && (
        <Styled.CancelButtonContainer>
          <Button
            variant="outline"
            color="error"
            size={2}
            onClick={() => {
              setIsOpenCancelModal(true)
            }}
          >
            Excluir pendências
          </Button>
        </Styled.CancelButtonContainer>
      )}
    </Styled.ContentContainer>
  )
}
