import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import {
  UseGuardianFinanceSummaryQueryProps,
  UseGuardianFinanceSummaryQueryResponse,
} from '../../services/types'
import { useApi } from '@/shared/hooks'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { guardianService } from '../../services'

export const useGuardianFinanceSummaryQuery = (
  props: UseGuardianFinanceSummaryQueryProps,
  options?: UseQueryOptions<UseGuardianFinanceSummaryQueryResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApi()

  const service = guardianService(apiClient.privateApiV2)

  const queryKey = ['guardian-finance-summary', props.guardianId, props.schoolId]

  const { data: guardianFinanceSummary, isLoading, isRefetching, isError, isSuccess } = useQuery<
    UseGuardianFinanceSummaryQueryResponse,
    XMLHttpRequest
  >(
    queryKey,
    () => {
      return service.fetchGuardianFinanceSummary({
        guardianId: props.guardianId,
        schoolId: props.schoolId,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      retry: false,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: true,
      ...options,
    }
  )

  return {
    guardianFinanceSummary,
    isLoading,
    isRefetching,
    isError,
    isSuccess,
  }
}
