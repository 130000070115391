import { DiscountType } from '@/shared/interfaces'

const DiscountTitle = {
  [DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION]: 'Desconto na baixa',
  [DiscountType.DUE_PAYMENT]: 'Desconto de Pontualidade',
  [DiscountType.EARLY_PAYMENT]: 'Desconto de Antecipação',
  [DiscountType.KEPT_ON_MANUAL_LIQUIDATION]: '',
  [DiscountType.PERPETUAL]: 'Desconto de Permanente',
}

export const getDiscountTitle = (type: DiscountType) => {
  return DiscountTitle[type]
}
