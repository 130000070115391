import { mdiCheckCircleOutline, mdiCurrencyUsd } from '@mdi/js'

export type LogisticsTeachingMaterialTab = {
  icon: string
  label: string
  value: LOGISTICS_TEACHING_MATERIAL_TABS
}

export enum LOGISTICS_TEACHING_MATERIAL_TABS {
  DELIVERY_REGISTRY = 'registro_entregas',
  SALES_REPORT = 'relatorio_vendas',
}

export const LOGISTICS_TABS: Array<LogisticsTeachingMaterialTab> = [
  {
    icon: mdiCurrencyUsd,
    label: 'Relatório de vendas',
    value: LOGISTICS_TEACHING_MATERIAL_TABS.SALES_REPORT,
  },
  {
    icon: mdiCheckCircleOutline,
    label: 'Registro de entregas',
    value: LOGISTICS_TEACHING_MATERIAL_TABS.DELIVERY_REGISTRY,
  },
]
