import NumberFormat from 'react-number-format'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { dissoc } from 'ramda'
import { formatCentsToReal } from '@/shared/utils'
import { ChangeEvent } from 'react'
import Typography from '@material-ui/core/Typography'
import { CustomFormControl, DaysBeforeDueDateInput } from './style'
import { DiscountFormFieldsProps } from './types'
import { DEFAULT_DISCOUNTS_OPTIONS } from './constants'
import { getDaysBeforeDueDate } from '../DiscountForm/utils/getDaysBeforeDueDate'

function DiscountFormFields({
  totalAmountPaid,
  fields: { description, amount, daysBeforeDueDateLabel, daysBeforeDueDate },
}: DiscountFormFieldsProps) {
  const {
    fieldState: { invalid: invalidAmount },
  } = amount

  const {
    fieldState: { invalid: invalidDescription },
  } = description

  const percentageValue = (amount.field.value / totalAmountPaid) * 100

  const onPercentageValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const percent = inputValue.replace('%', '')
    const newDiscountAmount = (totalAmountPaid * +percent) / 100
    amount.field.onChange(newDiscountAmount)
  }

  const showDaysBeforeDueDateInput = daysBeforeDueDateLabel.field.value === 'Antes do vencimento'

  return (
    <>
      <Box mb={3} mt={1}>
        <Box mb={3}>
          <CustomFormControl>
            <TextField
              id={description.field.name}
              variant="outlined"
              label="Nome do desconto"
              {...description.field}
              error={invalidDescription}
              helperText={invalidDescription ? 'Insira um nome para o desconto' : ''}
            />
          </CustomFormControl>
        </Box>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={7}>
            <CustomFormControl>
              <NumberFormat
                {...dissoc('onChange', amount.field)}
                onValueChange={value => {
                  amount.field.onChange(value.floatValue)
                }}
                id={amount.field.name}
                customInput={TextField}
                variant="outlined"
                label="Valor"
                format={formatCentsToReal}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                error={invalidAmount}
                helperText={invalidAmount ? 'Desconto não pode ser zero ou superior a 100%' : ''}
              />
            </CustomFormControl>
          </Grid>
          <Grid item xs={5}>
            <NumberFormat
              id={`${amount.field.name}-percent`}
              onChange={onPercentageValueChange}
              customInput={TextField}
              variant="outlined"
              label="Percentual"
              suffix="%"
              value={percentageValue}
              decimalScale={2}
              InputProps={{
                inputProps: { min: 0, max: 100 },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={3}>
        <CustomFormControl>
          <InputLabel>Regra de desconto</InputLabel>
          <Select
            labelId={daysBeforeDueDateLabel.field.name}
            label="Regra de desconto"
            inputProps={{
              label: 'Regra de desconto',
            }}
            renderValue={v => <Typography>{v}</Typography>}
            {...daysBeforeDueDateLabel.field}
            onChange={event => {
              daysBeforeDueDateLabel.field.onChange(event.target.value)
              daysBeforeDueDate.field.onChange(getDaysBeforeDueDate(event.target.value as string))
            }}
          >
            {DEFAULT_DISCOUNTS_OPTIONS.map(({ name, value: optionValue, description }) => (
              <MenuItem key={optionValue} value={name} data-testid={name}>
                <Box display="flex" flexDirection="column">
                  <Typography>{name}</Typography>
                  <Typography variant="body2">{description}</Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </CustomFormControl>
      </Box>
      {showDaysBeforeDueDateInput && (
        <Box mb={3}>
          <CustomFormControl>
            <DaysBeforeDueDateInput
              id={daysBeforeDueDate.field.name}
              {...daysBeforeDueDate.field}
            />
          </CustomFormControl>
        </Box>
      )}
    </>
  )
}

export default DiscountFormFields
