import { z } from 'zod'
import { CategoryType, StatusType } from '../constants/form'

export const categoryItemSchema = z.object({
  text: z.string(),
  value: z.nativeEnum(CategoryType),
  selected: z.boolean().default(false),
})

export const statusItemSchema = z.object({
  text: z.string(),
  value: z.nativeEnum(StatusType),
  selected: z.boolean().default(false),
})

export const filterSchema = z.object({
  category: z.array(categoryItemSchema).optional(),
  status: z.array(statusItemSchema).optional(),
})

export type filterForm = z.infer<typeof filterSchema>
