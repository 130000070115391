import { Skeleton } from '@gravity/skeleton'
import { Box, Grid } from '@material-ui/core'
import { FooterRightContainer, ResponsiveContainer } from './styles'
import {
  SkeletonCards,
  SkeletonLargeCard,
  SkeletonSmallCard,
} from '../../../components/SkeletonCards'

export const LoadingHome = () => {
  return (
    <>
      <Box mx={8} mt={6}>
        <Skeleton radius={8} fullWidth height={485} />
      </Box>
      <Box ml={6} mr={6} pl={8} pt={6} pr={8}>
        <ResponsiveContainer>
          <Grid container>
            <Grid item style={{ marginTop: '18px', marginLeft: '46px' }}>
              <Skeleton radius={8} width={548} height={38} />
            </Grid>

            <Grid item style={{ marginTop: '64px', display: 'flex', flexDirection: 'row' }}>
              <SkeletonCards cardsNumber={3} />
            </Grid>

            <Grid item container style={{ marginTop: '128px' }}>
              <SkeletonLargeCard />

              <FooterRightContainer>
                <SkeletonSmallCard />
              </FooterRightContainer>
            </Grid>
          </Grid>
        </ResponsiveContainer>
      </Box>
    </>
  )
}
