import { FC } from 'react'
import { FormControlLabel, Radio, Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'
import { formOfPaymentTextMap } from './helpers/constants'
import type { FormOfPaymentItemProps } from './types'
import * as S from './styles'

const FormOfPaymentItem: FC<FormOfPaymentItemProps> = ({
  formOfPayment,
  isSelected,
  isPaymentFeeEnabled,
  onSelect,
}) => {
  const { title, subtitle, subtitleWithFee } = formOfPaymentTextMap[formOfPayment]

  return (
    <S.Wrapper alignItems="center">
      <FormControlLabel
        label=""
        value={formOfPayment}
        style={{ display: 'flex', width: '100%', marginBottom: 0 }}
        control={
          <>
            <Radio
              checked={isSelected}
              name={formOfPayment}
              onClick={() => onSelect(formOfPayment)}
            />
            <Box marginLeft={3}>
              <Typography withoutMargin variation="headlineDesktopXsmall" color="primary">
                {title}
              </Typography>
              <Typography
                withoutMargin
                variation="bodySmall"
                color="secondary"
                style={{ marginTop: 4 }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: isPaymentFeeEnabled && subtitleWithFee ? subtitleWithFee : subtitle,
                  }}
                />
              </Typography>
            </Box>
          </>
        }
      />
    </S.Wrapper>
  )
}

export default FormOfPaymentItem
