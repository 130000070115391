import styled from 'styled-components'
import {
  ActionDrawerHeader,
  DialogContent,
  Drawer,
  Typography,
  Divider,
} from '@olaisaac/design-system'
import { Box } from '@material-ui/core'

export const ResumeDrawer = styled(Drawer).attrs({
  open: true,
})`
  .MuiDrawer-paper {
    z-index: 200 !important;
  }
`

export const ResumeSubtitle = styled(Typography).attrs({
  variation: 'bodyLarge',
  color: 'secondary',
  withoutMargin: true,
})`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

export const ResumeActionDrawerHeader = styled(ActionDrawerHeader).attrs({
  title: 'Resumo',
  extraContent: (
    <ResumeSubtitle>Confira abaixo os dados do contrato atual desse responsável</ResumeSubtitle>
  ),
})``

export const ResumeDialogContent = styled(DialogContent)`
  padding-left: 0;
  padding-right: 0;
`
export const ResumeSectionBox = styled(Box).attrs({
  p: 3,
})``

export const ResumeTextBox = styled(Box).attrs({ display: 'flex', flexDirection: 'column' })``

export const ResumeSubtilePrimary = styled(Typography).attrs({
  variation: 'bodySmall',
  color: 'primary',
  withoutMargin: true,
})`
  font-weight: 400;
  font-size: 14px;
`

export const ResumeSubtileSecondary = styled(Typography).attrs({
  variation: 'subtitleDesktopSmall',
  color: 'secondary',
  withoutMargin: true,
})`
  font-weight: 500;
  font-size: 14px;
`

export const ResumeTextPrimary = styled(Typography).attrs({
  variation: 'bodySmall',
  color: 'primary',
  withoutMargin: true,
})`
  font-weight: 400;
  font-size: 14px;
`

export const ResumeTextSecondary = styled(Typography).attrs({
  variation: 'caption',
  color: 'secondary',
  withoutMargin: true,
})`
  font-weight: 400;
  font-size: 12px;
`

export const ResumeTextBold = styled(Typography).attrs({
  variation: 'bodySmall',
  color: 'primary',
  withoutMargin: true,
})`
  font-weight: bold;
  font-size: 16px;
`

export const ResumeDivider = styled(Divider).attrs({})`
  margin: 0;
`
