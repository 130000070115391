import { Box } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gravity.spacing[6]};
`

export const ActionsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const PageInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gravity.spacing[2]};
`
