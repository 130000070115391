import type { ReasonDropdownProps } from './types'
import { Container, StyledSelect, StyledTextArea } from './styles'
import { useState } from 'react'

const ReasonDropdown = ({
  reasons,
  title,
  reason,
  setReason,
  note,
  setNote,
}: ReasonDropdownProps) => {
  /* This is a workaround for an open issue on the Select component from Radix 
    https://github.com/radix-ui/primitives/issues/1658
    without this, when selecting an option the click is propagated to the button behind the select 
  */
  const [open, setOpen] = useState(false)

  return (
    <Container>
      <StyledSelect
        data-testid="reason-dropdown"
        open={open}
        onOpenChange={() => {
          setTimeout(() => {
            setOpen(!open)
          }, 25)
        }}
        size={3}
        variant="surface"
        placeholder="Selecione uma opção"
        label={title}
        options={reasons ?? []}
        onValueChange={value => {
          const reasonSelected = reasons?.find(e => e.value === value)
          if (reasonSelected) {
            setReason(reasonSelected)
          }
        }}
        defaultValue={reason?.value}
      />

      {reason?.requiredNote && (
        <StyledTextArea
          placeholder="Descreva o motivo (obrigatório)"
          value={note}
          onChange={e => setNote(e.target.value)}
          size={2}
        />
      )}
    </Container>
  )
}

export default ReasonDropdown
