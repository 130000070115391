import { useRouteMatch } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Button } from '@gravity/button'

import Icon from '@mdi/react'
import { mdiCurrencyUsd, mdiReload } from '@mdi/js'

import { Section, SectionPlaceholder } from '../Section'
import { NumericInfo, NumericInfoPlaceholder } from './components/NumericInfo'
import { Subsection, SubsectionPlaceholder } from '../Subsection'

import { useLoadFinancialInfo } from './hooks/useLoadFinancialInfo'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventPageName } from '@/escolas/models/EventPageName.enum'
import { EventIdentifierName } from '@/escolas/models/EventIdentifierName.enum'
import { ErrorContainer } from './styles'

export const MainInfoSection = () => {
  const { url } = useRouteMatch()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const {
    info: financialInfo,
    isError: isErrorFinancial,
    isLoading: isLoadingFinancial,
    refetch: refetchFinancialInfo,
  } = useLoadFinancialInfo(school?.id ?? '', !!school?.id)

  const handleRetry = () => {
    refetchFinancialInfo()
  }

  const handleClickInfo = (buttonName: string) => {
    if (buttonName && isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.HOME,
        name: EventDispatcherEvents.BUTTON_CLICK,
        identifierName: EventIdentifierName.OPEN_REPORT_PAGE,
        pageName: EventPageName.HOME,
        customProperties: { $button: buttonName },
      })
    }
  }

  if (isLoadingFinancial) {
    return (
      <SectionPlaceholder variation="primary" variantSkeleton="neutral">
        <SubsectionPlaceholder>
          {[3, 4, 5].map(item => (
            <li key={item}>
              <NumericInfoPlaceholder />
            </li>
          ))}
        </SubsectionPlaceholder>
      </SectionPlaceholder>
    )
  }

  if (isErrorFinancial) {
    return (
      <Section
        title="Não foi possível carregar suas principais informações"
        variation="secondary"
        variantText="subtitle-medium"
        description="Ocorreu um erro inesperado. Tente novamente mais tarde."
      >
        <ErrorContainer data-testid="error">
          <Button
            onClick={handleRetry}
            title="Recarregar"
            iconStart={<Icon path={mdiReload} size={1} />}
          >
            Recarregar
          </Button>
        </ErrorContainer>
      </Section>
    )
  }

  const infos = Object.values(financialInfo).filter(info => Number.isFinite(info.value))

  return (
    <Section
      title="Principais informações da escola"
      description="Um resumo dos números mais importantes que você precisa saber"
      variation="secondary"
      variantText="subtitle-medium"
    >
      <Subsection
        title="Financeiro"
        variantText="body-1-regular"
        icon={<Icon path={mdiCurrencyUsd} size={1} />}
        columnsCount={infos.length}
      >
        {infos.map(info => (
          <li key={`${info.path}`}>
            <NumericInfo
              href={`${url}/${info.path}`}
              title={info.title}
              value={info.value}
              isCurrency
              onClick={() => handleClickInfo(info.title)}
            />
          </li>
        ))}
      </Subsection>
    </Section>
  )
}
