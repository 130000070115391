import { Box } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import { formatCentsToReal } from '@/shared/utils'
import { StyledTypography, TextScondaryTypography } from './style'
import { DiscountPreviewCardProps } from './types'

const DiscountPreviewCard = ({
  amount,
  description,
  percentage,
  daysBeforeDueDate,
  daysBeforeDueDateOptionDescription,
  index,
}: DiscountPreviewCardProps) => (
  <div data-testid={`discount-${index}-preview-card`}>
    <Box mb={1}>
      <Typography>{description}</Typography>
      <TextScondaryTypography>Valor: </TextScondaryTypography>
      <StyledTypography display="inline">{formatCentsToReal(amount)}</StyledTypography>
      <TextScondaryTypography> —</TextScondaryTypography>
      <StyledTypography display="inline">{` ${percentage}%`}</StyledTypography>
    </Box>
    <Box mb={2}>
      {daysBeforeDueDate > 0 && <TextScondaryTypography>Válido até </TextScondaryTypography>}
      <TextScondaryTypography>{daysBeforeDueDateOptionDescription}</TextScondaryTypography>
    </Box>
  </div>
)

export default DiscountPreviewCard
