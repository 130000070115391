import { guardian1 } from './guardians'
import { ogino, student } from './students'

export const guardianStudents = {
  guardian: guardian1,
  guardian_id: guardian1.id,
  students: [
    { student_data: { name: student.name }, student_id: student.id },
    { student_data: { name: ogino.name }, student_id: ogino.id },
  ],
}
