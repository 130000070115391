import { memo, useCallback, useMemo, useRef } from 'react'
import { useToast } from '@gravity/toast'

import { Box, Grid } from '@material-ui/core'
import {
  ActionDrawerHeader,
  ActionDrawerHeaderProps,
  Button,
  ButtonDocker,
  Drawer,
  DrawerProps,
  Sticky,
} from '@olaisaac/design-system'
import styled from 'styled-components'

import type { Props as ProductFormProps } from './components/ProductForm'
import ProductForm from './components/ProductForm'

type Props = Pick<DrawerProps, 'open'> &
  ProductFormProps & {
    onClose: (
      event?: Parameters<NonNullable<ActionDrawerHeaderProps['onClose']>>[0]
    ) => ReturnType<NonNullable<ActionDrawerHeaderProps['onClose']>>
  }

const TITLE = {
  create: 'Criar produto',
  edit: 'Editar produto',
}

const SUBTITLE = {
  create: 'Produtos são os serviços que a sua escola oferece.',
  edit: 'As mudanças feitas só serão aplicadas a novos contratos.',
}

const TOAST: Record<'create' | 'edit', { description?: string; title: string }> = {
  create: {
    title: 'Produto salvo com sucesso!',
  },
  edit: {
    title: 'Produto editado com sucesso!',
    description: 'As alterações não afetarão contratos previamente atrelados ao produto.',
  },
}

const GridWhiteBackground = styled(Grid)`
  background: #fff;
`

export const CreateOrEditProductDrawer = memo(({ open, onClose, ...props }: Props) => {
  const form = useRef<HTMLFormElement>(null)
  const { toast } = useToast()

  const isEditing = useMemo(() => Boolean(props?.product), [props.product])

  const [title, subtitle] = useMemo(() => {
    const key = isEditing ? 'edit' : 'create'
    return [TITLE[key], SUBTITLE[key]]
  }, [isEditing])

  const handleSubmit = useCallback(() => {
    if (form.current) {
      form.current.requestSubmit()
    }
  }, [form.current])

  const handleSuccess = useCallback(() => {
    onClose()

    const method = isEditing ? 'edit' : 'create'

    toast({
      title: TOAST[method].title,
      description: TOAST[method].description,
      type: 'success',
    })
  }, [onClose, toast, isEditing])

  const handleError = useCallback(() => {
    toast({
      title: 'Não foi possível salvar o produto.',
      description: 'Por favor, tente novamente ou entre em contato com seu gerente no isaac.',
      type: 'error',
    })
  }, [toast])

  return (
    <Drawer open={open}>
      <ActionDrawerHeader onClose={onClose} title={title} subtitle={subtitle} />

      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        style={{ flex: 1 }}
      >
        <Box px={3} py={3}>
          {open && (
            <ProductForm
              {...props}
              onApiError={handleError}
              showEnvelopField={Boolean(props.product)}
              ref={form}
              onSuccess={handleSuccess}
            />
          )}
        </Box>
      </Grid>
      <Sticky position="bottom">
        <GridWhiteBackground item>
          <ButtonDocker>
            <Button fullWidth onClick={handleSubmit}>
              Salvar produto
            </Button>
          </ButtonDocker>
        </GridWhiteBackground>
      </Sticky>
    </Drawer>
  )
})
