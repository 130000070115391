import { Callback } from 'mixpanel-browser'

export enum MixpanelEventsEnum {
  ACTIVE_STUDENTS_FILTER = 'ACTIVE_STUDENTS_FILTER',
  BANNER_CLICK_NEVER_PAID = 'BANNER_CLICK_NEVER_PAID',
  CHANGE_ENROLLMENT_DATE_ON_DASHBOARD = 'CHANGE_ENROLLMENT_DATE_ON_DASHBOARD', // TODO: Replaced by ENROLLMENT_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD
  CHANGE_PAYOUT_DATE_ON_DASHBOARD = 'CHANGE_PAYOUT_DATE_ON_DASHBOARD', // TODO: Replaced by TUITION_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD
  CHANGE_REPORT_DATE = 'CHANGE_REPORT_DATE', // TODO: Replaced by ENROLLMENT_PAYOUT_DATE_CHANGE / TUITION_PAYOUT_DATE_CHANGE
  CHANGE_TABLE_ORDER = 'CHANGE_TABLE_ORDER',
  CHANGE_TABLE_VIEW = 'CHANGE_TABLE_VIEW',
  CONFIRMATION_INCOMING_REGISTRATION_NEGOTIATION_2_ENTRY = 'CONFIRMATION_INCOMING_REGISTRATION_NEGOTIATION_2_ENTRY',
  CREATE_CONTRACT = 'CREATE_CONTRACT',
  CREATE_CONTRACT_CLICK_HOME = 'CREATE_CONTRACT_CLICK_HOME',
  ENROLLMENT_2024_CLICK = 'ENROLLMENT_2024_CLICK',
  ENROLLMENT_CYCLE_CLICK_NEW_CONTRACT = 'ENROLLMENT_CYCLE_CLICK_NEW_CONTRACT',
  ENROLLMENT_CYCLE_CLICK_REENROLL = 'ENROLLMENT_CYCLE_CLICK_REENROLL',
  ENROLLMENT_DETAILS_PAGE_VISIT = 'ENROLLMENT_DETAILS_PAGE_VISIT',
  ENROLLMENT_PAYOUT_VIEW_HOME = 'ENROLLMENT_PAYOUT_VIEW_HOME',
  // TODO: Replaced by NAVIGATION_TO_ENROLLMENT_REPORT
  ENROLLMENT_PERFORMANCE_PAGE_VISIT = 'ENROLLMENT_PERFORMANCE_PAGE_VISIT',
  // TODO: Replaced by NAVIGATION_TO_ENROLLMENT_REPORT
  ENROLLMENT_STUDENT_CLICK_REENROLL = 'ENROLLMENT_STUDENT_CLICK_REENROLL',
  GUARDIAN_SEARCH_CLICK_HOME = 'GUARDIAN_SEARCH_CLICK_HOME',
  METHODS_INCOMING_EDIT_ADDITIONAL_DISCOUNT_CHANGED = 'METHODS_INCOMING_EDIT_ADDITIONAL_DISCOUNT_CHANGED',
  METHODS_INCOMING_EDIT_ANTICIPATION_DISCOUNT_CHANGED = 'METHODS_INCOMING_EDIT_ANTICIPATION_DISCOUNT_CHANGED',
  METHODS_INCOMING_EDIT_PENALTY_INTERESTS_CHANGED = 'METHODS_INCOMING_EDIT_PENALTY_INTERESTS_CHANGED',
  METHODS_INCOMING_EDIT_PUNCTUALITY_DISCOUNT_CHANGED = 'METHODS_INCOMING_EDIT_PUNCTUALITY_DISCOUNT_CHANGED',
  METHODS_INCOMING_EDIT_REASON_EDITED = 'METHODS_INCOMING_EDIT_REASON_EDITED',
  METHODS_INCOMING_EDIT_REASON_SAVED = 'METHODS_INCOMING_EDIT_REASON_SAVED',
  METHODS_INCOMING_REGISTRATION_ACCOUNT_CHOSEN_EDITED = 'METHODS_INCOMING_REGISTRATION_ACCOUNT_CHOSEN_EDITED',
  METHODS_INCOMING_REGISTRATION_ACCOUNT_CHOSEN_SAVED = 'METHODS_INCOMING_REGISTRATION_ACCOUNT_CHOSEN_SAVED',
  METHODS_INCOMING_REGISTRATION_ADDITIONAL_DISCOUNT_ADDED = 'METHODS_INCOMING_REGISTRATION_ADDITIONAL_DISCOUNT_ADDED',
  METHODS_INCOMING_REGISTRATION_ANTICIPATION_DISCOUNT_ADDED = 'METHODS_INCOMING_REGISTRATION_ANTICIPATION_DISCOUNT_ADDED',
  METHODS_INCOMING_REGISTRATION_DATE_CHOSEN_EDITED = 'METHODS_INCOMING_REGISTRATION_DATE_CHOSEN_EDITED',
  METHODS_INCOMING_REGISTRATION_DATE_CHOSEN_SAVED = 'METHODS_INCOMING_REGISTRATION_DATE_CHOSEN_SAVED',
  METHODS_INCOMING_REGISTRATION_FLOW_FINISHED = 'METHODS_INCOMING_REGISTRATION_FLOW_FINISHED',
  METHODS_INCOMING_REGISTRATION_PAGE_FLOW_BACK = 'METHODS_INCOMING_REGISTRATION_PAGE_FLOW_BACK',
  METHODS_INCOMING_REGISTRATION_PAYMENT_RECEIPT_DOWNLOAD = 'METHODS_INCOMING_REGISTRATION_PAYMENT_RECEIPT_DOWNLOAD',
  METHODS_INCOMING_REGISTRATION_PAYOUT_METHOD_CHOSEN_EDITED = 'METHODS_INCOMING_REGISTRATION_PAYOUT_METHOD_CHOSEN_EDITED',
  METHODS_INCOMING_REGISTRATION_PAYOUT_METHOD_CHOSEN_SAVED = 'METHODS_INCOMING_REGISTRATION_PAYOUT_METHOD_CHOSEN_SAVED',
  METHODS_INCOMING_REGISTRATION_PENALTY_INTERESTS_REMOVED = 'METHODS_INCOMING_REGISTRATION_PENALTY_INTERESTS_REMOVED',
  METHODS_INCOMING_REGISTRATION_PUNCTUALITY_DISCOUNT_ADDED = 'METHODS_INCOMING_REGISTRATION_PUNCTUALITY_DISCOUNT_ADDED',
  MONTH_TUITION_PAYOUT_CLICK_HOME = 'MONTH_TUITION_PAYOUT_CLICK_HOME',
  NEGOTIATE_INSTALLMENTS_CLICK = 'NEGOTIATE_INSTALLMENTS_CLICK',
  NEXT_TUITION_PAYOUT_VIEW_HOME = 'NEXT_TUITION_PAYOUT_VIEW_HOME',
  OPEN_FILTER_DRAWER = 'OPEN_FILTER_DRAWER',
  PAGE_VIEW = 'PAGE_VIEW',
  PAYMENT_REPORT_CLICK_HOME = 'PAYMENT_REPORT_CLICK_HOME',
  PAYOUT_DETAILS_PAGE_VISIT = 'PAYOUT_DETAILS_PAGE_VISIT',
  // TODO: Replaced by NAVIGATION_TO_PAYOUT_REPORT
  PAYOUTS_PAGE_VIEW = 'PAYOUTS_PAGE_VIEW',
  SEARCH_CONTRACTS_BY_STUDENT_NAME = 'SEARCH_CONTRACTS_BY_STUDENT_NAME',
  SEE_REPORT_SUMMARY = 'SEE_REPORT_SUMMARY',
  // TODO: Replaced by ENROLLMENT_PAYOUT_SUMMARY / TUITION_PAYOUT_SUMMARY
  SEE_STUDENT_REPORT_DETAILS = 'SEE_STUDENT_REPORT_DETAILS',
  // TODO: Replaced by ENROLLMENT_PAYOUT_REPORT_DETAILS / tuition_payout_report_details
  SHARE_AGREEMENT_DETAILS = 'SHARE_AGREEMENT_DETAILS',
  STUDENT_SEARCH_CLICK_HOME = 'STUDENT_SEARCH_CLICK_HOME',
  STUDENTS_FINANCIAL_DEBT_FILTER = 'STUDENTS_FINANCIAL_DEBT_FILTER',
  STUDENTS_FINANCIAL_DEBT_HOME = 'STUDENTS_FINANCIAL_DEBT_HOME',
  STUDENTS_FINANCIAL_WARNING_FILTER = 'STUDENTS_FINANCIAL_WARNING_FILTER',
  STUDENTS_FINANCIAL_WARNING_HOME = 'STUDENTS_FINANCIAL_WARNING_HOME',
  SUBMIT_CONTRACT = 'SUBMIT_CONTRACT',
  TOTAL_ACTIVE_STUDENTS_CLICK_HOME = 'TOTAL_ACTIVE_STUDENTS_CLICK_HOME',
  TOTAL_PAYOUT_YTD_HOME = 'TOTAL_PAYOUT_YTD_HOME',
}

export type MixpanelProperty = `$${string}`

export type IdentifyUserRequestDTO = {
  userId: string
}

export type TrackEventRequestDTO = {
  callback?: Callback
  name: MixpanelEventsEnum
  properties?: Record<MixpanelProperty, any>
}
