import { Box, useMediaQuery } from '@material-ui/core'

import { StyledTableCell, spacing } from './styles'

import type { TableCellProps } from './types'

const MAX_TEXT_LENGTH = 50

export const trimText = (text: string) =>
  text.length > MAX_TEXT_LENGTH ? `${text.substring(0, MAX_TEXT_LENGTH)}...` : text

export const TableCell = (props: TableCellProps) => {
  const { children, width = 'auto' } = props

  const isBiggerScreen = useMediaQuery('(min-width:1440px)')
  const height = isBiggerScreen ? spacing[16] : spacing[12]

  return (
    <StyledTableCell height={height} width={width} aria-disabled>
      <Box display="flex" alignItems="center">
        {children}
      </Box>
    </StyledTableCell>
  )
}
