import { useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { ActionDrawerHeader, Button, ButtonDocker, Divider, Drawer } from '@olaisaac/design-system'

import { FiltersContractsFormType, GuardianDetailsContractsFilterProps } from './types'

import { CollapsibleFilterGroup } from '@/escolas/components/CollapsibleFilterGroup'
import { ContractsFiltersType } from '../../types'
import LoadingProgress from '@/escolas/components/CircularProgress/CircularProgress'
import { FilterButton } from '../../../ListGuardians/components/FilterButton/FilterButton'
import { ActiveFilters } from '../ActiveFilters/ActiveFilters'
import { filtersGroup, transformFilters, updatedFiltersForm } from '../../utils'
import { getAllFilters } from '../../../GuardianDetails/utils/getAllFilters'
import { countSelectedFilters } from '../../utils/countSelectedFilters'
import { FilterOptionsType } from '@/modules/guardians/types'

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
  padding: 20px 0 10px;
  gap: ${({ theme }) => theme.primitiveTokens.spacing[4]};
`

export const GuardianDetailsContractsFilter = ({
  filters,
  updateFilters,
  filtersOptions,
  isFiltersOptionsFetched,
  typeOfContractGlossaryButton,
}: GuardianDetailsContractsFilterProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const defaultValues: any = {
    debt_statuses: [],
    status: undefined,
    product_ids: [],
    reference_years: [],
    negotiation_id: undefined,
  }

  const form = useForm<FiltersContractsFormType>({
    defaultValues,
  })

  const { setValue, handleSubmit, reset, formState } = form

  const handleApplyFilters: SubmitHandler<FiltersContractsFormType> = data => {
    const filters = updatedFiltersForm(data)

    const updatedFilters = Object.keys(filters).length > 0 ? filters : defaultValues

    updateFilters(updatedFilters)
    setIsOpen(false)
  }

  const handleClearFilter = () => {
    reset(defaultValues)
  }

  const handleClearFiltersLink = () => {
    handleClearFilter()
    handleApplyFilters(defaultValues)
  }

  useEffect(() => {
    Object.keys(filters).forEach(key => {
      const typedKey = key as keyof ContractsFiltersType
      const value = filters[typedKey]
      setValue(typedKey, (value as unknown) as FilterOptionsType, {
        shouldDirty: true,
      })
    })
  }, [isOpen])

  const allFiltersOptions = useMemo(() => getAllFilters(filtersOptions), [filtersOptions])

  const filtersGroupArray = isFiltersOptionsFetched ? filtersGroup(allFiltersOptions, setValue) : []

  const activeFilters = isFiltersOptionsFetched
    ? transformFilters(filters, allFiltersOptions)
    : null

  const hasActiveFilters = activeFilters
    ? Object.values(activeFilters).some(filter => {
        if (Array.isArray(filter)) {
          return filter.length > 0
        }

        return filter !== undefined
      })
    : false

  return (
    <>
      <ButtonsContainer>
        {typeOfContractGlossaryButton}
        <FilterButton
          selectedFiltersCount={filters && countSelectedFilters(filters)}
          onClick={() => setIsOpen(true)}
          data-testid="guardianContractsFilterButton"
        />
      </ButtonsContainer>
      {hasActiveFilters && (
        <>
          <Divider />
          <ActiveFilters
            filters={filters}
            activeFilters={activeFilters}
            updateFilters={updateFilters}
            clearFilters={handleClearFiltersLink}
          />
        </>
      )}
      <Drawer anchor="right" variant="persistent" open={isOpen}>
        <ActionDrawerHeader
          onClose={() => {
            setIsOpen(false)
          }}
          title="Filtrar"
          data-testid="filter-drawer"
        />

        <Box display="flex" flex="1" flexDirection="column" padding="1.5rem 1.5rem" overflow="auto">
          <FormProvider {...form}>
            {isFiltersOptionsFetched ? (
              filtersGroupArray?.map(
                ({ label, filterName, options, totalOptions, searchText, onClearFilter }) => (
                  <Box mb="2rem" key={filterName}>
                    <CollapsibleFilterGroup
                      label={label}
                      filterName={filterName}
                      options={options}
                      totalOptions={totalOptions}
                      searchText={searchText}
                      onClearFilter={onClearFilter}
                      singleSelection={filterName === 'status'}
                    />
                  </Box>
                )
              )
            ) : (
              <LoadingProgress />
            )}
          </FormProvider>
        </Box>

        <ButtonDocker>
          <Button fullWidth onClick={handleSubmit(handleApplyFilters)} type="submit">
            Filtrar
          </Button>

          <Button
            fullWidth
            variation="ghost"
            disabled={!formState.isDirty}
            onClick={handleClearFilter}
          >
            Limpar tudo
          </Button>
        </ButtonDocker>
      </Drawer>
    </>
  )
}
