import { useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Tabs } from '@gravity/tabs'
import { Text } from '@gravity/text'

import { useLayout } from '@/shared/hooks/useLayout'
import { useJWT } from '@/shared/hooks'
import { useSchoolGroupManagementQuery } from './hooks/useSchoolGroupManagementQuery'
import { useSchoolManagementQuery } from './hooks/useSchoolManagementQuery'
import { useHasGroupManagerAccess } from '@/modules/app/hooks/useHasGroupManagerAccess'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { DASHBOARDS_TABS, MANAGEMENT_DASHBOARDS_TABS } from './constants'
import { ManagementDashboardsTab } from './types'

import * as Styled from './styles'
import { DashboardEmbedded } from './components/DashboardEmbedded'
import { CircularLoadingPlaceholder } from '@/modules/app/components/CircularLoadingPlaceholder'

import { EventPageName } from '@/modules/management/models/EventPageName.enum'
import { EventIdentifierName } from '@/modules/management/models/EventIdentifierName.enum'

export const ManagementDashboards = () => {
  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Painel de gestão',
  })

  const { hasPayoutReportAccess, isGroupManager } = useJWT()
  const { status, isLoading } = useHasGroupManagerAccess({ enabled: isGroupManager })
  const isUnitManagement = (!isGroupManager && hasPayoutReportAccess) || status === 'error'

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const {
    data: dataGroupManagement,
    status: statusGroupManagement,
    refetch: refetchGroupManagement,
  } = useSchoolGroupManagementQuery()

  const {
    data: dataSchoolManagement,
    status: statusSchoolManagement,
    refetch: refetchSchoolManagement,
  } = useSchoolManagementQuery()

  const [activeTab, setActiveTab] = useState<MANAGEMENT_DASHBOARDS_TABS>(
    MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT
  )

  const sendTabEvent = (tabName: string) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.MANAGEMENT_DASHBOARD,
        name: EventDispatcherEvents.BUTTON_CLICK,
        pageName: EventPageName.MANAGEMENT_DASHBOARDS,
        identifierName: EventIdentifierName.CHANGE_TAB_MANAGEMENT_DASHBOARD,
        customProperties: {
          $tab_name: tabName,
        },
      })
  }

  const handleTabChange = (tab: ManagementDashboardsTab) => {
    setActiveTab(tab.value)
    sendTabEvent(tab.label)
  }

  if (isGroupManager && isLoading) {
    return <CircularLoadingPlaceholder />
  }

  if (isUnitManagement) {
    return (
      <DashboardEmbedded
        id="unit-management-dashboard"
        url={dataSchoolManagement?.url}
        status={statusSchoolManagement}
        refetch={refetchSchoolManagement}
        style={{ padding: '2.5rem 4rem 0' }}
        isVisible
      />
    )
  }

  return (
    <Styled.Container>
      <Tabs.Root
        style={{ height: '100%' }}
        defaultValue={DASHBOARDS_TABS[0].value}
        value={activeTab}
        onValueChange={value => {
          const tab = DASHBOARDS_TABS.find(tab => tab.value === value)
          handleTabChange(tab)
        }}
      >
        <Tabs.List background="gray">
          {DASHBOARDS_TABS.map(tab => (
            <Tabs.Trigger key={tab.value} value={tab.value}>
              <Text variant="button-2" className="text-inherit">
                {tab.label}
              </Text>
            </Tabs.Trigger>
          ))}
        </Tabs.List>
        <Tabs.Content className="content" value={MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT}>
          <DashboardEmbedded
            id="unit-management-dashboard"
            url={dataSchoolManagement?.url}
            status={statusSchoolManagement}
            refetch={refetchSchoolManagement}
            isVisible={activeTab === MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT}
          />
        </Tabs.Content>
        <Tabs.Content className="content" value={MANAGEMENT_DASHBOARDS_TABS.SCHOOL_GROUP}>
          <DashboardEmbedded
            id="group-management-dashboard"
            url={dataGroupManagement?.url}
            status={statusGroupManagement}
            refetch={refetchGroupManagement}
            isVisible={activeTab === MANAGEMENT_DASHBOARDS_TABS.SCHOOL_GROUP}
          />
        </Tabs.Content>
      </Tabs.Root>
    </Styled.Container>
  )
}
