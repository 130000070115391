import { Callout } from '@gravity/callout'
import { CampaignStatus } from '@/modules/enrollment/services/enrollmentService/types'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { Skeleton } from '@gravity/skeleton'
import type { CampaignDetailsType } from '@/modules/enrollment/pages/Campaigns/CampaignDetails/types'

type Props = {
  campaignDetails: CampaignDetailsType
  formattedDate: string
  isLoading: boolean
}
export const CalloutCampaignDetails = ({ campaignDetails, formattedDate, isLoading }: Props) => {
  const isCampaignAddTuitionEnabled = useUnleashFlag(
    UnleashFlags.MAT_474_ENABLE_CAMPAIGN_ADD_INSTALLMENTS
  )

  const calloutText = () => {
    const closedCampaignText =
      campaignDetails.closedType === 'MANUALLY'
        ? `Esta campanha foi encerrada manualmente pela escola em ${formattedDate}.`
        : 'Esta campanha está encerrada.'

    const hasPaymentPlansText = campaignDetails.hasPaymentPlan
      ? 'nem efetuar o pagamento da pré-matrícula'
      : ''

    if (campaignDetails.status === CampaignStatus.CLOSED)
      return `${closedCampaignText} Os dados abaixo refletem o estado da campanha no momento do encerramento. Os responsáveis já não podem mais demonstrar interesse ${hasPaymentPlansText} através do meuisaac.`
    if (isCampaignAddTuitionEnabled) {
      return 'Lembre-se: alunos em campanhas podem ter suas mensalidades adicionadas em lote ou individualmente.'
    }
    return 'Atenção: A adição de mensalidades para alunos em campanha está em desenvolvimento. Em breve, será possível adicioná-las em lote ou individualmente através do botão acima.'
  }

  if (isLoading) return <Skeleton fullWidth height={24} radius={8} />
  return <Callout text={calloutText()} />
}
