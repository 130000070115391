import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useGuardianNavigation } from '@/modules/guardians/hooks/useGuardianNavigation'

export default function useStepsEntryFlow() {
  const [currentStep, setCurrentStep] = useState(0)

  const history = useHistory()
  const { pageToReturn } = useGuardianNavigation()

  const steps = ['Parcelas selecionadas', 'Forma de pagamento', 'Conclusão']

  const handleOnFinishStep = () => {
    if (pageToReturn) return history.push(pageToReturn)
    return history.goBack()
  }

  const goBack = () => {
    if (currentStep === 0) {
      return handleOnFinishStep()
    }
    setCurrentStep(prev => prev - 1)
  }

  const goToNextStep = () => {
    if (currentStep === steps.length - 1) return
    setCurrentStep(prev => prev + 1)
  }

  const handleStepperItemClick = (index: number) => {
    if (currentStep === steps.length - 1) return
    if (index < currentStep) {
      setCurrentStep(index)
    }
  }

  return {
    currentStep,
    steps,
    goBack,
    goToNextStep,
    handleOnFinishStep,
    handleStepperItemClick,
  }
}
