import { createContext, useCallback, useMemo, ReactNode } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

type RedirectMode = 'push' | 'replace'

type QueryContextType = {
  query: URLSearchParams
  setMultipleQueryParams: (
    params: Array<{
      name: string
      value?: string
    }>,
    mode?: RedirectMode
  ) => void
  setOnQueryParam: (value: string | undefined, name: string, mode?: RedirectMode) => void
}

type QueryProviderProps = {
  children: ReactNode
}

export const QueryContext = createContext({} as QueryContextType)

/**
 * Context to access route query params
 *
 * @param children Components that will be able to access the context
 *
 * @returns Context provider
 */
export const QueryProvider = ({ children }: QueryProviderProps) => {
  const { search } = useLocation()
  const { push, replace } = useHistory()

  const query = useMemo(() => new URLSearchParams(search), [search])

  const updateUrl = (name: string, value?: string) => {
    if (value) {
      return query.set(name, value)
    }

    query.delete(name)
  }

  const setOnQueryParam = useCallback(
    (value: string | undefined, name: string, mode: RedirectMode = 'push') => {
      updateUrl(name, value)

      if (mode === 'push') {
        push({ search: query.toString() })
        return
      }

      replace({ search: query.toString() })
    },
    [push, replace, query]
  )

  const setMultipleQueryParams = useCallback(
    (params: Array<{ name: string; value?: string }>, mode: RedirectMode = 'push') => {
      params.forEach(({ name, value }) => updateUrl(name, value))

      if (mode === 'push') {
        push({ search: query.toString() })
        return
      }

      replace({ search: query.toString() })
    },
    [push, replace, query]
  )

  const queryContext: QueryContextType = useMemo(
    () => ({
      query,
      setOnQueryParam,
      setMultipleQueryParams,
    }),
    [query, setOnQueryParam, setMultipleQueryParams]
  )

  return <QueryContext.Provider value={queryContext}>{children}</QueryContext.Provider>
}
