import { fontSize, fontWeight, letterSpacing, lineHeight, spacing } from '@gravity/tokens'
import { Box } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

export const ErrorTitle = styled(Typography)`
  font-size: ${fontSize['2xl']};
  font-weight: ${fontWeight.medium};
  line-height: 120%;
  text-align: center;
  margin-top: ${spacing[10]};
  align-self: stretch;
`
export const ErrorDescription = styled(Typography)`
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.normal};
  line-height: ${lineHeight.normal};
  text-align: center;
  margin-top: ${spacing[4]};
  align-self: stretch;
  letter-spacing: ${letterSpacing.normal};
  white-space: pre-line;
`

export const ErrorContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const ErrorContainerItem = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 552;
`
