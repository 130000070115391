import styled from 'styled-components'
import { spacing, colors, fontWeight, fontSize, letterSpacing, lineHeight } from '@gravity/tokens'
import { Typography } from '@olaisaac/design-system'

export const ContainerActualizationPeriod = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${spacing[2]};
`
export const ContainerTuitionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: ${spacing[8]};
`

export const LabelActualizationPeriod = styled(Typography)`
  text-align: right;
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.normal};
  line-height: ${lineHeight.normal};
  letter-spacing: ${letterSpacing.tight};
`

export const BoldActualizationPeriod = styled.strong`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.sm};
  font-style: normal;
  font-weight: ${fontWeight.medium};
  line-height: 150%;
  letter-spacing: ${letterSpacing.tight};
`
