import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import { StyledInput, StyledInputLabel } from './styles'
import type { InputListPosProps } from './types'

const InputListPos = ({ handleValue, selectedValue, list }: InputListPosProps) => {
  const orderedListPos = list?.sort((a, b) => (a.pdv_name > b.pdv_name ? 1 : -1))
  const value = !selectedValue ? '' : selectedValue
  return (
    <StyledInput maxWidth={294}>
      <StyledInputLabel maxWidth={294}>
        <Typography variation="bodySmall" withoutMargin color="secondary">
          Em qual maquininha o pagamento será realizado?
        </Typography>
      </StyledInputLabel>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="selected_pos">Selecione a maquininha</InputLabel>
        <Select
          labelId="selected_pos"
          value={value}
          label="Selecione a maquininha"
          onChange={e => {
            handleValue(e.target.value)
          }}
        >
          {orderedListPos?.map(value => (
            <MenuItem
              style={{
                maxWidth: '294px',
              }}
              key={value.pos_id}
              value={value.pos_id}
            >
              <Typography
                variation="subtitleDesktopMedium"
                withoutMargin
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '294px',
                }}
              >
                {value.pdv_name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StyledInput>
  )
}

export default InputListPos
