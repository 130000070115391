import { AxiosInstance } from 'axios'
import { CheckUserByEmailRequest, CheckUserByEmailResponse } from './types'

export const userCreationService = (fetcher: AxiosInstance) => {
  return {
    checkUserByEmail: async ({
      username,
      email,
    }: CheckUserByEmailRequest): Promise<CheckUserByEmailResponse> => {
      const { data: response } = await fetcher.get<{ data: CheckUserByEmailResponse }>(
        '/contract/users/check-user-by-email',
        {
          params: {
            email,
            username,
          },
        }
      )

      return response.data
    },
  }
}
