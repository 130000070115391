import { Dialog } from '@material-ui/core'
import { Button, DialogActions, DialogTitle } from '@olaisaac/design-system'

type ConfirmQuitDialogProps = {
  onClose: () => void
  onConfirm: () => void
  open: boolean
}

export const ConfirmQuitDialog = ({ open, onClose, onConfirm }: ConfirmQuitDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Se você sair da página agora, as edições não serão salvas.</DialogTitle>
      <DialogActions>
        <Button variation="ghost" onClick={onClose}>
          Continuar editando
        </Button>
        <Button variation="primary" onClick={onConfirm}>
          Sair sem salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
