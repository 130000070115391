import styled from 'styled-components'

export const Container = styled.div`
  max-width: 100vw;
  padding: 0 4rem;

  #page-header {
    margin: 0 -4rem 2.5rem;
  }
`
