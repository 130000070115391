import { Button } from '@olaisaac/design-system'

import * as Styled from './styles'
import image from './image.svg'

export const Error = () => {
  return (
    <Styled.Container>
      <Styled.TopContainer>
        <Styled.ImageContainer>
          <img src={image} alt="Erro" />
        </Styled.ImageContainer>
        <Styled.TextContainer>
          <Styled.Title>Algo deu errado</Styled.Title>
          <Styled.Subtitle>
            Por favor, verifique sua conexão com a internet ou aguarde um momento e tente novamente.
          </Styled.Subtitle>
        </Styled.TextContainer>
      </Styled.TopContainer>
      <Button onClick={() => window.location.reload()}>Tentar novamente</Button>
    </Styled.Container>
  )
}
