import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Serializable, StorageItem, storageSolutions, StorageStrategy } from '@/shared/hooks/usePersistState/storages'

export enum PersistedStatePrefix {
  CreditSimulation = 'credit-simulation',
  Enrollment = 'enrollment',
  PayoutAnticipationSimulation = 'payout-anticipation-simulation',
}

type PersistStateKey = `${PersistedStatePrefix}:${string}`

const isFunction = <T extends Serializable>(
  value: SetStateAction<T>,
): value is (prevState: T) => T => typeof value === 'function'

const setItem = <T extends Serializable>(storageSolution: Storage, key: string, value: T): void => {
  const result: StorageItem<T> = {
    data: value,
    timestamp_ms: new Date().getTime(),
  }
  storageSolution.setItem(key, JSON.stringify(result))
}

const getItem = <T extends Serializable>(storageSolution: Storage, key: string, ttl?: number): T | null => {
  const storedValueStr = storageSolution.getItem(key)
  if (storedValueStr) {
    const storageItem: StorageItem<T> = JSON.parse(storedValueStr)

    if (ttl != null) {
      const setDate = new Date(storageItem.timestamp_ms)
      const currentDate = new Date()
      const diff = currentDate.getTime() - setDate.getTime()
      if (diff > ttl) {
        storageSolution.removeItem(key)
        return null
      }
    }
    return storageItem.data
  }
  return null
}

interface Options {
  storageStrategy?: StorageStrategy
  TTL_ms?: number
}

const defaultOptions = {
  storageStrategy: 'sessionStorage',
// eslint-disable-next-line prettier/prettier -- don't know why prettier is complaining about the satisfies keyword
} as const satisfies Options

export const usePersistState = <T extends Serializable>(
  key: PersistStateKey,
  defaultValue: T,
  options: Options = {}, 
): readonly [T, Dispatch<SetStateAction<T>>] => {
  options = { ...defaultOptions, ...options }

  const storageKey = `persisted-state:${key}`
  const storageSolution = storageSolutions[options.storageStrategy ?? defaultOptions.storageStrategy]


  const storedValue = useMemo((): T => {
    const storedValue = getItem<T>(storageSolution, storageKey, options.TTL_ms)
    return storedValue ?? defaultValue
  }, [])

  const [state, setState] = useState<T>(storedValue)

  const setPersistedState: Dispatch<SetStateAction<T>> = newValue => {
    if (isFunction(newValue)) {
      setState(prev => {
        const result = newValue(prev)
        setItem(storageSolution, storageKey, result)
        return result
      })
      return
    }

    setState(newValue)
    setItem(storageSolution, storageKey, newValue)
  }

  return [state, setPersistedState] as const
}
