import styled from 'styled-components'

export const FooterSpaceClearance = styled.div`
  padding-bottom: 136px;
`

export const SelectedTableFooterContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const StickyComponent = styled.div`
  background-color: ${props => props.theme.primitiveTokens.colors.gray[0]};

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fade-in 0.15s ease-in forwards;

  position: sticky;
  bottom: 136px;

  &&::before {
    content: '';
    display: block;
    position: absolute;
    background-color: ${props => props.theme.primitiveTokens.colors.gray[0]};
    width: 100%;
    height: 136px;
    bottom: -136px;
  }
`

export const ActionBarContainer = styled.div`
  padding: 16px;
  border: 1px solid;
  border-color: ${props => props.theme.primitiveTokens.colors.gray[10]};
  border-radius: 0 0 8px 8px;
`

export const BackButtonWrapper = styled.div`
  margin-top: 60px;
`
