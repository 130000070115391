import { Box, FormControl } from '@material-ui/core'
import BoxFooter from '../../BoxFooter/BoxFooter'
import type { DiscountFieldProps } from '../types'
import * as S from '../styles'
import { Checkbox, FormControlLabel, Notification, TextField } from '@olaisaac/design-system'
import { Controller } from 'react-hook-form'
import { formatCentsToReal, formatRealToCents } from '@/shared/utils'
import NumberFormat from 'react-number-format'
import CircularProgress from '@material-ui/core/CircularProgress'

const DiscountsField = ({
  onSave,
  onCancel,
  discountsInfo,
  form,
  isLoading,
}: DiscountFieldProps) => {
  const { control, formState, getValues, setValue } = form
  const isAdditionalDiscountAmountInvalid = Boolean(
    formState.isDirty && formState.errors.additional_discount_amount
  )
  const formValues = getValues()
  const validateSave = () => {
    if (isLoading) return false
    if (
      discountsInfo?.isAdditionalDiscountEnabled &&
      (formValues.additional_discount_amount?.toString() === '0' ||
        !discountsInfo?.validateTotalDiscount)
    ) {
      return false
    }
    return true
  }

  const isValid = validateSave()

  return (
    <S.StyledBox>
      <S.StyledContent>
        {isLoading && (
          <S.StyledBoxLoading>
            <CircularProgress />
          </S.StyledBoxLoading>
        )}
        {!!discountsInfo?.warningMessage && (
          <Box width="100%">
            <Notification description={discountsInfo?.warningMessage} variation="warning" />
          </Box>
        )}
        {(!!discountsInfo?.fineAndInterest || !!discountsInfo?.fineAndInterestChange) && (
          <>
            <S.StyledTypograph>Multas</S.StyledTypograph>
            {discountsInfo?.fineAndInterest !== 0 && (
              <S.StyledBoxFields>
                <Controller
                  defaultValue
                  name="apply_previous_fines_and_interest"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <FormControlLabel
                      disabled={discountsInfo?.disableFinesAndInterest}
                      control={
                        <Checkbox
                          checkedLink={undefined}
                          valueLink={undefined}
                          {...rest}
                          checked={value}
                        />
                      }
                      label="Multa e juros"
                    />
                  )}
                />
                <S.NoWrapTypography variation="bodyLarge" color="secondary">
                  {formatCentsToReal(discountsInfo?.previousFinesAndInterest || 0)}
                </S.NoWrapTypography>
              </S.StyledBoxFields>
            )}

            {discountsInfo?.fineAndInterest !== 0 && discountsInfo?.fineAndInterestChange !== 0 && (
              <S.StyledBoxFields>
                <Controller
                  defaultValue
                  name="apply_fines_and_interest_change"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <FormControlLabel
                      disabled={discountsInfo?.disableFineAndInterestChange}
                      control={
                        <Checkbox
                          checkedLink={undefined}
                          valueLink={undefined}
                          {...rest}
                          checked={discountsInfo?.applyPreviousFinesAndInterest ? value : false}
                        />
                      }
                      label="Multa e juros mudança de data"
                    />
                  )}
                />
                {discountsInfo?.applyPreviousFinesAndInterest ? (
                  <S.NoWrapTypography variation="bodyLarge" color="secondary" aria-disabled>
                    {formatCentsToReal(discountsInfo?.fineAndInterestChange || 0)}
                  </S.NoWrapTypography>
                ) : (
                  <S.NoWrapTypographyDisabled variation="bodyLarge" color="secondary" aria-disabled>
                    {formatCentsToReal(discountsInfo?.fineAndInterestChange || 0)}
                  </S.NoWrapTypographyDisabled>
                )}
              </S.StyledBoxFields>
            )}
          </>
        )}
        {(!!discountsInfo?.lostEarlyPaymentDiscount ||
          !!discountsInfo?.lostDuePaymentDiscount ||
          !discountsInfo?.isRenegotiated) && (
          <>
            <S.StyledTypograph>Descontos</S.StyledTypograph>
            {discountsInfo?.lostEarlyPaymentDiscount !== 0 && (
              <S.StyledBoxFields>
                <Controller
                  defaultValue={false}
                  name="apply_lost_early_payment_discount"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <FormControlLabel
                      disabled={discountsInfo?.disableEarlyPayment}
                      control={
                        <Checkbox
                          checkedLink={undefined}
                          valueLink={undefined}
                          {...rest}
                          checked={value}
                        />
                      }
                      label="Antecipação"
                    />
                  )}
                />
                <S.NoWrapTypography variation="bodyLarge" color="secondary">
                  {formatCentsToReal(discountsInfo?.lostEarlyPaymentDiscount || 0)}
                </S.NoWrapTypography>
              </S.StyledBoxFields>
            )}
            {discountsInfo?.lostDuePaymentDiscount !== 0 && (
              <S.StyledBoxFields>
                <Controller
                  defaultValue={false}
                  name="apply_lost_due_payment_discount"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <FormControlLabel
                      disabled={discountsInfo?.disableDuePayment}
                      control={
                        <Checkbox
                          checkedLink={undefined}
                          valueLink={undefined}
                          {...rest}
                          checked={value}
                        />
                      }
                      label="Pontualidade"
                    />
                  )}
                />
                <S.NoWrapTypography variation="bodyLarge" color="secondary">
                  {formatCentsToReal(discountsInfo?.lostDuePaymentDiscount || 0)}
                </S.NoWrapTypography>
              </S.StyledBoxFields>
            )}
            {!discountsInfo?.isRenegotiated && (
              <>
                <FormControlLabel
                  disabled={discountsInfo?.disableMoreDiscounts}
                  control={
                    <Checkbox
                      checkedLink={undefined}
                      valueLink={undefined}
                      onChange={(_, checked) => {
                        discountsInfo?.setIsAdditionalDiscountEnabled(checked)
                        if (!checked) {
                          setValue('additional_discount_amount', 0)
                        }
                      }}
                      checked={discountsInfo?.isAdditionalDiscountEnabled}
                    />
                  }
                  label="Outros descontos"
                />
                {discountsInfo?.isAdditionalDiscountEnabled && (
                  <Box pt={3}>
                    <FormControl variant="outlined" fullWidth>
                      <Controller
                        control={control}
                        name="additional_discount_amount"
                        defaultValue={0}
                        render={({ field: { onChange, value } }) => (
                          <NumberFormat
                            disabled={discountsInfo?.disableMoreDiscounts}
                            id="additional-discount-input"
                            onBlur={event => {
                              onChange(formatRealToCents(event.target.value))
                              discountsInfo?.setIsAdditionalDiscountEnabled(true)
                            }}
                            customInput={TextField}
                            variant="outlined"
                            label="Desconto"
                            format={formatCentsToReal}
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                            value={value}
                            error={
                              isAdditionalDiscountAmountInvalid ||
                              !discountsInfo?.validateTotalDiscount
                            }
                            helperText={
                              isAdditionalDiscountAmountInvalid ||
                              !discountsInfo?.validateTotalDiscount
                                ? `O valor máximo de desconto é ${formatCentsToReal(
                                    discountsInfo?.finalBaseAmount
                                  )}`
                                : ''
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </S.StyledContent>
      <S.StyledBoxIndex>
        <BoxFooter isValid={isValid} onCancel={onCancel} onSave={() => onSave(form)} />
      </S.StyledBoxIndex>
    </S.StyledBox>
  )
}

export default DiscountsField
