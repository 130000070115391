import styled from 'styled-components'
import { Text as TextDS } from '@gravity/text'

export const Container = styled.div`
  display: flex;
  padding: ${props => props.theme.gravity.spacing[6]} 0;
`

export const StatusContainer = styled.div`
  padding-right: ${props => props.theme.gravity.spacing[2]};
  &:hover {
    cursor: pointer;
  }
`

export const Text = styled(TextDS)`
  padding-right: ${props => props.theme.gravity.spacing[4]};
`
