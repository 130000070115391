import { Button } from '@gravity/button'
import { spacing } from '@gravity/tokens'
import styled from 'styled-components'

export const Form = styled.form`
  padding-top: ${spacing[6]};
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Content = styled.div`
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  margin-top: ${spacing[4]};
`

export const FormWrapper = styled.div`
  margin-top: ${spacing[8]};
  height: -webkit-fill-available;
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

export const AddDiscountBtn = styled(Button)`
  max-width: 50%;
`

export const SubmitBtnRow = styled.div`
  margin-top: auto;
  padding-top: ${spacing[8]};
  display: flex;
  justify-content: space-between;
`
