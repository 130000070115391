import styled from 'styled-components'

import { Link as LinkR } from 'react-router-dom'

export const Container = styled.li`
  list-style: none;
`

export const Link = styled(LinkR)`
  text-decoration: none;

  padding: 0;
  padding: ${props => props.theme.gravity.spacing[2]};
  border-radius: ${props => props.theme.gravity.spacing[2]};

  cursor: pointer;

  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: ${props => props.theme.gravity.spacing[2]};

  &.single-row {
    grid-template-rows: auto;
  }

  &.double-row {
    grid-template-rows: auto auto;
  }

  .icon-item {
    grid-row: 1 / 2;
    grid-column: 1 / 2;

    color: ${props => props.theme.gravity.colors['colors-border-neutral-4']};
  }

  .guardian-item {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gravity.spacing[4]};

    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }

  .students-items {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gravity.spacing[4]};

    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  .second-text {
    color: ${props => props.theme.gravity.colors['colors-text-main-4']};
  }

  .guardian-name > mark {
    color: ${props => props.theme.gravity.colors['colors-text-main-1']};
    font-weight: ${props => props.theme.gravity.fontWeight.medium};
    background-color: ${props => props.theme.gravity.colors['colors-background-accent-3']};
  }

  .remove-button {
    opacity: 0;
  }

  :hover {
    background: ${props => props.theme.gravity.colors['colors-background-neutral-2']};

    .second-text {
      color: ${props => props.theme.gravity.colors['colors-text-main-3']};
    }

    .remove-button {
      opacity: 1;
    }
  }

  :active {
    background: ${props => props.theme.gravity.colors['colors-background-neutral-3']};
  }
`
