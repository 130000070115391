import styled from 'styled-components'

export const Header = styled.header`
  margin: ${({ theme }) => theme.spacing(3)}px 0 ${({ theme }) => theme.spacing(4)}px;
`

export const Title = styled.h1`
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.125;
  margin-bottom: 8px;
`
