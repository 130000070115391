import * as Styled from './styles'
import image from './image.svg'

export const EmptyState = ({ referenceYear }: { referenceYear: string }) => {
  return (
    <Styled.Container>
      <Styled.TopContainer>
        <Styled.ImageContainer>
          <img src={image} alt="Empty" />
        </Styled.ImageContainer>
        <Styled.TextContainer>
          <Styled.MainText>
            Tudo certo por aqui: sem correções necessárias em {referenceYear}!
          </Styled.MainText>
          <Styled.SecondaryText>
            É essencial manter as correções em dia para garantir que as cobranças aconteçam. Ah, não
            esquece de checar os períodos letivos anteriores, tá?
          </Styled.SecondaryText>
        </Styled.TextContainer>
      </Styled.TopContainer>
    </Styled.Container>
  )
}
