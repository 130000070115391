import { Box } from '@material-ui/core'
import { Button } from '@olaisaac/design-system'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { Title } from './styles'

// props quando clicar em voltar
type HeaderProps = {
  onClickBack: () => void
}

export function Header({ onClickBack = () => null }: HeaderProps) {
  return (
    <header>
      <Button variation="ghost" onClick={onClickBack}>
        <ArrowBackIcon />

        <span style={{ marginLeft: '0.75rem' }}>Voltar</span>
      </Button>

      <Box mt="1rem">
        <Title>Validação de inadimplência</Title>
      </Box>
    </header>
  )
}
