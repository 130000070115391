import { useEffect } from 'react'

export const useSetPageTitle = (title: string) => {
  useEffect(() => {
    const previousTitle = document.title

    document.title = title

    return () => {
      document.title = previousTitle
    }
  })
}
