import styled from 'styled-components'
import { Select } from '@gravity/select'

export const PageContainer = styled.div`
  padding-top: 32px;
  padding-bottom: 40px;
`

export const HeaderContainer = styled.div`
  display: flex;
  padding-bottom: 12px;
`

export const TitleContainer = styled.div`
  padding-right: 16px;
`

export const SubtitleContainer = styled.div`
  padding-bottom: 21px;
`

export const StyledSelect = styled(Select)`
  width: 88px;
  min-width: 88px;
  color: 'blue';
`

export const BigNumbersContainer = styled.div`
  margin-bottom: 48px;
`

export const SearchBarContainer = styled.div`
  padding-bottom: 24px;
`
