import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useApi } from '@/shared/hooks'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import {
  UseInstallmentsByUrnQueryProps,
  UseInstallmentsByUrnQueryResponse,
} from '@/modules/guardians/services/types'
import { guardianService } from '@/modules/guardians/services'

const useGuardiansInstallmentsByUrnQuery = (
  props: UseInstallmentsByUrnQueryProps,
  options?: UseQueryOptions<UseInstallmentsByUrnQueryResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApi()
  const service = guardianService(apiClient.privateApiV2)

  const queryKey = ['guardian-installments-by-urn', props.guardianId, props.urns]

  const {
    data: guardiansInstallments,
    isLoading: isFetchGuardiansInstallmentsLoading,
    isError: isFetchGuardiansInstallmentsError,
    isFetched: isFetchGuardiansInstallmentsFetched,
    refetch: refetchGuardiansInstallments,
    isRefetching: isRefetchingGuardiansInstallments,
    isSuccess: isFetchGuardiansInstallmentsSuccess,
  } = useQuery<UseInstallmentsByUrnQueryResponse, XMLHttpRequest>(
    queryKey,
    () => {
      return service.fetchInstallmentsByUrn({
        guardianId: props.guardianId,
        urns: props.urns,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      retry: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    }
  )

  return {
    guardiansInstallments,
    isFetchGuardiansInstallmentsLoading,
    isFetchGuardiansInstallmentsError,
    isFetchGuardiansInstallmentsFetched,
    isRefetchingGuardiansInstallments,
    refetchGuardiansInstallments,
    isFetchGuardiansInstallmentsSuccess,
  }
}

export default useGuardiansInstallmentsByUrnQuery
