import { Box, Collapse, Divider, FormControl, ListItem } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import { useEffect } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Guardian } from 'src/shared/interfaces'
import * as S from './EditRegistrationDrawer.styles'
import NumberFormat from 'react-number-format'

import { formatCPF, validateEmail, validatePhoneNumber } from 'src/shared/utils'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'

export type CollapseGuardianProps = {
  edit?: boolean
  form: UseFormReturn<any>
  guardian: Guardian
  handleOpenCollapse: (value: string) => void
  openCollapse: string
}

const CollapseGuardian = ({
  edit = false,
  guardian,
  openCollapse,
  handleOpenCollapse,
  form,
}: CollapseGuardianProps) => {
  const isNewEditRfNameEnabled = useUnleashFlag(UnleashFlags.GC_973_B2BCORE_122_EDIT_RF)
  const isOpen = openCollapse === 'guardian'
  const { control, trigger } = form

  useEffect(() => {
    if (isOpen) {
      trigger()
    }
  }, [openCollapse])

  return (
    <Box>
      <ListItem
        button
        style={{ justifyContent: 'space-between', paddingLeft: '22px' }}
        onClick={() => handleOpenCollapse('guardian')}
      >
        <Typography variation="subtitleDesktopLarge">Informações do Responsável</Typography>
        {isOpen ? <S.ExpandLess /> : <S.ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <S.StyledBox>
          <S.StyledTextField
            fullWidth
            label="CPF"
            defaultValue={formatCPF(guardian.tax_id)}
            disabled
          />
          {isNewEditRfNameEnabled ? (
            <Controller
              rules={{
                required: true,
              }}
              name="name"
              control={control}
              render={({ field, field: { value }, fieldState: { error } }) => {
                return (
                  <S.StyledTextField
                    {...field}
                    value={value}
                    fullWidth
                    label="Nome do Responsável Financeiro"
                    error={Boolean(error)}
                    helperText={error ? 'O nome é obrigatório' : ''}
                    disabled={!edit}
                  />
                )
              }}
            />
          ) : (
            <S.StyledTextField
              fullWidth
              label="Nome do Responsável Financeiro"
              defaultValue={guardian.name}
              disabled
            />
          )}
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{
                validate: v => (v ? validateEmail(v) : true),
                max: 254,
                min: 3,
                required: true,
              }}
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <S.StyledTextField
                  {...field}
                  label="Email"
                  id="edit-guardian-email-input"
                  fullWidth
                  error={Boolean(error)}
                  helperText={error ? 'E-mail inválido' : ''}
                  disabled={!edit}
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{
                required: true,
                validate: validatePhoneNumber,
              }}
              name="phone_number"
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <NumberFormat
                  {...rest}
                  label="Celular"
                  id="edit-guardian-phone-number-input"
                  data-testid="edit-guardian-phone-number-input"
                  type="tel"
                  format="(##) #####-####"
                  mask="_"
                  onValueChange={currentValue => {
                    onChange(currentValue.value)
                  }}
                  value={value}
                  customInput={S.StyledTextField}
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={error ? 'Telefone inválido' : ''}
                  disabled={!edit}
                />
              )}
            />
          </FormControl>
        </S.StyledBox>
      </Collapse>
      <Box paddingRight={3} paddingLeft={2.8}>
        <Divider variant="middle" style={{ margin: '0' }} />
      </Box>
    </Box>
  )
}

export default CollapseGuardian
