import { useRef, useState } from 'react'

import dayjs, { Dayjs } from 'dayjs'
import { Controller, FieldErrorsImpl, useForm } from 'react-hook-form'

import { Dialog } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { calcFixedDate, DATE_FORMAT, isDayTodayOrAfter } from 'src/shared/utils'
import {
  DatePicker,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@olaisaac/design-system'
import { Button } from '@gravity/button'
import { Installment } from '@/modules/contract/services/types'

import { ChipButton } from '../ChipButton'
import { validateDatesInUniqueMonths } from '../../utils'
import { SelectedInstallmentsText } from '../SelectedInstallmentsText'
import * as Styled from './styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ptBR from 'dayjs/locale/pt-br'
import DayJsUtils from '@date-io/dayjs'
import { contractAPI } from '@/modules/contract/services'
import { useEvents } from '../../hooks/eventContext'
import { InstallmentStatuses, InstallmentType } from '@/shared/interfaces'

class UTCUtils extends DayJsUtils {
  format(value: any, formatString: string) {
    return dayjs.utc(value).format(formatString)
  }

  parse(value: string, format: string): Dayjs {
    if (value === '') {
      return null
    }

    return dayjs.utc(value, format)
  }

  date(value?: any) {
    if (value === null) {
      return null
    }

    return dayjs.utc(value)
  }
}

enum Field {
  DUE_DATE = 'due_date',
  FIXED_DAY = 'FIXED_DAY',
  WORKING_DAY = 'WORKING_DAY',
}

export type EditDueDateForm = {
  [Field.DUE_DATE]: datestring
  [Field.FIXED_DAY]: number | ''
  [Field.WORKING_DAY]: number | ''
}

const REQUIRED_MESSAGES = {
  [Field.DUE_DATE]: 'Por favor, informe uma data para o vencimento.',
  [Field.FIXED_DAY]: 'Por favor, informe um dia fixo para o vencimento.',
  [Field.WORKING_DAY]: 'Por favor, informe um dia útil para o vencimento.',
}

const DATES_IN_THE_PAST_MESSAGE =
  'Uma das parcelas selecionadas pertence ao mês atual. Por isso, informe um dia posterior ao dia de hoje. Se preferir, você pode selecionar apenas as parcelas dos próximos meses e escolher qualquer dia de vencimento.'
const DATES_IN_THE_SAME_MONTH_MESSAGE = 'Mais de um vencimento no mesmo mês.'

const errorProps = (errors: Partial<FieldErrorsImpl<EditDueDateForm>>, field: Field) => {
  return {
    error: Boolean(errors[field]),
    helperText: errors[field] ? errors[field].message : '',
  }
}

export const EditDueDateDialog = ({
  onClose,
  onConfirm,
  selectedInstallments,
  allInstallments,
  api,
}: {
  allInstallments: Installment[]
  api: contractAPI
  onClose: () => void
  onConfirm?: (dueDates: datestring[], workingDayMode: boolean) => void
  selectedInstallments: Installment[]
}) => {
  const [workingDayMode, setWorkingDayMode] = useState(false)
  const isSingleInstallment = selectedInstallments.length === 1
  const dueDates = useRef([])
  const [isLoading, setIsLoading] = useState(false)
  const events = useEvents()

  const selectedInstallment = selectedInstallments[0]
  const minDateSingleInstallment = dayjs.utc(selectedInstallment.due_date).startOf('month')

  let maxDateSingleInstallment = dayjs.utc(selectedInstallment.due_date).endOf('month')

  if (selectedInstallment.receivable_type === InstallmentType.ENROLLMENT) {
    const lastInstallment = allInstallments[allInstallments.length - 1]
    maxDateSingleInstallment = dayjs.utc(lastInstallment.due_date).endOf('month')
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    getValues,
  } = useForm<EditDueDateForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      [Field.DUE_DATE]: dayjs(selectedInstallment.due_date).toISOString(),
      [Field.FIXED_DAY]: '',
      [Field.WORKING_DAY]: '',
    },
  })

  const skip = !isSubmitting

  const isTuitionAndPending = () => {
    return selectedInstallments.every(
      i => i.receivable_type === InstallmentType.TUITION && i.status === InstallmentStatuses.PENDING
    )
  }

  const updateDueDate = (value: string) => {
    const dates = selectedInstallments.map(i =>
      calcFixedDate(dayjs(i.competence_date[0]).utc(), parseInt(value) || 0).toISOString()
    )
    dueDates.current = dates
  }

  const validateFixedDates = async () => {
    return dueDates.current.every(d => isDayTodayOrAfter(dayjs(d)))
  }

  const validateWorkingDates = () => {
    return dueDates.current.every(d => isDayTodayOrAfter(dayjs(d)))
  }

  const calculateWorkingDates = async () => {
    setIsLoading(true)
    try {
      const dates = (
        await Promise.all(
          selectedInstallments.map(i =>
            api.getWorkingDateFromNthDay({
              date: dayjs(i.competence_date[0]).utc().toISOString(),
              nth_working_day: getValues().WORKING_DAY || 0,
            })
          )
        )
      ).map(d => d.data)

      dueDates.current = dates

      setIsLoading(false)
      return true
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      return false
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(() => onConfirm(dueDates.current, workingDayMode))}>
        <DialogTitle>Editar vencimento</DialogTitle>
        <DialogContent>
          <Styled.TextContainer>
            <SelectedInstallmentsText count={selectedInstallments.length} />
          </Styled.TextContainer>

          {isSingleInstallment && (
            <Controller
              name={Field.DUE_DATE}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.DUE_DATE],
                validate: {
                  minValue: v =>
                    isDayTodayOrAfter(dayjs(v)) ||
                    'Você precisa inserir uma data posterior a data de hoje.',
                  sameMonth: v => {
                    const otherInstallments = allInstallments.filter(
                      i =>
                        i.backoffice_installment_id !==
                          selectedInstallments[0].backoffice_installment_id &&
                        i.receivable_type === selectedInstallments[0].receivable_type
                    )
                    const otherDueDates = otherInstallments.map(i => i.due_date)

                    return (
                      validateDatesInUniqueMonths([v, ...otherDueDates], selectedInstallments) ||
                      'Já existe uma parcela para o mês selecionado.'
                    )
                  },
                  maxValue: v => {
                    const currentDate = dayjs(selectedInstallment.due_date)
                    const maxDate = dayjs(maxDateSingleInstallment)

                    return (
                      dayjs(v).isBefore(maxDate) ||
                      `A data de vencimento desta parcela, que é ${currentDate.format(
                        'DD/MM/YYYY'
                      )}, pode ser alterada no máximo para ${maxDate.format('DD/MM/YYYY')}.`
                    )
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl variant="outlined">
                  <MuiPickersUtilsProvider locale={ptBR} utils={UTCUtils}>
                    <DatePicker
                      id={Field.DUE_DATE}
                      value={value}
                      onChange={e => {
                        if (e !== null && e.isValid()) {
                          dueDates.current = [e.toISOString()]
                        }

                        onChange(e)

                        events.clickDatePicker()
                      }}
                      label="Data de vencimento"
                      format={DATE_FORMAT}
                      disablePast
                      minDate={minDateSingleInstallment}
                      maxDate={maxDateSingleInstallment}
                      {...errorProps(errors, Field.DUE_DATE)}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              )}
            />
          )}

          {!isSingleInstallment && (
            <Styled.SectionWrapper>
              <Typography variation="bodyLarge">Regra de vencimento</Typography>
              <Typography variation="caption" color="secondary">
                Selecione a regra e dia de vencimento
              </Typography>
              <Styled.DayModeWrapper>
                <ChipButton
                  label="Vencimento por dia fixo"
                  onClick={() => setWorkingDayMode(false)}
                  $isActive={!workingDayMode}
                />
                <ChipButton
                  label="Vencimento por dia útil"
                  onClick={() => setWorkingDayMode(true)}
                  $isActive={workingDayMode}
                />
              </Styled.DayModeWrapper>
            </Styled.SectionWrapper>
          )}

          {!workingDayMode && !isSingleInstallment && (
            <Controller
              name={Field.FIXED_DAY}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.FIXED_DAY],
                validate: {
                  minValue: v => Number(v) > 0 || 'O número de dias deve ser maior que zero',
                  maxValue: v =>
                    Number(v) <= 31 ||
                    'O dia fixo deve ser maior que zero e igual ou inferior a 31',
                  dateOnThePast: async () => {
                    return (
                      (await validateFixedDates()) ||
                      isTuitionAndPending() ||
                      DATES_IN_THE_PAST_MESSAGE
                    )
                  },
                  sameMonth: () =>
                    validateDatesInUniqueMonths(dueDates.current, selectedInstallments) ||
                    DATES_IN_THE_SAME_MONTH_MESSAGE,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    value={value}
                    onChange={e => {
                      if (e !== null) {
                        updateDueDate(e.target.value)
                      }

                      onChange(e)
                    }}
                    id={Field.FIXED_DAY}
                    type="number"
                    label="Dia fixo"
                    {...errorProps(errors, Field.FIXED_DAY)}
                  />
                </FormControl>
              )}
            />
          )}

          {workingDayMode && !isSingleInstallment && (
            <Controller
              name={Field.WORKING_DAY}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.WORKING_DAY],
                validate: {
                  minValue: v => Number(v) > 0 || 'O número de dias deve ser maior que zero',
                  maxValue: v =>
                    Number(v) <= 25 ||
                    'O dia útil deve ser maior que zero e igual ou inferior a 25',
                  calcDates: async () => {
                    if (skip) return true
                    return (await calculateWorkingDates()) || 'Erro ao calcular datas' // >>> TODO: review text with design
                  },
                  dateOnThePast: () => {
                    if (skip) return true
                    return (
                      isTuitionAndPending() || validateWorkingDates() || DATES_IN_THE_PAST_MESSAGE
                    )
                  },
                  sameMonth: () => {
                    if (skip) return true
                    return (
                      validateDatesInUniqueMonths(dueDates.current, selectedInstallments) ||
                      DATES_IN_THE_SAME_MONTH_MESSAGE
                    )
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    value={value}
                    onChange={onChange}
                    id={Field.WORKING_DAY}
                    type="number"
                    label="Dia útil"
                    error={isLoading ? false : Boolean(errors[Field.WORKING_DAY])}
                    helperText={
                      isLoading
                        ? 'Calculando datas...'
                        : errors[Field.WORKING_DAY]
                        ? errors[Field.WORKING_DAY].message
                        : ''
                    }
                  />
                </FormControl>
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="ghost" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="solid" type="submit" disabled={!isValid}>
            Aplicar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
