import { useSchoolEnrollmentCycles } from '@/modules/enrollment/hooks/useSchoolEnrollmentCycles'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useParams } from 'react-router-dom'

const useRouterReferenceYear = (): string => {
  const { referenceYear } = useParams<{ referenceYear: string }>()

  const isFlexibleEnrollmentCycleEnabled = useUnleashFlag(
    UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES
  )
  if (!isFlexibleEnrollmentCycleEnabled) {
    return referenceYear
  }

  const { enrollmentCycleYears } = useSchoolEnrollmentCycles()
  if (enrollmentCycleYears?.includes(Number(referenceYear))) {
    return referenceYear
  }
}

export default useRouterReferenceYear
