import { useApi, useSnackbar } from '@/shared/hooks'
import { LiquidationInfoResponse, ReceivableStatuses } from '@/shared/interfaces'
import { useEffect, useState } from 'react'
import { InstallmentV2 } from '@/modules/guardians/services/types'
import type { DiscountFieldType } from '../../types'
import dayjs, { Dayjs } from 'dayjs'
import { getDiscountWarningMessage, discountsFormByInstallment, getIdFromUrn } from '../../helper'
import { useGetContract } from '@/escolas/hooks'

interface Props {
  installment: InstallmentV2
  installmentIsLoading?: boolean
  isSimulateRequest?: boolean
  paidDate: Dayjs
}

const useLiquidationInfo = ({
  installment,
  paidDate,
  installmentIsLoading,
  isSimulateRequest = false,
}: Props) => {
  const { api } = useApi()

  const receivableId = getIdFromUrn(installment?.urn)
  const isDownPayment = installment?.type === 'DOWN_PAYMENT'

  const [isLoading, setIsLoading] = useState(false)
  const [liquidationInfo, setLiquidationInfo] = useState<LiquidationInfoResponse>()
  const [isAdditionalDiscountEnabled, setIsAdditionalDiscountEnabled] = useState<boolean>(
    installment?.current_additional_discount > 0
  )
  const { setSnackBar } = useSnackbar()

  const isPaid = installment?.status === ReceivableStatuses.PAID

  let { fine_and_interest, previous_fines_and_interest } = liquidationInfo || {}

  const searchForOriginalReceivable = () => {
    const { contract } = useGetContract(installment?.contracts?.[0]?.id)
    const installments = contract?.installments?.find(
      ({ id }) => id === installment.backoffice_installment_id
    )
    return installments?.receivables.find(({ id }) => id === receivableId)
  }

  const isRenegotiated = searchForOriginalReceivable()?.original_receivables?.some(
    r =>
      r?.status === ReceivableStatuses.RENEGOTIATED || r?.status === ReceivableStatuses.AGGLUTINATED
  )

  const form = discountsFormByInstallment(installment)
  const { getValues, watch, reset, setValue } = form

  watch([
    'additional_discount_amount',
    'apply_fines_and_interest_change',
    'apply_previous_fines_and_interest',
    'apply_lost_due_payment_discount',
    'apply_lost_early_payment_discount',
  ])
  const {
    additional_discount_amount,
    apply_fines_and_interest_change,
    apply_previous_fines_and_interest,
    apply_lost_due_payment_discount,
    apply_lost_early_payment_discount,
  } = getValues()

  const clearDiscounts = () => {
    form.reset()
    form.setValue('apply_previous_fines_and_interest', true)
    form.setValue('additional_discount_amount', 0)

    setIsAdditionalDiscountEnabled(false)
  }

  useEffect(() => {
    if (paidDate) {
      const shouldApplyFineAndInterest = dayjs(paidDate).isAfter(installment?.due_date)

      setValue('apply_previous_fines_and_interest', shouldApplyFineAndInterest)
      setValue('apply_fines_and_interest_change', shouldApplyFineAndInterest)
    }
  }, [paidDate])

  const onError = () => {
    reset()
    setSnackBar(
      'Ocorreu um erro ao consultar informações para o recebimento. Tente novamente.',
      'error'
    )
  }

  useEffect(() => {
    if (
      isLoading ||
      !dayjs(paidDate)?.isValid() ||
      isSimulateRequest ||
      installmentIsLoading ||
      isDownPayment
    ) {
      if (isDownPayment) {
        const downPaymentLiquidationInfo: LiquidationInfoResponse = isDownPayment
          ? {
              final_amount: installment?.current_amount,
              final_base_amount: installment?.base_amount,
              final_discount: 0,
              final_fine_and_interest:
                installment?.origin_negotiation?.fine_applied +
                installment?.origin_negotiation?.interest_applied,
              fine_and_interest: 0,
              lost_due_payment_discount: installment?.lost_due_payment_discount,
              lost_early_payment_discount: installment?.lost_early_payment_discount,
              payment_method: undefined,
              previous_due_payment_discount: installment?.discounts?.reduce(
                (acc, discount) =>
                  discount?.type === 'DUE_PAYMENT' ? acc + discount?.amount : acc,
                0
              ),
              previous_early_payment_discount: installment?.discounts?.reduce(
                (acc, discount) =>
                  discount?.type === 'EARLY_PAYMENT' ? acc + discount?.amount : acc,
                0
              ),
              previous_fines_and_interest:
                installment?.origin_negotiation?.exempt_fine +
                installment?.origin_negotiation?.exempt_interest +
                installment?.origin_negotiation?.fine_applied +
                installment?.origin_negotiation?.interest_applied,
            }
          : undefined
        setLiquidationInfo(downPaymentLiquidationInfo)
      }
      return
    }
    setIsLoading(true)
    api.receivables
      .liquidationInfo(receivableId, {
        additional_discount_amount: additional_discount_amount,
        apply_lost_due_payment_discount,
        apply_lost_early_payment_discount: apply_lost_early_payment_discount ?? false,
        is_edition: isPaid,
        paid_date: dayjs(paidDate)
          ?.hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .utc()
          .hour(0)
          .toISOString(),
        remove_fines_and_interest: !apply_previous_fines_and_interest,
        remove_fines_and_interest_change: !apply_fines_and_interest_change,
      })
      .then(data => {
        setLiquidationInfo(data)
      })
      .catch(onError)
      .finally(() => setIsLoading(false))
  }, [
    additional_discount_amount,
    apply_fines_and_interest_change,
    apply_previous_fines_and_interest,
    apply_lost_due_payment_discount,
    apply_lost_early_payment_discount,
    isAdditionalDiscountEnabled,
    paidDate,
    installmentIsLoading,
    isDownPayment,
  ])

  const finalAmount = liquidationInfo?.final_amount || 0
  const finalFineAndInterest = liquidationInfo?.final_fine_and_interest || 0

  const fineAndInterestChange = isPaid ? fine_and_interest - previous_fines_and_interest : 0

  if (!isPaid) {
    previous_fines_and_interest = fine_and_interest
  }

  const discountInfo: DiscountFieldType = {
    applyPreviousFinesAndInterest: apply_previous_fines_and_interest,
    disableDuePayment: false,
    disableEarlyPayment: false,
    disableFineAndInterestChange: true,
    disableFinesAndInterest: true,
    disableMoreDiscounts: false,
    finalBaseAmount: liquidationInfo?.final_base_amount,
    finalAmount: liquidationInfo?.final_amount,
    fineAndInterest: fine_and_interest,
    fineAndInterestChange: fineAndInterestChange,
    isAdditionalDiscountEnabled,
    isRenegotiated,
    lostDuePaymentDiscount: liquidationInfo?.lost_due_payment_discount,
    lostEarlyPaymentDiscount: liquidationInfo?.lost_early_payment_discount,
    previousFinesAndInterest: previous_fines_and_interest,
    setIsAdditionalDiscountEnabled,
    validateTotalDiscount: finalAmount - finalFineAndInterest > 0,
    warningMessage: !isPaid
      ? getDiscountWarningMessage(liquidationInfo, isRenegotiated)
      : 'Adicionar outros descontos ao pagamento impacta o repasse.',
  }
  return {
    discountInfo,
    form,
    isLoading,
    clearDiscounts,
    liquidationInfo,
  }
}

export default useLiquidationInfo
