export type DiscountOption = {
  description: string
  name: string
  value: number | null
}

export const DEFAULT_DISCOUNTS_OPTIONS = [
  { name: 'Desconto permanente', value: -1, description: 'Desconto independe do vencimento' },
  { name: 'Mantêm até vencimento', value: 0, description: 'Desconto para pagamento pontual' },
  { name: 'Antes do vencimento', value: null, description: 'Desconto para pagamento adiantado' },
]
