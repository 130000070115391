import styled from 'styled-components'

import { Link as LinkEl } from '@/modules/school-home/pages/SchoolHome/components/Link'

export const Link = styled(LinkEl)`
  .title {
    color: ${props => props.theme.gravity.colors['colors-text-main-3']};
  }

  &:link,
  &:visited {
    display: flex;
    flex-direction: column;

    padding: ${props => props.theme.gravity.spacing[4]} ${props => props.theme.gravity.spacing[8]};
    outline: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-3']};
    border-radius: ${props => props.theme.gravity.borderRadius[4]};
    text-decoration: none;
    color: ${props => props.theme.gravity.colors['colors-text-main-3']};
    height: 100%;
  }

  &:hover {
    background: ${props => props.theme.gravity.colors['colors-interaction-secondary-2']};
    outline-color: ${props => props.theme.gravity.colors['colors-border-accent-1']};

    .title,
    .button-link-icon {
      color: ${props => props.theme.gravity.colors['colors-text-main-1']};
    }
  }

  &:active {
    background-color: ${props => props.theme.gravity.colors['colors-interaction-secondary-2']};
    outline: 2px solid ${props => props.theme.gravity.colors['colors-interaction-primary-2']};
  }

  &:focus-visible {
    outline: 1px solid ${props => props.theme.gravity.colors['colors-interaction-primary-2']};
  }
`

export const Icon = styled.div`
  height: ${props => props.theme.gravity.spacing[6]};
  margin-bottom: ${props => props.theme.gravity.spacing[1]};
`
