import dayjs from 'dayjs'
import { useEffect } from 'react'
import { UseFormReturn, ValidateResult, useFieldArray } from 'react-hook-form'

import {
  FormProps,
  Offer,
} from '@/modules/enrollment/pages/Campaigns/NewCampaign/components/PaymentConditionsStep/types'

export const useManageOfferConditions = (form: UseFormReturn<FormProps, any>) => {
  const MAX_OFFER_ITEMS = 3

  const defaultOfferValue: Offer = {
    amount: null,
    expirationDate: null,
  }

  const { fields: offerFields, append: appendOffer, remove: removeOffer } = useFieldArray({
    control: form.control,
    name: 'offers',
  })
  const offers = form.watch('offers')

  const isAddOfferBtnDisabled = offerFields.length >= MAX_OFFER_ITEMS
  const isRemoveButtonDisabled = offerFields.length === 1

  const addNewOfferInput = () => {
    if (isAddOfferBtnDisabled) return
    appendOffer(defaultOfferValue)
  }

  const handleRemoveOfferClick = (index: number) => {
    return () => removeOffer(index)
  }

  const calculateTheLatestOfferDate = (date: Date | null = null) => {
    const latestOfferDate = offers.reduce(
      (acc, curr) => (curr.expirationDate && curr.expirationDate > acc ? curr.expirationDate : acc),
      date
    )
    if (latestOfferDate) form.setValue('end_date', latestOfferDate)
  }

  const validateOfferDueDate = (date: Date, index: number): ValidateResult => {
    if (!date) return 'Você deve selecionar uma data de expiração'

    if (index === 0) return true

    const prevOfferDate = offers[index - 1].expirationDate
    if (prevOfferDate && dayjs(date).isSameOrBefore(dayjs(prevOfferDate))) {
      return 'A data de validade deve ser posterior à data da condição anterior.'
    }

    return true
  }

  useEffect(() => {
    return () => {
      form.resetField('offers')
      form.resetField('end_date')
    }
  }, [])

  useEffect(() => {
    if (offerFields.length === 0) {
      appendOffer(defaultOfferValue)
    } else {
      calculateTheLatestOfferDate()
    }
  }, [offerFields])

  return {
    isAddOfferBtnDisabled,
    isRemoveButtonDisabled,
    handleRemoveOfferClick,
    validateOfferDueDate,
    addNewOfferInput,
    calculateTheLatestOfferDate,
    offerFields,
  }
}
