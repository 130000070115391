import { SchoolChargeConfig } from './SchoolChargeOperation'

export type School = {
  charge_operation?: string
  created_at: Date
  educational_stage?: EducationalStage | ''
  id: string
  name: string
  school_charge_config?: SchoolChargeConfig
  slug: string
  source_id: string
  tax_id: string
  updated_at: Date
}

export enum EducationalStage {
  COLLEGE = 'tertiary',
  STANDARD = 'standard',
}
