import type { AgreementSimulationCombined } from '@/modules/guardians/models/agreement'

type Receivable = {
  ids: string[]
  totalAmount: number
}

export const getReceivablesByAgreementSimulation = (agreements: AgreementSimulationCombined[]) => {
  const receivablesByAgreementSimulationId = new Map<string, Receivable>()

  const filteredAgreements = agreements.filter(agreement => agreement.agreement_simulation_id)

  filteredAgreements.forEach(agreement => {
    const { receivable_ids, agreement_simulation_id } = agreement
    receivablesByAgreementSimulationId.set(agreement_simulation_id, {
      ids: receivable_ids,
      totalAmount: agreement.total_amount,
    })
  })

  return receivablesByAgreementSimulationId
}
