import { useCallback } from 'react'
import { debounce } from 'throttle-debounce'
import { useHistory } from 'react-router-dom'
import { useToast } from '@gravity/toast'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import type { School } from '@/shared/models/School'

import { useAddTuitionToCampaign } from './useAddTuitionToCampaign'
import { useAddBulkInstallmentStore } from '../store/AddBulkInstallmentStore'
import { useBulkInstallmentAvailability } from './useBulkInstallmentAvailability'

type Props = {
  campaignId: uuid
  school?: School
}
export const useAddTuitionToCampaignStudentInBatch = ({ campaignId, school }: Props) => {
  const {
    formData,
    customInstallmentsFormData,
    excludedEnrollmentIds,
    resetStore,
  } = useAddBulkInstallmentStore()

  const { addTempUnavailability } = useBulkInstallmentAvailability(campaignId)
  const { addTuition, isLoading } = useAddTuitionToCampaign({
    campaignId,
    school,
  })

  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()
  const { toast } = useToast()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const addTuitions = useCallback(async () => {
    const customEnrollmentIds = Array.from(customInstallmentsFormData.keys())
    const excludedIds = [...excludedEnrollmentIds, ...customEnrollmentIds]
    try {
      const response = await addTuition({
        formData,
        excludedEnrollmentIds: excludedIds,
        customInstallmentsFormData,
      })
      if (!response?.data) return

      addTempUnavailability(response.data.total_affected_enrollments)

      history.replace({
        pathname: `/${schoolSlug}/campanha/${campaignId}`,
        search: '',
      })
      toast({
        title: 'As mensalidades estão sendo processadas',
        type: 'success',
      })
      resetStore()
    } catch (error) {
      toast({
        title: 'Ocorreu um erro, tente novamente.',
        type: 'error',
      })
    }
  }, [schoolSlug, campaignId, formData, excludedEnrollmentIds, customInstallmentsFormData])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const debouncedAddTuitions = useCallback(debounce(500, addTuitions), [addTuitions])

  return {
    addTuitions: debouncedAddTuitions,
    isLoading,
  }
}
