import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { adaptAgreementsToProductsByStudent } from '../helpers/adaptAgreementsToProductsByStudent'

export function useInstallmentsByStudents() {
  const { agreementSimulations, totalAmount } = useAgreement()

  const hasDiscounts = agreementSimulations.some(
    ({ due_payment_discount_applied, early_payment_discount_applied, agreement_simulation_id }) =>
      due_payment_discount_applied + early_payment_discount_applied > 0 && agreement_simulation_id
  )

  const installmentsByStudents = adaptAgreementsToProductsByStudent(agreementSimulations)

  return {
    installmentsByStudents,
    hasDiscounts,
    totalAmount,
  }
}
