import { useEffect, useState } from 'react'
import { Button } from '@gravity/button'
import { Checkbox } from '@gravity/checkbox'
import { DialogPrimitives } from '@gravity/dialog'
import { Text } from '@gravity/text'

import { CustomDialog, Container } from './styles'
import dayjs from 'dayjs'
import { useJWT } from '@/shared/hooks'

export const LOCALSTORAGE_ENTRY_DISABLED_KEY = '@olaisaac/entry-overdue-dialog-last-view'

export const EnrollmentOverdueDialog = () => {
  const { userId } = useJWT()
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<Record<string, string>>({})
  const [checked, setChecked] = useState(false)

  const handleClose = () => {
    const lastView = { ...value }

    if (checked) {
      lastView[userId] = 'never'
    } else {
      lastView[userId] = dayjs().toISOString()
    }

    localStorage.setItem(LOCALSTORAGE_ENTRY_DISABLED_KEY, JSON.stringify(lastView))
    setValue(lastView)
    setOpen(false)
  }

  const handleClickOutside = (event: any) => {
    event.preventDefault()
  }

  useEffect(() => {
    const lastView = JSON.parse(localStorage.getItem(LOCALSTORAGE_ENTRY_DISABLED_KEY)) ?? {}
    const shouldReopen = lastView[userId] !== 'never' && dayjs().diff(lastView[userId], 'day') >= 1

    if (!lastView[userId] || shouldReopen) {
      lastView[userId] = dayjs().toISOString()
      localStorage.setItem(LOCALSTORAGE_ENTRY_DISABLED_KEY, JSON.stringify({ ...lastView }))
      setValue(lastView)
      setOpen(true)
      return
    }

    setValue(lastView)
    setOpen(shouldReopen)
  }, [])

  return (
    <CustomDialog>
      <DialogPrimitives.Root open={open} onOpenChange={handleClose}>
        <DialogPrimitives.Overlay backdrop />
        <DialogPrimitives.Content
          title="Pré-matrícula vencida"
          size={1}
          onInteractOutside={handleClickOutside}
          data-testid="enrollment-overdue-dialog"
        >
          <Container>
            <Text variant="body-2-regular">
              Agora as faturas de pré-matrícula precisam ser renovadas antes de serem pagas, caso
              estejam vencidas. Para isso, basta colocar o mouse sobre a fatura e clicar no botão
              ”Editar contrato”.
            </Text>

            <label htmlFor="entry-overdue-dialog-checkbox">
              <Checkbox
                id="entry-overdue-dialog-checkbox"
                checked={checked}
                onCheckedChange={value => setChecked(value as boolean)}
                text="Não mostrar esta mensagem novamente"
                size={1}
                style={{ marginLeft: 0 }}
              />
            </label>

            <Button variant="solid" color="accent" onClick={handleClose}>
              Entendi
            </Button>
          </Container>
        </DialogPrimitives.Content>
      </DialogPrimitives.Root>
    </CustomDialog>
  )
}
