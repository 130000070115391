import styled from 'styled-components'

import { colors, fontSize, fontWeight, letterSpacing, lineHeight, spacing } from '@gravity/tokens'
import { Button } from '@gravity/button'
import { Grid, Typography } from '@material-ui/core'
import { Select } from '@gravity/select'
import { Skeleton } from '@gravity/skeleton'
import { Card } from '@gravity/card'

export const ButtonGroup = styled.div`
  display: flex;
  direction: column;
  align-items: center;
  margin-top: ${spacing[10]};
  justify-content: flex-end;
`

export const CheckBoxContainer = styled.div`
  display: flex;
  height: ${spacing[5]};
  align-items: center;
  margin-top: ${spacing[5]};
  gap: ${spacing[2]};
`

export const GhostButton = styled(Button)`
  min-width: 186px;
`

export const StyledGrid = styled(Grid)`
  width: 100%;
`

export const StyledOverlay = styled.div`
  opacity: 0.87;
  position: absolute;
  background: ${colors['colors-background-neutral-1']};
  top: ${spacing.none};
  right: ${spacing.none};
  left: ${spacing.none};
  bottom: ${spacing.none};
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const TitleText = styled(Typography)`
  font-size: ${fontSize['2xl']};
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.normal};
  letter-spacing: ${letterSpacing.normal};
  margin-bottom: ${spacing[6]};
  margin-top: ${spacing[6]};
`

export const ContainerCard = styled(Card)`
  margin-top: ${spacing[6]};
  padding: ${spacing[6]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[4]};
  width: 100%;
  border-radius: 8px;
  background-color: ${colors['colors-background-neutral-2']};
`

export const ResultAnticipationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[4]};
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;
`

export const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const HeaderContainer = styled.div``

export const BaseText = styled(Typography)`
  font-weight: ${fontWeight.normal};
  line-height: ${lineHeight.normal};
  letter-spacing: ${letterSpacing.normal};
`

export const KeyText = styled(BaseText)`
  color: ${colors['colors-text-main-3']};
  font-size: ${fontSize.base};
`

export const BoldKeyText = styled(BaseText)`
  color: ${colors['colors-text-main-3']};
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.medium};
`

export const BigValueText = styled(BaseText)`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.lg};
`

export const ValueText = styled(BaseText)`
  font-size: ${fontSize.base};
`

export const BoldValueText = styled(BaseText)`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.base};
`

export const CommentText = styled(BaseText)`
  color: ${colors['colors-text-main-3']};
  font-size: ${fontSize.xs};
  margin-top: 20px;
`

export const StyledSelect = styled(Select)<{ $fullWith?: boolean }>`
  width: ${props => (props.$fullWith ? '100%' : '300px')};
  margin-bottom: ${spacing[3]};
`

export const StyledSkeleton = styled(Skeleton)`
  margin-bottom: ${spacing[2]};
`
