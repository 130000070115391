import { useState, useMemo } from 'react'
import { useGuardianFinanceSummaryQuery } from '@/modules/guardians/GuardianDetailsFinancialSummaryTab/hooks/useGuardianFinanceSummaryQuery'
import { useParams, useLocation } from 'react-router-dom'
import { Row } from '@/modules/guardians/types'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import {
  reorderByStatus,
  concatAndFilterInstallmentsToBeListed,
  sortInstallmentsToSummaryPage,
} from '../utils/transformInstallments'
import { useInstallmentDrawerContext } from '@/modules/guardians/contexts/InstallmentDrawerContext'
import { OrderedGuardianSummaryInfo } from '../types'
import { GuardianFinanceSummaryStatus } from '../../GuardianDetails/constants'

export const useGuardianDetailsFinancialSummaryTab = () => {
  const { guardianId } = useParams<{ guardianId: string }>()
  const { school } = useSelectedSchool()
  const { selectedInstallment, setSelectedInstallment } = useInstallmentDrawerContext()

  const [showInstallmentsDetailsDrawer, setShowInstallmentsDetailsDrawer] = useState(false)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const {
    guardianFinanceSummary,
    isLoading,
    isRefetching,
    isError,
    isSuccess,
  } = useGuardianFinanceSummaryQuery({
    guardianId,
    schoolId: school.id,
  })

  const filteredInstallmentsData = useMemo(() => {
    const filters = queryParams.get('installment_status')?.split(',')
    if (isSuccess) {
      const orderedGuardianFinanceSummary = reorderByStatus(
        guardianFinanceSummary.data?.guardian_finance_summary.status
      )
      return sortInstallmentsToSummaryPage(
        concatAndFilterInstallmentsToBeListed(orderedGuardianFinanceSummary, filters)
      )
    }
  }, [location, guardianFinanceSummary])

  const guardianSummaryData = useMemo(() => {
    if (isSuccess)
      return reorderByStatus(guardianFinanceSummary.data.guardian_finance_summary.status)
  }, [guardianFinanceSummary])

  const handleRowClick = (row: Row): void => {
    setSelectedInstallment(row)
    setShowInstallmentsDetailsDrawer(true)
  }
  const handleCloseDrawer = () => {
    setShowInstallmentsDetailsDrawer(false)
  }

  const filtersKey = queryParams.get('installment_status')?.split(',').length
  const isEmpty = guardianSummaryData?.length === 0
  const hasOnlyPaidInstallments = guardianSummaryData?.every(summary =>
    summary.status.includes(GuardianFinanceSummaryStatus.PAID)
  )

  const guardianSummarySkeletonRenderQuantity = [1, 2, 3] as OrderedGuardianSummaryInfo[]
  return {
    guardianSummary: guardianSummaryData ?? guardianSummarySkeletonRenderQuantity,
    isEmpty,
    installments: filteredInstallmentsData,
    isLoadingGuardianSummaryData: isLoading || isRefetching,
    isSuccessGuardianSummaryData: isSuccess,
    isErrorGuardianSummaryData: isError,
    guardianId,
    handleRowClick,
    selectedInstallment,
    showInstallmentsDetailsDrawer,
    handleCloseDrawer,
    filtersKey,
    hasOnlyPaidInstallments,
  }
}
