import styled from 'styled-components'
import { colors, spacing, borderRadius } from '@gravity/tokens'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[8]};
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${spacing[8]} ${spacing[10]};
  background-color: ${colors['colors-background-neutral-2']};
  border-radius: ${borderRadius[2]};
  gap: ${spacing[6]};
`
export const IconWrapper = styled.img`
  width: 20px;
  height: auto;
`
