export enum EventIdentifierName {
  CANCEL_CREATE_ACCESS = 'cancelar',
  CREATE_NEW_ACCESS = 'criar_novo_acesso',
  EDIT_ACCESS = 'editar_acesso',
  INVALID_EMAIL = 'email_invalido',
  LINK_LEARN_MORE_ABOUT_ACCESS = 'saiba_mais_sobre_acessos',
  NEW_ACCESS = 'novo_acesso',
  REMOVE_ACCESS = 'excluir_acesso',
  TOAST_CREATED_ACCESS_ERROR = 'erro_na_criacao_de_acesso',
  TOAST_CREATED_ACCESS_SUCCESS = 'acesso_criado',
  TOAST_REMOVE_ACCESS_ERROR = 'erro_na_exclusao_do_acesso',
  TOAST_REMOVE_ACCESS_SUCCESS = 'acesso_excluido',
  USER_ACCESS = 'acessos_de_usuarios',
}
