import { useState } from 'react'
import styled from 'styled-components'

import { MenuItem, Popover } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { Table, TableColumns, IconButton } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

import {
  renderDebtStatus,
  renderContractStatus,
} from '@/escolas/components/contract/ContractDetails'
import {
  ContractResponse,
  MatriculasApiContractStatus,
  ReenrollmentStatus,
} from '@/shared/interfaces'
import { useNavigation } from '@/escolas/hooks'
import { useJWT } from '@/shared/hooks/useJWT'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import { useAcceptPaymentPlan } from '../hooks/queries/useAcceptPaymentPlan'
import { AcceptPaymentPlanDialog } from './AcceptPaymentPlanDialog'
import { useQueryClient } from '@tanstack/react-query'
import { CancelProposalDialog } from '@/modules/enrollment/components/CancelProposalDialog'
import { useCancelProposal } from '@/modules/enrollment/pages/StudentEnrollment/hooks/queries/useCancelProposal'
import { useCancelContractModal } from '@/modules/contract/pages/EditContract/components/CancelContractModal/useCancelContractModal'
import { CancelContractModal } from '@/modules/contract/pages/EditContract/components/CancelContractModal'

type Rows = Array<
  ContractResponse & {
    actions: {
      canAcceptPaymentPlan: boolean
      contractId: string
      guardianId: string
      productId: string
      referenceYear: string
      status: ContractResponse['status']
      studentId: string
      studentName: string
    }
    key: string
  }
>

const mapDataToRows = (rawData: ContractResponse[]): Rows => {
  return rawData.map(item => ({
    ...item,
    key: item.id,
    actions: {
      contractId: item.id,
      guardianId: item.guardian_id,
      referenceYear: item.reference_year,
      studentName: item.student.name,
      studentId: item.student_id,
      productId: item.product_id,
      status: item.status,
      canAcceptPaymentPlan: item.can_accept_payment_plan,
    },
  }))
}

type StudentTableProps = {
  data: ContractResponse[]
  enrollmentStatus: ReenrollmentStatus
  isLoading: boolean
  waitTime?: number
}

const TableContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[5]};

  & tbody tr {
    cursor: pointer;
  }
`

const ActionContainer = styled.div`
  margin-left: auto;
`

const NODES_NOT_ALLOWED_CLICK = ['LI', 'BUTTON']

export const ContractsTable = ({
  data,
  isLoading,
  enrollmentStatus,
  waitTime = 5000,
}: StudentTableProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedRow, setSelectedRow] = useState<null | Rows[number]['actions']>(null)
  const considerUserRole = useUnleashFlag(UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL)
  const { school } = useSelectedSchool()
  const { navigateTo, newTabNavigateTo, schoolId } = useNavigation()
  const acceptPPMutation = useAcceptPaymentPlan(waitTime)
  const {
    mutate: { mutate, isLoading: isLoadingMutation },
    onSuccessDefault,
  } = useCancelProposal()
  const { getUserRolesBySchool, isBackofficeUser } = useJWT()
  const queryClient = useQueryClient()
  const [proposalIDToCancel, setProposalIDToCancel] = useState<uuid>(null)

  const cancelContractNewRules = useUnleashFlag(UnleashFlags.EFI_215_CANCEL_CONTRACT_NEW_RULES)

  const cancelContractModalState = useCancelContractModal()

  const handleClickMoreOptions = (
    event: React.MouseEvent<HTMLElement>,
    row?: Rows[number]['actions']
  ) => {
    setAnchorEl(event.currentTarget)
    setSelectedRow(row)
  }

  const handleClickToSeeInstallments = (row?: Rows[number]) => {
    if (row?.status === MatriculasApiContractStatus.PENDING_GUARDIAN_APPROVAL) return

    let rowData: any = selectedRow
    if (row) {
      rowData = {
        guardianId: row.guardian_id,
        studentId: row.student_id,
        referenceYear: row.reference_year,
        productId: row.product_id,
      }
    }

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
        name: row ? EventDispatcherEvents.TABLE_ROW_CLICK : EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $Button_name: 'VER_PARCELAS',
          $table_name: 'LISTA_DE_PRODUTOS',
          $Enrollment_status: enrollmentStatus,
        },
      })

    const pathInstallments = `/responsaveis/${rowData?.guardianId}/faturas?student_ids=${rowData?.studentId}&product_ids=${rowData?.productId}&reference_years=${rowData.referenceYear}`

    setAnchorEl(null)
    if (row) {
      newTabNavigateTo({ path: pathInstallments })
    } else {
      navigateTo({
        path: pathInstallments,
      })
    }
  }

  const handleClickToSeeContract = () => {
    const pathContracts = `/responsaveis/${selectedRow?.guardianId}/contratos?student_ids=${selectedRow?.studentId}&product_ids=${selectedRow?.productId}`

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $Button_name: 'VER_CONTRATOS',
          $table_name: 'LISTA_DE_PRODUTOS',
          $Enrollment_status: enrollmentStatus,
        },
      })

    setAnchorEl(null)

    navigateTo({
      path: pathContracts,
    })
  }

  const handleClickToEditPreContract = () => {
    setAnchorEl(null)

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $Button_name: 'EDITAR_CONTRATOS',
          $table_name: 'LISTA_DE_PRODUTOS',
          $Enrollment_status: enrollmentStatus,
        },
      })

    navigateTo({
      path: `/contratos/${selectedRow?.contractId}/editar`,
    })
  }

  const handleAcceptPaymentPlan = (enrollmentID: uuid) => {
    acceptPPMutation.mutate({ enrollmentID })
  }

  const handleCloseAcceptPaymentPlanDialog = () => {
    acceptPPMutation.reset()
    queryClient.invalidateQueries(['matriculas-api-contracts'])
    queryClient.invalidateQueries(['contracts', 'students'])
  }

  const handleConfirmCancelProposal = () => {
    mutate(
      { enrollmentID: proposalIDToCancel, schoolID: schoolId },
      {
        onSuccess: () => {
          onSuccessDefault()
          setProposalIDToCancel(null)
        },
      }
    )
  }

  const closePopover = () => {
    setSelectedRow(null)
    setAnchorEl(null)
  }

  const hasEditContractPermission =
    isBackofficeUser || getUserRolesBySchool(school?.id ?? '').has('editar_contrato')

  const showEditContractOption = !considerUserRole || hasEditContractPermission

  const columns: TableColumns = [
    {
      headerName: 'Status',
      field: 'status',
      renderCell: renderContractStatus,
    },
    { headerName: 'Ano', field: 'reference_year' },
    {
      headerName: 'Produto',
      field: 'product',
      valueFormatter: e => e.name,
    },
    {
      headerName: 'Situação Financeira',
      field: 'debt_status',
      renderCell: value => renderDebtStatus(value),
    },
    {
      field: 'actions',
      renderCell: (value: Rows[number]['actions']) => (
        <ActionContainer>
          <IconButton variation="ghost" onClick={e => handleClickMoreOptions(e, value)}>
            <MoreVert />
          </IconButton>
          <Popover
            anchorEl={selectedRow?.contractId === value.contractId ? anchorEl : null}
            keepMounted={false}
            open={selectedRow?.contractId === value.contractId}
            onClose={closePopover}
          >
            {value.status === MatriculasApiContractStatus.PENDING_GUARDIAN_APPROVAL ? (
              <>
                {value.canAcceptPaymentPlan && (
                  <MenuItem
                    onClick={() => {
                      handleAcceptPaymentPlan(value.contractId)
                      closePopover()
                    }}
                  >
                    Gerar boleto de pré-matrícula
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setProposalIDToCancel(value.contractId)
                    closePopover()
                  }}
                >
                  Cancelar proposta
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem onClick={() => handleClickToSeeInstallments()}>Ver parcelas</MenuItem>
                <MenuItem onClick={() => handleClickToSeeContract()}>Ver contrato</MenuItem>
                {showEditContractOption && (
                  <MenuItem onClick={() => handleClickToEditPreContract()}>
                    Editar contrato
                  </MenuItem>
                )}
                {cancelContractNewRules && (
                  <MenuItem
                    onClick={() => {
                      cancelContractModalState.open(value.contractId)
                      closePopover()
                    }}
                  >
                    Cancelar Contrato
                  </MenuItem>
                )}
              </>
            )}
          </Popover>
        </ActionContainer>
      ),
    },
  ]

  const rows: Rows = isLoading ? [] : mapDataToRows(data)

  return (
    <>
      <TableContainer>
        <Table
          isLoading={isLoading}
          columns={columns}
          rows={rows}
          onRowClick={(params, e) => {
            !NODES_NOT_ALLOWED_CLICK.includes(e.target.nodeName) &&
              !anchorEl &&
              handleClickToSeeInstallments(params.row)
          }}
        />
      </TableContainer>
      <AcceptPaymentPlanDialog
        isLoading={acceptPPMutation.isLoading}
        isSuccess={acceptPPMutation.isSuccess}
        isError={acceptPPMutation.isError}
        installment={acceptPPMutation.isSuccess ? acceptPPMutation.data : null}
        onClose={handleCloseAcceptPaymentPlanDialog}
      />
      <CancelProposalDialog
        open={proposalIDToCancel !== null}
        onConfirm={handleConfirmCancelProposal}
        onClose={() => setProposalIDToCancel(null)}
        isLoading={isLoadingMutation}
      />

      {cancelContractNewRules && (
        <CancelContractModal
          state={cancelContractModalState}
          onSuccess={() => {
            setTimeout(() => {
              queryClient.invalidateQueries(['matriculas-api-contracts'])
              queryClient.invalidateQueries(['contracts', 'students'])
            }, 1000)
          }}
        />
      )}
    </>
  )
}
