import { useQuery } from '@tanstack/react-query'
import { omit } from 'ramda'
import { SOFTCACHE_OPTIONS_BASE } from 'src/shared/constants'
import { useApi } from 'src/shared/hooks'
import { Installment } from 'src/shared/interfaces'
import { parseUpdatedInstallments } from '../components/contract/ContractEditDrawer/utils'

export type useChangeDueDayInfoParams = {
  change_due_month: boolean
  contractId: uuid
  due_day: number
  installment_id: uuid
  start_month: datestring
  use_working_dates?: boolean
}

const validateParams = (params: useChangeDueDayInfoParams): boolean => {
  return (
    !!params?.contractId && !!params?.installment_id && !!params?.due_day && !!params?.start_month
  )
}

export const useChangeDueDayInfo = (
  params: useChangeDueDayInfoParams = {
    change_due_month: false,
    contractId: null,
    due_day: null,
    installment_id: null,
    start_month: null,
  }
) => {
  const { api } = useApi()
  const queryKey = ['change-due-day-info', params]

  const methods = useQuery<{
    flexible_maximum_due_date: string
    is_contract_in_flexible_period: boolean
    new_installments: Installment[]
    original_installments: Installment[]
  }>(
    queryKey,
    () => api.contracts.changeDueDayInfo(params.contractId, omit(['contractId'], params)),
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: validateParams(params),
    }
  )

  const { data, isLoading, isError } = methods

  return {
    ...methods,
    installmentsToChangeDueDay: data ? parseUpdatedInstallments(data) : [],
    isInstallmentsToChangeDueDayLoading: isLoading,
    isInstallmentsToChangeDueDayError: isError,
  }
}
