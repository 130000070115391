import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { withStyles } from '@material-ui/core'

import { colors, spacing } from '@gravity/tokens'

export const Accordion = withStyles({
  root: {
    margin: 0,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    width: '100%',
    minHeight: 0,
    maxHeight: '56px',
    padding: `${spacing[2]} ${spacing[6]}`,
    backgroundColor: colors['colors-background-neutral-2'],
    borderBottom: '1px solid #D0D0D0',
    '&.Mui-expanded': {
      minHeight: 0,
    },
  },
})(MuiAccordionSummary)

export const AccordionDetails = withStyles({
  root: {
    width: '100%',
    margin: 0,
    padding: `${spacing[6]} ${spacing[6]} 0`,
    display: 'flex',
    flexDirection: 'column',
    gap: `${spacing[2]}`,
  },
})(MuiAccordionDetails)
