import dayjs, { Dayjs } from 'dayjs'
import { Box, FormControl } from '@material-ui/core'
import { DatePicker, Typography } from '@olaisaac/design-system'
import { Controller, UseFormReturn } from 'react-hook-form'
import { omit } from 'ramda'

import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'
import { DATE_FORMAT, isDayTodayOrAfter } from '@/shared/utils'

import { ContractEditForm } from '../../ContractEditDueDayDrawer'
import { Installment, InstallmentType } from '@/shared/interfaces'

export const validDueDateRange = (
  isAdmin: boolean,
  ffEnabled: boolean,
  inFlexPeriod: boolean,
  flexMaxDueDate: string,
  allInstallments: Installment[],
  selectedInstallmentID: uuid
): [Dayjs, Dayjs] => {
  const selectedInstallmentDueDate = dayjs.utc(
    allInstallments.find(i => i.id === selectedInstallmentID).due_date
  )
  const startOfMonth = selectedInstallmentDueDate.startOf('month')
  const endOfMonth = selectedInstallmentDueDate.endOf('month')

  if (isAdmin) {
    return [startOfMonth, endOfMonth]
  }

  const firstTuitionInstallment = allInstallments.filter(i => i.type === InstallmentType.TUITION)[0]
  const firstInstallmentSelected = firstTuitionInstallment.id === selectedInstallmentID

  if (ffEnabled && inFlexPeriod && firstInstallmentSelected) {
    return [startOfMonth, dayjs.utc(flexMaxDueDate)]
  }

  return [startOfMonth, endOfMonth]
}

export type SelectDueDayProps = {
  allInstallments: Installment[]
  flexibleMaxDueDate: string
  form: UseFormReturn<ContractEditForm>
  inFlexPeriod: boolean
  selectedInstallmentID: uuid
}

const SelectFirstDueDay = ({
  flexibleMaxDueDate,
  form,
  inFlexPeriod,
  allInstallments,
  selectedInstallmentID,
}: SelectDueDayProps) => {
  const { isAdmin } = useJWT()
  const {
    control,
    formState: { errors },
  } = form
  const flexPeriodFFEnabled = useUnleashFlag(
    UnleashFlags.ENABLE_NEW_RULES_FOR_SCHOOL_CONTRACT_EDITS
  )

  const isNewContractEditRulesEnabled = flexPeriodFFEnabled && !isAdmin && inFlexPeriod

  if (!allInstallments || !selectedInstallmentID) {
    return null
  }

  if (!isAdmin && !isNewContractEditRulesEnabled) {
    return null
  }

  const dateRange = validDueDateRange(
    isAdmin,
    flexPeriodFFEnabled,
    inFlexPeriod,
    flexibleMaxDueDate,
    allInstallments,
    selectedInstallmentID
  )

  return (
    <>
      <Box mb={4}>
        <Box mb={2}>
          <Typography variation="subtitleDesktopLarge">
            Escolha a data de vencimento da primeira parcela selecionada.
          </Typography>
        </Box>
      </Box>

      <FormControl variant="outlined">
        <Controller
          rules={{
            required: true,
            validate: {
              inThePast: v =>
                isDayTodayOrAfter(v) ||
                'Escolha uma data de vencimento válida e que não esteja no passado',
              minDate: v =>
                v.isSame(dateRange[0]) ||
                v.isAfter(dateRange[0]) ||
                'A data não pode ser anterior ao início do mês atual do vencimento da parcela',
              maxDate: v =>
                v.isSame(dateRange[1]) ||
                v.isBefore(dateRange[1]) ||
                'A data não pode ser posterior ao fim do mês atual de vencimento da parcela',
            },
          }}
          name="start_month"
          control={control}
          render={({ field }) => (
            <DatePicker
              label="Vencimento 1ª parcela"
              placeholder={DATE_FORMAT}
              format={DATE_FORMAT}
              disablePast
              minDate={dateRange[0]}
              maxDate={dateRange[1]}
              error={Boolean(errors.start_month)}
              helperText={errors.start_month ? errors.start_month.message : ''}
              {...omit(['ref'], field)}
            />
          )}
        />
      </FormControl>
    </>
  )
}

export default SelectFirstDueDay
