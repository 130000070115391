import { useNavigation } from '@/escolas/hooks'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { useApi } from 'src/shared/hooks'
import { Checkout, CheckoutStatus, CheckoutType, CheckoutProvider } from 'src/shared/interfaces'
import { SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import { useSendCheckoutEvent } from './useSendCheckoutEvents'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { useCreateUnifiedInvoiceQuery } from '../../hooks/useCreateUnifiedInvoiceQuery'
import {
  UnifiedInvoicePaymentMethod,
  UnifiedInvoiceRequestBody,
} from '@/shared/interfaces/unifiedInvoice'

type StartCheckoutData = {
  checkoutProvider?: CheckoutProvider
  downPaymentConversion?: boolean
  keepDuePaymentDiscounts?: boolean
  keepEarlyPaymentDiscounts?: boolean
  keepFineAndInterest?: boolean
  numberOfInstallments: number
  paymentMethod: SimulationPaymentMethod
  posId?: uuid
  receivablesIds: string[]
}

const initCheckoutStatusPolling = (
  getCheckout: (id: uuid) => Promise<Checkout>,
  checkoutObject: Checkout,
  setCheckoutObject: Dispatch<SetStateAction<Checkout | null>>,
  setIsProcessingCheckoutPayment: Dispatch<SetStateAction<boolean>>,
  setCheckoutPollingInterval: Dispatch<SetStateAction<NodeJS.Timeout | null>>,
  setShowErrorDialog: Dispatch<SetStateAction<boolean>>
) => {
  setIsProcessingCheckoutPayment(true)

  const checkoutId = checkoutObject.id

  let asking = false

  const ask = () => {
    if (asking) {
      return
    }

    asking = true

    getCheckout(checkoutId)
      .then(data => {
        setCheckoutObject(data)
      })
      .catch(() => {
        setIsProcessingCheckoutPayment(false)
        setShowErrorDialog(true)
      })
      .finally(() => {
        asking = false
      })
  }

  const interval = setInterval(ask, 4000)
  setCheckoutPollingInterval(interval)
}

const useFlowCheckout = (onSuccessfullyConcluded: (data: Checkout) => void) => {
  const sendCheckoutEvent = useSendCheckoutEvent()

  const { api } = useApi()

  const [checkoutObject, setCheckoutObject] = useState<Checkout | null>(null)
  const [checkoutInformationTitle, setCheckoutInformationTitle] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  const [isProcessingCheckoutPayment, setIsProcessingCheckoutPayment] = useState<boolean>(false)
  const [isCheckoutFeedbackDisabled, setIsCheckoutFeedbackDisabled] = useState<boolean>(false)
  const [checkoutPollingInterval, setCheckoutPollingInterval] = useState<NodeJS.Timeout | null>(
    null
  )

  const [waitingApi, setWaitingApi] = useState<boolean>(false)

  const { guardian } = useNavigation()

  const { mutate: invoiceMutate } = useCreateUnifiedInvoiceQuery()

  const getCheckoutCode = () => (Math.random() + 1).toString(36).substring(7).toUpperCase()

  const startCheckout = ({
    numberOfInstallments,
    paymentMethod,
    posId,
    receivablesIds,
    checkoutProvider,
    downPaymentConversion = false,
    keepDuePaymentDiscounts = true,
    keepFineAndInterest = true,
  }: StartCheckoutData) => {
    setWaitingApi(true)
    const code = getCheckoutCode()
    setCheckoutInformationTitle(code)

    const checkoutType =
      checkoutProvider === CheckoutProvider.STONE_PAGARME
        ? CheckoutType.PAGARME_CHECKOUT
        : CheckoutType.STONE_CHECKOUT

    const body = {
      checkout_type: checkoutType,
      information_title: code,
      installments_count: numberOfInstallments,
      keep_due_payment_discounts: keepDuePaymentDiscounts,
      keep_early_payment_discounts: false,
      keep_fine_and_interest: keepFineAndInterest,
      payment_methods: [paymentMethod],
      pos_id: posId ?? '',
      receivables_ids: receivablesIds,
      down_payment_conversion: downPaymentConversion,
    }

    api.checkout
      .startCheckout(body)
      .then(data => {
        setCheckoutObject(data)
        setIsCheckoutFeedbackDisabled(false)
        initCheckoutStatusPolling(
          api.checkout.getCheckout,
          data,
          setCheckoutObject,
          setIsProcessingCheckoutPayment,
          setCheckoutPollingInterval,
          setShowErrorDialog
        )
      })
      .catch(() => {
        setShowErrorDialog(true)
      })
      .finally(() => {
        setWaitingApi(false)
      })
  }

  const startCheckoutV2 = (
    numberOfInstallments: number,
    paymentMethod: SimulationPaymentMethod,
    posId: uuid,
    urns: string[],
    checkoutProvider?: CheckoutProvider
  ) => {
    setWaitingApi(true)
    const code = getCheckoutCode()
    setCheckoutInformationTitle(code)

    const paymentMethods: UnifiedInvoicePaymentMethod[] = [
      paymentMethod === SimulationPaymentMethod.CREDIT_CARD
        ? UnifiedInvoicePaymentMethod.POS_CREDIT_CARD
        : UnifiedInvoicePaymentMethod.POS_DEBIT_CARD,
    ]

    const checkoutType =
      checkoutProvider === CheckoutProvider.STONE_PAGARME
        ? CheckoutType.PAGARME_CHECKOUT
        : CheckoutType.STONE_CHECKOUT

    const body: UnifiedInvoiceRequestBody = {
      item_urns: urns,
      payment_methods: paymentMethods,
      pos: {
        id: posId,
        information_title: checkoutInformationTitle,
        installments_count: numberOfInstallments,
        type: checkoutType,
      },
    }

    invoiceMutate(body, {
      onSuccess: data => {
        setWaitingApi(false)

        const formattedAsCheckout = {
          id: data.id,
          amount: data.amount,
          external_transaction_id: data.checkout.external_transaction_id,
          installments_count: data.checkout.installments_count,
          payment_methods: [data.checkout.payment_method],
          school_id: '',
          status: data.checkout.status,
          type: data.checkout.type,
          created_at: data.checkout.created_at,
          updated_at: data.checkout.updated_at,
        } as Checkout

        setCheckoutObject(formattedAsCheckout)
        setIsCheckoutFeedbackDisabled(false)
        initCheckoutStatusPolling(
          api.checkout.getCheckout,
          formattedAsCheckout,
          setCheckoutObject,
          setIsProcessingCheckoutPayment,
          setCheckoutPollingInterval,
          setShowErrorDialog
        )
      },
      onError: () => {
        setWaitingApi(false)
        setShowErrorDialog(true)
      },
    })
  }

  const onCancelCheckout = () => {
    setWaitingApi(true)

    api.checkout
      .cancelCheckout(checkoutObject?.id ?? '')
      .then(() => {
        sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_CANCEL, {
          name: 'Cancelar transação',
        })
      })
      .catch(() => {
        clearInterval(Number(checkoutPollingInterval))
        setShowErrorDialog(true)
      })
      .finally(() => {
        setWaitingApi(false)
      })
  }

  const onFinishCheckout = () => {
    if (checkoutObject?.status === 'finished') {
      window.location.reload()
    }
    if (checkoutObject?.status === 'aborted') {
      sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_CANCEL, {
        name: 'Tentar transação novamente',
      })
    }
    setIsProcessingCheckoutPayment(false)
  }

  useEffect(() => {
    if (checkoutObject?.status === CheckoutStatus.FINISHED && isProcessingCheckoutPayment) {
      clearInterval(Number(checkoutPollingInterval))
      setIsProcessingCheckoutPayment(false)
      onSuccessfullyConcluded(checkoutObject)
      return
    }

    if (!isProcessingCheckoutPayment || checkoutObject?.status === CheckoutStatus.ABORTED) {
      clearInterval(Number(checkoutPollingInterval))
    }
  }, [checkoutObject?.status, isProcessingCheckoutPayment])

  useEffect(() => {
    return () => {
      clearInterval(Number(checkoutPollingInterval))
    }
  }, [])

  const closeCheckoutFeedback = () => {
    setIsProcessingCheckoutPayment(false)
    setIsCheckoutFeedbackDisabled(true)
  }

  const closeCheckoutErrorDialog = () => {
    setShowErrorDialog(false)
    closeCheckoutFeedback()
  }

  return {
    guardianName: guardian.name,
    checkoutObject,
    checkoutInformationTitle,
    showErrorDialog,
    isProcessingCheckoutPayment,
    isCheckoutFeedbackDisabled,
    checkoutPollingInterval,
    startCheckout,
    startCheckoutV2,
    waitingApi,
    onCancelCheckout,
    onFinishCheckout,
    closeCheckoutFeedback,
    closeCheckoutErrorDialog,
  }
}

export default useFlowCheckout
