import { LEGACY_CYCLE_YEARS } from '../constants/enrollment'
import { useGetSchoolEnrollmentCycles } from './queries/useGetSchoolEnrollmentCycles'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const useSchoolEnrollmentCycles = () => {
  const { school } = useSelectedSchool()
  const { data: response } = useGetSchoolEnrollmentCycles(school.id ?? undefined, {
    enabled: !!school.id,
  })
  const schoolEnrollmentCycles = response?.data

  const activeEnrollmentCycleYear = schoolEnrollmentCycles?.find(
    enrollmentCycle => enrollmentCycle.enabled === true
  )?.reference_year

  // Returns the list of all enrollment cycle years signed up by the school including the legacy years
  // Since legacy years are the years that are hardcoded in the frontend, we need to validate if
  // it is expected to show them in the list if a new school is enrolled after 2023.
  const enrollmentCycleYears = [
    ...LEGACY_CYCLE_YEARS,
    ...(schoolEnrollmentCycles?.map(enrollmentCycle => enrollmentCycle.reference_year).reverse() ??
      []),
  ]

  // Returns the list of all enrollment cycle years that are enabled.
  // The array must contain 0, 1 or 2 elements.
  // If it contains 0 elements, probably the school has canceled the contract.
  const enabledEnrollmentCyclesYears = schoolEnrollmentCycles
    ?.filter(enrollmentCycle => enrollmentCycle.enabled)
    .map(enrollmentCycle => enrollmentCycle.reference_year)

  return { activeEnrollmentCycleYear, enrollmentCycleYears, enabledEnrollmentCyclesYears }
}
