import { ENROLLMENT_PAGE_BASE_PATH } from '@/modules/enrollment/constants/enrollment'
import { useNavigation } from 'src/escolas/hooks'
import { StyledListItem } from './ContractYearPopover'
import { Chip } from '@olaisaac/design-system'
import styled from 'styled-components'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

interface EnrollmentPageItemProps {
  activeEnrollmentCycleYear: number
}

export const EnrollmentPageItem = ({ activeEnrollmentCycleYear }: EnrollmentPageItemProps) => {
  const { getNavigationUrl } = useNavigation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  return (
    <StyledListItem
      button
      component="a"
      href={getNavigationUrl({ path: `/${ENROLLMENT_PAGE_BASE_PATH}` })}
      onClick={() =>
        isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.CONTRACTS_PAGE,
          name: EnrollmentEventDispatcherEvents.CLICKED,
          action: 'click',
          customProperties: {
            $Button_name: `MATRÍCULAS_${activeEnrollmentCycleYear}_DROWDOWN`,
            $Dropdown_name: 'PERIODO_LETIVO',
          },
        })
      }
    >
      <Wrapper>
        {`Matrículas ${activeEnrollmentCycleYear}`}
        <Chip label="Novo" variation="blue" />
      </Wrapper>
    </StyledListItem>
  )
}
