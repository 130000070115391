import { useMemo } from 'react'

import type { CampaignStudent } from '@/modules/enrollment/services/enrollmentService/types'
import type { ColumnProps, TableRowProps } from '../types'

import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import { columnNames } from '../types'

export const useTableData = (campaignStudents: CampaignStudent[], hasPaymentPlan: boolean) => {
  const isCampaignAddTuitionEnabled = useUnleashFlag(
    UnleashFlags.MAT_474_ENABLE_CAMPAIGN_ADD_INSTALLMENTS
  )

  const rows = useMemo((): TableRowProps[] => {
    return campaignStudents.map(student => {
      return {
        columns: {
          [columnNames.STUDENT_NAME]: student.student_name,
          [columnNames.GUARDIAN_NAME]: student.guardian_name,
          [columnNames.HAS_OPENED_EMAIL]: student.has_open_email ? '1' : '0',
          [columnNames.IS_ENGAGED]: student.is_engaged ? '1' : '0',
          [columnNames.PAYMENT_CONFIRMED]: student.paid_enrollment ? '1' : '0',
          [columnNames.HAS_TUITION]: student.has_tuition ? '1' : '0',
        },
        key: student.student_id,
        reenrollmentStatus: student.enrollment_status,
        guardianEmail: student.guardian_email,
        enrollmentID: student.enrollment_id,
        isCampaignProposalCanceled: student.is_campaign_proposal_canceled,
      }
    })
  }, [campaignStudents])

  const paymentConfirmedColumn = {
    key: columnNames.PAYMENT_CONFIRMED,
    label: 'Pagamento confirmado?',
    sortable: false,
    minWidth: '136px',
  }
  const tuitionAddedColumn = {
    key: columnNames.HAS_TUITION,
    label: 'Possui mensalidades?',
    sortable: false,
    minWidth: '136px',
  }

  const columnHeaders: Array<ColumnProps> = [
    { key: columnNames.STUDENT_NAME, label: 'Aluno', sortable: false, minWidth: '190px' },
    { key: columnNames.GUARDIAN_NAME, label: 'Responsável', sortable: false, minWidth: '190px' },
    {
      key: columnNames.HAS_OPENED_EMAIL,
      label: 'Abriu email?',
      sortable: false,
      minWidth: '136px',
    },
    {
      key: columnNames.IS_ENGAGED,
      label: 'Demonstrou interesse?',
      sortable: false,
      minWidth: '136px',
    },
    ...(hasPaymentPlan ? [paymentConfirmedColumn] : []),
    ...(isCampaignAddTuitionEnabled ? [tuitionAddedColumn] : []),
  ]

  return {
    rows,
    columnHeaders,
  }
}
