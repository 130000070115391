import type { AxiosInstance } from 'axios'

import type {
  GetSchoolEnrollmentCyclesResponse,
  GetStudentEnrollmentCycleResponse,
  IrregularStudentsRequest,
  IrregularStudentsResponse,
  IrregularityBigNumbersRequest,
  IrregularityBigNumbersResponse,
  StudentEnrollmentCycleRequest,
  CampaignNameCheckRequest,
  GetCampaignNameCheckResponse,
} from './types'

export const schoolsEnrollmentCycleService = (fetcher: AxiosInstance) => {
  return {
    /**
     *
     * Function responsible for get all enrollment cycles for a school
     *
     * @param schoolId School ID
     *
     * @returns SchoolEnrollmentCycles
     */
    getSchoolEnrollmentCycles: async (
      schoolId: uuid
    ): Promise<GetSchoolEnrollmentCyclesResponse> => {
      const { data } = await fetcher.get<GetSchoolEnrollmentCyclesResponse>(
        `school_enrollment_cycle/${schoolId}`
      )
      return data
    },
    /**
     *
     * Function responsible for get all enabled enrollment cycles for a student
     *
     * @param request schoolId and studentId
     *
     * @returns StudentEnrollmentCycle
     */
    fetchStudentEnrollmentCycle: async (
      request: StudentEnrollmentCycleRequest
    ): Promise<GetStudentEnrollmentCycleResponse> => {
      return (
        await fetcher.get<GetStudentEnrollmentCycleResponse>(
          `student/enrollment/${request.studentId}/enabled-cycles`,
          {
            params: {
              school_id: request.schoolId,
            },
          }
        )
      ).data
    },
    /**
     *
     * Function responsible to check if the campaign name is valid
     *
     * @param CampaignNameCheckRequest
     *
     * @returns GetCampaignNameCheckResponse
     */
    fetchSchoolNameAvailability: async (
      request: CampaignNameCheckRequest
    ): Promise<GetCampaignNameCheckResponse> => {
      return (
        await fetcher.get<GetCampaignNameCheckResponse>(
          `campaign/${request.schoolId}/check-name-availability`,
          {
            params: {
              school_enrollment_cycle_id: request.schoolEnrollmentCycleId,
              name: request.name,
              school_id: request.schoolId,
              product_id: request.productId,
            },
          }
        )
      ).data
    },
  }
}

export const IrregularEnrollmentService = (fetcher: AxiosInstance) => {
  return {
    /**
     *
     * Function responsible for get paginated irregular students data
     *
     * @param IrregularStudentsRequest
     *
     * @returns IrregularStudentsResponse[]
     */
    fetchIrregularStudents: async (
      request: IrregularStudentsRequest
    ): Promise<IrregularStudentsResponse> => {
      return (
        await fetcher.get<IrregularStudentsResponse>(`/enrollment-request/students`, {
          params: {
            school_id: request.schoolId,
            reference_year: request.referenceYear,
            status: request.status,
            student_name: request.studentName,
            ...request.pagination,
          },
        })
      ).data
    },

    /**
     *
     * Function responsible for getting irregularity page's big numbers
     *
     * @param IrregularityBigNumbersRequest
     *
     * @returns IrregularityBigNumbers
     */
    fetchIrregularityBigNumbers: async (
      request: IrregularityBigNumbersRequest
    ): Promise<IrregularityBigNumbersResponse> => {
      return (
        await fetcher.get<IrregularityBigNumbersResponse>(`/enrollment-request/status-summary`, {
          params: {
            school_id: request.schoolId,
            reference_year: request.referenceYear,
          },
        })
      ).data
    },

    /**
     *
     * Function responsible for canceling open irregularities of an enrollment request
     *
     * @param enrollmentRequestId
     *
     * @returns empty response and 204 status code for success
     */
    cancelOpenIrregularities: async (enrollmentRequestId: uuid) => {
      return await fetcher.put(
        `/enrollment-request/${enrollmentRequestId}/cancel-open-irregularities`
      )
    },
  }
}
