import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.gravity.spacing[16]};
  gap: ${({ theme }) => theme.gravity.spacing[16]};
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.gravity.spacing[10]};

  .title {
    margin-bottom: ${({ theme }) => theme.gravity.spacing[4]};
  }
`
