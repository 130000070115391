import { useQuery } from '@/shared/hooks/useQuery'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { IrregularStudentsFilterQueryParams, IrregularStudentsFilterType } from './types'
import { IrregularStudentStatusFilter } from '../../types'

export const useIrregularStudentsFilter = (
  initialStatusValue: IrregularStudentStatusFilter[] = [],
  initialReferenceYearValue = ''
) => {
  const { query, setMultipleQueryParams } = useQuery()

  const getFiltersFromUrl = (
    defaultStatusValues: IrregularStudentStatusFilter[] = [],
    defaultReferenceYearValue = ''
  ): IrregularStudentsFilterType => {
    return {
      irregularStatus:
        (query.get('irregular_status')?.split(',') as IrregularStudentStatusFilter[]) ??
        defaultStatusValues,
      referenceYear: query.get('reference_year') ?? defaultReferenceYearValue,
    }
  }

  const [
    irregularStudentsFilter,
    setIrregularStudentsFilter,
  ] = useState<IrregularStudentsFilterType>(() =>
    getFiltersFromUrl(initialStatusValue, initialReferenceYearValue)
  )

  useEffect(() => {
    const checkIfHasChanges = (
      urlFilter: IrregularStudentsFilterType,
      stateFilter: IrregularStudentsFilterType
    ) => {
      let hasChanges = false

      Object.keys(stateFilter).every(key => {
        if (
          stateFilter[key as keyof IrregularStudentsFilterType].toString() !==
          urlFilter[key as keyof IrregularStudentsFilterType].toString()
        ) {
          hasChanges = true

          return false
        }

        return true
      })

      return hasChanges
    }

    const urlIrregularStudentsFilter = getFiltersFromUrl()

    const hasChangesOnFilter = checkIfHasChanges(
      urlIrregularStudentsFilter,
      irregularStudentsFilter
    )

    hasChangesOnFilter && setIrregularStudentsFilter(urlIrregularStudentsFilter)
  }, [query])

  const updateFilter = useCallback(
    (
      updatedFilter: IrregularStudentsFilterType = {
        irregularStatus: [],
        referenceYear: '',
      }
    ) => {
      const data: Array<{
        name: IrregularStudentsFilterQueryParams
        value: string
      }> = [
        {
          name: 'irregular_status',
          value: updatedFilter.irregularStatus.join(','),
        },
        {
          name: 'reference_year',
          value: updatedFilter.referenceYear,
        },
      ]

      setMultipleQueryParams(data)
    },
    [setMultipleQueryParams]
  )

  useEffect(() => {
    updateFilter(irregularStudentsFilter)
  }, [])

  return useMemo(
    () => ({
      irregularStudentsFilter,
      updateFilter,
    }),
    [irregularStudentsFilter, updateFilter]
  )
}
