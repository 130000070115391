import {
  Contract,
  ContractResponse,
  ContractStatus,
  NextYearContractResponse,
  Product,
  ProductResponse,
} from '../interfaces'

export const productToProductResponse = (p: Product): ProductResponse => {
  if (!p) {
    return null
  }
  return { id: p.id, name: p.name }
}

export const contractToNextYearContractResponse = (c: Contract): NextYearContractResponse => {
  const nextContracts = c?.next_year_contracts?.filter(c => c.status === ContractStatus.OPEN)
  const firstOpenContract = nextContracts[0]
  if (!firstOpenContract) {
    return null
  }

  const result: NextYearContractResponse = {
    id: firstOpenContract.id,
    sign_payment_status: firstOpenContract.sign_payment_status,
    status: firstOpenContract.status,
  }
  return result
}

export const contractToContractResponse = (c: Contract): ContractResponse => {
  const result: ContractResponse = {
    debt_status: c.debt_status,
    end_month: c.end_month,
    guardian: c.guardian,
    guardian_id: c.guardian_id,
    id: c.id,
    is_reenrollable: c.is_reenrollable,
    next_year_contract: contractToNextYearContractResponse(c),
    product: productToProductResponse(c.product),
    product_id: c.product_id,
    sign_payment_status: c.sign_payment_status,
    start_month: c.start_month,
    status: c.status,
    student: c.student,
    student_id: c.student_id,
    reference_year: c.reference_year,
  }
  return result
}

export const contractListToContractResponse = (contracts: Contract[]): ContractResponse[] => {
  return contracts.map(c => contractToContractResponse(c))
}
