import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo } from 'react'

import { PersistedStatePrefix, usePersistState } from '@/shared/hooks/usePersistState'

type BulkInstallmentUnavailability = Record<uuid, Date>

export const useBulkInstallmentAvailability = (
  campaignId: uuid,
  onUnavailabilityExpires?: () => void
) => {
  const [unavailability, setUnavailability] = usePersistState<BulkInstallmentUnavailability>(
    `${PersistedStatePrefix.Enrollment}:bulk-installment-unavailability`,
    {},
    {
      storageStrategy: 'localStorage',
    }
  )

  const isAvailable = useMemo(() => {
    if (!unavailability[campaignId]) return true

    const availableDate = dayjs(unavailability[campaignId])
    return availableDate.isBefore(dayjs())
  }, [unavailability, campaignId])

  const secondsUntilUnavailabilityExpires = isAvailable
    ? 0
    : dayjs(unavailability[campaignId]).diff(dayjs(), 'seconds')

  const addTempUnavailability = (totalAffectedEnrollments: number) => {
    const unavailableTimeInSeconds = Math.max(totalAffectedEnrollments, 5)
    const expiryUnavailabilityDate = dayjs().add(unavailableTimeInSeconds, 'seconds').toDate()
    setUnavailability({
      ...unavailability,
      [campaignId]: expiryUnavailabilityDate,
    })
  }

  const removeTempUnavailability = useCallback(() => {
    setUnavailability(prev => {
      const newValue = { ...prev }
      delete newValue[campaignId]
      return newValue
    })
  }, [campaignId])

  useEffect(() => {
    if (isAvailable || !unavailability[campaignId]) return

    const clearUnavailability = () => {
      removeTempUnavailability()
      if (onUnavailabilityExpires) {
        onUnavailabilityExpires()
      }
    }
    const timeInMS = secondsUntilUnavailabilityExpires * 1000
    const timeoutId = setTimeout(clearUnavailability, timeInMS)
    return () => clearTimeout(timeoutId)
  }, [
    isAvailable,
    campaignId,
    unavailability,
    secondsUntilUnavailabilityExpires,
    removeTempUnavailability,
  ])

  return {
    isAvailable,
    addTempUnavailability,
  }
}
