import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { DrawerState } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/constants'
import DrawerContent from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/DrawerContent'
import { useContract } from 'src/escolas/hooks'
import { ActionDrawerHeader, Drawer, Typography } from '@olaisaac/design-system'
import { propEq } from 'ramda'
import { renderStatus } from 'src/escolas/components/contract/ContractDetails'
import { Installment, Receivable, ReceivableStatuses } from 'src/shared/interfaces'
import { Box } from '@material-ui/core'
import { date2PTFormat } from 'src/shared/utils'
import dayjs from 'dayjs'

export type InstallmentDrawerProps = {
  callbackCheckout?: Dispatch<SetStateAction<boolean>>
  initialDrawerState?: DrawerState
  isBrokenPromise?: boolean
  isCheckout?: boolean
  isOpen: boolean
  onClose: () => void
  onSuccess?: () => void
  orderReference: string
  removeIsaacOption?: boolean
  resetManualLiquidationForm?: boolean
  selectedInstallmentHasError: boolean
  selectedReceivableId: uuid
  setIsFailureFeedbackOpen?: (value: boolean) => void
  setSelectedReceivableId: Dispatch<SetStateAction<uuid>>
  setShowInvoicesNotGeneratedDialog: (value: boolean) => void
  urn?: string
}

const InstallmentDrawer: FC<InstallmentDrawerProps> = ({
  callbackCheckout,
  initialDrawerState = DrawerState.DEFAULT,
  orderReference,
  isOpen,
  onClose,
  onSuccess,
  removeIsaacOption,
  isCheckout,
  selectedReceivableId,
  setSelectedReceivableId,
  resetManualLiquidationForm,
  setIsFailureFeedbackOpen,
  selectedInstallmentHasError,
  setShowInvoicesNotGeneratedDialog,
  urn,
  isBrokenPromise,
}) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(initialDrawerState)

  const goBackToDefaultContent = () => setDrawerState(initialDrawerState)
  const goBackToManualLiquidationContent = () => setDrawerState(DrawerState.MANUAL_LIQUIDATION)
  const showGoBack = drawerState !== initialDrawerState

  const { contract } = useContract()

  const installment = contract?.installments.find(installment =>
    installment?.receivables.some(propEq('id', selectedReceivableId))
  )
  const receivables = installment?.receivables

  const [receivable, setReceivable] = useState<Receivable>(null)

  useEffect(() => {
    const receivable = receivables?.find(propEq('id', selectedReceivableId))
    if (receivable) setReceivable(receivable)
  }, [selectedReceivableId])

  const isPaid = receivable?.status === ReceivableStatuses.PAID
  const isReceivableOpen = receivable?.status === ReceivableStatuses.OPEN
  const { available_payment_methods } = receivable?.invoice || {}

  const paymentMethodMap = {
    bank_slip: 'Boleto',
    pix: 'Pix',
    credit_card: 'Cartão de crédito',
  }
  let info = ''

  if (isReceivableOpen && available_payment_methods?.length) {
    const availablePayments = available_payment_methods.includes('all')
      ? Object.values(paymentMethodMap)
      : available_payment_methods.map(method => paymentMethodMap[method])

    const hasMutiplePayments = availablePayments.length > 1

    const availablePaymentsMessage = hasMutiplePayments
      ? `${availablePayments.slice(0, -1).join(', ')} e ${availablePayments.slice(-1)}`
      : availablePayments[0]
    info = `Forma de pagamento da parcela: ${availablePaymentsMessage}`
  }

  const getReceivableFromReceivableId = (selectedId: string) => {
    const receivable = installment?.receivables?.find(r => r?.id === selectedId)

    return receivable
  }

  const getReceivableFromInstallmentId = (installmentId: string, installments: Installment[]) => {
    const installment = installments?.find(i => i?.id === installmentId)
    // Check if the originals where agglutinated
    const originalReceivables = installment?.receivables.find(receivable => {
      if (!receivable.original_receivables) return false
      return receivable?.original_receivables.some(
        r => r?.status === ReceivableStatuses.AGGLUTINATED || r?.status === ReceivableStatuses.PAID
      )
    })
    // Return the root node of the agglutination tree
    return originalReceivables
  }

  const getInstallmentIdFromSelectedId = (selectedId: string) => {
    return selectedId.split('installment::')[1]
  }
  const getIncludesInstallment = (selectedId: string) => {
    return selectedId?.includes('installment::')
  }
  const getOriginalReceivables = (selectedId: string, installments: Installment[]) => {
    const isSplitReceivable = getIncludesInstallment(selectedId)
    if (isSplitReceivable) {
      const installmentId = getInstallmentIdFromSelectedId(selectedId)
      const agglutinationRoot = getReceivableFromInstallmentId(installmentId, installments)
      const originalReceivables = agglutinationRoot?.original_receivables
      return { originalReceivables, receivable: agglutinationRoot, isSplit: true }
    }

    const receivable = getReceivableFromReceivableId(selectedId)
    const originalReceivables = receivable?.original_receivables

    return { originalReceivables, receivable, isSplit: false }
  }
  let subtitleManualLiquidation = ''
  if (removeIsaacOption) {
    subtitleManualLiquidation = 'na conta escola'
  }

  const { isSplit } = getOriginalReceivables(selectedReceivableId, contract?.installments)
  const drawerHeader = {
    [DrawerState.DEFAULT]: (
      <ActionDrawerHeader
        title={`Parcela ${orderReference}`}
        subtitle={
          !isSplit && isReceivableOpen
            ? `Vencimento em ${date2PTFormat(dayjs(receivable?.due_date).toISOString())}`
            : undefined
        }
        onClose={onClose}
        onBack={showGoBack ? goBackToDefaultContent : undefined}
        extraContent={
          !isSplit && isReceivableOpen ? (
            info && (
              <Typography variation="caption" color="secondary" withoutMargin>
                {info}
              </Typography>
            )
          ) : (
            <Box display="flex" flexWrap="no-wrap" alignItems="center">
              {renderStatus(receivable?.status)}
            </Box>
          )
        }
      />
    ),
    [DrawerState.MANUAL_LIQUIDATION]: (
      <ActionDrawerHeader
        title={`Parcela ${orderReference}`}
        subtitle={`${isPaid ? 'Editar' : 'Registrar'} recebimento ${subtitleManualLiquidation}`}
        onClose={onClose}
        onBack={showGoBack ? goBackToDefaultContent : undefined}
      />
    ),
    [DrawerState.CONFIRM_MANUAL_LIQUIDATION]: (
      <ActionDrawerHeader
        title="Registrar recebimento"
        onClose={onClose}
        onBack={showGoBack ? goBackToManualLiquidationContent : undefined}
      />
    ),
    [DrawerState.RENEGOTIATION]: (
      <ActionDrawerHeader
        title={`Parcela ${orderReference}`}
        subtitle="Renegociar"
        onClose={onClose}
        onBack={showGoBack ? goBackToDefaultContent : undefined}
      />
    ),
    [DrawerState.ADD_DISCOUNTS]: (
      <ActionDrawerHeader
        title={`Parcela ${orderReference}`}
        subtitle="Editar descontos"
        onClose={onClose}
        onBack={showGoBack ? goBackToDefaultContent : undefined}
      />
    ),
    [DrawerState.EDIT_ENROLLMENT_DUEDATE]: (
      <ActionDrawerHeader
        title="Editar vencimento"
        onClose={onClose}
        onBack={showGoBack ? goBackToDefaultContent : undefined}
      />
    ),
  }

  return (
    <Drawer open={isOpen}>
      {drawerHeader[drawerState]}

      <DrawerContent
        onClose={onClose}
        defaultDrawerState={initialDrawerState}
        drawerState={drawerState}
        selectedReceivableId={selectedReceivableId}
        setDrawerState={setDrawerState}
        setSelectedReceivableId={setSelectedReceivableId}
        removeIsaacOption={removeIsaacOption}
        isCheckout={isCheckout}
        callbackCheckout={callbackCheckout}
        resetManualLiquidationForm={resetManualLiquidationForm}
        setIsFailureFeedbackOpen={setIsFailureFeedbackOpen}
        onSuccess={onSuccess}
        selectedInstallmentHasError={selectedInstallmentHasError}
        setShowInvoicesNotGeneratedDialog={setShowInvoicesNotGeneratedDialog}
        urn={urn}
        isBrokenPromise={isBrokenPromise}
      />
    </Drawer>
  )
}

export default InstallmentDrawer
