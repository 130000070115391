import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { useIsCampaignEnabled } from './useIsCampaignEnabled'

export const useEnrollmentCampaignRedirect = () => {
  const isCampaignEnabled = useIsCampaignEnabled()

  const history = useHistory()

  const { schoolSlug } = useSelectedSchool()

  const redirect = () => {
    if (!isCampaignEnabled) {
      history.replace(`/${schoolSlug}/matriculas`)
    }
  }

  useEffect(() => {
    redirect()
  }, [])
}
