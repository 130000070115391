import styled from 'styled-components'
import { Accordion } from '@gravity/accordion'

export const AccordionItem = styled(Accordion.Item)`
  margin-bottom: 16px;
`

export const AccordionContainer = styled.div`
  margin-top: 32px;
`

export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;
`

export const AccordionTitleContainer = styled.div`
  display: flex;
  padding-bottom: 8px;
  align-items: center;
`
