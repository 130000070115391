import { useEffect, useRef, useState, ReactElement } from 'react'
import { Button, ButtonProps } from '@olaisaac/design-system'

export type TimeoutButtonProps = ButtonProps & {
  buttonLabel: string
  disabled?: boolean
  startIcon?: ReactElement
  timeout: number
}

const INTERVAL = 1000
const DECREMENT = 1000

const TimeoutButton = ({
  disabled,
  timeout,
  onClick,
  buttonLabel,
  startIcon,
  ...props
}: TimeoutButtonProps) => {
  const [timerDiff, setTimerDiff] = useState<number>(0)
  const intervalId = useRef<NodeJS.Timeout>()

  const shouldWait = timerDiff > 0

  const calcTimerDiff = () => {
    setTimerDiff(timerDiff => timerDiff - DECREMENT)
  }

  const timestampToMMSS = (timestamp: number) => {
    const date = new Date(timestamp)
    const minutes = date.getMinutes()
    const seconds = date.getSeconds().toString().padStart(2, '0')

    return `${minutes}:${seconds}`
  }

  useEffect(() => {
    setTimerDiff(timeout - DECREMENT)

    intervalId.current = setInterval(calcTimerDiff, INTERVAL)

    return () => {
      clearInterval(intervalId.current)
    }
  }, [timeout])

  if (!shouldWait) {
    clearInterval(intervalId.current)
  }

  return (
    <Button
      startIcon={!shouldWait && startIcon}
      disabled={disabled || shouldWait}
      onClick={onClick}
      {...props}
    >
      {shouldWait ? `${timestampToMMSS(timerDiff)}` : `${buttonLabel}`}
    </Button>
  )
}

export default TimeoutButton
