import StatusBadge from '@/escolas/components/StatusBadge/StatusBadge'
import { ContractStatus as ContractStatusType } from '@/shared/interfaces'
import { Typography } from '@olaisaac/design-system'
import { contractStatusDict } from './constants'
import { Box } from '@material-ui/core'

export const ContractStatus: React.FC<{ status: ContractStatusType }> = ({ status }) => {
  return (
    <>
      <StatusBadge color={contractStatusDict[status]?.color} />
      <Box ml={1}>
        <Typography variation="bodySmall" color="secondary" data-testid="contractStatusLabel">
          {contractStatusDict[status]?.text}
        </Typography>
      </Box>
    </>
  )
}
