import { CardInfo } from './components'
import { StyledContainer } from './styles'
import { GuardianDetailsCardProps } from './types'
import { StudentsCarousel } from '../StudentsCarousel'

export const GuardianDetailsCard = ({
  guardian,
  isLoading,
  openEditRegistrationDrawer,
}: GuardianDetailsCardProps) => {
  return (
    <StyledContainer>
      <CardInfo
        guardian={{
          cpf: guardian?.tax_id,
          email: guardian?.email,
          guardianName: guardian?.name,
          phone: guardian?.phone_number,
          debtStatus: guardian?.debt_status,
        }}
        isLoading={isLoading}
        openEditRegistrationDrawer={openEditRegistrationDrawer}
      />
      <StudentsCarousel />
    </StyledContainer>
  )
}
