import { SetStateAction, useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControlLabel,
  Radio,
  TextField,
} from '@olaisaac/design-system'
import { Button } from '@gravity/button'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import { v4 as uuidv4 } from 'uuid'

import { Installment } from '@/modules/contract/services/types'
import { FormControl, RadioGroup } from '@material-ui/core'
import { ContractCancellationReason } from '@/shared/interfaces/contract'
import { contractAPI } from '@/modules/contract/services'
import { useCancelContractMutation } from '../../hooks/queries'

type CancelContractDialogProps = {
  api: contractAPI
  contractId: uuid
  onClose: () => void
  onError: (error: unknown) => void
  onSuccess: () => void
  schoolId: uuid
  selectedInstallments: Installment[]
}

type ContractCancellationReasons = {
  [key: string]: string
}

const ContractCancellationReasonMapper: ContractCancellationReasons = {
  [ContractCancellationReason.WRONG_DATE]: 'Alteração da data',
  [ContractCancellationReason.DUPLICATED_CONTRACT]: 'Contrato duplicado',
  [ContractCancellationReason.WRONG_GUARDIAN_INFO]: 'Alteração dos dados do responsável',
  [ContractCancellationReason.STUDENT_LEFT]: 'Aluno saiu da escola',
  [ContractCancellationReason.WRONG_AMOUNT]: 'Alteração de valor',
  [ContractCancellationReason.OTHER]: 'Outro (digite o motivo abaixo)',
}
export const CancelContractDialog = ({
  api,
  contractId,
  onClose,
  onError,
  onSuccess,
  schoolId,
  selectedInstallments,
}: CancelContractDialogProps) => {
  const [radioOption, setRadioOption] = useState('')
  const [otherReasonDescription, setOtherReasonDescription] = useState('')

  const { isLoading, mutateAsync: executeCancelContract } = useCancelContractMutation(api)

  const handleRadioChange = (value: SetStateAction<string>) => {
    setRadioOption(value)
    setOtherReasonDescription('')
  }

  const otherReasonDisabled = radioOption !== ContractCancellationReason.OTHER

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box>Cancelar contrato</Box>
      </DialogTitle>
      <DialogContent>
        <Typography variation="subtitleDesktopMedium" style={{ marginBottom: '24px' }}>
          Qual é o motivo do cancelamento?
        </Typography>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <RadioGroup
            aria-label="Cancellation reasons options"
            name="radio-buttons-group"
            value={radioOption}
            onChange={e => handleRadioChange(e.target.value)}
          >
            {Object.entries(ContractCancellationReasonMapper).map(([reasonType, reason]) => (
              <FormControlLabel
                key={reasonType}
                label={reason}
                value={reasonType}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
          <TextField
            label="Motivo"
            disabled={otherReasonDisabled}
            onChange={e => setOtherReasonDescription(e.target.value)}
            value={otherReasonDescription}
            style={{ marginBottom: '24px' }}
          />
        </FormControl>
        <Typography>
          Essa ação é irreversível e pode alterar o repasse. Ao cancelar o contrato, ele ficará
          inativo e suas parcelas serão removidas dos próximos repasses.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="ghost" onClick={onClose}>
          Voltar
        </Button>
        <Button
          disabled={radioOption === '' || (!otherReasonDisabled && otherReasonDescription === '')}
          variant="solid"
          color="error"
          loading={isLoading}
          onClick={async () => {
            const correlationID = uuidv4()

            try {
              await executeCancelContract({
                schoolId,
                contractId,
                installments: selectedInstallments,
                description: otherReasonDescription,
                correlationID,
                reason: radioOption as ContractCancellationReason,
              })
              onSuccess()
            } catch (error) {
              onError(error)
            }

            onClose()
          }}
        >
          Continuar cancelamento
        </Button>
      </DialogActions>
    </Dialog>
  )
}
