import { Dialog } from '@material-ui/core'
import FormControlMUI from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from '@olaisaac/design-system'
import { Button } from '@gravity/button'
import { useState } from 'react'
import { Controller, FieldErrorsImpl, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { formatCentsToReal } from 'src/shared/utils'
import { Installment } from '@/modules/contract/services/types'
import {
  DiscountRule,
  DiscountRuleLabels,
  MINIMUM_AMOUNT_CENTS,
  MINIMUM_DISCOUNT_AMOUNT_CENTS,
  validateDiscountAmount,
  validateDiscountPercentage,
} from '../../utils'
import { SelectedInstallmentsText } from '../SelectedInstallmentsText'
import * as Styled from './styles'
import { Callout } from '@gravity/callout'
import { InstallmentStatuses } from '@/shared/interfaces'
import dayjs from 'dayjs'

const discountRuleOptions = [
  DiscountRule.PERPETUAL,
  DiscountRule.DUE_PAYMENT,
  DiscountRule.EARLY_PAYMENT,
]

const discountRuleToDefaultDaysBeforeDueDate = (rule: DiscountRule): number => {
  if (rule === DiscountRule.PERPETUAL) {
    return -1
  }

  return 0
}

const FormControl = (props: any) => <FormControlMUI variant="outlined" fullWidth {...props} />

enum Field {
  AMOUNT = 'amount',
  DAYS_BEFORE_DUE_DATE = 'days_before_due_date',
  DESCRIPTION = 'description',
  DISCOUNT_RULE = 'discountRule',
  PERCENTAGE = 'percentage',
}

export type AddDiscountForm = {
  [Field.AMOUNT]: cents
  [Field.DAYS_BEFORE_DUE_DATE]: number
  [Field.DESCRIPTION]: string
  [Field.DISCOUNT_RULE]: DiscountRule
  [Field.PERCENTAGE]: number
}

const REQUIRED_MESSAGES = {
  [Field.AMOUNT]: 'Por favor, informe o valor do desconto.',
  [Field.DAYS_BEFORE_DUE_DATE]: 'Por favor, informe a quantidade de dias.',
  [Field.DESCRIPTION]: 'Por favor, forneça um nome para o desconto.',
  [Field.PERCENTAGE]: 'Por favor, informe a porcentagem do desconto.',
}

const errorProps = (errors: Partial<FieldErrorsImpl<AddDiscountForm>>, field: Field) => {
  return {
    error: Boolean(errors[field]),
    helperText: errors[field] ? errors[field].message : '',
  }
}

export const AddDiscountDialog = ({
  onClose,
  onConfirm,
  selectedInstallments,
}: {
  onClose: () => void
  onConfirm?: (e: AddDiscountForm, percentMode: boolean) => void
  selectedInstallments: Installment[]
}) => {
  const [percentMode, setPercentMode] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    watch,
  } = useForm<AddDiscountForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      [Field.DESCRIPTION]: '',
      [Field.AMOUNT]: null,
      [Field.DAYS_BEFORE_DUE_DATE]: discountRuleToDefaultDaysBeforeDueDate(DiscountRule.PERPETUAL),
      [Field.DISCOUNT_RULE]: DiscountRule.PERPETUAL,
      [Field.PERCENTAGE]: null,
    },
  })
  watch(Field.DISCOUNT_RULE)

  const hasOverdueInstallments = selectedInstallments.some(
    installment => installment.status === InstallmentStatuses.OVERDUE
  )
  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(e => onConfirm(e, percentMode))}>
        <DialogTitle>Aplicar descontos</DialogTitle>
        <DialogContent>
          <Styled.TextContainer>
            <SelectedInstallmentsText count={selectedInstallments.length} />
          </Styled.TextContainer>
          <Controller
            name={Field.DESCRIPTION}
            control={control}
            rules={{ required: REQUIRED_MESSAGES[Field.DESCRIPTION] }}
            render={({ field: { onChange } }) => (
              <FormControl>
                <TextField
                  onChange={onChange}
                  id={Field.DESCRIPTION}
                  label="Nome do desconto"
                  {...errorProps(errors, Field.DESCRIPTION)}
                />
              </FormControl>
            )}
          />

          <Styled.SectionWrapper>
            <Typography variation="bodySmall">Preencher com base em:</Typography>
            <Styled.DiscountModeWrapper>
              <FormControl component="fieldset">
                <Styled.RadioGroupRow
                  aria-label="Discount options"
                  defaultValue="amount"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    style={{ marginBottom: '0px' }}
                    label="Valor do desconto"
                    value="amount"
                    onClick={() => setPercentMode(false)}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    style={{ marginBottom: '0px' }}
                    label="Porcentagem do desconto"
                    value="percentage"
                    onClick={() => setPercentMode(true)}
                    control={<Radio />}
                  />
                </Styled.RadioGroupRow>
              </FormControl>
            </Styled.DiscountModeWrapper>
          </Styled.SectionWrapper>

          {!percentMode && (
            <Controller
              name={Field.AMOUNT}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.AMOUNT],
                validate: {
                  minValue: v =>
                    v >= MINIMUM_DISCOUNT_AMOUNT_CENTS ||
                    `O valor do desconto deve ser superior a ${formatCentsToReal(
                      MINIMUM_DISCOUNT_AMOUNT_CENTS
                    )}`,
                  netInstallmentAmount: v =>
                    validateDiscountAmount(v, selectedInstallments) ||
                    `O valor ${
                      selectedInstallments.length > 1 ? 'de cada parcela' : 'da parcela'
                    } com os descontos aplicados deve ser superior a ${formatCentsToReal(
                      MINIMUM_AMOUNT_CENTS
                    )}`,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <NumberFormat
                    id={Field.AMOUNT}
                    onValueChange={({ floatValue }) => onChange(floatValue)}
                    customInput={TextField}
                    variant="outlined"
                    label="Valor"
                    format={formatCentsToReal}
                    value={value}
                    {...errorProps(errors, Field.AMOUNT)}
                  />
                </FormControl>
              )}
            />
          )}

          {percentMode && (
            <Controller
              name={Field.PERCENTAGE}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.PERCENTAGE],
                validate: {
                  minValue: v => v > 0 || 'O valor do desconto deve ser superior a zero.',
                  maxValue: v => v < 100 || 'O valor do desconto deve ser inferior a 100%.',
                  netInstallmentAmount: v =>
                    validateDiscountPercentage(v, selectedInstallments) ||
                    `O valor líquido da parcela deve ser superior a ${formatCentsToReal(
                      MINIMUM_AMOUNT_CENTS
                    )}`,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <NumberFormat
                    id={Field.PERCENTAGE}
                    suffix="%"
                    onValueChange={({ floatValue }) => onChange(floatValue)}
                    customInput={TextField}
                    variant="outlined"
                    label="Porcentagem"
                    decimalScale={2}
                    value={value}
                    {...errorProps(errors, Field.PERCENTAGE)}
                  />
                </FormControl>
              )}
            />
          )}

          <Controller
            name={Field.DISCOUNT_RULE}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Styled.SectionWrapper>
                <FormControl>
                  <InputLabel id="discountRule">Regra de desconto</InputLabel>
                  <Select
                    labelId={Field.DISCOUNT_RULE}
                    label="Regra de desconto"
                    value={value}
                    renderValue={value => (
                      <Typography>{DiscountRuleLabels[value as DiscountRule].name}</Typography>
                    )}
                    onChange={e => {
                      onChange(e)
                      const value = e.target.value as DiscountRule
                      const days = discountRuleToDefaultDaysBeforeDueDate(value)
                      setValue(Field.DAYS_BEFORE_DUE_DATE, days)
                    }}
                  >
                    {discountRuleOptions.map(rule => (
                      <MenuItem key={rule} value={rule}>
                        <Styled.OptionWrapper>
                          <Typography variation="subtitleDesktopMedium">
                            {DiscountRuleLabels[rule].name}
                          </Typography>
                          <Typography color="secondary">
                            {DiscountRuleLabels[rule].description}
                          </Typography>
                        </Styled.OptionWrapper>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Styled.SectionWrapper>
            )}
          />

          {getValues().discountRule === DiscountRule.EARLY_PAYMENT && (
            <Controller
              name={Field.DAYS_BEFORE_DUE_DATE}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.DAYS_BEFORE_DUE_DATE],
                validate: {
                  minValue: v => v >= 1 || 'O número de dias deve ser superior a 1',
                  maxValue: v => v <= 120 || 'O número de dias deve ser igual ou inferior a 120',
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <TextField
                    value={value}
                    onChange={onChange}
                    id={Field.DAYS_BEFORE_DUE_DATE}
                    type="number"
                    label="Dias antes do vencimento"
                    {...errorProps(errors, Field.DAYS_BEFORE_DUE_DATE)}
                  />
                </FormControl>
              )}
            />
          )}

          {hasOverdueInstallments && (
            <div style={{ marginTop: '24px' }}>
              <Callout
                text={`Ao aplicar desconto nesta parcela, a data do seu vencimento será recalculada para amanhã, ${dayjs()
                  .add(1, 'day')
                  .format('DD/MM/YYYY')}.`}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="ghost" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="solid" type="submit" disabled={!isValid}>
            Aplicar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
