import { Discount } from 'src/shared/interfaces'
import { SaveTuitionPayload, TuitionEditFormValues } from '../types'

export const createSaveTuitionPayload = (
  id: uuid,
  values: TuitionEditFormValues & { discounts_deleted: uuid[] }
): SaveTuitionPayload => {
  const {
    due_day,
    use_working_dates_tuition,
    start_month,
    discounts,
    monthly_amount,
    duration_months,
    pre_contract_buffer_id,
    discounts_deleted,
  } = values

  const formatedDiscounts: Discount[] = discounts.map(d => ({
    amount: d.amount,
    days_before_due_date: Number(d.days_before_due_date),
    description: d.description,
    id: d?.id,
  }))

  return {
    id,
    tuition: {
      pre_contract_buffer_id,
      due_day: Number(due_day),
      use_working_dates: use_working_dates_tuition === 'true',
      duration_months,
      start_month: start_month.toISOString(),
      monthly_amount: monthly_amount,
      discounts: formatedDiscounts,
      discounts_deleted: discounts_deleted,
    },
  }
}
