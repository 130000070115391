export enum Status {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  DISABLED = 'disabled',
  ENABLED = 'enabled',
}

export type StyledStepItemProps = {
  active: boolean
  status: Status
}

export type StepItemProps = {
  active: boolean
  label: string
  onClick: () => void
  status: Status
}
