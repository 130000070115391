import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { notificationsService } from '../services'
import type { NotificationRequestDTO, NotificationResponseDTO } from '../services/types'

const notificationsApi = notificationsService()

export const useNotificationsQuery = (
  queryParams: NotificationRequestDTO,
  options?: UseQueryOptions<NotificationResponseDTO>
) =>
  useQuery<NotificationResponseDTO>(
    ['useNotificationsQuery', JSON.stringify(queryParams)],
    () => notificationsApi.fetchNotifications(queryParams),
    {
      retry: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      ...options,
    }
  )
