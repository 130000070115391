import { DebtStatus } from '@/shared/interfaces/contract'
import { ListGuardiansFilterType } from './types'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

const handleSendEvent = (
  data: ListGuardiansFilterType,
  isInitialized: boolean,
  eventDispatcherClient
) => {
  const includesDue = data.debt_status.includes(DebtStatus.UP_TO_DATE)
  const includesOverdue = data.debt_status.includes(DebtStatus.OVERDUE)
  const includesWarning = data.debt_status.includes(DebtStatus.WARNING)

  if (!includesDue && includesOverdue && !includesWarning) {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.GUARDIANS,
        name: EventDispatcherEvents.GUARDIANS_WITH_FINANCIAL_DEBT_FILTER,
        action: 'click',
      })
  }
  if ((includesDue || includesWarning) && !includesOverdue) {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.GUARDIANS,
        name: EventDispatcherEvents.GUARDIANS_WITHOUT_FINANCIAL_DEBT_FILTER,
        action: 'click',
      })
  }
}

export default handleSendEvent
