import { UseFormReturn } from 'react-hook-form'
import { useState } from 'react'

import { Dialog } from '@gravity/dialog'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { Separator } from '@gravity/separator'

import { Content } from './styles'

interface Filter {
  component: React.ReactNode
  id: number
  title: string
}

interface Props {
  closeDialog: () => void
  filters: Array<Filter>
  form: UseFormReturn<any>
  onApplyFilters: (filters: any) => void
  onResetFilters?: () => void
  resetValues: any
  visible: boolean
}

export const FiltersDialog = ({
  closeDialog,
  onApplyFilters,
  onResetFilters,
  filters,
  form,
  visible,
  resetValues,
}: Props) => {
  const { control, handleSubmit, reset } = form
  const [tempFilters, setTempFilters] = useState(null)

  const handleDialogOpenChange = (open: boolean) => {
    if (!open && visible) closeDialog()
  }

  const handleApplyFilters = (filters: any) => {
    setTempFilters(null)
    onApplyFilters(filters)
  }

  const handleResetFilters = () => {
    setTempFilters(resetValues)

    if (onResetFilters) {
      onResetFilters()
    }

    reset(resetValues)
  }

  const handleClose = (open: boolean) => {
    if (tempFilters) {
      reset(tempFilters)
    } else {
      reset(control._options.defaultValues)
    }

    handleDialogOpenChange(open)
  }

  return (
    <Dialog
      title="Filtros da tabela"
      size={3}
      open={visible}
      onOpenChange={handleClose}
      actionButton={
        <Button data-testid="apply-filters" onClick={handleSubmit(handleApplyFilters)} size={2}>
          Aplicar filtros
        </Button>
      }
      cancelButton={
        <Button
          onClick={e => {
            e.preventDefault()
            handleResetFilters()
          }}
          size={2}
          variant="ghost"
          data-testid="remove-filters"
        >
          Remover filtros
        </Button>
      }
      content={
        <Content>
          {filters.map((filter, index) => (
            <div data-testid="filter" className="filter-group" key={filter.id}>
              <Text variant="subtitle-medium">{filter.title}</Text>
              <div>{filter.component}</div>
              {index < filters.length - 1 && (
                <Separator orientation="horizontal" color="neutral-2" />
              )}
            </div>
          ))}
        </Content>
      }
      backdrop
    />
  )
}
