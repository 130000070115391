import { ElementType } from 'react'
import { Text } from '@gravity/text'
import { Skeleton } from '@gravity/skeleton'

import type { InfoPlaceholderProps, InfoProps } from './types'

import { Box, Link } from './styles'

export const Info = (props: InfoProps) => {
  const { children, title, href = '' } = props

  const Container = (href ? Link : Box) as ElementType

  const containerProps = href
    ? {
        href,
        'aria-label': title,
        onClick: props.onClick,
      }
    : {}

  return (
    <Container variant="secondary" {...containerProps}>
      <Text variant="body-2-regular" className="title">
        {title}
      </Text>
      {children}
    </Container>
  )
}

export const InfoPlaceholder = (props: InfoPlaceholderProps) => {
  const { children } = props

  return (
    <Box className="skeleton">
      <Skeleton variant="neutral" fullWidth height={10} />
      {children}
    </Box>
  )
}
