import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'

import { Divider, Notification } from '@olaisaac/design-system'

import { formatter } from '@/modules/guardians/GuardianDetailsInstallmentsTab/utils/formatter'
import { getDiscountTitle } from '@/modules/guardians/InstallmentsDrawerContainer/utils'

import {
  InstallmentDrawerAccordion,
  InstallmentDrawerHeader,
  InfoRow,
  InstallmentDrawerActions,
  ContentWrapper,
  FooterContainer,
  InfoText,
  InfoWrapper,
  InstallmentDrawerActionWrapper,
} from '@/modules/guardians/InstallmentsDrawerContainer/components'
import { InstallmentDrawerProps } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import { InstallmentStatus } from '@/modules/guardians/GuardianDetails/constants'

import { useNegotiationDrawer } from './hooks/useNegotiationDrawer'
import { formatDate } from '@/shared/utils'

export const negotiation = ({ installment, onCloseDrawer }: InstallmentDrawerProps) => {
  const {
    headerTitle,
    headerStatusInfo,
    notification,
    footerMessage,
    hasCompositeAmount,
  } = useNegotiationDrawer({ installment })

  const {
    credit_card_fee,
    current_amount,
    current_fine,
    current_interest,
    discounts,
    is_from_credit_card_flow,
    negotiation,
    original_amount,
    product_name,
    status,
    student_name,
  } = installment

  const header = (
    <InstallmentDrawerHeader
      installment={installment}
      title={headerTitle}
      statusInfo={headerStatusInfo}
      onClose={onCloseDrawer}
    />
  )

  const content = (
    <>
      {notification && (
        <Notification variation={notification?.variation} description={notification?.description} />
      )}

      <ContentWrapper>
        <InfoWrapper>
          <InfoText variant="body-2-regular">{student_name}</InfoText>
          <Heading variant="heading-h4-medium">{product_name}</Heading>
        </InfoWrapper>
      </ContentWrapper>

      <InstallmentDrawerAccordion
        id={negotiation?.id}
        disabled={negotiation?.receivables?.length < 1}
        title="Detalhes da negociação"
      >
        <Text variant="body-1-medium">Parcelas negociadas</Text>

        {negotiation?.receivables?.map(receivable => (
          <InfoRow
            key={receivable?.id}
            label={formatDate(receivable?.due_date, 'MMM. YYYY')}
            value={formatter.amount(receivable?.current_amount)}
          />
        ))}
      </InstallmentDrawerAccordion>
      <ContentWrapper>
        {hasCompositeAmount && (
          <InfoRow label="Valor desta parcela" value={formatter.amount(original_amount)} />
        )}

        {discounts?.map((discount, key) =>
          ['EARLY_PAYMENT_DISCOUNT', 'DUE_PAYMENT'].includes(discount.type) ? (
            <InfoRow
              key={key}
              label={`${getDiscountTitle(discount.type)} Válido até ${formatter.full_date(
                discount.limit_date
              )}`}
              value={`- ${formatter.amount(discount.total_amount)}`}
            />
          ) : (
            <InfoRow
              key={key}
              label={getDiscountTitle(discount.type)}
              value={`- ${formatter.amount(discount.total_amount)}`}
            />
          )
        )}

        {[InstallmentStatus.OVERDUE, InstallmentStatus.CANCELED].includes(status) && (
          <>
            <InfoRow label="Juros" value={`+ ${formatter.amount(current_interest)}`} />
            <InfoRow label="Multa" value={`+ ${formatter.amount(current_fine)}`} />
          </>
        )}

        {is_from_credit_card_flow && credit_card_fee && (
          <InfoRow label="Taxa do Cartão" value={`+ ${formatter.amount(credit_card_fee)}`} />
        )}

        {hasCompositeAmount && <Divider style={{ margin: '18px 0' }} />}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {[InstallmentStatus.OVERDUE, InstallmentStatus.CANCELED].includes(status) ? (
            <Text variant="body-1-regular">Valor pendente</Text>
          ) : (
            <Text variant="body-1-regular">Valor a pagar</Text>
          )}

          <Heading variant="heading-h4-medium">{formatter.amount(current_amount)}</Heading>
        </div>
      </ContentWrapper>
    </>
  )

  const footer = (
    <FooterContainer>
      <Text variant="body-1-regular">{footerMessage}</Text>
      <InstallmentDrawerActionWrapper>
        <InstallmentDrawerActions onCloseDrawer={onCloseDrawer} />
      </InstallmentDrawerActionWrapper>
    </FooterContainer>
  )

  return {
    header,
    content,
    footer,
  }
}
