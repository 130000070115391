import { Table } from '@gravity/table'
import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'

import Icon from '@mdi/react'
import { mdiListBox, mdiPencil, mdiPrinter, mdiTrashCan } from '@mdi/js'

import { Contract as ContractData } from '@/shared/interfaces'
import { Contract, ErrorDialog } from '@/modules/guardians/GuardianDetails/types'

import { useGuardianDetailsContractsTableActions } from './useGuardianDetailsContractsTableActions'
import { MoreOptionsPopover } from '../MoreOptionsPopover'

interface GuardianDetailsContractsTableActionsProps {
  contract?: Contract
  contractData?: ContractData
  onChangeContractOwnership: (contractId: uuid) => void
  onGeneratePaymentStatement: (contractId: uuid) => void
  openCancelContractDrawer: (contractId: uuid) => void
  setErrorDialog: (errorDialog: ErrorDialog) => void
}

export const GuardianDetailsContractsTableActions = ({
  contract,
  contractData,
  openCancelContractDrawer,
  onChangeContractOwnership,
  onGeneratePaymentStatement,
  setErrorDialog,
}: GuardianDetailsContractsTableActionsProps) => {
  if (!contract) return null

  const {
    showEditContractOption,
    showDownloadCarneOption,
    showCancelContractOption,
    showGeneratePaymentStatement,
    showChangeContractOwnershipOption,
    handleClickGoToContractEdit,
    handleClickGoToInvoicePage,
    handleClickGoToDownloadCarne,
    handleClickGoToEnrollmentPage,
    sendGenerateStatementEvents,
    sendChangeContractOwnershipEvents,
  } = useGuardianDetailsContractsTableActions({
    contract,
    contractData,
    setErrorDialog,
  })

  const handleClickChangeOwnership = () => {
    sendChangeContractOwnershipEvents()
    onChangeContractOwnership(contract.id)
  }

  const handleClickGeneratePaymentStatement = () => {
    sendGenerateStatementEvents()
    onGeneratePaymentStatement(contract.id)
  }

  const paymentStatementOption = showGeneratePaymentStatement
    ? {
        label: 'Gerar demonstrativo de pagamento',
        onClick: handleClickGeneratePaymentStatement,
      }
    : null

  const changeOwnershipOption = showChangeContractOwnershipOption
    ? { label: 'Alterar titularidade', onClick: handleClickChangeOwnership }
    : null

  const moreOptions = [
    changeOwnershipOption,
    paymentStatementOption,
    { label: 'Ver situação de matrícula', onClick: handleClickGoToEnrollmentPage },
  ]

  return (
    <Table.ActionCell
      actions={
        <>
          {showEditContractOption && (
            <Tooltip text="Editar contrato" position="left">
              <IconButton variant="ghost" onClick={handleClickGoToContractEdit}>
                <Icon path={mdiPencil} size={16} />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip text="Ver faturas" position="left">
            <IconButton variant="ghost" onClick={handleClickGoToInvoicePage}>
              <Icon path={mdiListBox} size={16} />
            </IconButton>
          </Tooltip>

          {showDownloadCarneOption && (
            <Tooltip text="Imprimir carnê" position="left">
              <IconButton variant="ghost" onClick={handleClickGoToDownloadCarne}>
                <Icon path={mdiPrinter} size={16} />
              </IconButton>
            </Tooltip>
          )}

          {showCancelContractOption && (
            <Tooltip text="Cancelar contrato" position="left">
              <IconButton
                variant="ghost"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  openCancelContractDrawer(contract.id)
                }}
              >
                <Icon path={mdiTrashCan} size={16} />
              </IconButton>
            </Tooltip>
          )}

          <MoreOptionsPopover options={moreOptions.filter(Boolean)} />
        </>
      }
    />
  )
}
