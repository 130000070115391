import { useMutation } from '@tanstack/react-query'
import { useApi } from '@/shared/hooks/useApi'
import { ChooseOfferRequest } from '@/shared/services/credit/types'

export const chooseOfferQueryKey = 'anticipation-choose-offer'

export const useAnticipationChooseOffer = () => {
  const { api } = useApi()

  return useMutation([chooseOfferQueryKey], async (body: ChooseOfferRequest) =>
    api.credit.chooseOffer(body)
  )
}
