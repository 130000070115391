import { Skeleton } from '@material-ui/lab'
import { Typography } from '@olaisaac/design-system'

import { StyledButton, Title } from './styles'

export const CardTogglePlaceholder = () => {
  return (
    <StyledButton disabled>
      <Skeleton height={24} width={24} variant="rect" />
      <Title>
        <Skeleton width={100} />
      </Title>
      <Typography as="span" style={{ fontSize: '1rem', lineHeight: 1.5 }}>
        <Skeleton width={250} />
      </Typography>
    </StyledButton>
  )
}
