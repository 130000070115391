import {
  APIResponse,
  DebtStatus,
  PaginationRequest,
  Contract as LegacyContract,
  LiquidationSource,
  InstallmentType,
} from '@/shared/interfaces'
import { AgglutinationProcessedInstallment } from '@/escolas/contexts/checkoutContext'

import { GuardianBasicInfo } from '../ListGuardians/types'
import {
  Contract,
  GuardianFinanceSummaryPayload,
  Guardian,
  Installment,
} from '../GuardianDetails/types'
import { ContractsFiltersType } from '../GuardianDetailsContractsTab/types'
import { InstallmentsFiltersType } from '../GuardianDetailsInstallmentsTab/types'
import { InstallmentDrawerInfo, NegotiationInstallment } from '../InstallmentsDrawerContainer/types'
import { AgglutinationErrorReason } from '../GuardianDetails/features/Agglutination/constants'

export type useCanAgglutinateQueryProps = {
  receivable_ids: uuid[]
  school_id: uuid
}

export type useCanAgglutinateReturn = {
  can_agglutinate: boolean
  reason: AgglutinationErrorReason
}

export type useGetReceivableByIDQueryProps = {
  include_contract?: boolean
  include_discounts?: boolean
  include_education_substage?: boolean
  include_installment?: boolean
  include_invoice?: boolean
  include_new_receivables?: boolean
  include_original_receivables?: boolean
}

export type UseGuardiansQueryProps = {
  filter?: Partial<{
    debt_status: DebtStatus[]
    name: string
    tax_id: string
  }>
  pagination?: PaginationRequest
  schoolId: string
}

export type UseGuardianQueryProps = {
  guardianId: uuid
  schoolId: uuid
}

export type UseGuardianFiltersOptionsQueryProps = {
  guardianId: uuid
  schoolId: uuid
}

export type UseNegotiationInstallmentQueryProps = {
  guardianId: uuid
  schoolId: uuid
  status: string
  urn: string
}

export type UseGuardianContractsQueryProps = {
  filter?: Partial<ContractsFiltersType>
  guardianId: uuid
  schoolId: uuid
}

export type UseInstallmentsQueryProps = {
  filter?: Partial<InstallmentsFiltersType>
  guardianId: uuid
  schoolId: uuid
}

export type UseGuardianFinanceSummaryQueryProps = {
  guardianId: uuid
  schoolId: uuid
}

export type UseInstallmentsByUrnQueryProps = {
  guardianId: uuid
  urns: string[]
}

export enum GuardianHistoryExemptionReasons {
  consumer_defense = 'consumer_defense',
  extraordinary = 'extraordinary',
  incorrect_data = 'incorrect_data',
  is_angry = 'is_angry',
  is_inactive = 'is_inactive',
  isaac_error = 'isaac_error',
  local_holiday = 'local_holiday',
  public_complaint = 'public_complaint',
  school_exception = 'school_exception',
  social_media = 'social_media',
}

export enum GuardianHistoryReceivableStatus {
  AGGLUTINATED = 'AGGLUTINATED',
  AWAITING = 'AWAITING',
  CANCELED = 'CANCELED',
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  RENEGOTIATED = 'RENEGOTIATED',
}

export type GuardianHistoryOriginalReceivable = {
  amount: never
  competence: string
  due_date: string
  id: string
  order_reference: string
  product_name: string
  status: never
  student_name: string
  type: InstallmentType
}
export type GuardianHistoryResultantReceivable = {
  amount: cents
  competence: string[]
  due_date: string
  id: string
  order_reference: string
  product_name: string
  status: GuardianHistoryReceivableStatus
  student_name: string
  type: InstallmentType
}

export type GuardianHistory = {
  channel: string
  created_at: string
  id: uuid
  products: { id: uuid; name: string }[]
  short_id: string
  status: NegotiationStatus
  students: { id: uuid; name: string }[]
  total_amount: cents
}

export type GuardianHistoryNegotiation = {
  channel: string
  created_at: string
  exemption_reasons: GuardianHistoryExemptionReasons[]
  id: uuid
  original_amount: cents
  original_receivables: GuardianHistoryOriginalReceivable[]
  payment_method: NegotiationPaymentMethod
  remaining_amount: cents
  resultant_receivables: GuardianHistoryResultantReceivable[]
  short_id: string
  status: NegotiationStatus
  total_amount: cents
  total_discounts: cents
  total_taxes: cents
}

export type GuardianHistoryQueryProps = {
  guardianId: uuid
  period: string
  schoolId: uuid
}

export type GuardianHistoryQueryResponse = GuardianHistory[]

export type GuardianHistoryNegotiationQueryResponse = GuardianHistoryNegotiation

export type LegacyContractsReturn = {
  allBufferInstallments: AgglutinationProcessedInstallment[]
  allContracts: LegacyContract[]
  installmentsByContract: AgglutinationProcessedInstallment[]
}

export type UseLegacyContractsProps = {
  guardianId: uuid
  schoolId: uuid
}

export type FilterQueryType = {
  label: string
  value: string
}

export type FiltersOptionsQuery = {
  contract_statuses: FilterQueryType[]
  debt_statuses: FilterQueryType[]
  installment_statuses: FilterQueryType[]
  installment_types: FilterQueryType[]
  negotiation_id: FilterQueryType[]
  products: FilterQueryType[]
  reference_years: FilterQueryType[]
  students: FilterQueryType[]
}

export type DiscountType =
  | 'EARLY_PAYMENT'
  | 'DUE_PAYMENT'
  | 'PERPETUAL'
  | 'EARLY_PAYMENT_DISCOUNT'
  | 'KEPT_ON_MANUAL_LIQUIDATION'
  | 'ADDITIONAL_ON_MANUAL_LIQUIDATION'

export type Discount = {
  amount: number
  days_before_due_date?: number
  description?: string
  limit_date?: string
  type?: DiscountType
}

export type SchoolV2 = {
  id?: string
  name: string
  slug?: string
}

export type ContractV2 = {
  created_at: string
  erp: string
  id: string
  number_of_installments: number
  reference_year: string
  school: SchoolV2
}

export type InstallmentGuardianV2 = {
  email: string
  name: string
  source_id: string
  tax_id: string
}

export type InvoiceV2 = {
  digitable_line?: string
  id: string
  payment_instrument_additional_Info?: string
  payment_instrument_status: 'GENERATED' | 'PENDING' | 'WITH_ERROR'
  payment_link?: string
  pix_code?: string
}

export type PaymentMethods = 'CREDIT_CARD' | 'PIX' | 'BANK_SLIP' | 'SCHOOL_PORTFOLIO_REPURCHASE'

export type ReceivableTypeV2 = 'TUITION' | 'ENROLLMENT'

export type InstallmentTypeV2 = 'RECEIVABLE' | 'DOWN_PAYMENT'

export type InstallmentStudent = {
  id?: string
  name: string
  source_id: string
}

export type InstallmentStatus =
  | 'OPEN'
  | 'RENEGOTIATED'
  | 'OVERDUE'
  | 'PAID'
  | 'CANCELED'
  | 'AGGLUTINATED'
  | 'CHECKOUTED'
  | 'AWAITING'
  | 'BROKEN_PROMISE'

export type Product = { id: string; name: string }

export type PaymentSource = 'ISAAC_ACCOUNT' | 'IUGU' | 'SCHOOL_ACCOUNT' | 'STONE_CHECKOUT'

export type InstallmentV2 = {
  backoffice_installment_id?: string
  base_amount: number
  contracts: ContractV2[]
  credit_card_fee?: number
  current_additional_discount: number
  current_amount: number
  current_discount: number
  current_due_payment_discount: number
  current_early_payment_discount: number
  current_fine: number
  current_interest: number
  description?: string
  discounts?: Discount[]
  due_amount: number
  due_date?: string
  enabled_payment_methods?: PaymentMethods[]
  exempt_fine: number
  exempt_interest: number
  guardian: InstallmentGuardianV2
  in_negotiations?: Negotiation[]
  invoice: InvoiceV2
  lost_due_payment_discount: number
  lost_early_payment_discount: number
  order_reference: string
  origin_negotiation?: Negotiation
  original_amount: number
  overdue: boolean
  paid_amount?: number
  paid_date?: string
  paid_payment_method?: PaymentMethods
  paid_payment_source?: LiquidationSource
  products: Product[]
  receivable_type: ReceivableTypeV2
  status: InstallmentStatus
  student: InstallmentStudent
  type: InstallmentTypeV2
  urn: string
}

export type AgreementChannel = 'PI-ESCOLA' | 'PI-ADMIN' | 'SAMUCA'

export interface ChosenPaymentPlanNegotiationV2 {
  amount: number
  due_date: string
  index: number
  max_installments_credit_card: number
  methods: PaymentMethods[]
}

export type AgreementExemptionReasons = 'is_angry' | 'extraordinary' | 'incorrect_data' | null

export type NegotiationStatus =
  | 'PROMISE'
  | 'BROKEN_PROMISE'
  | 'BROKEN_DEAL'
  | 'PAID_DEAL'
  | 'CANCELED'
  | 'DEAL'

export enum NegotiationPaymentMethod {
  BANK_SLIP = 'BANK_SLIP',
  CREDIT_CARD = 'CREDIT_CARD',
  HYBRID = 'HYBRID',
  PIX = 'PIX',
}

export type Negotiation = {
  channel?: AgreementChannel
  chosen_payment_plans?: ChosenPaymentPlanNegotiationV2[]
  down_payment_amount?: number
  down_payment_due_date?: string
  down_payment_enabled_payment_methods?: PaymentMethods[]
  down_payment_max_installments_credit_card?: number
  due_payment_discount_applied: number
  early_payment_discount_applied?: number
  exempt_fine: number
  exempt_interest: number
  exemption_reasons?: AgreementExemptionReasons[]
  fine_applied: number
  id: string
  interest_applied: number
  isaac_discount_applied: number
  negotiation_amount: number
  negotiation_date: string
  original_receivables_ids?: string[]
  resultant_invoices_ids?: string[]
  resultant_receivables_ids?: string[]
  short_id: string
  simulation_id: string
  status: NegotiationStatus
  type: AgreementType
}

export type AgreementType =
  | 'NEGOTIATION_API'
  | 'AGGLUTINATED'
  | 'RENEGOTIATED'
  | 'NEGOTIATION_API_V2'

export type UseGuardiansQueryResponse = APIResponse<GuardianBasicInfo[]>

export type UseGuardianQueryResponse = APIResponse<Guardian>

export type UseGuardianFiltersOptionsQueryResponse = APIResponse<FiltersOptionsQuery>

export type UseGuardianContractsQueryResponse = APIResponse<Contract[]>

export type UseInstallmentsQueryResponse = APIResponse<Installment[]>

export type UseGuardianFinanceSummaryQueryResponse = APIResponse<GuardianFinanceSummaryPayload>

export type UseInstallmentsByUrnQueryResponse = APIResponse<InstallmentV2[]>

export type UseLegacyContractsQueryResponse = APIResponse<LegacyContractsReturn>

export type UseNegotiationInstallmentQueryResponse = NegotiationInstallment

export type UseInstallmentQueryResponse = InstallmentDrawerInfo

export type UseCanAgglutinateQueryResponse = useCanAgglutinateReturn

export type DownPaymentStatus = 'OPEN' | 'PAID' | 'CANCELED'
export type InvoiceType = 'CREDIT_CARD_PAYMENT' | 'DOWN_PAYMENT'

export type NegotiationPayment = {
  index: number
  maxInstallmentsCreditCard: number
  methods: NegotiationPaymentMethod[]
}

export type DownPayment = {
  amount: number
  available_payment_methods: NegotiationPaymentMethod[]
  created_at?: string
  credit_card_fee: number
  description: string
  digitable_line?: string
  due_date: string
  id: uuid
  invoice_id?: uuid
  invoice_type: InvoiceType
  invoice_url: string
  paid_amount?: number
  paid_date?: string
  payment_method?: string
  payment_provider: string
  qr_code?: string
  referral?: string
  status: DownPaymentStatus
}

export type GuardianNegotiation = {
  agreement_simulation_id: uuid
  channel: AgreementChannel
  chosen_payment_plan: NegotiationPayment[]
  created_at: string
  down_payment?: DownPayment
  due_payment_discount_applied: number
  early_payment_discount_applied: number
  exempt_fine: number
  exempt_interest: number
  exemption_reasons: string[]
  fine_applied: number
  guardian_id: uuid
  id: uuid
  interest_applied: number
  isaac_discount_applied: number
  resultant_amount: number
  school_id?: uuid
  source: string
  status: NegotiationStatus
  student_id?: uuid
  updated_at: string
}

export type GuardianNegotiationQueryResponse = APIResponse<GuardianNegotiation>
