import { InstallmentType } from '@/modules/guardians/GuardianDetails/constants'

export type InstallmentDrawerTypes =
  | 'addDiscount'
  | 'contractEditEnrollmentDueDay'
  | 'installment'
  | 'loading'
  | 'negotiation'
  | 'renegotiation'

interface UseDrawerTypeProps {
  installmentType?: InstallmentType
  isLoading: boolean
  selectedDrawer?: InstallmentDrawerTypes
}

export const useDrawerType = ({
  installmentType,
  isLoading,
  selectedDrawer,
}: UseDrawerTypeProps): InstallmentDrawerTypes => {
  if (isLoading) {
    return 'loading'
  }

  if (selectedDrawer) {
    return selectedDrawer
  }

  if ([InstallmentType.NEGOTIATION, InstallmentType.DOWN_PAYMENT].includes(installmentType)) {
    return 'negotiation'
  }

  return 'installment'
}
