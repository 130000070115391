import { spacing } from '@gravity/tokens'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: ${spacing[8]};
`

export const TextFieldWrapper = styled.div`
  .MuiInputBase-root {
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
`
