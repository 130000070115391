import { student, openContract2, openContract } from '@/shared/mocks'
import { PreContractStatuses } from '@/shared/interfaces'

export const guardiansContractsMock = () => {
  const guardianStudent = {
    name: student.name,
    id: student.id,
  }

  const contractsArr = [{ ...openContract2 }, { ...openContract }]
  const preContractStatus = [PreContractStatuses.CREATED_PCS, PreContractStatuses.EFFECTED_PCS]

  const contracts = contractsArr.map((contract, index) => ({
    debt_status: contract.debt_status,
    id: contract.id,
    product: contract.product,
    reference_year: contract.reference_year,
    status: contract.status,
    pre_contract_status: preContractStatus[index],
    student: {
      ...guardianStudent,
      name: contract.student.name,
    },
    student_id: guardianStudent.id,
    created_at: contract.created_at,
  }))

  return {
    data: contracts,
  }
}
