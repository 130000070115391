import qs from 'qs'
import { Box } from '@material-ui/core'
import { Drawer, ActionDrawerHeader, Divider, ButtonDocker, Button } from '@olaisaac/design-system'

import { ChangesChip } from '@/escolas/components/report/Chips/ChangesChip'

import { formatMicroCentsToReal, prettifyFullName } from '@/shared/utils'
import { TRANSACTIONS_IN_ORDER } from '../../constants/transactionsInOrder'

import { FinancialDetails, TransactionEventType } from '@/modules/report/models/report'
import { Content, SummaryItem } from '../IsaacPaySummaryDrawer/styles'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useIsaacPayOutsourcedPaymentProvider } from '@/modules/isaacpay/hooks/useIsaacPayOutsourcedPaymentProvider'

type IsaacPayReportTableRowDetailDrawerProps = {
  contractId: uuid
  contractReferenceYear?: string
  financialDetails?: FinancialDetails
  guardianId?: string
  isOpen: boolean
  onClose: () => void
  productId?: string
  productName: string
  receiptDate: Date
  studentId?: string
  studentName: string
}

export const IsaacPayReportTableRowDetailDrawer = ({
  guardianId,
  contractReferenceYear,
  studentId,
  studentName,
  productId,
  productName,
  financialDetails,
  receiptDate,
  isOpen,
  onClose,
}: IsaacPayReportTableRowDetailDrawerProps) => {
  const { school } = useSelectedSchool()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)

  const handleNavigateToContract = () => {
    const route = `/${school?.slug}/responsaveis/${guardianId}/faturas`

    const filtersQueryParams = qs.stringify({
      student_ids: studentId,
      product_ids: productId,
      reference_years: contractReferenceYear,
    })

    window.open(`${route}?${filtersQueryParams}`, '_blank')
  }

  const formatDate = (date: Date, isOutsourcedProvider: boolean) => {
    if (isOutsourcedProvider) {
      const day = String(date.getUTCDate()).padStart(2, '0')
      const month = String(date.getUTCMonth() + 1).padStart(2, '0')
      const year = String(date.getUTCFullYear())

      return `${day}/${month}/${year}`
    }

    const formatter = new Intl.DateTimeFormat('pt-BR')
    return formatter.format(date)
  }

  const convertEventsKeysToArray = financialDetails?.events
    ? (Object.keys(financialDetails?.events) as Array<TransactionEventType>)
    : []

  const values = {
    ...financialDetails?.events,
    total_base_amount: financialDetails?.total_base_amount,
    total_fee: -1 * financialDetails?.total_fee,
  }

  const studentNameFormatted = prettifyFullName(studentName)

  return (
    <Drawer open={isOpen}>
      <ActionDrawerHeader
        title={studentNameFormatted}
        subtitle={productName}
        extraContent={
          financialDetails?.events && (
            <Box display="flex" flexWrap="wrap" gridGap="0.5rem">
              <ChangesChip tags={[...convertEventsKeysToArray]} limit={15} />
            </Box>
          )
        }
        onClose={onClose}
      />

      <Content>
        <SummaryItem variation="tertiary">
          <p className="label">Data de recebimento</p>

          <span>{formatDate(receiptDate, isOutsourcedProvider)}</span>
        </SummaryItem>

        <Divider variation="secondary" />

        {Object.keys(TRANSACTIONS_IN_ORDER).map(key => {
          const valueKey = key as keyof typeof values
          if (values[valueKey]) {
            return (
              <SummaryItem key={valueKey} variation="tertiary">
                <p className="label">
                  {TRANSACTIONS_IN_ORDER[valueKey as keyof typeof TRANSACTIONS_IN_ORDER].label}
                </p>

                <span>{formatMicroCentsToReal(values[valueKey])}</span>
              </SummaryItem>
            )
          }

          return null
        })}

        <SummaryItem variation="secondary">
          <p className="label">Valor final</p>

          <span>{formatMicroCentsToReal(financialDetails?.total)}</span>
        </SummaryItem>
      </Content>

      <ButtonDocker>
        <Button fullWidth onClick={handleNavigateToContract}>
          Ir para contrato
        </Button>
      </ButtonDocker>
    </Drawer>
  )
}
