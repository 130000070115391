import { APIResponse } from '@/shared/interfaces'

export type GracePeriodDate = {
  label: string
  value: string
}

export type GracePeriod = {
  AR: Date[]
  CP: Date[]
  CPCR: Date[]
}

export type Eligibility = {
  eligibility?: {
    eligible: {
      AR: boolean
      CP: boolean
      CPCR: boolean
    }
    pa: {
      AR: number
      CP: number
      CPCR: number
    }
  }
  eligibleForGracePeriod: boolean
  gracePeriod: GracePeriod
  renovationPa?: number
}

export type EligibilityResponse = APIResponse<Eligibility>

export enum CreditType {
  AR = 'ar',
  CP = 'cp',
  CPCR = 'cp-cr',
}

export type CreateCreditSimulationRequest = {
  creditTypes: CreditType[]
  firstDiscountDate: string
  gracePeriodDate: GracePeriodDate
  gracePeriodDates: GracePeriodDate[]
  note: string
  reason: string
  requestedValue: number

  schoolId: string
}

export type CreateAnticipationSimulationRequest = {
  note: string
  reason: string
  requestedValue: number

  schoolId: string
}

export type AnticipationOffer = {
  appliedFee: string
  chosenOfferDate: string
  disbursementDate: string
  discountDate: string
  feeValue: string
  id: string
  requestValue: string
  totalAmount: string
}

export type AnticipationSimulation = {
  creditValue: string
  offer: AnticipationOffer
  orderId: string
}

export type AnticipationSimulationResponse = APIResponse<AnticipationSimulation>

export type Offer = {
  annualFee: string
  category: CreditType
  chosenOfferDate: string
  firstDiscountDate: string
  id: string
  installmentValue: string
  monthlyFee: string
  totalAmount: string
  totalInstallments: number
  type: string
}

export type CreditSimulation = {
  creditValue: string
  offers: Offer[]
  orderId: string
}

export type CreditSimulationResponse = APIResponse<CreditSimulation>

export enum ReasonsType {
  goal = 'goal',
  payout = 'payout',
}

export type CreditReason = {
  label: string
  requiredNote: boolean
  value: string
}

export type CreditReasonsResponse = APIResponse<CreditReason[]>

export type ChooseOfferRequest = {
  chooseOfferId: string
  orderId: string
}

export type StepperMap = {
  finished: boolean
  step: number
}

export type GetOrderStatus = {
  chosenAnticipationOffer: AnticipationOffer
  chosenOffer: Offer
  creditValue: string
  offerType: CreditType
  orderId: string
  status: string
  stepperMapper: StepperMap
}

export type DropoutType = 'cancel' | 'abandonment'

export type OrderDropoutRequest = {
  creditOrderId: string
  message?: string
  type: DropoutType
}

export type RequestSimulation = {
  gracePeriodDate: GracePeriodDate
  gracePeriodDates: GracePeriodDate[]
  note: string
  reason: string
}

export type GetOrderStatusResponse = APIResponse<GetOrderStatus>

export type CreditOrderLockResponse = {
  data: {
    chosen_offer?: Offer
    creditValue: string
    id: string
    status: string
  }
}
export type GetLockResponse = {
  isLocked: boolean
  order?: CreditOrderLockResponse
}

export enum ReasonForChosenPolicyType {
  becauseOfTheGracePeriod = 'becauseOfTheGracePeriod',
  becauseOfTheInstallment = 'becauseOfTheInstallment',
  becauseOfTheInstallmentValue = 'becauseOfTheInstallmentValue',
}

export type DataReasonForChosenPolicyType = {
  chosenGracePeriod: string
  fromTheseInstallment: number
  maxPaCP: number
  reason?: ReasonForChosenPolicyType
}

export type ResponseGetStatus = AnticipationSimulationResponse | GetOrderStatusResponse
