import { createGlobalStyle } from 'styled-components'

export const DSHighlighterStyles = () => {
  const DSGlobalStyles = createGlobalStyle`
  [class*="Mui"] {
      outline: red solid 2px !important;
      outline-style: dashed !important;
      outline-offset: 2px !important;
    }

    [class*="Mui"] > [class*="Mui"] {
        outline: red solid 1px !important;
        outline-style: dashed !important;
        outline-offset: 2px !important;
    }

    [class*="rounded"]:not([class*="Mui"]),
    [class*="font"]:not([class*="Mui"]),
    [class*="text"]:not([class*="Mui"]),
    [class*="colors"]:not([class*="Mui"]),
    [class*="tracking"]:not([class*="Mui"]),
    [class*="leading"]:not([class*="Mui"]),
    [class*="shadow"]:not([class*="Mui"]) {
        outline: green solid 2px !important;
        outline-style: dashed !important;
    }
  `
  const isHighlighterEnabled = JSON.parse(localStorage.getItem('_DS_HIGHLIGHTER_ENABLED'))

  return <>{isHighlighterEnabled && <DSGlobalStyles />}</>
}
