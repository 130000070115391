import { Contract, Installment, Receivable } from '@/shared/interfaces'
import { Dayjs } from 'dayjs'

export type FieldsStatus = {
  isDirty: boolean
  isOpen: boolean
  name: ManualLiquidationFields
}

export enum ManualLiquidationFields {
  DISCOUNTS = 'DISCOUNTS',
  EDIT_REASON = 'EDIT_REASON',
  PAID_DATE = 'PAID_DATE',
  PAYMENT_FORM = 'PAYMENT_FORM',
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
}

export type ManualLiquidationDiscountPageForm = {
  additional_discount_amount: cents
  apply_fines_and_interest_change: boolean
  apply_lost_due_payment_discount: boolean
  apply_lost_early_payment_discount: boolean
  apply_previous_fines_and_interest: boolean
}

export type InfoLiquidationValues = {
  addedDiscounts: number
  dueDiscount: number
  earlyDiscount: number
  fine: number
  interest: number
  originalAmount: number
  paidDate: Dayjs
}

export type DiscountFieldType = {
  applyPreviousFinesAndInterest: boolean
  disableDuePayment: boolean
  disableEarlyPayment: boolean
  disableFineAndInterestChange: boolean
  disableFinesAndInterest: boolean
  disableMoreDiscounts: boolean
  finalAmount: cents
  finalBaseAmount: cents
  fineAndInterest: cents
  fineAndInterestChange: cents
  isAdditionalDiscountEnabled: boolean
  isRenegotiated: boolean
  lostDuePaymentDiscount: cents
  lostEarlyPaymentDiscount: cents
  previousFinesAndInterest: cents
  setIsAdditionalDiscountEnabled?: (value: boolean) => void
  validateTotalDiscount: boolean
  warningMessage?: string
}

export interface LocationState {
  contract: Contract
  installment: Installment
  receivable: Receivable
  receivableId: uuid
}
