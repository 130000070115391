import type { AxiosInstance } from 'axios'
import {
  ProductStudentsRequest,
  ProductStudentsResponse,
  ProductsByReferenceYearRequest,
  ProductsByReferenceYearResponse,
} from './types'

export const enrollmentCampaignService = (fetcher: AxiosInstance) => {
  return {
    /**
     *
     * Function responsible for get all products by reference year
     *
     * @param ProductsByReferenceYearRequest
     *
     * @returns ProductItem[]
     */
    fetchProductsByReferenceYear: async (
      request: ProductsByReferenceYearRequest
    ): Promise<ProductsByReferenceYearResponse> => {
      const { data } = await fetcher.get<ProductsByReferenceYearResponse>(
        `/product/by-reference-year`,
        {
          params: {
            school_id: request.schoolId,
            reference_years: request.referenceYears,
          },
        }
      )
      return data
    },

    /**
     *
     * Function responsible for get all students by product.
     *
     * @param {uuid} schoolId - The ID of the school
     * @param {ProductStudentsRequest} request - The request parameters.
     *
     * @param {string[]} request.inProductIds - List of product IDs
     * @param {boolean} request.isAbleCampaign - Boolean value to return eligible or ineligible students
     * @param {string} request.notProductId - Campaign product ID
     * @param {PaginationRequest} request.pagination - Pagination properties
     * @param {number} request.referenceYear - Reference year
     *
     * @returns List of students from a product
     *
     */
    fetchStudentsByProduct: async (
      schoolId: uuid,
      request: ProductStudentsRequest
    ): Promise<ProductStudentsResponse> => {
      const { data } = await fetcher.get<ProductStudentsResponse>(
        `/campaign/${schoolId}/students`,
        {
          params: {
            in_product_ids: request.inProductIds,
            is_able_campaign: request.isAbleCampaign,
            not_product_id: request.notProductId,
            reference_year: request.referenceYear,
            ...request.pagination,
          },
        }
      )
      return data
    },
  }
}
