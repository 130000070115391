import { propEq } from 'ramda'
import { InstallmentChange } from './ContractEditDueDayDrawer'
import { ReceivableStatuses } from 'src/shared/interfaces'
import dayjs from 'dayjs'

export const parseUpdatedInstallments = ({
  original_installments,
  new_installments,
}): InstallmentChange[] => {
  const updatedInstallments: InstallmentChange[] = []
  for (let index = 0; index < original_installments?.length; index++) {
    const updatedInstallment = new_installments?.find(propEq('id', original_installments[index].id))
    if (!updatedInstallment || !updatedInstallment.receivables) {
      continue
    }

    const newReceivable = updatedInstallment.receivables[0]

    const originalReceivable = original_installments[index].receivables.find(
      ({ status }) => status === ReceivableStatuses.OPEN
    )
    updatedInstallments.push({
      current_amount: updatedInstallment.receivables[0].current_amount,
      due_month: updatedInstallment.due_date,
      id: updatedInstallment.id,
      index,
      newDueDate: dayjs(newReceivable.due_date).utc(),
      newValue: newReceivable.current_amount,
      orderReference: `${index + 1} de ${original_installments.length}`,
      originalDueDate: dayjs(originalReceivable.due_date).utc(),
      originalValue: originalReceivable.current_amount,
      receivable: newReceivable,
    })
  }
  return updatedInstallments
}
