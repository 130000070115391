import styled from 'styled-components'

import { MAX_STEP_WIDTH } from '../../constants'

export const Container = styled.div`
  max-width: ${MAX_STEP_WIDTH}px;
  margin: ${({ theme }) => theme.spacing(2)}px auto 0;
`

export const Wrapper = styled.div`
  padding: 32px 24px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  margin-bottom: 150px;
`

export const CardSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

export const Footer = styled.div`
  position: fixed;
  left: 96px;
  bottom: 0px;
  right: 0px;
  padding: ${({ theme }) => theme.spacing(4)}px ${({ theme }) => theme.spacing(8)}px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FooterContent = styled.div`
  max-width: 654px;
  margin: 0 auto;
  padding-right: 12%;
  display: flex;
  justify-content: space-between;
`

export const FooterBtnWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing(7)}px;
`
