import { useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { Button } from '@olaisaac/design-system'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { GuardianDetailsDisclaimerModalProps } from './types'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'

export const GuardianDetailsDisclaimerModal: React.FC<GuardianDetailsDisclaimerModalProps> = props => {
  const { isOpen, onClose } = props
  const [checked, setChecked] = useState(false)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleChecked = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.INSTALLMENTS_LIST,
        entity: EventDispatcherEntities.OPEN_NEGOTIATION_MODAL_CONFIRM,
        customProperties: {
          $name: 'Estou Ciente',
        },
        name: EventDispatcherEvents.CHECKBOX_SELECTED,
        action: 'click',
      })
    setChecked(!checked)
  }
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>Este responsável possui negociação em andamento</DialogTitle>
      <DialogContent>
        <Typography>
          Caso o pagamento da parcela de entrada seja efetuado por um meio não conciliado (dinheiro,
          cheque, permuta, etc.), por favor, registre o recebimento na plataforma no momento do
          pagamento. Caso o recebimento seja registrado em uma data posterior ao vencimento, a
          parcela terá expirado e será necessário realizar uma nova negociação, implicando em
          alterações de valores e condições de pagamento.
        </Typography>
        <Box display="flex" alignItems="center" py={2}>
          <Checkbox checked={checked} onChange={handleChecked} />
          <Typography>
            Estou ciente, caso o responsável efetue o pagamento por um meio não conciliado, irei
            registar o recebimento no momento do pagamento.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} disabled={!checked}>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
