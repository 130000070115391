import { CommonMetadata } from './metadata'

export enum InvoiceProviders {
  IUGU = 'IUGU',
  STONE_CHECKOUT = 'STONE_CHECKOUT',
}

export type InvoiceBankSlip = {
  barcode: string
  barcode_data: string
  digitable_line: string
}

export type InvoiceAdditionalInformationPaymentMethod = {
  DigitableLine?: string
  PixQrCodeText?: string
}

export type InvoceAdditionalInformation = {
  amount?: number
  payment_method?: InvoiceAdditionalInformationPaymentMethod
}

export type Invoice = CommonMetadata & {
  additional_informations?: InvoceAdditionalInformation
  amount: number
  available_payment_methods?: string[]
  bank_slip?: InvoiceBankSlip
  checkout_url: url
  due_date: datestring
  paid_amount: number
  payment_provider: InvoiceProviders
  referral: string
}
