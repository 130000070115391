import dayjs from 'dayjs'

type Props = {
  dueDateMonth: null | Date
  referenceYear: number
}

export const useMaxTuitionInstallmentValidation = ({ dueDateMonth, referenceYear }: Props) => {
  const today = dayjs().utc()
  const januaryNextYear = dayjs(`${referenceYear}-01-01`).utc().add(1, 'year').endOf('month')

  const maxTuitionInstallments = januaryNextYear.diff(today, 'months') + 1

  const calcTuitionDurationMonthsLimit = () => {
    const selectedTuitionStartMonth = dayjs(dueDateMonth).utc()

    const monthsTuitionDifferenceStartMonth =
      januaryNextYear.diff(selectedTuitionStartMonth, 'months') + 1

    return Math.min(monthsTuitionDifferenceStartMonth, maxTuitionInstallments)
  }

  const isSelectedTuitionDurationMonthsValid = (value: string) => {
    if (dueDateMonth) {
      return +value <= calcTuitionDurationMonthsLimit()
    }
    return true
  }

  const monthsDifference = dueDateMonth ? calcTuitionDurationMonthsLimit() : 0
  const tuitionDurationMonthsValidationText =
    monthsDifference > 0
      ? `Suas parcelas não podem exceder Janeiro de ${referenceYear + 1}.${
          monthsDifference < 1 ? '' : ` Por favor, selecione até ${monthsDifference} parcelas.`
        }`
      : 'Selecione a quantidade de parcelas'

  return {
    maxTuitionInstallments,
    isSelectedTuitionDurationMonthsValid,
    tuitionDurationMonthsValidationText,
  }
}
