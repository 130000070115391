import Dialog from '@material-ui/core/Dialog'
import styled from 'styled-components'
import { spacing, colors } from '@gravity/tokens'
import { DialogActions, DialogTitle } from '@olaisaac/design-system'

export const DialogCustom = styled(Dialog)`
  display: flex;
  padding: ${spacing[4]} ${spacing.none};
  flex-direction: column;
  align-items: center;
  gap: ${spacing[8]};
  align-self: stretch;

  .MuiBackdrop-root {
    background-color: ${colors['colors-background-accent-2']};
    opacity: 0.3 !important;
  }

  .MuiPaper-rounded {
    border-radius: 0.5rem;
  }
`

export const StyledDialogTitle = styled(DialogTitle)`
  margin: 16px 16px 32px;
`

export const ContainerActions = styled(DialogActions)`
  display: flex;
  gap: ${spacing[2]};
  border-top: none;
  margin-top: 16px;
  padding: 16px;
`

export const List = styled.ul`
  list-style-position: inside;
`
export const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: ${spacing[3]};
  align-self: stretch;
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`
