import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => `${theme.gravity.spacing[4]} ${theme.gravity.spacing[2]}`};

  > svg {
    box-sizing: content-box;
  }
`
