import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { WeekPicker } from '@/escolas/components/WeekPicker'
import { DatePicker } from '@/escolas/components/DatePicker'
import {
  PayoutSubHeader,
  PayoutTopHeader,
} from '@/modules/report/components/PayoutReportPageHeader'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useQuery } from '@/shared/hooks/useQuery'

import { enrollmentPayoutReportDataMapper } from '../../../PayoutReportDashboard/hooks/usePayoutReportDashboardData/utils/enrollmentPayoutReportDataMapper'

import { REPORT_PAGE_NAMES } from '@/modules/report/constants/REPORT_PAGE_NAMES'

import type { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { isIsaacPaySchool } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'
import { TileDisabledFunctionArgs } from '@olaisaac/design-system'

type EnrollmentPayoutReportHeaderProps = {
  enrollmentPayoutReportList: UnguaranteedReport[]
  handleOpenDrawerSummary: () => void
  isHourIncluded?: boolean
  isLoadingEnrollmentPayoutReportList: boolean
  isOnboardingDrawerOpen: boolean
  onChangeEnrollmentPayout: () => void
  onRequestOnboarding: () => void
}

export const EnrollmentPayoutReportHeader = ({
  enrollmentPayoutReportList,
  isLoadingEnrollmentPayoutReportList,
  isOnboardingDrawerOpen,
  onRequestOnboarding,
  onChangeEnrollmentPayout,
  isHourIncluded,
  handleOpenDrawerSummary,
}: EnrollmentPayoutReportHeaderProps) => {
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { query, setOnQueryParam, setMultipleQueryParams } = useQuery()

  const [selectedEnrollmentPayoutReportId, setSelectedEnrollmentPayoutReportId] = useState<string>()

  const isIsaacPay = isIsaacPaySchool(school)

  const urlPayoutId = query.get('id')

  useEffect(() => {
    if (enrollmentPayoutReportList.length === 0) return

    let currentPayout = enrollmentPayoutReportList.find(item => item.payout_id === urlPayoutId)

    if (!currentPayout) {
      currentPayout = enrollmentPayoutReportList[enrollmentPayoutReportList.length - 1]
    }

    setSelectedEnrollmentPayoutReportId(currentPayout.payout_id)

    if (urlPayoutId !== currentPayout.payout_id) {
      setOnQueryParam(currentPayout.payout_id, 'id', 'replace')
    }
  }, [urlPayoutId, enrollmentPayoutReportList])

  const enrollmentMinDate = enrollmentPayoutReportList?.[0]?.started_at

  const enrollmentMaxDate = enrollmentPayoutReportList?.at(-1)?.ended_at

  const findEnrollmentPayoutByDate = (date: Date) => {
    return enrollmentPayoutReportList.find(
      item => dayjs(item.ended_at).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
    )
  }

  const handleChangeEnrollmentPayoutReportById = (id: string) => {
    const newCurrentEnrollmentPayoutReport = enrollmentPayoutReportList.find(
      item => item.payout_id === id
    )

    if (!newCurrentEnrollmentPayoutReport) return

    setMultipleQueryParams(
      [
        { name: 'id', value: newCurrentEnrollmentPayoutReport.payout_id },
        { name: 'events' },
        { name: 'amount_signs' },
        { name: 'products' },
        { name: 'reference_years' },
      ],
      'replace'
    )
    onChangeEnrollmentPayout()

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
        name: EventDispatcherEvents.ENROLLMENT_PAYOUT_DATE_CHANGE,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
        },
      })
  }

  const handleChangeEnrollmentPayoutReportByDate = (date: Date) => {
    const payout = findEnrollmentPayoutByDate(date)
    if (!payout) return
    const id = payout.payout_id
    handleChangeEnrollmentPayoutReportById(id)
  }

  const checkIfDateShouldBeDisabled = ({ view, date }: TileDisabledFunctionArgs) => {
    if (view !== 'month') return false

    const payout = findEnrollmentPayoutByDate(date)

    return !payout
  }

  const options = enrollmentPayoutReportList.map(item => enrollmentPayoutReportDataMapper(item))

  const currentOption =
    options.find(item => item.id === selectedEnrollmentPayoutReportId) ??
    options[options.length - 1]

  const linkButtonText = 'Ver resumo'
  const onClickAtLinkButton = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
        name: EventDispatcherEvents.ENROLLMENT_PAYOUT_SUMMARY,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
        },
      })

    handleOpenDrawerSummary()
  }

  return (
    <>
      <PayoutTopHeader
        backButtonLink={`/${school?.slug}/repasses`}
        isOnboardingDrawerOpen={isOnboardingDrawerOpen}
        onRequestOnboarding={() => {
          onRequestOnboarding()

          isInitialized &&
            eventDispatcherClient.sendEvent({
              scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
              name: EventDispatcherEvents.ENROLLMENT_PAYOUT_HELP,
              action: 'click',
              customProperties: {
                $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
              },
            })
        }}
      />
      <PayoutSubHeader
        isHourIncluded={isHourIncluded}
        linkButtonText={linkButtonText}
        onClickAtLinkButton={onClickAtLinkButton}
        payoutReportPeriod={{ endDate: enrollmentMaxDate, startDate: enrollmentMinDate }}
        title="Repasse de matrículas"
        picker={
          isIsaacPay ? (
            <DatePicker
              valueTemplate="DD [de] MMMM [de] YYYY"
              variation="ghost"
              maxDetail="month"
              minDetail="decade"
              minDate={enrollmentMinDate}
              maxDate={enrollmentMaxDate}
              onChange={handleChangeEnrollmentPayoutReportByDate}
              tileDisabled={checkIfDateShouldBeDisabled}
              value={dayjs(currentOption?.ended_at).toDate()}
            />
          ) : (
            <WeekPicker<{ ended_at: Date; id: string; started_at: Date }>
              variation="ghost"
              keyExtractor={item => item.id}
              maxDetail="year"
              minDate={enrollmentMinDate}
              maxDate={enrollmentMaxDate}
              value={currentOption}
              options={options}
              onChange={({ id }) => handleChangeEnrollmentPayoutReportById(id)}
            />
          )
        }
        isLoading={isLoadingEnrollmentPayoutReportList}
      />
    </>
  )
}
