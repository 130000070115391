import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { ROLES } from '@/shared/constants'

export const PROFILE = {
  [ROLES.PROFILE_MANTAINER]: 'Administração',
  [ROLES.PROFILE_SECRETARY]: 'Operação',
} as const

export const useProfileDetails = () => {
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const { hasPayoutReportAccess: isMaintainer } = useJWT()
  const { school } = useSelectedSchool()

  const profileType = isMaintainer ? ROLES.PROFILE_MANTAINER : ROLES.PROFILE_SECRETARY

  return {
    isIntegratedSchool,
    isMaintainer,
    isSecretary: !isMaintainer,
    profile: PROFILE[profileType],
    school,
  }
}
