import { Dialog } from '@material-ui/core'

import {
  Typography,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@olaisaac/design-system'

type ModalConfirmationProps = {
  isLoading?: boolean
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  studentName: string
  totalItemsSelected: number
}

export function ModalConfirmation({
  isLoading = false,
  isOpen,
  onClose,
  onConfirm,
  studentName,
  totalItemsSelected = 0,
}: ModalConfirmationProps) {
  const currentYear = new Date().getFullYear()

  return (
    <Dialog open={isOpen} onClose={isLoading ? () => null : onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variation="headlineDesktopSmall" style={{ margin: 0, marginBottom: -30 }}>
          Atenção!
        </Typography>
      </DialogTitle>

      <DialogContent style={{ paddingTop: '1rem' }}>
        {totalItemsSelected > 1 ? (
          <>
            <Typography>
              Ao manter as cobranças selecionadas de {studentName}, você confirma que a matrícula
              para o ciclo {currentYear.toString()} está regularizada, o contrato continua ativo, e
              o pagamento das parcelas não foi efetuado na escola.
            </Typography>

            <Typography style={{ marginTop: '1rem' }}>
              Se necessário, revise a(s) cobrança(s) individualmente.
            </Typography>
          </>
        ) : (
          <Typography>
            Ao manter esta cobrança de {studentName}, você confirma que a matrícula para o ciclo{' '}
            {currentYear.toString()} está regularizada, o contrato continua ativo e o pagamento da
            parcela não foi efetuado na escola.
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button variation="ghost" onClick={onClose} disabled={isLoading}>
          Cancelar
        </Button>

        <Button variation="primary" onClick={onConfirm} loading={isLoading}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
