import { Box, Collapse } from '@material-ui/core'

import { PropsWithChildren } from 'react'
import * as S from './styles'
import type { InputBoxProps } from './types'

const InputBox = ({
  isOpen,
  onClick,
  name,
  isDisabled,
  children,
  title,
  subTitle,
  buttonLabel,
}: PropsWithChildren<InputBoxProps>) => {
  return (
    <S.StyledBox role="treeitem" aria-expanded={isOpen} aria-label={title}>
      <S.WrapperContent>
        <S.WrapperTitle>
          <S.Title variation="headlineDesktopXsmall">{title}</S.Title>
          <S.SubTitle variation="bodySmall">{subTitle}</S.SubTitle>
        </S.WrapperTitle>
        <Box>
          <S.StyledListItem
            style={{ backgroundColor: 'transparent' }}
            button
            disabled={isDisabled}
            data-testid={`open-button-${name}`}
            onClick={() => onClick(name)}
          >
            {!isOpen && (
              <S.ButtonLabel color={isDisabled ? 'secondary' : 'accent'} variation="bodyLarge">
                {buttonLabel}
              </S.ButtonLabel>
            )}
            {isOpen ? <S.ExpandLess /> : <S.ExpandMore />}
          </S.StyledListItem>
        </Box>
      </S.WrapperContent>
      <Collapse in={isOpen} timeout={400} unmountOnExit>
        <S.CollapseContent>{children}</S.CollapseContent>
      </Collapse>
    </S.StyledBox>
  )
}

export default InputBox
