import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { TextField } from '@olaisaac/design-system'
import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { UFs, validateSpecialCharactery, validateZipCode } from 'src/shared/utils'
import { GuardianFormTitle } from '../../styles'
import { useAddressAutoComplete } from './hooks/useAddressAutoComplete'

export const GuardianAddressFormInput = ({ form }) => {
  const { control, setValue, trigger } = form
  const { addressData, fecthAddress, cleanAddress } = useAddressAutoComplete()

  const addressFields = ['zipCode', 'street', 'addressNumber', 'additionalInfo', 'city', 'state']

  const resetAddressFields = () => addressFields.forEach(field => setValue(field, ''))
  const handleAutoComplete = (value: string): void => {
    if (validateZipCode(value)) {
      fecthAddress(value)
    } else {
      cleanAddress()
      resetAddressFields()
    }
  }

  useEffect(() => {
    if (addressData) {
      const {
        street,
        additional_information: additionalInfo,
        city,
        state_code: stateCode,
      } = addressData
      setValue('street', street, { shouldTouch: true, shouldValidade: true })
      setValue('additionalInfo', additionalInfo, { shouldTouch: true, shouldValidade: true })
      setValue('city', city, { shouldTouch: true, shouldValidade: true })
      setValue('state', stateCode, { shouldTouch: true, shouldValidade: true })
      trigger(addressFields)
    }
  }, [addressData])

  return (
    <Box mb={5}>
      <GuardianFormTitle>Endereço</GuardianFormTitle>
      <Grid spacing={2} container>
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined">
            <Controller
              control={control}
              rules={{ required: true }}
              name="zipCode"
              render={({ field, fieldState: { error } }) => (
                <NumberFormat
                  {...field}
                  id="zipcode"
                  customInput={TextField}
                  variant="outlined"
                  label="CEP"
                  format="#####-###"
                  error={Boolean(error?.type)}
                  helperText={error?.type ? 'Insira um CEP válido' : ''}
                  onValueChange={({ value }) => {
                    handleAutoComplete(value)
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth variant="outlined">
            <Controller
              control={control}
              rules={{ required: true, maxLength: 100 }}
              name="street"
              render={({ field, field: { value }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="street"
                  data-testid="street"
                  value={value || ''}
                  label="Logradouro (rua, avenida, praça)"
                  error={Boolean(error?.type)}
                  helperText={error?.type ? 'Insira um logradouro válido' : ''}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined">
            <Controller
              control={control}
              rules={{ required: true }}
              name="addressNumber"
              render={({ field, fieldState: { error } }) => (
                <NumberFormat
                  {...field}
                  id="addressNumber"
                  customInput={TextField}
                  variant="outlined"
                  label="Número"
                  error={Boolean(error?.type)}
                  helperText={error?.type ? 'Insira um número' : ''}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{ required: false, maxLength: 100 }}
              control={control}
              name="additionalInfo"
              render={({ field, field: { value }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="additionalInfo"
                  value={value || ''}
                  label="Complemento (opcional)"
                  error={Boolean(error?.type)}
                  helperText={error?.type ? 'Insira um complemento válido' : ''}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined">
            <Controller
              control={control}
              rules={{ required: true, maxLength: 100 }}
              name="city"
              render={({ field, field: { value }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="city"
                  data-testid="city"
                  value={value || ''}
                  label="Cidade"
                  error={Boolean(error?.type)}
                  helperText={error?.type ? 'Insira uma cidade' : ''}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="guardian-state">Estado</InputLabel>
            <Controller
              rules={{ required: true, maxLength: 100, validate: validateSpecialCharactery }}
              control={control}
              name="state"
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <Select
                  {...rest}
                  labelId="guardian-state"
                  label="Estado"
                  id="guardian-state"
                  inputProps={{ 'data-testid': 'guardian-state-option' }}
                  value={value || ''}
                  onChange={currentValue => {
                    onChange(currentValue)
                  }}
                  error={Boolean(error?.type)}
                >
                  {UFs.map(state => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}
