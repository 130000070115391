import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .action-container {
    margin-top: ${props => props.theme.gravity.spacing[6]};
  }
`
