import { Box } from '@material-ui/core'
import { Badge } from '@gravity/badge'

import { LoadingState } from './components/LoadingState'

import { formatCurrencyValue } from '@/shared/utils/numberFormatters'
import { colors } from '@gravity/tokens'

import { Amount, TitleContainer, Title } from './styles'
import { getPayoutAmountLabel } from '../../utils/getPayoutAmountLabel'
import { IsaacPayToggleGroup } from '@/modules/isaacpay/pages/IsaacPayReport/components/IsaacPayToggleGroup'

const POSITIVE_VALUE_COLOR = colors['colors-text-main-2']
const NEGATIVE_VALUE_COLOR = colors['colors-background-semantic-colors-error-1']

type PayoutReportContentMainProps = {
  currentReportingPeriodView?: string
  defaultReportingPeriod?: string
  isLoading: boolean
  isPayoutOpen: boolean
  onChangeReportingPeriod?: (reportingPeriod: string) => void
  payoutSubtitleLabel?: string
  payoutValue: number
  shouldShowToggle?: boolean
}

/**
 * Component used to display payout report summary data on payout report pages
 *
 * @param props
 * @param props.payoutValue Payout value in decimal
 * @param props.isPayoutOpen Indicates if payout is open
 * @param props.isLoading Indicates if loading state should be displayed
 * @param props.payoutSubtitleLabel Payout label value for open and close instances
 */
export const PayoutReportContentMain = ({
  payoutValue,
  isPayoutOpen,
  isLoading,
  payoutSubtitleLabel,
  currentReportingPeriodView,
  defaultReportingPeriod,
  onChangeReportingPeriod,
  shouldShowToggle = false,
}: PayoutReportContentMainProps) => {
  const valueLabel = payoutSubtitleLabel ?? getPayoutAmountLabel(payoutValue < 0)

  if (isLoading) {
    return <LoadingState shouldShowToggle={shouldShowToggle} />
  }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="start">
      <Box>
        <Box display="flex" alignItems="center">
          {isPayoutOpen ? (
            <Badge variant="soft" color="warning">
              Aberto
            </Badge>
          ) : (
            <Badge variant="soft" color="success">
              Fechado
            </Badge>
          )}
        </Box>
        <TitleContainer>
          <Title variation="subtitleDesktopLarge" data-testid="payout-value-label">
            {valueLabel}
          </Title>
        </TitleContainer>

        <Amount
          style={{
            color: payoutValue >= 0 ? POSITIVE_VALUE_COLOR : NEGATIVE_VALUE_COLOR,
          }}
          data-testid="payout-report-value"
        >
          {formatCurrencyValue(payoutValue)}
        </Amount>
      </Box>

      {shouldShowToggle && (
        <Box display="flex" justifyContent="center">
          <IsaacPayToggleGroup
            value={currentReportingPeriodView}
            defaultReportingPeriod={defaultReportingPeriod}
            onChangeReportingPeriod={onChangeReportingPeriod}
          />
        </Box>
      )}
    </Box>
  )
}
