import styled from 'styled-components'
import { borderRadius, colors, spacing, fontSize } from '@gravity/tokens'

export const PaymentPlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spacing[8]};
`

export const Input = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  line-height: ${spacing[7]};
  padding: ${spacing[2]} ${spacing[2]};
  gap: ${spacing[2]};
  align-items: center;

  font-size: ${fontSize.base};
  color: ${colors['colors-text-main-4']};

  background: ${colors['colors-background-neutral-2']};
  border: 1px solid ${colors['colors-border-neutral-3']};
  border-radius: ${borderRadius[2]};
`
export const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing[6]};
  margin-top: ${spacing[2]};
`
export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]};
`
