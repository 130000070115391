export type StorageStrategy = 'localStorage' | 'sessionStorage'

export const storageSolutions: Record<StorageStrategy, Storage> = {
  localStorage: localStorage,
  sessionStorage: sessionStorage,
}

type SerializablePrimitives = string | number | boolean | null | undefined
export type Serializable = SerializablePrimitives | Record<string, unknown>

export interface StorageItem<T extends Serializable> {
  data: T
  timestamp_ms: number
}
