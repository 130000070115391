import {
  validateCpf,
  validateEmail,
  validatePhoneNumber,
  validateSpecialCharactery,
} from '@/shared/utils'
import { Box, FormControl, Grid } from '@material-ui/core'
import { TextField } from '@olaisaac/design-system'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { GuardianFormTitle } from '../../styles'

export const GuardianBasicFormInput = ({ control, isSameTaxId }) => {
  return (
    <Box mb={5}>
      <GuardianFormTitle>Dados do novo responsável</GuardianFormTitle>
      <Grid spacing={2} container>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              control={control}
              rules={{
                required: true,
                validate: value => {
                  return validateCpf(value) && !isSameTaxId()
                },
              }}
              name="taxId"
              render={({ field, fieldState: { error } }) => (
                <NumberFormat
                  {...field}
                  id="taxId"
                  customInput={TextField}
                  variant="outlined"
                  label="CPF"
                  format="###.###.###-##"
                  error={Boolean(error?.type)}
                  helperText={error?.type ? 'Insira um CPF válido' : ''}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={9}>
          <FormControl fullWidth variant="outlined">
            <Controller
              control={control}
              rules={{
                required: true,
                maxLength: 100,
                validate: validateSpecialCharactery,
              }}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="name"
                  label="Nome do responsável"
                  error={Boolean(error?.type)}
                  helperText={error?.type ? 'Insira um nome válido' : ''}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              control={control}
              rules={{ required: true, validate: validatePhoneNumber }}
              name="phoneNumber"
              render={({ field, fieldState: { error } }) => (
                <NumberFormat
                  {...field}
                  customInput={TextField}
                  id="phoneNumber"
                  variant="outlined"
                  label="Celular"
                  format="(##) #####-####"
                  error={Boolean(error?.type)}
                  helperText={error?.type ? 'Insira um número válido' : ''}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={9}>
          <FormControl fullWidth variant="outlined">
            <Controller
              control={control}
              rules={{ required: true, validate: validateEmail }}
              name="email"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  id="email"
                  label="Email"
                  error={Boolean(error?.type)}
                  helperText={error?.type ? 'Insira um e-mail válido' : ''}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}
