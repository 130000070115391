import { RouteDefinition } from '@/shared/core/router'
import { ManagementDashboards } from '../pages/ManagementDashboards'

export const routes: RouteDefinition[] = [
  {
    name: 'Painel de gestão',
    key: 'MANAGEMENT_DASHBOARD',
    path: `/painel-de-gestao`,
    component: ManagementDashboards,
    exact: true,
  },
]

export const routesMap = routes.reduce((routeMap, route) => {
  Object.assign(routeMap, { [route.name]: `/:schoolSlug${route.path}` })

  return routeMap
}, {})
