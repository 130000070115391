import { Button, Typography, DialogActions, DialogContent, Divider } from '@olaisaac/design-system'
import { Dialog, Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import type { PaymentDetailProps } from './types'
import { formatCentsToReal } from '@/shared/utils'
import {
  StyledDialogTitle,
  StyledIconButton,
  StyledToolTip,
  TextNegative,
  TextPositive,
} from './styles'

const PaymentDetail = ({ onClose, open, paidDate, info }: PaymentDetailProps) => {
  const { earlyDiscount, dueDiscount, fine, interest, originalAmount, addedDiscounts } = info

  const totalAmount =
    originalAmount - earlyDiscount - dueDiscount - addedDiscounts + fine + interest

  const getDiscountText = discount =>
    discount === 0 ? formatCentsToReal(discount) : formatCentsToReal(-discount)

  const helpText =
    'Descontos de pontualidade e antecipação são calculados automaticamente. Descontos adicionais são feitos antes do registro do recebimento. Nenhum desconto pode ser alterado no registro de recebimento.'

  return (
    <Box mt={4}>
      <Dialog open={open} onClose={() => onClose()} fullWidth>
        <StyledDialogTitle>
          <Typography variation="headlineDesktopSmall" style={{ margin: 0, marginBottom: -30 }}>
            Detalhes do valor da parcela
          </Typography>
          <StyledIconButton
            style={{ backgroundColor: 'transparent' }}
            onClick={onClose}
            data-testid="close-modal-button"
          >
            <CloseIcon />
          </StyledIconButton>
        </StyledDialogTitle>
        <DialogContent>
          <Typography variation="subtitleDesktopMedium">
            Para o recebimento do pagamento na data de <b>{paidDate?.format('DD/MM/YYYY')}</b> serão
            considerados para o cálculo do valor da parcela:
          </Typography>
          <Box mt={3} />
          <Box display="flex" width="100%" justifyContent="space-between">
            <Typography variation="bodyLarge" color="secondary">
              Valor padrão
            </Typography>
            <Typography variation="bodyLarge" color="secondary">
              {formatCentsToReal(originalAmount)}
            </Typography>
          </Box>
          <Box mt={2} />
          <Box display="flex" alignItems="center">
            <TextPositive variation="bodyLarge">
              <b>Descontos</b>
            </TextPositive>
            <StyledToolTip title={helpText}>
              <InfoOutlinedIcon fontSize="small" />
            </StyledToolTip>
          </Box>
          <Box mt={2} />
          <Box pl={5}>
            <Box display="flex" width="100%" justifyContent="space-between">
              <TextPositive variation="bodyLarge">Desconto de pontualidade</TextPositive>
              <TextPositive variation="bodyLarge">{getDiscountText(dueDiscount)}</TextPositive>
            </Box>
            <Box mt={2} />
            <Box display="flex" width="100%" justifyContent="space-between">
              <TextPositive variation="bodyLarge">Desconto de antecipação</TextPositive>
              <TextPositive variation="bodyLarge">{getDiscountText(earlyDiscount)}</TextPositive>
            </Box>
            <Box mt={2} />
            <Box display="flex" width="100%" justifyContent="space-between">
              <TextPositive variation="bodyLarge">Desconto adicional</TextPositive>
              <TextPositive variation="bodyLarge">{getDiscountText(addedDiscounts)}</TextPositive>
            </Box>
          </Box>
          <Box mt={2} />
          <Box display="flex" width="100%" justifyContent="space-between">
            <TextNegative variation="bodyLarge">Multa</TextNegative>
            <TextNegative variation="bodyLarge">{formatCentsToReal(fine)}</TextNegative>
          </Box>
          <Box mt={2} />
          <Box display="flex" width="100%" justifyContent="space-between">
            <TextNegative variation="bodyLarge">Juros</TextNegative>
            <TextNegative variation="bodyLarge">{formatCentsToReal(interest)}</TextNegative>
          </Box>
          <Box mt={2} />
          <Divider variation="secondary" />
          <Box mt={2} />
          <Box display="flex" width="100%" justifyContent="space-between">
            <Typography variation="subtitleDesktopLarge" color="primary">
              Valor esperado
            </Typography>
            <Typography variation="subtitleDesktopLarge" color="primary">
              {formatCentsToReal(totalAmount)}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variation="primary" onClick={onClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default PaymentDetail
