import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from 'src/shared/hooks'

import { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'

type ReportsQueryProps = {
  options?: UseQueryOptions<UnguaranteedReport[]>
  reportType?: string
  schoolId: string
}

export const useReportsQuery = ({ schoolId, options }: ReportsQueryProps) => {
  const { api } = useApi()

  return useQuery<UnguaranteedReport[]>(
    ['reports', schoolId, 'UNGUARANTEED'],
    async () => {
      const { data } = await api.report.fetchReportList({ schoolId, reportType: 'UNGUARANTEED' })
      return data
    },
    options
  )
}
