import { Product } from '@/shared/interfaces'

const sortDesc = (a: Product, b: Product): number => {
  return a.name > b.name ? -1 : 1
}

export const sortProducts = (products: Product[]): Product[] => {
  const regex = /^\d{4}/g
  const productsWithYearPrefix = products.filter(p => p.name.match(regex))
  const productsWithoutYearPrefix = products.filter(p => !p.name.match(regex))

  return [
    ...productsWithYearPrefix.sort(sortDesc),
    ...productsWithoutYearPrefix.sort(sortDesc).reverse(),
  ]
}
