import { CommonMetadata } from './metadata'
import { Category, EducationalSubstage } from './productClassification'
import { School } from './school'

/**
 * @deprecated Use src/modules/products/models/Product
 */
export type Product = CommonMetadata & {
  educational_substage?: EducationalSubstage
  educational_substage_id: string
  enrollment_amount: cents
  enrollment_installments_quantity: number
  envelope_template_referral_id?: string
  installments_quantity: number
  monthly_amount: cents
  name: string
  product_category?: Category
  product_category_id: string
  product_program_id: string
  school?: School
  school_id: School['id']
}

export const isProduct = (product: string | Product): product is Product =>
  !!(product as Product)?.id
