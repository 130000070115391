import { student } from '@/shared/mocks'
import {
  InstallmentStatus,
  InstallmentStatusDescription,
  InstallmentType,
  ReceivableType,
} from '../GuardianDetails/constants'
import { Installment } from '../GuardianDetails/types'

export const guardiansInstallmentsMock = () => {
  const baseInstallment = {
    amount: 100,
    backoffice_installment_id: 'f32dff2f-4e36-4787-92a4-6e6f0a1df9b6',
    contract_id: '999aff34-5202-4fa3-9ea6-cb79d0885279',
    credit_card_fee: 0,
    current_due_payment_discount: 0,
    current_early_payment_discount: 0,
    current_perpetual_discount: 0,
    is_from_credit_card_flow: false,
    original_amount: 100,
    product_name:
      'Fake Product asdfasd fads fasdf asdf asdf asdf adsf adf asdf asdf asdfasd afasdf asdf asd fasdfadsf adsf adsf as',
    receivable_id: '2a11e66e-3306-4663-8efd-e42f1d7f96e5',
    simulation_id: null,
    status_description: null,
    urn: 'urn:negotiation-payment-plan:{{negotiation_id}},{{payment_plan.index}}',
    student: {
      id: student.id,
      name: student.name,
    },
    invoice: {
      err_description: null,
      id: null,
    },
  } as Installment

  // pré-matricula receivable type == enrollment
  const enrollmentInstallment: Installment = {
    ...baseInstallment,
    competence_date: ['2023-01'],
    due_date: '2023-01-04T06:00:00.00Z',
    negotiation_id: null,
    order_reference: '1/1',
    overdue_for_days: null,
    paid_date: null,
    receivable_type: ReceivableType.ENROLLMENT,
    status: InstallmentStatus.OPEN,
    type: InstallmentType.INSTALLMENT,
  }

  // mensalidade - aguardando
  const awaitingInstallment: Installment = {
    ...baseInstallment,
    competence_date: ['2023-02'],
    due_date: '2023-02-04T06:00:00.00Z',
    negotiation_id: null,
    order_reference: '1/1',
    overdue_for_days: null,
    paid_date: null,
    receivable_type: ReceivableType.TUITION,
    status: InstallmentStatus.PENDING,
    status_description: InstallmentStatusDescription.PENDING_PAYMENT,
    type: InstallmentType.RECEIVABLE,
  }

  // mensalidade - aberta
  const openInstallment: Installment = {
    ...baseInstallment,
    competence_date: ['2023-03'],
    due_date: '2023-03-04T06:00:00.00Z',
    negotiation_id: null,
    order_reference: '1/1',
    overdue_for_days: null,
    paid_date: null,
    receivable_type: ReceivableType.TUITION,
    status: InstallmentStatus.OPEN,
    type: InstallmentType.INSTALLMENT,
  }

  // mensalidade - vencida
  const overdueInstallment: Installment = {
    ...baseInstallment,
    competence_date: ['2023-04'],
    due_date: '2023-04-04T06:00:00.00Z',
    negotiation_id: null,
    order_reference: '1/1',
    overdue_for_days: 10,
    paid_date: null,
    receivable_type: ReceivableType.TUITION,
    status: InstallmentStatus.OVERDUE,
    type: InstallmentType.INSTALLMENT,
  }

  // mensalidade - paga
  const paidInstallment: Installment = {
    ...baseInstallment,
    competence_date: ['2023-05'],
    due_date: '2023-05-04T06:00:00.00Z',
    negotiation_id: null,
    order_reference: '1/1',
    overdue_for_days: null,
    paid_date: '2023-04-02T00:00:00.00Z',
    receivable_type: ReceivableType.TUITION,
    status: InstallmentStatus.PAID,
    type: InstallmentType.INSTALLMENT,
  }

  // negotiation start
  const negotiationStartInstallment: Installment = {
    ...baseInstallment,
    competence_date: ['2023-05', '2023-06', '2023-07', '2023-08'],
    due_date: '2023-06-04T06:00:00.00Z',
    negotiation_id: 'a1cd0d99-8ec2-458f-9bd3-817cd6a525a4',
    order_reference: '1/2',
    overdue_for_days: null,
    paid_date: null,
    receivable_type: ReceivableType.NEGOTIATION,
    status: InstallmentStatus.OPEN,
    type: InstallmentType.DOWN_PAYMENT,
  }

  // negotiation - aguardando
  const negotiationAwaitingInstallment: Installment = {
    ...baseInstallment,
    amount: 300,
    competence_date: ['2023-05', '2023-06', '2023-07', '2023-08'],
    due_date: '2023-06-04T06:00:00.00Z',
    negotiation_id: 'a1cd0d99-8ec2-458f-9bd3-817cd6a525a4',
    order_reference: '1/2',
    overdue_for_days: null,
    paid_date: null,
    receivable_type: ReceivableType.NEGOTIATION,
    status: InstallmentStatus.PENDING,
    status_description: InstallmentStatusDescription.PENDING_DOWNPAYMENT,
    type: InstallmentType.RECEIVABLE,
  }

  // negotiation paid
  const negotiationStartPaidInstallment: Installment = {
    ...baseInstallment,
    competence_date: ['2023-09', '2023-10', '2023-11'],
    due_date: '2023-11-04T06:00:00.00Z',
    negotiation_id: '4a4a35d8-c99a-45b3-82bb-62399c514a70',
    order_reference: '1/2',
    overdue_for_days: null,
    paid_date: '2023-10-06T00:00:00.00Z',
    receivable_type: ReceivableType.NEGOTIATION,
    status: InstallmentStatus.PAID,
    type: InstallmentType.DOWN_PAYMENT,
  }

  // negotiation awaiting invoice generation
  const negotiationAwaitingInvoiceGenerationInstallment: Installment = {
    ...baseInstallment,
    competence_date: ['2023-09', '2023-10', '2023-11'],
    due_date: '2023-12-04T06:00:00.00Z',
    negotiation_id: '4a4a35d8-c99a-45b3-82bb-62399c514a70',
    order_reference: '2/2',
    overdue_for_days: null,
    paid_date: null,
    receivable_type: ReceivableType.NEGOTIATION,
    status: InstallmentStatus.PENDING,
    status_description: InstallmentStatusDescription.PENDING_INVOICE_GENERATION,
    type: InstallmentType.RECEIVABLE,
  }

  // negotiation - overdue
  const negotiationOverdueInstallment: Installment = {
    ...baseInstallment,
    competence_date: ['2024-01'],
    due_date: '2024-01-04T06:00:00.00Z',
    negotiation_id: '4a4a35d8-c99a-45b3-82bb-62399c514a70',
    order_reference: '1/1',
    overdue_for_days: 3,
    paid_date: null,
    receivable_type: ReceivableType.NEGOTIATION,
    status: InstallmentStatus.OVERDUE,
    status_description: InstallmentStatusDescription.NEGOTIATION_EXPIRED,
    type: InstallmentType.DOWN_PAYMENT,
  }

  const installmentForDrawer: Installment = {
    ...baseInstallment,
    backoffice_installment_id: '7d723d54-290f-4846-a4ae-0c53a3125cc2',
    contract_id: '4eb7953f-45b5-4d9e-baa5-038c4d39d86b',
    receivable_id: 'fa42b836-c004-4f89-a6f0-dba9067029aa',
    receivable_type: ReceivableType.TUITION,
    status: InstallmentStatus.OPEN,
    status_description: null,
    type: InstallmentType.INSTALLMENT,
    competence_date: ['2024-01'],
    due_date: '2024-01-04T06:00:00.00Z',
    order_reference: '1/1',
  }

  // mensalidade - aguardando gates
  const awaitingGatesInstallment: Installment = {
    ...baseInstallment,
    competence_date: ['2023-02'],
    due_date: '2023-02-04T06:00:00.00Z',
    negotiation_id: null,
    order_reference: '1/1',
    overdue_for_days: null,
    paid_date: null,
    receivable_type: ReceivableType.TUITION,
    status: InstallmentStatus.PENDING,
    status_description: InstallmentStatusDescription.PENDING_ENROLL_PROCESSING,
    type: InstallmentType.RECEIVABLE,
  }

  const downPaymentInstallment: Installment = {
    ...baseInstallment,
    competence_date: ['2023-02'],
    due_date: '2023-02-04T06:00:00.00Z',
    negotiation_id: '4a4a35d8-c99a-45b3-82bb-62399c514a70',
    order_reference: '1/1',
    overdue_for_days: null,
    paid_date: null,
    receivable_type: ReceivableType.TUITION,
    status: InstallmentStatus.OPEN,
    status_description: InstallmentStatusDescription.PENDING_PAYMENT,
    type: InstallmentType.DOWN_PAYMENT,
  }
  const installments = [
    enrollmentInstallment,
    awaitingInstallment,
    awaitingGatesInstallment,
    openInstallment,
    overdueInstallment,
    paidInstallment,
    negotiationStartInstallment,
    negotiationAwaitingInstallment,
    negotiationStartPaidInstallment,
    negotiationAwaitingInvoiceGenerationInstallment,
    negotiationOverdueInstallment,
    installmentForDrawer,
    downPaymentInstallment,
  ]

  return {
    data: installments,
  }
}
