import { StepItem } from './components/StepItem'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { mdiChevronLeft } from '@mdi/js'
import { Icon } from '@mdi/react'

import * as Styled from './styles'
import { StepperProps } from './types'
import { Status } from './components/StepItem/types'

export const Stepper = ({
  steps,
  title,
  handleStepClick,
  activeStep,
  stepsNavHistory,
  subtitle,
  onClickBackBtn,
}: StepperProps) => {
  const stepStatus = (stepIndex: number) => {
    switch (true) {
      case stepsNavHistory[stepIndex].completed:
        return Status.COMPLETED
      case stepIndex === activeStep:
        return Status.ACTIVE
      case stepsNavHistory[stepIndex].visited:
        return Status.ENABLED
      default:
        return Status.DISABLED
    }
  }

  const isStepActive = (stepIndex: number) => stepIndex === activeStep

  return (
    <Styled.Container>
      <Styled.ContainerWrapper>
        <Styled.ReturnButton
          iconStart={<Icon path={mdiChevronLeft} />}
          size={1}
          variant="ghost"
          onClick={onClickBackBtn}
        >
          Voltar
        </Styled.ReturnButton>
        <Heading variant="heading-h3-medium">{title}</Heading>
        {subtitle && (
          <Styled.SubtitleWrapper>
            <Text>{subtitle}</Text>
          </Styled.SubtitleWrapper>
        )}
        {steps.map((step, index) => (
          <StepItem
            key={index}
            label={step.label}
            status={stepStatus(index)}
            active={isStepActive(index)}
            onClick={() => handleStepClick(index)}
          />
        ))}
      </Styled.ContainerWrapper>
    </Styled.Container>
  )
}
