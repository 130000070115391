import styled from 'styled-components'

const HEADER_HEIGHT = '96px'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${HEADER_HEIGHT});
  padding: ${({ theme }) => `${theme.gravity.spacing[8]} ${theme.gravity.spacing[16]} 0`};

  .content {
    margin-top: ${({ theme }) => theme.gravity.spacing[8]};
    height: 100%;
  }
`
