import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'
import { colors, fontWeight, fontSize, lineHeight, letterSpacing } from '@gravity/tokens'
import { TextArea } from '@gravity/text-area'

export const LabelReason = styled(Typography)`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.base};
  font-style: normal;
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.normal};
  letter-spacing: ${letterSpacing.normal};
  margin-bottom: 0.25rem;
`

export const StyledTextArea = styled(TextArea)`
  width: 100%;
`

export const StyledDialogContent = styled.div`
  width: 100%;
`
