import { useQuery } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const useGetEnrollmentReportCampaignReport = () => {
  const { school } = useSelectedSchool()
  const { api } = useApi()

  return useQuery({
    queryKey: ['enrollment-campaign', school.id],
    queryFn: async () => {
      const { data } = await api.report.fetchEnrollmentCampaignReport({ id: school.id })

      return {
        url: `${data.url}#font=Roboto&bordered=false&titled=false`,
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
  })
}
