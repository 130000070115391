import { Container } from './styles'
import { PaymentDisputeSummaryCard } from '../PaymentDisputeSummaryCard'

export type PaymentDisputeSummaryProps = {
  loading?: boolean
  totalFinish?: number
  totalInReview?: number
  totalPending?: number
}

export const PaymentDisputeSummary = ({
  loading,
  totalFinish,
  totalInReview,
  totalPending,
}: PaymentDisputeSummaryProps) => {
  return (
    <Container>
      <PaymentDisputeSummaryCard loading={loading} status="pending" total={totalPending} />
      <PaymentDisputeSummaryCard loading={loading} status="in_review" total={totalInReview} />
      <PaymentDisputeSummaryCard loading={loading} status="finish" total={totalFinish} />
    </Container>
  )
}
