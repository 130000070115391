import { Text } from '@gravity/text'
import * as Styled from './styles'
import { IrregularStudentStatusFilter, irregularStatusList } from '../../types'
import { IrregularStatusFilterCard } from '../IrregularStatusFilterCard'
import { useIrregularStudentsEvents } from '../../hooks/useIrregularStudentsEvents'
import { IrregularStudentsFilterType } from '../../hooks/useIrregularStudentsFilter/types'

type IrregularStatusFilterProps = {
  irregularStudentsFilter: IrregularStudentsFilterType
  updateFilter: (updatedFilter?: IrregularStudentsFilterType) => void
}

export const IrregularStatusFilter = ({
  irregularStudentsFilter,
  updateFilter,
}: IrregularStatusFilterProps) => {
  const { sendClickStatusFilterBadgeEvent } = useIrregularStudentsEvents()
  return (
    <Styled.Container>
      <Text variant="body-1-regular">Filtrar por:</Text>
      {irregularStatusList.map(status => {
        const statusFilter = (status.toUpperCase() as unknown) as IrregularStudentStatusFilter
        const isSelected = irregularStudentsFilter.irregularStatus.includes(statusFilter)
        return (
          <Styled.StatusContainer
            key={status}
            onClick={() => {
              const activeFilters = irregularStudentsFilter.irregularStatus
              let nextFilters = [...activeFilters, statusFilter]

              if (activeFilters.includes(statusFilter)) {
                nextFilters = activeFilters.filter(e => e !== statusFilter)
              } else {
                sendClickStatusFilterBadgeEvent(status)
              }

              updateFilter({
                ...irregularStudentsFilter,
                irregularStatus: nextFilters,
              })
            }}
          >
            <IrregularStatusFilterCard status={status} isSelected={isSelected} />
          </Styled.StatusContainer>
        )
      })}
    </Styled.Container>
  )
}
