import { getParamGuardianId } from '../../utils/getParamGuardianId'
import { useGuardianQuery } from '../../hooks/useGuardianQuery'

import { useEnrollmentCycle } from '@/modules/enrollment/hooks/queries/useEnrollmentCycle'
import { DEFAULT_INITIAL_PAGE, DEFAULT_ITEMS_PER_PAGE } from './constants'
import { useSchoolEnrollmentCycleContext } from '@/modules/enrollment/contexts/SchoolEnrollmentContext'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { ENROLLMENT_YEAR } from '@/modules/enrollment/constants/enrollment'
import { StudentsCarouselContainer } from './components/StudentsCarouselContainer'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const StudentsCarousel = () => {
  const { school } = useSelectedSchool()
  const guardianId = getParamGuardianId()

  const { guardian, isFetchGuardianLoading } = useGuardianQuery({ guardianId, schoolId: school.id })

  const studentIds = guardian?.data?.students?.map(s => s.id)

  const isSelectCyclesEnabled = useUnleashFlag(UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES)

  const { selectedEnrollmentCycle, isFetchingCycles } = useSchoolEnrollmentCycleContext()
  const selectedYear = isSelectCyclesEnabled
    ? selectedEnrollmentCycle?.reference_year
    : ENROLLMENT_YEAR

  const referenceYears = selectedYear ? [selectedYear, selectedYear - 1].map(String) : []
  const { data: students, isLoading: isLoadingStudents } = useEnrollmentCycle(
    {
      schoolId: school.id,
      studentIds,
      referenceYears,
      pagination: {
        page: DEFAULT_INITIAL_PAGE,
        per_page: DEFAULT_ITEMS_PER_PAGE,
      },
    },
    { enabled: studentIds?.length > 0 && referenceYears.length > 0 }
  )

  const isLoading = isFetchGuardianLoading || isLoadingStudents || isFetchingCycles

  return <StudentsCarouselContainer students={students?.data} isLoading={isLoading} />
}
