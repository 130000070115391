import { AxiosInstance } from 'axios'
import qs from 'qs'
import {
  LegacyContractsReturn,
  UseCanAgglutinateQueryResponse,
  UseLegacyContractsProps,
  useCanAgglutinateQueryProps,
  useGetReceivableByIDQueryProps,
} from './types'
import { getContractsFromMatriculasApi } from '@/escolas/router/responsavel/[guardianId]/contratos/utils/getContracts'
import { APIResponse, Receivable } from '@/shared/interfaces'

/**
 * Fetch the legacy endpoint contracts
 *
 * @param api Api provided by useApi
 * @param props.schoolId School ID
 * @param props.guardianId Guardian ID
 *
 * @returns allBufferInstallments, allContracts and installmentsByContract arrays
 */
export const fetchLegacyContracts = async (
  api,
  { schoolId, guardianId }: UseLegacyContractsProps
) => {
  const allContractsByYearPromise = [2021, 2022, 2023, 2024].map(referenceYear => {
    return getContractsFromMatriculasApi(api, guardianId, referenceYear, schoolId)
  })

  const responses = await Promise.all(allContractsByYearPromise)

  const legacyContracts = responses.reduce<LegacyContractsReturn>(
    (prev, curr) => {
      prev.allBufferInstallments = [...prev.allBufferInstallments, ...curr.allBufferInstallments]
      prev.allContracts = [...prev.allContracts, ...curr.allContracts]
      prev.installmentsByContract = [...prev.installmentsByContract, ...curr.installmentsByContract]
      return prev
    },
    {
      allBufferInstallments: [],
      allContracts: [],
      installmentsByContract: [],
    }
  )

  return legacyContracts
}

/**
 * Check if two - or more - receivables can be agglutinated
 *
 * @param service Api provided by useApi hook
 * @param params.schoolId School ID
 * @param params.receivables array of receivables IDs
 *
 * @returns an object containing a boolean - which indicates whether or not it is possible to agglutinate the receivables - and a reason if it is not possible to agglutinate them
 */
export const checkCanAgglutinate = async (
  service: AxiosInstance,
  params: useCanAgglutinateQueryProps
): Promise<UseCanAgglutinateQueryResponse> => {
  const { data } = await service.get<APIResponse<UseCanAgglutinateQueryResponse>>(
    `/receivable/can-agglutinate`,
    {
      params: { ...params },
      paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma', skipNulls: true }),
    }
  )
  return data.data
}

/**
 * Get receivable by ID
 *
 * @param service Api provided by useApi hook
 * @param receivableID
 * @param params all optional query params to include more properties and objects into response
 *
 * @returns an receivable containing all properties
 */
export const getReceivableByID = async (
  service: AxiosInstance,
  receivableID: uuid,
  params: useGetReceivableByIDQueryProps
): Promise<Receivable> => {
  const { data } = await service.get<APIResponse<Receivable>>(`/receivable/${receivableID}`, {
    params: { ...params },
    paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma', skipNulls: true }),
  })
  return data.data
}
