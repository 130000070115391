import { useHistory } from 'react-router-dom'

import AddIcon from '@material-ui/icons/Add'
import { ArrowBack } from '@material-ui/icons'

import { Button } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { Container, ButtonGroup } from './styles'
import { CardDetailsHeaderProps } from './types'
import { CURRENT_YEAR } from '@/escolas/components/contract/constants'
import { GUARDIAN_ID_QUERY_PARAM } from '@/escolas/components/contract/create/constants'
import useAddContractEnabled from '@/escolas/hooks/useAddContractEnabled'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

const GuardianDetailsHeaderSkeleton = ({
  isAddContractButtonEnabled,
}: {
  isAddContractButtonEnabled: boolean
}) => {
  return (
    <Container>
      <Button variation="ghost" startIcon={<ArrowBack />} disabled>
        Voltar
      </Button>
      <ButtonGroup>
        <Button variation="ghost" disabled>
          Editar cadastro
        </Button>
        {isAddContractButtonEnabled && (
          <Button variation="primary" startIcon={<AddIcon />} disabled>
            Adicionar contrato
          </Button>
        )}
      </ButtonGroup>
    </Container>
  )
}

export const GuardianDetailsHeader: React.FC<CardDetailsHeaderProps> = ({
  schoolSlug,
  guardianId,
  isLoading,
  openEditRegistrationDrawer,
}) => {
  const history = useHistory()
  const isAddContractButtonEnabled = useAddContractEnabled()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const sendEvent = (scope: EventDispatcherEventScopes, name: EventDispatcherEvents) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope,
        name,
        action: 'click',
      })
  }

  if (isLoading) {
    return <GuardianDetailsHeaderSkeleton isAddContractButtonEnabled={isAddContractButtonEnabled} />
  }

  const onClickAddContractBtn = () => {
    sendEvent(EventDispatcherEventScopes.GUARDIANS, EventDispatcherEvents.CREATE_CONTRACT_GUARDIAN)

    const path = `/${schoolSlug}/contratos/${CURRENT_YEAR}`
    const params = new URLSearchParams()
    params.append(GUARDIAN_ID_QUERY_PARAM, guardianId)

    history.push({
      pathname: `${path}/novo`,
      search: params.toString(),
    })
  }

  return (
    <Container>
      <Button
        variation="ghost"
        style={{ paddingLeft: 0 }}
        startIcon={<ArrowBack />}
        onClick={() => history.push(`/${schoolSlug}/responsaveis`)}
        data-testid="backButton"
      >
        Voltar
      </Button>
      <ButtonGroup>
        <Button
          variation="ghost"
          onClick={() => {
            sendEvent(
              EventDispatcherEventScopes.GUARDIANS,
              EventDispatcherEvents.PERSONAL_DATA_CHANGE
            )
            openEditRegistrationDrawer()
          }}
          data-testid="guardianRegistrationViewButton"
        >
          Editar cadastro
        </Button>
        {isAddContractButtonEnabled && (
          <Button
            variation="primary"
            startIcon={<AddIcon />}
            onClick={() => onClickAddContractBtn()}
            data-testid="guardianAddContractButton"
          >
            Adicionar contrato
          </Button>
        )}
      </ButtonGroup>
    </Container>
  )
}
