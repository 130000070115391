import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'
import { Card } from '@gravity/card'
import { spacing, colors, fontWeight, fontSize, letterSpacing, borderRadius } from '@gravity/tokens'
import { Box } from '@material-ui/core'

export const ContainerTuitionMainContent = styled(Box)<{ $maxWidth?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: ${props => (props.$maxWidth ? '100%' : '34.75rem')};
  @media screen and (max-width: 1112px) {
    justify-content: flex-start;
    align-items: left;
    display: grid;
    grid-template-columns: 1fr;
  }
`

export const PayoutCardContainer = styled(Card)`
  display: flex;
  width: 60%;
  height: 318px;
  padding: ${spacing[8]};
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: ${borderRadius[4]};
  border: 1px solid ${colors['colors-border-neutral-3']};
  @media screen and (max-width: 1112px) {
    width: 100%;
  }
`

export const ContainerGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-2, 0.5rem);
`

export const Amount = styled.p`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize['3xl']};
  font-style: normal;
  font-weight: ${fontWeight.medium};
  line-height: 120%;
`

export const ContainerTitle = styled.div`
  display: flex;
  width: 29.75rem;
  height: 2.5rem;
  align-items: center;
`

export const Title = styled(Typography)`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.lg};
  font-style: normal;
  font-weight: ${fontWeight.medium};
  line-height: 125%;
`

export const DescriptionAmount = styled(Typography)`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.sm};
  font-style: normal;
  font-weight: ${fontWeight.normal};
  line-height: 150%;
  letter-spacing: ${letterSpacing.tight};
`

export const StrongDescription = styled.strong`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.sm};
  font-style: normal;
  font-weight: ${fontWeight.medium};
  line-height: 150%;
  letter-spacing: ${letterSpacing.tight};
`
