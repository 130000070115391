import { Box } from '@material-ui/core'
import styled from 'styled-components'

export const AvailableMethod = styled(Box)`
  display: flex;
  margin-bottom: 4px;
  align-items: center;
`

export const StyledImg = styled.img`
  margin-right: 8px;
`

export const StyledImgWithPadding = styled(StyledImg)`
  padding: 1.5px 1px;
`
