import styled from 'styled-components'
import { spacing } from '@gravity/tokens'

export const BigNumberSkeleton = styled.div`
  flex: 1;
  & > :first-child {
    width: 25%;
    margin-bottom: ${spacing[2]};
  }
  & > :nth-child(2) {
    width: 50%;
  }
`
