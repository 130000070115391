import { StyledSeparator } from './styles'
import { Separator } from '@gravity/separator'

export const SeparatorLine = () => {
  return (
    <StyledSeparator>
      <Separator color="neutral-2" orientation="horizontal" />
    </StyledSeparator>
  )
}
