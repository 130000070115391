import { spacing } from '@gravity/tokens'
import styled from 'styled-components'

export const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]};
`

export const RowContainer = styled.div`
  max-width: 1366px;

  @media screen and (min-width: 1440px) {
    margin-left: auto;
    margin-right: auto;
  }
`

export const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[2]};
  margin-bottom: 25px;
`

export const TitleSkeletonContainer = styled(ColumnContainer)`
  margin-top: 33px;
  margin-bottom: 53px;
`

export const PairSkeletonContainer = styled(ColumnContainer)`
  margin-bottom: 25px;
`
