export enum GuardianDetailsTabs {
  CONTRATOS = 'contratos',
  PARCELAS = 'parcelas',
  VISAO_GERAL = 'visao_geral',
}

export enum InstallmentStatus {
  AGGLUTINATED = 'AGGLUTINATED',
  CANCELED = 'CANCELED',
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PAID_AND_CANCELED = 'PAID_AND_CANCELED',
  RENEGOTIATED = 'RENEGOTIATED',
}

export const MIN_RECEIVABLES_ALLOWED = 2

export const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 5
export const DEFAULT_QUERY_RETRY_COUNT = 2

export const guardianDetailsQueries = [
  'guardian-details-installments',
  'guardian-details-contracts',
]
