import { useContextSimulation } from '@/modules/credit/contexts/PayoutAnticipationContext'
import {
  BigValueText,
  BoldKeyText,
  BoldValueText,
  ContainerCard,
  KeyText,
  ResultAnticipationContainer,
  RowContainer,
  ValueText,
} from './styles'
import { AnticipationSummaryProps } from './types'
import { Separator } from '@gravity/separator'
import { dateToFormattedString } from '@/modules/credit/utils/formatDate'

const PayoutAnticipationSummaryCard = ({ requestValue, offer }: AnticipationSummaryProps) => {
  const { reason } = useContextSimulation()

  return (
    <ContainerCard>
      <RowContainer>
        <KeyText>Motivo selecionado</KeyText>
        <ValueText>{reason.label}</ValueText>
      </RowContainer>

      <RowContainer>
        <KeyText>Valor simulado</KeyText>
        <ValueText>{requestValue}</ValueText>
      </RowContainer>

      <Separator color="neutral-2" orientation="horizontal" />
      <ResultAnticipationContainer>
        <RowContainer>
          <BoldKeyText>Valor a receber</BoldKeyText>
          <BigValueText>{requestValue}</BigValueText>
        </RowContainer>

        <RowContainer>
          <KeyText>Data de recebimento</KeyText>
          <ValueText>{offer.disbursementDate}</ValueText>
        </RowContainer>

        <RowContainer>
          <KeyText>{`Deságio (${offer.appliedFee}% a.m.)`}</KeyText>
          <ValueText>{offer.feeValue}</ValueText>
        </RowContainer>

        <RowContainer>
          <KeyText>Valor que será descontado</KeyText>
          <ValueText>{offer.totalAmount}</ValueText>
        </RowContainer>

        <RowContainer>
          <BoldKeyText>Valor descontado no repasse de</BoldKeyText>
          <BoldValueText>{dateToFormattedString(offer.discountDate)}</BoldValueText>
        </RowContainer>
      </ResultAnticipationContainer>
    </ContainerCard>
  )
}

export default PayoutAnticipationSummaryCard
