import axios from 'axios'

type IsValidZipCodeResponse = Partial<{
  cep: string
  city: string
  neighborhood: string
  service: string
  state: string
  street: string
  type: string
}>

export const external = {
  zipCode: {
    isValid: async (zipcode: cep) => {
      const response = await axios.get<IsValidZipCodeResponse>(
        `https://brasilapi.com.br/api/cep/v2/${zipcode}`,
        {
          withCredentials: false,
        }
      )

      return response.status === 200
    },
  },
}
