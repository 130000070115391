import theme from '@/shared/theme'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Button, Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

const { spacing, colors } = theme.primitiveTokens

export type InvoicesNotGeneratedDialogProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: calc(${spacing[7]} + 2px);
  right: ${spacing[7]};
  padding: 0;
  color: ${colors.gray[500]};
`

const StyledTypography = styled(Typography)`
  margin-bottom: ${spacing[4]};
`

export const InvoicesNotGeneratedDialog = (props: InvoicesNotGeneratedDialogProps) => {
  const { isOpen, setIsOpen } = props

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <DialogTitle
        style={{ padding: spacing[6], paddingBottom: spacing[3] }}
        data-testid="titulo-modal-faturas-contrato-nao-geradas"
      >
        <Typography variation="headlineDesktopSmall" style={{ padding: 0 }}>
          Faturas do contrato não geradas
        </Typography>
        <StyledIconButton aria-label="close" onClick={() => setIsOpen(false)}>
          <Close style={{ color: '#838383' }} />
        </StyledIconButton>
      </DialogTitle>
      <DialogContent>
        <StyledTypography>
          As faturas desse contrato ainda não foram geradas por isso não é possível realizar essa
          operação.
        </StyledTypography>
        <StyledTypography>
          Verifique se algum dos itens abaixo estão corretos nesse contrato:
        </StyledTypography>
        <StyledTypography>
          <ul style={{ listStylePosition: 'inside' }}>
            <li>CEP</li>
            <li>CPF</li>
            <li>Data de vencimento</li>
          </ul>
        </StyledTypography>
      </DialogContent>
      <DialogActions style={{ borderTop: '1px solid #dadada' }}>
        <Button
          variation="primary"
          onClick={() => setIsOpen(false)}
          data-testid="botao-fechar-modal"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
