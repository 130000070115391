export enum columnNames {
  GUARDIAN_NAME = 'guardian_name',
  PRODUCT_NAME = 'product_name',
  STUDENT_NAME = 'student_name',
  STUDENT_STATUS = 'student_status',
}

type ColumnName = `${columnNames}`

export type TableRowProps = {
  columns: Record<columnNames, string>
  hasStartedEnrollment: boolean
  isReenrollable: boolean
  key: string
  otherProducts: string[]
  studentID: string
}

export type ColumnProps = {
  key: ColumnName
  label: string
  minWidth: string
  sortable?: boolean
}
