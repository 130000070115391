import type { AxiosInstance } from 'axios'

import type { FetchSchoolResponseDTO } from './types'

export const schoolsService = (fetcher: AxiosInstance) => {
  return {
    /**
     * TODO: Listar apenas escolas que o usuário tem acesso
     *
     * Function responsible for fetch a school for ID
     *
     * @param schoolId School ID
     *
     * @returns School
     */
    fetchSchoolById: async (schoolId: string): Promise<FetchSchoolResponseDTO> => {
      const { data } = await fetcher.get<FetchSchoolResponseDTO>(`school/${schoolId}`)

      return data
    },

    /**
     * TODO: Listar apenas escolas que o usuário tem acesso
     *
     * Function responsible for fetch a school for slug
     *
     * @param schoolId School slug
     *
     * @returns School
     */
    fetchSchoolBySlug: async (schoolSlug: string): Promise<FetchSchoolResponseDTO> => {
      const { data } = await fetcher.get<FetchSchoolResponseDTO>(`school/slug/${schoolSlug}`)

      return data
    },
  }
}
