import styled from 'styled-components'
import { Box } from '@material-ui/core'
import theme from '@/shared/theme'
import { Typography } from '@olaisaac/design-system'

const { spacing } = theme.primitiveTokens
const { typography } = theme.humanizedTokens.components

export const Container = styled(Box)`
  display: flex;
  margin: ${spacing[5]} 0;
`
export const ActiveFiltersText = styled(Typography)`
  ${typography.variation.caption};
  ${typography.color.accent};
  margin-right: ${spacing[6]};
  min-width: fit-content;
`

export const ActiveFiltersContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
`

export const ClearFiltersContainer = styled.div`
  margin-right: ${spacing[6]};
`
