import styled from 'styled-components'
import { spacing, borderRadius, colors } from '@gravity/tokens'
import { IconButton as DSIconButton } from '@gravity/icon-button'
import { Text as DSText } from '@gravity/text'

export const CardContainer = styled.div`
  display: flex;
  width: 336px;
  min-width: 336px;
  align-items: center;
  padding: ${spacing[4]};
  gap: ${spacing[4]};

  border-radius: ${borderRadius[2]};
  border: 1px solid ${colors['colors-border-neutral-2']};
  background: ${colors['colors-background-neutral-1']};

  :hover {
    background: ${colors['colors-interaction-secondary-2']};
  }

  :first-of-type {
    margin-left: ${spacing[16]};
  }

  :last-of-type {
    margin-right: ${spacing[16]};
  }
`

export const CardAvatar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: ${spacing[10]};
  height: ${spacing[10]};
  border-radius: ${borderRadius[2]};
  background: ${colors['colors-interaction-secondary-2']};
`

interface IconProps {
  cursor?: 'pointer' | 'default'
}

export const IconButton = styled(DSIconButton)<IconProps>`
  cursor: ${({ cursor }) => cursor ?? 'pointer'};
  ${props => `visibility: ${props.hidden ? 'hidden' : 'visible'}`};
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${spacing[1]};
  flex: 1 0 0;
`

export const Text = styled(DSText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`
