import { Guardian } from '@/shared/interfaces'

export const getFirstAndLastName = (fullName: string): string => {
  const names = fullName.split(' ')

  if (names.length > 2) {
    return `${names[0]} ${names[names.length - 1]}`
  }

  return fullName
}

export const formatGuardians = (guardians: Pick<Guardian, 'name'>[]): string => {
  return guardians.map(g => getFirstAndLastName(g.name)).join(', ')
}
