import { useState } from 'react'
import { Tooltip } from '@material-ui/core'
import { SyncRounded } from '@material-ui/icons'
import { IconButton } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { ReenrollmentStatus } from '@/shared/interfaces'

type Props = {
  refreshSituation: () => Promise<void>
  status?: ReenrollmentStatus
}
export const RefreshSituationButton = ({ status, refreshSituation }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const showRefreshButton = (status: ReenrollmentStatus): boolean => {
    return status !== ReenrollmentStatus.ENROLLED
  }
  const handleRefresh = async (e: React.SyntheticEvent) => {
    e.stopPropagation()
    setIsLoading(true)

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
        name: EnrollmentEventDispatcherEvents.CLICKED,
        action: 'click',
        customProperties: { $Button_name: 'ATUALIZAR_SITUAÇÃO', $value: status },
      })

    try {
      await refreshSituation()
    } finally {
      setIsLoading(false)
    }
  }

  if (!status) return null
  if (!showRefreshButton(status)) {
    return null
  }

  return (
    <Tooltip title="Utilize caso tenha recebido algum pagamento que pode alterar a situação de matrícula do aluno. Para pagamentos em boleto, o tempo de compensação pode ser até 3 dias.">
      <IconButton size="small" variation="ghost" loading={isLoading} onClick={handleRefresh}>
        <SyncRounded />
      </IconButton>
    </Tooltip>
  )
}
