import { Controller, UseFormReturn } from 'react-hook-form'
import { Radio, Typography } from '@olaisaac/design-system'
import { Box, FormControl, RadioGroup } from '@material-ui/core'

import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'

import { EditAvailabilityWarning } from '../EditAvailabilityWarning/EditAvailabilityWarning'
import { StyledFormControlLabel, StyledTypography } from './style'

export type SelectReasonProps = {
  form: UseFormReturn<any>
  isContractInFlexiblePeriod: boolean
}

const SelectReason = ({ form, isContractInFlexiblePeriod }: SelectReasonProps) => {
  const { control } = form
  const { isAdmin } = useJWT()
  const isNewContractEditRulesEnabled =
    useUnleashFlag(UnleashFlags.ENABLE_NEW_RULES_FOR_SCHOOL_CONTRACT_EDITS) && !isAdmin

  return (
    <>
      <EditAvailabilityWarning
        isContractInFlexiblePeriod={isContractInFlexiblePeriod}
        isNewContractEditRulesEnabled={isNewContractEditRulesEnabled}
      />
      <div data-testid="select-reason-edit-due-day">
        <Box mb={3} mt={3}>
          <Typography variation="subtitleDesktopLarge">Selecione o motivo da edição:</Typography>
        </Box>
        <FormControl component="fieldset">
          <Controller
            rules={{ required: true }}
            control={control}
            name="change_due_month"
            defaultValue=""
            render={({ field }) => (
              <RadioGroup aria-label="change_due_month" {...field}>
                <StyledFormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Solicitação do responsável"
                />
                <Box pl={4} pb={2}>
                  <StyledTypography variation="caption" color="secondary">
                    Repasse não sofre alteração
                  </StyledTypography>
                </Box>
                <StyledFormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Correção de contrato"
                />
                <Box pl={4}>
                  <StyledTypography variation="caption" color="secondary">
                    O repasse será alterado
                  </StyledTypography>
                </Box>
              </RadioGroup>
            )}
          />
        </FormControl>
      </div>
    </>
  )
}

export default SelectReason
