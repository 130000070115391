import { useEffect, useState } from 'react'

import { PageWrapper } from '@/escolas/components/PageWrapper'

import { useScrollToElement } from '@/escolas/hooks'

import { Select } from '@gravity/select'
import { Button } from '@gravity/button'

import {
  mdiArchiveOutline,
  mdiBookOpenPageVariantOutline,
  mdiCalendarBlank,
  mdiPlus,
} from '@mdi/js'
import Icon from '@mdi/react'

import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { UserGuidingGuidesIds, useUserGuidingTrigger } from '@/shared/hooks/useUserGuidingTrigger'

import { ErrorDialog } from '@/modules/enrollment/components/ErrorDialog'
import { Search } from '@/modules/enrollment/pages/SchoolEnrollmentCycle/components/TableSearch'
import { FiltersDialog } from '@/shared/components/FiltersDialog'
import { BigNumbersGroup } from './components/BigNumbersGroup'

import { useIsFirstRender } from '@/shared/hooks/useIsFirstRender'
import { useQuery } from '@/shared/hooks/useQuery'
import { useLayout } from '@/shared/hooks/useLayout'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import * as Styled from './styles'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { EnrollmentTable } from './components/EnrollmentTable'
import { useEnrollmentFilters } from './hooks/useFiltersActions'
import { useEnrollmentData } from './hooks/useEnrollmentData'
import { useEnrollmentYear } from './hooks/useEnrollmentYear'
import { useEnrollmentActions } from './hooks/useEnrollmentActions'
import { Skeleton } from '@gravity/skeleton'

export const SchoolEnrollmentCycle = () => {
  const isFirstRender = useIsFirstRender()
  const [name, setName] = useState<string>('')
  const { school } = useSelectedSchool()
  const schoolId = school?.id ?? ''

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const isScoreFFEnabled = useUnleashFlag(UnleashFlags.MAT_616_ENABLE_ISAAC_SCORE)

  const {
    selectedYear,
    selectedCycle,
    years,
    isCampaignButtonEnabled,
    handleChangeSelectedYear,
  } = useEnrollmentYear()

  useSetPageTitle(`Matrículas ${selectedYear} - isaac`)

  const { setOnQueryParam } = useQuery()

  const { canUseAutoTrigger } = useUserGuidingTrigger()

  const {
    isFilterDialogOpen,
    statusOptions,
    subStatusOptions,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    handleClearFilter,
    handleRemoveFilter,
    onSubmitFilters,
    filtersArray,
    filtersGroup,
    studentsEnrollmentsFilter,
    form,
    setValue,
  } = useEnrollmentFilters(selectedYear)

  const {
    enrollmentsData,
    dataSummary,
    pagination,
    updatePaginationValue,
    isLoading: isEnrollmentDataLoading,
    isLoadingSummary,
    isError,
    isErrorSummary,
  } = useEnrollmentData({
    schoolId,
    selectedYear,
    name,
    studentsEnrollmentsFilter,
  })

  const {
    goToCampaignsPage,
    handleAddStudent,
    addContractEnabled,
    goToScorePage,
  } = useEnrollmentActions(selectedCycle)

  useEffect(() => {
    if (isFirstRender) return

    if (name || studentsEnrollmentsFilter) {
      updatePaginationValue('page', 1)
    }
  }, [name, studentsEnrollmentsFilter])

  const guideId = isIntegratedSchool
    ? UserGuidingGuidesIds.ENROLLMENT_PAGE_INTEGRATED_SCHOOL
    : UserGuidingGuidesIds.ENROLLMENT_PAGE

  useEffect(() => {
    if (canUseAutoTrigger) {
      setOnQueryParam(guideId, 'guide')
    }
  }, [isIntegratedSchool])

  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Matrículas' })

  useScrollToElement({
    ready: !isEnrollmentDataLoading && !isLoadingSummary && !isErrorSummary && !isError,
  })

  useEffect(() => {
    setValue(
      'enrollment_status',
      statusOptions.map(status => ({
        ...status,
        selected: studentsEnrollmentsFilter.enrollmentStatus.includes(status.value),
      }))
    )

    setValue(
      'enrollment_sub_status',
      subStatusOptions.map(subStatusOption => ({
        ...subStatusOption,
        selected: studentsEnrollmentsFilter.enrollmentSubStatus.includes(subStatusOption.value),
      }))
    )
  }, [studentsEnrollmentsFilter, statusOptions, subStatusOptions, setValue])
  return (
    <PageWrapper>
      <Styled.PageContainer>
        <ErrorDialog isError={isError || isErrorSummary} />
        <Styled.YearAndCampaignWrapper>
          <Styled.ReferenceYear>
            <Heading variant="heading-h2-medium">Ano letivo</Heading>
            <Select
              size={3}
              options={years.map(year => ({
                label: year.toString(),
                value: year.toString(),
              }))}
              value={selectedYear.toString()}
              onValueChange={evt => handleChangeSelectedYear(Number(evt))}
              icon={<Icon path={mdiCalendarBlank} />}
            />
          </Styled.ReferenceYear>
          {!isLoadingSummary ? (
            isCampaignButtonEnabled && (
              <Button
                variant="outline"
                iconStart={<Icon path={mdiBookOpenPageVariantOutline} />}
                onClick={goToCampaignsPage}
              >
                Gerenciar campanhas
              </Button>
            )
          ) : (
            <Skeleton width={223} height={40} />
          )}
        </Styled.YearAndCampaignWrapper>
        <BigNumbersGroup data={dataSummary} isLoading={isLoadingSummary} isError={isErrorSummary} />
        <Styled.TotalAndContractActions>
          {!isLoadingSummary && !isErrorSummary ? (
            <Text variant="body-1-medium">{`Total de alunos: ${dataSummary?.total}`}</Text>
          ) : (
            <Skeleton width={157} height={40} />
          )}
          <Styled.ScoreAndContract>
            {!isLoadingSummary ? (
              isScoreFFEnabled && (
                <Button
                  variant="outline"
                  iconStart={<Icon path={mdiArchiveOutline} />}
                  onClick={goToScorePage}
                >
                  Score isaac
                </Button>
              )
            ) : (
              <Skeleton width={138} height={40} />
            )}
            {!isLoadingSummary ? (
              addContractEnabled && (
                <Button
                  variant="solid"
                  iconStart={<Icon path={mdiPlus} />}
                  onClick={handleAddStudent}
                >
                  Novo aluno
                </Button>
              )
            ) : (
              <Skeleton width={138} height={40} />
            )}
          </Styled.ScoreAndContract>
        </Styled.TotalAndContractActions>
        <Search value={name} setValue={setName} placeholder="Busque por aluno ou responsável" />
        <EnrollmentTable
          enrollmentsData={enrollmentsData}
          isLoading={isEnrollmentDataLoading}
          updatePaginationValue={updatePaginationValue}
          pagination={pagination}
          handleOpenFilter={handleOpenFilterDialog}
          handleRemoveFilter={handleRemoveFilter}
          enrollmentYear={selectedCycle}
          filtersArray={filtersArray}
        />
      </Styled.PageContainer>
      <FiltersDialog
        form={form}
        filters={filtersGroup}
        onApplyFilters={onSubmitFilters}
        closeDialog={handleCloseFilterDialog}
        visible={isFilterDialogOpen}
        resetValues={handleClearFilter}
      />
    </PageWrapper>
  )
}
