import {
  useMutateOnCreateProduct,
  useMutateOnUpdateProduct,
} from '@/modules/products/hooks/queries/products'
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { Divider, Typography } from '@olaisaac/design-system'
import { forwardRef, memo, useCallback } from 'react'
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useProductClassifications } from '@/modules/products/hooks/useProductClassifications'
import { Product, School } from 'src/shared/interfaces'
import { Category, EducationalStage } from 'src/shared/interfaces/productClassification'
import { formatCentsToReal } from 'src/shared/utils'
import { validate as isValidUUID } from 'uuid'
import { useFormValues } from './hooks/useFormValues'

export type Props = {
  onApiError?: () => void
  onFormError?: SubmitErrorHandler<Product>
  onSuccess?: () => void
  product?: Pick<
    Product,
    | 'product_category_id'
    | 'product_program_id'
    | 'educational_substage_id'
    | 'id'
    | 'name'
    | 'enrollment_amount'
    | 'enrollment_installments_quantity'
    | 'monthly_amount'
    | 'installments_quantity'
    | 'envelope_template_referral_id'
    | 'educational_substage'
  >
  schoolId: School['id']
  showEnvelopField?: boolean
}
export type FormValues = Omit<Props['product'], 'id'> & {
  educational_stage_id: EducationalStage['id']
}

const ProductForm = forwardRef<HTMLFormElement, Props>(
  ({ product, schoolId, onSuccess, onApiError, onFormError, showEnvelopField = false }, ref) => {
    const { mutateAsync: mutateAsyncOnCreate } = useMutateOnCreateProduct()
    const { mutateAsync: mutateAsyncOnUpdate } = useMutateOnUpdateProduct()

    const { defaultFormValues } = useFormValues(product)

    const { control, formState, handleSubmit, setError, watch } = useForm<FormValues>({
      mode: 'onChange',
      defaultValues: defaultFormValues,
    })

    const selectedEducationalStageId = watch('educational_stage_id')
    const {
      data: productClassifications,
      loading,
      filteredEducationalSubstages,
    } = useProductClassifications({ stageId: selectedEducationalStageId })

    const selectedCategoryId = watch('product_category_id')
    const hasSelectedEducationalCategory = !!productClassifications?.product_categories.find(
      category => category.id === selectedCategoryId && category.is_educational
    )

    const isProductEdit = Boolean(product?.id)
    const hasDocsign = Boolean(product?.envelope_template_referral_id)

    const validateEnvelopeID = useCallback(
      (value: FormValues['envelope_template_referral_id']) => {
        if (!formState.dirtyFields?.envelope_template_referral_id) {
          return true
        }

        const trimmedValue = value.trim()
        const isIdValid = isValidUUID(trimmedValue)
        const isEmpty = trimmedValue === ''
        const isRemovingEnvelopId = product?.envelope_template_referral_id && isEmpty
        const isEnvelopIdInvalid = !isIdValid && !isEmpty
        const hasError = isEnvelopIdInvalid || isRemovingEnvelopId

        if (hasError) {
          setError('envelope_template_referral_id', {
            type: 'custom',
            message: 'Insira um ID de envelope válido',
          })
          return false
        }
      },
      [formState, product, setError]
    )

    const onValid = useCallback<SubmitHandler<FormValues>>(
      data => {
        if (product) {
          const updateProductParams = {
            id: product.id,
            data: {
              ...product,
              ...data,
              id: undefined as string,
              educational_stage_id: undefined as string,
            },
          }
          mutateAsyncOnUpdate(updateProductParams, { onSuccess, onError: onApiError })
        } else {
          const createProductParams = {
            ...data,
            school_id: schoolId,
            educational_stage_id: undefined as string,
            product_category: undefined as Category,
          }
          mutateAsyncOnCreate(createProductParams, { onSuccess, onError: onApiError })
        }
      },
      [schoolId, onSuccess, onApiError]
    )

    return (
      <form onSubmit={handleSubmit(onValid, onFormError)} ref={ref} data-testid="product-form">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box marginBottom={3}>
              <Typography variation="headlineDesktopXsmall" withoutMargin>
                Nome do produto
              </Typography>
              <Typography color="secondary" variation="bodySmall" withoutMargin>
                O nome que aparece no boleto de cobrança
              </Typography>
            </Box>
            <FormControl variant="outlined" fullWidth>
              <Controller
                rules={{ required: true }}
                name="name"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    {...rest}
                    inputRef={ref}
                    label="Produto"
                    placeholder="Ensino fundamental"
                    id="product-name-input"
                    variant="outlined"
                    fullWidth
                    error={Boolean(formState.errors.name)}
                    helperText={
                      formState.errors.name?.type === 'required'
                        ? 'Insira um nome para o produto'
                        : formState.errors.name?.message
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box marginY={3}>
              <Typography variation="headlineDesktopXsmall" withoutMargin>
                Categoria
              </Typography>
              <Typography color="secondary" variation="bodySmall" withoutMargin>
                O tipo de produto que você quer criar
              </Typography>
            </Box>
            <FormControl variant="outlined" fullWidth>
              <Controller
                rules={{ required: true }}
                control={control}
                name="product_category_id"
                render={({ field: { value, onChange, ref } }) => (
                  <>
                    <InputLabel id="product_category_label">Selecione uma categoria</InputLabel>
                    <Select
                      inputRef={ref}
                      labelId="product_category_label"
                      label="Selecione uma categoria"
                      data-testid="category_test_id"
                      value={value ?? ''}
                      onChange={e => onChange(e.target.value)}
                      fullWidth
                      disabled={loading}
                      error={Boolean(formState.errors.product_category_id)}
                    >
                      {productClassifications?.product_categories.map(category => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box marginBottom={3}>
              <Typography variation="headlineDesktopXsmall" withoutMargin>
                Segmento
              </Typography>
              <Typography color="secondary" variation="bodySmall" withoutMargin>
                Ciclo de ensino do MEC. {!hasSelectedEducationalCategory && 'Opcional'}
              </Typography>
            </Box>
            <FormControl variant="outlined" fullWidth>
              <Controller
                rules={{ required: hasSelectedEducationalCategory }}
                control={control}
                name="educational_stage_id"
                render={({ field: { value, onChange, ref } }) => (
                  <>
                    <InputLabel id="educational_stage_label">Selecione o segmento</InputLabel>
                    <Select
                      inputRef={ref}
                      labelId="educational_stage_label"
                      label="Segmento"
                      value={value ?? ''}
                      fullWidth
                      disabled={loading}
                      error={Boolean(formState.errors.educational_stage_id)}
                      onChange={e => onChange(e.target.value)}
                    >
                      {productClassifications?.educational_stages.map(stage => (
                        <MenuItem key={stage.id} value={stage.id}>
                          {stage.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Box marginY={3}>
              <Typography variation="headlineDesktopXsmall" withoutMargin>
                Série
              </Typography>
              {!hasSelectedEducationalCategory && (
                <Typography color="secondary" variation="bodySmall" withoutMargin>
                  Opcional
                </Typography>
              )}
            </Box>
            <FormControl variant="outlined" fullWidth>
              <Controller
                rules={{ required: hasSelectedEducationalCategory }}
                control={control}
                name="educational_substage_id"
                render={({ field: { value, onChange, ref } }) => (
                  <>
                    <InputLabel id="educational_substage_label">Selecione a série</InputLabel>
                    <Select
                      inputRef={ref}
                      labelId="educational_substage_label"
                      label="Série"
                      value={value ?? ''}
                      onChange={e => onChange(e.target.value)}
                      fullWidth
                      disabled={loading}
                      error={Boolean(formState.errors.educational_substage_id)}
                    >
                      {filteredEducationalSubstages.map(substage => (
                        <MenuItem key={substage.id} value={substage.id}>
                          {substage.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Box marginY={3}>
              <Typography variation="headlineDesktopXsmall" withoutMargin>
                Turno
              </Typography>
              <Typography color="secondary" variation="bodySmall" withoutMargin>
                Horário em que as aulas acontecem. Opcional
              </Typography>
            </Box>
            <FormControl variant="outlined" fullWidth>
              <Controller
                control={control}
                name="product_program_id"
                render={({ field: { value, onChange, ref } }) => (
                  <>
                    <InputLabel id="product_program_label">Selecione o Turno</InputLabel>
                    <Select
                      inputRef={ref}
                      labelId="product_program_label"
                      label="Turno"
                      value={value ?? ''}
                      onChange={e => onChange(e.target.value)}
                      fullWidth
                      disabled={loading}
                      error={Boolean(formState.errors.product_program_id)}
                    >
                      {productClassifications?.product_programs.map(program => (
                        <MenuItem key={program.id} value={program.id}>
                          {program.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <Controller
                control={control}
                name="enrollment_amount"
                render={({ field: { onChange, value, ref } }) => (
                  <NumberFormat
                    id="enrollment_amount-input"
                    inputRef={ref}
                    onValueChange={({ floatValue }) => {
                      onChange(floatValue)
                    }}
                    customInput={TextField}
                    variant="outlined"
                    label="Valor pré-matrícula"
                    format={formatCentsToReal}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    value={value}
                    error={Boolean(formState.errors.enrollment_amount)}
                    helperText={formState.errors.enrollment_amount ? 'Insira um valor válido' : ''}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <Controller
                rules={{ required: false, validate: v => v >= 0 }}
                control={control}
                name="enrollment_installments_quantity"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    type="number"
                    id="enrollment_installments_quantity-input"
                    variant="outlined"
                    label="Quantidade pré-matrícula"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    error={Boolean(formState.errors.enrollment_installments_quantity)}
                    disabled={isProductEdit}
                    onChange={e => onChange(parseInt(e.target.value))}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <Controller
                rules={{ required: true, validate: v => v >= 100 }}
                control={control}
                name="monthly_amount"
                render={({ field: { onChange, value, ref } }) => (
                  <NumberFormat
                    id="monthly_amount-input"
                    inputRef={ref}
                    onValueChange={({ floatValue }) => {
                      onChange(floatValue)
                    }}
                    customInput={TextField}
                    variant="outlined"
                    label="Valor mensalidade"
                    format={formatCentsToReal}
                    InputProps={{
                      inputProps: { min: 100 },
                    }}
                    value={value}
                    error={Boolean(formState.errors.monthly_amount)}
                    helperText={formState.errors.monthly_amount ? 'Insira um valor válido' : ''}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth>
              <Controller
                rules={{ required: true, validate: v => v >= 0 }}
                control={control}
                name="installments_quantity"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    type="number"
                    id="installments_quantity-input"
                    variant="outlined"
                    label="Quantidade mensalidade"
                    InputProps={{
                      inputProps: { min: 1 },
                    }}
                    error={Boolean(formState.errors.installments_quantity)}
                    disabled={isProductEdit}
                    onChange={e => onChange(parseInt(e.target.value))}
                    helperText={
                      formState.errors.installments_quantity ? 'Insira um valor válido' : ''
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
          {showEnvelopField && (
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Controller
                  rules={{
                    required: hasDocsign,
                    validate: value => validateEnvelopeID(value),
                  }}
                  name="envelope_template_referral_id"
                  control={control}
                  render={({ field: { onChange, value, ref, ...rest } }) => (
                    <TextField
                      {...rest}
                      inputRef={ref}
                      label="ID do envelope DocuSign"
                      value={value}
                      placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                      id="product-envelope-template-id-input"
                      data-testid="product-envelope-template-id-input"
                      variant="outlined"
                      onChange={currentValue => {
                        onChange(currentValue.target.value)
                      }}
                      fullWidth
                      error={Boolean(formState.errors.envelope_template_referral_id)}
                      helperText={
                        Boolean(formState.errors.envelope_template_referral_id) &&
                        'Insira um ID de envelope válido'
                      }
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </form>
    )
  }
)

export default memo(ProductForm)
