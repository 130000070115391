import styled from 'styled-components'

export const AccordionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gravity.spacing[2]};
`
export const AccordionTriggerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[2]};
  text-align: left;
`

export const AccordionTriggerItems = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const AccordionTriggerItem = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[2]};
`
