import { FilterOptions } from '../../components/IsaacPayPayoutReportFilterDrawer/types'
import { Receivable } from '@/shared/interfaces'

/**
 * Utility function to parse filter options for isaacPay external payment report page
 *
 * @param data Filter options
 *
 * @returns Parsed filter options
 */
export const buildExternalPaymentsReportFilterOptions = (data?: Receivable[]): FilterOptions => {
  if (data) {
    const uniqueProductLabels = Array.from(
      new Set(data.map(item => item.installment.contract.product.name))
    )

    const products = uniqueProductLabels.map(label => ({
      label: label,
      value: label,
    }))

    return {
      events: [],
      products: products,
      amountSigns: [],
    }
  }

  return {
    events: [],
    products: [],
    amountSigns: [],
  }
}
