import styled, { css } from 'styled-components'

type SummaryItemProps = {
  valueColor?: string
  variation: 'primary' | 'secondary' | 'tertiary'
}

// TODO: Use DD tokens
export const Content = styled.div`
  flex: 1;
  padding: 0 1.5rem 3rem;
  overflow-y: auto;
`

export const SummaryItem = styled.div<SummaryItemProps>`
  display: flex;
  justify-content: space-between;
  margin: ${props => (props.variation === 'secondary' ? '1rem' : '2rem ')} 0 2rem;

  .label {
    width: 100%;
    max-width: 60%;
    margin: 0;
    font-weight: 400;
    font-family: 'Roboto';
    line-height: 1.5rem;
    font-size: 1rem;
    color: #6d6d6d;
    display: inline-block;
    vertical-align: middle;

    ${props =>
      props.variation === 'secondary' &&
      css`
        font-size: 1.125rem;
        line-height: 1.75rem;
        color: #414141;
      `}

    svg {
      width: 1rem;
      height: 1rem;
      margin-left: 0.25rem;
      color: #afafaf;
      display: inline-block;
      vertical-align: middle;

      &:hover {
        cursor: pointer;
        color: #575757;
      }
    }
  }

  > span {
    margin: 0;
    font-weight: 400;
    font-family: 'Roboto';
    line-height: 1.5rem;
    font-size: 1rem;
    white-space: nowrap;

    ${props =>
      props.variation === 'primary' &&
      css`
        color: ${props.color};
      `}

    ${props =>
      props.variation === 'secondary' &&
      css`
        font-size: 1.125rem;
        line-height: 1.75rem;
        color: #414141;
      `}

    ${props =>
      props.variation === 'tertiary' &&
      css`
        color: #414141;
      `}
  }
`

export const ButtonContainer = styled.span`
  width: 100%;
`

export const TooltipText = styled.p`
  margin: 0;
  text-align: center;
  color: #ffffff;
  font-size: 0.75rem;
`
