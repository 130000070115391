import { useEffect } from 'react'

import { useInfiniteProducts } from '@/modules/products/hooks/queries/products'
import { sortProducts } from '@/modules/enrollment/utils/sortProducts'

const ITEMS_PER_PAGE = 100

export const useAllProductsList = (schoolId?: string) => {
  const productsQuery = useInfiniteProducts(
    {
      schoolId: schoolId ?? '',
      pagination: {
        per_page: ITEMS_PER_PAGE,
        sort_by: 'name',
        sort_order: 'ASC',
      },
    },
    {
      enabled: !!schoolId,
      keepPreviousData: true,
    }
  )
  const productList = productsQuery.data?.pages?.flatMap(p => p.data) ?? []
  const sortedProductsList = sortProducts(productList)

  useEffect(() => {
    if (productsQuery.hasNextPage && !productsQuery.isFetchingNextPage) {
      productsQuery.fetchNextPage()
    }
  }, [productsQuery.hasNextPage, productsQuery.isFetchingNextPage])

  return {
    data: sortedProductsList,
    isLoading: productsQuery.isLoading,
  }
}
