import { useCampaignContext } from '@/modules/enrollment/contexts/CampaignContext'
import { StepProps } from '../../../../components/Stepper/types'
import { useEnrollmentMixpanelEvents } from '@/modules/enrollment/hooks/useEnrollmentMixpanelEvents'

export const useCampaignStepper = (steps: StepProps[]) => {
  const { updateStepsNavHistory, activeStep, setActiveStep, stepsNavHistory } = useCampaignContext()
  const { sendButtonClickEvent } = useEnrollmentMixpanelEvents()

  const handleButtonClick = (index: number) => {
    if (index < 0 || index >= steps.length) {
      return
    }
    updateStepsNavHistory(index)
    setActiveStep(index)
  }

  const handleStepClick = (index: number) => {
    if (!stepsNavHistory[index].visited) {
      return
    }
    sendButtonClickEvent('stepper', '', '', steps[index].label)
    setActiveStep(index)
  }

  return {
    activeStep,
    ActiveComponent: steps[activeStep]?.component,
    handleButtonClick,
    handleStepClick,
    isLastStep: activeStep === steps.length - 1,
    isFirstStep: activeStep === 0,
    stepsNavHistory,
  }
}
