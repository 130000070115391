import type { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import { ButtonGroup, Pagination } from '@olaisaac/design-system'

import theme from '@/shared/theme'

type PayoutReportPageFooterProps = {
  children?: ReactNode
  itemsPerPage: number
  onChangeItemsPerPage: (value: number) => void
  onChangePage: (value: number) => void
  page: number
  totalItems: number
}

const BORDER_COLOR = theme.primitiveTokens.colors.gray[5]

/**
 * Component that represents the payout report footer
 *
 * @param props
 * @param props.page Current page
 * @param props.itemsPerPage Items to be displayed per page
 * @param props.totalItems Total of items
 * @param props.children Optional children to be displayed inside footer
 * @param props.onChangePage On change page callback
 * @param props.onChangeItemsPerPage On change items per page callback
 */
export const PayoutReportPageFooter = ({
  page,
  itemsPerPage,
  totalItems,
  children,
  onChangePage,
  onChangeItemsPerPage,
}: PayoutReportPageFooterProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="1.5rem 4rem 2.5rem"
      borderTop={`1px solid ${BORDER_COLOR}`}
    >
      <Pagination
        currentPage={page}
        itensPerPage={itemsPerPage}
        totalItens={totalItems}
        onPageChange={onChangePage}
        onItensPerChangeChange={onChangeItemsPerPage}
      />

      {children && <ButtonGroup>{children}</ButtonGroup>}
    </Box>
  )
}
