import { useMutation } from '@tanstack/react-query'

import { UnifiedInvoiceRequestBody } from '@/shared/interfaces/unifiedInvoice'
import { mockApiMethods } from '@/utils/api/__mocks__'

export const useCreateUnifiedInvoiceQuery = () => {
  return useMutation(
    (props: UnifiedInvoiceRequestBody) => mockApiMethods.unifiedInvoice.createInvoice(props),
    {
      onSuccess: response => setTimeout(() => response, 2000),
    }
  )
}
