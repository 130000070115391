import { FC } from 'react'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot, { TimelineDotProps } from '@material-ui/lab/TimelineDot'

import { Receivable, ReceivableStatuses } from 'src/shared/interfaces'
import { date2PTFormat, formatCentsToReal } from 'src/shared/utils'
import { assoc } from 'ramda'

const StyledTimeline = styled(Timeline)`
  .MuiTimelineItem-missingOppositeContent:before {
    padding: 0;
    flex: unset;
  }
`

type MaterialUITimelineDotColorType = TimelineDotProps['color']

const paramsDict: PartialRecord<ReceivableStatuses, { color: MaterialUITimelineDotColorType }> = {
  CANCELED: { color: 'secondary' },
  OPEN: { color: 'grey' },
  OVERDUE: { color: 'secondary' }, // TODO é a msm cor pra OVERDUE, CANCELED, RENEGOTIATED ?
  PAID: { color: 'primary' },
  RENEGOTIATED: { color: 'secondary' },
}

const timelineRenderStatus = (status: ReceivableStatuses) => <TimelineDot {...paramsDict[status]} />

type TimelineProps = {
  receivables?: Array<Receivable>
}

const TimelineComponent: FC<TimelineProps> = ({ receivables = [] } = { receivables: [] }) => {
  const formattedReceivables = receivables.map((rec, idx, arr) => {
    let descriptionLine = ''
    if (idx === 0) {
      descriptionLine = 'Valor base do contrato'
      // } else if (rec.description) { // TODO a description de renegociaveis deveria ser um campo somente usado na renegociacao para providenciar mais contexto
      //   descriptionLine = rec.description
    } else if (arr[idx - 1].status === ReceivableStatuses.RENEGOTIATED) {
      descriptionLine = `${formatCentsToReal(
        rec.current_amount - arr[idx - 1].current_amount
      )} de renegociação`
    }
    return assoc('description_line', descriptionLine, rec)
  })
  return (
    <StyledTimeline>
      {formattedReceivables.map(({ description_line, id, status, current_amount, due_date }) => (
        <TimelineItem key={id}>
          <TimelineSeparator>
            {timelineRenderStatus(status)}
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1" gutterBottom>
              {formatCentsToReal(current_amount)}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {description_line}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Vencimento {date2PTFormat(due_date)}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </StyledTimeline>
  )
}

export default TimelineComponent
