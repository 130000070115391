import { ArrowDropDownRounded, ArrowDropUpRounded } from '@material-ui/icons'

import type { SortOrder } from '@/shared/hooks/useOrdering/types'

type StudentOderIconProps = {
  order: SortOrder | undefined
}

/**
 * Arrow icon from student name column
 *
 * @param props
 * @param props.order Order to define the icon to be displayed
 */
export const StudentOrderIcon = ({ order }: StudentOderIconProps) => {
  if (!order) {
    return <ArrowDropDownRounded />
  }

  return order === 'ASC' ? <ArrowDropDownRounded /> : <ArrowDropUpRounded />
}
