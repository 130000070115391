import { FC, Fragment, useState, useEffect, useRef, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { Button, DatePicker, Notification } from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import ReceivableFinancialSummary from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/ReceivableFinancialSummary'
import DrawerForm from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/DrawerForm'
import { useApi } from 'src/shared/hooks'
import { Receivable } from 'src/shared/interfaces'
import { propEq, update, omit, repeat, sum, prop, range } from 'ramda'
import {
  isDayTodayOrAfter,
  formatCentsToReal,
  DATE_FORMAT,
  leftZeroPadding,
  dateToOnlyDateISO,
} from 'src/shared/utils'
import dayjs, { Dayjs } from 'dayjs'
import Divider from '@material-ui/core/Divider'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

const possibleInstallmentAmount = range(1, 13)
export const formOfPaymentOptions = [
  { name: 'Boleto ou Pix', value: JSON.stringify(['PIX', 'BANK_SLIP']), key: 'PIX_BANKSLIP' },
  { name: 'Cartão de crédito', value: JSON.stringify(['CREDIT_CARD']), key: 'CREDIT_CARD' },
]

const StyledSelect = styled(Select)`
  min-width: 190px;
`

export type RenegotiationContentProps = {
  onFinish: () => void
  receivables: Array<Receivable>
  selectedReceivableId: uuid
  setSelectedReceivableId: Dispatch<SetStateAction<uuid>>
  successCallback: (receivables: Array<Receivable>) => void
}

export type InstallmentFormValues = {
  amount: number
  due_date: Dayjs
}

export type RenegotiationFormType = {
  installments: InstallmentFormValues[]
  numberOfInstallments: number | ''
  payment_method: string
  totalAmount: number
}
const getInstallmentAmounts = (totalAmount: number, numberOfInstallments: number): number[] => {
  if (!numberOfInstallments) {
    return []
  }
  const installmentAmount = Math.floor(totalAmount / numberOfInstallments)
  const firstInstallmentExtra = totalAmount % numberOfInstallments

  return [
    installmentAmount + firstInstallmentExtra,
    ...repeat<number>(installmentAmount, numberOfInstallments - 1),
  ]
}
const RenegotiationContent: FC<RenegotiationContentProps> = ({
  onFinish,
  receivables,
  selectedReceivableId,
  setSelectedReceivableId,
  successCallback,
}) => {
  const { api } = useApi()
  const [shouldCreateNewInstallmentField, setShouldCreateNewInstallmentField] = useState(false)
  const [showBankSlipOptions, setShowBankSlipOptions] = useState(false)
  const [showCreditOptions, setShowCreditOptions] = useState(false)
  const form = useForm<RenegotiationFormType>({
    mode: 'onChange',
    defaultValues: {
      numberOfInstallments: '',
      payment_method: '',
    },
  })
  const { reset, control, getValues, setValue, trigger } = form
  const { fields: installmentFields, append, remove } = useFieldArray({
    control,
    name: 'installments',
  })
  const installmentsRef = useRef(null)

  const receivable = receivables?.find(propEq('id', selectedReceivableId))
  const isEnrollmentBlock = receivable?.installment?.type === 'ENROLLMENT'
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  let [numberOfInstallments, totalAmount] = getValues([
    'numberOfInstallments',
    'totalAmount',
    'payment_method',
  ])
  let futureDates: datestring[]

  const submitHandler = async (form: RenegotiationFormType) => {
    const { installments, payment_method, numberOfInstallments, totalAmount } = form
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.RENEGOTIATION,
        entity: EventDispatcherEntities.RENEGOTIATE_CONFIRM,
        action: 'click',
        customProperties: {
          $name: 'Confirmar renegociação',
          $receivable_id: selectedReceivableId,
          $payment_selected: payment_method,
          $total_amount: formatCentsToReal(totalAmount),
        },
      })
    return await api.receivables
      .renegotiate({
        channel: 'PI-BACKOFFICE',
        to: installments.map(({ amount, due_date }) => ({
          amount,
          due_date: due_date.utc().format('YYYY-MM-DDT00:00:00Z'),
        })),
        from: selectedReceivableId,
        payment_methods: payment_method ? JSON.parse(payment_method) : [],
        max_installments_value: numberOfInstallments
          ? parseInt(numberOfInstallments.toString())
          : 0,
      })
      .then(({ renegotiated, created }) => {
        successCallback([
          ...update(
            receivables.findIndex(propEq('id', renegotiated.id)),
            renegotiated,
            receivables
          ),
          ...created,
        ])
      })
  }

  const setInstallmentDueDates = () => {
    const newInstallments = getValues('installments')
    if (newInstallments && newInstallments.length && futureDates && futureDates.length) {
      if (showBankSlipOptions) {
        if (newInstallments.length === futureDates.length) {
          for (let i = 0; i < newInstallments.length; i++) {
            futureDates[i] = dayjs(futureDates[i]).toString()
            newInstallments[i].due_date = dayjs(futureDates[i]).utc()
          }
        }
      } else if (showCreditOptions) {
        futureDates[0] = dayjs(futureDates[0]).toString()
        newInstallments[0].due_date = dayjs(futureDates[0]).utc()
      }
      setValue('installments', newInstallments)
      trigger()
    }
  }

  const removeInstallmentsAndTriggerCreation = () => {
    remove()
    setShouldCreateNewInstallmentField(true)
  }

  const getWorkingDates = async (form: RenegotiationFormType) => {
    const { payment_method, numberOfInstallments } = form
    return await api.date
      .getWorkingDueDates({
        date: dayjs().add(1, 'day').utc().format('YYYY-MM-DDT00:00:00Z'),
        number_due_dates:
          payment_method && payment_method.length > 1
            ? parseInt(numberOfInstallments.toString())
            : 1,
      })
      .then(({ data }) => {
        futureDates = data ? data.filter(x => !x.includes('0001')) : data
        setInstallmentDueDates()
      })
  }

  const onSuccessSetReceivableId = () => {
    onFinish()
    // This unmounts the form and should be run only after the success message appears
    setSelectedReceivableId(null)
  }

  const onAddFineAndInstallmentButtonClick = () => {
    const newTotalAmount = totalAmount + receivable?.current_fine + receivable?.current_interest
    if (!newTotalAmount) {
      setValue('totalAmount', receivable.current_amount, { shouldValidate: true })
    } else {
      setValue('totalAmount', newTotalAmount, { shouldValidate: true })
    }
    removeInstallmentsAndTriggerCreation()
  }

  const onSingleInstallmentChange = () => {
    const installments = getValues('installments')

    const newTotalAmount = sum(installments.map(prop('amount')))
    if (!newTotalAmount) {
      setValue('totalAmount', receivable.current_amount, { shouldValidate: true })
    } else {
      setValue('totalAmount', newTotalAmount, { shouldValidate: true })
    }
  }

  const createNewInstallmentsFromTotalAmount = () => {
    if (!totalAmount) {
      totalAmount = receivable.current_amount
      setValue('totalAmount', receivable.current_amount, { shouldValidate: true })
    }
    if (!shouldCreateNewInstallmentField) {
      installmentsRef.current?.scrollIntoView({ behavior: 'smooth' })
      return
    }

    if (showBankSlipOptions) {
      const instalmentAmounts = getInstallmentAmounts(
        totalAmount,
        Number(numberOfInstallments).valueOf()
      )
      const newInstallments = instalmentAmounts.map<InstallmentFormValues>(amount => ({
        amount,
        due_date: null,
      }))
      append(newInstallments)
    } else if (showCreditOptions) {
      const instalmentAmounts = getInstallmentAmounts(totalAmount, 1)
      const newInstallments = instalmentAmounts.map<InstallmentFormValues>(amount => ({
        amount,
        due_date: null,
      }))
      append(newInstallments)
    }
    trigger()

    setShouldCreateNewInstallmentField(false)
  }

  useEffect(() => {
    if (numberOfInstallments) {
      getWorkingDates(form.getValues())
    }
  }, [numberOfInstallments])

  // Este effect é necessário para chamar a função append do useFieldArray após o remove do mesmo.
  // Isso é uma limitaçao da lib react-hook-form, eles tem que ser usados em renders diferentes
  // https://react-hook-form.com/api/usefieldarray
  useEffect(createNewInstallmentsFromTotalAmount, [shouldCreateNewInstallmentField])

  // Existem um bug prevenindo que a validação do campo de data de vencimento funcione na primeira renderização
  // Adicionar o reset do formuário, foi uma forma de resolver o problema
  useEffect(() => {
    reset()
    setShowBankSlipOptions(false)
    setShowCreditOptions(false)
  }, [])
  const selectFormOfPayment = (value: any) => {
    if (value && value.indexOf('CREDIT_CARD') >= 0) {
      form.setValue('numberOfInstallments', '')
      form.setValue('installments', [])
      setShowBankSlipOptions(false)
      setShowCreditOptions(true)
      trigger()
    } else {
      form.setValue('numberOfInstallments', '')
      if (isEnrollmentBlock) {
        form.setValue('numberOfInstallments', 1)
        setShouldCreateNewInstallmentField(true)
      }
      form.setValue('installments', [])
      setShowBankSlipOptions(true)
      setShowCreditOptions(false)
      trigger()
    }
  }
  return (
    <>
      <DrawerForm
        form={form}
        errorTitle="Volte e registre novamente"
        errorMessage="Houve uma falha inesperada e não conseguimos registrar a renegociação."
        successMessage="A renegociação foi registrada com sucesso."
        onFinish={onSuccessSetReceivableId}
        onSubmit={submitHandler}
      >
        <Box mt={2}>
          <ReceivableFinancialSummary receivable={receivable} />
        </Box>

        <Box mt={5} mb={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{ required: true, validate: v => v > 0 }}
              control={control}
              name="totalAmount"
              render={({ field: { onChange, value } }) => (
                <NumberFormat
                  id="totalAmount-input"
                  onValueChange={value => {
                    onChange(value.floatValue)
                  }}
                  onChange={removeInstallmentsAndTriggerCreation}
                  customInput={TextField}
                  variant="outlined"
                  label="Novo valor total?"
                  format={formatCentsToReal}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  value={value}
                />
              )}
            />
          </FormControl>
        </Box>
        <Button
          fullWidth
          variation="ghost"
          startIcon={<AddIcon />}
          onClick={onAddFineAndInstallmentButtonClick}
        >
          Multa e juros
        </Button>
        <Divider />
        <Box mt={4} marginBottom="28px">
          <Box my={2}>
            <Typography variant="subtitle1" color="textSecondary">
              Forma de Pagamento
            </Typography>
          </Box>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="payment_method">Forma de pagamento</InputLabel>
            <Controller
              rules={{ required: true, validate: v => v?.length > 0 }}
              control={control}
              name="payment_method"
              render={({ field: { value, onChange } }) => (
                <StyledSelect
                  labelId="payment_method"
                  label="Forma de pagamento"
                  value={value}
                  onChange={e => {
                    selectFormOfPayment(e.target.value)
                    onChange(e.target.value)
                  }}
                >
                  {formOfPaymentOptions.map(({ name, value, key }) => (
                    <MenuItem key={key} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
            />
          </FormControl>
        </Box>
        {showBankSlipOptions ? (
          <>
            <Divider />
            <Box mt={4}>
              <Box my={2}>
                <Typography variant="subtitle1" color="textSecondary">
                  Renegociar em quantas parcelas?
                </Typography>
              </Box>
              <FormControl disabled={isEnrollmentBlock} fullWidth variant="outlined">
                <InputLabel id="numberOfInstallments-select">Parcelas</InputLabel>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="numberOfInstallments"
                  render={({ field: { value, onChange } }) => (
                    <StyledSelect
                      label="Parcelas"
                      labelId="numberOfInstallments-select"
                      value={value}
                      onChange={e => {
                        removeInstallmentsAndTriggerCreation()
                        onChange(e.target.value)
                      }}
                    >
                      {possibleInstallmentAmount.map(value =>
                        value === 1 ? (
                          <MenuItem key={value} value={value}>
                            À vista
                          </MenuItem>
                        ) : (
                          <MenuItem key={value} value={value}>
                            {value}x
                          </MenuItem>
                        )
                      )}
                    </StyledSelect>
                  )}
                />
              </FormControl>
            </Box>
          </>
        ) : null}
        {showCreditOptions ? (
          <>
            <Notification
              variation="information"
              title="Lembrando"
              description="Negociações em cartão de crédito aparecem como uma parcela única na nossa plataforma. Não importando o máximo de parcelas que você selecionar abaixo."
            />

            <Box mt={4} marginBottom="26px">
              <Box my={2}>
                <Typography variant="subtitle1" color="textSecondary">
                  Qual o máximo de parcelas que o responsável poderá dividir?
                </Typography>
              </Box>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="numberOfInstallments-select">Parcelas</InputLabel>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="numberOfInstallments"
                  render={({ field: { value, onChange } }) => (
                    <StyledSelect
                      label="Parcelas"
                      labelId="numberOfInstallments-select"
                      value={value}
                      onChange={e => {
                        removeInstallmentsAndTriggerCreation()
                        onChange(e.target.value)
                      }}
                    >
                      {possibleInstallmentAmount.map(value =>
                        value === 1 ? (
                          <MenuItem key={value} value={value}>
                            À vista
                          </MenuItem>
                        ) : (
                          <MenuItem key={value} value={value}>
                            {value}x
                          </MenuItem>
                        )
                      )}
                    </StyledSelect>
                  )}
                />
              </FormControl>
            </Box>
            <Divider />
            <Box mt={4}>
              {installmentFields.map((data, index) => (
                <Fragment key={data.id}>
                  <Box my={2}>
                    <Typography variant="subtitle1" color="textSecondary">
                      Qual a nova data de vencimento?
                    </Typography>
                  </Box>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <Controller
                        rules={{ required: true, validate: isDayTodayOrAfter }}
                        name={`installments.${index}.due_date` as const}
                        control={control}
                        defaultValue={data.due_date}
                        render={({ field }) => (
                          <DatePicker
                            id="credit_due_date"
                            label="Vencimento"
                            format={DATE_FORMAT}
                            disablePast
                            onAccept={date => date.toISOString()}
                            minDateMessage="A data não pode ser anterior a hoje"
                            invalidDateMessage="Formato de data inválido"
                            initialFocusedDate={dateToOnlyDateISO(receivable?.due_date)}
                            {...omit(['ref'], field)}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Fragment>
              ))}
            </Box>
          </>
        ) : (
          <> </>
        )}
        {showBankSlipOptions && Boolean(installmentFields.length) && (
          <Box mt={4}>
            <div ref={installmentsRef} />
            <Typography variant="subtitle1" color="textSecondary">
              Informe valor e vencimento:
            </Typography>
          </Box>
        )}
        {showBankSlipOptions ? (
          installmentFields.map((data, index, array) => (
            <Fragment key={data.id}>
              <Box mt={3} mb={1}>
                <Typography variant="subtitle2" color="textSecondary">
                  Parcela {leftZeroPadding(index + 1, 2)}/{leftZeroPadding(array.length, 2)}
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      rules={{ required: true, validate: v => v > 0 }}
                      control={control}
                      name={`installments.${index}.amount` as const}
                      defaultValue={data.amount}
                      render={({ field: { onChange, value } }) => (
                        <NumberFormat
                          id={`installment-amount-input-${index}`}
                          onValueChange={value => {
                            onChange(value.floatValue)
                            onSingleInstallmentChange()
                          }}
                          customInput={TextField}
                          variant="outlined"
                          label="Valor"
                          format={formatCentsToReal}
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          value={value}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      rules={{ required: true, validate: isDayTodayOrAfter }}
                      name={`installments.${index}.due_date` as const}
                      control={control}
                      defaultValue={data.due_date}
                      render={({ field }) => (
                        <DatePicker
                          id={`installment-due-date-input-${index}`}
                          label="Vencimento"
                          format={DATE_FORMAT}
                          onAccept={date => date.toISOString()}
                          disablePast
                          minDateMessage="A data não pode ser anterior a hoje"
                          invalidDateMessage="Formato de data inválido"
                          initialFocusedDate={dateToOnlyDateISO(receivable?.due_date)}
                          {...omit(['ref'], field)}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Fragment>
          ))
        ) : (
          <> </>
        )}
      </DrawerForm>
    </>
  )
}

export default RenegotiationContent
