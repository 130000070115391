import { installment1_2, product1, student } from '@/shared/mocks'
import { UseInstallmentsQueryResponse } from '../../services/types'
import { InstallmentStatus } from '../constants'
import { formatDate } from '@/shared/utils'

export const getInstallmentsMock = async (): Promise<UseInstallmentsQueryResponse> => {
  const installmentStudent = {
    name: student.name,
    id: student.id,
  }

  const contractInstallments = [{ ...installment1_2 }]

  const installments = contractInstallments.map(installment => ({
    installment_competence_date: formatDate(installment.due_date, 'YYYY-MM-DD'),
    installment_due_date: installment.due_date,
    installment_id: installment.id,
    installment_order_reference: installment.order_reference,
    calculated_installment_status: InstallmentStatus.OPEN,
    contract_id: installment.contract_id,
    installment_type: installment.type,
    installment_value: installment.amount,
    product_name: product1.name,
    student: installmentStudent,
  }))

  return {
    data: installments,
  }
}
