import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.gravity.spacing[4]};
`

export const Content = styled.div`
  text-align: right;
`
