import { RouteDefinition } from '../router'
import { MenuItem } from './types'

interface RegistryOptions {
  basePath?: string
}
export class ModuleRegister {
  private routes: RouteDefinition[] = []
  private menuItems: MenuItem[] = []

  public registerRoutes(routes: RouteDefinition[], options: RegistryOptions = {}) {
    for (const route of routes) {
      let enhancedRoute = route
      if (options.basePath) {
        enhancedRoute = this.addBasePath(enhancedRoute, options.basePath)
      }
      this.checkRoute(enhancedRoute)
      this.routes.push(enhancedRoute)
    }
  }

  public registerMenuItem(menuItem: MenuItem) {
    this.menuItems.push(menuItem)
  }

  public getRoutes() {
    return this.routes
  }

  public getMenuItems() {
    return this.menuItems
  }

  private addBasePath(route: RouteDefinition, basePath: string): RouteDefinition {
    const redirect = route.redirect ? basePath + route.redirect : route.redirect

    return { ...route, path: basePath + route.path, redirect }
  }

  private checkRoute(route: RouteDefinition) {
    if (this.routes.some(registeredRoute => registeredRoute.key === route.key))
      throw new Error(`This key is already in use: ${route.key}`)
    if (this.routes.some(registeredRoute => registeredRoute.path === route.path))
      throw new Error(`This path is already in use: ${route.path}`)
  }
}
