import { FILTER_KEY_TO_FILTER_QUERY_PARAMS } from '@/escolas/services/report/constants/FILTER_KEY_TO_FILTER_QUERY_PARAMS'

export const buildQueryParams = (params: Record<string, unknown>) => {
  return Object.entries(params).reduce<Record<string, unknown | unknown[]>>((acc, [key, value]) => {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        return { ...acc, [FILTER_KEY_TO_FILTER_QUERY_PARAMS[key]]: value }
      }

      return acc
    }

    if (value) return { ...acc, [key]: value }

    return acc
  }, {})
}
