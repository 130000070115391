import { Drawer, ActionDrawerHeader } from '@olaisaac/design-system'
import Icon from '@mdi/react'
import {
  mdiArrowULeftTop,
  mdiCalendar,
  mdiCheck,
  mdiBookOpenVariant,
  mdiMessageText,
} from '@mdi/js'

import {
  ContactInfo,
  DrawerContent,
  InfoItem,
  Link,
} from '@/modules/report/pages/EnrollmentPayoutReport/components/EnrollmentPayoutReportOnboardingDrawer/styles'
import { Separator } from '@gravity/separator'
import { REPORT_BUTTON_NAMES } from '@/modules/report/constants/REPORT_BUTTON_NAMES'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { REPORT_PAGE_NAMES } from '@/modules/report/constants/REPORT_PAGE_NAMES'

type TuitionPayoutReportOnboardingDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

/**
 * Onboarding drawer for tuition payout report page
 *
 * @param props
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.onClose Callback function called on request close
 */
export const TuitionPayoutReportOnboardingDrawer = ({
  isOpen,
  onClose,
}: TuitionPayoutReportOnboardingDrawerProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const clickAnticaptionLink = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_ANTICIPATION,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
          $button_name: REPORT_BUTTON_NAMES.FIND_OUT_MORE,
        },
      })
  }

  return (
    <Drawer open={isOpen} appearance="secondary">
      <ActionDrawerHeader
        title="Entenda o repasse de mensalidades"
        appearance="secondary"
        onClose={onClose}
      />

      <DrawerContent>
        <InfoItem>
          <Icon path={mdiCalendar} size={1} />
          <h2>Fechamento mensal</h2>
          <p>
            <strong>Mensalmente, a partir do dia 05*</strong>, o isaac vai repassar os valores
            referentes às mensalidades do mês vigente. <br />
            <br />
            *Pagamentos de acordo com o seu contrato com o isaac. Em caso de feriado, o repasse será
            feito no dia útil seguinte.
          </p>
        </InfoItem>
        <Separator color="neutral-2" orientation="horizontal" />
        <InfoItem>
          <Icon path={mdiCheck} size={1} />
          <h2>Valores garantidos</h2>
          <p>
            O isaac garante a transferência dos valores de todas as mensalidades, independente do
            pagamento das parcelas pelas famílias.
          </p>
        </InfoItem>
        <Separator color="neutral-2" orientation="horizontal" />

        <InfoItem>
          <Icon path={mdiArrowULeftTop} size={1} />
          <h2>Antecipação de repasse</h2>
          <p>
            Facilidade que permite à escola receber antecipadamente parte do valor do repasse do mês
            seguinte ou subsequente.
          </p>
          <p>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://centraldeajuda.olaisaac.io/repasse/o-que-e-antecipacao-de-repasse-no-isaac"
              onClick={clickAnticaptionLink}
            >
              Saiba mais
            </Link>
          </p>
        </InfoItem>
        <Separator color="neutral-2" orientation="horizontal" />
        <InfoItem data-testid="glossary-section">
          <Icon path={mdiBookOpenVariant} size={1} />
          <h2>Glossário do repasse</h2>
          <p>
            Não entendeu algum termo ou conceito do seu repasse? Não se preocupe,{' '}
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://centraldeajuda.olaisaac.io/repasse"
            >
              acesse o glossário do repasse
            </Link>{' '}
            e veja as definições!
          </p>
        </InfoItem>
        <Separator color="neutral-2" orientation="horizontal" />
        <ContactInfo>
          <Icon path={mdiMessageText} size={1} />
          <h2>Ainda tem dúvidas?</h2>
          <p className="label">Entre em contato com o Apoio isaac</p>
          <p className="contact">WhatsApp: (11) 97876-5797</p>
          <p className="contact">falecom@isaac.com.br</p>
        </ContactInfo>
      </DrawerContent>
    </Drawer>
  )
}
