import styled from 'styled-components'

import type { LabelSize } from '.'

type DateTextProps = {
  size?: LabelSize
}

export const DateText = styled.h3<DateTextProps>`
  font-size: ${({ size }) => (size === 'small' ? '0.75rem' : size === 'large' ? '1.5rem' : '1rem')};
  font-weight: 400;
  font-family: 'Roboto';
  line-height: ${({ size }) => (size === 'large' ? '2.25rem' : '1.5rem')};
  text-transform: ${({ size }) => (size === 'small' ? 'uppercase' : 'capitalize')};
  margin: 0 0.25rem 0 0;
`
