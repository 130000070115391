import { FilterOptions } from '../../components/IsaacPayPayoutReportFilterDrawer/types'

/**
 * Utility function to parse filter options for isaacPay external payment report page
 *
 * @param data Filter options
 *
 * @returns Parsed filter options
 */
export const mergePayoutAndExternalPaymentFilters = (
  ...objects: FilterOptions[]
): FilterOptions => {
  const mergedObject: FilterOptions = {
    events: [],
    products: [],
    amountSigns: [],
  }

  const productLabelsMap: { [label: string]: boolean } = {}

  for (const obj of objects) {
    // Merge events and amountSigns arrays
    mergedObject.events.push(...obj.events)
    mergedObject.amountSigns.push(...obj.amountSigns)

    // Add unique product labels to the map
    for (const product of obj.products) {
      if (!productLabelsMap[product.label]) {
        productLabelsMap[product.label] = true
        mergedObject.products.push(product)
      }
    }
  }

  return mergedObject
}
