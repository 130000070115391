import { Button } from '@gravity/button'
import { Dialog } from '@material-ui/core'
import { DialogActions, DialogContent, DialogTitle } from '@olaisaac/design-system'

type Props = {
  confirmationText?: string
  description: string
  onConfirm: () => void
  title: string
}

export const NotAllowedDialog = ({
  onConfirm,
  title,
  description,
  confirmationText = 'Ok, entendi',
}: Props) => {
  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button variant="solid" onClick={onConfirm}>
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
