import { Box } from '@material-ui/core'
import { Refresh } from '@material-ui/icons'
import { Button, Typography } from '@olaisaac/design-system'

import { Title } from './styles'

type ErrorStateProps = {
  description: string
  onRetry: () => void
  title: string
}

/**
 * Generic error state
 *
 * @param props
 * @param props.title Title
 * @param props.description Description
 * @param props.onRetry Retry callback function
 */
export const ErrorState = ({ title, description, onRetry }: ErrorStateProps) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
      <Box
        maxWidth="470px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="2rem"
      >
        <Title>{title}</Title>

        <Typography variation="bodySmall" style={{ textAlign: 'center' }}>
          {description}
        </Typography>

        <Button onClick={onRetry} startIcon={<Refresh />}>
          Recarregar
        </Button>
      </Box>
    </Box>
  )
}
