import { SchoolGroup } from '@/shared/models/SchoolGroup'
import { School } from '../models/School'

const school1: School = {
  created_at: new Date('2021-01-27'),
  id: 'f1d17ac5-8d2d-420c-ae46-1a4062838094',
  name: 'Escolinha - Unidade 1',
  slug: 'escolinha-unidade-1',
  source_id: '1cc992ac-4011-40a5-a81a-5279743400bd',
  tax_id: '44272087000121',
  updated_at: new Date('2021-01-27'),
}

const school2: School = {
  created_at: new Date('2022-10-27'),
  id: '70a992d2-a0cc-4b00-99f6-ccc41361852a',
  name: 'Escolinha - Unidade 2',
  slug: 'escolinha-unidade-2',
  source_id: '2c94306f-bf5e-4254-b22c-e95de96c6bca',
  tax_id: '54480723000164',
  updated_at: new Date('2022-10-27'),
}

const mockGroupSchools: School[] = [school1, school2]

const mockSchoolGroup: SchoolGroup = {
  id: '5a83b758-4677-429a-a897-d157334d2f32',
  name: 'Grupo 1',
  role: 'admin',
}

const schoolsGroupMock = () => ({
  data: {
    school_group: mockSchoolGroup,
    schools: mockGroupSchools,
  },
})

export default schoolsGroupMock
