import styled from 'styled-components'
import { IrregularityTableProps } from './types'
import { Table } from '@gravity/table'
import Icon from '@mdi/react'
import { mdiCheckCircle } from '@mdi/js'

const StyledTableHead = styled(Table.Head)`
  background-color: white;
`

export const IrregularityTable = ({ columnHeaders, rows }: IrregularityTableProps) => {
  return (
    <Table.Root style={{ borderRadius: '0px' }}>
      <StyledTableHead>
        {columnHeaders.map(({ key, label }: { key: string; label: string }) => (
          <Table.HeaderCell key={key} name={key}>
            {label}
          </Table.HeaderCell>
        ))}
      </StyledTableHead>

      <Table.Body>
        {rows.map(row => (
          <Table.Row key={row.key} highlighOnHover={false}>
            <Table.CustomCell width={344}>{row.columns[0]}</Table.CustomCell>
            <Table.CustomCell width={344}>{row.columns[1]}</Table.CustomCell>
            <Table.CustomCell width={344}>{row.columns[2]}</Table.CustomCell>
            <Table.CustomCell width={61}>
              {row.resolved && <Icon path={mdiCheckCircle} size={1} color="green" />}
            </Table.CustomCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  )
}
