import { useEffect, useState } from 'react'

import { useQuery } from '@/shared/hooks/useQuery'
import { useTrackNoticationTableEvents } from './useTrackNoticationTableEvents'

import { NOTIFICATION_INITIAL_PAGE, NOTIFICATION_STATUS } from '../../constants'

import type { NotificationRow, NotificationTableParams } from './types'

export const useNotificationTable = ({ notifications, isLoading }: NotificationTableParams) => {
  const { query, setOnQueryParam } = useQuery()
  const [rows, setRows] = useState<NotificationRow[]>([])
  const [selectedRow, setSelectedRow] = useState<NotificationRow>(null)
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(null)

  const { trackRowClick, trackPageChange, trackUnreadFilterClick } = useTrackNoticationTableEvents()

  useEffect(() => {
    setRows(
      notifications.map(notification => ({
        ...notification,
        key: notification.id.toString(),
      }))
    )

    if (notifications.length === 0 || isLoading) {
      return
    }

    const notificationId = Number(query.get('id'))

    if (notificationId) {
      const { notification, index } = notifications
        .map((notification, index) => ({ notification, index }))
        .find(({ notification }) => notification.id === notificationId)

      setSelectedRow({ ...notification, key: notification.id.toString() })
      setSelectedRowIndex(index)
    }
  }, [isLoading, notifications, query])

  const handlePageChange = (page: number) => {
    setOnQueryParam(`${page}`, 'page')

    trackPageChange()
  }

  const handleItensPerChangeChange = (count: number) => {
    setOnQueryParam(`${count}`, 'count')
  }

  const handleRowClick = (rowIndex: number) => {
    setSelectedRowIndex(rowIndex)
    setOnQueryParam(`${rows[rowIndex].id}`, 'id')

    trackRowClick(rows[rowIndex])
  }

  const handleUnreadFilterClick = () => {
    const status = query.get('status') ? '' : NOTIFICATION_STATUS.UNREAD
    setOnQueryParam(`${status}`, 'status')
    setOnQueryParam(`${NOTIFICATION_INITIAL_PAGE}`, 'page')

    trackUnreadFilterClick()
  }

  const resetSelectedRow = () => {
    setSelectedRow(null)
    setSelectedRowIndex(null)
    setOnQueryParam('', 'id')
  }

  return {
    rows,
    selectedRow,
    selectedRowIndex,
    handlePageChange,
    handleItensPerChangeChange,
    handleKeyDown: handleRowClick,
    handleRowClick,
    handleUnreadFilterClick,
    resetSelectedRow,
  }
}
