import { useState, createContext, Dispatch, SetStateAction } from 'react'
import { Contract } from 'src/shared/interfaces'

export type ContractContext = {
  contract: Contract
  setContract: Dispatch<SetStateAction<Contract>>
}
export const ContractContext = createContext<ContractContext>(null)

type ContractProviderProps = { children: React.ReactNode }

export const ContractProvider = ({ children }: ContractProviderProps) => {
  const [contract, setContract] = useState<Contract>(null)
  return (
    <ContractContext.Provider value={{ contract, setContract }}>
      {children}
    </ContractContext.Provider>
  )
}
