import { createElement } from 'react'
import styled from 'styled-components'
import { ListItem as MUIListItem } from '@material-ui/core'

export const ListItem = styled(props => createElement(MUIListItem, { ...props }))`
  min-height: 48px;
  font-size: 1rem;
  font-family: 'Roboto';

  &.active {
    color: #3d4ed7;
    background-color: rgba(236, 237, 251, 0.8);
  }
`
