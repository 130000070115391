import { Tooltip } from '@material-ui/core'
import { DialogTitle, IconButton, Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
`

export const StyledIconButton = styled(IconButton)`
  width: 11px;
  height: 11px;
`

export const TextPositive = styled(Typography)`
  color: #156320;
`

export const TextNegative = styled(Typography)`
  color: #ab1207; ;
`

export const StyledToolTip = styled(Tooltip)`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.primitiveTokens.colors.gray[50]};
`
