import { useState, createContext, ReactNode, useCallback, useMemo } from 'react'

export type Breadcrumb = { name: string; path: string }

type BreadcrumbsContextType = {
  breadcrumbs: Breadcrumb[]
  popBreadcrumb: () => void
  pushBreadcrumb: (breadcrumb: Breadcrumb) => void
  updateBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
}

type BreadCrumbsProviderProps = {
  children: ReactNode
}

export const BreadcrumbsContext = createContext({} as BreadcrumbsContextType)

/**
 * Context to control application breadcrumbs
 *
 * @param children Components that will be able to access the context
 *
 * @returns Context provider
 */
export const BreadcrumbsProvider = ({ children }: BreadCrumbsProviderProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])

  const pushBreadcrumb = useCallback(
    (breadcrumb: Breadcrumb) => {
      setBreadcrumbs(prevState => [...prevState, breadcrumb])
    },
    [setBreadcrumbs]
  )

  const updateBreadcrumbs = useCallback(
    (breadcrumbs: Breadcrumb[]) => {
      setBreadcrumbs(breadcrumbs)
    },
    [setBreadcrumbs]
  )

  const popBreadcrumb = useCallback(() => {
    setBreadcrumbs(prevState => prevState.slice(0, prevState.length - 1))
  }, [setBreadcrumbs])

  const breadcrumbsContext: BreadcrumbsContextType = useMemo(
    () => ({
      breadcrumbs,
      pushBreadcrumb,
      updateBreadcrumbs,
      popBreadcrumb,
    }),
    [breadcrumbs, pushBreadcrumb, updateBreadcrumbs, popBreadcrumb]
  )

  return (
    <BreadcrumbsContext.Provider value={breadcrumbsContext}>{children}</BreadcrumbsContext.Provider>
  )
}
