import { fontSize, fontWeight, lineHeight } from '@gravity/tokens'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const TitleStyled = styled(Typography)`
  font-size: ${fontSize.lg};
  line-height: ${lineHeight.snug};
  font-weight: ${fontWeight.medium};
`

export const TextStyled = styled(Typography)`
  font-size: ${fontSize.sm};
  line-height: ${lineHeight.normal};
  font-weight: ${fontWeight.normal};
`
