import { create } from 'zustand'

type CreditAccessStore = {
  hasCreditAccess: boolean
  setHasCreditAccess: (hasCreditAccess: boolean) => void
}

export const useCreditAccessStore = create<CreditAccessStore>()(set => ({
  hasCreditAccess: false,
  setHasCreditAccess: (hasCreditAccess: boolean) => set(state => ({ ...state, hasCreditAccess })),
}))
