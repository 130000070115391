import { useHistory } from 'react-router-dom'
import { LinkProps } from './types'

export const Link = (props: LinkProps) => {
  const { children, forcedReload = false, ...remainingProps } = props

  const history = useHistory()

  const anchorProps = {
    ...remainingProps,
    onClick: (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (forcedReload) {
        return
      }

      // Avoid refreshing the page when redirect is trigged
      event.preventDefault()

      history.push(remainingProps.href)

      props.onClick?.(event)
    },
  }

  return <a {...anchorProps}>{children}</a>
}
