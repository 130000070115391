import { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import dayjs from 'dayjs'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { ErrorState } from '../../components/ErrorState'
import { TuitionPayoutReportHeader } from './components/TuitionPayoutReportHeader'
import { ReportPageTableHeader } from '@/escolas/components/ReportPageTableHeader'
import { TuitionPayoutReportTable } from './components/TuitionPayoutReportTable'
import { TuitionPayoutReportFooter } from './components/TuitionPayoutReportFooter'
import { TuitionPayoutReportOnboardingDrawer } from './components/TuitionPayoutReportOnboardingDrawer'
import { TuitionPayoutReportTransfersDrawer } from './components/TuitionPayoutReportTransfersDrawer'
import { TuitionPayoutReportFilterDrawer } from './components/TuitionPayoutReportFilterDrawer'
import { TuitionPayoutReportInstallmentDetailsDrawer } from './components/TuitionPayoutReportInstallmentDetailsDrawer'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useDrawerWidth } from '@/shared/hooks/useDrawerWidth'
import { useQuery } from '@/shared/hooks/useQuery'
import { useTuitionPayoutReportData } from './hooks/useTuitionPayoutReportData'

import { buildTuitionPayoutReportFilterOptions } from './utils/buildTuitionPayoutReportFilterOptions'

import { PAYOUT_REPORT_STATUS } from '../../constants/PAYOUT_REPORT_STATUS'
import { REPORT_PAGE_NAMES } from '../../constants/REPORT_PAGE_NAMES'

import type { FinancialTransactionProps } from '@/modules/report/models/report'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { Container, Content } from './styles'
import { TuitionMainContent } from '../../components/TuitionMainContent'

type PageDrawers = 'payout_onboarding' | 'payout_transfers' | 'payout_filter' | 'student_detail'

const USER_GUIDING_ONBOARDING_ID = '98475'

/**
 * Tuition payout report page component
 */
export const TuitionPayoutReport = () => {
  const { school } = useSelectedSchool()
  const { drawerWidth } = useDrawerWidth()
  const { setOnQueryParam } = useQuery()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  useEffect(() => {
    setOnQueryParam(USER_GUIDING_ONBOARDING_ID, 'guide', 'replace')
  }, [])

  const [selectedInstallment, setSelectedInstallment] = useState<FinancialTransactionProps>()
  const [isPayoutOnboardingDrawerOpen, setIsPayoutOnboardingDrawerOpen] = useState(false)
  const [isPayoutTransferDrawerOpen, setIsPayoutTransferDrawerOpen] = useState(false)
  const [isPayoutFilterDrawerOpen, setIsPayoutFilterDrawerOpen] = useState(false)
  const [isStudentDetailsDrawerOpen, setIsStudentDetailsDrawerOpen] = useState(false)

  const {
    tuitionPayoutReportList,
    isFetchingTuitionPayoutReportList,
    currentTuitionPayoutDetails,
    isFetchingCurrentTuitionPayoutDetails,
    hasErrorOnFetch,
    refetch,
  } = useTuitionPayoutReportData()

  const drawers: Record<PageDrawers, React.Dispatch<React.SetStateAction<boolean>>> = {
    payout_onboarding: setIsPayoutOnboardingDrawerOpen,
    payout_transfers: setIsPayoutTransferDrawerOpen,
    payout_filter: setIsPayoutFilterDrawerOpen,
    student_detail: setIsStudentDetailsDrawerOpen,
  }

  const closeDrawers = () => {
    Object.entries(drawers).forEach(([, setDrawerVisibility]) => setDrawerVisibility(false))
  }

  const handleOpenDrawer = (name: PageDrawers) => {
    closeDrawers()

    drawers[name](true)
  }

  const isPayoutOpen = currentTuitionPayoutDetails?.status === PAYOUT_REPORT_STATUS.OPEN

  const includedFilters = buildTuitionPayoutReportFilterOptions(
    currentTuitionPayoutDetails?.filtersIncluded
  )

  const isTableEmpty = (currentTuitionPayoutDetails?.transactionsTotal ?? 0) === 0

  const hasOpenedDrawer =
    isPayoutOnboardingDrawerOpen ||
    isPayoutTransferDrawerOpen ||
    isPayoutFilterDrawerOpen ||
    isStudentDetailsDrawerOpen

  if (hasErrorOnFetch) {
    return (
      <ErrorState
        title="Repasse de mensalidade"
        description="Ocorreu um erro inesperado ao carregar as informações do seu repasse. Tente novamente mais tarde."
        onRetry={refetch}
      />
    )
  }

  return (
    <Container isCollapsed={hasOpenedDrawer} drawerWidth={drawerWidth}>
      <Content>
        <TuitionPayoutReportHeader
          tuitionPayoutReportList={tuitionPayoutReportList}
          isLoadingTuitionPayoutReportList={isFetchingTuitionPayoutReportList}
          isOnboardingDrawerOpen={isPayoutOnboardingDrawerOpen}
          onRequestOnboarding={() => handleOpenDrawer('payout_onboarding')}
          onChangeTuitionPayout={closeDrawers}
          payoutReportPeriod={{
            startDate: currentTuitionPayoutDetails?.startedAt,
            endDate: currentTuitionPayoutDetails?.endedAt,
          }}
          linkButtonText="Ver transferências programadas"
          onClickAtLinkButton={() => {
            setOnQueryParam(undefined, 'transfer_section', 'replace')
            handleOpenDrawer('payout_transfers')

            isInitialized &&
              eventDispatcherClient.sendEvent({
                scope: EventDispatcherEventScopes.PAYOUT_REPORT,
                name: EventDispatcherEvents.BUTTON_CLICK,
                action: 'click',
                customProperties: {
                  $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
                  $button_label: 'Ver transferências programadas',
                },
              })
          }}
        />

        <Box mt="2rem">
          <TuitionMainContent
            payoutValue={currentTuitionPayoutDetails?.payoutAmount ?? 0}
            isNewVersionPayout={currentTuitionPayoutDetails?.isNewVersion}
            isPayoutOpen={isPayoutOpen}
            isLoading={isFetchingCurrentTuitionPayoutDetails}
            onRequestOpenPayoutTransfersDrawer={() => handleOpenDrawer('payout_transfers')}
            payoutReportPeriod={{
              startDate: currentTuitionPayoutDetails?.startedAt,
              endDate: currentTuitionPayoutDetails?.endedAt,
            }}
            linkButtonText="Ver transferências programadas"
            incomeValue={currentTuitionPayoutDetails?.income?.total ?? 0}
            deductionsValue={currentTuitionPayoutDetails?.deductions?.total ?? 0}
            onClickAtLinkButton={() => {
              setOnQueryParam(undefined, 'transfer_section', 'replace')
              handleOpenDrawer('payout_transfers')

              isInitialized &&
                eventDispatcherClient.sendEvent({
                  scope: EventDispatcherEventScopes.PAYOUT_REPORT,
                  name: EventDispatcherEvents.BUTTON_CLICK,
                  action: 'click',
                  customProperties: {
                    $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
                    $button_label: 'Ver transferências programadas',
                  },
                })
            }}
          />
        </Box>

        <Box mt="4rem" mb="2rem">
          {/* TODO: Move and refactor component to report module */}
          {/* TODO: USE REPLACE STRATEGY FOR NAME FILTER AND ORDERING */}
          <ReportPageTableHeader
            filterOptions={includedFilters}
            reportType="tuition"
            disableActions={
              !currentTuitionPayoutDetails?.isNewVersion ||
              isFetchingCurrentTuitionPayoutDetails ||
              isTableEmpty
            }
            isFilterReportDrawerOpen={isPayoutFilterDrawerOpen}
            onRequestFilterDrawerOpen={() => handleOpenDrawer('payout_filter')}
          />
        </Box>

        <TuitionPayoutReportTable
          isLoading={isFetchingCurrentTuitionPayoutDetails}
          isNewVersionPayout={currentTuitionPayoutDetails?.isNewVersion ?? false}
          transactions={currentTuitionPayoutDetails?.transactions ?? []}
          onSelectInstallment={installmentId => {
            if (!currentTuitionPayoutDetails) return

            const installments = currentTuitionPayoutDetails.transactions

            const newSelectedInstallment = installments.find(
              installment => installment.referrals.installment_id === installmentId
            )

            if (newSelectedInstallment) {
              setSelectedInstallment(newSelectedInstallment)
              handleOpenDrawer('student_detail')
            }

            isInitialized &&
              eventDispatcherClient.sendEvent({
                scope: EventDispatcherEventScopes.PAYOUT_REPORT,
                name: EventDispatcherEvents.TUITION_PAYOUT_REPORT_DETAILS,
                action: 'click',
                customProperties: {
                  $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
                },
              })
          }}
        />
      </Content>

      <TuitionPayoutReportFooter
        tuitionPayoutReportId={currentTuitionPayoutDetails?.payoutId ?? ''}
        tuitionPayoutReportDate={currentTuitionPayoutDetails?.payoutDueDate}
        isNewVersionPayout={currentTuitionPayoutDetails?.isNewVersion ?? false}
        tuitionPayoutReportStatus={currentTuitionPayoutDetails?.status}
        payoutFileVersion={currentTuitionPayoutDetails?.fileVersion}
        totalItems={currentTuitionPayoutDetails?.transactionsTotal ?? 0}
        isLoading={isFetchingCurrentTuitionPayoutDetails}
      />

      <TuitionPayoutReportOnboardingDrawer
        isOpen={isPayoutOnboardingDrawerOpen}
        onClose={() => setIsPayoutOnboardingDrawerOpen(false)}
      />

      <TuitionPayoutReportTransfersDrawer
        schoolSlug={school?.slug ?? ''}
        payoutId={currentTuitionPayoutDetails?.payoutId ?? ''}
        isPayoutOpen={isPayoutOpen}
        payoutDueDate={dayjs(currentTuitionPayoutDetails?.payoutDueDate).toDate()}
        income={currentTuitionPayoutDetails?.income}
        deductions={currentTuitionPayoutDetails?.deductions}
        bankTransfers={currentTuitionPayoutDetails?.bankTransfers}
        transactionsInfo={{
          students: currentTuitionPayoutDetails?.uniqueStudents ?? 0,
          charges: currentTuitionPayoutDetails?.transactionsCount ?? 0,
        }}
        isOpen={isPayoutTransferDrawerOpen}
        onClose={() => setIsPayoutTransferDrawerOpen(false)}
      />

      <TuitionPayoutReportFilterDrawer
        filterOptions={includedFilters}
        isOpen={isPayoutFilterDrawerOpen}
        onClose={() => setIsPayoutFilterDrawerOpen(false)}
      />

      <TuitionPayoutReportInstallmentDetailsDrawer
        contractReferenceYear={selectedInstallment?.referrals.contract_reference_year}
        guardianId={selectedInstallment?.referrals.guardian_id ?? ''}
        student={{
          id: selectedInstallment?.referrals.student_id ?? '',
          name: selectedInstallment?.referrals.student_name ?? '',
        }}
        product={{
          id: selectedInstallment?.referrals.product_id ?? '',
          name: selectedInstallment?.referrals.product_name ?? '',
        }}
        referenceDate={dayjs(selectedInstallment?.reference_month).toDate()}
        feeAmount={selectedInstallment?.financial_detail.total_fee ?? 0}
        totalAmount={selectedInstallment?.financial_detail.total ?? 0}
        totalExpectedBaseAmount={
          selectedInstallment?.financial_detail.total_expected_base_amount ?? 0
        }
        events={selectedInstallment?.financial_detail.events_details ?? []}
        isOpen={isStudentDetailsDrawerOpen}
        onClose={() => setIsStudentDetailsDrawerOpen(false)}
      />
    </Container>
  )
}
