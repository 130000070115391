import { FC } from 'react'
import { Button, Table, Typography, Notification } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'

import { useAgreement } from '@/escolas/contexts/agreementContext'
import { formatCentsToReal } from '@/shared/utils'
import { StepComponentProps } from '@/modules/guardians/Negotiation/types'
import { Footer } from '@/modules/guardians/Negotiation/components/Footer'

import { HelpButton } from '../../components/HelpButton'
import { useTableColumns } from './hooks/useTableColumns'
import { useEvents } from './hooks/useEvents'
import * as Styled from './styles'

export const CheckoutFailStep: FC<StepComponentProps> = ({ onFinish, helpButtonEntry }) => {
  const { checkoutData, agreementSimulations, setOpenDrawer } = useAgreement()
  const { sendEventFinishBtnClick, sendEventHelpBtnClick } = useEvents()

  const checkoutReceivables = agreementSimulations
    .flatMap(simulation => simulation.receivables)
    .filter(receivable => checkoutData.originalReceivableIds.includes(receivable.id))

  const columns = useTableColumns(checkoutReceivables)

  const rows = checkoutReceivables.map(receivable => ({
    key: receivable.id,
    id: receivable.id,
    invoice_link: receivable.invoice_link,
    amount: receivable.amount,
    status: receivable.status,
    student: receivable.student,
    due_date: receivable.due_date,
    product: receivable.product,
  }))

  const installmentText = `${rows.length} parcela${
    rows.length > 1 ? 's' : ''
  }, totalizando ${formatCentsToReal(checkoutData.amount)}`

  function handleFinishBtnClick() {
    sendEventFinishBtnClick()
    onFinish()
  }

  function handleHelpBtnClick() {
    sendEventHelpBtnClick()
  }

  return (
    <Styled.Container>
      <Styled.HeaderWrapper>
        <Typography withoutMargin color="primary" variation="headlineDesktopSmall">
          Aguardando pagamento
        </Typography>
        <Box mt="4px" display="flex" flexDirection="row">
          <Typography color="secondary" variation="bodySmall">
            {installmentText}
          </Typography>

          <Styled.Link onClick={() => setOpenDrawer(true)}>
            <Typography color="accent">Ver detalhes</Typography>
          </Styled.Link>
        </Box>
      </Styled.HeaderWrapper>
      <Box mt={3}>
        <Notification
          description="O comprovante de pagamento pode ser gerado através da aba de parcelas da página do responsável após confirmação do pagamento."
          variation="information"
        />
      </Box>
      <Styled.Wrapper>
        <Table columns={columns} rows={rows} />
        <Footer
          button={
            <Box width="100%" display="flex">
              <HelpButton
                eventLocationName="conclusion"
                helpButtonEntry={helpButtonEntry}
                onClick={handleHelpBtnClick}
              />
            </Box>
          }
        >
          <Button variation="primary" onClick={handleFinishBtnClick}>
            Concluir
          </Button>
        </Footer>
      </Styled.Wrapper>
    </Styled.Container>
  )
}
