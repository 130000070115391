import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query'

import { IrregularEnrollmentService } from '@/modules/enrollment/services'
import {
  IrregularStudentsRequest,
  IrregularStudentsResponse,
  IrregularityBigNumbersRequest,
  IrregularityBigNumbersResponse,
} from '@/modules/enrollment/services/types'
import { useApi } from '@/shared/hooks'
import { queryClient } from '@/modules/app/contexts/GlobalProviders/ReactQuery'

const irregularStudentsKey = 'irregular-students'
const irregularityBigNumbersKey = 'irregularity-big-numbers-key'

export const useIrregularStudentsQuery = (
  params: IrregularStudentsRequest,
  options?: UseQueryOptions<IrregularStudentsResponse>
) => {
  const { apiClient } = useApi()
  const service = IrregularEnrollmentService(apiClient.privateApi)

  return useQuery<IrregularStudentsResponse>(
    [irregularStudentsKey, params],
    () => service.fetchIrregularStudents(params),
    options
  )
}

export const useIrregularityBigNumbersQuery = (
  params: IrregularityBigNumbersRequest,
  options?: UseQueryOptions<IrregularityBigNumbersResponse>
) => {
  const { apiClient } = useApi()
  const service = IrregularEnrollmentService(apiClient.privateApi)

  return useQuery<IrregularityBigNumbersResponse>(
    [irregularityBigNumbersKey, params],
    () => service.fetchIrregularityBigNumbers(params),
    options
  )
}

export const useCancelOpenIrregularitiesMutation = () => {
  const { apiClient } = useApi()
  const service = IrregularEnrollmentService(apiClient.privateApi)

  return useMutation(
    (enrollmentRequestId: uuid) => {
      return service.cancelOpenIrregularities(enrollmentRequestId)
    },
    {
      onSettled: async response => {
        await queryClient.invalidateQueries([irregularStudentsKey, irregularityBigNumbersKey])
        return response
      },
    }
  )
}
