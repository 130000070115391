import styled from 'styled-components'
import { spacing } from '@gravity/tokens'

export const Container = styled.div`
  margin: ${spacing[8]} 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: ${spacing[6]};
`

export const ButtonGroup = styled.div`
  display: flex;
  gap: ${spacing[6]};
`
