import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export const useEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  function sendEventOpenPaymentLink() {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.CHECKOUT_FALLBACK,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: { $Button_name: 'Abrir fatura' },
      })
  }

  function sendEventCopyPaymentLink() {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.CHECKOUT_FALLBACK,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: { $Button_name: 'Copiar link' },
      })
  }

  function sendEventCopyAllPaymentLinks() {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.CHECKOUT_FALLBACK,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: { $Button_name: 'Copiar todos os links' },
      })
  }

  function sendEventFinishBtnClick() {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.CHECKOUT_FALLBACK,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: { $Button_name: 'Concluir' },
      })
  }

  function sendEventHelpBtnClick() {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.CHECKOUT_FALLBACK,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: { $Button_name: 'Ajuda' },
      })
  }

  return {
    sendEventOpenPaymentLink,
    sendEventCopyPaymentLink,
    sendEventCopyAllPaymentLinks,
    sendEventFinishBtnClick,
    sendEventHelpBtnClick,
  }
}
