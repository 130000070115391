import { useEffect, useState } from 'react'

import { Installment } from '@/modules/guardians/GuardianDetails/types'
import { mapDataToRows } from '@/modules/guardians/utils/mapDataToRows'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'

type useGuardianDetailsFinancialProps = {
  installments: Installment[]
}

export const useGuardianDetailsFinancial = ({ installments }: useGuardianDetailsFinancialProps) => {
  const [rows, setRows] = useState(mapDataToRows(installments))
  const { addInstallments } = useCheckoutCart()

  const handleRowSelect = (urn: string) => {
    setRows(prevState =>
      prevState.map(item => (item.urn === urn ? { ...item, checked: !item.checked } : item))
    )
  }

  const handleSelectAll = (select: boolean | 'indeterminate') => {
    if (select === 'indeterminate') return

    setRows(prevState =>
      prevState.map(item => (item.enableCheckbox ? { ...item, checked: select } : item))
    )
  }

  const batchSelection: boolean | 'indeterminate' = (() => {
    const selectedItems = rows?.filter(item => item.checked).length
    const totalItems = rows?.filter(item => item.enableCheckbox).length

    if (selectedItems === 0) return false

    if (selectedItems === totalItems) return true

    return 'indeterminate'
  })()

  const enableBatchSelection = !!rows?.filter(item => item.enableCheckbox).length

  useEffect(() => {
    setRows(mapDataToRows(installments))
  }, [installments])

  useEffect(() => {
    addInstallments(rows?.filter(item => item.checked).filter(Boolean))
  }, [rows])

  return {
    rows,
    batchSelection,
    enableBatchSelection,
    handleRowSelect,
    handleSelectAll,
  }
}
