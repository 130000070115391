import { Dialog } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { TextField, DialogActions, DialogContent, DialogTitle } from '@olaisaac/design-system'
import { Controller, FieldErrorsImpl, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { formatCentsToReal } from 'src/shared/utils'
import { Installment } from '@/modules/contract/services/types'
import {
  MINIMUM_AMOUNT_CENTS,
  MAXIMUM_AMOUNT,
  calculateCommomAmount,
  validateEditedAmount,
} from '../../utils'
import { Button } from '@gravity/button'
import { SelectedInstallmentsText } from '../SelectedInstallmentsText'
import * as Styled from './styles'
import { Callout } from '@gravity/callout'
import dayjs from 'dayjs'
import { InstallmentStatuses } from '@/shared/interfaces'

enum Field {
  AMOUNT = 'amount',
}

export type EditAmountForm = {
  [Field.AMOUNT]: cents
}

const REQUIRED_MESSAGES = {
  [Field.AMOUNT]: 'Por favor, informe o valor da parcela.',
}

const errorProps = (errors: Partial<FieldErrorsImpl<EditAmountForm>>, field: Field) => {
  return {
    error: Boolean(errors[field]),
    helperText: errors[field] ? errors[field].message : '',
  }
}

export const EditAmountDialog = ({
  onClose,
  onConfirm,
  selectedInstallments,
}: {
  onClose: () => void
  onConfirm?: (e: any) => void
  selectedInstallments: Installment[]
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<EditAmountForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { amount: calculateCommomAmount(selectedInstallments) },
  })

  const hasOverdueInstallments = selectedInstallments.some(
    installment => installment.status === InstallmentStatuses.OVERDUE
  )

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onConfirm)}>
        <DialogTitle>Editar valor da parcela</DialogTitle>
        <DialogContent>
          <Styled.TextContainer>
            <SelectedInstallmentsText count={selectedInstallments.length} />
          </Styled.TextContainer>
          <Controller
            rules={{
              required: REQUIRED_MESSAGES[Field.AMOUNT],
              validate: {
                maxValue: v => MAXIMUM_AMOUNT >= v || 'O valor máximo da parcela é R$ 36.000,00',
                minValue: v => v >= MINIMUM_AMOUNT_CENTS || 'O valor mínimo da parcela é R$ 1,00',
                netInstallmentAmount: v =>
                  validateEditedAmount(v, selectedInstallments) ||
                  'As parcelas selecionadas têm descontos aplicados, e a soma dos descontos não pode ultrapassar o valor da parcela.',
              },
            }}
            control={control}
            name={Field.AMOUNT}
            render={({ field: { onChange, value } }) => (
              <FormControl variant="outlined" fullWidth>
                <NumberFormat
                  id={Field.AMOUNT}
                  onValueChange={({ floatValue }) => {
                    if (floatValue === undefined) {
                      onChange(null)
                      return
                    }

                    onChange(floatValue)
                  }}
                  customInput={TextField}
                  variant="outlined"
                  label="Valor da parcela"
                  format={formatCentsToReal}
                  value={value}
                  {...errorProps(errors, Field.AMOUNT)}
                />
              </FormControl>
            )}
          />

          {hasOverdueInstallments && (
            <div style={{ marginTop: '24px' }}>
              <Callout
                text={`Ao editar o valor desta parcela, a data do seu vencimento será recalculada para amanhã, ${dayjs()
                  .add(1, 'day')
                  .format('DD/MM/YYYY')}.`}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="ghost" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="solid" type="submit" disabled={!isValid}>
            Aplicar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
