import styled from 'styled-components'
import TableCell from '@material-ui/core/TableCell'

const StyledTableCell = styled(TableCell)`
  color: inherit;
  height: 48px;
  width: ${props => props.width};
`

export default StyledTableCell
