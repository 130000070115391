import {
  AgreementSimulationCombined,
  SimulationPaymentMethod,
} from '@/modules/guardians/models/agreement'

export default function isPosAvailable(agreementSimulations: AgreementSimulationCombined[]) {
  return agreementSimulations.every(agreement =>
    agreement.payment_plans.some(
      paymentPlan => paymentPlan.payment_method === SimulationPaymentMethod.POS
    )
  )
}
