import { useQuery } from '@tanstack/react-query'

import { useApi, useJWT } from '@/shared/hooks'
import { useUserSchoolGroup } from '@/shared/hooks/queries/schoolGroups'

import { MANAGEMENT_QUERY_KEY } from '../constants'

export const useSchoolGroupManagementQuery = () => {
  const { api } = useApi()

  const { schoolId, isGroupManager } = useJWT()
  const { data } = useUserSchoolGroup(schoolId ?? '', {
    enabled: !!schoolId && isGroupManager,
  })

  const schoolGroup = data?.data?.school_group

  return useQuery({
    queryKey: ['school-group', MANAGEMENT_QUERY_KEY, schoolGroup?.id ?? ''],
    queryFn: async () => {
      const { data } = await api.management.fetchSchoolGroupManagement({
        schoolGroupId: schoolGroup?.id ?? '',
        schoolId: schoolId ?? '',
      })

      return {
        url: `${data.url}#font=Roboto&bordered=false&titled=false`,
      }
    },

    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
    enabled: !!schoolGroup?.id && !!schoolId && isGroupManager,
  })
}
