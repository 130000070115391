import { Box as MuiBox, Checkbox as MuiCheckbox } from '@material-ui/core'
import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'

export const Box = styled(MuiBox)`
  padding: 0 ${({ theme }) => theme.spacing(1)}px;
`

export const WarningBox = styled(MuiBox)`
  background-color: ${({ theme }) => theme.primitiveTokens.colors.yellow[20]};
`

export const FallbackWarningText = styled(Typography)`
  font-weight: 500;
`

export const BoxCloseButton = styled(Box)`
  padding: ${({ theme }) => theme.spacing(4)}px ${({ theme }) => theme.spacing(4)}px;
`

export const BoxLoading = styled(Box)`
  margin-bottom: ${({ theme }) => `${theme.spacing(5)}px`};
`

interface withTopButton {
  readonly withButton: boolean
}

export const Image = styled.img<withTopButton>`
  text-align: center;
  padding: ${({ theme, withButton }) => {
    return `${theme.spacing(withButton ? 0 : 12)}px 0px 0px`
  }};
`

export const Checkbox = styled(MuiCheckbox)`
  padding-top: 0px;
  padding-left: 0px;
`

export const SubtitleText = styled(Typography)`
  ${({ theme }) => theme.humanizedTokens.components.typography.variation.subtitleDesktopMedium}
  font-size: 18px;
  text-align: center;
  margin: unset;
  padding: unset;
`
