import dayjs from 'dayjs'
import { GuardianFinanceSummaryStatus } from '../../GuardianDetails/constants'
import { GuardianFinanceSummary, Installment } from '../../GuardianDetails/types'
import { OrderedGuardianSummaryInfo } from '../types'

export const reorderByStatus = (
  guardianFinanceSummaryData: GuardianFinanceSummary
): OrderedGuardianSummaryInfo[] => {
  const desiredOrder = [
    GuardianFinanceSummaryStatus.OVERDUE,
    GuardianFinanceSummaryStatus.IN_NEGOTIATION,
    GuardianFinanceSummaryStatus.OPEN,
    GuardianFinanceSummaryStatus.PAID,
  ]

  const validStatus = desiredOrder.filter(key => guardianFinanceSummaryData[key] !== undefined)
  return validStatus.map(installmentStatus => {
    const statusToAppend = {
      status: installmentStatus,
    }
    return { ...statusToAppend, ...guardianFinanceSummaryData[installmentStatus] }
  })
}

export const concatAndFilterInstallmentsToBeListed = (
  guardianFinanceSummary: OrderedGuardianSummaryInfo[],
  filters: string[] = []
): Installment[] => {
  const filteredSummary =
    filters.length > 0
      ? guardianFinanceSummary.filter(summary => filters.includes(summary.status))
      : guardianFinanceSummary
  const installments = filteredSummary.flatMap(filteredSummary => filteredSummary.installments)
  return installments
}

export const sortInstallmentsToSummaryPage = (installments: Installment[]): Installment[] => {
  return installments.sort((a, b) => {
    if (dayjs(a.due_date).isBefore(b.due_date)) {
      return -1
    }
    if (dayjs(a.due_date).isAfter(b.due_date)) {
      return 1
    }
    if (a.student.name < b.student.name) {
      return -1
    }
    if (a.student.name > b.student.name) {
      return 1
    }
    if (a.product_name < b.product_name) {
      return -1
    }
    if (a.product_name > b.product_name) {
      return 1
    }
    return 0
  })
}
