import MixpanelBrowser from 'mixpanel-browser'

import envConfig from '@/config'

import { IdentifyUserRequestDTO, MixpanelProperty, TrackEventRequestDTO } from './types'

const MIXPANEL_PROJECT_TOKEN = envConfig.EVENT_DISPATCHER.MIXPANEL.PROJECT_TOKEN
const MIXPANEL_DEBUG_MODE = envConfig.EVENT_DISPATCHER.MIXPANEL.ENABLE_DEBUG_LOGS === 'true'
const IS_MIXPANEL_ENABLED = envConfig.EVENT_DISPATCHER.MIXPANEL.ENABLED === 'true'

// TODO: Replace by EventDispatcher

/**
 * Function responsible for start mixpanel
 */
const initMixpanel = () => {
  if (!IS_MIXPANEL_ENABLED) return

  if (!MIXPANEL_PROJECT_TOKEN) {
    console.error('[Mixpanel] Project token is missing')
    return
  }

  MixpanelBrowser.init(MIXPANEL_PROJECT_TOKEN, {
    debug: MIXPANEL_DEBUG_MODE,
    persistence: 'localStorage',
  })
}

/**
 * Function responsible for user identification
 *
 * @param user Object containing user data
 */
const identifyUser = ({ userId }: IdentifyUserRequestDTO) => {
  if (!IS_MIXPANEL_ENABLED) return

  MixpanelBrowser.identify(userId)
}

/**
 * Function responsible for define properties that should be sended by all events
 *
 * @param properties Data to be defined as super properties
 */
const setSuperProperties = (properties: Record<MixpanelProperty, unknown>) => {
  if (!IS_MIXPANEL_ENABLED) return

  MixpanelBrowser.register(properties)
}

/**
 * Function responsible for emitting an event
 *
 * @param data.name Event name
 * @param data.properties Event data to be sended
 * @param data.callback Optional callback to be executed
 */
const trackEvent = ({ name, properties, callback }: TrackEventRequestDTO) => {
  if (!IS_MIXPANEL_ENABLED) return

  MixpanelBrowser.track(name, properties, undefined, callback)
}

/**
 * Mixpanel integration: Main tool for product metrics
 *
 * @deprecated Should be replaced by https://www.npmjs.com/package/@olaisaac/event-dispatcher-sdk
 */
export const mixpanel = { initMixpanel, identifyUser, setSuperProperties, trackEvent }
