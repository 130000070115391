import styled from 'styled-components'

import theme from '@/shared/theme'

const DIVIDER_COLOR = theme.primitiveTokens.colors.gray[10]

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  padding: 2rem 0;
  border-bottom: 1px solid ${DIVIDER_COLOR};
`
