import * as S from './styles'
import { Notification } from '@olaisaac/design-system'
import loadingImg from '@/escolas/assets/Isaac_Junho_Previsibilidade 1.png'

interface Props {
  negotiationId?: string
}

const LoadingLiquidation = ({ negotiationId }: Props) => {
  const description = `A negociação agora é a #${negotiationId.slice(0, 6).toUpperCase()}`
  return (
    <S.Wrapper>
      <img src={loadingImg} width={381} />
      <S.Title>Registrando recebimento</S.Title>
      <S.Subtitle>isso pode levar alguns segundos</S.Subtitle>
      <Notification
        title="O código identificador da negociação mudou"
        description={description}
        variation="information"
      />
    </S.Wrapper>
  )
}

export default LoadingLiquidation
