import { Box, FormControl } from '@material-ui/core'
import { Checkbox, Typography } from '@olaisaac/design-system'
import { GuardianFormTitle, StyledFormControlLabel } from '../../styles'

type GuardianConditionsFormInputProps = {
  onChange: (args: any) => void
  value: any
}

export const GuardianConditionsFormInput = ({
  onChange,
  value,
}: GuardianConditionsFormInputProps) => {
  return (
    <Box mb={5}>
      <GuardianFormTitle>Condições para alteração</GuardianFormTitle>
      <Box mb={6}>
        <FormControl error={!value}>
          <StyledFormControlLabel
            control={
              <Checkbox
                checkedLink={undefined}
                valueLink={undefined}
                checked={value}
                onChange={event => {
                  onChange(event.target.checked)
                }}
              />
            }
            data-testid="conditions"
            label={
              <Typography variation="bodySmall">
                Ao concluir a alteração, eu estou ciente de que o contrato com o responsável atual
                será cancelado e ficará inativo e um novo contrato será criado com os dados do novo
                responsável financeiro informado.
              </Typography>
            }
          />
        </FormControl>
      </Box>
    </Box>
  )
}
