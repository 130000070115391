import { formatCurrencyValue } from '@/shared/utils'
import {
  CreateCreditSimulationRequest,
  CreditSimulationResponse,
  CreditType,
  Eligibility,
  DataReasonForChosenPolicyType,
  ReasonForChosenPolicyType,
} from '@/shared/services/credit/types'
import { dateToFormattedString } from '../utils/formatDate'

export const useReasonForChosenPolicy = () => {
  const getReasonForChosenPolicyType = (
    { eligibility, renovationPa, gracePeriod, eligibleForGracePeriod }: Eligibility,
    creditSimulationRequest: CreateCreditSimulationRequest,
    creditSimulationResponse: CreditSimulationResponse
  ): DataReasonForChosenPolicyType | undefined => {
    const containsGracePeriodInCp = gracePeriod?.CP?.find(
      e => e === new Date(creditSimulationRequest?.firstDiscountDate ?? '')
    )

    const requestValue = creditSimulationRequest.requestedValue / 100
    const offersCPCR = creditSimulationResponse.data.offers.filter(
      e => e.category === CreditType.CPCR
    )
    const fromTheseInstallment = offersCPCR?.[offersCPCR?.length - 1]?.totalInstallments ?? 0

    const becauseOfTheInstallmentValue =
      (renovationPa ?? 0) <= requestValue && requestValue > (eligibility?.pa.CP ?? 0)
    if (becauseOfTheInstallmentValue) {
      return {
        maxPaCP: eligibility?.pa.CP ?? 0,
        fromTheseInstallment,
        chosenGracePeriod: creditSimulationRequest.firstDiscountDate ?? '',
        reason: ReasonForChosenPolicyType.becauseOfTheInstallmentValue,
      }
    }

    const becauseOfTheInstallment =
      !containsGracePeriodInCp &&
      creditSimulationResponse.data.offers.some(e => e.category === CreditType.CP)

    if (becauseOfTheInstallment) {
      return {
        maxPaCP: eligibility?.pa.CP ?? 0,
        fromTheseInstallment,
        chosenGracePeriod: creditSimulationRequest.firstDiscountDate ?? '',
        reason: ReasonForChosenPolicyType.becauseOfTheInstallment,
      }
    }

    if (eligibleForGracePeriod) {
      return {
        maxPaCP: eligibility?.pa.CP ?? 0,
        fromTheseInstallment,
        chosenGracePeriod: creditSimulationRequest.firstDiscountDate ?? '',
        reason: ReasonForChosenPolicyType.becauseOfTheGracePeriod,
      }
    }
  }

  const getCalloutMessage = (reasonForChosenPolicyType: DataReasonForChosenPolicyType) => {
    const { reason, maxPaCP, fromTheseInstallment, chosenGracePeriod } = reasonForChosenPolicyType

    const mapper = {
      becauseOfTheInstallmentValue: () => {
        const currencyMaxPa = formatCurrencyValue(maxPaCP)
        return `Valores superiores a ${currencyMaxPa} dependem da renovação do contrato da escola com o isaac.`
      },
      becauseOfTheInstallment: () => {
        return `Créditos com ${fromTheseInstallment} parcelas ou mais dependem da renovação do contrato da escola com o isaac.`
      },
      becauseOfTheGracePeriod: () => {
        const formattedGracePeriodDate = dateToFormattedString(chosenGracePeriod)
        return `O pagamento da primeira parcela, a partir de ${formattedGracePeriodDate}, depende da renovação do contrato da escola com o isaac.`
      },
    }

    if (!reason) {
      return ''
    }
    return mapper[reason]()
  }
  return { getCalloutMessage, getReasonForChosenPolicyType }
}
