import { ReactNode, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiBell } from '@mdi/js'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Heading } from '@gravity/heading'
import { IconButton } from '@gravity/icon-button'
import { Select, Option } from '@gravity/select'

import { NotificationMenu } from '@/modules/notification/components/NotificationMenu'
import { UserMenu } from './components/UserMenu'
import { LoadingState } from './components/LoadingState'
import { NotificationDialog } from './components/NotificationsDialog'

import { useJWT } from '@/shared/hooks/useJWT'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useUserSchoolGroup } from '@/shared/hooks/queries/schoolGroups'
import { useUnleashFlag, UnleashFlags } from '@/shared/hooks/useUnleashFlag'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { DSHighlighter } from '../DSHighlighter'

import { Container, NotificationContainer } from './styles'

type HeaderProps = {
  title: string | ReactNode
}

/**
 * Standard platform header
 *
 * @param props.title Header title
 */
export const Header = ({ title }: HeaderProps) => {
  const { push, location } = useHistory()
  const { getUserGroupNameLabel, getUserGroupNameBySchool } = useJWT()
  const { schoolSlug, school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false)

  const isSchoolGroupsEnabled = useUnleashFlag(UnleashFlags.PE_ENABLE_SCHOOL_GROUPS)
  const isSchoolGroupModuleEnabled = useUnleashFlag(UnleashFlags.PE_ENABLE_SCHOOL_GROUPS_MODULES)
  const isNewNotificationsScreenEnabled = useUnleashFlag(
    UnleashFlags.PE_ENABLE_NEW_NOTIFICATIONS_SCREEN
  )

  const isSchoolGroupFeatureEnabled = isSchoolGroupsEnabled && isSchoolGroupModuleEnabled

  const userGroupName = getUserGroupNameBySchool(school?.id ?? '')
  const isGroupManager = userGroupName === 'gestor'

  // TODO: handle error
  const { data, isLoading: isFetching } = useUserSchoolGroup(school?.id ?? '', {
    enabled: !!school?.id && isGroupManager && isSchoolGroupFeatureEnabled,
  })

  const sendChangeSchoolEvent = (selectedSchool: string) => {
    isInitialized &&
      eventDispatcherClient?.sendEvent({
        scope: EventDispatcherEventScopes.SCHOOL_GROUP,
        name: EventDispatcherEvents.OPTION_SELECTED,
        action: 'click',
        customProperties: {
          $form_action: 'Troca de unidade do grupo escolar',
          $selected_school: selectedSchool,
        },
      })
  }

  const handleChangeSchool = (option: Option | null) => {
    if (!option) return

    sendChangeSchoolEvent(option.label)

    push({
      pathname: schoolSlug
        ? location.pathname.replace(schoolSlug, String(option.value))
        : `/${String(option.value)}`,
    })
  }

  const schoolsOptions =
    data?.data.schools.map(school => ({
      label: school.name,
      value: school.slug,
    })) ?? []

  const currentOption = useMemo(() => schoolsOptions.find(item => item.value === schoolSlug), [
    schoolSlug,
    schoolsOptions,
  ])

  const schoolGroup = data?.data.school_group

  const isLoading = !school || !isGroupManager ? false : isFetching

  const isNotificationsRoute = window.location.pathname.includes('/avisos')

  return (
    <Container>
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          {typeof title === 'string' ? (
            <Heading variant="heading-h4-medium">{title}</Heading>
          ) : (
            title
          )}

          {isGroupManager && (
            <div className="select-container">
              <Select
                fullWidth
                options={schoolsOptions}
                value={currentOption?.value}
                onValueChange={value => {
                  const selectedOption = schoolsOptions.find(item => item.value === value)

                  if (selectedOption) {
                    handleChangeSchool(selectedOption)
                  }
                }}
              />
            </div>
          )}

          <div className="controllers-container">
            <DSHighlighter />

            {isNewNotificationsScreenEnabled ? (
              <IconButton
                id="beamerButton"
                variant="ghost"
                data-beamer-click="false"
                aria-label="Visualizar menu de avisos"
                disabled={isNotificationsRoute}
                onClick={() => setIsNotificationDialogOpen(true)}
              >
                <Icon path={mdiBell} />
              </IconButton>
            ) : (
              <NotificationContainer hideDivider iconSize={20}>
                <NotificationMenu />
              </NotificationContainer>
            )}

            <UserMenu
              title={(isGroupManager ? schoolGroup?.name : school?.name) ?? ''}
              subtitle={getUserGroupNameLabel(school?.id ?? '')}
            />
          </div>
        </>
      )}

      <NotificationDialog
        isOpen={isNotificationDialogOpen}
        onClose={() => setIsNotificationDialogOpen(false)}
      />
    </Container>
  )
}
