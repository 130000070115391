import styled from 'styled-components'

export const Container = styled.section`
  display: grid;
  grid-gap: ${props => props.theme.gravity.spacing[18]};
  margin-bottom: -2px;

  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: ${props => props.theme.gravity.spacing[16]};
  }

  .content {
    display: flex;
    gap: ${props => props.theme.gravity.spacing[6]};
  }

  .description {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;

    gap: ${props => props.theme.gravity.spacing[2]};
  }
`
