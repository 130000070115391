import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { ChangeGuardian } from 'src/escolas/pages/ChangeGuardian'

export const ChangeGuardianRoutes = () => {
  const { path } = useRouteMatch()

  const enrollmentPath = `${path}/contratos`
  return (
    <Switch>
      <Route path={`${enrollmentPath}/:contractId/alterar-titularidade`} exact>
        <ChangeGuardian />
      </Route>
    </Switch>
  )
}
