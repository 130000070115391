import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'

import type { BannerProps } from './types'

import { ContainerCard } from './styles'

export const Banner = (props: BannerProps) => {
  const { title, hidden, explanation, buttonLabel, buttonOnClick } = props

  return (
    <>
      {!hidden && (
        <ContainerCard>
          <Heading variant="heading-h4-medium" className="banner-title">
            {title}
          </Heading>
          <Text variant="body-2-regular" className="banner-subtitle">
            {explanation}
          </Text>
          <div>
            <Button color="accent" size={1} variant="ghost" onClick={buttonOnClick}>
              {buttonLabel}
            </Button>
          </div>
        </ContainerCard>
      )}
    </>
  )
}
