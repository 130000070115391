import copy from 'copy-to-clipboard'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Callout } from '@gravity/callout'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@/shared/models/enums/EventPageName.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'
import { InstallmentTypeV2, ReceivableTypeV2 } from '@/modules/guardians/services/types'
import { useToast } from '@gravity/toast'

interface CalloutPaymentLinkProps {
  installmentId?: string
  link?: string
  receivableType?: ReceivableTypeV2
  showToastDescription?: boolean
  type?: InstallmentTypeV2
}

const CalloutPaymentLink = ({
  link,
  installmentId,
  receivableType,
  type,
  showToastDescription,
}: CalloutPaymentLinkProps) => {
  const { toast } = useToast()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  function copyInvoiceLink() {
    if (!link) return

    copy(link)

    const description = showToastDescription
      ? 'Clique no botão "Pular" para seguir com o pagamento das próximas faturas.'
      : ''

    toast({
      type: 'success',
      title: 'Link copiado com sucesso',
      description,
      style: { width: '50%' },
    })

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.INSTALLMENT_NEGOTIATIONS,
        name: EventDispatcherEvents.LINK_CLICKED,
        pageName: EventPageName.NEGOTIATION,
        identifierName: EventIdentifierName.COPY_LINK_BANK_SLIP_PAYMENT,
        customProperties: {
          $link: link,
          $installment_id: installmentId,
          $receivable_type: receivableType,
          $type: type,
          $link_name: 'Copiar link de pagamento',
        },
      })
    }
  }

  const title =
    type === 'DOWN_PAYMENT'
      ? 'Esta negociação já possui um link de pagamento.'
      : 'Para pagamento parcelado no Cartão de Crédito ou à vista no Boleto/Pix via link de pagamento, copie o link ao lado.'

  return <Callout text={title} linkLabel="Copiar link" href="#" onLinkClick={copyInvoiceLink} />
}

export default CalloutPaymentLink
