import { Box } from '@material-ui/core'
import { date2PTFormat } from 'src/shared/utils'
import { InstallmentChange } from '../../ContractEditDueDayDrawer'
import {
  StyledBodyLargeTypography,
  ReasonTitleTypography,
  ReasonTypography,
  ConfirmationTitle,
  ConfirmationHeader,
  ReasonBox,
  RuleBox,
} from './style'
import dayjs from 'dayjs'

export type ConfirmationNewsDueDaysProps = {
  changeReasonAdditionalInformation: string
  installmentChanges: InstallmentChange[]
  isWorkingDay: boolean
}

const ConfirmationNewsDueDays = ({
  installmentChanges,
  changeReasonAdditionalInformation,
  isWorkingDay,
}: ConfirmationNewsDueDaysProps) => (
  <div data-testid="confirm-edit-due-day">
    <ConfirmationHeader>
      <ConfirmationTitle>Confirme as alterações de vencimento:</ConfirmationTitle>
    </ConfirmationHeader>
    <Box mb={1} display="flex" flexDirection="column">
      <ReasonBox>
        <ReasonTitleTypography>Motivo</ReasonTitleTypography>
        <ReasonTypography>{`${changeReasonAdditionalInformation}`}</ReasonTypography>
      </ReasonBox>
      <RuleBox>
        <ReasonTitleTypography>Regra de vencimento</ReasonTitleTypography>
        <ReasonTypography>{isWorkingDay ? 'Por dia útil' : 'Por dia fixo'}</ReasonTypography>
      </RuleBox>
    </Box>
    {installmentChanges?.map(
      ({ orderReference: originalOrderReference, originalDueDate, newDueDate }, index) => (
        <Box key={index}>
          <Box mt={3} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography>Parcela</StyledBodyLargeTypography>
            <StyledBodyLargeTypography>{originalOrderReference}</StyledBodyLargeTypography>
          </Box>
          <Box mt={1.5} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">
              Vencimento original
            </StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {date2PTFormat(dayjs(originalDueDate).toISOString())}
            </StyledBodyLargeTypography>
          </Box>
          <Box mt={1.5} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">Novo vencimento</StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {date2PTFormat(dayjs(newDueDate).toISOString())}
            </StyledBodyLargeTypography>
          </Box>
        </Box>
      )
    )}
  </div>
)

export default ConfirmationNewsDueDays
