import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .controllers {
    display: flex;
    gap: ${props => props.theme.gravity.spacing[6]};
  }

  .profile {
    display: flex;
    align-items: center;

    .user-group {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: ${props => props.theme.gravity.spacing[2]};
      ${props =>
        css`
          margin: ${props.theme.gravity.spacing[2]} 0 ${props.theme.gravity.spacing[4]};
        `};
    }
  }
`
