import styled from 'styled-components'
import { spacing } from '@gravity/tokens'

export const BigNumberWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${spacing[8]};
  margin: ${spacing[8]} 0;
`

export const SummaryPageWrapper = styled.div`
  padding-top: ${spacing[8]};
  padding-bottom: 84px;
`

export const NoPendingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  margin-top: ${spacing[12]};
  span {
    margin-top: ${spacing[2]};
    line-height: ${spacing[6]};
  }
  a {
    margin-top: ${spacing[4]};
  }
`

export const Image = styled.img`
  width: 124px;
  height: 116px;
  margin-bottom: ${spacing[2]};
`
