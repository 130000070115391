import { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { TuitionEditFormValues } from '../types'

export const useTuitionFormManager = () => {
  const tuitionForm = useForm<TuitionEditFormValues>({
    mode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      discounts: [],
    },
  })
  const [discountsDeleted, setDiscountsExcluded] = useState<string[]>([])

  const { control, watch, reset, getValues, register } = tuitionForm
  watch()

  register('monthly_amount', { required: true })
  register('start_month', { required: true })

  const { fields: discountFields, append, remove } = useFieldArray<
    TuitionEditFormValues,
    'discounts'
  >({
    control: control,
    name: 'discounts',
  })

  const removeDiscount = (index: number) => {
    const formValues = getValues()
    const discounts = formValues.discounts
    if (discounts[index]?.id) {
      setDiscountsExcluded([...discountsDeleted, discounts[index].id])
    }
    remove(index)
  }

  const appendNewDiscount = () => {
    append({
      amount: null,
      days_before_due_date: null,
      description: null,
      label_days_before_due_date: null,
      is_finished: false,
    })
  }

  return {
    tuitionForm,
    appendNewDiscount,
    removeDiscount,
    discountFields,
    resetTuitionForm: reset,
    discountsDeleted,
    setDiscountsExcluded,
  }
}
