import { Box } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled(Box).attrs(props => ({
  ...props,
  paddingY: 4,
  paddingLeft: 4,
  paddingRight: 6,
}))`
  display: flex;
`
