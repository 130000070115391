import dayjs from 'dayjs'

import { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'

export function findIsaacPayPayoutReportByDate(payouts: UnguaranteedReport[], date: Date) {
  const payout = payouts?.find(
    item => dayjs(item.ended_at).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
  )

  return payout
}
