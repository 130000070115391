export type PaginationType = {
  itemsPerPage: number
  page: number
}

export type PaginationQueryParamNames = 'page' | 'items_per_page'

export enum PaginationQueryParamsNameEnum {
  itemsPerPage = 'items_per_page',
  page = 'page',
  sortBy = 'sort_by',
  sortOrder = 'sort_order',
}

export type PaginationHookType = {
  pagination: PaginationType
  updatePagination: (data: PaginationType, replaceRoute?: boolean) => void
  updatePaginationValue: (key: keyof PaginationType, value: number, replaceRoute?: boolean) => void
}
