import { Box } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'

import { StatusBadge } from './StatusBadge'
import { StatusContainer } from './styles'
import { StatusProps, StatusBadgeColor } from './types'
import { InstallmentStatus } from '@/modules/guardians/GuardianDetails/constants'

export const paramsDict: PartialRecord<
  InstallmentStatus,
  { color: StatusBadgeColor; text: string }
> = {
  AGGLUTINATED: { color: 'grey', text: 'Aglutinada' },
  CANCELED: { color: 'grey', text: 'Cancelada' },
  DUE_TODAY: { color: 'warning', text: 'Vence hoje' },
  OPEN: { color: 'primary', text: 'A vencer' },
  OVERDUE: { color: 'error', text: 'Vencido' },
  PAID: { color: 'success', text: 'Pago' },
  PAID_AND_CANCELED: { color: 'grey', text: 'Paga e cancelada' },
  RENEGOTIATED: { color: 'grey', text: 'Renegociada' },
  PENDING: { color: 'grey', text: 'Aguardando' },
}

export const Status = ({ status, text, renderText }: StatusProps) => (
  <StatusContainer>
    <StatusBadge color={paramsDict[status]?.color} />
    <Box ml={1}>
      {renderText ? (
        renderText(paramsDict[status]?.text)
      ) : (
        <Typography variation="bodyLarge">
          {paramsDict[status]?.text} {text?.length ? text : ''}
        </Typography>
      )}
    </Box>
  </StatusContainer>
)
