import { PreContractStatuses as ContractStatusType } from '@/shared/interfaces'

export type StatusBadgeColor = 'created' | 'effected'

export const contractStatusDict: PartialRecord<
  ContractStatusType,
  { color: StatusBadgeColor; label: string; text: string }
> = {
  CREATED: {
    color: 'created',
    label:
      'Contrato com parcela de pré matrícula a vencer e/ou é necessário regularizar o pagamentos das parcelas vencidas.',
    text: 'Em andamento',
  },
  EFFECTED: {
    color: 'effected',
    label: 'Contrato com parcelas e mensalidades geradas',
    text: 'Confirmado',
  },
}
