import { IrregularityTable } from './IrregularityTable'
import { mdiAccountMultiple } from '@mdi/js'
import Icon from '@mdi/react'
import { IrregularityTableTitle } from './IrregularityTableTitle'
import { GuardianIrregularityType, guardianIrregularityParamsDict } from '../types'
import { Irregularity } from '@/modules/enrollment/services/types'
import {
  IrregularityTableBaseboard,
  ReferenceLink,
  ReferenceMessage,
} from './IrregularityTableBaseboard'
import { Text } from '@gravity/text'

interface GuardianIrregularityTableProps {
  externalId: string
  guardianName: string
  irregularityList: Irregularity[]
  referenceLink: string
}

const neededActionColumn = { key: 'needed_action_column', label: 'Ação necessária' }

const COLUMN_HEADERS: Array<{ key: string; label: string }> = [
  { key: 'pending_column', label: 'Pendência' },
  { key: 'guardian_name_column', label: 'Responsável' },
  neededActionColumn,
  { key: 'resolved_column', label: '' },
]

const getActionMessage = (referenceLink: string, externalId: string): JSX.Element => {
  return referenceLink ? (
    <ReferenceLink url={referenceLink} />
  ) : (
    <ReferenceMessage
      identifier="ID do Responsável Financeiro"
      copyable={externalId}
      copyableTooltip="ID do responsável copiado"
    />
  )
}

export const GuardianIrregularityTable = ({
  externalId,
  guardianName,
  irregularityList,
  referenceLink,
}: GuardianIrregularityTableProps) => {
  let irregularitiesResolved = 0

  const rows = irregularityList.map(irregularity => {
    const value =
      guardianIrregularityParamsDict[irregularity.irregularity_type as GuardianIrregularityType]
    const isResolved = irregularity.resolved_at?.length > 0

    if (isResolved) irregularitiesResolved++

    const key = `${guardianName}-${irregularity.irregularity_type}`
    return {
      key: key,
      resolved: isResolved,
      columns: [
        <Text key={key} variant="body-1-regular">
          {value.name}
        </Text>,
        <Text key={key} variant="body-1-regular">
          {guardianName || 'Nome não indicado'}
        </Text>,
        <Text key={key} variant="body-1-regular">
          {value.action}
        </Text>,
      ],
    }
  })

  const hasIrregularities = irregularityList.length > 0
  const allIrregularitiesAreResolved = irregularitiesResolved === irregularityList.length
  const shouldShowConcludedMessage = hasIrregularities && allIrregularitiesAreResolved

  let callToActionMsg = 'Corrija os dados no seu sistema'
  if (shouldShowConcludedMessage) {
    neededActionColumn.label = 'Ação feita'
    callToActionMsg = 'Dados corrigidos no seu sistema'
  }

  return (
    <div>
      <IrregularityTableTitle
        title="Informação do responsável"
        icon={<Icon path={mdiAccountMultiple} size={1} />}
      />
      <IrregularityTable rows={rows} columnHeaders={COLUMN_HEADERS} />
      <IrregularityTableBaseboard
        callToActionMsg={callToActionMsg}
        actionElement={
          shouldShowConcludedMessage ? <></> : getActionMessage(referenceLink, externalId)
        }
      />
    </div>
  )
}
