import { ListGuardiansFilterType } from '../components/ListGuardiansFilter/types'

/**
 * @description Remove a filter from filters used in API.
 * @param filters Actual filters.
 * @param value Filter to be removed.
 * @returns Filters to be used in API.
 */
export const updatedFiltersToRemove = (
  filters: Partial<ListGuardiansFilterType>,
  value: string
) => {
  return Object.keys(filters).reduce((acc, key) => {
    const filter = filters[key as keyof ListGuardiansFilterType]

    if (filter.length === 0) return acc

    return {
      ...acc,
      [key]: filter.filter(item => item !== value),
    }
  }, {} as ListGuardiansFilterType)
}
