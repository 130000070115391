import { IconButton } from '@gravity/icon-button'
import { Icon } from '@mdi/react'
import { mdiClose } from '@mdi/js'

import { Box } from '@material-ui/core'
import { Skeleton } from '@gravity/skeleton'

import { InstallmentDrawerProps } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import { DrawerVariationsProps } from '@/modules/guardians/InstallmentsDrawerContainer/variations'

import {
  Divider,
  HeaderWrapper,
  HeaderContentWrapper,
  HeaderSkeletonGroup,
  ContentWrapper,
  RowWrapper,
  FooterWrapper,
} from './styles'

import { spacing } from '@gravity/tokens'

export const loading = ({ onCloseDrawer }: InstallmentDrawerProps): DrawerVariationsProps => {
  return {
    header: (
      <HeaderWrapper>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={onCloseDrawer} variant="ghost" size={1}>
            <Icon path={mdiClose} />
          </IconButton>
        </Box>

        <HeaderContentWrapper>
          <HeaderSkeletonGroup>
            <Skeleton width={83} height={21} />
            <Skeleton width={180} height={34} />
          </HeaderSkeletonGroup>

          <Box>
            <Skeleton width={80} height={22} />
          </Box>
        </HeaderContentWrapper>
      </HeaderWrapper>
    ),
    fullContent: (
      <ContentWrapper>
        <Box display="flex" flexDirection="column" style={{ gap: spacing[2], flexFlow: 'wrap' }}>
          <Skeleton width={160} height={21} />
          <Skeleton width={304} height={25} />
        </Box>

        <Divider />

        <Box display="flex" flexDirection="column" style={{ gap: spacing[4] }}>
          <RowWrapper>
            <Skeleton width={260} height={21} />
            <Skeleton width={80} height={21} />
          </RowWrapper>

          <RowWrapper>
            <Skeleton width={260} height={21} />
            <Skeleton width={80} height={21} />
          </RowWrapper>

          <RowWrapper>
            <Skeleton width={260} height={21} />
            <Skeleton width={80} height={21} />
          </RowWrapper>
        </Box>

        <Divider />

        <FooterWrapper>
          <RowWrapper>
            <Skeleton width={260} height={21} />
            <Skeleton width={80} height={21} />
          </RowWrapper>
        </FooterWrapper>
      </ContentWrapper>
    ),
  }
}
