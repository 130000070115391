import { Help } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import { Divider } from '@olaisaac/design-system'

import { convertToUTCDate, formatMicroCentsToReal } from '@/shared/utils'

import { ISAAC_PAY_TRANSACTIONS_IN_ORDER } from '@/modules/isaacpay/pages/IsaacPayReport/constants/transactionsInOrder'
import {
  TooltipText,
  SummaryItem as SummaryInfoItem,
  Content,
} from '../IsaacPaySummaryDrawer/styles'

/**
 * Drawer for payout content. This content is for internal payout providers
 * @param props
 * @param props.bankTransfers Summary of bank transfers
 * @param props.values Values of transfers in each payout
 * @param props.total Total payout value
 * @param props.total_fee Total fee value
 * @param props.getValueColor Function to get label color
 */
export const IsaacPayPayoutDrawerContent = ({
  bankTransfers,
  values,
  total,
  total_fee,
  getValueColor,
}) => {
  return (
    <Content>
      {Object.keys(ISAAC_PAY_TRANSACTIONS_IN_ORDER).map(
        key =>
          !!values[key] && (
            <SummaryInfoItem key={key} variation="primary" color={getValueColor(values[key])}>
              <p className="label">
                {ISAAC_PAY_TRANSACTIONS_IN_ORDER[key].label}

                {ISAAC_PAY_TRANSACTIONS_IN_ORDER[key].description && (
                  <Tooltip
                    title={
                      <TooltipText>{ISAAC_PAY_TRANSACTIONS_IN_ORDER[key].description}</TooltipText>
                    }
                  >
                    <Help />
                  </Tooltip>
                )}
              </p>

              <span>{formatMicroCentsToReal(values[key])}</span>
            </SummaryInfoItem>
          )
      )}

      <Divider variation="secondary" />

      <SummaryInfoItem variation="primary" color={getValueColor(total_fee)}>
        <p className="label">
          Taxa isaac
          <Tooltip title="Valor referente à prestação de serviços isaac">
            <Help />
          </Tooltip>
        </p>

        <span>{formatMicroCentsToReal(total_fee)}</span>
      </SummaryInfoItem>

      <Divider variation="secondary" />

      <SummaryInfoItem variation="secondary">
        <p className="label">
          {total < 0 ? 'Valor pendente a ser descontado do repasse' : 'Valor total'}
        </p>

        <span>{formatMicroCentsToReal(total)}</span>
      </SummaryInfoItem>

      <Divider variation="primary" />

      {bankTransfers.map((item, index) => {
        return (
          <SummaryInfoItem key={index} variation="tertiary">
            <p className="label">Transferência programada</p>

            <span>{convertToUTCDate(item.date).format('DD/MM/YYYY')}</span>
          </SummaryInfoItem>
        )
      })}
    </Content>
  )
}
