import { forwardRef } from 'react'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import * as Styled from './styles'
import type {
  BigNumberRootProps,
  BigNumberTitleProps,
  BigNumberValueContentProps,
  BigNumberDescriptionContentProps,
} from './types'

const BigNumberRoot = forwardRef<HTMLDivElement, BigNumberRootProps>(
  ({ backgroundColor, ...props }, ref) => (
    <Styled.Root backgroundColor={backgroundColor} ref={ref} {...props} />
  )
)

const BigNumberTitle = forwardRef<HTMLHeadingElement, BigNumberTitleProps>(({ children }, ref) => (
  <Styled.Title ref={ref}>{children}</Styled.Title>
))

const BigNumberValueContent = ({ children }: BigNumberValueContentProps) => (
  <Heading variant="heading-h4-medium">{children}</Heading>
)

const BigNumberDescriptionContent = ({ children }: BigNumberDescriptionContentProps) => (
  <Text>{children}</Text>
)

export const BigNumberPrimitives = {
  Root: BigNumberRoot,
  Title: BigNumberTitle,
  Value: BigNumberValueContent,
  Description: BigNumberDescriptionContent,
}
