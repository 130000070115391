import { APIResponse } from '@/shared/interfaces'
import { AxiosInstance } from 'axios'

export const enrollmentRequestService = (fetcher: AxiosInstance) => {
  return {
    fetchHasPendingIrregularities: async (params: {
      referenceYear: number
      schoolId: string
    }): Promise<{ has_pending_irregularities: boolean }> => {
      return (
        await fetcher.get<APIResponse<{ has_pending_irregularities: boolean }>>(
          `/enrollment-request/students/has-pending-irregularities`,
          {
            params: {
              school_id: params.schoolId,
              reference_year: params.referenceYear,
            },
          }
        )
      ).data.data
    },
  }
}
