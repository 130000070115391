import { Box } from '@material-ui/core'
import { FormControlLabel, Radio, Typography } from '@olaisaac/design-system'
import { PropsWithChildren } from 'react'
import SplitIcon from 'src/escolas/assets/split-icon.svg'
import { Card, CircleIcon, StyledCreditCard } from './styles'
import { OptionCardProps } from './types'

export const PAYMENT_CARD: Record<string, { icon: React.ReactNode; name: string }> = {
  CREDIT_CARD: {
    name: 'Cartão de crédito',
    icon: <StyledCreditCard />,
  },
  DEBIT_CARD: {
    name: 'Cartão de débito',
    icon: <StyledCreditCard />,
  },
  BANK_SLIP: {
    name: 'Boleto e Pix',
    icon: <img src={SplitIcon} />,
  },
}

const OptionCard = ({
  informationText,
  type,
  onSelect,
  isPaymentFeeEnabled,
  isSelected,
  children,
}: PropsWithChildren<OptionCardProps>) => {
  const { name, icon } = PAYMENT_CARD[type]

  return (
    <FormControlLabel
      label=""
      value={type}
      style={{ display: 'flex', width: '100%', marginBottom: 0 }}
      control={
        <Card width="100%">
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" width="25%">
              <Radio checked={isSelected} name={type} onClick={() => onSelect(type)} />
              <CircleIcon>{icon}</CircleIcon>
            </Box>
            <Box display="flex" alignItems="center" width="75%" marginLeft={3}>
              <span style={{ width: '70%' }}>
                <Typography withoutMargin variation="headlineDesktopXsmall" color="primary">
                  {name}
                </Typography>
                {isPaymentFeeEnabled && (
                  <Typography variation="bodySmall" color="secondary">
                    {informationText?.bottomLeft}
                  </Typography>
                )}
              </span>
              <Box
                flexGrow={1}
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
                width="55%"
                textAlign="right"
              >
                {isPaymentFeeEnabled ? (
                  <>
                    <Typography variation="headlineDesktopXsmall" color="primary">
                      {informationText?.info}
                    </Typography>
                    <Typography variation="bodySmall" color="secondary">
                      {informationText?.bottomRight}
                    </Typography>
                  </>
                ) : (
                  <Typography variation="bodySmall" color="primary">
                    {informationText?.info}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          {isSelected && children}
        </Card>
      }
    />
  )
}

export default OptionCard
