import {
  SimulationPaymentMethod,
  AgreementSimulation,
  AgreementSimulationCombined,
  MakeAgreementResponse,
  AgreementEntry,
} from '@/modules/guardians/models/agreement'
import dayjs from 'dayjs'

export const mockEntry: AgreementEntry = {
  competenceMonths: ['Agosto'],
  dueDate: dayjs().add(6, 'day').toISOString(),
  negotiationID: '97d18b3a-901b-40f2-b960-85157f92ae73',
  product: [{ id: '372634ed-5a2a-43f4-986d-faff424a08a1', name: 'Produto legal' }],
  simulationID: 'e8eb475e-215e-46a3-8316-ccaf0d0c2258',
  student: {
    id: 'a5e767c6-cce8-4267-91f0-e934eb6c9177',
    name: 'Robertinho',
    source_id: 'isaac',
  },
  value: 30000,
  maxInstallment: 1,
  paymentLink: 'link-legal',
  orderReference: '1 de 2',
}

export const mockEntry2: AgreementEntry = {
  competenceMonths: ['Agosto', 'Setembro'],
  dueDate: dayjs().add(6, 'day').toISOString(),
  negotiationID: '80182ed4-89fe-4852-8151-a0fccbb6a27e',
  product: [{ id: '372634ed-5a2a-43f4-986d-faff424a0aa2', name: 'Produto mais ou menos legal' }],
  simulationID: 'e8eb475e-215e-46a3-8316-ccaf2d0c2258',
  student: { id: 'a5e767c6-cce8-4267-91f0-e944eb6c9177', name: 'Robertinha', source_id: 'isaac' },
  value: 40000,
  maxInstallment: 3,
  paymentLink: 'link-legal',
  orderReference: '1 de 2',
}

export const mockEffectedResponse: MakeAgreementResponse[] = [
  {
    id: 'e8eb475e-215e-46a3-8316-ccaf0d0c2258',
    agreement_amount: 20000,
    invoices: [
      {
        amount: 20000,
        due_date: '2022-06-30T20:25:41.645625Z',
        id: '18d11e2e-3af0-4188-b66c-5a02d5da9369',
        installment: '1/1',
        payment_link: 'random-link',
        payment_method: SimulationPaymentMethod.BANK_SLIP,
        receivable_id: '508aa288-7b06-467a-81eb-a8888391f211',
      },
    ],
  },
]

export const mockAgreementSimulations: AgreementSimulation[] = [
  {
    receivable_ids: [
      '508aa288-7b06-467a-81eb-a8888391f211',
      'ee08b929-e347-4011-ac04-ae24506aa597',
    ],
    agreement_simulation_id: '8ff4114e-9239-4602-9a1b-5b08667af5d8',
    short_id: 'aa96dd5d',
    original_amount: 38889,
    due_date: '2022-06-30T20:25:41.645625Z',
    original_interest: 1000,
    original_fine: 2000,
    fine_applied: 533,
    interest_applied: 1000,
    perpetual_discount_applied: 6666,
    early_payment_discount_applied: 0,
    due_payment_discount_applied: 3000,
    exempted_fine: 0,
    exempted_interest: 0,
    total_amount: 35423,
    payment_plans: [
      {
        max_installments_allowed: 5,
        min_down_payment: 43,
        payment_method: SimulationPaymentMethod.PIX,
      },
      {
        max_installments_allowed: 5,
        min_down_payment: 43,
        payment_method: SimulationPaymentMethod.POS,
      },
    ],
    credit_card_fee: 0,
    credit_card_fee_percentage: 0,
  },
  {
    receivable_ids: [
      '509aa288-7b06-467a-81eb-a8888391f211',
      'ea08b929-e347-4011-ac04-ae24506aa597',
    ],
    agreement_simulation_id: '8gf4114e-9239-4602-9a1b-5b08667af5d8',
    short_id: 'ac96dd5d',
    original_amount: 53334,
    due_date: '2022-02-30T20:25:41.645625Z',
    original_interest: 1000,
    original_fine: 5000,
    fine_applied: 1066,
    interest_applied: 1272,
    perpetual_discount_applied: 13332,
    early_payment_discount_applied: 0,
    due_payment_discount_applied: 0,
    exempted_fine: 0,
    exempted_interest: 0,
    total_amount: 45674,
    payment_plans: [
      {
        max_installments_allowed: 1,
        min_down_payment: 43,
        payment_method: SimulationPaymentMethod.POS,
      },
      {
        max_installments_allowed: 1,
        min_down_payment: 43,
        payment_method: SimulationPaymentMethod.CREDIT_CARD,
      },
    ],
    credit_card_fee: 0,
    credit_card_fee_percentage: 0,
  },
]

export const mockAgreementSimulationsCombined: AgreementSimulationCombined[] = [
  {
    ...mockAgreementSimulations[0],
    receivables: [
      {
        amount: 12222,
        contract_id: '381cc759-f28d-4f28-9c22-bd4ff1a6182a',
        competenceDate: '2022-06-14T21:00:00-03:00',
        current_early_payment_discount: 0,
        current_perpetual_discount: 0,
        current_due_payment_discount: 0,
        current_fine: 0,
        current_interest: 0,
        discounts: [],
        due_date: '2022-06-14T21:00:00-03:00',
        id: '508aa288-7b06-467a-81eb-a8888391f211',
        installment_id: '80ba7f12-38af-469d-9d90-33985d12f903',
        lost_due_payment_discount: 0,
        lost_early_payment_discount: 0,
        orderReference: '1 de 2',
        original_amount: 12222,
        product: 'Ano 5',
        product_id: '0e7a8070-74d5-4f84-b0d3-0b68acb5573e',
        status: 'OPEN',
        student: 'Caroline Rayssa Rezende',
        student_id: '635b4d54-f5a9-4283-a2cb-69b71059410a',
        isEnrollment: true,
      },
      {
        amount: 28200,
        contract_id: '381cc759-f28d-4f28-9c22-bd4ff1a6182a',
        competenceDate: '2022-06-15T21:00:00-03:00',
        current_early_payment_discount: 0,
        current_perpetual_discount: 6666,
        current_due_payment_discount: 0,
        current_fine: 533,
        current_interest: 1000,
        discounts: [],
        due_date: '2022-06-15T21:00:00-03:00',
        id: 'ee08b929-e347-4011-ac04-ae24506aa597',
        installment_id: '14c300fb-a285-40ea-99fd-2c974d047c94',
        lost_due_payment_discount: 4999,
        lost_early_payment_discount: 3333,
        orderReference: '2 de 2',
        original_amount: 33333,
        product: 'Ano 5',
        product_id: '0e7a8070-74d5-4f84-b0d3-0b68acb5573e',
        status: 'OPEN',
        student: 'Caroline Rayssa Rezende',
        student_id: '635b4d54-f5a9-4283-a2cb-69b71059410a',
        isEnrollment: false,
      },
    ],
  },
  {
    ...mockAgreementSimulations[1],
    total_amount: 55672,
    credit_card_fee: 1113,
    credit_card_fee_percentage: 2,
    receivables: [
      {
        amount: 27960,
        contract_id: '381cc759-f28d-4f28-9c22-bd4ff1a6182a',
        competenceDate: '2022-07-15T21:00:00-03:00',
        current_early_payment_discount: 0,
        current_perpetual_discount: 6666,
        current_due_payment_discount: 0,
        current_fine: 533,
        current_interest: 760,
        discounts: [],
        due_date: '2022-07-15T21:00:00-03:00',
        id: '509aa288-7b06-467a-81eb-a8888391f211',
        installment_id: 'c8e04bf9-ba32-4dba-ac6a-2667ba31dfa9',
        lost_due_payment_discount: 4999,
        lost_early_payment_discount: 3333,
        orderReference: '1 de 2',
        original_amount: 33333,
        product: 'Ano 5',
        product_id: '0e7a8070-74d5-4f84-b0d3-0b68acb5573e',
        status: 'OPEN',
        student: 'Caroline Rayssa Rezende',
        student_id: '635b4d54-f5a9-4283-a2cb-69b71059410a',
        isEnrollment: false,
      },
      {
        amount: 27712,
        contract_id: '381cc759-f28d-4f28-9c22-bd4ff1a6182a',
        competenceDate: '2022-08-15T21:00:00-03:00',
        current_early_payment_discount: 0,
        current_perpetual_discount: 6666,
        current_due_payment_discount: 0,
        current_fine: 533,
        current_interest: 512,
        discounts: [],
        due_date: '2022-08-15T21:00:00-03:00',
        id: 'ea08b929-e347-4011-ac04-ae24506aa597',
        installment_id: 'b1c8acdd-faa5-42cc-beae-4320d852622c',
        lost_due_payment_discount: 4999,
        lost_early_payment_discount: 3333,
        orderReference: '2 de 2',
        original_amount: 33333,
        product: 'Ano 5',
        product_id: '0e7a8070-74d5-4f84-b0d3-0b68acb5573e',
        status: 'OPEN',
        student: 'Caroline Rayssa Rezende',
        student_id: '635b4d54-f5a9-4283-a2cb-69b71059410a',
        isEnrollment: false,
      },
    ],
  },
]
