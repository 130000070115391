import { create } from 'zustand'

type GuardianStore = {
  pageToReturn?: string
  setPageToReturn: (pageToReturn?: string) => void
}

export const useGuardianNavigationStore = create<GuardianStore>()(set => ({
  pageToReturn: undefined,
  setPageToReturn: (pageToReturn?: string) => set(state => ({ ...state, pageToReturn })),
}))
