import { MenuItem } from '@material-ui/core'
import { formatCentsToReal } from 'src/shared/utils'

export const ListOptionInstallments = (maxInstallmentEnrollment: number, totalAmount: cents) => {
  const listItens = [
    <MenuItem key="1x" value={1}>
      1x de {formatCentsToReal(totalAmount)} - sem juros
    </MenuItem>,
  ]

  for (let i = 2; i <= maxInstallmentEnrollment; i++) {
    const text = `${i}x`
    listItens.push(
      <MenuItem key={text} value={i}>
        {text} de {formatCentsToReal(totalAmount / i)} - sem juros
      </MenuItem>
    )
  }
  return listItens
}
