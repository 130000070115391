import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks/useApi'

import { schoolsService } from '@/shared/services/schools'

import type { FetchSchoolResponseDTO } from '@/shared/services/schools/types'

export const useSchoolBySlug = (
  schoolSlug: string,
  options?: UseQueryOptions<FetchSchoolResponseDTO>
) => {
  const { apiClient } = useApi()

  const service = schoolsService(apiClient.privateApi)

  return useQuery<FetchSchoolResponseDTO>(
    ['schools', 'slug', schoolSlug],
    () => service.fetchSchoolBySlug(schoolSlug),
    options
  )
}

export const useSchoolById = (
  schoolId: string,
  options?: UseQueryOptions<FetchSchoolResponseDTO>
) => {
  const { apiClient } = useApi()

  const service = schoolsService(apiClient.privateApiV2)

  return useQuery<FetchSchoolResponseDTO>(
    ['schools', 'id', schoolId],
    () => service.fetchSchoolById(schoolId),
    options
  )
}
