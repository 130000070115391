import { ListGuardiansFilterFormType } from '../components/ListGuardiansFilter/types'

export const haveActiveFilters = (activeFilters: ListGuardiansFilterFormType): boolean => {
  if (!activeFilters) {
    return false
  }
  return Object.values(activeFilters).some(filter => {
    if (Array.isArray(filter)) {
      return filter.length > 0
    }

    return filter !== undefined
  })
}
