import { useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Text } from '@gravity/text'
import { Divider } from '@olaisaac/design-system'

import { Accordion, AccordionDetails, AccordionSummary } from './styles'

type InstallmentDrawerAccordionProps = {
  children?: React.ReactNode
  disabled?: boolean
  id: string
  title: string
}

export const InstallmentDrawerAccordion = ({
  children,
  disabled,
  id,
  title,
}: InstallmentDrawerAccordionProps) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)

  useEffect(() => {
    setIsAccordionExpanded(false)
  }, [id])

  return (
    <Accordion
      key={id}
      expanded={isAccordionExpanded}
      disabled={disabled}
      onChange={() => {
        setIsAccordionExpanded(!isAccordionExpanded)
      }}
    >
      <AccordionSummary id={id} expandIcon={<ExpandMoreIcon color="primary" />}>
        <Text variant="body-1-medium">{title}</Text>
      </AccordionSummary>
      <AccordionDetails>
        {children}
        <Divider style={{ margin: '16px 0 0 0' }} />
      </AccordionDetails>
    </Accordion>
  )
}
