import { FieldsStatus, ManualLiquidationFields } from './types'

export const DEFAULT_FIELD_STATUS = {
  isDirty: false,
  isOpen: false,
  name: '',
}

export const DEFAULT_FIELDS_STATUS: Record<ManualLiquidationFields, FieldsStatus> = {
  [ManualLiquidationFields.DISCOUNTS]: {
    ...DEFAULT_FIELD_STATUS,
    name: ManualLiquidationFields.DISCOUNTS,
  },
  [ManualLiquidationFields.PAID_DATE]: {
    ...DEFAULT_FIELD_STATUS,
    name: ManualLiquidationFields.PAID_DATE,
  },
  [ManualLiquidationFields.PAYMENT_FORM]: {
    ...DEFAULT_FIELD_STATUS,
    name: ManualLiquidationFields.PAYMENT_FORM,
  },
  [ManualLiquidationFields.SELECT_ACCOUNT]: {
    ...DEFAULT_FIELD_STATUS,
    name: ManualLiquidationFields.SELECT_ACCOUNT,
  },
  [ManualLiquidationFields.EDIT_REASON]: {
    ...DEFAULT_FIELD_STATUS,
    name: ManualLiquidationFields.EDIT_REASON,
  },
}

export const EDIT_FIELDS_STATUS = {
  ...DEFAULT_FIELDS_STATUS,
  [ManualLiquidationFields.EDIT_REASON]: {
    isOpen: true,
    isDirty: true,
    name: ManualLiquidationFields.EDIT_REASON,
  },
}

export const FIELDS_STATUS_SCHOOL = {
  ...DEFAULT_FIELDS_STATUS,
  [ManualLiquidationFields.PAYMENT_FORM]: {
    isOpen: true,
    isDirty: true,
    name: ManualLiquidationFields.PAYMENT_FORM,
  },
}

export const FIELDS_STATUS_ADMIN = {
  ...DEFAULT_FIELDS_STATUS,
  [ManualLiquidationFields.SELECT_ACCOUNT]: {
    isOpen: true,
    isDirty: true,
    name: ManualLiquidationFields.SELECT_ACCOUNT,
  },
}
