import { GROUP_NAME_ORDER } from '@/modules/access-management/constants/GROUP_NAME_ORDER'
import { REQUIRED_ROLE_FOR_GROUP_ASSIGN } from '@/modules/access-management/constants/REQUIRED_ROLE_FOR_GROUP_ASSIGN'

import type { User } from '@/modules/access-management/models/User'
import type { UserGroupNames } from '@/shared/integrations/sorting-hat/types'
import type { Roles } from '@/shared/contexts/Auth'

interface Props {
  loggedUserGroupName?: UserGroupNames
  loggedUserId: string
  userRoles: Set<Roles>
  userToBeUpdated: User
}

export const canUpdateUserAccess = ({
  loggedUserId,
  userRoles,
  loggedUserGroupName,
  userToBeUpdated,
}: Props) => {
  if (!loggedUserGroupName) return false
  if (userToBeUpdated.id === loggedUserId) return true

  const loggedUserAccessLevel = GROUP_NAME_ORDER[loggedUserGroupName]
  const userToBeEditedLevel = GROUP_NAME_ORDER[userToBeUpdated.group_name]

  const neededRoleToEditUser = REQUIRED_ROLE_FOR_GROUP_ASSIGN[userToBeUpdated.group_name]

  if (loggedUserAccessLevel > userToBeEditedLevel && userRoles.has(neededRoleToEditUser))
    return true

  return false
}
