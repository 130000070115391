import { UnguaranteedReport } from '@/modules/report/models/UnguaranteedReport'
import {
  Payout,
  PayoutStatus,
  PayoutVersion,
  ReportAggregations,
} from '@/modules/report/models/report'
import { Events2, FinancialDetail } from '@/shared/interfaces/payout'

const events1: Events2 = {
  EXISTING_CONTRACT: 1,
}

const financialDetail1: FinancialDetail = {
  total: -26700000,
  total_expected_base_amount: 0,
  total_base_amount: 0,
  total_fee: 26700000,
  events: events1,
}

const agregation1: ReportAggregations = {
  unique_students: 1,
  platform_transaction_count: 1,
  financial_details: financialDetail1,
}

const payout1: Payout = {
  due_date: '2021-09-30',
  status: PayoutStatus.OPEN,
  version: PayoutVersion.DASHBOARD_PV,
}

const payout2: Payout = {
  due_date: '2021-09-30',
  status: PayoutStatus.PAID,
  version: PayoutVersion.DASHBOARD_PV,
}

const report1: UnguaranteedReport = {
  take_rate: 0.1,
  bank_transfers: [],
  agregations: agregation1,
  payout_id: 'a5571533-466c-4feb-9c01-49d096ad4d07',
  started_at: new Date('2022-09-17T17:00:00Z'),
  ended_at: new Date('2022-09-23T17:00:00Z'),
  payout: payout1,
}

const report2: UnguaranteedReport = {
  take_rate: 0.1,
  bank_transfers: [],
  agregations: agregation1,
  payout_id: 'a5571533-466c-4feb-9c01-49d096ad4d08',
  started_at: new Date('2022-09-24T17:00:00Z'),
  ended_at: new Date('2022-09-30T17:00:00Z'),
  payout: payout2,
}

const report3: UnguaranteedReport = {
  take_rate: 0.1,
  bank_transfers: [],
  agregations: agregation1,
  payout_id: 'a5571533-466c-4feb-9c01-49d096ad4d09',
  started_at: new Date('2022-10-01T17:00:00Z'),
  ended_at: new Date('2022-10-03T17:00:00Z'),
  payout: payout1,
}

const report4: UnguaranteedReport = {
  take_rate: 0.1,
  bank_transfers: [],
  agregations: agregation1,
  payout_id: 'a5571533-466c-4feb-9c01-49d096ad4d10',
  started_at: new Date('2022-10-04T17:00:00Z'),
  ended_at: new Date('2022-10-07T17:00:00Z'),
  payout: payout1,
}

export const unguaranteedList = {
  data: [report1, report2, report3, report4],
}
