import { ChoiceChip } from '@gravity/chip'

import type { SearchType } from '@/modules/school-home/types/SearchType'
import { SEARCH_TYPES } from '@/modules/school-home/constants/SEARCH_TYPES'

import { Container } from './styles'

interface FilterSearchProps {
  onSelectedFilter: (filter: SearchType) => void
  selectedFilter: string
}

const SEARCH_CATEGORY_LABELS: Record<SearchType, string> = {
  all: 'Todos',
  students: 'Alunos',
  guardians: 'Responsáveis',
}

export const FiltersSearch = ({ onSelectedFilter, selectedFilter }: FilterSearchProps) => {
  return (
    <Container>
      {Object.entries(SEARCH_TYPES).map(([, value]) => (
        <ChoiceChip
          key={value}
          selected={selectedFilter === value}
          onSelected={value => onSelectedFilter(value as SearchType)}
          text={SEARCH_CATEGORY_LABELS[value]}
          value={value}
          data-testid={value}
        />
      ))}
    </Container>
  )
}
