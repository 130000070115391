import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  svg {
    box-sizing: content-box;
  }

  gap: ${({ theme }) => theme.gravity.spacing[8]};

  .filter-group {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.gravity.spacing[8]};
  }
`
