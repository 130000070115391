export const groupNameStudents = (listName, maxCaracter) => {
  let left = 0
  let str = listName[0].split(' ', 1)[0]
  for (let i = 1; i < listName.length && left === 0; i++) {
    const firstName = listName[i].split(' ', 1)[0]
    if (str.length + firstName.length > maxCaracter) {
      left = i
    } else {
      str += ', ' + firstName
    }
  }
  return !left ? str : (str += ', +' + (listName.length - left))
}
