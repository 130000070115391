import { FC } from 'react'
import styled from 'styled-components'
import { DiscountType, Receivable } from 'src/shared/interfaces'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { formatCentsToReal } from 'src/shared/utils'
import FinancialSummaryDiscount from './FinancialSummaryDiscount'

const StyledTypography = styled(Typography)``

export type ReceivableFinancialSummaryProps = {
  isAgglutinated?: boolean
  receivable: Receivable
}

const ReceivableFinancialSummary: FC<ReceivableFinancialSummaryProps> = ({
  isAgglutinated,
  receivable,
}) => {
  const labelBaseAmount = isAgglutinated ? 'Novo valor padrão' : 'Valor padrão'

  const expectedLabel = receivable?.total_amount_paid > 0 ? 'Valor pago' : 'Valor esperado'
  const expectedValue = receivable?.current_amount

  const discounts = receivable?.discounts

  const filterDiscountsByType = (discountType: DiscountType) =>
    discounts?.filter(discount => discount.type === discountType)

  const perpetualDiscounts = filterDiscountsByType(DiscountType.PERPETUAL)
  const earlyPaymentDiscounts = filterDiscountsByType(DiscountType.EARLY_PAYMENT)
  const duePaymentDiscounts = filterDiscountsByType(DiscountType.DUE_PAYMENT)
  const additionalDiscounts = filterDiscountsByType(DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION)

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1">{labelBaseAmount}</Typography>
        <Typography variant="body1">{formatCentsToReal(receivable?.original_amount)}</Typography>
      </Box>
      <Box
        pt={1}
        pb={1}
        display="flex"
        justifyContent="space-between"
        data-testid={`${DiscountType.PERPETUAL}-summary`}
      >
        <Typography variant="body1">Desconto permanente</Typography>
        <Typography variant="body1">
          -{formatCentsToReal(receivable?.current_perpetual_discount)}
        </Typography>
      </Box>
      {perpetualDiscounts?.map((perpetualDiscount, index) => (
        <FinancialSummaryDiscount
          key={`${DiscountType.PERPETUAL}-${index}`}
          testId={`${DiscountType.PERPETUAL}-${index}`}
          discountType={DiscountType.PERPETUAL}
          discount={perpetualDiscount}
        />
      ))}
      <Box
        pt={1}
        pb={1}
        display="flex"
        justifyContent="space-between"
        data-testid={`${DiscountType.DUE_PAYMENT}-summary`}
      >
        <Typography variant="body1">Desconto de pontualidade</Typography>
        <Typography variant="body1">
          -{formatCentsToReal(receivable?.current_due_payment_discount)}
        </Typography>
      </Box>
      {duePaymentDiscounts?.map((duePaymentDiscount, index) => (
        <FinancialSummaryDiscount
          key={`${DiscountType.DUE_PAYMENT}-${index}`}
          testId={`${DiscountType.DUE_PAYMENT}-${index}`}
          discountType={DiscountType.DUE_PAYMENT}
          discount={duePaymentDiscount}
        />
      ))}
      <Box
        pt={1}
        pb={1}
        display="flex"
        justifyContent="space-between"
        data-testid={`${DiscountType.EARLY_PAYMENT}-summary`}
      >
        <Typography variant="body1">Desconto de antecipação</Typography>
        <Typography variant="body1">
          -{formatCentsToReal(receivable?.current_early_payment_discount)}
        </Typography>
      </Box>
      {earlyPaymentDiscounts?.map((earlyPaymentDiscount, index) => (
        <FinancialSummaryDiscount
          key={`${DiscountType.EARLY_PAYMENT}-${index}`}
          testId={`${DiscountType.EARLY_PAYMENT}-${index}`}
          discountType={DiscountType.EARLY_PAYMENT}
          discount={earlyPaymentDiscount}
        />
      ))}
      <Box
        pt={1}
        pb={1}
        display="flex"
        justifyContent="space-between"
        data-testid={`${DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION}-summary`}
      >
        <Typography variant="body1">Desconto na baixa</Typography>
        <Typography variant="body1">
          -{formatCentsToReal(receivable?.current_additional_discount)}
        </Typography>
      </Box>
      {additionalDiscounts?.map((additionalDiscount, index) => (
        <FinancialSummaryDiscount
          key={`${DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION}-${index}`}
          testId={`${DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION}-${index}`}
          discountType={DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION}
          discount={additionalDiscount}
        />
      ))}
      {(receivable?.credit_card_fee ?? 0) > 0 && (
        <Box pt={1} pb={1} display="flex" justifyContent="space-between">
          <Typography variant="body1">Taxa do cartão</Typography>
          <Typography variant="body1">{formatCentsToReal(receivable?.credit_card_fee)}</Typography>
        </Box>
      )}
      <Box pt={1} pb={1} display="flex" justifyContent="space-between">
        <Typography variant="body1">Multa</Typography>
        <Typography variant="body1">{formatCentsToReal(receivable?.current_fine)}</Typography>
      </Box>
      <Box pb={2} display="flex" justifyContent="space-between">
        <Typography variant="body1">Juros</Typography>
        <Typography variant="body1">{formatCentsToReal(receivable?.current_interest)}</Typography>
      </Box>
      <Divider />
      <Box pt={2} pb={8} display="flex" justifyContent="space-between">
        <StyledTypography variant="subtitle1">{expectedLabel}</StyledTypography>
        <StyledTypography variant="subtitle1">{formatCentsToReal(expectedValue)}</StyledTypography>
      </Box>
    </>
  )
}

export default ReceivableFinancialSummary
