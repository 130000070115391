import { ContractStatus, DebtStatus } from 'src/shared/interfaces'
import { FixedStudentsFilterType } from '../types'

/**
 * Constant that represents all contract not-dynamic filters
 */
export const FIXED_STUDENTS_FILTER_OPTIONS: FixedStudentsFilterType[] = [
  {
    filterName: 'debtStatus',
    options: [
      { label: 'Em dia', value: DebtStatus.UP_TO_DATE },
      { label: 'Atenção', value: DebtStatus.WARNING },
      { label: 'Com pendência', value: DebtStatus.OVERDUE },
    ],
  },
  {
    filterName: 'contractStatus',
    options: [
      { label: 'Ativo', value: ContractStatus.OPEN },
      { label: 'Inativo', value: ContractStatus.CANCELED },
    ],
  },
]
