import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

type LocationStateStudent = {
  id: uuid | null
  name: string
}

export const useIsReenrollment = (): { isReenrollment: boolean; student: LocationStateStudent } => {
  const location = useLocation<{ studentID: uuid; studentName: string }>()
  const [isReenrollment, setIsReenrollment] = useState(false)
  const [student, setStudent] = useState<LocationStateStudent>({ id: null, name: '' })

  useEffect(() => {
    if (location.state?.studentID) {
      setIsReenrollment(true)
      setStudent({
        id: location.state.studentID,
        name: location.state.studentName,
      })
    }
  }, [location])

  return { isReenrollment, student }
}
