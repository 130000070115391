import { useApi } from '@/shared/hooks/useApi'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { userProfileService } from '@/modules/access-management/services/user-profile'
import {
  UpdateProfileResponse,
  UpdateProfileRequest,
} from '@/modules/access-management/services/user-profile/types'

export const useMutateOnUpdateUserProfile = (
  options: UseMutationOptions<UpdateProfileResponse, unknown, UpdateProfileRequest, unknown>
) => {
  const { apiClient } = useApi()

  const service = userProfileService(apiClient.getClients().bffApi)

  return useMutation(service.updateProfile, options)
}
