import { FiltersInstallmentsFormType } from '../components/GuardianDetailsInstallmentsFilter/types'
import { InstallmentsFiltersType } from '../types'

/**
 * @description Transform filters form data to filters to be used in API.
 * @param data Filters form data.
 * @returns Filters to be used in API.
 */
export const updatedFiltersForm = (data: FiltersInstallmentsFormType) => {
  return Object.keys(data).reduce((acc, key) => {
    const filter = data[key as keyof FiltersInstallmentsFormType]

    if (Array.isArray(filter) && filter.length === 0) return acc

    if (!Array.isArray(filter) && !filter) return acc

    return {
      ...acc,
      [key]: filter,
    }
  }, {} as InstallmentsFiltersType)
}
