import CircularProgress from '@material-ui/core/CircularProgress'

import * as Styled from './styles'

export const Loader = () => {
  return (
    <Styled.Container>
      <CircularProgress />
    </Styled.Container>
  )
}
