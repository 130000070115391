import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { ExpandLess } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { Button, ButtonGroup } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import PopoverButton from 'src/shared/components/PopoverButton'

type ContractListActionButtonProps = {
  handleAddContract: () => void
  handleGoToCampaign: () => void
}

const ContractListActionButton = ({
  handleAddContract,
  handleGoToCampaign,
}: ContractListActionButtonProps) => {
  const isCampaignEnabled = false // Disable campain while its not validate for 2024
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const options = [
    {
      label: 'Rematrícula em lote',
      tag: 'Novo',
      handleOnClick: () => handleGoToCampaign(),
    },
    {
      label: 'Adicionar contrato individual',
      handleOnClick: () => handleAddContract(),
    },
  ]

  return isCampaignEnabled ? (
    <PopoverButton
      options={options}
      title="Adicionar contrato"
      icon={<ExpandLess style={{ marginLeft: '10px' }} />}
    />
  ) : (
    <ButtonGroup>
      <Button
        variation="primary"
        startIcon={<AddIcon />}
        onClick={() => {
          handleAddContract()
          isInitialized &&
            eventDispatcherClient.sendEvent({
              scope: EventDispatcherEventScopes.CONTRACTS_PAGE,
              name: EnrollmentEventDispatcherEvents.CLICKED,
              action: 'click',
              customProperties: { $Button_name: 'ADICIONAR_CONTRATO' },
            })
        }}
      >
        Adicionar contrato
      </Button>
    </ButtonGroup>
  )
}

export default ContractListActionButton
