export type TableRowProps = {
  columns: string[]
  key: string
}
export type ColumnProps = {
  key: string
  label: string
}

export enum RowActionTypes {
  REMOVE = 'REMOVE',
  UNDO = 'UNDO',
}
