import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '../constants/MixpanelEventActions.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
const useMixpanelEventSender = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const sendCreditEventToMixpanel = (
    name: EventDispatcherEvents,
    action: MixpanelEventActions,
    customProperties: Record<string, any>,
    scope: EventDispatcherEventScopes = EventDispatcherEventScopes.CREDIT
  ) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: scope,
        name,
        action,
        customProperties,
      })
    }
  }
  return { sendCreditEventToMixpanel }
}
export default useMixpanelEventSender
