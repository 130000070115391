import { Skeleton } from '@gravity/skeleton'

import { Container } from './styles'

type ResultSectionPlaceholderProps = {
  items?: number
}

export const ResultSectionPlaceholder = ({ items = 3 }: ResultSectionPlaceholderProps) => {
  return (
    <Container data-testid="result-section-placeholder">
      <Skeleton variant="neutral" width={80} />

      <div>
        {Array.from(Array(items).keys()).map(id => (
          <div key={id} className="item-skeleton">
            <Skeleton className="avatar" variant="neutral" radius="full" width={24} height={24} />

            <div>
              <Skeleton variant="neutral" width={400} />
              <Skeleton variant="neutral" width={240} />
            </div>
          </div>
        ))}
      </div>
    </Container>
  )
}
