import { FooterContainer, FooterText } from './styles'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { Link } from '@gravity/link'

export const Footer = () => {
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  return (
    <FooterContainer>
      <FooterText>
        O isaac, por meio de plataforma online, atua como correspondente bancário da QI Tech para
        operações com CCBs (Cédulas de Crédito Bancário).
      </FooterText>

      <Link
        underline="hover"
        onClick={() => {
          sendCreditEventToMixpanel(
            EventDispatcherEvents.BUTTON_CLICKED,
            MixpanelEventActions.CLICK,
            { $button_name: CreditEventComponentNames.LEARN_MORE_BUTTON }
          )
          window.open(
            'https://centraldeajuda.olaisaac.io/credito-isaac/o-isaac-emite-creditos',
            '_blank'
          )
        }}
      >
        Saiba mais
      </Link>
    </FooterContainer>
  )
}
