import { Products } from '../pages/Products'

import type { RouteDefinition } from '@/shared/core/router'

export const routes: RouteDefinition[] = [
  {
    key: 'PRODUCTS',
    path: '/produtos',
    component: Products,
    exact: true,
  },
]
