import styled from 'styled-components'

export const Container = styled.section`
  .category {
    text-transform: capitalize;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.gravity.spacing[10]};

    margin-bottom: ${props => props.theme.gravity.spacing[10]};
  }
`
