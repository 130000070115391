import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApi } from 'src/shared/hooks'
import {
  GetGuardiansRequest,
  GetGuardiansResponse,
  StatusSummaryRequest,
  StatusSummaryResponse,
} from '@/modules/enrollment/services/enrollmentService/types'
import { ContractResponse } from '@/shared/interfaces'
import { fillMatriculasApiContracts } from '@/escolas/router/responsavel/[guardianId]/contratos/utils/fillMatriculasApiContracts'

export const StatusSummaryQuery = 'status-summary'

export const useGetGuardians = (
  queryName: string,
  props: GetGuardiansRequest,
  options?: UseQueryOptions<GetGuardiansResponse['data'], XMLHttpRequest>
) => {
  const { api } = useApi()

  return useQuery<GetGuardiansResponse['data'], XMLHttpRequest>(
    [queryName, props],
    async () => {
      const data = await api.enrollment.fetchStudentGuardians(props)
      return data.data
    },
    {
      retry: false,
      ...options,
    }
  )
}

export const useGetStatusSummary = (
  props: StatusSummaryRequest,
  options: UseQueryOptions<StatusSummaryResponse['data'], XMLHttpRequest> = {}
) => {
  const { api } = useApi()

  return useQuery<StatusSummaryResponse['data'], XMLHttpRequest>(
    [StatusSummaryQuery, props],
    async () => {
      const data = await api.enrollment.fetchStatusSummary(props)
      return data.data
    },
    {
      retry: false,
      ...options,
    }
  )
}

export const useGetMatriculasApiContracts = (studentId: string, schoolId: uuid) => {
  const { api } = useApi()

  return useQuery<ContractResponse[], XMLHttpRequest>(
    ['matriculas-api-contracts', studentId, schoolId],
    async () => {
      // TODO: matriculas-api should accept guardian_id as query param
      const nullUUID = '00000000-0000-0000-0000-000000000000'

      const matriculasApiContracts = await api.guardians.getMatriculasApiLegacyEnrollments(
        nullUUID,
        '0',
        schoolId,
        null,
        studentId
      )

      const filledContracts = (await fillMatriculasApiContracts(
        api,
        matriculasApiContracts
      )) as ContractResponse[]

      return filledContracts
    },
    {
      enabled: !!studentId,
      retry: false,
    }
  )
}
