import { Skeleton } from '@material-ui/lab'
import { Typography } from '@olaisaac/design-system'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { PageHeaderProps } from './types'
import * as Styled from './styles'
import { ContractStatus } from '@/shared/interfaces'

const formatDate = (value: datestring) =>
  dayjs(value).format('DD [de] MMM. [de] YYYY').toLocaleLowerCase()

export const PageHeader = ({ isLoading, contract }: PageHeaderProps) => {
  return (
    <Styled.Header>
      <Styled.Title>Editar contrato</Styled.Title>
      {isLoading || !contract ? (
        <Skeleton width={288} height={32} />
      ) : (
        <>
          <Typography as="span" variation="bodyLarge">
            {`Contrato criado em ${formatDate(contract.created_at)}`}
          </Typography>

          {contract?.status === ContractStatus.CANCELED && (
            <Typography as="span" variation="bodyLarge">
              &nbsp;/&nbsp;Contrato cancelado
            </Typography>
          )}
        </>
      )}
    </Styled.Header>
  )
}
