import { DialogContent } from '@olaisaac/design-system'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import * as Styled from './styles'
import { IconButton } from '@gravity/icon-button'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'

type CustomDialogProps = {
  isVisible: boolean
  onClose: () => void
  submitHandler: () => void
}

export const CustomDialog = ({ submitHandler, isVisible, onClose }: CustomDialogProps) => {
  return (
    <Styled.DialogCustom open={isVisible} onClose={onClose} fullWidth>
      <Styled.CardTitle>
        <Styled.StyledDialogTitle>Excluir pendências</Styled.StyledDialogTitle>
        <Styled.CloseButtonContainer>
          <IconButton onClick={onClose} size={1} color="accent" variant="ghost">
            <Icon path={mdiClose} />
          </IconButton>
        </Styled.CloseButtonContainer>
      </Styled.CardTitle>
      <DialogContent>
        <Text variant="body-1-regular">Ao excluir pendências:</Text>
        <Styled.List>
          <li>
            <Text variant="body-1-regular">
              As pendências serão excluídas e não aparecerão mais nesta lista;
            </Text>
          </li>
          <li>
            <Text variant="body-1-regular">
              As pendências não serão atualizadas no seu sistema;
            </Text>
          </li>
          <li>
            <Text variant="body-1-regular">
              As cobranças não serão liberadas e o responsável financeiro não receberá nenhuma
              cobrança relacionada às pendências.
            </Text>
          </li>
        </Styled.List>
      </DialogContent>
      <Styled.ContainerActions>
        <Button variant="solid" color="error" onClick={submitHandler}>
          Confirmar exclusão
        </Button>
      </Styled.ContainerActions>
    </Styled.DialogCustom>
  )
}
