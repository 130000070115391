import { Typography } from '@olaisaac/design-system'
import { Box, Tooltip } from '@material-ui/core'

import { Chip, StyledButton, Title, useStyles } from './styles'

import type { FilterCardProps } from './types'

export const CardToggle = (props: FilterCardProps) => {
  const { title, icon: Icon, details, description, isActive, onToggle } = props

  const classes = useStyles()

  const className = isActive ? 'active' : ''

  return (
    <Tooltip
      id={`${title}-tooltip`}
      title={description}
      classes={{ tooltip: classes.tooltip }}
      arrow
    >
      <StyledButton
        className={className}
        onClick={onToggle}
        aria-label={`Filtrar avisos da categoria ${title}`}
        aria-pressed={isActive}
        aria-describedby={`${title}-tooltip`}
      >
        <Box component="span" height={24} width={24}>
          {Icon}
        </Box>
        <Title aria-hidden>{title}</Title>
        <Typography as="span" style={{ fontSize: '1rem', lineHeight: 1.5 }}>
          {details}
        </Typography>
        {isActive && (
          <Box component="span" marginTop="8px">
            <Chip
              component="span"
              label={<Typography as="span">{title}</Typography>}
              variant="outlined"
              onDelete={onToggle}
              tabIndex={-1}
              aria-hidden
            />
          </Box>
        )}
      </StyledButton>
    </Tooltip>
  )
}
