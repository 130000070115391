import { create } from 'zustand'

import type { FormProps } from '@/modules/enrollment/pages/Campaigns/CampaignDetails/components/InstallmentForm/types'

export const initialFormData: FormProps = {
  custom_monthly_amount: 0,
  duration_months: '',
  installment_due_date_rule: null,
  due_day: null,
  due_date: null,
  due_date_month: null,
  discounts: [],
}

export type AddBulkInstallmentStore = {
  customInstallmentsFormData: Map<uuid, FormProps>
  excludedEnrollmentIds: uuid[]
  formData: FormProps
  removeCustomInstallmentsFormData: (enrollmentID: uuid) => void
  removeEnrollmentFromExcludedEnrollmentIds: (enrollmentID: uuid) => void
  resetStore: () => void
  setCustomInstallmentsFormData: (formData: FormProps, enrollmentID: uuid) => void
  setExcludedEnrollmentIds: (enrollmentID: uuid) => void
  setFormData: (formData: FormProps) => void
}

export const useAddBulkInstallmentStore = create<AddBulkInstallmentStore>()(set => ({
  formData: initialFormData,
  setFormData: (formData: FormProps) => set(state => ({ ...state, formData })),

  customInstallmentsFormData: new Map(),
  setCustomInstallmentsFormData: (formData: FormProps, enrollmentID: uuid) =>
    set(state => ({
      ...state,
      customInstallmentsFormData: state.customInstallmentsFormData.set(enrollmentID, formData),
    })),
  removeCustomInstallmentsFormData: (enrollmentID: uuid) =>
    set(state => {
      const map = new Map(state.customInstallmentsFormData)
      map.delete(enrollmentID)
      return {
        ...state,
        customInstallmentsFormData: map,
      }
    }),

  excludedEnrollmentIds: [],
  setExcludedEnrollmentIds: (enrollmentID: uuid) =>
    set(state => ({
      ...state,
      excludedEnrollmentIds: [...state.excludedEnrollmentIds, enrollmentID],
    })),
  removeEnrollmentFromExcludedEnrollmentIds: (enrollmentID: uuid) =>
    set(state => ({
      ...state,
      excludedEnrollmentIds: state.excludedEnrollmentIds.filter(e => e !== enrollmentID),
    })),
  resetStore: () =>
    set(state => ({
      ...state,
      formData: initialFormData,
      excludedEnrollmentIds: [],
      customInstallmentsFormData: new Map(),
    })),
}))
