import styled from 'styled-components'

export const ItemContainer = styled.div`
  &:not(:last-child) {
    border-right: 1px solid ${props => props.theme.primitiveTokens.colors.gray[10]};
    padding-right: 24px;
  }
`

export const PersonalDataContainer = styled.div`
  display: flex;
  gap: 24px;
  margin: 24px 0 32px;
  padding: 16px 16px;
  border: 1px solid;
  border-color: ${props => props.theme.primitiveTokens.colors.gray[10]};
  border-radius: 8px;
`
