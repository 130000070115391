import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'

interface Props {
  closeDialog: () => void
  productName: string
  studentName: string
  visible: boolean
}

export const CampaignErrorDialog = ({ visible, closeDialog, studentName, productName }: Props) => {
  const handleDialogOpenChange = (open: boolean) => {
    if (!open && visible) closeDialog()
  }

  return (
    <Dialog
      title="Não foi possível criar o contrato"
      backdrop
      open={visible}
      size={1}
      onOpenChange={handleDialogOpenChange}
      cancelButton={
        <Button variant="solid" onClick={closeDialog}>
          Ok, entendi
        </Button>
      }
      actionButton={<></>}
      content={
        <div>
          <Text variant="body-2-regular">
            {`${studentName} está em campanha de matrícula para ${productName}. Caso deseje criar o
            contrato mesmo assim, será necessário removê-lo(a) da campanha individualmente.`}
          </Text>
        </div>
      }
    />
  )
}
