import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import { Button } from '@olaisaac/design-system'
import styled from 'styled-components'

const Container = styled.div`
  max-width: 164px;
`

export const UndoCancelButton = ({ onClick }: { onClick: (e: any) => void }) => (
  <Container>
    <Button
      startIcon={<SettingsBackupRestoreIcon />}
      variation="ghost"
      style={{ textAlign: 'start' }}
      onClick={onClick}
    >
      Desfazer cancelamento
    </Button>
  </Container>
)
