import styled from 'styled-components'

export const CssStyles = styled.div`
  --swiper-theme-color: ${props => props.theme.gravity.colors['colors-background-accent-1']};
  --swiper-navigation-size: 16px;
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-horizontal-gap: ${props => props.theme.gravity.spacing[1]};

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    &:after {
      content: '';
    }

    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }

  .swiper-pagination-bullets {
    height: 16px;
  }
`

export type SliderContainerProps = {
  $hasPadding: boolean
}

export const SlideContainer = styled.div<SliderContainerProps>`
  padding: ${props =>
    props.$hasPadding
      ? `0 ${props.theme.gravity.spacing[6]} ${props.theme.gravity.spacing[4]} ${props.theme.gravity.spacing[6]}`
      : 0};
`
