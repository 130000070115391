import { AxiosInstance } from 'axios'
import qs from 'qs'
import {
  UseGuardianContractsQueryProps,
  UseGuardianContractsQueryResponse,
  UseGuardianFiltersOptionsQueryProps,
  UseGuardianQueryProps,
  UseGuardianQueryResponse,
  UseInstallmentsQueryProps,
  UseInstallmentsQueryResponse,
} from './types'

export const guardianService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetch Guardian data
     *
     * @param data.guardianId Guardian ID
     *
     * @returns Guardian details
     */
    fetchGuardian: async ({
      guardianId,
      schoolId,
    }: UseGuardianQueryProps): Promise<UseGuardianQueryResponse> => {
      const { data } = await fetcher.get<UseGuardianQueryResponse>(`/guardians/${guardianId}`, {
        params: { school_id: schoolId },
      })

      return data
    },

    /**
     * Function responsible for fetch all guardian's contracts data
     *
     * @param data.guardianId Guardian ID
     * @param data.filter Optional filters (query params)
     * @param data.schoolId School ID
     *
     * @returns All guardians's contracts
     */
    fetchGuardianContracts: async ({
      guardianId,
      filter,
      schoolId,
    }: UseGuardianContractsQueryProps): Promise<UseGuardianContractsQueryResponse> => {
      const { data } = await fetcher.get<UseGuardianContractsQueryResponse>(`/contracts`, {
        params: {
          ...filter,
          guardian_ids: [guardianId],
          school_id: schoolId,
        },
        paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma', skipNulls: true }),
      })

      return data
    },

    /**
     * Function responsible for fetch all installments of guardians's contracts
     *
     * @param data.guardianId Guardian ID
     * @param data.filter Optional filters (query params)
     *
     * @returns Installments of guardian's contracts
     */
    fetchInstallments: async ({
      schoolId,
      guardianId,
      filter,
    }: UseInstallmentsQueryProps): Promise<UseInstallmentsQueryResponse> => {
      const { data } = await fetcher.get<UseInstallmentsQueryResponse>(`/installments`, {
        params: {
          ...filter,
          guardian_ids: [guardianId],
          school_id: schoolId,
        },
        paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma', skipNulls: true }),
      })

      return data
    },
    /**
     * Function responsible for fetch all guardian's filters
     * @param data.guardianId Guardian ID
     * @param data.schoolId School ID
     * @returns Guardian's filters e.g { debt_status: [] }
     */
    fetchGuardianFiltersOptions: async ({
      guardianId,
      schoolId,
    }: UseGuardianFiltersOptionsQueryProps) => {
      const { data } = await fetcher.get(`/contracts/filters`, {
        params: { guardian_id: guardianId, school_id: schoolId },
      })

      return data
    },
  }
}
