import { useQuery } from '@tanstack/react-query'
import { SOFTCACHE_OPTIONS_BASE } from 'src/shared/constants'
import { useApi } from 'src/shared/hooks'
import { Contract, ContractStatus, MatriculasApiContractStatus } from 'src/shared/interfaces'
import { getContractsFromMatriculasApi } from '../router/responsavel/[guardianId]/contratos/utils/getContracts'
import { MATRICULAS_API_FIRST_YEAR } from '../components/contract/constants'
import { useParams } from 'react-router-dom'

export const useGetContract = (contractId: uuid, schoolId?: uuid) => {
  const { api } = useApi()
  const { guardianId } = useParams<{ guardianId: string }>()

  const { data: contract, isFetching, isFetched, refetch } = useQuery<Contract>(
    ['contract', contractId],
    async () => {
      let contract = await api.contracts
        .get(contractId, {
          include_guardian: true,
          include_installments: true,
          include_invoice: true,
          include_product: true,
          include_receivables: true,
          include_signable_document: true,
          include_student: true,
        })
        .catch(() => null)

      // required to load the bufferInstallments - and data from contracts that only exist on matriculas-api - at contract details drawer
      if (schoolId) {
        const { allBufferInstallments, allContracts } = await getContractsFromMatriculasApi(
          api,
          guardianId,
          contract?.reference_year || String(MATRICULAS_API_FIRST_YEAR),
          schoolId
        )

        if (!contract) {
          contract = allContracts?.find(c => c.id === contractId)
        }

        if (contract?.status === ContractStatus.CANCELED) {
          const { allContracts: allCanceledContracts } = await getContractsFromMatriculasApi(
            api,
            guardianId,
            contract?.reference_year || String(MATRICULAS_API_FIRST_YEAR),
            schoolId,
            MatriculasApiContractStatus.CANCELED
          )

          contract.pre_contract_installments = allCanceledContracts?.find(
            c => c.id === contractId
          )?.pre_contract_installments
        }

        return {
          ...contract,
          pre_contract_installments:
            contract?.pre_contract_installments ||
            allBufferInstallments.filter(
              bufferInstallment => bufferInstallment.contractID === contract?.id
            ),
        } as Contract
      }

      return contract
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: Boolean(contractId),
      staleTime: 10 * 60 * 1000,
      retry: false,
    }
  )

  return {
    isFetching,
    isFetched,
    contract,
    refetch,
  }
}
