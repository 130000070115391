import { useState } from 'react'
import { InfoLiquidationValues } from '../../types'
import { getInformationForPaymentDetailByInstallment } from '../../helper'
import { InstallmentV2 } from '@/modules/guardians/services/types'

const usePaymentDetail = (installment: InstallmentV2, shouldRemoveDiscountButton = true) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogInformation, setDialogInformation] = useState<InfoLiquidationValues>()

  const handleDialogInformation = (liquidationInfo, discountForm, discountInfo, paidDate) => {
    const {
      additional_discount_amount,
      apply_lost_due_payment_discount,
      apply_lost_early_payment_discount,
    } = discountForm.getValues()

    const addicionalDiscount = discountInfo.isAdditionalDiscountEnabled
      ? additional_discount_amount
      : 0

    const lostDueDiscount = apply_lost_due_payment_discount
      ? discountInfo.lostDuePaymentDiscount
      : 0

    const lostEarlyDiscount = apply_lost_early_payment_discount
      ? discountInfo.lostEarlyPaymentDiscount
      : 0

    const totalDiscount = Number(addicionalDiscount) + lostDueDiscount + lostEarlyDiscount

    const { liquidationValues } = getInformationForPaymentDetailByInstallment({
      installment,
      liquidationInfo,
      discountInfo,
      totalDiscount,
      shouldRemoveDiscountButton,
      paidDate,
    })

    setDialogInformation(liquidationValues)
    setOpenDialog(true)
  }

  const closeDialog = () => setOpenDialog(false)

  return { openDialog, dialogInformation, closeDialog, handleDialogInformation }
}

export default usePaymentDetail
