import styled from 'styled-components'
import { Alert } from '@material-ui/lab'
import { Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'

export const StudentNameTypography = styled(Typography)``

export const EditContractTypography = styled(Typography).attrs({
  variation: 'headlineDesktopMedium',
})`
  margin: 0;
`

export const GuardianNameTypography = styled(Typography)`
  color: #999999;
`

export const SchoolNameTypography = styled(Typography).attrs({
  variation: 'subtitleDesktopMedium',
  color: 'secondary',
})`
  margin-top: 40px;
  margin-bottom: 5px;
`

export const HeaderBox = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
})``

export const MarginHeaderBox = styled(HeaderBox)`
  margin-bottom: 40px;
`

export const ContractEditInfoAlert = styled(Alert).attrs({
  severity: 'info',
})`
  background-color: #ecedfb;
  color: #414141;
  margin-bottom: 48px;
  .MuiAlert-icon {
    color: #3d4ed7;
  }
`
