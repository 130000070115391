export const checkIfHasChanges = <k extends string, T extends unknown[]>(
  urlFilter: Record<k, T>,
  stateFilter: Record<k, T>
) => {
  const hasChanges = Object.keys(stateFilter).some(key => {
    const stringifiedStateFilter = stateFilter[key as k].join(',')
    const stringifiedUrlFilter = urlFilter[key as k].join(',')

    return stringifiedStateFilter !== stringifiedUrlFilter
  })

  return hasChanges
}
