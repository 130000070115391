import { MouseEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { Typography, Link, Button } from '@olaisaac/design-system'

import dayjs from 'dayjs'
import sanitizeHtml from 'sanitize-html'

import { Chip } from '../Chip'
import { ContentContainer } from '../ContentContainer'

import { useFormatNotificationLink } from '../../hooks'

import type { NotificationCardProps } from './types'

import theme from '@/shared/theme'

export const { spacing, colors } = theme.primitiveTokens
export const { link } = theme.humanizedTokens.components

export const NotificationCard = (props: NotificationCardProps) => {
  const { notification, path, onTitleLinkClick, onActionButtonClick } = props

  const [isRedirecting, setIsRedirecting] = useState(false)

  const history = useHistory()
  const { formattedLinkUrl } = useFormatNotificationLink(notification.linkUrl)

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    history.push(path)

    onTitleLinkClick(notification)
  }

  const handleActionButtonClick = () => {
    window.location.href = formattedLinkUrl
    setIsRedirecting(true)

    onActionButtonClick(notification.title)
  }

  return (
    <Box
      display="grid"
      gridGap={spacing[4]}
      padding={spacing[8]}
      border={`1px solid ${colors.gray[10]}`}
      borderRadius={spacing[2]}
      width={524}
      minHeight={176}
    >
      <Box display="grid" gridGap={spacing[2]}>
        <Box display="flex" gridGap={spacing[2]} alignItems="center">
          <Chip $variation={notification.category} size="small" label={notification.category} />

          <Typography
            variation="buttonLarge"
            color="secondary"
            style={{ fontSize: '0.875rem', lineHeight: 1.42857 }}
          >
            {dayjs(notification.date).format('DD/MM/YYYY')}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gridGap={spacing[1]}>
          <Link href={path} onClick={handleLinkClick}>
            <Typography
              as="h3"
              variation="headlineDesktopXsmall"
              style={{
                fontSize: '1.125rem',
                lineHeight: 1.55556,
                color: 'inherit',
              }}
            >
              {notification.title}
            </Typography>
          </Link>
          {/* Container which will load the html from beamer notification */}
          <ContentContainer
            $variation="normal"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(notification.contentHtml) }}
          />
        </Box>
      </Box>
      {notification?.linkUrl && (
        <Button
          style={{ width: 'fit-content' }}
          onClick={handleActionButtonClick}
          loading={isRedirecting}
        >
          {notification.linkText}
        </Button>
      )}
    </Box>
  )
}
