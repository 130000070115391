import * as Styled from './styles'
import { Tooltip } from '@gravity/tooltip'
import { Badge } from '@gravity/badge'

type Props = {
  mainProductName: string
  otherProductsNames: string[]
}
export const EnrollmentTableProducts = ({ mainProductName, otherProductsNames }: Props) => {
  const mainProductNameFormat = mainProductName || '-'
  const otherProductsNamesCount = otherProductsNames?.length || 0

  return (
    <Styled.ProductsContainer>
      {mainProductNameFormat}
      <Styled.OtherProductsContainer>
        {otherProductsNamesCount > 0 && (
          <Tooltip text={otherProductsNames.join(', ')}>
            <span>
              <Badge variant="soft" color="neutral">
                {'+' + otherProductsNamesCount}
              </Badge>
            </span>
          </Tooltip>
        )}
      </Styled.OtherProductsContainer>
    </Styled.ProductsContainer>
  )
}
