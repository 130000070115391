import { InstallmentRowType } from '@/modules/guardians/GuardianDetails/constants'

export const INSTALLMENT_ROW_TYPES = {
  [InstallmentRowType.ENROLLMENT_INSTALLMENT]: 'Pré-matrícula',
  [InstallmentRowType.INSTALLMENT]: 'Mensalidade',
  [InstallmentRowType.NEGOTIATION]: 'Negociação',
  [InstallmentRowType.NEGOTIATION_START]: 'Entrada da negociação',
}

export const NEW_INSTALLMENT_ROW_TYPES = {
  [InstallmentRowType.ENROLLMENT_INSTALLMENT]: 'Pré-matrícula',
  [InstallmentRowType.INSTALLMENT]: 'Mensalidade',
  [InstallmentRowType.NEGOTIATION]: 'Negociação',
  [InstallmentRowType.NEGOTIATION_START]: 'Negociação',
}
