import { Search } from '@olaisaac/design-system'
import { debounce } from 'throttle-debounce'

const debounceDelay = 900

type SearchWithDebounceProps = {
  placeholder: string
  setValue: (v: string) => void
  value: string
}

export const SearchWithDebounce = ({ value, setValue, placeholder }: SearchWithDebounceProps) => {
  return (
    <Search
      initialValue={value}
      placeholder={placeholder}
      onChange={debounce(debounceDelay, setValue)}
      onClear={() => setValue('')}
    />
  )
}
