import { useState, useRef } from 'react'
import MuiPopover from '@material-ui/core/Popover'
import { Chip, IconButton } from '@olaisaac/design-system'
import { useNavigation } from 'src/escolas/hooks'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'
import { EnrollmentPageItem } from './EnrollmentPageItem'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { ENROLLMENT_YEAR } from '@/modules/enrollment/constants/enrollment'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useSchoolEnrollmentCycles } from '@/modules/enrollment/hooks/useSchoolEnrollmentCycles'
import useRouterReferenceYear from '@/escolas/hooks/useRouterReferenceYear'
import * as Styled from './styles'
import { Box, MenuItem } from '@material-ui/core'
import { useSelectYear } from '@/modules/enrollment/pages/SchoolEnrollmentCycle/hooks/useSelectYear'

const IconButtonWrapper = styled.div`
  margin-left: 16px;
`

const StyledList = styled(props => <List {...props} />)`
  min-width: 225px;
`

export const StyledListItem = styled(props => <ListItem {...props} />)`
  min-height: 48px;
  font-size: 1rem;
  font-family: 'Roboto';
`
export function ContractYearPopover() {
  const anchorEl = useRef<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const { getNavigationUrl, navigateTo } = useNavigation()
  const referenceYear = useRouterReferenceYear()
  const isFlexibleEnrollmentCycleEnabled = useUnleashFlag(
    UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES
  )

  const { activeEnrollmentCycleYear: cycleYear, enrollmentCycleYears } = useSchoolEnrollmentCycles()
  const { handleChangeSelectedYear } = useSelectYear()
  const activeEnrollmentCycleYear = isFlexibleEnrollmentCycleEnabled ? cycleYear : ENROLLMENT_YEAR

  const contractPageYears = isFlexibleEnrollmentCycleEnabled
    ? enrollmentCycleYears
    : [2021, 2022, 2023, 2024]

  const currentYear = new Date().getFullYear()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const handlePopoverClose = () => {
    setOpen(false)
  }

  const handlePopoverClick = () => {
    setOpen(true)
  }

  const handleSelectYear = (year: number) => {
    handleChangeSelectedYear(year)
    return navigateTo({ path: `/contratos/${year}` })
  }

  return (
    <>
      {isFlexibleEnrollmentCycleEnabled ? (
        <Styled.Container>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Styled.SelectYearTitle>Ano letivo:</Styled.SelectYearTitle>
            <Styled.SelectYear
              value={referenceYear}
              onChange={evt => handleSelectYear(Number(evt.target.value))}
            >
              {contractPageYears
                .map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                    <Box flex={1} />
                    {year > currentYear && (
                      <Box display="inline-block" ml={1}>
                        <Chip label="Novo" variation="blue" />
                      </Box>
                    )}
                  </MenuItem>
                ))
                .reverse()}
            </Styled.SelectYear>
          </Box>
        </Styled.Container>
      ) : (
        <>
          <IconButtonWrapper>
            <IconButton ref={anchorEl} onClick={handlePopoverClick} variation="ghost">
              <ExpandMoreIcon />
            </IconButton>
          </IconButtonWrapper>

          <MuiPopover
            open={open}
            onClick={() =>
              isInitialized &&
              eventDispatcherClient.sendEvent({
                scope: EventDispatcherEventScopes.CONTRACTS_PAGE,
                name: EnrollmentEventDispatcherEvents.CLICKED,
                action: 'click',
                customProperties: { $Dropdown_name: 'DROPDOWN_PERIODO_LETIVO' },
              })
            }
            anchorEl={anchorEl.current}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <StyledList component="nav">
              {activeEnrollmentCycleYear && (
                <EnrollmentPageItem activeEnrollmentCycleYear={activeEnrollmentCycleYear} />
              )}
              {contractPageYears
                .filter(year => year !== activeEnrollmentCycleYear)
                .map(year => {
                  const path = getNavigationUrl({ path: `/contratos/${year}` })
                  return (
                    <StyledListItem key={year} button component="a" href={path}>
                      {`Contratos ${year}`}
                    </StyledListItem>
                  )
                })
                .reverse()}
            </StyledList>
          </MuiPopover>
        </>
      )}
    </>
  )
}

export default ContractYearPopover
