import styled from 'styled-components'
import { colors, fontSize, fontWeight, spacing } from '@gravity/tokens'
import { Card } from '@gravity/card'
import { Typography } from '@olaisaac/design-system'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[6]};
`

export const TitleText = styled(Typography)`
  font-size: ${fontSize['2xl']};
  font-weight: ${fontWeight.medium};
  margin-left: ${spacing[8]};
  margin-bottom: ${spacing[6]};
`

export const StyledCard = styled(Card)`
  border-radius: 2rem;
  border: none;
  background-color: ${colors['colors-background-accent-3']};
`
