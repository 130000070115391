import { Invoice, InvoiceProviders } from '@/shared/interfaces'

export const invoice1: Invoice = {
  amount: 100000,
  checkout_url: 'https://faturas.iugu.com/2093b753-1525-47ba-bd79-3caa2300dd00-ebed',
  created_at: '2021-01-27T17:29:18.701Z',
  due_date: '2021-05-05T16:50:26.393-03:00',
  id: '676a30d3-8f80-4319-9e19-3f6ea82b05a3',
  paid_amount: 10000,
  payment_provider: InvoiceProviders.IUGU,
  referral: '2093B753152547BABD793CAA2300DD00',
  available_payment_methods: ['bank_slip', 'pix'],
}

export const invoice2: Invoice = {
  amount: 100000,
  checkout_url: 'https://faturas.iugu.com/cb33edd5-1d34-4652-aabc-8a15f2fa781a-ebed',
  created_at: '2021-01-27T17:29:18.701Z',
  due_date: '2021-05-05T16:50:26.393-03:00',
  id: '0870b8c6-bd37-43bd-bef5-05acc0361f70',
  paid_amount: 10000,
  payment_provider: InvoiceProviders.IUGU,
  referral: '',
}
