import styled, { css } from 'styled-components'
import { Skeleton } from '@gravity/skeleton'

export const Container = styled.div<{ backgroundColor?: string; color?: string }>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  grid-gap: ${props => props.theme.gravity.spacing[8]};

  padding-block: ${props => props.theme.gravity.spacing[8]};
  padding-inline: 6rem;
  margin-bottom: ${props => props.theme.gravity.spacing[8]};

  width: 100%;
  border: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-3']};
  border-radius: 8px;
  background-color: ${props => props.backgroundColor || '#FFFFFF00'};

  .banner-title {
    margin-bottom: ${props => props.theme.gravity.spacing[4]};
  }

  .banner-subtitle {
    margin-bottom: ${props => props.theme.gravity.spacing[8]};
    color: ${props => props.color || props.theme.gravity.colors['colors-text-main-3']};
  }

  ${props =>
    !!props.color &&
    css`
      .banner-title {
        color: ${props.color};
      }
    `}

  &.skeleton {
    margin-bottom: ${props => props.theme.gravity.spacing[8]};
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TitlePlaceholder = styled(Skeleton)`
  margin-bottom: ${props => props.theme.gravity.spacing[4]};
`

export const ExplanationPlaceholder = styled(Skeleton)`
  margin-top: ${props => props.theme.gravity.spacing[4]};
  margin-bottom: ${props => props.theme.gravity.spacing[8]};
`
