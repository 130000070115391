import { ReEnrollmentSubStatus, ReenrollmentStatus } from '@/shared/interfaces'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ErrorIcon from '@material-ui/icons/Error'
import { Link, Typography } from '@olaisaac/design-system'
import React from 'react'
import styled from 'styled-components'
import { CardContent, CardVariant, statusDetailCards } from '../statusDetailCards'
import { useNavigation } from '@/escolas/hooks'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { colors } from '@gravity/tokens'

const WarningIcon = styled(AccessTimeIcon)`
  color: ${colors['colors-background-neutral-5']};
`
const AlertIcon = styled(ErrorIcon)`
  color: ${colors['colors-background-neutral-5']};
`
const SuccessIcon = styled(CheckCircleIcon)`
  color: ${colors['colors-background-neutral-5']};
`

const Icon = ({ variant }: { variant: CardVariant }) => {
  return {
    [CardVariant.WARNING]: <WarningIcon />,
    [CardVariant.ALERT]: <AlertIcon />,
    [CardVariant.SUCCESS]: <SuccessIcon />,
  }[variant]
}

const CardContainer = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  border-radius: 8px;
  background-color: #fff;
  min-width: 360px;
  max-width: 360px;
  display: flex;
  gap: 12px;
  align-items: start;
  align-self: stretch;
`

const CardTitle = styled(Typography)`
  line-height: 24px;
  margin-bottom: 4px;
`

const NoTitleContainer = styled.div`
  min-width: 200px;
  max-width: 200px;
`

// Remoção da tela temporária
const ExternalLinkContainer = styled.div`
  display: flex;
  justify-content: end;
  display: none;
`

const ExternalLink = styled(Link)`
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin: 8px 0 4px;
  &::after {
    content: ' ›';
  }
`

const Card = (data: CardContent) => {
  const { text, type } = data

  const { getNavigationUrl } = useNavigation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  if (type === 'textOnly') {
    return (
      <NoTitleContainer>
        <Typography variation="caption">{text}</Typography>
      </NoTitleContainer>
    )
  }

  const { title, variant, externalLink } = data
  return (
    <CardContainer>
      <Icon variant={variant} />
      <div>
        <CardTitle variation="bodyLarge">
          <strong>{title}</strong>
        </CardTitle>
        <Typography variation="bodySmall">{text}</Typography>
        {externalLink && (
          <ExternalLinkContainer>
            <ExternalLink
              href={getNavigationUrl({ path: externalLink.link })}
              target="_blank"
              onClick={() =>
                isInitialized &&
                eventDispatcherClient.sendEvent({
                  scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
                  name: EnrollmentEventDispatcherEvents.CLICKED,
                  action: 'click',
                  customProperties: {
                    $Button_name: 'CALL_TO_ACTION',
                    $value: title,
                    $Card_name: 'DETALHE_DA_SITUACAO_DE_MATRICULA',
                  },
                })
              }
            >
              {externalLink.label}
            </ExternalLink>
          </ExternalLinkContainer>
        )}
      </div>
    </CardContainer>
  )
}

const CardsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  overflow-x: auto;
  margin: 24px -64px 0;
  padding: 0 64px 32px;
`

const Divider = styled.span`
  border-top: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  min-width: 16px;
  height: 2px;
`

const EnrolledTextContainer = styled.div`
  margin: 24px 0 32px;
  display: flex;
  gap: 8px;
  align-items: center;
`

const EnrolledText = ({ variant, text }: { text: string; variant: CardVariant }) => {
  return (
    <EnrolledTextContainer>
      <Icon variant={variant} />
      <Typography variation="bodyLarge">{text}</Typography>
    </EnrolledTextContainer>
  )
}

type StatusDetailsCardsProps = {
  details: ReEnrollmentSubStatus[]
  enrollmentYear: number
  mainGuardianId: string
  status: ReenrollmentStatus
  studentId: string
}

export const StatusDetailCards = ({
  enrollmentYear,
  status,
  details,
  mainGuardianId,
  studentId,
}: StatusDetailsCardsProps) => {
  const cards = statusDetailCards(status, details, enrollmentYear, mainGuardianId, studentId)

  if (status === ReenrollmentStatus.ENROLLED) {
    return <EnrolledText variant={CardVariant.SUCCESS} text={cards[0].text} />
  }
  if (
    status === ReenrollmentStatus.ENROLLMENT_IN_PROGRESS &&
    details.includes(ReEnrollmentSubStatus.STUDENT_RECORD_PENDING)
  ) {
    return <EnrolledText variant={CardVariant.WARNING} text={cards[0].text} />
  }
  if (status === ReenrollmentStatus.UNAVAILABLE_AT_MOMENT) {
    return (
      <EnrolledText
        variant={CardVariant.WARNING}
        text="Desculpe o inconveniente. Estamos trabalhando para resolver o problema e exibir a situação de matrícula do aluno. Por favor, tente novamente mais tarde."
      />
    )
  }
  return (
    <CardsContainer>
      {cards.map((value, index) => {
        return (
          <React.Fragment key={index}>
            <Card {...value} />
            {index < cards.length - 1 && <Divider />}
          </React.Fragment>
        )
      })}
    </CardsContainer>
  )
}
