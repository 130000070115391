import { useRef } from 'react'
import { AccordionProps } from './types'
import { AccordionContent, AccordionContainer } from './style'
import AccordionHeader from './components/AccordionHeader'

const Accordion = ({ children, title, step, disableClosing = false }: AccordionProps) => {
  const accElem = useRef<Element>(null)
  const icon: Element = accElem.current?.children[0].children[1]
  if (disableClosing) icon?.setAttribute('style', 'display: none;')

  return (
    <AccordionContainer
      data-testid={`accordion-container-${title}`}
      {...(disableClosing && { expanded: true, ref: accElem })}
    >
      <AccordionHeader title={title} step={step} />
      <AccordionContent data-testid={`accordion-content-${title}`}>{children}</AccordionContent>
    </AccordionContainer>
  )
}

export default Accordion
