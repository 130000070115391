import { Box } from '@material-ui/core'
import type { AgreementSimulationCombined } from '@/modules/guardians/models/agreement'
import AgreementCard from '../AgreementCard'
import { StyledAddIcon, WrapperIcon, WrapperList } from './styles'

export interface AgreementListProps {
  agreements: AgreementSimulationCombined[]
}

const AgreementList = ({ agreements }: AgreementListProps) => (
  <WrapperList>
    {agreements.map((agreement, index) => (
      <Box key={`${index + 1}-payment`}>
        {index / 2 !== 0 && (
          <WrapperIcon>
            <StyledAddIcon fontSize="small" />
          </WrapperIcon>
        )}

        <AgreementCard agreement={agreement} index={index} />
      </Box>
    ))}
  </WrapperList>
)

export default AgreementList
