import styled from 'styled-components'
import { TableControlBar } from '@olaisaac/design-system'

export const PageTableControlBar = styled(TableControlBar)<{
  $isActionDrawerOpen?: boolean
  sizeRight?: string
}>(({ $isActionDrawerOpen, sizeRight = '25%' }) => ({
  bottom: 0,
  left: 96,
  position: 'fixed',
  right: $isActionDrawerOpen ? sizeRight : 0,
  zIndex: 1,
}))
