import type { FinancialCategories } from '@/modules/report/models/FinancialCategories'

/**
 * Constant that defines the tooltips to each financial category
 */
export const FINANCIAL_CATEGORIES_TOOLTIPS: Record<FinancialCategories, string | undefined> = {
  TRANSACTIONS: 'Soma das mensalidades deste ciclo de repasse.',
  FEE: 'A taxa isaac é aplicada em cada parcela de mensalidade.',
  EVENTS: 'Alterações feitas em parcelas de mensalidade que afetam o valor final.',
  OPERATIONAL_ADJUSTMENTS: 'Ajustes do repasse atual ou de repasses anteriores.',
  UNRECONCILED_PAYMENTS: 'Mensalidades pagas com pagamentos não conciliados.',
  BONUS_AND_INCENTIVES: 'Bonificações e incentivos contratuais.',
  CREDIT: 'Crédito solicitado pela escola.',
  CONTRACTUAL_AGREEMENT: 'Valores referentes a acordos contratuais.',
  BALANCE_TRANSFER: 'Repasses de matrículas negativos são descontados do repasse de mensalidades.',
  PAST_TUITION_DEBTS:
    'Repasses de mensalidades com valores negativos são descontados no próximo ciclo.',
  ADDITIONAL_INCOME: undefined,
  ADDITIONAL_DEDUCTIONS: undefined,
}
