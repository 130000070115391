import { AxiosInstance } from 'axios'

import type { Product } from '@/modules/products/models/Product'
import type {
  CreateProductRequest,
  FetchProductsRequest,
  FetchProductsResponse,
  UpdateProductRequest,
} from './types'

export const productsService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetch all school products
     *
     * @param data.schoolId School ID
     * @param data.pagination Pagination properties
     *
     * @returns List of products of the provided school
     */
    fetchProducts: async ({
      schoolId,
      pagination = {},
      ids = [],
    }: FetchProductsRequest): Promise<FetchProductsResponse> => {
      const { data } = await fetcher.get<FetchProductsResponse>('product', {
        params: {
          school_id: schoolId,
          ids: ids,
          ...pagination,
        },
      })

      return data
    },

    /**
     * Function responsible for product creation
     *
     * @param product Product data
     *
     * @returns Created product
     */
    createProduct: async (product: CreateProductRequest): Promise<Product> => {
      const { data: response } = await fetcher.post<{ data: Product }>('product', product)

      return response.data
    },

    /**
     * Function responsible for product update
     *
     * @param data.id ID of the product to be updated
     * @param data.data Object with updated properties
     *
     * @returns Updated product
     */
    updateProduct: async ({ id, data }: UpdateProductRequest): Promise<Product> => {
      const { data: response } = await fetcher.patch<{ data: Product }>(`/product/${id}`, data)

      return response.data
    },

    /**
     * Function responsible for product deletion
     *
     * @param id ID of the product to be deleted
     */
    deleteProduct: async (id: string): Promise<void> => {
      await fetcher.delete(`/product/${id}`)
    },
  }
}
