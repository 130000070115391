import { useHistory } from 'react-router-dom'
import { TextField, Box, CircularProgress, InputAdornment } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Search as SearchIcon } from '@material-ui/icons'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useSchools } from '@/escolas/hooks'

import { ON_SELECT_SCHOOL_EVENT_SESSION_STORAGE_KEY } from '@/modules/app/constants'

import { School } from 'src/shared/interfaces'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export const AutocompleteInput = () => {
  const { push } = useHistory()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { schools, isFetching } = useSchools()

  const handleSelectSchool = (school: School) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.SCHOOL_SELECTION,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $form_action: 'Seleção de escola',
          $school: school.name,
          $school_id: school.id,
          $selected_school_slug: school.slug,
        },
      })

    sessionStorage.setItem(ON_SELECT_SCHOOL_EVENT_SESSION_STORAGE_KEY, 'true')

    push(school.slug)
  }

  return (
    <Box bgcolor="common.white">
      {isFetching ? (
        <CircularProgress />
      ) : (
        <Autocomplete
          id="escolas-opcoes"
          data-testid="school-selector"
          options={schools}
          getOptionLabel={school => school.name}
          noOptionsText="Nenhuma escola encontrada"
          disableClearable
          forcePopupIcon={false}
          onChange={(_, data) => {
            const school = data as School
            handleSelectSchool(school)
          }}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Procure uma escola"
              variant="outlined"
            />
          )}
        />
      )}
    </Box>
  )
}
