export type SchoolChargeConfig = {
  charge_operation?: string
  is_outsourced_provider?: boolean
  reporting_period?: string
}

export enum SchoolChargeOperation {
  default = 'default',
  isaacPay = 'isaac-pay',
}

export enum SchoolReportingPeriod {
  daily = 'daily',
  weekly = 'weekly',
}
