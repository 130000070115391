// This event is dispatched by the userguiding
export const ON_PRESS_NEVER_SHOW_AGAIN_GUIDE_EVENT = 'OnPressNeverShowAgain-71676'

export const LOCALSTORAGE_USERGUIDING_STATE_BY_USERS = '@olaisaac/userguiding-state-by-users'

export enum InvoiceErrors {
  DEFAULT = 'DEFAULT',
  DUE_DATE = 'DUE_DATE',
  TAX_ID = 'PAYER.CPF_CNPJ',
  ZIPCODE = 'PAYER.ADDRESS.ZIP_CODE',
}

export enum EditGuardianFormMode {
  EDIT_MODE = 'EDIT_MODE',
  VIEW_MODE = 'VIEW_MODE',
}

export enum EditGuardianFormSection {
  ADDRESS_SECTION = 'abrirEndereco',
  GUARDIAN_SECTION = 'abrirResponsavel',
  STUDENT_SECTION = 'abrirAluno',
}

export const MAX_INTERVAL_VALUE = 30000
export const INITIAL_INTERVAL_VALUE = 10000
export const INCREMENT_INTERVAL_VALUE = 10000
export const invoicesMappedErrors = [
  'DEFAULT',
  'DUE_DATE',
  'PAYER.CPF_CNPJ',
  'PAYER.ADDRESS.ZIP_CODE',
]

export const MAX_ATTEMPTS = 3
export const MAX_RETRIES = 2
export const REFETCH_INTERVAL = 3000

export enum CONTRACT_TABLE_TAB {
  CONTRACTS = 0,
  INSTALLMENTS = 1,
}
