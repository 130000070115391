import { useMutation } from '@tanstack/react-query'
import { useApi } from '@/shared/hooks/useApi'
import { CreateAnticipationSimulationRequest } from '@/shared/services/credit/types'

export const createAnticipationSimulationQueryKey = 'create-anticipation-simulation'

export const useCreateAnticipationSimulation = () => {
  const { api } = useApi()

  return useMutation(
    [createAnticipationSimulationQueryKey],
    async (body: CreateAnticipationSimulationRequest) =>
      api.credit.createAnticipationSimulation(body),
    { retry: 2, useErrorBoundary: true }
  )
}
