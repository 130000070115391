import styled from 'styled-components'

export const Container = styled.div`
  padding: 2.5rem 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media (max-width: 1120px) {
    grid-template-columns: 1fr;
  }
`
