import {
  agglutinatedInstallment,
  canceledInstallment,
  installment1,
  installment2,
  installment4,
  installment5,
  openContract,
  receivableAgglutinated1,
  receivableRenegotiated,
  receivableCanceled,
  receivableDueToday,
  receivableOpen,
  receivablePaid,
  receivableOverDue,
} from '.'
import {
  Contract,
  InstallmentStatuses,
  InstallmentType,
  LiquidationSource,
  PaymentMethod,
  ReceivableStatuses,
} from '../interfaces'
import { openContract3 } from './contracts'
import { installment1_2 } from './installments'
import { receivableOpenWithDiscount } from './receivables'

export const openContractWithInstallmentsAndReceivables = {
  ...openContract,
  installments: [
    {
      ...installment1,
      receivables: [receivableDueToday],
    },
    {
      ...installment2,
      receivables: [receivablePaid],
    },
    {
      ...canceledInstallment,
      receivables: [receivableCanceled],
    },
    {
      ...installment5,
      receivables: [receivableOpen],
    },
    {
      ...agglutinatedInstallment,
      receivables: [receivableAgglutinated1],
    },
    {
      ...installment4,
      receivables: [receivableRenegotiated, receivableOverDue],
    },
    {
      ...canceledInstallment,
      receivables: [receivablePaid],
    },
  ],
}

export const openContractWithReceivablesAndDiscount = {
  ...openContract,
  installments: [
    {
      ...installment1_2,
      receivables: [receivableOpenWithDiscount],
    },
  ],
}

const nextYear = new Date().getFullYear() + 1

export const openContractWithOpenReceivables: Contract = {
  ...openContract3,
  installments: [
    {
      id: '126ee613-8081-4024-bb86-434c72877ce4',
      created_at: '2022-06-14T14:23:13.434071-03:00',
      updated_at: '2022-06-14T14:23:13.434071-03:00',
      contract_id: '8d9789a7-e650-41af-b5d8-0725238571d6',
      amount: 44000,
      due_date: '2022-06-16T21:00:00-03:00',
      status: InstallmentStatuses.OPEN,
      receivables: [
        {
          id: '29e5e2e3-49a1-453c-9aa4-08510acb26b4',
          created_at: '2022-06-14T14:23:13.458658-03:00',
          updated_at: '2022-06-14T14:36:44.399097-03:00',
          description: 'Mensalidade 1º Ano - EF I',
          type: '',
          original_amount: 44000,
          status: ReceivableStatuses.PAID,
          due_date: '2022-06-16T21:00:00-03:00',
          paid_date: '2022-06-13T21:00:00-03:00',
          total_amount_paid: 44000,
          payment_source: LiquidationSource.SCHOOL_ACCOUNT,
          payment_method: PaymentMethod.PIX_PAYMENT_METHOD,
          invoice_id: '9feb988b-5eef-41b1-b064-fb77a4dddca6',
          installment: {
            id: '126ee613-8081-4024-bb86-434c72877ce4',
            created_at: '2022-06-14T14:23:13.434071-03:00',
            updated_at: '2022-06-14T14:23:13.434071-03:00',
            contract_id: '8d9789a7-e650-41af-b5d8-0725238571d6',
            amount: 44000,
            due_date: '2022-06-16T21:00:00-03:00',
            status: InstallmentStatuses.OPEN,
            receivables: null,
            order_reference: '1/5',
            type: InstallmentType.TUITION,
          },
          installment_id: '126ee613-8081-4024-bb86-434c72877ce4',
          original_receivables: [],
          discounts: [],
          base_amount: 44000,
          current_amount: 44000,
          current_discount: 0,
          current_perpetual_discount: 0,
          current_early_payment_discount: 0,
          current_due_payment_discount: 0,
          current_additional_discount: 0,
          current_fine: 0,
          current_interest: 0,
          lost_early_payment_discount: 0,
          lost_due_payment_discount: 0,
        },
      ],
      order_reference: '1/5',
      type: InstallmentType.TUITION,
    },
    {
      id: 'c8ac51a1-e01b-4713-8b3b-bf206f169c83',
      created_at: '2022-06-14T14:23:13.434071-03:00',
      updated_at: '2022-06-14T14:23:13.434071-03:00',
      contract_id: '8d9789a7-e650-41af-b5d8-0725238571d6',
      amount: 44000,
      due_date: `${nextYear}-07-21T00:00:00-00:00`,
      status: InstallmentStatuses.OPEN,
      receivables: [
        {
          id: '2ec450e0-4bf2-4280-8f78-ffdd5a29db79',
          created_at: '2022-06-14T14:23:13.458658-03:00',
          updated_at: '2022-06-14T14:23:13.458658-03:00',
          description: 'Mensalidade 1º Ano - EF I',
          type: '',
          original_amount: 44000,
          status: ReceivableStatuses.OPEN,
          due_date: `${nextYear}-07-21T00:00:00-00:00`,
          paid_date: null,
          total_amount_paid: 0,
          invoice_id: 'a2c17e9d-8cec-49b4-b6cd-2625ef789da4',
          installment: {
            id: 'c8ac51a1-e01b-4713-8b3b-bf206f169c83',
            created_at: '2022-06-14T14:23:13.434071-03:00',
            updated_at: '2022-06-14T14:23:13.434071-03:00',
            contract_id: '8d9789a7-e650-41af-b5d8-0725238571d6',
            amount: 44000,
            due_date: `${nextYear}-07-21T00:00:00-00:00`,
            status: InstallmentStatuses.OPEN,
            receivables: null,
            order_reference: '2/5',
            type: InstallmentType.TUITION,
          },
          installment_id: 'c8ac51a1-e01b-4713-8b3b-bf206f169c83',
          original_receivables: [],
          discounts: [],
          base_amount: 44000,
          current_amount: 44000,
          current_discount: 0,
          current_perpetual_discount: 0,
          current_early_payment_discount: 0,
          current_due_payment_discount: 0,
          current_additional_discount: 0,
          current_fine: 0,
          current_interest: 0,
          lost_early_payment_discount: 0,
          lost_due_payment_discount: 0,
        },
      ],
      order_reference: '2/5',
      type: InstallmentType.TUITION,
    },
    {
      id: 'ad67085f-51eb-4e12-96ba-e97df1bbf6fc',
      created_at: '2022-06-14T14:23:13.434071-03:00',
      updated_at: '2022-06-14T14:23:13.434071-03:00',
      contract_id: '8d9789a7-e650-41af-b5d8-0725238571d6',
      amount: 44000,
      due_date: '2022-08-16T21:00:00-03:00',
      status: InstallmentStatuses.OPEN,
      receivables: [
        {
          id: '02fb41f2-1695-4049-be52-85ca87408904',
          created_at: '2022-06-14T14:23:13.458658-03:00',
          updated_at: '2022-06-14T14:23:13.458658-03:00',
          description: 'Mensalidade 1º Ano - EF I',
          type: '',
          original_amount: 44000,
          status: ReceivableStatuses.OPEN,
          due_date: '2022-08-16T21:00:00-03:00',
          paid_date: null,
          total_amount_paid: 0,
          invoice_id: '622abd79-d488-41aa-a0c5-8e0ac1d8d021',
          installment: {
            id: 'ad67085f-51eb-4e12-96ba-e97df1bbf6fc',
            created_at: '2022-06-14T14:23:13.434071-03:00',
            updated_at: '2022-06-14T14:23:13.434071-03:00',
            contract_id: '8d9789a7-e650-41af-b5d8-0725238571d6',
            amount: 44000,
            due_date: '2022-08-16T21:00:00-03:00',
            status: InstallmentStatuses.OPEN,
            receivables: null,
            order_reference: '3/5',
            type: InstallmentType.TUITION,
          },
          installment_id: 'ad67085f-51eb-4e12-96ba-e97df1bbf6fc',
          original_receivables: [],
          discounts: [],
          base_amount: 44000,
          current_amount: 44000,
          current_discount: 0,
          current_perpetual_discount: 0,
          current_early_payment_discount: 0,
          current_due_payment_discount: 0,
          current_additional_discount: 0,
          current_fine: 0,
          current_interest: 0,
          lost_early_payment_discount: 0,
          lost_due_payment_discount: 0,
        },
      ],
      order_reference: '3/5',
      type: InstallmentType.TUITION,
    },
    {
      id: '3ffc065a-a4c9-40b5-8b83-247dc8abe628',
      created_at: '2022-06-14T14:23:13.434071-03:00',
      updated_at: '2022-06-14T14:23:13.434071-03:00',
      contract_id: '8d9789a7-e650-41af-b5d8-0725238571d6',
      amount: 44000,
      due_date: '2022-09-16T21:00:00-03:00',
      status: InstallmentStatuses.OPEN,
      receivables: [
        {
          id: '04c2f82a-3919-4012-a135-32d01b0d9598',
          created_at: '2022-06-14T14:23:13.458658-03:00',
          updated_at: '2022-06-14T14:23:13.458658-03:00',
          description: 'Mensalidade 1º Ano - EF I',
          type: '',
          original_amount: 44000,
          status: ReceivableStatuses.OPEN,
          due_date: '2022-09-16T21:00:00-03:00',
          paid_date: null,
          invoice_id: '061063cf-08e6-43cc-b290-eb33f0488aee',
          installment: {
            id: '3ffc065a-a4c9-40b5-8b83-247dc8abe628',
            created_at: '2022-06-14T14:23:13.434071-03:00',
            updated_at: '2022-06-14T14:23:13.434071-03:00',
            contract_id: '8d9789a7-e650-41af-b5d8-0725238571d6',
            amount: 44000,
            due_date: '2022-09-16T21:00:00-03:00',
            status: InstallmentStatuses.OPEN,
            receivables: null,
            order_reference: '4/5',
            type: InstallmentType.TUITION,
          },
          installment_id: '3ffc065a-a4c9-40b5-8b83-247dc8abe628',
          original_receivables: [],
          discounts: [],
          base_amount: 44000,
          current_amount: 44000,
          current_discount: 0,
          current_perpetual_discount: 0,
          current_early_payment_discount: 0,
          current_due_payment_discount: 0,
          current_additional_discount: 0,
          current_fine: 0,
          current_interest: 0,
          lost_early_payment_discount: 0,
          lost_due_payment_discount: 0,
        },
      ],
      order_reference: '4/5',
      type: InstallmentType.TUITION,
    },
    {
      id: '1b59a8bf-3c5c-438d-a2fc-e18d255db258',
      created_at: '2022-06-14T14:23:13.434071-03:00',
      updated_at: '2022-06-14T14:23:13.434071-03:00',
      contract_id: '8d9789a7-e650-41af-b5d8-0725238571d6',
      amount: 44000,
      due_date: '2022-10-16T21:00:00-03:00',
      status: InstallmentStatuses.OPEN,
      receivables: [
        {
          id: 'b1f4a70a-c235-450a-ae64-8bb888ec7282',
          created_at: '2022-06-14T14:23:13.458658-03:00',
          updated_at: '2022-06-14T14:23:13.458658-03:00',
          description: 'Mensalidade 1º Ano - EF I',
          type: '',
          original_amount: 44000,
          status: ReceivableStatuses.OPEN,
          due_date: '2022-10-16T21:00:00-03:00',
          paid_date: null,
          total_amount_paid: 0,
          invoice_id: 'bc472f31-065c-42fb-bd9b-934db943d263',
          installment: {
            id: '1b59a8bf-3c5c-438d-a2fc-e18d255db258',
            created_at: '2022-06-14T14:23:13.434071-03:00',
            updated_at: '2022-06-14T14:23:13.434071-03:00',
            contract_id: '8d9789a7-e650-41af-b5d8-0725238571d6',
            amount: 44000,
            due_date: '2022-10-16T21:00:00-03:00',
            status: InstallmentStatuses.OPEN,
            receivables: null,
            order_reference: '5/5',
            type: InstallmentType.TUITION,
          },
          installment_id: '1b59a8bf-3c5c-438d-a2fc-e18d255db258',
          original_receivables: [],
          discounts: [],
          base_amount: 44000,
          current_amount: 44000,
          current_discount: 0,
          current_perpetual_discount: 0,
          current_early_payment_discount: 0,
          current_due_payment_discount: 0,
          current_additional_discount: 0,
          current_fine: 0,
          current_interest: 0,
          lost_early_payment_discount: 0,
          lost_due_payment_discount: 0,
        },
      ],
      order_reference: '5/5',
      type: InstallmentType.TUITION,
    },
  ],
}

export const openContractWithoutOpenInstallments = {
  ...openContract,
  installments: [
    {
      ...installment2,
      receivables: [receivablePaid],
    },
    {
      ...canceledInstallment,
      receivables: [receivableCanceled],
    },
    {
      ...canceledInstallment,
      receivables: [receivablePaid],
    },
  ],
}

export const openContractWithInstallmentsWithReceivableDueToday = {
  ...openContract,
  installments: [
    {
      ...installment1,
      receivables: [receivableDueToday],
    },
  ],
}

export const openContractWithInstallmentsWithReceivableRenegotiatedAndOverDue = {
  ...openContract,
  installments: [
    {
      ...installment4,
      receivables: [receivableRenegotiated, receivableOverDue],
    },
  ],
}

export const openContractWithInstallmentsWithReceivableRenegotiatedAndOpen = {
  ...openContract,
  installments: [
    {
      ...installment1,
      receivables: [receivableRenegotiated, receivableOpen],
    },
    {
      ...installment2,
      receivables: [receivableRenegotiated, receivableDueToday],
    },
  ],
}
