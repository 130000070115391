import { useParams } from 'react-router-dom'

import { Box } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'

import LogoIsaac from 'src/escolas/components/IsaacLogo'

import {
  months,
  splitDate,
  unabbreviatedDate,
  formatCentsToReal,
  formatCPF,
  formatCNPJ,
  formatReal,
  formatDate,
} from 'src/shared/utils'

import { ContractProvider } from '@/shared/contexts/contractContext'
import { PathParams } from 'src/shared/interfaces'
import theme from 'src/shared/theme'

import useContractPaidAmountsReport from './use-contract-paid-amounts-report'

import * as S from './styles'
import { useLayout } from '@/shared/hooks/useLayout'

export const ContractPaidAmountsReport = () => {
  useLayout({ enableHeader: false, enableSideMenu: false })

  const { contractId } = useParams<PathParams>()
  const {
    isLoading,
    schoolData,
    contract,
    totalDiscount,
    totalOriginalAmount,
    totalInterest,
    totalFine,
    totalPaid,
    paidReceivables,
  } = useContractPaidAmountsReport(contractId)

  const formatValues = (v: string | number) => (v ? formatCentsToReal(v) : formatReal(v))

  return isLoading ? (
    <S.A4Sheet data-testid="receipt-loading">
      <Box>
        <p>Carregando</p>
        <CircularProgress />
      </Box>
    </S.A4Sheet>
  ) : (
    <ContractProvider>
      <S.A4Sheet data-testid="receipt-content">
        <Box>
          <Box justifyContent="space-between" alignContent="flex-start" display="flex">
            <Box>
              <Typography variant="h3">Demonstrativo de valores pagos</Typography>
              <Typography variant="subtitle1">
                {contract.product.name} • {contract.reference_year}
              </Typography>
            </Box>
            <LogoIsaac width="92" />
          </Box>
          <Box display="flex" py={theme.spacing(1)}>
            <S.CompanyInfo>
              <S.BusinessInfo>Serviços prestados por:</S.BusinessInfo>
              <S.BusinessName>{schoolData?.name}</S.BusinessName>
              <S.BusinessInfo>CNPJ {formatCNPJ(schoolData?.tax_id)}</S.BusinessInfo>
            </S.CompanyInfo>
            <S.CompanyInfo>
              <S.BusinessInfo>Pagamento intermediado por:</S.BusinessInfo>
              <S.BusinessName>Oisa Tecnologia e Serviços LTDA.</S.BusinessName>
              <S.BusinessInfo>CNPJ 38.008.510/0001-88</S.BusinessInfo>
            </S.CompanyInfo>
          </Box>

          <Typography color="textSecondary">
            Declaramos para os devidos fins, que aluno(a) {contract.student.name}, cujo responsável
            financeiro é {contract.guardian.name}, CPF {formatCPF(contract?.guardian.tax_id)}, está
            matriculado(a) neste estabelecimento, {schoolData?.name}, tem os seguintes valores pagos
            referentes a {contract.product.name}, no período letivo de {contract.reference_year}:
          </Typography>

          <S.DetailsBox>
            <Box py={2}>
              <Typography variant="h3">Detalhamento</Typography>
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <S.TableCell>Competência</S.TableCell>
                  <S.TableCell colSpan={3}>Descrição*</S.TableCell>
                  <S.TableCell>Pago em</S.TableCell>
                  <S.TableCell className="lbrk">Valor integral</S.TableCell>
                  <S.TableCell style={{ maxWidth: '110px' }} className="lbrk">
                    Descontos condicionais
                  </S.TableCell>
                  <S.TableCell>Juros</S.TableCell>
                  <S.TableCell>Multas</S.TableCell>
                  <S.TableCell>Valor pago</S.TableCell>
                </TableRow>
              </TableHead>
              <TableFooter>
                <TableRow>
                  <S.TableCell colSpan={10} />
                </TableRow>
                <TableRow>
                  <S.TableCell colSpan={5} />
                  <S.TableCell>{formatValues(totalOriginalAmount)}</S.TableCell>
                  <S.TableCell>{formatValues(-totalDiscount)}</S.TableCell>
                  <S.TableCell>{formatValues(totalInterest)}</S.TableCell>
                  <S.TableCell>{formatValues(totalFine)}</S.TableCell>
                  <S.TableCell>{formatValues(totalPaid)}</S.TableCell>
                </TableRow>
                <TableRow>
                  <S.TableCellWithNoBorder colSpan={4}>
                    <S.DateEmission>
                      Declaração emitida em {unabbreviatedDate(new Date().toISOString())}
                    </S.DateEmission>
                  </S.TableCellWithNoBorder>
                  <S.TableCellWithNoBorder />
                  <S.TableCell colSpan={5}>
                    <S.TotalAmountInfo>{`Valor total pago: ${formatValues(
                      totalPaid
                    )}`}</S.TotalAmountInfo>
                  </S.TableCell>
                </TableRow>
              </TableFooter>
              <TableBody>
                {paidReceivables.map(receivable => {
                  return (
                    <TableRow key={receivable.id}>
                      <S.TableCell>
                        {months[+splitDate(receivable.installment.due_date).month]}
                      </S.TableCell>
                      <S.TableCell colSpan={3} className="lbrk">
                        {receivable.description}
                      </S.TableCell>
                      <S.TableCell>{formatDate(receivable.paid_date, 'DD/MM/YYYY')}</S.TableCell>
                      <S.TableCell className="lbrk">
                        {formatValues(receivable.totalAmount)}
                      </S.TableCell>
                      <S.TableCell className="lbrk">
                        {formatValues(
                          receivable.conditionDiscounts === 0
                            ? receivable.conditionDiscounts
                            : -receivable.conditionDiscounts
                        )}
                      </S.TableCell>
                      <S.TableCell>{formatValues(receivable.current_interest)}</S.TableCell>
                      <S.TableCell>{formatValues(receivable.current_fine)}</S.TableCell>
                      <S.TableCell>{formatValues(receivable.total_amount_paid)}</S.TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </S.DetailsBox>
          <S.DisclaimerText>
            * Só podem ser deduzidos do Imposto de Renda valores pagos referentes ao ensino regular.
          </S.DisclaimerText>
          <S.DisclaimerText>
            * Neste demonstrativo só constam valores pagos de competência do isaac.
          </S.DisclaimerText>
        </Box>
      </S.A4Sheet>
    </ContractProvider>
  )
}
