export const formatGuardianPayload = formValues => {
  return {
    name: formValues?.name,
    email: formValues?.email,
    tax_id: formValues?.taxId?.match(/\d/g)?.join(''),
    phone_number: formValues?.phoneNumber?.match(/\d/g)?.join(''),
    address: {
      additional_information: formValues?.additionalInfo,
      number: formValues?.addressNumber,
      city: formValues?.city,
      state_code: formValues?.state,
      street: formValues?.street,
      zip: formValues?.zipCode,
    },
  }
}
