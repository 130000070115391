import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { Checkout, PaymentMethod, Pos } from '@/shared/interfaces'
import {
  AgreementEntry,
  DuplicateAgreementRequest,
  SimulationPaymentMethod,
} from '@/modules/guardians/models/agreement'
import { useDuplicateAgreement } from '@/escolas/hooks/useDuplicateAgreement'
import { useNavigation } from '@/escolas/hooks'
import { getDuplicateAgreementPaymentPlan } from '@/modules/guardians/Negotiation/helpers/agreement'
import { useAgreement } from '@/escolas/contexts/agreementContext'
import { InstallmentV2 } from '@/modules/guardians/services/types'
import useGuardiansInstallmentsByUrnQuery from '@/modules/guardians/ManualLiquidation/hooks/manualLiquidation/useGuardianInstallmentsQueryByUrn'
import useFlowCheckout from '@/modules/guardians/Negotiation/hooks/useFlowCheckout'
import { PaymentOptionsValue } from '@/modules/guardians/Negotiation/types'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

export type NegotiationData = {
  amount: number
  id: uuid
  installmentsNumberSelected?: number
  paymentMethod: PaymentMethod
  receivableId: uuid
}

export default function useEntryNegotiation(
  goToNextStep: () => void,
  urn: string,
  guardianId: uuid
) {
  const isEnableUnifiedInvoice = useUnleashFlag(UnleashFlags.REG_228_ENABLE_UNIFIED_INVOICE)

  const [pos, setPos] = useState<Pos>()
  const [isError, setIsError] = useState(false)
  const [showStatusDialog, setShowStatusDialog] = useState(false)
  const [negotiationData, setNegotiationData] = useState<NegotiationData>()
  const [currentVariantDialog, setCurrentVariantDialog] = useState<
    'starting_checkout' | 'starting_duplicate' | 'starting_checkout_v2'
  >('starting_duplicate')
  const { guardian } = useNavigation()
  const { setCheckoutData } = useAgreement()

  const {
    guardiansInstallments,
    isFetchGuardiansInstallmentsLoading,
  } = useGuardiansInstallmentsByUrnQuery({
    guardianId,
    urns: [urn],
  })

  const parseInstallmentV2ToAgreementEntry = (installment: InstallmentV2) => {
    if (!installment) return

    const paymentPlan = getDuplicateAgreementPaymentPlan(installment.origin_negotiation)
    const agreement: AgreementEntry = {
      dueDate: installment.due_date,
      negotiationID: installment.origin_negotiation.id,
      paymentLink: installment.invoice.payment_link,
      value: installment.current_amount,
      competenceMonths: [dayjs(installment.due_date).utc().format('MMMM')],
      maxInstallment:
        installment.origin_negotiation.down_payment_max_installments_credit_card > 0
          ? installment.origin_negotiation.down_payment_max_installments_credit_card
          : 1,
      simulationID: installment.origin_negotiation.simulation_id,
      paymentPlan: paymentPlan,
      product: installment.products,
      student: installment.student,
      orderReference: installment.order_reference,
    }
    return agreement
  }
  const entryInformation = parseInstallmentV2ToAgreementEntry(guardiansInstallments?.data?.[0])

  const handleOnFinishCheckout = (checkoutData: Checkout) => {
    setCheckoutData(checkoutData)
    setNegotiationData({
      ...negotiationData,
      amount: checkoutData.amount,
    })
    goToNextStep()
  }

  const duplicateAgreement = useDuplicateAgreement()
  const checkoutValues = useFlowCheckout(handleOnFinishCheckout)

  const handleCheckout = (
    chosenPayment: PaymentOptionsValue,
    receivableId: string,
    downPaymentConversion = false
  ) => {
    const { installments, pos, paymentOption } = chosenPayment
    setPos(pos)

    if (isEnableUnifiedInvoice) {
      return checkoutValues.startCheckoutV2(
        installments,
        paymentOption,
        pos.pos_id,
        [receivableId],
        pos.provider
      )
    }

    checkoutValues.startCheckout({
      numberOfInstallments: installments,
      paymentMethod: paymentOption,
      posId: pos.pos_id,
      receivablesIds: [receivableId],
      checkoutProvider: pos.provider,
      downPaymentConversion,
    })
  }

  const convertSimulationPaymentMethod = (simulationPaymentMethod: SimulationPaymentMethod) => {
    if (simulationPaymentMethod === SimulationPaymentMethod.CREDIT_CARD)
      return PaymentMethod.CREDIT_CARD_PAYMENT_METHOD
    return PaymentMethod.DEBIT_CARD_PAYMENT_METHOD
  }

  const handleOnNegotiationStep = (chosenPayment: PaymentOptionsValue) => {
    setShowStatusDialog(true)

    if (isEnableUnifiedInvoice) {
      const urn = guardiansInstallments.data[0].urn
      setCurrentVariantDialog('starting_checkout_v2')
      handleCheckout(chosenPayment, urn)
      return
    }

    if (guardiansInstallments.data[0].origin_negotiation.type !== 'NEGOTIATION_API_V2') {
      const receivableId = guardiansInstallments.data[0].urn.split(':')[2]
      setCurrentVariantDialog('starting_checkout')
      setNegotiationData({
        id: entryInformation.negotiationID,
        amount: entryInformation.value,
        paymentMethod: convertSimulationPaymentMethod(chosenPayment.paymentOption),
        receivableId: receivableId,
        installmentsNumberSelected: chosenPayment.installments,
      })
      handleCheckout(chosenPayment, receivableId)
      return
    }

    if (negotiationData?.id) {
      setCurrentVariantDialog('starting_checkout')
      handleCheckout(chosenPayment, negotiationData.receivableId, true)
      return
    }

    const body: DuplicateAgreementRequest = {
      simulation_id: entryInformation.simulationID,
      guardian_id: guardian.id,
      payment_plan: entryInformation.paymentPlan,
    }

    duplicateAgreement.mutate(body, {
      onSuccess: data => {
        setCurrentVariantDialog('starting_checkout')
        const invoices = data.invoices.map(invoice => {
          if (entryInformation.paymentPlan.payment_method === SimulationPaymentMethod.CREDIT_CARD) {
            return {
              ...invoice,
              instalment_credit_card: entryInformation.paymentPlan.number_of_installments,
            }
          }
          return invoice
        })
        const firstInvoice = invoices.find(invoice => invoice.installment.startsWith('1/'))
        setNegotiationData({
          id: data.id,
          amount: firstInvoice.amount,
          paymentMethod: convertSimulationPaymentMethod(firstInvoice.payment_method),
          receivableId: firstInvoice.receivable_id,
          installmentsNumberSelected: chosenPayment.installments,
        })
        handleCheckout(chosenPayment, firstInvoice.receivable_id, true)
      },
      onError: () => {
        setTimeout(() => {
          setIsError(true)
        }, 500)
        setShowStatusDialog(false)
      },
    })
  }

  useEffect(() => {
    if (checkoutValues.isProcessingCheckoutPayment) {
      setShowStatusDialog(false)
      setCurrentVariantDialog('starting_duplicate')
    }
  }, [checkoutValues.isProcessingCheckoutPayment])

  const closeErrorDialog = () => setIsError(false)

  return {
    handleOnNegotiationStep,
    closeErrorDialog,
    checkoutValues,
    statusDialogContent: {
      pos,
      guardianName: guardian.name,
      totalAmount: negotiationData?.amount,
      shortId: negotiationData?.id.slice(0, 6).toUpperCase(),
    },
    showStatusDialog,
    isError,
    currentVariantDialog,
    isLoading: isFetchGuardiansInstallmentsLoading,
    entryInformation,
    negotiationIsLoading: duplicateAgreement.isLoading || checkoutValues.waitingApi,
    negotiationData,
  }
}
