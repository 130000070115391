import { useEffect, useState } from 'react'

import MuiTypography from '@material-ui/core/Typography'
import ConfirmationDialog from 'src/escolas/components/modal/ConfirmationDialog'

import { Installment, Contract } from 'src/shared/interfaces'
import { useApi, useJWT, useSnackbar } from 'src/shared/hooks'
import { useNavigation } from 'src/escolas/hooks'
import { useForm } from 'react-hook-form'
import CancellationDrawer from 'src/escolas/components/contract/CancellationDrawer'
import { ContractCancellationForm } from '@/escolas/components/contract/CancellationDrawer/CancellationDrawerDefaultContent'
import useInstallments from '@/escolas/hooks/useInstallments'
import { processInstallments } from '@/escolas/components/contract/ContractDetails'
import { useQueryClient } from '@tanstack/react-query'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export type ContractCancellationDrawerProps = {
  contract: Contract
  isOpen: boolean
  onCancel?: () => null
  onClose: () => void
}

export const ContractCancellationDrawer = (props: ContractCancellationDrawerProps) => {
  const { isOpen, onCancel = () => null, onClose } = props

  const { isAdmin } = useJWT()
  const { schoolId } = useNavigation()
  const { api } = useApi()
  const queryClient = useQueryClient()

  const isCancellationEnabled = !isAdmin

  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const [showCancellationErrorMessage, setShowCancellationErrorMessage] = useState<boolean>(false)
  const [showConfirmCancellationMessage, setShowConfirmCancellationMessage] = useState<boolean>(
    false
  )
  const [isOnTheFirstPage, setIsOnTheFirstPage] = useState<boolean>(true)
  const { processedInstallments, setProcessedInstallments } = useInstallments()
  const [installmentsToCancelContract, setInstallmentsToCancelContract] = useState<
    Array<Installment>
  >([])

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const cancelContractForm = useForm<ContractCancellationForm>({
    mode: 'all',
    defaultValues: {
      installment_id: '',
      cancellation_description: '',
    },
  })

  const {
    handleSubmit: cancelContractHandleSubmit,
    reset: cancelContractReset,
  } = cancelContractForm

  const handleCancellationDrawerClose = () => {
    onClose()
  }

  const onCancellationSuccess = () => {
    queryClient.refetchQueries({
      queryKey: ['contracts', 'neverPaid'],
    })
    onCancel()
    setShowConfirmCancellationMessage(false)
    handleCancellationDrawerClose()
  }

  const cancelContractSubmitHandler = (form: ContractCancellationForm) => {
    const { cancellation_reason, cancellation_description, installment_id } = form

    const snackbarErrorMessage = isCancellationEnabled
      ? 'Antes de cancelar, é necessário renegociar as parcelas vencidas deste contrato.'
      : 'Ocorreu um erro ao cancelar o contrato'

    return api.contracts
      .revoke(
        props.contract.id,
        {
          installment_id: installment_id || props.contract.installments[0].id,
          cancellation_reason,
          cancellation_description,
          triggered_by: 'NEVER_PAID',
        },
        schoolId,
        props.contract
      )
      .then(() => {
        setSnackbarVariation('success')
        setSnackbarMessage('Contrato cancelado com sucesso.')
        setSnackbarIsOpen(true)
        onCancellationSuccess()
        setIsOnTheFirstPage(true)
        cancelContractReset()

        if (isInitialized) {
          eventDispatcherClient.sendEvent({
            scope: EventDispatcherEventScopes.NEVER_PAID,
            name: EventDispatcherEvents.NEVER_PAID_CANCEL_CONTRACT_CONFIRM,
            action: 'click',
          })
        }
      })
      .catch(() => {
        setSnackbarVariation('error')
        setSnackbarMessage(snackbarErrorMessage)
        setSnackbarIsOpen(true, {
          title: 'Esse contrato não pode ser cancelado',
        })
        handleCancellationDrawerClose()
        setShowConfirmCancellationMessage(false)
        setIsOnTheFirstPage(true)
        cancelContractReset()
      })
  }

  const getCancelContractInstallments = () => {
    if (!props.contract) return
    api.contracts.cancelContractInfo(props.contract.id).then(data => {
      setInstallmentsToCancelContract(data)
    })
  }

  useEffect(() => {
    if (props.contract?.installments.length === 0) {
      return
    }

    const processedInstallments = processInstallments(props.contract)
    setProcessedInstallments(processedInstallments)
    getCancelContractInstallments()
  }, [props.contract])

  return (
    <>
      <div>
        <CancellationDrawer
          isOpen={isOpen}
          onConfirm={cancelContractHandleSubmit(cancelContractSubmitHandler)}
          onClose={handleCancellationDrawerClose}
          processedInstallments={processedInstallments}
          isOnTheFirstPage={isOnTheFirstPage}
          setIsOnTheFirstPage={setIsOnTheFirstPage}
          form={cancelContractForm}
          setShowConfirmCancellationMessage={setShowConfirmCancellationMessage}
          availableInstallments={installmentsToCancelContract}
        />
        {isCancellationEnabled && (
          <ConfirmationDialog
            isVisible={showCancellationErrorMessage}
            onClose={() => setShowCancellationErrorMessage(false)}
            submitHandler={() => setShowCancellationErrorMessage(false)}
            title="Esse contrato não pode ser cancelado"
          >
            <MuiTypography>
              Contratos com parcelas vencidas ou renegociadas não podem ser cancelados. Entre em
              contato com o atendimento.
            </MuiTypography>
          </ConfirmationDialog>
        )}
        <ConfirmationDialog
          isVisible={showConfirmCancellationMessage}
          onClose={() => setShowConfirmCancellationMessage(false)}
          submitHandler={cancelContractHandleSubmit(cancelContractSubmitHandler)}
          backButtonLabel="Voltar"
          buttonLabel="Cancelar contrato"
          title="Atenção"
        >
          <MuiTypography>
            O cancelamento de contrato é irreversível e altera os próximos repasses.
          </MuiTypography>
        </ConfirmationDialog>
      </div>
    </>
  )
}
