import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useHistory } from 'react-router-dom'

export const useTableActions = () => {
  const { schoolSlug } = useSelectedSchool()
  const history = useHistory()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const goToStudentsPage = (studentId: uuid) =>
    history.push(`/${schoolSlug}/matriculas/${studentId}`)

  const handleRowClick = (studentId: uuid, studentStatus: string) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        customProperties: {
          $Button_name: 'LINHA_DA_TABELA_DE_ALUNOS',
          $table_name: 'LISTA_DE_ALUNOS',
          $situation: studentStatus,
        },
      })
    return goToStudentsPage(studentId)
  }

  return {
    handleRowClick,
  }
}
