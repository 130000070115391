import styled from 'styled-components'

import type { ContentContainerProps } from './types'

import theme from '@/shared/theme'

export const { spacing, colors } = theme.primitiveTokens

const { variation, color } = theme.humanizedTokens.components.typography

// Container which will load the html from beamer notification
export const ContentContainer = styled.div<ContentContainerProps>`
  p,
  div,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${({ $variation }) => ($variation === 'normal' ? variation.bodyLarge : variation.bodySmall)};
    ${({ $variation }) => ($variation === 'normal' ? color.secondary : color.primary)};
    line-height: 1.5;
  }

  p + *,
  div + * {
    margin-top: ${spacing[4]};
  }

  ol,
  ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin: ${spacing[6]} 0 0 ${spacing[3]};
  }

  li {
    list-style-type: disc;
    margin-left: ${spacing[5]};
  }

  b {
    font-weight: 500;
  }

  a {
    ${color.accent};
  }
`
