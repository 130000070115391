const escapeRegex = (value: string) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
}

export const getHighlightWords = (input: string, name: string) => {
  if (!input) return name

  const keywords = input
    .split(/\s+/)
    .map(word => escapeRegex(word))
    .filter(word => word.length > 0)

  /* eslint-disable security/detect-non-literal-regexp */
  const pattern = new RegExp(`(${keywords.join('|')})`, 'gi')

  return name.replace(pattern, match => `<mark>${match}</mark>`)
}
