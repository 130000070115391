export enum AgglutinationErrorReason {
  CREDIT_CARD_RECEIVABLES = 'CREDIT_CARD_RECEIVABLES',
  LESS_THAN_TWO_RECEIVABLES = 'LESS_THAN_TWO_RECEIVABLES',
  MIXED_CONTRACT_RECEIVABLES = 'MIXED_CONTRACT_RECEIVABLES',
  ONLY_OPEN_RECEIVABLES_ALLOWED = 'ONLY_OPEN_RECEIVABLES_ALLOWED',
  ONLY_TUITION_RECEIVABLES_ALLOWED = 'ONLY_TUITION_RECEIVABLES_ALLOWED',
  PART_OF_PREVIOUS_NEGOTIATION = 'PART_OF_PREVIOUS_NEGOTIATION',
}

export enum AgglutinationErrorLabel {
  CREDIT_CARD_RECEIVABLES = 'Parcelas geradas para cartão de crédito não podem ser aglutinadas. Selecione apenas parcelas de mensalidade.',
  LESS_THAN_TWO_RECEIVABLES = 'Selecione pelo menos duas parcelas do mesmo contrato para aglutinar.',
  MIXED_CONTRACT_RECEIVABLES = 'Não é possível aglutinar parcelas de mais de um contrato e/ou aluno.',
  ONLY_OPEN_RECEIVABLES_ALLOWED = 'Só é possível aglutinar parcelas a vencer, vencidas e vence hoje.',
  ONLY_TUITION_RECEIVABLES_ALLOWED = 'Parcelas de matrícula não podem ser aglutinadas. Selecione apenas parcelas de mensalidade.',
  PART_OF_PREVIOUS_NEGOTIATION = 'Não é possível aglutinar parcelas que já foram aglutinadas ou negociadas anteriormente.',
}

export const AgglutinationErrorMap = {
  [AgglutinationErrorReason.CREDIT_CARD_RECEIVABLES]:
    AgglutinationErrorLabel.CREDIT_CARD_RECEIVABLES,
  [AgglutinationErrorReason.LESS_THAN_TWO_RECEIVABLES]:
    AgglutinationErrorLabel.LESS_THAN_TWO_RECEIVABLES,
  [AgglutinationErrorReason.MIXED_CONTRACT_RECEIVABLES]:
    AgglutinationErrorLabel.MIXED_CONTRACT_RECEIVABLES,
  [AgglutinationErrorReason.ONLY_OPEN_RECEIVABLES_ALLOWED]:
    AgglutinationErrorLabel.ONLY_OPEN_RECEIVABLES_ALLOWED,
  [AgglutinationErrorReason.ONLY_TUITION_RECEIVABLES_ALLOWED]:
    AgglutinationErrorLabel.ONLY_TUITION_RECEIVABLES_ALLOWED,
  [AgglutinationErrorReason.PART_OF_PREVIOUS_NEGOTIATION]:
    AgglutinationErrorLabel.PART_OF_PREVIOUS_NEGOTIATION,
}
