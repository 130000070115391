import { Typography, DatePicker } from '@olaisaac/design-system'
import dayjs, { Dayjs } from 'dayjs'
import { StyledInput, StyledInputLabel } from './styles'
import type { InputDueDateProps } from './types'

const InputDueDate = ({
  maxDate,
  minDate,
  selectedValue,
  handleValue,
  label,
}: InputDueDateProps) => {
  return (
    <StyledInput>
      <StyledInputLabel maxWidth={255}>
        <Typography variation="bodySmall" withoutMargin color="secondary">
          {label}
        </Typography>
      </StyledInputLabel>
      <DatePicker
        value={selectedValue}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(date: Dayjs) => handleValue(date)}
        label=""
        minDateMessage={`Data não deve ser menor do que a mínima (${dayjs(minDate).format(
          'DD/MM/YYYY'
        )})`}
        maxDateMessage={`Data não deve ser maior do que a máxima (${dayjs(maxDate).format(
          'DD/MM/YYYY'
        )})`}
      />
    </StyledInput>
  )
}

export default InputDueDate
