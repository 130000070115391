import { ArrowBack } from '@material-ui/icons'
import { Button } from '@olaisaac/design-system'
import { useHistory } from 'react-router-dom'
type GoBackButtonProps = {
  aditionalOnClick?: () => void
}
export const GoBackButton = ({ aditionalOnClick = () => null }: GoBackButtonProps) => {
  const history = useHistory()
  return (
    <Button
      startIcon={<ArrowBack />}
      variation="ghost"
      onClick={() => {
        history.goBack()
        aditionalOnClick()
      }}
    >
      Voltar
    </Button>
  )
}
