import { Installment } from '@/modules/contract/services/types'
import { InstallmentType } from '@/shared/interfaces'

export function sortInstallmentsToUpdate(unorderedInstallments: Installment[]): Installment[] {
  if (!unorderedInstallments) return []

  const sortByDueDateDesc = (a: Installment, b: Installment) => a.due_date.localeCompare(b.due_date)

  const groupedInstallmentInitialValue = {
    enrollmentInstallments: new Array<Installment>(),
    otherInstallments: new Array<Installment>(),
  }
  type GroupedInstallments = typeof groupedInstallmentInitialValue
  const groupByType = (acc: GroupedInstallments, curr: Installment) => {
    const installmentGroup: keyof GroupedInstallments =
      curr.receivable_type === InstallmentType.ENROLLMENT
        ? 'enrollmentInstallments'
        : 'otherInstallments'
    acc[installmentGroup].push(curr)
    return acc
  }

  const { enrollmentInstallments, otherInstallments } = unorderedInstallments
    .sort(sortByDueDateDesc)
    .reduce(groupByType, groupedInstallmentInitialValue)

  return [...enrollmentInstallments, ...otherInstallments]
}
