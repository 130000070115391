import { ReactElement, cloneElement, useState } from 'react'
import { Popover, List } from '@material-ui/core'

import { ListItem } from './styles'

type Option = {
  label: string
  name: string
  onClick: () => void
}

type ContextDropdownProps = {
  button: ReactElement
  options: Option[]
}

/**
 * Reusable contextual dropdown
 */
export const ContextDropdown = ({ button, options }: ContextDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      {cloneElement(button, {
        'aria-controls': 'context-menu',
        'aria-haspopup': 'true',
        onClick: handleClick,
      })}

      {options.length > 0 && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <List>
            {options.map(({ name, label, onClick }) => (
              <ListItem
                key={name}
                button
                onClick={() => {
                  onClick()
                  handleClose()
                }}
              >
                {label}
              </ListItem>
            ))}
          </List>
        </Popover>
      )}
    </>
  )
}
