import { Dispatch, SetStateAction, useState } from 'react'
import MuiPopover from '@material-ui/core/Popover'
import MuiCard from '@material-ui/core/Card'
import MuiCardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded'
import Typography from '@material-ui/core/Typography'
import { assoc } from 'ramda'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { Button, Chip } from '@olaisaac/design-system'

export type TableFilterParam<TValue> = {
  isCpf?: boolean
  isGuardian?: boolean
  label?: string
  value: TValue
}

export type TableFilterOptions = {
  filterName: string
  filterTitle: string
  labelOptions: Record<string, number | string>
}

export type TableFilterProps<TFilterProp extends Record<string, any>> = {
  filterEventHandler?: () => void
  filterOptions: Array<TableFilterOptions>
  filters: TFilterProp
  filtersSetter: Dispatch<SetStateAction<TFilterProp>>
}

export function TableFilter<T extends Record<string, any>>({
  filterOptions,
  filters,
  filtersSetter,
  filterEventHandler,
}: TableFilterProps<T>) {
  const [filterAnchor, setFilterAnchor] = useState<HTMLButtonElement | null>(null)

  const removeFilter = (filterName: TableFilterOptions['filterName']) => {
    filtersSetter(assoc(filterName, { label: '', value: '' }, filters))
  }

  const handleFilterPopoverClose = () => {
    setFilterAnchor(null)
  }

  const handleFilterPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    filterEventHandler && filterEventHandler()
    setFilterAnchor(event.currentTarget)
  }

  const handleFilterOptionClick = (
    options: TableFilterOptions,
    optionValue: TableFilterParam<any>['value']
  ) => {
    handleFilterPopoverClose()
    filtersSetter(
      assoc(
        options.filterName,
        { label: options.labelOptions[optionValue], value: optionValue },
        filters
      )
    )
  }

  const renderFilterOptionChip = (
    options: TableFilterOptions,
    optionValue: TableFilterParam<any>['value']
  ) => {
    return (
      <Chip
        key={options.filterName + optionValue}
        label={options.labelOptions[optionValue]}
        clickable
        onClick={() => handleFilterOptionClick(options, optionValue)}
        variation="gray"
      />
    )
  }

  const renderFilterOptionSection = (options: TableFilterOptions) => {
    return (
      <Grid key={options.filterName}>
        <Typography variant="overline" color="textSecondary">
          {options.filterTitle}
        </Typography>
        <Divider />
        <Box mt={2} display="flex" gridGap={8}>
          {Object.keys(options.labelOptions).map(labelKey =>
            renderFilterOptionChip(options, labelKey)
          )}
        </Box>
      </Grid>
    )
  }

  const renderAppliedFilterChip = (filterName: string, filterParam: TableFilterParam<any>) => {
    if (filterParam?.value && filterParam?.label)
      return (
        <Chip
          key={filterName}
          label={filterParam?.label}
          onDelete={() => removeFilter(filterName)}
        />
      )
  }

  return (
    <>
      {Object.entries(filters).map(filter => renderAppliedFilterChip(...filter))}
      <Button
        onClick={handleFilterPopoverClick}
        variation="ghost"
        startIcon={<FilterListRoundedIcon />}
        style={{ marginLeft: 16 }}
      >
        Filtrar
      </Button>
      <MuiPopover
        open={Boolean(filterAnchor)}
        anchorEl={filterAnchor}
        onClose={handleFilterPopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MuiCard>
          <MuiCardContent style={{ paddingBottom: 16 }}>
            {filterOptions.map(renderFilterOptionSection)}
          </MuiCardContent>
        </MuiCard>
      </MuiPopover>
    </>
  )
}

export default TableFilter
