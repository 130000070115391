import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes as scopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents as events } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { Contract } from '@/modules/contract/services/types'

import { DialogNames, dialogEventHash } from '../EditContract'
import { AddDiscountForm } from '../components/AddDiscountDialog'
import { filterSelectedInstallments } from '../utils'
import { ReactNode, createContext, useContext, useState } from 'react'
import { Row } from '../components/InstallmentsTable/types'
import { InstallmentStatuses } from '@/shared/interfaces'

type Events = {
  addDiscount: (values: AddDiscountForm, percentMode: boolean) => void
  cancelEditContract: () => void
  clickCancelBtn: (dialogName: DialogNames) => void
  clickDatePicker: () => void
  clickEditAction: (buttonName: string) => void
  clickEditEnrollmentLink: () => void
  clickRow: (row: Row) => void
  editAmount: (dialogName: DialogNames) => void
  editDueDate: (workingDayMode: boolean) => void
  pageViewed: () => void
  removeDiscount: (installmentId: uuid, discountIndexToDelete: number) => void
  saveEdits: () => void
  submitError: () => void
}

type EventContext = Events & {
  setContract: (contract: Contract) => void
  setSelectedInstallmentIds: (ids: uuid[]) => void
}

const EventContext = createContext<EventContext>(null)

export const EventProvider = ({ children }: { children: ReactNode }) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const [contract, setContract] = useState<Contract>(null)
  const [selectedInstallmentIds, setSelectedInstallmentIds] = useState<uuid[]>([])
  const installments = contract?.installments ?? []

  type Event = Parameters<typeof eventDispatcherClient.sendEvent>[0]

  const send = (event: Event) => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent(event)
  }

  const selectedInstallments = filterSelectedInstallments(installments, selectedInstallmentIds)
  const selectedInstallmentType =
    selectedInstallments.length > 0 ? selectedInstallments[0].receivable_type : ''

  const contractType = installments.some(i => i.status === InstallmentStatuses.PENDING)
    ? 'PRE_CONTRACT'
    : 'EFFECTED_CONTRACT'

  const sendEvents = {
    clickEditAction: (buttonName: string) => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: buttonName,
          $installment_type: selectedInstallmentType,
          $contract_type: contractType,
        },
      })
    },
    editAmount: (dialogName: DialogNames) => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Aplicar',
          $modal_name: dialogEventHash[dialogName],
          $installment_type: selectedInstallmentType,
          $installment_number: selectedInstallmentIds.length,
          $number_of_installments: installments.length,
          $contract_type: contractType,
        },
      })
    },
    addDiscount: (values: AddDiscountForm, percentMode: boolean) => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Aplicar',
          $modal_name: 'Descontos',
          $installment_type: selectedInstallmentType,
          $installment_number: selectedInstallmentIds.length,
          $chip_selected: percentMode ? 'percentage' : 'value',
          $discount_rule: values.discountRule,
          $contract_type: contractType,
        },
      })
    },
    editDueDate: (workingDayMode: boolean) => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Aplicar',
          $modal_name: 'Vencimentos',
          $installment_type: selectedInstallmentType,
          $installment_number: selectedInstallmentIds.length,
          $number_of_installments: installments.length,
          $selected_rule: workingDayMode ? 'dia útil' : 'dia fixo',
          $contract_type: contractType,
        },
      })
    },
    removeDiscount: (installmentId: uuid, discountIndexToDelete: number) => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Remover desconto',
          $chip_name: 'Desconto',
          $installment_type: selectedInstallmentType,
          $installment_number: selectedInstallmentIds.length,
          $discount_rule: installments.find(
            installment => installment.backoffice_installment_id === installmentId
          ).discounts[discountIndexToDelete]?.type,
          $contract_type: contractType,
        },
      })
    },
    pageViewed: () => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.PAGE_VIEWED,
        action: 'page_view',
        customProperties: {
          $contract_type: contractType,
        },
      })
    },
    clickCancelBtn: (dialogName: DialogNames) => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Cancelar',
          $modal_name: dialogEventHash[dialogName],
          $contract_type: contractType,
        },
      })
    },
    submitError: () => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.MODAL_VIEWED,
        action: 'component_view',
        customProperties: {
          $button_name: 'Salvar edições',
          $modal_name: 'Mensagem de erro edição contrato',
          $contract_type: contractType,
        },
      })
    },
    clickRow: (row: Row) => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.TABLE_ROW_CLICK,
        action: 'click',
        customProperties: {
          $checkbox_name: 'parcela',
          $installment_type: row.receivable_type,
          $installment_number: row.order_reference.split('de')[0].trim(),
          $contract_type: contractType,
        },
      })
    },
    clickEditEnrollmentLink: () => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.LINK_CLICK,
        action: 'click',
        customProperties: {
          $link_name: 'Editar parcelas de pré-matrícula',
          $contract_type: contractType,
        },
      })
    },
    clickDatePicker: () => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.DATEPICKER_CLICK,
        action: 'click',
        customProperties: {
          $datepicker_name: 'Data de vencimento',
          $modal_name: 'Vencimento',
          $contract_type: contractType,
        },
      })
    },
    cancelEditContract: () => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Cancelar edição',
          $contract_type: contractType,
        },
      })
    },
    saveEdits: () => {
      send({
        scope: scopes.CONTRACT_EDIT,
        name: events.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Salvar edições',
          $contract_type: contractType,
        },
      })
    },
  }

  return (
    <EventContext.Provider
      value={{
        setContract,
        setSelectedInstallmentIds,
        ...sendEvents,
      }}
    >
      {children}
    </EventContext.Provider>
  )
}

export const useEvents = () => useContext(EventContext)
