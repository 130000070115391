import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Button, DialogTitle, DialogContent, DialogActions } from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import erroPi from 'src/escolas/assets/erro-pi.svg'
import { useApi } from 'src/shared/hooks'

const DEFAULT_MESSAGE =
  'Estamos com uma instabilidade momentânea no sistema e já estamos trabalhando para ajustar. Enquanto isso, sugerimos que a família use o aplicativo do meu isaac para ter acesso às faturas ou ainda, você pode imprimir as faturas individualmente. '

const StyledImage = styled.img`
  text-align: center;
  width: 50%;
  padding: ${({ theme }) => theme.spacing(4)}px 0px;
`

const errorMsg = (err: string) => {
  if (err) {
    if (err.includes('all open receivable are overdue')) {
      return 'Todas as parcelas desse contrato estão vencidas. É necessário gerar segunda via das parcelas para criar o carnê.'
    } else if (err.includes('any receivables open')) {
      return 'Não existem parcelas disponíveis para geração de carnê. Todas as parcelas do contrato já foram pagas ou canceladas.'
    } else if (err.includes('can only be paid with credit card')) {
      return 'Parcelas disponíveis desse contrato só podem ser pagas via cartão de crédito. Renegocie para disponibilizar outras formas de pagamento e gerar o carnê.'
    }
  }
  return DEFAULT_MESSAGE
}

const TextAndImageContent = ({
  imageSrc,
  message,
  secondaryMessage,
}: {
  imageSrc: string
  message: string
  secondaryMessage?: React.ReactNode
}) => (
  <>
    <Box display="flex" justifyContent="center">
      <StyledImage src={imageSrc} alt="" />
    </Box>
    <Box justifyContent="center" textAlign="initial">
      <Typography>{message}</Typography>
      {secondaryMessage ? <Typography>{secondaryMessage}</Typography> : null}
    </Box>
  </>
)

export const FailureFeedbackContent = ({ err }: { err?: string }) => {
  let message = errorMsg(err)
  const { correlationId } = useApi()

  const secondaryMessage =
    message === DEFAULT_MESSAGE && correlationId ? (
      <Typography>
        <strong>#{correlationId.split('-')[0]}</strong>
      </Typography>
    ) : null

  if (secondaryMessage) {
    message = message + ' Código de erro: '
  }
  return (
    <TextAndImageContent message={message} imageSrc={erroPi} secondaryMessage={secondaryMessage} />
  )
}

export type FeedbackCarneDialogProps = {
  backButtonLabel?: string
  buttonLabel?: string
  centered?: boolean
  children: ReactNode
  closeIcon?: boolean
  hideTitle?: boolean
  isConfirmDisabled?: () => boolean
  isVisible: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  onClose: () => void
  submitHandler: () => void
  title?: string
}

const FeedbackCarneDialog = ({
  buttonLabel = 'Entendi',
  backButtonLabel,
  centered,
  children,
  closeIcon,
  isVisible,
  onClose,
  submitHandler,
  title,
  isConfirmDisabled,
  hideTitle,
  maxWidth,
}: FeedbackCarneDialogProps) => (
  <Dialog
    data-testid="feedback-dialog"
    open={isVisible}
    onClose={onClose}
    maxWidth={maxWidth || 'sm'}
    fullWidth
  >
    {!hideTitle && (
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {title}
          {closeIcon && (
            <IconButton onClick={onClose} data-testid="close-feedback-button">
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
    )}
    <DialogContent>{children}</DialogContent>
    <DialogActions className={`${centered ? 'centered' : ''}`}>
      {backButtonLabel && (
        <Button variation="ghost" onClick={onClose}>
          {backButtonLabel}
        </Button>
      )}
      <Button variation="primary" onClick={submitHandler} disabled={isConfirmDisabled?.()}>
        {buttonLabel}
      </Button>
    </DialogActions>
  </Dialog>
)

export default FeedbackCarneDialog

export const failureFeedbackTitle = 'A geração do carnê está indisponível no momento'

export const FailureFeedbackDialog = ({
  title = failureFeedbackTitle,
  ...rest
}: FeedbackCarneDialogProps) => (
  <FeedbackCarneDialog title={title} {...rest}>
    {rest.children ?? <FailureFeedbackContent />}
  </FeedbackCarneDialog>
)
