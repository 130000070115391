import { ReactNode } from 'react'

import { Layout } from '@/modules/app/components/Layout'
import { LayoutProvider } from '@/shared/contexts/Layout'

export type SchoolLayoutProviderProps = {
  children: ReactNode
}

export const SchoolLayoutProvider = ({ children }: SchoolLayoutProviderProps) => (
  <LayoutProvider LayoutComponent={Layout}>{children}</LayoutProvider>
)
