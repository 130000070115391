import { Typography } from '@olaisaac/design-system'
import { formatStudentsName } from './formatStudents'
import { StudentBasicInfo } from '../../../types'
import { StyledStudentsList, StyledStudentsListContainer } from '../styles'

export const renderStudents = (students: StudentBasicInfo[]) => {
  return (
    <StyledStudentsListContainer display="flex" flexDirection="column" my={1}>
      <Typography variation="bodySmall" color="primary">
        {`${students?.length} aluno(s)`}
      </Typography>
      <StyledStudentsList variation="bodySmall" color="secondary">
        {formatStudentsName(students)}
      </StyledStudentsList>
    </StyledStudentsListContainer>
  )
}
