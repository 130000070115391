import { Dialog, FormHelperText } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import EditIcon from '@material-ui/icons/Edit'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography as TypographyDS,
} from '@olaisaac/design-system'
import { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useApi } from 'src/shared/hooks'
import { Address } from 'src/shared/interfaces'
import { UFs, formatCPF, validateEmail, validatePhoneNumber } from 'src/shared/utils'
import styled from 'styled-components'
import { defaultAddress } from '../../constants'
import { FormProps, GuardianFormNames } from '../../types'

const OutlinedContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  padding: 16px;
  padding-top: 24px;
  margin-top: 16px;
`

const FilledContainer = styled(OutlinedContainer)`
  background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[1]};
`

const TransparentContainer = styled.div`
  margin-top: 32px;
`

const HeaderContainer = styled.div`
  p {
    flex: 1;
  }

  margin-bottom: 24px;
  display: flex;
  justify-content: end;
  gap: 16px;
  align-items: center;
  min-height: 48px;
`

const getContainer = (sameGuardianSelected: boolean, taxIDFound: boolean, isEditing: boolean) => {
  if (isEditing) {
    return OutlinedContainer
  }

  if (sameGuardianSelected || taxIDFound) {
    return FilledContainer
  }

  return TransparentContainer
}

const guardianHeaderText = (
  taxIDFound: boolean,
  sameGuardianSelected: boolean,
  guardianName: string,
  taxID: string
): JSX.Element | string => {
  if (sameGuardianSelected) {
    return 'Responsável selecionado:'
  }

  if (taxIDFound) {
    return (
      <>
        Responsável <strong>{guardianName}</strong> encontrado:
      </>
    )
  }

  return (
    <>
      Adicione um novo responsável com o CPF <strong>{formatCPF(taxID)}</strong>
    </>
  )
}

type ConfirmEditingDialogProps = {
  onConfirm: () => void
  open: boolean
  setOpen: (open: boolean) => void
}

const ConfirmEditingDialog = ({ open, setOpen, onConfirm }: ConfirmEditingDialogProps) => {
  const handleClose = () => setOpen(false)

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <TypographyDS variation="headlineDesktopMedium">
          Editar dados do responsável financeiro
        </TypographyDS>
      </DialogTitle>
      <DialogContent>
        As edições serão refletidas para todos os contratos que esse responsável financeiro estiver
        vinculado.
      </DialogContent>
      <DialogActions>
        <Button variation="ghost" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variation="primary"
          onClick={() => {
            onConfirm()
            handleClose()
          }}
        >
          Continuar com a edição
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type GuardianTextFieldsProps = {
  form: UseFormReturn<FormProps>
  isEditing: boolean
  sameGuardianSelected: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  taxIDFound: boolean
}

export const GuardianTextFields = ({
  form,
  isEditing,
  sameGuardianSelected,
  setIsEditing,
  taxIDFound,
}: GuardianTextFieldsProps) => {
  const { api } = useApi()
  const { control, setValue, trigger, reset, getValues } = form
  const [zipInputFocused, setZipInputFocused] = useState(false)
  const [open, setOpen] = useState(false)

  const formValues = getValues()

  const setFormAddressValues = (address: Address) => {
    setValue(GuardianFormNames.ADDRESS, address)

    trigger([
      GuardianFormNames.ZIP,
      GuardianFormNames.STREET,
      GuardianFormNames.CITY,
      GuardianFormNames.STATE_CODE,
    ])
  }

  const OnZipFieldFilled = async (zip: cep) => {
    await api.guardians
      .getAddressFromZip(zip)
      .then(setFormAddressValues)
      .catch(() => {
        setValue(GuardianFormNames.ADDRESS, defaultAddress)
      })
  }

  const Container = getContainer(sameGuardianSelected, taxIDFound, isEditing)

  const isNewGuardian = !taxIDFound && !sameGuardianSelected
  const disabled = !isEditing && !isNewGuardian

  return (
    <>
      <ConfirmEditingDialog open={open} setOpen={setOpen} onConfirm={() => setIsEditing(true)} />
      <Container>
        <HeaderContainer>
          <TypographyDS variation="bodyLarge">
            {guardianHeaderText(
              taxIDFound,
              sameGuardianSelected,
              formValues.guardian.name,
              formValues.guardian.tax_id
            )}
          </TypographyDS>
          {!isNewGuardian && !isEditing && (
            <Button startIcon={<EditIcon />} variation="ghost" onClick={() => setOpen(true)}>
              Editar
            </Button>
          )}
        </HeaderContainer>

        <Grid spacing={5} container>
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <NumberFormat
                  id={GuardianFormNames.TAX_ID}
                  disabled
                  type="tel"
                  value={formValues.guardian.tax_id}
                  format="###.###.###-##"
                  label="CPF"
                  customInput={TextField}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  rules={{ required: true }}
                  name={GuardianFormNames.NAME}
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      id={GuardianFormNames.NAME}
                      disabled={sameGuardianSelected || taxIDFound}
                      value={value}
                      onChange={onChange}
                      label="Nome do responsável financeiro"
                      error={Boolean(error?.type)}
                      helperText={error?.type ? 'Insira um nome válido' : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  rules={{ required: true, validate: validatePhoneNumber }}
                  name={GuardianFormNames.PHONE_NUMBER}
                  control={control}
                  render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                    <NumberFormat
                      {...rest}
                      disabled={disabled}
                      id={GuardianFormNames.PHONE_NUMBER}
                      value={value}
                      type="tel"
                      label="Telefone ou celular"
                      format="(##) #####-####"
                      mask="_"
                      onValueChange={currentValue => {
                        if (!currentValue) {
                          return reset(
                            {
                              ...formValues,
                              guardian: { ...formValues.guardian, phone_number: '' },
                            },
                            { keepErrors: true }
                          )
                        }
                        onChange(currentValue.value)
                      }}
                      customInput={TextField}
                      variant="outlined"
                      error={Boolean(error?.type)}
                      helperText={error?.type ? 'Insira um número válido' : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  rules={{
                    required: true,
                    validate: {
                      validateEmail,
                    },
                  }}
                  name={GuardianFormNames.EMAIL}
                  control={control}
                  render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                    <TextField
                      {...rest}
                      disabled={disabled}
                      id={GuardianFormNames.EMAIL}
                      label="Email"
                      value={value}
                      onChange={currentValue => {
                        if (!currentValue) {
                          return reset(
                            {
                              ...formValues,
                              guardian: { ...formValues.guardian, email: '' },
                            },
                            { keepErrors: true }
                          )
                        }
                        onChange(currentValue)
                      }}
                      error={Boolean(error?.type)}
                      helperText={error?.type ? error?.message ?? 'E-mail inválido' : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  rules={{ required: true, minLength: 8, maxLength: 8 }}
                  name={GuardianFormNames.ZIP}
                  control={control}
                  render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                    <NumberFormat
                      {...rest}
                      disabled={disabled}
                      id={GuardianFormNames.ZIP}
                      value={value}
                      type="tel"
                      label="CEP"
                      format="#####-###"
                      onFocus={() => setZipInputFocused(true)}
                      onBlur={() => setZipInputFocused(false)}
                      onValueChange={currentValue => {
                        if (!currentValue.value) {
                          return reset(
                            {
                              ...formValues,
                              guardian: {
                                ...formValues.guardian,
                                address: defaultAddress,
                              },
                            },
                            { keepErrors: true }
                          )
                        }
                        onChange(currentValue.value)

                        if (zipInputFocused) {
                          currentValue.value.length === 8 && OnZipFieldFilled(currentValue.value)
                        }
                      }}
                      customInput={TextField}
                      variant="outlined"
                      error={Boolean(error?.type)}
                      helperText={error?.type ? 'CEP inválido' : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  rules={{ required: true }}
                  name={GuardianFormNames.STREET}
                  control={control}
                  render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                    <TextField
                      {...rest}
                      disabled={disabled}
                      id={GuardianFormNames.STREET}
                      value={value}
                      label="Logradouro (rua, avenida, praça)"
                      placeholder="Logradouro"
                      onChange={currentValue => {
                        if (!currentValue) {
                          return reset(
                            {
                              ...formValues,
                              guardian: {
                                ...formValues.guardian,
                                address: { ...formValues.guardian.address, street: '' },
                              },
                            },
                            { keepErrors: true }
                          )
                        }
                        onChange(currentValue)
                      }}
                      error={Boolean(error?.type)}
                      helperText={error?.type ? 'Logradouro inválido' : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name={GuardianFormNames.ADDRESS_NUMBER}
                  control={control}
                  render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                    <TextField
                      {...rest}
                      disabled={disabled}
                      id={GuardianFormNames.ADDRESS_NUMBER}
                      value={value}
                      label="Número"
                      placeholder="Número"
                      onChange={currentValue => {
                        if (!currentValue) {
                          return reset(
                            {
                              ...formValues,
                              guardian: {
                                ...formValues.guardian,
                                address: { ...formValues.guardian.address, number: '' },
                              },
                            },
                            { keepErrors: true }
                          )
                        }
                        onChange(currentValue)
                      }}
                      error={Boolean(error?.type)}
                      helperText={error?.type ? 'Insira um número' : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name={GuardianFormNames.ADDITIONAL_INFO}
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <TextField
                      {...rest}
                      disabled={disabled}
                      id={GuardianFormNames.ADDITIONAL_INFO}
                      label="Complemento (opcional)"
                      placeholder="Complemento"
                      value={value}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  rules={{ required: true }}
                  name={GuardianFormNames.CITY}
                  control={control}
                  render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                    <TextField
                      {...rest}
                      disabled={disabled}
                      id={GuardianFormNames.CITY}
                      value={value}
                      label="Cidade"
                      placeholder="Cidade"
                      onChange={currentValue => {
                        if (!currentValue) {
                          return reset(
                            {
                              ...formValues,
                              guardian: {
                                ...formValues.guardian,
                                address: { ...formValues.guardian.address, city: '' },
                              },
                            },
                            { keepErrors: true }
                          )
                        }
                        onChange(currentValue)
                      }}
                      error={Boolean(error?.type)}
                      helperText={error?.type ? 'Insira uma cidade' : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id={GuardianFormNames.STATE_CODE}>UF</InputLabel>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name={GuardianFormNames.STATE_CODE}
                  render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                    <>
                      <Select
                        {...rest}
                        disabled={disabled}
                        labelId={GuardianFormNames.STATE_CODE}
                        label="UF"
                        value={value}
                        onChange={currentValue => {
                          if (!currentValue) {
                            return reset(
                              {
                                ...formValues,
                                guardian: {
                                  ...formValues.guardian,
                                  address: { ...formValues.guardian.address, state_code: '' },
                                },
                              },
                              { keepErrors: true }
                            )
                          }
                          onChange(currentValue)
                        }}
                        error={Boolean(error?.type)}
                      >
                        {UFs.map(state => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && <FormHelperText error>Informe um UF válido</FormHelperText>}
                    </>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
