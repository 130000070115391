import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'

export const StyledTypography = styled(Typography).attrs({ withoutMargin: true })`
  overflow-wrap: break-word;
`
export const StyledBodyLargeTypography = styled(StyledTypography).attrs({
  variation: 'bodyLarge',
})``

export const ConfirmationHeader = styled(Box)`
  margin-bottom: 24px;
  margin-top: 24px;
`
export const ConfirmationTitle = styled(StyledTypography)`
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 4px;
`
export const ReasonTitleTypography = styled(StyledTypography)`
  font-size: 18px;
  line-height: 28px;
  color: #414141;
  margin-bottom: 4px;
`
export const ReasonTypography = styled(StyledTypography)`
  font-size: 16px;
  line-height: 24px;
  color: #6d6d6d;
`
export const ReasonBox = styled(Box)`
  margin-bottom: 24px;
`
export const RuleBox = styled(Box)`
  margin-bottom: 24px;
`
