import styled from 'styled-components'

export const Container = styled.div`
  padding: 24px 64px 0;
  margin: 0px -64px;
  border-top: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  border-bottom: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
`

export const BadgeContainer = styled.div`
  padding-left: 8px;
`

export const BannerHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

export const RefreshButtonContainer = styled.div`
  margin-left: auto;
`
