import { useRef, useState } from 'react'
import type { TableRowProps } from '../types'

export const useCloseCampaignDialog = () => {
  const [dialogVisible, setDialogVisible] = useState(false)
  const selectedRow = useRef<null | TableRowProps>(null)

  const openDialog = (row: TableRowProps) => {
    selectedRow.current = row
    setDialogVisible(true)
  }

  const closeDialog = () => {
    setDialogVisible(false)
    selectedRow.current = null
  }

  return {
    dialogVisible,
    selectedRow: selectedRow.current,
    openDialog,
    closeDialog,
  }
}
