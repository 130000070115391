import { FallbackProps } from 'react-error-boundary'
import { useLocation } from 'react-router-dom'
import { GenericCreditError, SimulationCreditError } from '../Errors'

const SIMULATION_CREDIT_PATH = '/credito/simulacao'

export const ErrorFallback: React.FC<FallbackProps> = () => {
  const location = useLocation()

  if (location.pathname.includes(SIMULATION_CREDIT_PATH)) {
    return <SimulationCreditError />
  }

  return <GenericCreditError />
}
