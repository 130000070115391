import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import type { RouteDefinition } from './types'
import { RestrictedRoute } from './RestrictedRoute'

type RouterParams = {
  loadingComponent: JSX.Element
  routes: RouteDefinition[]
}

export const buildRoutes = (routes: RouteDefinition[]) => {
  return routes.map(route => {
    if (route.redirect) return <Redirect key={route.key} from={route.path} to={route.redirect} />

    if (route.canAccess) {
      return (
        <Route key={route.key} path={route.path} exact={route.exact}>
          <RestrictedRoute canAccess={route.canAccess} Component={route.component} />
        </Route>
      )
    }

    return (
      <Route key={route.key} path={route.path} exact={route.exact} component={route.component} />
    )
  })
}

export function Router(props: RouterParams) {
  return (
    <Suspense fallback={props.loadingComponent}>
      <Switch>{buildRoutes(props.routes)}</Switch>
    </Suspense>
  )
}
