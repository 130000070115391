import { PayoutStatus, PayoutVersion } from '@/modules/report/models/report'
import { PayoutSummary } from '@/modules/report/models/PayoutSummary'

const payoutSummaryItem1: PayoutSummary = {
  final_gross_amount: null,
  final_amount: null,
  final_discounts_amount: null,
  has_enrollment_payout: null,
  enrollment_take_rate_amount: null,
  take_rate_amount: null,
  enrollment_take_rate: null,
  take_rate: null,
  pos_payments: null,
  payout_id: '7069c0fe-3640-44f2-be95-a9009185d835',
  payout_due_date: '2022-10-01T00:00:00Z',
  payout_status: PayoutStatus.OPEN,
  version: PayoutVersion.DASHBOARD_PV,
}

const payoutSummaryItem2: PayoutSummary = {
  final_gross_amount: 0,
  final_amount: 133648.91,
  final_discounts_amount: 0,
  pos_payments: 0,
  has_enrollment_payout: true,
  enrollment_take_rate_amount: 0,
  take_rate_amount: 25420.47,
  enrollment_take_rate: 0,
  take_rate: 15.25,
  payout_id: '2d1225e0-179d-47f1-bc55-10b5c1f54045',
  payout_due_date: '2022-09-01T00:00:00Z',
  payout_status: PayoutStatus.PAID,
  version: PayoutVersion.DASHBOARD_PV,
}

export const payoutSummary = [payoutSummaryItem1, payoutSummaryItem2]
