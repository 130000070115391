import { FilterOptionsType } from '../types'

export enum GuardiansInstallmentsQueryParamsNameEnum {
  COMPETENCE_DATES = 'installment_competence_dates',
  CONTRACT_STATUS = 'contract_status',
  DEBT_STATUSES = 'debt_statuses',
  PRODUCT_IDS = 'product_ids',
  STATUSES = 'installment_statuses',
  STUDENT_IDS = 'student_ids',
  TYPES = 'installment_types',
  YEARS = 'reference_years',
}

export const INSTALLMENT_STATUSES: FilterOptionsType[] = [
  {
    label: 'Paga',
    value: 'PAID',
  },
  {
    label: 'Paga e cancelada',
    value: 'PAID_AND_CANCELED',
  },
  {
    label: 'Vencida',
    value: 'OVERDUE',
  },
  {
    label: 'A vencer',
    value: 'OPEN',
  },
  {
    label: 'Cancelada',
    value: 'CANCELED',
  },
  {
    label: 'Renegociada',
    value: 'RENEGOTIATED',
  },
  {
    label: 'Aglutinada',
    value: 'AGGLUTINATED',
  },
]

export const INSTALLMENT_TYPES: FilterOptionsType[] = [
  {
    label: 'Pré-matrícula',
    value: 'ENROLLMENT',
  },
  {
    label: 'Mensalidade',
    value: 'TUITION',
  },
]

export const INSTALLMENT_CONTRACT_STATUSES: FilterOptionsType[] = [
  {
    label: 'Contrato ativo',
    value: 'OPEN',
  },
  {
    label: 'Contrato inativo',
    value: 'CANCELED',
  },
]
