import { z } from 'zod'

export const enrollmentStatusItemSchema = z.object({
  label: z.string(),
  value: z.string(),
  selected: z.boolean().default(false),
})

export const enrollmentSubStatusItemSchema = z.object({
  label: z.string(),
  value: z.string(),
  selected: z.boolean().default(false),
})

export const filterSchema = z
  .object({
    enrollment_status: z.array(enrollmentStatusItemSchema).optional(),
    enrollment_sub_status: z.array(enrollmentSubStatusItemSchema).optional(),
  })
  .optional()
  .nullable()
  .transform(data => {
    return {
      enrollment_status: data?.enrollment_status,
      enrollment_sub_status: data?.enrollment_sub_status,
      enrollmentStatus: data?.enrollment_status
        ? data?.enrollment_status.reduce<string[]>((acc, status) => {
            if (status.selected) acc.push(status.value)
            return acc
          }, [])
        : null,
      enrollmentSubStatus: data?.enrollment_sub_status
        ? data?.enrollment_sub_status.reduce<string[]>((acc, status) => {
            if (status.selected) acc.push(status.value)
            return acc
          }, [])
        : null,
    }
  })

export type filterForm = z.infer<typeof filterSchema>
