import styled from 'styled-components'

export const NoPendingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  margin-top: ${props => props.theme.gravity.spacing[12]};
  span {
    margin-top: ${props => props.theme.gravity.spacing[2]};
    line-height: ${props => props.theme.gravity.spacing[6]};
  }
  a {
    margin-top: ${props => props.theme.gravity.spacing[4]};
  }
`

export const Image = styled.img`
  width: 124px;
  height: 116px;
  margin-bottom: ${props => props.theme.gravity.spacing[2]}; ;
`
