import { CreditReason, ReasonsType } from '@/shared/services/credit/types'
import { useApi } from '@/shared/hooks'
import { useQuery } from '@tanstack/react-query'

export const creditReasonsQueryKey = 'payout-anticipation-reasons'

export const useGetPayoutAnticipationReasons = () => {
  const { api } = useApi()

  return useQuery<CreditReason[]>(
    [creditReasonsQueryKey],
    async () => {
      const { data } = await api.credit.getCreditReasons(ReasonsType.payout)
      return data
    },
    { retry: 2, suspense: true }
  )
}
