import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Select } from '@gravity/select'
import { TextField } from '@gravity/text-field'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import { Controller } from 'react-hook-form'
import { mdiPlus, mdiInformationOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { Callout } from '@gravity/callout'
import { spacing } from '@gravity/tokens'
import { Tooltip } from '@gravity/tooltip'

import { formatDate } from '@/shared/utils'
import { StepFooter } from '@/modules/enrollment/components/Stepper/components/StepFooter'
import { StepPageProps } from '@/modules/enrollment/components/Stepper/types'
import { usePaymentConditionsStep } from '@/modules/enrollment/pages/Campaigns/NewCampaign/components/PaymentConditionsStep/hooks/usePaymentConditionsStep'

import { SelectProductInput } from './components/SelectedProductInput'
import { OfferList } from './components/OfferList'
import { InputWrapper, Row } from '../styles'
import * as Styled from './styles'

export const PaymentConditionsStep = ({ onFinish, enrollmentCycle }: StepPageProps) => {
  const {
    form,
    tomorrowDate,
    lastDayOfYearDate,
    disableSubmitButton,
    handleSubmit,
    isAddOfferBtnDisabled,
    addNewOfferInput,
    offerFields,
    isRemoveButtonDisabled,
    handleRemoveOfferClick,
    advancePaymentInstallment,
    product,
    offers,
    errors,
  } = usePaymentConditionsStep({ onFinish, enrollmentCycle })

  return (
    <Styled.Form onSubmit={form.handleSubmit(handleSubmit)}>
      <Heading variant="heading-h3-medium">Condições</Heading>

      <div style={{ marginTop: spacing[4] }}>
        <Text variant="body-1-regular">
          {`Defina o produto de ${enrollmentCycle.reference_year} e as condições da pré-matrícula.`}
        </Text>

        <Styled.TextFieldWrapper style={{ marginTop: spacing[8] }}>
          <Controller
            rules={{
              required: true,
            }}
            name="name"
            control={form.control}
            render={({ field }) => (
              <TextField
                label="Qual o nome da campanha?"
                aria-label="Qual o nome da campanha?"
                placeholder="Digite o nome da campanha"
                size={3}
                width="100%"
                name={field.name}
                value={field.value}
                ref={field.ref}
                // eslint-disable-next-line react/jsx-handler-names
                onChange={field.onChange}
                error={!!errors?.name}
                errorMessage="Já existe uma campanha com este nome para o produto 2025 selecionado. Insira outro nome ou selecione um produto diferente."
              />
            )}
          />
        </Styled.TextFieldWrapper>

        <SelectProductInput form={form} referenceYear={enrollmentCycle.reference_year.toString()} />

        <div style={{ marginTop: spacing[6] }}>
          {product && (
            <Row>
              <Controller
                rules={{
                  required: true,
                }}
                name="advancePaymentInstallment"
                control={form.control}
                render={({ field, fieldState }) => (
                  <Styled.SelectWrapper data-testid="advance-payment-installment-wrapper">
                    <InputWrapper hasError={!!fieldState.error?.type}>
                      <div>
                        <Text variant="subtitle-medium">Possui parcela de pré-matrícula?</Text>{' '}
                        <Tooltip text="Você pode coletar apenas o interesse de rematrícula dos responsáveis ou enviar também a parcela de pré-matrícula.">
                          <Icon path={mdiInformationOutline} size="16px" />
                        </Tooltip>
                      </div>
                      <Select
                        size={3}
                        variant="surface"
                        label=""
                        options={[
                          {
                            label: 'Sim',
                            value: 'Sim',
                          },
                          {
                            label: 'Não',
                            value: 'Não',
                          },
                        ]}
                        value={field.value}
                        placeholder="Selecione uma opção"
                        // eslint-disable-next-line react/jsx-handler-names
                        onValueChange={newValue => {
                          if (newValue === 'Não') form.setValue('end_date', null)
                          else form.setValue('end_date', offers[offers.length - 1]?.expirationDate)
                          return field.onChange(newValue)
                        }}
                      />
                    </InputWrapper>
                  </Styled.SelectWrapper>
                )}
              />

              <Controller
                rules={{
                  required: true,
                }}
                name="end_date"
                control={form.control}
                render={({ field, fieldState }) => (
                  <InputWrapper
                    hasError={!!fieldState.error?.type && advancePaymentInstallment !== 'Sim'}
                  >
                    <Datepicker.Root>
                      <div>
                        <Text variant="subtitle-medium" id="campaign-due-date-label">
                          Qual data de validade da campanha?
                        </Text>{' '}
                        <Tooltip
                          text={
                            advancePaymentInstallment === 'Sim'
                              ? `A data de validade da campanha corresponde à última data de validade entre as condições de pagamento.
                                Após o encerramento da campanha, todas as propostas não aceitas serão canceladas e removidas do meu isaac.`
                              : 'Após o término da campanha, todas as propostas não aceitas serão canceladas e removidas do meu isaac.'
                          }
                        >
                          <Icon path={mdiInformationOutline} size="16px" />
                        </Tooltip>
                      </div>
                      <Styled.DatepickerTrigger
                        disabled={advancePaymentInstallment === 'Sim'}
                        size={3}
                        aria-labelledby="campaign-due-date-label"
                        placeholder="Selecione a data"
                      >
                        {field.value ? formatDate(field.value, 'DD/MM/YYYY') : null}
                      </Styled.DatepickerTrigger>
                      <Datepicker.Calendar
                        value={field.value}
                        minDate={tomorrowDate}
                        maxDate={lastDayOfYearDate}
                        data-testid="datepicker-calendar"
                        minDetail="decade"
                        onChange={date => field.onChange(date)}
                      />
                    </Datepicker.Root>
                  </InputWrapper>
                )}
              />
            </Row>
          )}
          <Styled.OfferInfoWrapper>
            {advancePaymentInstallment === 'Sim' && (
              <Text variant="body-1-regular">
                Você pode definir até três condições de pagamento para a pré-matrícula. O valor da
                parcela é automaticamente atualizado se o responsável não efetuar o pagamento até a
                data limite.
              </Text>
            )}

            {advancePaymentInstallment === 'Não' && (
              <Callout
                text="Lembre-se: campanhas sem parcela de pré-matrícula ainda necessitam da adição das
                parcelas de mensalidade no momento da criação do contrato!"
              />
            )}
          </Styled.OfferInfoWrapper>
          {product && advancePaymentInstallment === 'Sim' && (
            <OfferList
              form={form}
              referenceYear={enrollmentCycle.reference_year}
              offerFields={offerFields}
              isRemoveButtonDisabled={isRemoveButtonDisabled}
              handleRemoveOfferClick={handleRemoveOfferClick}
            />
          )}
        </div>
      </div>

      <StepFooter disabled={disableSubmitButton}>
        {product && advancePaymentInstallment === 'Sim' && (
          <Row>
            <Styled.AddOfferBtnWrapper>
              <Tooltip
                text={isAddOfferBtnDisabled ? 'Só é possível adicionar até três condições.' : ''}
                hidden={!isAddOfferBtnDisabled}
              >
                <div>
                  <Styled.OfferButton
                    disabled={isAddOfferBtnDisabled}
                    variant="outline"
                    iconStart={<Icon path={mdiPlus} />}
                    onClick={addNewOfferInput}
                    type="button"
                    fullWidth
                  >
                    Adicionar nova condição
                  </Styled.OfferButton>
                </div>
              </Tooltip>
            </Styled.AddOfferBtnWrapper>
          </Row>
        )}
      </StepFooter>
    </Styled.Form>
  )
}
