export const OPTIONS = [
  {
    label: 'Em dia',
    value: 'UP_TO_DATE',
  },
  {
    label: 'Com pendência',
    value: 'OVERDUE',
  },
  {
    label: 'Atenção',
    value: 'WARNING',
  },
]
