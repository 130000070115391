import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[6]};
  padding: ${props => props.theme.gravity.spacing[6]};
  background-color: ${props => props.theme.gravity.colors['colors-background-neutral-1']};
  border: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-3']};
  border-radius: ${props => props.theme.gravity.borderRadius[3]};

  > div {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gravity.spacing[6]};
  }
`
