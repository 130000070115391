import { useContext } from 'react'
import { ApiContext } from '@/shared/contexts/apiContext'

export const useApi = () => {
  const { correlationId, api, setCorrelationId, apiClient } = useContext(ApiContext)

  return {
    correlationId,
    setCorrelationId,
    api,
    apiClient,
  }
}
