import { useState } from 'react'
import { mdiAccountSchool, mdiMinusCircle } from '@mdi/js'
import { Tabs } from '@gravity/tabs'
import { Text } from '@gravity/text'
import Icon from '@mdi/react'
import { spacing } from '@gravity/tokens'

export enum ToggleValues {
  INCLUDED = 'included',
  NOT_INCLUDED = 'not-included',
}

interface ToggleButtonProps {
  onChange: (value: ToggleValues) => void
}

export const ToggleButton = ({ onChange }: ToggleButtonProps) => {
  const includedLabel = 'Alunos incluídos'
  const notIncludedLabel = 'Alunos não incluídos'

  const [value, setValue] = useState(ToggleValues.INCLUDED)

  const handleValueChange = (newValue: string) => {
    if (newValue === ToggleValues.INCLUDED || newValue === ToggleValues.NOT_INCLUDED) {
      setValue(newValue)
      onChange(newValue)
    }
  }

  return (
    <Tabs.Root
      value={value}
      onValueChange={value => handleValueChange(value)}
      style={{ marginTop: spacing[8] }}
    >
      <Tabs.List background="gray">
        <Tabs.Trigger value={ToggleValues.INCLUDED} iconStart={<Icon path={mdiAccountSchool} />}>
          <Text variant="button-2" className="text-inherit">
            {includedLabel}
          </Text>
        </Tabs.Trigger>
        <Tabs.Trigger value={ToggleValues.NOT_INCLUDED} iconStart={<Icon path={mdiMinusCircle} />}>
          <Text variant="button-2" className="text-inherit">
            {notIncludedLabel}
          </Text>
        </Tabs.Trigger>
      </Tabs.List>
    </Tabs.Root>
  )
}
