import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[4]};
`
export const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};
  padding: ${props => props.theme.gravity.spacing[4]};
`

export const CardInfoWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${props => props.theme.gravity.spacing[2]};
`
