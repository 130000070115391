import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import { Box, FormControl, FormHelperText } from '@material-ui/core'
import { Typography, Checkbox, FormControlLabel } from '@olaisaac/design-system'
import { PreContractDisclaimerProps } from './types'

const Container = styled.section`
  margin-top: 64px;
`

const HelperTextWrapper = styled.div`
  margin-left: 32px;
  margin-top: -10px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-items: start;
`

const StyledTypography = styled(Typography)`
  margin-top: 4px;
`

export const PreContractDisclaimer = ({ form, referenceYear }: PreContractDisclaimerProps) => {
  const { control } = form

  return (
    <Container>
      <Typography variation="headlineDesktopXsmall">Termos e condições</Typography>
      <Box mt={2} mb={6}>
        <Controller
          rules={{ required: true, validate: v => v }}
          defaultValue={false}
          name="pre_contract_disclaimer_check"
          control={control}
          render={({ field: { value, ...rest }, fieldState: { error } }) => (
            <FormControl error={Boolean(error?.type)}>
              <StyledFormControlLabel
                control={
                  <Checkbox
                    checkedLink={undefined}
                    valueLink={undefined}
                    {...rest}
                    checked={value}
                    data-testid="pre-contract-disclaimer-checkbox"
                  />
                }
                label={
                  <StyledTypography variation="bodySmall">
                    Declaro que li e concordo que o pagamento do valor da pré-matrícula não garante
                    a vaga do aluno no ano letivo de {referenceYear}. A mesma será confirmada{' '}
                    <strong>
                      mediante adimplência ou renegociação do contrato de{' '}
                      {Number(referenceYear) - 1}
                    </strong>
                    , de acordo com as nossas políticas internas.
                  </StyledTypography>
                }
              />
              {Boolean(error?.type) && (
                <HelperTextWrapper>
                  <FormHelperText>
                    Aceite os termos e condições para criar o contrato.
                  </FormHelperText>
                </HelperTextWrapper>
              )}
            </FormControl>
          )}
        />
      </Box>
    </Container>
  )
}

export default PreContractDisclaimer
