import { useState } from 'react'
import { Button, Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'
import { CloseRounded as CloseRoundedIcon } from '@material-ui/icons'

import dayjs from 'dayjs'
import sanitizeHtml from 'sanitize-html'

import { Chip } from '../Chip'
import { ContentContainer } from '../ContentContainer'

import IsaacProfileLogo from '@/escolas/assets/isaac-profile-logo.svg'

import { useTrackNotificationModalEvents } from './useTrackNotificationModalEvents'
import { useFormatNotificationLink } from '../../hooks'

import { Modal, StyledIconButton, spacing } from './styles'

import type { NotificationModalProps } from './types'

export const NotificationModal = (props: NotificationModalProps) => {
  const { open, onClose, notification } = props

  const [isRedirecting, setIsRedirecting] = useState(false)

  const { formattedLinkUrl } = useFormatNotificationLink(notification.linkUrl)

  const { trackModalClose, trackActionButtonClick } = useTrackNotificationModalEvents()

  const handleModalClose = () => {
    onClose()
    trackModalClose()
  }

  const handleActionButtonClick = () => {
    window.location.href = formattedLinkUrl
    setIsRedirecting(true)

    trackActionButtonClick(notification.title)
  }

  return (
    <Modal open={open} maxWidth="md" onClose={handleModalClose}>
      <Box display="grid" gridGap={spacing[3]} padding={spacing[8]}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Chip label={notification.category} $variation={notification.category} size="small" />
          <StyledIconButton size="small" onClick={handleModalClose}>
            <CloseRoundedIcon />
          </StyledIconButton>
        </Box>
        <Typography as="h2" variation="headlineDesktopSmall">
          {notification.title}
        </Typography>

        <Box display="grid" gridGap={spacing[6]} marginBottom={spacing[3]}>
          <Box display="flex" gridGap={spacing[2]}>
            <img src={IsaacProfileLogo} alt="" width={37.2} height={32} />
            <Box display="grid">
              <Typography variation="caption" style={{ fontWeight: 500 }}>
                isaac comunica
              </Typography>
              <Typography variation="caption" color="secondary">
                {dayjs(notification.date).format('dddd, DD/MM/YYYY, HH[h]mm')}
              </Typography>
            </Box>
          </Box>
          <ContentContainer
            $variation="small"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(notification.contentHtml) }}
          />
        </Box>
        {notification?.linkUrl && (
          <>
            <Button
              style={{ width: 'fit-content' }}
              onClick={handleActionButtonClick}
              loading={isRedirecting}
            >
              {notification.linkText}
            </Button>
          </>
        )}
      </Box>
    </Modal>
  )
}
