import { useUnleashFlag, UnleashFlags } from '@/shared/hooks/useUnleashFlag'
import { useHasPendingIrregularitiesQuery } from '@/modules/school-home/hooks/queries/enrollment-request/useHasPendingIrregularitiesQuery'
import { useGetSchoolEnrollmentCycles } from '@/modules/enrollment/hooks/queries/useGetSchoolEnrollmentCycles'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { isIsaacPaySchool } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'

import IrregularitiesBanner from '@/escolas/assets/irregularities-banner-illustration.png'
import EnrollmentCampaignIllustration from '@/escolas/assets/EnrollmentCampaignIllustration.svg'
import ReEnrollmentCampaignIllustration from '@/escolas/assets/ReEnrollmentCampaignIllustration.svg'
import Banner2kSchools from '@/escolas/assets/banner-2k-schools.png'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import type { BannerInfo, UseBannerInfosProps, UseBannerInfosReturn } from './types'

const getReEnrollmentCampaignBanner = (): BannerInfo => {
  const title = 'Campanhas de rematrícula para 2025'
  return {
    title: title,
    explanation:
      'Descubra a facilidade de criar campanhas na Plataforma isaac! Antecipe o início das rematrículas e simplifique o processo para sua escola e responsáveis.',
    actionLabel: 'Acessar agora',
    actionPath: `/matriculas`,
    imageSrc: EnrollmentCampaignIllustration,
    backgroundColor: '#131B5C',
    color: '#FFFFFF',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

const getEnrollmentCampaignBanner = (): BannerInfo => {
  const title = 'Matrículas 2025'
  return {
    title: title,
    explanation:
      'O novo ano letivo já está disponível para sua escola! Você já pode matricular alunos para 2025.',
    actionLabel: 'Acessar agora',
    actionPath: `/matriculas`,
    imageSrc: ReEnrollmentCampaignIllustration,
    backgroundColor: '#F7F8FD',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

const getIrregularitiesBanner = (): BannerInfo => {
  const title = 'Evite bloqueios no envio de boletos: corrija o registro dos alunos'
  return {
    title: title,
    explanation:
      'Pendências de registro impedem que os alunos sejam registrados e os valores sejam repassados.',
    actionLabel: 'Liberar alunos pendentes',
    actionPath: `/irregularidades`,
    imageSrc: IrregularitiesBanner,
    backgroundColor: '#FFEAEE',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

const get2kSchoolsBanner = (): BannerInfo => {
  const title = 'Obrigado por fazer parte das 2000 escolas parceiras isaac.'
  return {
    title: title,
    titleSize: 'large',
    explanation: '',
    actionLabel: 'Celebre com a gente',
    actionPath: `https://www.youtube.com/watch?v=0eL0gb2DfKQ`,
    actionExternalLink: true,
    imageSrc: Banner2kSchools,
    backgroundColor: '#131B5C',
    color: '#FFFFFF',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

export const useBannerInfos = ({
  referenceYear,
  schoolId,
}: UseBannerInfosProps): UseBannerInfosReturn => {
  const infos: BannerInfo[] = []

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const { school, retryFetchSchool, hasErrorOnFetchSchool, isLoadingSchool } = useSelectedSchool()
  const isIsaacPay = isIsaacPaySchool(school)
  const {
    data: response,
    refetch: refetchEnrollmentCycles,
    isError: isEnrollmentCyclesError,
    isFetching: isEnrollmentCyclesFetching,
  } = useGetSchoolEnrollmentCycles(school?.id || '', {
    enabled: !!school?.id,
  })

  const schoolEnrollmentCycles = response?.data
  const enrollmentRefrenceYear = 2025
  const isEnrollmentCampaignEnabled =
    schoolEnrollmentCycles?.find(cycle => cycle.reference_year === enrollmentRefrenceYear)
      ?.enabled ?? false

  const hasPendingIrregularitiesQuery = useHasPendingIrregularitiesQuery({
    referenceYear,
    schoolId,
  })

  const isPendingIrregularityLoading =
    referenceYear &&
    (hasPendingIrregularitiesQuery.isLoading || hasPendingIrregularitiesQuery.isFetching)

  function refetch(): void {
    if (hasPendingIrregularitiesQuery.isError) {
      hasPendingIrregularitiesQuery.refetch()
    }

    if (hasErrorOnFetchSchool) {
      retryFetchSchool()
    }

    if (isEnrollmentCyclesError) {
      refetchEnrollmentCycles()
    }
  }

  if (isEnrollmentCampaignEnabled) {
    const enrollmentBanner =
      isIsaacPay || isIntegratedSchool
        ? getEnrollmentCampaignBanner()
        : getReEnrollmentCampaignBanner()

    infos.push(enrollmentBanner)
  }

  if (isIntegratedSchool && hasPendingIrregularitiesQuery?.data?.has_pending_irregularities) {
    infos.push(getIrregularitiesBanner())
  }

  infos.push(get2kSchoolsBanner())

  return {
    isError:
      hasPendingIrregularitiesQuery.isError || hasErrorOnFetchSchool || isEnrollmentCyclesError,
    isLoading: isPendingIrregularityLoading || isLoadingSchool || isEnrollmentCyclesFetching,
    data: infos,
    refetch,
  }
}
