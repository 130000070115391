import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { NotificationEventPageNamesEnum } from '../../models/enums/NotificationEventPageNames.enum'
import { NotificationEventIdentifierNamesEnum } from '../../models/enums/NotificationEventIdentifierNames.enum'

import type { NotificationResponse } from '../../services/types'

export const useTrackNoticationTableEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const trackRowClick = (notification: NotificationResponse) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.TABLE_ROW_CLICK,
        pageName: NotificationEventPageNamesEnum.NOTIFICATIONS_HOME,
        identifierName: NotificationEventIdentifierNamesEnum.NOTIFICATION_TABLE_ROW,
        customProperties: {
          $title: notification.title,
          $notification: notification,
        },
      })
    }
  }

  const trackPageChange = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.BUTTON_CLICK,
        pageName: NotificationEventPageNamesEnum.NOTIFICATIONS_HOME,
        identifierName: NotificationEventIdentifierNamesEnum.NOTIFICATIONS_TABLE_PAGINATION_BUTTON,
        customProperties: {
          $title: 'Change page',
          $button: 'Pagination button',
        },
      })
    }
  }

  const trackUnreadFilterClick = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.BUTTON_CLICK,
        pageName: NotificationEventPageNamesEnum.NOTIFICATIONS_HOME,
        identifierName: NotificationEventIdentifierNamesEnum.UNREAD_NOTIFICATIONS_FILTER,
        customProperties: {
          $title: 'Apenas não lidos',
          $button: 'Unread filter button',
        },
      })
    }
  }

  return {
    trackPageChange,
    trackRowClick,
    trackUnreadFilterClick,
  }
}
