import type { RouteDefinition } from '@/shared/core/router'
import { EditContractPage } from './pages/EditContract'
import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const routes: RouteDefinition[] = [
  {
    key: 'EDIT_CONTRACT',
    path: '/contratos/:contractId/editar',
    component: EditContractPage,
    exact: true,
    canAccess: () => {
      const { getUserRolesBySchool, isBackofficeUser } = useJWT()
      const { school } = useSelectedSchool()
      const userRoles = getUserRolesBySchool(school?.id ?? '')

      const considerUserRole = useUnleashFlag(
        UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL
      )

      return !considerUserRole || isBackofficeUser || userRoles.has('editar_contrato')
    },
  },
]
