import { FormControl, InputLabel, Select } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import { ListOptionInstallments } from './ListOptionInstallments'
import { StyledInput, StyledInputLabel } from './styles'
import type { InputInstallmentsProps } from './types'

const InputInstallments = ({
  maxInstallment,
  totalAmount,
  handleValue,
  selectedValue,
}: InputInstallmentsProps) => {
  return (
    <StyledInput marginRight={4}>
      <StyledInputLabel>
        <Typography variation="bodySmall" withoutMargin color="secondary">
          Qual o número de parcelas?
        </Typography>
      </StyledInputLabel>
      <FormControl fullWidth variant="outlined" disabled={totalAmount === 0}>
        <InputLabel id="number_of_installments">Número de parcelas?</InputLabel>
        <Select
          labelId="number_of_installments"
          label="Número de parcelas?"
          placeholder="Selecionar forma de pagar"
          value={selectedValue}
          onChange={e => {
            handleValue(Number(e.target.value))
          }}
        >
          {ListOptionInstallments(maxInstallment, totalAmount)}
        </Select>
      </FormControl>
    </StyledInput>
  )
}

export default InputInstallments
