import { Typography } from '@olaisaac/design-system'

import { TableCell } from './TableCell'

import type { TableCellProps } from './types'

export const TableColumnCell = (props: TableCellProps) => {
  const { children, width = 'auto' } = props

  return (
    <TableCell width={width}>
      <Typography variation="bodySmall" color="secondary" style={{ fontWeight: 500 }}>
        {children}
      </Typography>
    </TableCell>
  )
}
