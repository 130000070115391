import styled from 'styled-components'
import { spacing } from '@gravity/tokens'
import { Card } from '@gravity/card'

export const StyledCard = styled(Card)`
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[2]};
  margin-left: ${spacing[6]};
`
