import type { AxiosInstance } from 'axios'

import type { FetchSchoolGroupAndSchoolsResponse } from './types'

export const schoolGroupsService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetch user school group and its schools
     */
    fetchSchoolGroupAndSchools: async (
      schoolId: string
    ): Promise<FetchSchoolGroupAndSchoolsResponse> => {
      const { data } = await fetcher.get<FetchSchoolGroupAndSchoolsResponse>(
        'school-groups/schools',
        {
          params: {
            external_id: schoolId,
          },
        }
      )

      return data
    },
    fetchIsGroupManager: async (): Promise<number> => {
      const { status } = await fetcher.get('school-groups/access')

      return status
    },
  }
}
