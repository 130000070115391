import { Controller, UseFormReturn, FieldError } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { dissoc, path } from 'ramda'
import { formatCentsToReal, roundCents } from 'src/shared/utils'
import { ChangeEvent, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { AddDiscountsFormType } from '../DiscountForm/DiscountForm'

export const defaultDiscountsOptions = [
  { name: 'Desconto permanente', value: -1, description: 'Desconto independe do vencimento' },
  { name: 'Mantêm até vencimento', value: 0, description: 'Desconto para pagamento pontual' },
  { name: 'Antes do vencimento', value: null, description: 'Desconto para pagamento adiantado' },
]

type DiscountFormFieldsProps = {
  amountDefaultValue?: number
  amountFieldName?: string
  daysBeforeDueDateDefaultValue?: number
  daysBeforeDueDateFieldName?: string
  daysBeforeDueDateLabelFieldName?: string
  descriptionDefaultValue?: string
  descriptionFieldName?: string
  form: UseFormReturn<any>
  totalAmountPaid: number
}

function DiscountFormFields({
  amountDefaultValue,
  amountFieldName = 'amount',
  daysBeforeDueDateDefaultValue,
  daysBeforeDueDateFieldName = 'days_before_due_date',
  daysBeforeDueDateLabelFieldName = 'label_days_before_due_date',
  descriptionDefaultValue,
  descriptionFieldName = 'description',
  form,
  totalAmountPaid,
}: DiscountFormFieldsProps) {
  const [
    shouldShowCustomDaysBeforeDueDateInput,
    setShouldShowCustomDaysBeforeDueDateInput,
  ] = useState<boolean>(false)
  const { control, formState, setValue, watch, getValues } = form
  const invalidAmountError: FieldError | undefined = path(
    amountFieldName.split('.'),
    formState.errors
  )
  const invalidDescription = Boolean(path(descriptionFieldName.split('.'), formState.errors))
  const discountDescription = watch(descriptionFieldName)
  const daysBeforeDueDateLabel = watch(daysBeforeDueDateLabelFieldName)
  const daysBeforeDueDate = watch(daysBeforeDueDateFieldName)
  const discountAmount = watch(amountFieldName)
  const percentageValue = (discountAmount / totalAmountPaid) * 100
  const onPercentageValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const percent = inputValue.replace('%', '')
    const newDiscountAmount = roundCents((totalAmountPaid * +percent) / 100)
    setValue(amountFieldName, newDiscountAmount, { shouldValidate: true })
  }

  useEffect(() => {
    daysBeforeDueDate <= 0 && setShouldShowCustomDaysBeforeDueDateInput(false)
    daysBeforeDueDateLabel === 'Antes do vencimento' &&
      setShouldShowCustomDaysBeforeDueDateInput(true)
  }, [daysBeforeDueDateLabel, daysBeforeDueDate])

  useEffect(() => {
    const selectedValue = defaultDiscountsOptions.filter(
      option => option.value === daysBeforeDueDate || option.value === null
    )[0]

    if (daysBeforeDueDate !== null) {
      setValue(daysBeforeDueDateFieldName, selectedValue?.value || daysBeforeDueDate, {
        shouldValidate: true,
      })

      setValue(daysBeforeDueDateLabelFieldName, selectedValue?.name, {
        shouldValidate: true,
      })
    }
  }, [])

  const validateDiscountValue = (value: number) => {
    if (value <= 0) {
      return 'O valor do desconto não pode ser zero'
    }

    if (value >= totalAmountPaid) {
      return 'O valor do desconto aplicado não pode ser maior ou igual que o valor total de cada parcela.'
    }

    return true
  }

  const validateDiscountsSum = () => {
    const discounts: AddDiscountsFormType['discounts'] = getValues()?.discounts

    const discountsTotal = discounts.reduce((total, discount) => total + discount.amount, 0)

    if (discountsTotal >= totalAmountPaid) {
      return 'Descontos separados que somam 100% da parcela não são permitidos.'
    }

    return true
  }

  return (
    <>
      <Box mb={1} mt={1}>
        <Box mb={1}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{ required: true }}
              control={control}
              name={descriptionFieldName}
              defaultValue={descriptionDefaultValue}
              render={({ field, field: { value } }) => (
                <TextField
                  id={descriptionFieldName}
                  value={value || discountDescription}
                  variant="outlined"
                  label="Motivo do desconto"
                  {...field}
                  error={invalidDescription}
                  helperText={invalidDescription ? 'Insira um motivo para o desconto' : ''}
                />
              )}
            />
          </FormControl>
        </Box>
        <Grid container justifyContent="space-between">
          <Grid item xs={7} style={{ paddingLeft: 0 }}>
            <FormControl fullWidth variant="outlined">
              <Controller
                rules={{
                  required: true,
                  validate: { validateDiscountValue, validateDiscountsSum },
                }}
                control={control}
                name={amountFieldName}
                defaultValue={amountDefaultValue}
                render={({ field, field: { onChange, value } }) => (
                  <NumberFormat
                    {...dissoc('onChange', field)}
                    onValueChange={value => {
                      onChange(value.floatValue || '')
                    }}
                    id={amountFieldName}
                    customInput={TextField}
                    variant="outlined"
                    label="Valor"
                    format={formatCentsToReal}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    value={value}
                    error={Boolean(invalidAmountError)}
                    helperText={invalidAmountError?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={5} style={{ paddingRight: 0 }}>
            <NumberFormat
              id={`${amountFieldName}-percent`}
              onChange={onPercentageValueChange}
              customInput={TextField}
              variant="outlined"
              label="Percentual"
              suffix="%"
              value={percentageValue}
              decimalScale={2}
              InputProps={{
                inputProps: { min: 0, max: 100 },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id={daysBeforeDueDateLabelFieldName}>Desconto válido até</InputLabel>
          <Controller
            rules={{ required: true }}
            control={control}
            name={daysBeforeDueDateLabelFieldName}
            render={({ field: { value, ...rest } }) => (
              <Select
                labelId={daysBeforeDueDateLabelFieldName}
                label="Desconto válido até"
                value={value || ''}
                inputProps={{
                  label: 'Desconto válido até',
                }}
                renderValue={v => <Typography>{v}</Typography>}
                {...rest}
                onChange={e => {
                  const selectedValue = defaultDiscountsOptions.filter(
                    option => option.name === e.target.value
                  )[0]

                  setValue(daysBeforeDueDateFieldName, selectedValue?.value, {
                    shouldValidate: true,
                  })

                  setValue(daysBeforeDueDateLabelFieldName, selectedValue?.name, {
                    shouldValidate: true,
                  })
                }}
              >
                {defaultDiscountsOptions.map(({ name, value: optionValue, description }) => (
                  <MenuItem key={optionValue} value={name} data-testid={name}>
                    <Box display="flex" flexDirection="column">
                      <Typography>{name}</Typography>
                      <Typography variant="body2">{description}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Box>
      {shouldShowCustomDaysBeforeDueDateInput ? (
        <Box mb={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{ required: true }}
              control={control}
              name={daysBeforeDueDateFieldName}
              defaultValue={daysBeforeDueDateDefaultValue ?? ''}
              render={({ field: { value } }) => (
                <NumberFormat
                  id={daysBeforeDueDateFieldName}
                  customInput={TextField}
                  variant="outlined"
                  label="Dias antes do vencimento"
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                  value={value || ''}
                  onValueChange={e => {
                    if (e.floatValue) {
                      setValue(daysBeforeDueDateFieldName, e.floatValue, { shouldValidate: true })
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Box>
      ) : null}
    </>
  )
}

export default DiscountFormFields
