import { Chip as MUIChip } from '@material-ui/core'

import styled from 'styled-components'
import theme from '@/shared/theme'

import type { ChipProps } from './types'

export const { spacing, colors } = theme.primitiveTokens

export const chipColor = {
  alertas: 'yellow',
  informes: 'gray',
  tarefas: 'isaacBlue',
} as const

export const Chip = styled(MUIChip)<ChipProps>`
  text-transform: capitalize;
  color: ${({ $variation }) => colors[chipColor[$variation]][10]};
  background: ${({ $variation }) => colors[chipColor[$variation]][80]};
  width: fit-content;
`
