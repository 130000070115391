import { useState } from 'react'

export const useMenuPopover = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    setAnchorEl(undefined)
  }

  return {
    anchorEl,
    isOpen,
    handleOpen,
    handleClose,
  }
}
