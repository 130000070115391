import { useRef } from 'react'
import { Text } from '@gravity/text'
import { Skeleton } from '@gravity/skeleton'

import { StudentCard, StudentCardSkeleton } from '../StudentCard'
import { StudentsSlider } from '../StudentsSlider'

import { StudentsCarouselProps } from './types'
import * as Styled from './styles'

const CARD_WIDTH = 352

export const StudentsCarouselContainer = ({ students, isLoading }: StudentsCarouselProps) => {
  const cardsContainerRef = useRef<HTMLDivElement>(null)

  const getTitle = () => {
    if (!students?.length) return ''
    return students?.length === 1
      ? `${students?.length} Aluno vinculado`
      : `${students?.length} Alunos vinculados`
  }

  const screenWidth = cardsContainerRef?.current?.offsetWidth
  const cardsForScreen = screenWidth ? Math.trunc(screenWidth / CARD_WIDTH) : 1
  const dotsQuantity =
    students?.length > cardsForScreen ? Math.ceil(students.length / cardsForScreen) : 0
  const calcBase = cardsForScreen * CARD_WIDTH

  return (
    <Styled.Container>
      <Styled.Header>
        {isLoading ? (
          <Skeleton radius={2} width={140} />
        ) : (
          <Text variant="body-2-regular">{getTitle()}</Text>
        )}
        {students?.length > cardsForScreen && (
          <StudentsSlider
            quantityDots={dotsQuantity}
            quantityItems={students?.length}
            anchorEl={cardsContainerRef}
            calcBase={calcBase}
          />
        )}
      </Styled.Header>
      <Styled.CardsContainer ref={cardsContainerRef}>
        {isLoading ? (
          <>
            <StudentCardSkeleton />
            <StudentCardSkeleton />
            <StudentCardSkeleton />
          </>
        ) : (
          students?.map(student => <StudentCard student={student} key={student.student_id} />)
        )}
      </Styled.CardsContainer>
    </Styled.Container>
  )
}
