import { useMemo } from 'react'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useQuery } from '@/shared/hooks/useQuery'
import { usePagination } from '@/shared/hooks/usePagination'
import { useOrdering } from '@/shared/hooks/useOrdering'
import { useTuitionPayoutReports } from '@/modules/report/hooks/queries/useTuitionPayoutReports'
import { useTuitionPayoutReport } from '@/modules/report/hooks/queries/useTuitionPayoutReport'
import { useTuitionPayoutReportFilter } from '@/modules/report/hooks/useTuitionPayoutReportFilter'
import {
  useContextOrderLock,
  useContextEligibility,
} from '@/modules/report/contexts/PayoutAnticipationContext'
import { useGetLock } from '@/modules/report/hooks/queries/useGetLock'
import { useAnticipationEligibility } from '@/modules/report/hooks/queries/useAnticipationEligibility'
import { Eligibility } from '@/shared/services/credit/types'

import { currentTuitionPayoutDetailsMapper } from './utils/currentTuitionPayoutDetailsMapper'

import { PAYOUT_REPORT_VERSIONS } from '@/modules/report/constants/PAYOUT_REPORT_VERSIONS'

import type {
  OrderLock,
  ResponseCreditAnticipationHook,
  TuitionPayoutReportDataValue,
} from './types'

/**
 * Custom hook that exports tuition payout report page data
 */
export const useTuitionPayoutReportData = (): TuitionPayoutReportDataValue => {
  const { query } = useQuery()
  const { school } = useSelectedSchool()
  const { filter } = useTuitionPayoutReportFilter()
  const { pagination } = usePagination(1, 25)
  const { ordering } = useOrdering()

  const {
    data: tuitionPayoutReportList,
    isFetching: isFetchingTuitionPayoutReportList,
    isError: hasErrorOnFetchTuitionPayoutReportList,
    refetch: refetchTuitionPayoutReportList,
  } = useTuitionPayoutReports(school?.id ?? '', {
    enabled: !!school?.id,
  })

  const payoutId = query.get('id') ?? ''
  const nameFilter = query.get('name') ?? ''

  const currentTuitionPayoutReport = tuitionPayoutReportList?.find(
    item => item.payout_id === payoutId
  )
  const isCurrentPayoutInNewVersion =
    currentTuitionPayoutReport?.version === PAYOUT_REPORT_VERSIONS.DASHBOARD_PV

  const {
    data: tuitionPayoutReportDetails,
    isFetching: isFetchingTuitionPayoutReportDetails,
    isError: hasErrorOnFetchCurrentTuitionPayoutReportDetails,
    refetch: refetchTuitionPayoutReportDetails,
  } = useTuitionPayoutReport(
    {
      payoutId,
      filter: {
        name: nameFilter,
        ...filter,
      },
      pagination: {
        page: pagination.page,
        per_page: pagination.itemsPerPage,
        sort_by: ordering.sortBy,
        sort_order: ordering.sortOrder,
      },
    },
    {
      keepPreviousData: true,
      enabled: !isFetchingTuitionPayoutReportList && isCurrentPayoutInNewVersion,
    }
  )

  const hookValue = useMemo<TuitionPayoutReportDataValue>(() => {
    return {
      tuitionPayoutReportList: tuitionPayoutReportList ?? [],
      isFetchingTuitionPayoutReportList,
      currentTuitionPayoutDetails: isCurrentPayoutInNewVersion
        ? currentTuitionPayoutDetailsMapper('details', tuitionPayoutReportDetails)
        : currentTuitionPayoutDetailsMapper('summary', currentTuitionPayoutReport),
      isFetchingCurrentTuitionPayoutDetails:
        isFetchingTuitionPayoutReportList || isFetchingTuitionPayoutReportDetails,
      hasErrorOnFetch:
        hasErrorOnFetchTuitionPayoutReportList || hasErrorOnFetchCurrentTuitionPayoutReportDetails,
      refetch: hasErrorOnFetchTuitionPayoutReportList
        ? refetchTuitionPayoutReportList
        : refetchTuitionPayoutReportDetails,
    }
  }, [
    tuitionPayoutReportList,
    isFetchingTuitionPayoutReportList,
    tuitionPayoutReportDetails,
    isCurrentPayoutInNewVersion,
    currentTuitionPayoutReport,
    isFetchingTuitionPayoutReportDetails,
    hasErrorOnFetchTuitionPayoutReportList,
    hasErrorOnFetchCurrentTuitionPayoutReportDetails,
    refetchTuitionPayoutReportList,
    refetchTuitionPayoutReportDetails,
  ])

  return hookValue
}

export const useGetEligibility = (
  schoolId: string,
  onError?: () => void,
  enabled = true
): ResponseCreditAnticipationHook<Eligibility> => {
  const { eligibility: ctxEligibility, setEligibility } = useContextEligibility()

  const enabledEligibility = enabled && !ctxEligibility
  const {
    data: dataEligibility,
    isLoading,
    isError: isErrorEligibility,
  } = useAnticipationEligibility(schoolId, {
    enabled: enabledEligibility,
    onError,
  })

  enabledEligibility && !isLoading && dataEligibility && setEligibility(dataEligibility)

  return {
    data: ctxEligibility ?? dataEligibility,
    isLoading: enabledEligibility && isLoading,
    isError: isErrorEligibility,
  }
}

export const useGetOrderLock = (
  schoolId: string,
  onError?: () => void,
  enabled = true
): ResponseCreditAnticipationHook<OrderLock> => {
  const { orderLock: ctxOrderLock, setOrderLock } = useContextOrderLock()

  const enabledLock = enabled && !ctxOrderLock
  const { data: lock, isLoading, isError: isErrorLock } = useGetLock(schoolId, {
    enabled: enabledLock,
    onError,
  })

  enabledLock &&
    !isLoading &&
    lock &&
    setOrderLock({
      isLocked: lock.isLocked,
      creditType: lock.order?.data?.chosen_offer?.type ?? '',
    })

  return {
    data: ctxOrderLock ?? {
      isLocked: lock?.isLocked,
      creditType: lock?.order?.data?.chosen_offer?.type,
    },
    isLoading: enabledLock && isLoading,
    isError: isErrorLock,
  }
}
