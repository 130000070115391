import { useState } from 'react'

import { StepNavHistoryType } from '@/modules/enrollment/contexts/CampaignContext'
import { StepProps } from '@/modules/enrollment/components/Stepper/types'

export const useStepper = (steps: StepProps[]) => {
  const initialNavHistory: StepNavHistoryType[] = steps.map((_, index) => ({
    index,
    completed: false,
    visited: false,
  }))
  const [stepsNavHistory, setStepsNavHistory] = useState<StepNavHistoryType[]>(initialNavHistory)
  const [activeStep, setActiveStep] = useState(0)

  const reset = () => {
    setActiveStep(0)
    setStepsNavHistory(initialNavHistory)
  }

  const updateStepsNavHistory = (index: number) => {
    const updatedStepsNavHistory = stepsNavHistory.map(step => {
      if (step.index === index - 1) {
        return { ...step, completed: true, visited: true }
      }
      if (step.index === index) {
        return { ...step, visited: true }
      }
      return step
    })

    setStepsNavHistory(updatedStepsNavHistory)
  }

  const goToStep = (index: number) => {
    if (index < 0 || index >= steps.length) {
      return
    }
    updateStepsNavHistory(index)
    setActiveStep(index)
  }

  const handleStepClick = (index: number) => {
    if (!stepsNavHistory[index].visited) {
      return
    }
    goToStep(index)
  }

  const handleStepFinish = () => {
    const nextIndex = activeStep + 1
    goToStep(nextIndex)
  }

  return {
    reset,
    activeStep,
    ActiveComponent: steps[activeStep]?.component,
    isLastStep: activeStep === steps.length - 1,
    isFirstStep: activeStep === 0,
    stepsNavHistory,
    handleStepFinish,
    handleStepClick,
  }
}
