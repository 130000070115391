import styled from 'styled-components'
import { Box } from '@material-ui/core'

import { colors, spacing } from '@gravity/tokens'

export const InstallmentDrawerHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing[6]};
  gap: ${spacing[4]};

  border-bottom: 1px solid ${colors['colors-border-neutral-3']};
`

export const InstallmentDrawerContentWrapper = styled(Box)`
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`

export const InstallmentDrawerFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing[6]};
  gap: ${spacing[4]};

  border-top: 1px solid ${colors['colors-border-neutral-3']};
`
