import { formatCPF } from '@/shared/utils'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { colors } from '@gravity/tokens'

import { scoreColorMapping, scoreDescriptionMapping, scoreTextMapping } from '../../constants'
import * as Styled from './styles'
import { ScoreNumber } from '../../types'

interface Props {
  score: ScoreNumber
  taxId: string
}

export const ScoreCard = ({ score, taxId }: Props) => {
  const renderScoreBars = () => {
    const maxScore: ScoreNumber = 5
    const lines = new Array<JSX.Element>()
    const getLineColor = (index: number) =>
      index <= score ? scoreColorMapping[score] : colors['colors-background-neutral-3']
    for (let i = 1; i <= maxScore; i++) {
      lines.push(<Styled.ScoreBar color={getLineColor(i)} key={i} />)
    }
    return lines
  }
  return (
    <Styled.Wrapper>
      <div>CPF {formatCPF(taxId)}</div>
      <Styled.ScoreTitleWrapper>
        <Heading variant="heading-h3-medium">Score: {score}</Heading>
        <Heading variant="heading-h3-medium">{scoreTextMapping[score]}</Heading>
      </Styled.ScoreTitleWrapper>
      <Styled.ScoreBarsWrapper>{renderScoreBars()}</Styled.ScoreBarsWrapper>
      <Text>{scoreDescriptionMapping[score]}</Text>
    </Styled.Wrapper>
  )
}
