import styled from 'styled-components'

import { colors, spacing } from '@gravity/tokens'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${spacing[4]};
  margin-left: -${spacing[16]};
  padding-left: ${spacing[16]};
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: -152px;
  z-index: 20;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
  background-color: ${colors['colors-background-neutral-1']};
  border-bottom: 1px solid ${colors['colors-border-neutral-2']};
  height: 152px;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[8]};
`
