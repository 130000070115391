import { Box, Menu, MenuItem } from '@material-ui/core'
import { Button, Typography } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { KeyboardArrowUpRounded as KeyboardArrowUpRoundedIcon } from '@material-ui/icons'
import { useState } from 'react'

import theme from '@/shared/theme'

import { ModalConfirmationProps } from './types'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export function ActionMenu(props: ModalConfirmationProps) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NEVER_PAID,
        name: EventDispatcherEvents.NEVER_PAID_REVIEW_INSTALLMENTS_CLICK,
        action: 'click',
      })
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleConfirm = () => {
    props.onConfirm()
    handleClose()

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NEVER_PAID,
        name: EventDispatcherEvents.NEVER_PAID_CONFIRM_CLICK,
        action: 'click',
        customProperties: {
          $origin: 'Tela inicial - Botão "Manter todas as cobranças" do menu',
        },
      })
    }
  }

  const handleReview = () => {
    props.onReview()

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NEVER_PAID,
        name: EventDispatcherEvents.NEVER_PAID_DETAILS_ACCESS,
        action: 'click',
        customProperties: {
          $origin:
            'Tela inicial - Botão "Registrar recebimentos, revisar parcelas ou cancelar contrato" do menu',
        },
      })
    }
  }

  return (
    <>
      <Button variation="primary" disabled={props.disabled} onClick={handleClick}>
        <Box component="span" display="flex" gridGap={theme.primitiveTokens.spacing[3]}>
          Revisar cobranças do aluno <KeyboardArrowUpRoundedIcon />
        </Box>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 200,
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuItem onClick={handleReview}>
          <Box
            display="grid"
            gridGap={theme.primitiveTokens.spacing[1]}
            whiteSpace="normal"
            width={321}
          >
            <Typography variation="subtitleDesktopMedium">
              Registrar recebimentos, revisar parcelas ou cancelar contrato
            </Typography>
            <Typography variation="caption" color="secondary">
              Registre o recebimento de parcelas, mantenha a cobrança de parcelas específicas ou
              cancele contrato do aluno.
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={handleConfirm}>
          <Box
            display="grid"
            gridGap={theme.primitiveTokens.spacing[1]}
            whiteSpace="normal"
            width={321}
          >
            <Typography variation="subtitleDesktopMedium">Manter todas as cobranças</Typography>
            <Typography variation="caption" color="secondary">
              Confirme em lote todas as parcelas pendentes de todos os contratos do aluno.
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </>
  )
}
