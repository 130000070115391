import type { AxiosInstance } from 'axios'
import type { UpdateProfileRequest, UpdateProfileResponse } from './types'

export const userProfileService = (fetcher: AxiosInstance) => {
  return {
    updateProfile: async ({
      firstName,
      lastName,
    }: UpdateProfileRequest): Promise<UpdateProfileResponse> => {
      const { data: response } = await fetcher.patch<{ data: UpdateProfileResponse }>(
        'access-management/profile',
        { first_name: firstName, last_name: lastName }
      )

      return response.data
    },
  }
}
