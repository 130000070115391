import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks'
import { UseGuardianQueryProps, UseGuardianQueryResponse } from '../../services/types'
import { DEFAULT_QUERY_STALE_TIME, DEFAULT_QUERY_RETRY_COUNT } from '../constants'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'

export type useGuardianQueries = {
  guardians: UseGuardianQueryResponse
  isFetchGuardiansError: boolean
  isFetchGuardiansFetched: boolean
  isFetchGuardiansLoading: boolean
}

export const useGuardianQuery = (
  props: UseGuardianQueryProps,
  options?: UseQueryOptions<UseGuardianQueryResponse, XMLHttpRequest>
) => {
  const { api } = useApi()

  const queryKey = ['guardian-details', props]

  const {
    data: guardian,
    isLoading: isFetchGuardianLoading,
    isError: isFetchGuardianError,
    isFetched: isFetchGuardianFetched,
    refetch,
  } = useQuery<UseGuardianQueryResponse, XMLHttpRequest>(
    queryKey,
    () => {
      return api.guardianV2.fetchGuardian({
        guardianId: props.guardianId,
        schoolId: props.schoolId,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: Boolean(props.guardianId),
      retry: DEFAULT_QUERY_RETRY_COUNT,
      staleTime: DEFAULT_QUERY_STALE_TIME,
      ...options,
    }
  )

  return {
    guardian,
    isFetchGuardianLoading,
    isFetchGuardianError,
    isFetchGuardianFetched,
    refetchGuardian: refetch,
  }
}
