import type { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ProviderComposer } from '@/shared/core/providers'
import { QueryProvider } from '@/shared/contexts/Query'
import { UnleashProvider } from './Unleash'
import { AuthProvider } from '@/shared/contexts/Auth'
import { ReactQueryProvider } from './ReactQuery'
import { EventDispatcherProvider } from './EventDispatcher'
import { ThemeProvider } from './ThemeProvider'

type GlobalProvidersProps = {
  children: ReactNode
}

/**
 * Application global providers
 */
export const GlobalProviders = ({ children }: GlobalProvidersProps) => {
  return (
    <ProviderComposer
      providers={[
        BrowserRouter,
        QueryProvider,
        UnleashProvider,
        AuthProvider,
        ReactQueryProvider,
        EventDispatcherProvider,
        ThemeProvider,
      ]}
    >
      {children}
    </ProviderComposer>
  )
}
