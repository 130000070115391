import styled from 'styled-components'
import Container from '@material-ui/core/Container'

export const StyledMain = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledContainer = styled(Container)`
  padding-left: 24px;
  padding-right: 24px;
  max-width: 620px;
`
