import { useContext, useEffect } from 'react'
import { PosContext } from 'src/escolas/contexts/posContext'

const useSelectPos = (schoolId?: uuid) => {
  const { getList, isLoading, listPos, isError } = useContext(PosContext)

  useEffect(() => {
    if (!isLoading && schoolId) {
      getList(schoolId)
    }
  }, [schoolId])

  return {
    isLoading,
    listPos,
    isError,
  }
}

export default useSelectPos
