export const ISAAC_PAY_DRAWER_ITEMS = {
  TOTAL_EXTERNAL: {
    label: 'Boletos, Pix ou dinheiro',
  },
  TOTAL_PAYOUT: {
    label: 'Outros métodos de pagamento',
  },
  PAYOUT_FEE: {
    label: 'Taxa isaac já retida',
    description: 'Valor referente à prestação de serviços isaac',
  },
  TOTAL: {
    label: 'Valor total',
  },
  EXTERNAL_FEE: {
    label: 'Taxa isaac a ser cobrada',
  },
}
