import styled from 'styled-components'
import { Button } from '@gravity/button'
import { spacing } from '@gravity/tokens'

export const FormStyled = styled.div`
  margin-top: ${spacing[6]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]};
  height: 500px;
`

export const BtnSimulation = styled(Button)`
  margin-top: ${spacing[6]};
`
