import { Box } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'

import { BackgroundImage } from './styles'

import Background from '@/shared/assets/graphism.svg'

/**
 * Empty state component for payout report dashboard
 */
export const EmptyState = () => {
  return (
    <Box flex="1" display="flex" justifyContent="center" data-testid="empty-state">
      <Box mt="16rem" maxWidth={700}>
        <Typography variation="headlineDesktopXsmall" style={{ textAlign: 'center' }}>
          Você ainda não possui nenhum repasse. Em breve você poderá ver todos os detalhes dos
          valores a serem transferidos.
        </Typography>
      </Box>

      <BackgroundImage src={Background} alt="Background image" width={379} height={513} />
    </Box>
  )
}
