import Icon from '@mdi/react'
import { Text } from '@gravity/text'
import { mdiHistory, mdiAccountMultipleOutline } from '@mdi/js'

import { getHighlightWords } from '@/modules/school-home/pages/SchoolHome/utils/getHighlightWords'

import type { StorageItemType } from '@/modules/school-home/types/StorageItem'
import { RemoveButton } from '../RemoveButton'

import { Container, Link } from './styles'

interface ItemGuardianSearchProps {
  id: string
  inputSearch: string
  name: string
  onClick: (data: StorageItemType) => void
  onRemove?: (id: string) => void
  students: string[]
  type: 'history' | 'search'
  url: string
}

const MAX_STUDENTS_TO_DISPLAY = 3

export const ItemGuardianSearch = ({
  name,
  students,
  type,
  onRemove,
  onClick,
  id,
  url,
  inputSearch,
}: ItemGuardianSearchProps) => {
  return (
    <Container
      data-testid="item-guardian-search"
      className={type === 'history' ? 'single-row' : 'double-row'}
    >
      <Link
        data-testid="link-guardian"
        to={url + `/responsaveis/${id}`}
        onClick={() =>
          onClick({
            id,
            name,
            students,
            type: 'guardians',
          })
        }
      >
        <Icon
          className="icon-item"
          path={type === 'history' ? mdiHistory : mdiAccountMultipleOutline}
          size={1}
        />
        <div className="guardian-item">
          <Text
            className="guardian-name"
            variant="subtitle-regular"
            data-testid="mark-guardian-name"
            dangerouslySetInnerHTML={{ __html: getHighlightWords(inputSearch, name) }}
          />

          <Text variant="body-2-regular" className="second-text">
            Responsável
          </Text>
        </div>
        {students?.length > 0 && type !== 'history' && (
          <div className="students-items">
            <Text data-testid="students-list" variant="body-2-regular">
              {students.slice(0, MAX_STUDENTS_TO_DISPLAY).join(', ')}
              {students.length > MAX_STUDENTS_TO_DISPLAY &&
                ` +${students.length - MAX_STUDENTS_TO_DISPLAY}`}
            </Text>
            <Text variant="body-2-regular" className="second-text">
              Alunos do responsável
            </Text>
          </div>
        )}
        {type === 'history' && <RemoveButton onClick={onRemove} id={id} />}
      </Link>
    </Container>
  )
}
