import {
  Container,
  GuardianNameContainer,
  GuardianNameContent,
  ContainerCardInfo,
  BoxCardInfo,
  TitleText,
  InfoText,
  WrapText,
  LinkText,
} from './styles'

import { CardInfoProps } from './types'
import { Tooltip } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { DebtStatus } from '../DebtStatus'
import { Text } from '@gravity/text'
import { formatPhoneNumber, formatCPF } from '@/shared/utils'
import { Skeleton } from '@gravity/skeleton'

const CardInfoPlaceholder = () => {
  return (
    <Container>
      <GuardianNameContent>
        <InfoText variant="body-2-regular">Responsável financeiro</InfoText>
        <GuardianNameContainer>
          <Skeleton width={350} height={30} />
          <Skeleton radius={6} width={84} />
        </GuardianNameContainer>
      </GuardianNameContent>
      <ContainerCardInfo>
        <BoxCardInfo>
          <InfoText variant="body-2-regular">CPF</InfoText>
          <Skeleton width={200} />
        </BoxCardInfo>
        <BoxCardInfo>
          <InfoText variant="body-2-regular">Telefone</InfoText>
          <Skeleton width={200} />
        </BoxCardInfo>
        <BoxCardInfo>
          <InfoText variant="body-2-regular">Email</InfoText>
          <Skeleton width={250} />
        </BoxCardInfo>
      </ContainerCardInfo>
    </Container>
  )
}

const InsertLink: React.FC<{ onClick: () => void; type: 'email' | 'telefone' }> = ({
  type,
  onClick,
}) => (
  <LinkText onClick={onClick}>
    {type === 'email' ? 'Insira um endereço de e-mail' : 'Insira um número de telefone'}
  </LinkText>
)

export const CardInfo: React.FC<CardInfoProps> = ({
  guardian: { cpf, email, guardianName, phone, debtStatus },
  isLoading,
  openEditRegistrationDrawer,
}) => {
  const [showGuardianNameTooltip, setShowGuardianNameTooltip] = useState<boolean>(false)

  const isOverflowing = (textElement: HTMLElement) => {
    if (!textElement) return false
    return textElement.scrollWidth > textElement.clientWidth
  }

  useEffect(() => {
    const guardianNameElement = document.getElementById('guardianName')
    setShowGuardianNameTooltip(isOverflowing(guardianNameElement))
  }, [guardianName])

  if (isLoading) {
    return <CardInfoPlaceholder />
  }

  return (
    <Container>
      <GuardianNameContent>
        <InfoText variant="body-2-regular">Responsável financeiro</InfoText>
        <GuardianNameContainer>
          <Tooltip
            title={guardianName}
            placement="bottom"
            disableHoverListener={!showGuardianNameTooltip}
          >
            <TitleText variant="heading-h2-medium">{guardianName}</TitleText>
          </Tooltip>
          <DebtStatus status={debtStatus} />
        </GuardianNameContainer>
      </GuardianNameContent>
      <ContainerCardInfo>
        <BoxCardInfo>
          <InfoText variant="body-2-regular">CPF</InfoText>
          <Text variant="subtitle-regular">{formatCPF(cpf)}</Text>
        </BoxCardInfo>
        <BoxCardInfo>
          <InfoText variant="body-2-regular">Telefone</InfoText>
          {phone ? (
            <Text variant="subtitle-regular">{formatPhoneNumber(phone)}</Text>
          ) : (
            <InsertLink type="telefone" onClick={openEditRegistrationDrawer} />
          )}
        </BoxCardInfo>
        <BoxCardInfo>
          <InfoText variant="body-2-regular">Email</InfoText>
          {email ? (
            <WrapText variant="subtitle-regular">{email}</WrapText>
          ) : (
            <InsertLink type="email" onClick={openEditRegistrationDrawer} />
          )}
        </BoxCardInfo>
      </ContainerCardInfo>
    </Container>
  )
}
