import { Button } from '@gravity/button'
import { Text } from '@gravity/text'

import { Container } from './styles'

type NotificationDialogErrorStateProps = {
  onRetry: () => void
}

export const NotificationDialogErrorState = ({ onRetry }: NotificationDialogErrorStateProps) => {
  return (
    <Container>
      <Text variant="subtitle-medium">Opa, não foi possível exibir avisos agora</Text>
      <Text variant="body-1-regular">
        Alguma coisa aconteceu por aqui, mas podemos tentar de novo! Vamos lá?
      </Text>

      <div className="action-container">
        <Button onClick={onRetry}>Tentar ler de novo</Button>
      </div>
    </Container>
  )
}
