import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import mainTheme from '@/shared/theme'
import { withStyles } from '@material-ui/core'

export const Accordion = withStyles({
  root: {
    borderBottom: `1px solid ${mainTheme.primitiveTokens.colors.gray[5]}`,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
      backgroundColor: mainTheme.primitiveTokens.colors.gray[3],
    },
  },
  expanded: {},
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    padding: 0,
    margin: `0 ${mainTheme.primitiveTokens.spacing[6]} 0 0`,
  },
  content: {
    margin: `${mainTheme.primitiveTokens.spacing[6]} 0 ${mainTheme.primitiveTokens.spacing[6]} ${mainTheme.primitiveTokens.spacing[6]}`,
    padding: 0,
    '&$expanded': {
      margin: `${mainTheme.primitiveTokens.spacing[6]} 0 ${mainTheme.primitiveTokens.spacing[6]} ${mainTheme.primitiveTokens.spacing[6]}`,
    },
  },
  expanded: {},
})(MuiAccordionSummary)

export const AccordionDetails = withStyles(() => ({
  root: {
    margin: `0 ${mainTheme.primitiveTokens.spacing[6]} ${mainTheme.primitiveTokens.spacing[6]} ${mainTheme.primitiveTokens.spacing[6]}`,
    padding: 0,
  },
}))(MuiAccordionDetails)
