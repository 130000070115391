import { formatDate, isDayBeforeToday, isDayTodayOrAfter } from '@/shared/utils'
import { includes } from 'ramda'
import { useMemo } from 'react'
import { Installment } from '../../../types'
import { InstallmentStatus } from '../../../constants'

export const getTotalAmount = (installments: Installment[]): number =>
  useMemo(() => installments?.reduce((acc, curr) => acc + curr.amount, 0), [installments])

export const getTotalOverdueAmount = (installments: Installment[]): number =>
  useMemo(
    () =>
      installments
        ?.filter(
          i =>
            includes(i.status, [InstallmentStatus.OVERDUE, InstallmentStatus.OPEN]) &&
            isDayBeforeToday(i.due_date)
        )
        .reduce((acc, curr) => acc + curr.amount, 0),
    [installments]
  )

export const getTotalUpToDateAmount = (installments: Installment[]): number =>
  useMemo(
    () =>
      installments
        ?.filter(
          i =>
            includes(i.status, [InstallmentStatus.DUE_TODAY, InstallmentStatus.OPEN]) &&
            isDayTodayOrAfter(formatDate(i.due_date, 'YYYY-MM-DD'))
        )
        .reduce((acc, curr) => acc + curr.amount, 0),
    [installments]
  )
