import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { propEq, assocPath } from 'ramda'
import { Receivable, ReceivableStatuses } from 'src/shared/interfaces'
import DiscountContent from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/AddDiscountContent'
import ManualLiquidationContent from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/ManualLiquidationContent'
import ConfirmManualLiquidationContent from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/ConfirmManualLiquidationContent'
import RenegotiationContent from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/RenegotiationContent'
import { DrawerState } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/constants'
import { useContract } from 'src/escolas/hooks'
import { DefaultContent } from './DefaultContent'
import ContractEditEnrollmentDueDayDrawer from '@/escolas/components/contract/ContractEditDrawer/ContractEditEnrollmentDueDayDrawer'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import { useJWT } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export type DrawerContentProps = {
  callbackCheckout?: Dispatch<SetStateAction<boolean>>
  defaultDrawerState: DrawerState
  drawerState: DrawerState
  isBrokenPromise?: boolean
  isCheckout?: boolean
  onClose: () => void
  onSuccess?: () => void
  removeIsaacOption?: boolean
  resetManualLiquidationForm?: boolean
  selectedInstallmentHasError: boolean
  selectedReceivableId: uuid
  setDrawerState: Dispatch<SetStateAction<DrawerState>>
  setIsFailureFeedbackOpen?: (value: boolean) => void
  setSelectedReceivableId: Dispatch<SetStateAction<uuid>>
  setShowInvoicesNotGeneratedDialog: (value: boolean) => void
  urn?: string
}

const DrawerContent: FC<DrawerContentProps> = ({
  onClose,
  onSuccess,
  defaultDrawerState = DrawerState.DEFAULT,
  drawerState,
  selectedReceivableId,
  setDrawerState,
  setSelectedReceivableId,
  removeIsaacOption,
  isCheckout,
  callbackCheckout,
  resetManualLiquidationForm,
  setIsFailureFeedbackOpen,
  selectedInstallmentHasError,
  setShowInvoicesNotGeneratedDialog,
  urn,
  isBrokenPromise,
}) => {
  const { contract, setContract } = useContract()

  const installment = contract?.installments?.find(installment =>
    installment?.receivables?.some(propEq('id', selectedReceivableId))
  )
  const receivables = installment?.receivables as Receivable[]

  const replaceReceivables = (replacingReceivables: Receivable[]) => {
    const installmentIndex = contract?.installments?.findIndex(item => item.id === installment?.id)
    if (installmentIndex) {
      setContract(
        assocPath(['installments', installmentIndex, 'receivables'], replacingReceivables, contract)
      )
    }
    if (isCheckout && callbackCheckout) {
      callbackCheckout(true)
    }
  }

  const goBackToDefaultContent = () => setDrawerState(defaultDrawerState)

  const goBackToConfirmManualLiquidation = () =>
    setDrawerState(DrawerState.CONFIRM_MANUAL_LIQUIDATION)

  const [manualLiquidationInfo, setManualLiquidationInfo] = useState({
    submitHandler: null,
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { isBackofficeUser, getUserRolesBySchool } = useJWT()
  const { school } = useSelectedSchool()

  const userRoles = getUserRolesBySchool(school?.id ?? '')

  const goToConfirmManualLiquidationContent = (submitHandler: any) => {
    goBackToConfirmManualLiquidation()
    setManualLiquidationInfo({ submitHandler })
  }

  useEffect(() => {
    goBackToDefaultContent()
  }, [selectedReceivableId])

  const isEnabledBasicOperationAccessLevel = useUnleashFlag(
    UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL
  )

  const isManualLiquidationCreateBlockedForNoAccessRole =
    isEnabledBasicOperationAccessLevel && !userRoles.has('criar_baixa_manual') && !isBackofficeUser

  const removeOptionManualLiquidation = isManualLiquidationCreateBlockedForNoAccessRole

  const drawerContentMap: Partial<Record<DrawerState, JSX.Element>> = {
    [DrawerState.DEFAULT]: (
      <DefaultContent
        receivables={receivables?.filter(rec => rec.status !== ReceivableStatuses.CANCELED)}
        selectedReceivableId={selectedReceivableId}
        setDrawerState={setDrawerState}
        removeOptionManualLiquidation={removeOptionManualLiquidation}
        selectedInstallmentHasError={selectedInstallmentHasError}
        setShowInvoicesNotGeneratedDialog={setShowInvoicesNotGeneratedDialog}
        urn={urn}
        isBrokenPromise={isBrokenPromise}
        replaceReceivables={replaceReceivables}
      />
    ),
    [DrawerState.MANUAL_LIQUIDATION]: (
      <ManualLiquidationContent
        onSuccess={onSuccess}
        onFinish={goBackToDefaultContent}
        receivables={receivables}
        selectedReceivableId={selectedReceivableId}
        successCallback={replaceReceivables}
        setSelectedReceivableId={setSelectedReceivableId}
        removeIsaacOption={removeIsaacOption}
        goToConfirmManualLiquidationContent={goToConfirmManualLiquidationContent}
        setIsSubmitting={setIsLoading}
        resetManualLiquidationForm={!!resetManualLiquidationForm}
        setIsFailureFeedbackOpen={setIsFailureFeedbackOpen}
      />
    ),
    [DrawerState.CONFIRM_MANUAL_LIQUIDATION]: (
      <ConfirmManualLiquidationContent
        submitHandler={(manualLiquidationInfo.submitHandler as unknown) as () => Promise<void>}
        isLoading={isLoading}
      />
    ),
    [DrawerState.RENEGOTIATION]: (
      <RenegotiationContent
        onFinish={goBackToDefaultContent}
        receivables={receivables}
        selectedReceivableId={selectedReceivableId}
        successCallback={replaceReceivables}
        setSelectedReceivableId={setSelectedReceivableId}
      />
    ),
    [DrawerState.ADD_DISCOUNTS]: (
      <DiscountContent
        onClose={onClose}
        onFinish={goBackToDefaultContent}
        receivables={receivables}
        selectedReceivableId={selectedReceivableId}
        successCallback={replaceReceivables}
        setSelectedReceivableId={setSelectedReceivableId}
      />
    ),
    [DrawerState.EDIT_ENROLLMENT_DUEDATE]: (
      <ContractEditEnrollmentDueDayDrawer
        selectedReceivableId={selectedReceivableId}
        receivables={receivables}
        callbackCheckout={callbackCheckout}
        goBackToDefaultContent={goBackToDefaultContent}
      />
    ),
  }

  return drawerContentMap[drawerState] ?? <></>
}

export default DrawerContent
