import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useApi } from 'src/shared/hooks/useApi'
import { Address, GuardianStudentsType } from 'src/shared/interfaces'
import { useNavigation } from 'src/escolas/hooks'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import { useGuardianStudents } from '@/escolas/hooks/useGuardianStudents'
import { defaultAddress } from '@/escolas/components/contract/create/constants'
import { useSnackbar } from '@/shared/hooks'
import { Guardian } from '../../types'
import { EditGuardianFormType } from './types'

const useHelperEditRegistration = (
  guardian: Guardian,
  studentsId: string[],
  handleClose: () => void
) => {
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [isZipValid, setIsZipValid] = useState<boolean>(true)
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState<boolean>(false)

  const isNewEditRfNameEnabled = useUnleashFlag(UnleashFlags.GC_973_B2BCORE_122_EDIT_RF)

  const { api } = useApi()
  const { schoolId } = useNavigation()

  const {
    updateGuardianStudents: updateGuardianStudentsMutation,
    guardianStudents,
    isStudentsFetched,
  } = useGuardianStudents(guardian.id, studentsId)

  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()

  const form = useForm<EditGuardianFormType>({
    mode: 'onChange',
  })

  const { trigger, setValue } = form

  const setFormAddressValues = (address: Omit<Address, 'created_at' | 'updated_at' | 'id'>) => {
    const { zip, street, number, city, state_code, additional_information } = address

    setValue('address.zip', zip)
    setValue('address.street', street)
    setValue('address.number', number)
    setValue('address.city', city)
    setValue('address.state_code', state_code)
    setValue('address.additional_information', additional_information)

    trigger()
  }

  const setFormStudentValues = (studentsFromQuery: GuardianStudentsType['students']) => {
    const students = studentsFromQuery?.map(student => ({
      id: student.student_id,
      name: student.student_data.name,
    }))

    setValue('students', students)

    trigger()
  }

  const setFormValues = () => {
    const { email, phone_number, name, tax_id } = guardianStudents?.guardian
    setValue('id', guardianStudents?.guardian_id)
    setValue('email', email)
    isNewEditRfNameEnabled && setValue('name', name)
    setValue('phone_number', phone_number)
    setValue('tax_id', tax_id)

    setFormAddressValues(guardianStudents?.guardian?.address)
    setFormStudentValues(guardianStudents?.students)
    setIsFirstRender(false)
  }

  const onZipFieldFilled = async (zip: cep) => {
    await api.guardians
      .getAddressFromZip(zip)
      .then(validAddress => {
        setIsZipValid(true)
        setFormAddressValues({
          ...validAddress,
        })
      })
      .catch(() => {
        setIsZipValid(false)
        setFormAddressValues({ ...defaultAddress, zip })
      })
  }

  const handleMenuItemClick = () => {
    setFormValues()
  }

  const handleCloseFeedbackDialog = () => {
    setIsFeedbackDialogOpen(false)
  }

  const onSubmit = async (form: EditGuardianFormType) => {
    const updatedGuardianStudents = await updateGuardianStudentsMutation({
      newGuardianStudents: { ...form },
      schoolID: schoolId,
    })

    if (!updatedGuardianStudents) return

    if (isNewEditRfNameEnabled) {
      setSnackbarMessage('Cadastro editado com sucesso.')
      setSnackbarIsOpen(true)
      return
    }

    setSnackbarMessage('Cadastro editado com sucesso.')
    setSnackbarIsOpen(true)
  }

  const submitHandler = async (form: EditGuardianFormType) => {
    try {
      await onSubmit(form)

      handleClose()
      window.location.reload()
    } catch (errors) {
      setIsFeedbackDialogOpen(true)
    }
  }

  return {
    form,
    isFirstRender,
    isZipValid,
    handleMenuItemClick,
    onZipFieldFilled,
    submitHandler,
    isFeedbackDialogOpen,
    handleCloseFeedbackDialog,
    isStudentsFetched,
  }
}

export default useHelperEditRegistration
