import { Box } from '@material-ui/core'
import { Refresh as IconRefresh } from '@material-ui/icons'

import { Button, Typography } from '@olaisaac/design-system'

export type RequestErrorProps = {
  onRefetch: () => void
}

export function RequestError({ onRefetch }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyItems="center"
      alignItems="center"
      paddingTop="50%"
      marginTop="-12rem"
    >
      <Typography variation="headlineDesktopSmall">Não foi possível carregar sua página</Typography>

      <Typography variation="bodyLarge">
        Ocorreu um erro inesperado. <br /> Tente novamente mais tarde.
      </Typography>

      <Box marginTop="2rem">
        <Button onClick={onRefetch}>
          <IconRefresh style={{ marginRight: '0.75rem' }} />
          Recarregar
        </Button>
      </Box>
    </Box>
  )
}
