import { Contract, InstallmentStatuses, ReceivableStatuses } from '@/shared/interfaces'

export const getInvoicesIds = (contract: Contract): uuid[] => {
  const installments = contract.installments.filter(i => i?.status !== InstallmentStatuses.CANCELED)
  const receivables = installments
    ?.flatMap(i => i.receivables)
    ?.filter(r => r.status === ReceivableStatuses.OPEN)

  const invoices = receivables
    ?.flatMap(r => ({ invoice_id: r.invoice_id, installment_id: r.installment_id }))
    ?.filter(i => Boolean(i.invoice_id))

  return invoices?.map(i => i.invoice_id)
}
