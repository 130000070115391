import { Typography } from '@olaisaac/design-system'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'

const StyledTypography = styled(Typography).attrs({
  variation: 'primary',
})``

export const SessionTitleTypography = styled(StyledTypography)`
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  /* Core/Text/text-color-accent ==> #3D4ED7 */
  color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
  margin: 0;
`

const ExpandIcon = styled(ExpandMoreIcon).attrs({
  label: 'accordion-expand-icon',
})``

export const AccordionSummaryBox = styled(AccordionSummary).attrs({
  expandIcon: <ExpandIcon />,
  pt: 24,
  pl: 24,
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
`

export const StepBox = styled(Box).attrs({})`
  /* Core/Background/background-light-info ==> #ECEDFB */
  background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[10]};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  margin-right: 12px;
`
