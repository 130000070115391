import { FC } from 'react'
import styled from 'styled-components'
import { Discount, DiscountType } from 'src/shared/interfaces'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { formatCentsToReal } from 'src/shared/utils'
import Tooltip from '@material-ui/core/Tooltip'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import { TooltipButton } from '@olaisaac/design-system'

const StyledBox = styled(Box)`
  width: 15px;
  height: 15px;
  border-left: 2px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  border-bottom: 2px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  border-bottom-left-radius: 4px;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`
const StyledTooltip = styled(Tooltip)`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`

const StyledTypography = styled(Typography)`
  overflow-wrap: break-word;
`

export type FinancialSummaryDiscountProps = {
  discount: Discount
  discountType: DiscountType
  testId?: string
}

export const discountsInfoMap = {
  [DiscountType.PERPETUAL]: {
    name: 'Permanente',
    description: 'Desconto independe do vencimento',
  },
  [DiscountType.DUE_PAYMENT]: {
    name: 'Pontualidade',
    description: 'Desconto válido até o vencimento',
  },
  [DiscountType.EARLY_PAYMENT]: {
    name: 'Antecipação',
    description: 'Desconto para pagamento adiantado',
  },
  [DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION]: {
    name: 'Baixa manual',
    description: 'Desconto aplicado durante a baixa manual',
  },
}

const FinancialSummaryDiscount: FC<FinancialSummaryDiscountProps> = ({
  discountType,
  discount,
  testId,
}) => (
  <Box pb={1} display="flex" justifyContent="space-between" data-testid={testId}>
    <Box display="flex" justifyContent="space-between">
      <StyledBox />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <StyledTypography variant="body2" color="textSecondary">
          {discount?.description || discountsInfoMap[discountType]?.description}
        </StyledTypography>
        <StyledTooltip title={discountsInfoMap[discountType]?.name}>
          <TooltipButton>
            <InfoRoundedIcon fontSize="small" />
          </TooltipButton>
        </StyledTooltip>
      </Box>
    </Box>
    <Typography variant="body2" color="textSecondary">
      -{formatCentsToReal(discount.amount)}
    </Typography>
  </Box>
)

export default FinancialSummaryDiscount
