import { useEffect, useMemo, useState } from 'react'
import { useApi } from 'src/shared/hooks'
import {
  EducationalStage,
  GetProductClassificationsData,
} from 'src/shared/interfaces/productClassification'

type Params = {
  onError?: (error: Error) => void
  onSuccess?: (data: GetProductClassificationsData) => void
  stageId?: EducationalStage['id']
}

type Return = {
  data: GetProductClassificationsData
  filteredEducationalSubstages: GetProductClassificationsData['educational_substages']
  loading: boolean
}

export const useProductClassifications = ({ stageId, onError, onSuccess }: Params = {}): Return => {
  const { api } = useApi()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<GetProductClassificationsData>()

  const filteredEducationalSubstages = useMemo(
    () =>
      stageId
        ? data?.educational_substages.filter(
            substage => substage.educational_stage_id === stageId
          ) ?? []
        : [],

    [stageId, data]
  )

  useEffect(() => {
    const handler = async () => {
      try {
        setLoading(true)
        const response = await api.products.getClassifications()
        setData(response.data)
        onSuccess?.(response.data)
      } catch (e) {
        onError?.(e as Error)
      } finally {
        setLoading(false)
      }
    }

    handler()
  }, [api, onError, onSuccess])

  return {
    data,
    filteredEducationalSubstages,
    loading,
  }
}
