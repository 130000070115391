import { DatePickerV2, TileDisabledFunctionArgs } from '@olaisaac/design-system'

import type { DetailType } from '../..'

import { Popover } from './styles'

type PickerPopOverProps = {
  anchorEl: Element | ((element: Element) => Element)
  isOpen: boolean
  maxDate?: Date
  maxDetail: DetailType
  minDate?: Date
  minDetail?: DetailType
  onChange: (date: Date) => void
  onClose: () => void
  tileDisabled?: (data: TileDisabledFunctionArgs) => boolean
  value?: Date
}

/**
 * Popover that wraps the date picker component
 *
 * @param props
 * @param props.anchorEl Anchor element ref
 * @param props.isOpen Defines if popover is open
 * @param props.maxDate Defines the max date
 * @param props.minDate Defines the min date
 * @param props.maxDetail Defines the maximum date detail
 * @param props.minDetail Defines the minimum date detail
 * @param props.value Input value
 * @param props.onChange On change callback function
 * @param props.onClose On close callback function
 * @param props.tileDisabled Function that allows disable specific dates
 */
export const PickerPopOver = ({
  anchorEl,
  value,
  isOpen,
  onClose,
  onChange,
  ...rest
}: PickerPopOverProps) => {
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      elevation={0}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <DatePickerV2 inputMode="single" value={value} onChange={onChange} {...rest} />
    </Popover>
  )
}
