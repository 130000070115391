import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
`

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  padding-bottom: ${props => props.theme.gravity.spacing[10]};
  position: relative;
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${props => props.theme.gravity.spacing[5]} 0 0;
  margin-bottom: ${props => props.theme.gravity.spacing[12]};
`

export const Title = styled(Typography).attrs({ variation: 'headlineDesktopMedium' })`
  font-size: 32px;
  font-weight: 700;
  width: 100%;
  z-index: 1;
  margin-top: ${props => props.theme.gravity.spacing[12]};
`

export const SubTitle = styled(Typography).attrs({ variation: 'headlineDesktopMedium' })`
  margin-top: ${props => props.theme.gravity.spacing[4]};
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  z-index: 1;
`
