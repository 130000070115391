import { Skeleton } from '@gravity/skeleton'
import { Container } from './styles'

export const InputLoading = () => {
  return (
    <Container>
      <Skeleton width={160} height={24} radius={2} />
      <Skeleton fullWidth height={40} radius={2} />
    </Container>
  )
}
