import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@/shared/hooks/useQuery'

import {
  PaginationHookType,
  PaginationQueryParamNames,
  PaginationQueryParamsNameEnum,
  PaginationType,
} from './types'

/**
 * Custom hook to handle pagination query params
 */
export const usePagination = (initialPage = 1, initialItemsPerPage = 10): PaginationHookType => {
  const { query, setOnQueryParam, setMultipleQueryParams } = useQuery()

  const getPaginationFromURL = () => {
    const getUrlValue = (paramKey: string, defaultValue: number) => {
      const paramValue = query.get(paramKey)

      return paramValue ? Number(paramValue) : defaultValue
    }

    const data = {
      page: getUrlValue('page', initialPage),
      itemsPerPage: getUrlValue('items_per_page', initialItemsPerPage),
    }

    return data
  }

  const [pagination, setPagination] = useState<PaginationType>(() => getPaginationFromURL())

  useEffect(() => {
    const page = query.get('page')
    const itemsPerPage = query.get('items_per_page')

    if (!page || !itemsPerPage) {
      setMultipleQueryParams(
        [
          { name: 'page', value: page ?? String(initialPage) },
          { name: 'items_per_page', value: itemsPerPage ?? String(initialItemsPerPage) },
        ],
        'replace'
      )
    }
  }, [])

  useEffect(() => {
    const page = Number(query.get('page'))
    const itemsPerPage = Number(query.get('items_per_page'))

    const hasChanges = page !== pagination.page || itemsPerPage !== pagination.itemsPerPage

    hasChanges && setPagination(getPaginationFromURL())
  }, [query])

  const updatePagination = useCallback(
    ({ page, itemsPerPage }: PaginationType, replaceRoute = false) => {
      const data: Array<{ name: PaginationQueryParamNames; value: string }> = [
        { name: 'page', value: String(page) },
        { name: 'items_per_page', value: String(itemsPerPage) },
      ]

      setMultipleQueryParams(data, replaceRoute ? 'replace' : 'push')
    },
    [setMultipleQueryParams]
  )

  const updatePaginationValue = useCallback(
    (key: keyof PaginationType, value: number, replaceRoute = false) => {
      setOnQueryParam(
        String(value),
        PaginationQueryParamsNameEnum[key],
        replaceRoute ? 'replace' : 'push'
      )
    },
    [setOnQueryParam]
  )

  const paginationHookValue = useMemo(
    () => ({
      pagination,
      updatePagination,
      updatePaginationValue,
    }),
    [pagination, updatePagination, updatePaginationValue]
  )

  return paginationHookValue
}
