import { Box, Grid } from '@material-ui/core'

import { formatCentsToReal } from 'src/shared/utils'

import PeopleIcon from '@material-ui/icons/People'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import PaymentIcon from '@material-ui/icons/Payment'

import { Typography } from '@olaisaac/design-system'

import { Paragraph, Title } from '../../styles'

export type NumbersReviewedCardProps = {
  totalAmount: number
  totalInstallments: number
  totalStudents: number
}

export function NumbersReviewedCard({
  totalAmount,
  totalInstallments,
  totalStudents,
}: NumbersReviewedCardProps) {
  const DATA = [
    {
      icon: <PeopleIcon height="2rem" style={{ color: 'rgba(0, 0, 0, 0.6)' }} />,
      title: 'Alunos inadimplentes',
      value: totalStudents,
      type: 'number',
    },
    {
      icon: <PaymentIcon height="2rem" style={{ color: 'rgba(0, 0, 0, 0.6)' }} />,
      title: 'Parcelas com pendência',
      value: totalInstallments,
      type: 'number',
    },
    {
      icon: <MonetizationOnIcon height="2rem" style={{ color: 'rgba(0, 0, 0, 0.6)' }} />,
      title: 'Valor total',
      value: totalAmount,
      type: 'price',
    },
  ]

  return (
    <Box marginTop="2rem">
      <Box marginBottom="2rem">
        <Title>Seus números a serem revisados</Title>

        <Paragraph>
          Alunos com cobranças em aberto por mais de 90 dias precisam ser revisados pela escola. Ao
          manter sua base atualizada, você evita impactos no seu repasse, garante que o isaac está
          cobrando os responsáveis corretamente e facilita seu processo de rematrículas!
        </Paragraph>
      </Box>

      <Grid container spacing={3}>
        {DATA.map(({ icon, title, value, type }) => (
          <Grid key={title} item sm={4}>
            <Box
              display="flex"
              alignItems="center"
              padding="0.5rem 2rem"
              border="1px solid #DADADA"
              borderRadius="0.5rem"
              color="#414141"
            >
              <Box display="flex" alignItems="center" width="2rem" height="2rem">
                {icon}
              </Box>

              <Box>
                <Typography variation="bodySmall" withoutMargin>
                  {title}
                </Typography>
                <Typography variation="bodyLarge" withoutMargin>
                  {type === 'number' ? value : formatCentsToReal(value)}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
