import {
  AllListGuardiansFiltersOptions,
  ListGuardiansFilterFormType,
  ListGuardiansFilterType,
} from '../components/ListGuardiansFilter/types'

/**
 * Transform a group of filters to be used in ActiveFilters component.
 *
 * @param filters Filters.
 * @param allFiltersOptions Options of filters.
 *
 * @returns Filters in the format { label, value }.
 */
export const transformFilters = (
  filters: Partial<ListGuardiansFilterType>,
  allFiltersOptions: AllListGuardiansFiltersOptions
): ListGuardiansFilterFormType => {
  const transformedFilters: ListGuardiansFilterFormType = {
    debt_status: [],
  }

  if (filters.debt_status) {
    transformedFilters.debt_status = filters.debt_status.map(value => {
      const option = allFiltersOptions.debtStatus.find(option => option.value === value)
      return option || { label: '', value }
    })
  }

  return transformedFilters
}
