import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import {
  UseGuardianContractsQueryProps,
  UseGuardianContractsQueryResponse,
} from '../../services/types'
import { useApi } from '@/shared/hooks'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'

import { guardianService } from '../../services'

export const useGuardianContractsQuery = (
  props: UseGuardianContractsQueryProps,
  options?: UseQueryOptions<UseGuardianContractsQueryResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApi()
  const service = guardianService(apiClient.privateApiV2)

  const queryKey = ['guardian-details-contracts', props.guardianId, props.filter]

  const {
    data: contracts,
    refetch: refetchGuardianContracts,
    isLoading: isFetchGuardianContractsLoading,
    isError: isFetchGuardianContractsError,
    isFetched: isFetchGuardianContractsFetched,
    isRefetching: isRefetchingGuardianContracts,
  } = useQuery<UseGuardianContractsQueryResponse, XMLHttpRequest>(
    queryKey,
    () => {
      return service.fetchGuardianContracts({
        guardianId: props.guardianId,
        filter: props.filter,
        schoolId: props.schoolId,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      retry: false,
      staleTime: 1000 * 60 * 5,
      ...options,
    }
  )

  return {
    contracts,
    refetchGuardianContracts,
    isFetchGuardianContractsLoading,
    isFetchGuardianContractsError,
    isFetchGuardianContractsFetched,
    isRefetchingGuardianContracts,
  }
}
