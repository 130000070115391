import { Box } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import { FC } from 'react'
import dayjs from 'dayjs'

import { formatCentsToReal } from '@/shared/utils'

import type { HeaderProps } from './types'
import * as S from './styles'

const Header: FC<HeaderProps> = ({
  title,
  finalAmount,
  totalDiscount = 0,
  isLoading,
  installment,
}) => {
  const installmentDueMonth = dayjs(installment?.due_date).format('MMM')

  const installmentOrder = installment?.order_reference?.replace('/', ' de ')

  const showDiscount = totalDiscount > 0

  const amountWithoutDiscount = finalAmount + totalDiscount

  return (
    <S.Wrapper>
      <Typography
        withoutMargin
        color="primary"
        variation="headlineDesktopMedium"
        style={{ fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      <S.Separator />
      <Box display="flex" justifyContent="space-between">
        <Typography withoutMargin color="secondary" variation="bodySmall">
          {`${installment?.student.name} - ${installment?.products?.[0]?.name}`}
        </Typography>

        <Typography withoutMargin color="secondary" variation="bodySmall">
          Valor recebido
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography withoutMargin color="primary" variation="headlineDesktopSmall">
          {`Parcela ${installmentOrder} - ${installmentDueMonth}`}
        </Typography>

        {isLoading ? (
          <Box display="flex" flexDirection="row" alignItems="center" paddingRight={4}>
            <S.StyledLoading size={20} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" flexDirection="row" alignItems="center">
              {showDiscount ? (
                <S.FullAmount>{formatCentsToReal(amountWithoutDiscount)}</S.FullAmount>
              ) : null}
              <Typography withoutMargin color="primary" variation="headlineDesktopSmall">
                {formatCentsToReal(finalAmount)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </S.Wrapper>
  )
}

export default Header
