import { matchPath, Router, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { initSentry } from '@/shared/integrations/sentry'

import { routes as accountReportRoutes } from '@/modules/account-reports/routes'
import { routes as contractRoutes } from '@/modules/contract/routes'
import { routes as creditRoutes } from '@/modules/credit/routes'
import { routes as guardiansRoutes } from '@/modules/guardians/routes'
import { routes as enrollmentRoutes } from '@/modules/enrollment/routes'
import { routes as managementRoutes } from '@/modules/management/routes'
import { routes as notificationRoutes } from '@/modules/notification/routes'
import { routes as productsRoutes } from '@/modules/products/routes'
import { routes as reportRoutes } from '@/modules/report/routes'
import { routes as generalRoutes } from '@/modules/app/routes'

import config from '@/config'
import React from 'react'

type SentryWrapperProps = {
  children: JSX.Element
}

const schoolRoutes = [
  ...accountReportRoutes,
  ...contractRoutes,
  ...creditRoutes,
  ...guardiansRoutes,
  ...enrollmentRoutes,
  ...managementRoutes,
  ...notificationRoutes,
  ...productsRoutes,
  ...reportRoutes,
]
  .filter(route => !route.redirect)
  .reverse()
  .map(route => ({ path: `/:schoolSlug${route.path}` }))

const routes = [...schoolRoutes, ...generalRoutes.map(route => ({ path: route.path }))]

export const SentryWrapper = ({ children }: SentryWrapperProps) => {
  const history = useHistory()

  if (config.SENTRY_ENABLED) {
    initSentry({
      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({
          history,
          routes,
          matchPath,
        }),
      ],
    })
  }

  return <Router history={history}>{children}</Router>
}
