import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import theme from '@/shared/theme'

export const { spacing, colors } = theme.primitiveTokens

export const NotificationCardPlaceholder = () => {
  return (
    <Box
      display="grid"
      gridGap={spacing[2]}
      padding={spacing[8]}
      border={`1px solid ${colors.gray[10]}`}
      borderRadius={spacing[2]}
      width={524}
      height={176}
    >
      <Box display="flex" gridGap={spacing[2]} alignItems="center">
        <Skeleton width={64} variant="rect" />
        <Skeleton width={85} style={{ fontSize: '0.875rem', lineHeight: 1.42857 }} />
      </Box>
      <Box display="flex" flexDirection="column" gridGap={spacing[3]}>
        <Skeleton
          width="60%"
          style={{ fontSize: '1.125rem', lineHeight: 1.55556 }}
          role="heading"
        />
        <Box>
          <Skeleton width="100%" style={{ fontSize: '0.875rem', lineHeight: 1.42857 }} />
          <Skeleton width="100%" style={{ fontSize: '0.875rem', lineHeight: 1.42857 }} />
        </Box>
      </Box>
    </Box>
  )
}
