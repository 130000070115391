import { rest } from 'msw'

import config from '@/config'

import { canAgglutinateMock } from '.'

const apiV1 = path => {
  return new URL(`/ws/v1${path}`, config.API.URL).toString()
}

const canAgglutinate = rest.get(apiV1('/receivable/can-agglutinate'), (req, res, ctx) =>
  res(ctx.json(canAgglutinateMock()))
)

export const apiV1Mocks = [canAgglutinate]
