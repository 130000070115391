import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

export const useModalEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  function sendEventClickOtherPaymentOptions() {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.CHECKOUT_FALLBACK,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: { $Button_name: 'Outras opções de pagamento' },
      })
  }

  return { sendEventClickOtherPaymentOptions }
}
