import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { sortBy, compose, toLower, prop } from 'ramda'
import dayjs from 'dayjs'

export type CombinableReceivable = {
  amount: cents
  due_date: datestring
  id: uuid
  orderReference: string
}

type ReceivableAgglutinationContextType = {
  addReceivable: (receivable: CombinableReceivable) => void
  baseAmount: cents
  clearCombinedReceivables: () => void
  combinedReceivables: CombinableReceivable[]
  discount: cents
  dueDate: datestring
  finalAmount: cents
  isCombining: boolean
  removeReceivable: (receivable: CombinableReceivable) => void
  setDiscount: (amount: cents) => void
  setDueDate: (date: datestring) => void
  setIsCombining: (isCombining: boolean) => void
}

export const ReceivablesAgglutinationStore = createContext<ReceivableAgglutinationContextType>(
  {} as ReceivableAgglutinationContextType
)
ReceivablesAgglutinationStore.displayName = 'Receivables Agglutination Store'

export const useAgglutinateReceivables = () => {
  const [isCombining, setIsCombining] = useState<boolean>(false)
  const [combinedReceivables, setCombinedReceivables] = useState<CombinableReceivable[]>([])
  const [discount, setDiscount] = useState<cents>(0)
  const [dueDate, setDueDate] = useState<datestring>(dayjs().add(1, 'day').toISOString())
  const [finalAmount, setFinalAmount] = useState<cents>(0)
  const [baseAmount, setBaseAmount] = useState<cents>(0)

  const sortReceivablesByKey = key => sortBy(compose(toLower, prop(key)))

  useEffect(() => {
    setBaseAmount(combinedReceivables.reduce((acc, { amount }) => amount + acc, 0))
  }, [combinedReceivables])
  useEffect(() => {
    discount ? setFinalAmount(baseAmount - discount) : setFinalAmount(baseAmount)
  }, [baseAmount, discount])

  const clearCombinedReceivables = useCallback(() => {
    setCombinedReceivables([])
  }, [setCombinedReceivables])

  const addReceivable = useCallback(
    (receivable: CombinableReceivable) => {
      if (combinedReceivables.findIndex(({ id }) => id === receivable.id) > -1) return
      setCombinedReceivables(combinedReceivables =>
        sortReceivablesByKey('due_date')([...combinedReceivables, receivable])
      )
    },
    [combinedReceivables]
  )

  const removeReceivable = useCallback(
    (receivable: CombinableReceivable) => {
      setCombinedReceivables(combinedReceivables.filter(({ id }) => id !== receivable.id))
    },
    [combinedReceivables]
  )

  return {
    addReceivable,
    baseAmount,
    clearCombinedReceivables,
    combinedReceivables,
    discount,
    dueDate,
    finalAmount,
    isCombining,
    removeReceivable,
    setDiscount,
    setDueDate,
    setIsCombining,
  }
}

export const ReceivablesAgglutinationProvider = ({ children }) => {
  const {
    addReceivable,
    baseAmount,
    clearCombinedReceivables,
    combinedReceivables,
    discount,
    dueDate,
    finalAmount,
    isCombining,
    removeReceivable,
    setDiscount,
    setDueDate,
    setIsCombining,
  } = useAgglutinateReceivables()

  const contextValue = useMemo(
    () => ({
      addReceivable,
      baseAmount,
      clearCombinedReceivables,
      combinedReceivables,
      discount,
      dueDate,
      finalAmount,
      isCombining,
      removeReceivable,
      setDiscount,
      setDueDate,
      setIsCombining,
    }),
    [
      addReceivable,
      baseAmount,
      clearCombinedReceivables,
      combinedReceivables,
      discount,
      dueDate,
      finalAmount,
      isCombining,
      removeReceivable,
      setDiscount,
      setDueDate,
      setIsCombining,
    ]
  )

  return (
    <ReceivablesAgglutinationStore.Provider value={contextValue}>
      {children}
    </ReceivablesAgglutinationStore.Provider>
  )
}
