import { Dialog } from '@gravity/dialog'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import Icon from '@mdi/react'
import { mdiHelpCircleOutline } from '@mdi/js'

export const PaymentDisputeHelp = () => {
  return (
    <Dialog
      title="O que é uma cobrança indevida?"
      size={3}
      description={
        <Text variant="subtitle-regular">
          São casos de responsáveis que possuem <strong>valores em atraso</strong> e quando cobrados
          pelo isaac declararam para nosso time que essa cobrança é indevida.
          <br />
          <br />
          As escolas tem o <strong>prazo de 30 dias corridos</strong> desde a abertura da
          contestação para resolvé-las.&nbsp;
          <strong>Será realizada a baixa automática desses valores</strong> caso a escola não
          conclua a análise neste prazo e isso acarretará em um&nbsp;
          <strong>desconto no repasse do mês seguinte.</strong>
          <br />
          <br />
          Em caso de negativa das contestações, os responsáveis&nbsp;
          <strong>continuarão sendo cobrados e podem ser negativados</strong> por essa dívida.
        </Text>
      }
      trigger={
        <Button variant="ghost" iconStart={<Icon path={mdiHelpCircleOutline} />}>
          O que é uma cobrança indevida?
        </Button>
      }
      actionButton={<></>}
      cancelButton={<Button>Entendi</Button>}
    />
  )
}
