import { Box } from '@material-ui/core'
import { FC, ReactNode } from 'react'

import * as S from './styles'

interface FooterProps {
  button?: ReactNode
}

const Footer: FC<FooterProps> = ({ children, button }) => {
  return (
    <S.Wrapper>
      {button ? (
        <Box width="100%" display="flex">
          {button}
        </Box>
      ) : null}
      <S.ContentWrapper>{children}</S.ContentWrapper>
    </S.Wrapper>
  )
}

export default Footer
