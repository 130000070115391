import type { ReactNode } from 'react'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'

import { ContentContainer, StyledCard } from './styles'

type ReportCardProps = {
  children: ReactNode
  description: string
  title: string
}

export const ReportCard = ({ title, description, children }: ReportCardProps) => {
  return (
    <StyledCard>
      <Heading variant="heading-h4-medium">{title}</Heading>
      <ContentContainer>
        <Text variant="body-1-regular">{description}</Text>

        <div>{children}</div>
      </ContentContainer>
    </StyledCard>
  )
}
