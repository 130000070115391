/**
 * Function to count array elements inside an object
 *
 * @param data Object containing arrays or a unique filter to be counted
 *
 * @returns Total elements
 */
export const countSelectedFilters = <T extends unknown>(data: Record<string, T[] | T>) => {
  const count = Object.entries(data).reduce((acc, [, value]) => {
    if (Array.isArray(value)) {
      return acc + value.length
    }

    if (value) {
      return acc + 1
    }

    return acc
  }, 0)

  return count
}
