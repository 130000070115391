import {
  InstallmentStatuses,
  InstallmentType,
  ProcessedInstallment,
  ReceivableStatuses,
} from 'src/shared/interfaces'

export const paramsDict: PartialRecord<ReceivableStatuses, string> = {
  AGGLUTINATED: 'Aglutinada',
  CANCELED: 'Cancelada',
  DUE_TODAY: 'Vence hoje',
  OPEN: 'A vencer',
  OVERDUE: 'Vencida',
  PAID: 'Paga',
  PAID_AND_CANCELED: 'Paga e cancelada',
  RENEGOTIATED: 'Renegociada',
}

export const getStatus = (status: ReceivableStatuses) => paramsDict[status]

export const getPaidEnrollmentInstallment = (
  processedInstallments: ProcessedInstallment[]
): ProcessedInstallment =>
  processedInstallments?.find(
    installment =>
      installment.type === InstallmentType.ENROLLMENT &&
      installment.status === InstallmentStatuses.PAID
  )

export const getOpenInstallments = (
  processedInstallments: ProcessedInstallment[]
): ProcessedInstallment[] =>
  processedInstallments?.filter(
    installment =>
      installment.status !== InstallmentStatuses.CANCELED &&
      installment.status !== InstallmentStatuses.PAID_AND_CANCELED &&
      installment.status !== InstallmentStatuses.PAID
  )

export const getRenegotiatedInstallment = (
  processedInstallments: ProcessedInstallment[]
): ProcessedInstallment =>
  processedInstallments?.find(
    installment => installment.status === InstallmentStatuses.RENEGOTIATED
  )

export const getAgglutinatedInstallment = (
  processedInstallments: ProcessedInstallment[]
): ProcessedInstallment =>
  processedInstallments?.find(
    installment => installment.status === InstallmentStatuses.AGGLUTINATED
  )

export const getOverdueInstallment = (
  processedInstallments: ProcessedInstallment[]
): ProcessedInstallment =>
  processedInstallments?.find(installment => installment.status === InstallmentStatuses.OVERDUE)

export const getDueTodayInstallment = (
  processedInstallments: ProcessedInstallment[]
): ProcessedInstallment =>
  processedInstallments?.find(installment => installment.status === InstallmentStatuses.DUE_TODAY)

export const isAllPaidInstallments = (processedInstallments: ProcessedInstallment[]): boolean =>
  processedInstallments?.filter(
    installment =>
      installment.status === InstallmentStatuses.PAID ||
      installment.status === InstallmentStatuses.PAID_AND_CANCELED
  )?.length === processedInstallments?.length

export const getTuitionInstallmentsPaid = (
  processedInstallments: ProcessedInstallment[]
): ProcessedInstallment[] =>
  processedInstallments?.filter(
    installment =>
      installment.status === InstallmentStatuses.PAID &&
      installment.type === InstallmentType.TUITION
  )
