import { invoicesErrorsPriority } from '../../../constants'
import { Invoice } from '../../../types'

export const sortInvoiceErrorsByPriority = (invoicesWithErrors: Invoice[]) =>
  invoicesWithErrors.sort((a, b) => {
    const priorityA = invoicesErrorsPriority.indexOf(a.err_description)
    const priorityB = invoicesErrorsPriority.indexOf(b.err_description)

    if (priorityA === -1 && priorityB === -1) {
      return 0
    } else if (priorityA === -1) {
      return 1
    } else if (priorityB === -1) {
      return -1
    }

    return priorityA - priorityB
  })
