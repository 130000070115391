import { spacing } from '@gravity/tokens'
import styled from 'styled-components'

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[8]};
  padding: ${spacing[8]} 0;
`
export const YearAndCampaignWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const TotalAndContractActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const ReferenceYear = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing[4]};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${spacing[4]};
`
export const ScoreAndContract = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[4]};
`
