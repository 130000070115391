import { useContext } from 'react'
import { InstallmentsStore } from '@/shared/contexts/installmentsContext'

const useInstallments = () => {
  const {
    processedInstallments,
    setProcessedInstallments,
    selectedProcessedInstallment,
    setSelectedProcessedInstallment,
  } = useContext(InstallmentsStore)
  return {
    processedInstallments,
    setProcessedInstallments,
    selectedProcessedInstallment,
    setSelectedProcessedInstallment,
  }
}

export default useInstallments
