import { createContext, useContext } from 'react'

import { useDeclareAgreement } from 'src/escolas/hooks'

import type { AgreementContextData, AgreementProviderProps } from './types'

export const AgreementContext = createContext<AgreementContextData | undefined>(undefined)

export const AgreementProvider = ({ children }: AgreementProviderProps) => {
  const value = useDeclareAgreement()

  return <AgreementContext.Provider value={value}>{children}</AgreementContext.Provider>
}

export function useAgreement(): AgreementContextData {
  const context = useContext(AgreementContext)

  if (!context) {
    throw new Error('useAgreement must be used within a AgreementProvider')
  }

  return context
}
