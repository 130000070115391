import { AxiosInstance } from 'axios'
import { APIResponse } from '@/shared/interfaces'

export const managementService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetching the school group management URL
     *
     * @param schoolGroupId School Group ID
     * @param schoolId School ID
     *
     * @returns Embedded management URL
     */
    fetchSchoolGroupManagement: async ({
      schoolGroupId,
      schoolId,
    }: {
      schoolGroupId: string
      schoolId: string
    }) => {
      const { data } = await fetcher.get<APIResponse<{ url: string }>>(
        `/report/school-group/${schoolGroupId}/management/${schoolId}`
      )

      return data
    },
    /**
     * Function responsible for fetching the school management URL
     *
     * @param schoolId School ID
     *
     * @returns Embedded management URL
     */
    fetchSchoolManagement: async ({ schoolId }: { schoolId: string }) => {
      const { data } = await fetcher.get<APIResponse<{ url: string }>>(
        `/report/school/${schoolId}/management`
      )

      return data
    },
  }
}
