import { useEffect, useRef, useState } from 'react'

import type { NotificationResponseDTO } from '../services/types'
import type { Notification } from '../types'

import { NOTIFICATION_STATUS } from '../constants'

export type NotificationStatusParams = {
  notifications: NotificationResponseDTO
  unreadNotifications: NotificationResponseDTO
}

export const useNotificationsStatus = (params: NotificationStatusParams) => {
  const { notifications, unreadNotifications } = params

  const [currentNotifications, setCurrentNotifications] = useState<Notification[]>(null)

  const unreadNotificationsRef = useRef({})
  const { current: unreadNotificationsMap } = unreadNotificationsRef

  // Check which notifications should be set as an unread notifications
  useEffect(() => {
    if (!unreadNotifications) {
      return
    }

    unreadNotifications?.forEach(notification => {
      if (typeof unreadNotificationsMap[notification.id] !== 'boolean') {
        unreadNotificationsMap[notification.id] = true
      }
    })
  }, [unreadNotifications])

  // Set the  notifications status
  useEffect(() => {
    if (!notifications || !unreadNotifications) {
      return
    }

    const notificationsWithStatus = notifications.map(notification => ({
      ...notification,
      status: unreadNotificationsMap[notification.id]
        ? NOTIFICATION_STATUS.UNREAD
        : NOTIFICATION_STATUS.READ,
    }))

    setCurrentNotifications(notificationsWithStatus)
  }, [notifications, unreadNotifications])

  const handleNotificationRead = (index: number, notificationId: string) => {
    if (currentNotifications[index].status === NOTIFICATION_STATUS.READ) {
      return
    }

    unreadNotificationsMap[notificationId] = false
    currentNotifications[index].status = NOTIFICATION_STATUS.READ
    setCurrentNotifications([...currentNotifications])
  }

  return {
    data: {
      notifications: currentNotifications,
      unreadNotifications:
        currentNotifications?.filter(({ status }) => status === NOTIFICATION_STATUS.UNREAD) ?? [],
    },
    handleNotificationRead,
  }
}
