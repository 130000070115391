import {
  IconButton as MUIIconButton,
  MenuItem as MUIMenuItem,
  Popover as MUIPopover,
  withStyles,
} from '@material-ui/core'
import styled from 'styled-components'

import type { BoxProps } from '@material-ui/core'

import theme from '@/shared/theme'

export const { colors, spacing } = theme.primitiveTokens

export const getBoxProps = (props: BoxProps) => ({
  position: 'sticky',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${spacing[8]} ${spacing[8]}`,
  zIndex: 1,
  style: { background: colors.gray[1] },
  ...props,
})

export const Menu = withStyles({
  paper: {
    height: 555,
    width: 557,
    borderRadius: '8px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
})(MUIPopover)

export const IconButton = styled(MUIIconButton)`
  border-radius: 8px;
  padding: ${spacing[1]};
`

export const MenuItem = styled(MUIMenuItem)`
  padding: 0 ${spacing[4]} ${spacing[1]} ${spacing[4]};
  user-select: auto;
  white-space: normal;

  &:hover,
  &:active {
    background: ${colors.gray[0]};
    cursor: default;
  }
`
