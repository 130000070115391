import { Skeleton } from '@gravity/skeleton'
import { Box, Grid } from '@material-ui/core'
import {
  RowContainer,
  RowFlexContainer,
  TitleSkeleton,
  ContainerCard,
  TitleChosenOfferDate,
  SkeletonRows,
  SubTitleSkeleton,
} from './styles'
import { Separator } from '@gravity/separator'

export const LoadingStatus = () => {
  return (
    <RowContainer>
      <Box mt={12}>
        <Grid container justifyContent="center" spacing={3} style={{ padding: '0 64px' }}>
          <Grid item xs={6}>
            <TitleSkeleton radius={8} width={600} height={40} />
            <SubTitleSkeleton radius={8} width={233} />

            <RowContainer>
              <ContainerCard>
                <TitleChosenOfferDate>
                  <Skeleton radius={8} fullWidth />
                </TitleChosenOfferDate>
                <Separator color="neutral-2" orientation="horizontal" />

                <SkeletonRows radius={8} fullWidth />
                <SkeletonRows radius={8} fullWidth />
                <SkeletonRows radius={8} fullWidth />
                <SkeletonRows radius={8} fullWidth />
                <SkeletonRows radius={8} fullWidth />
              </ContainerCard>
            </RowContainer>

            <RowFlexContainer>
              <Skeleton radius={8} width={195} height={40} />
            </RowFlexContainer>
          </Grid>
        </Grid>
      </Box>
    </RowContainer>
  )
}
