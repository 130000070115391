import { Table } from '@gravity/table'
import { Heading } from '@gravity/heading'
import dayjs from 'dayjs'

import { useQuery } from '@/shared/hooks/useQuery'
import { useNotificationTable } from './hooks/useNotificationTable'
import { useNotificationFilters } from './hooks/useNotificationFilters'

import { NotificationDetailsDialog } from '../NotificationDetailsDialog'
import { FiltersDialog } from '@/shared/components/FiltersDialog'
import { NotificationsPlaceholder } from './components/NotificationsPlaceholder'
import { trimText } from '../Table'

import type { Category, NotificationStatus } from '../../types'
import type { NotificationsSectionProps } from './types'

import { HEADER_CELL } from './constants/HEADER_CELL'
import { ICON } from './constants/ICON'
import { NOTIFICATION_STATUS } from '../../constants'
import { categoryOptions, statusOptions } from './constants/form'

import { Container } from './styles'

const getStatusMessage = (category: Category, status: NotificationStatus) =>
  ({
    [NOTIFICATION_STATUS.READ]: category === 'tarefas' ? 'Lida' : NOTIFICATION_STATUS.READ,
    [NOTIFICATION_STATUS.UNREAD]: category === 'tarefas' ? 'Não lida' : NOTIFICATION_STATUS.UNREAD,
  }[status])

export const NotificationsSectionV2 = (props: NotificationsSectionProps) => {
  const { notifications, isLoading, page, itemsPerPage, totalItems, onNotificationRead } = props

  const {
    rows,
    selectedRow,
    selectedRowIndex,
    resetSelectedRow,
    handlePageChange,
    handleItensPerChangeChange,
    handleKeyDown,
    handleRowClick,
  } = useNotificationTable({ notifications, isLoading })

  const {
    filtersGroup,
    isFilterDialogOpen,
    form,
    selectedFilters,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    handleRemoveFilter,
    onSubmitFilters,
  } = useNotificationFilters()

  const { query } = useQuery()

  return (
    <Container>
      <div className="content">
        <Heading variant="heading-h4-medium">Lista de avisos</Heading>
        <Table.Root>
          <Table.Filtering
            filters={selectedFilters}
            onClickFilterButton={handleOpenFilterDialog}
            onRemoveFilter={handleRemoveFilter}
          />
          <Table.Head>
            {HEADER_CELL.map(cell => (
              <Table.HeaderCell key={cell.name} name={cell.name}>
                {cell.label}
              </Table.HeaderCell>
            ))}
          </Table.Head>
          <Table.Body>
            {isLoading && <NotificationsPlaceholder />}
            {!isLoading &&
              rows.map((row, index) => (
                <Table.Row
                  onClick={() => {
                    handleRowClick(index)
                  }}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      handleKeyDown(index)
                    }
                  }}
                  key={row.id}
                >
                  <Table.TextCell className="category" icon={ICON[row.category].icon}>
                    {row.category}
                  </Table.TextCell>
                  <Table.TextCell>{dayjs(row.date).format('DD/MM/YYYY')}</Table.TextCell>
                  <Table.BadgeCell
                    badgeVariant="soft"
                    badgeColor={row.status === 'Lido' ? 'neutral' : 'error'}
                  >
                    {getStatusMessage(row.category, row.status)}
                  </Table.BadgeCell>
                  <Table.TextCell>{row.title}</Table.TextCell>
                  <Table.TextCell>{trimText(row.content)}</Table.TextCell>
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Pagination
            page={page}
            itemsPerPage={itemsPerPage}
            total={totalItems}
            onChangePage={page => handlePageChange(page)}
            onChangeItemsPerPage={itemsPerPage => handleItensPerChangeChange(itemsPerPage)}
          />
        </Table.Root>
      </div>
      {selectedRow && !isLoading && (
        <NotificationDetailsDialog
          category={selectedRow.category}
          contentHtml={selectedRow.contentHtml}
          date={new Date(selectedRow.date)}
          isOpen={!!selectedRow}
          onClose={() => {
            !!query.get('id') && onNotificationRead(selectedRowIndex, query.get('id'))

            resetSelectedRow()
          }}
          title={selectedRow.title}
          action={
            !!selectedRow.linkText && !!selectedRow.linkUrl
              ? { label: selectedRow.linkText, url: selectedRow.linkUrl }
              : undefined
          }
          onActionClick={() => console.log('TODO')}
        />
      )}
      <FiltersDialog
        form={form}
        filters={filtersGroup}
        onApplyFilters={onSubmitFilters}
        closeDialog={handleCloseFilterDialog}
        visible={isFilterDialogOpen}
        resetValues={{
          status: statusOptions,
          category: categoryOptions,
        }}
      />
    </Container>
  )
}
