import { FormOfPayment, SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import { CREDIT_CARD_PAYMENT_OPTIONS } from '../../../../components/Inputs/types'

export const isPaymentByPos = (
  selectedFormOfPayment,
  selectedSimulationPaymentMethod,
  creditCardPaymentOption
) => {
  if (selectedFormOfPayment === FormOfPayment.POS || selectedFormOfPayment === FormOfPayment.POS_V2)
    return true

  if (selectedSimulationPaymentMethod === SimulationPaymentMethod.DEBIT_CARD) return true

  if (creditCardPaymentOption === CREDIT_CARD_PAYMENT_OPTIONS.POS) return true

  if (
    selectedFormOfPayment === FormOfPayment.INSTALLMENTS &&
    selectedSimulationPaymentMethod === SimulationPaymentMethod.CREDIT_CARD &&
    creditCardPaymentOption === CREDIT_CARD_PAYMENT_OPTIONS.POS
  )
    return true
  return false
}
