import { Contract } from '@/escolas/pages/responsaveis/GuardianDetails/types'
import { Rows } from '../types'

export const mapDataToRows = (rawData: Contract[]): Rows => {
  return rawData?.map(item => ({
    ...item,
    key: item.id,
    contract: item,
  }))
}
