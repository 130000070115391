import { useApi } from '@/shared/hooks'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

export const useAddressAutoComplete = () => {
  const { api } = useApi()
  const [zipCode, setZipCode] = useState<string>('')
  const { data } = useQuery({
    queryKey: ['guardian-address', zipCode],
    queryFn: () => {
      if (zipCode) return api.guardians.getAddressFromZip(zipCode.replace(/[^0-9]/g, ''))
      return null
    },
    enabled: Boolean(zipCode),
    retry: false,
  })

  const fecthAddress = (zip: string) => {
    setZipCode(zip)
  }

  const cleanAddress = () => {
    setZipCode('')
  }

  return {
    addressData: data,
    fecthAddress,
    cleanAddress,
  }
}
