export enum IsaacPayTabs {
  EXTERNAL_PAYMENTS = 'external_payments',
  OTHER_PAYMENTS = 'other_payments',
}

export type IsaacPayTab = {
  label: string
  value: IsaacPayTabs
}

export const OUTSOURCED_PROVIDER_TABS: Array<IsaacPayTab> = [
  {
    label: 'Pagamentos recebidos pela escola',
    value: IsaacPayTabs.EXTERNAL_PAYMENTS,
  },
  {
    label: 'Pagamentos recebidos pelo isaac',
    value: IsaacPayTabs.OTHER_PAYMENTS,
  },
]
