import Accordion from '@material-ui/core/Accordion'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'

export const AccordionContainer = styled(Accordion).attrs({
  defaultExpanded: true,
  disableGutters: true,
})``
export const AccordionContent = styled(Box)`
  padding: 48px;
  padding-top: 0;
`
