import styled from 'styled-components'
import { Text } from '@gravity/text'
import { Box } from '@material-ui/core'

import { spacing, colors } from '@gravity/tokens'

interface InfoTextProps {
  disabled?: boolean
}

export const DiscountTotalAmount = styled(Text)<InfoTextProps>`
  color: ${props => (props.disabled ? colors['colors-text-main-4'] : colors['colors-text-main-3'])};
  text-decoration: ${props => (props.disabled ? 'line-through' : '')};
`

export const InfoText = styled(Text)<InfoTextProps>`
  color: ${props => (props.disabled ? colors['colors-text-main-4'] : colors['colors-text-main-3'])};
`

export const DiscountInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]};
`

export const DiscountInfoHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export const DiscountInfoItemWrapper = styled(Box)`
  display: flex;
  gap: ${spacing[2]};
  flex-direction: column;
`
export const DiscountInfoItem = styled(Box)`
  display: flex;
  gap: ${spacing[4]};
  justify-content: space-between;
`
