import { useAddTuitionToCampaignQuery } from '@/modules/enrollment/pages/StudentEnrollment/hooks/queries/useAddTuitionToCampaign'
import { discountRule } from '@/modules/enrollment/pages/Campaigns/CampaignDetails/components/InstallmentFormDiscountItem'
import type {
  FormProps,
  Discount,
} from '@/modules/enrollment/pages/Campaigns/CampaignDetails/components/InstallmentForm/types'
import { installmentDueDateRule } from '@/modules/enrollment/pages/Campaigns/CampaignDetails/components/InstallmentForm/types'
import type { School } from '@/shared/models/School'
import { isIsaacPaySchool } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'
import type {
  AddBulkInstallmentData,
  DiscountBulkInstallment,
} from '@/modules/enrollment/services/enrollmentService/types'
import { SchoolChargeOperation } from '@/modules/enrollment/services/enrollmentService/types'
import { useToast } from '@gravity/toast'
import { queryClient } from '@/modules/app/contexts/GlobalProviders/ReactQuery'
import dayjs from 'dayjs'

type Params = {
  campaignId: uuid
  school?: School
}

type AddTuitionParams = {
  customInstallmentsFormData?: Map<string, FormProps>
  enrollmentId?: uuid
  excludedEnrollmentIds?: uuid[]
  formData: FormProps | null
}

export const useAddTuitionToCampaign = ({ campaignId, school }: Params) => {
  const { mutateAsync: mutateAddTuition, isLoading } = useAddTuitionToCampaignQuery()
  const { toast } = useToast()

  const getDiscountDaysBeforeDueDate = (discountType: discountRule, daysBefore: number): number => {
    const discountTypeMap = {
      [discountRule.PERMANENT_DISCOUNT]: -1,
      [discountRule.DUE_DATE_DISCOUNT]: 0,
      [discountRule.EARLY_PAYMENT_DISCOUNT]: daysBefore,
    }

    return discountTypeMap[discountType] ?? 0
  }

  const normalizeDiscounts = (discounts: Discount[]): DiscountBulkInstallment[] =>
    discounts.map(discount => {
      const daysBeforeDueDate = getDiscountDaysBeforeDueDate(
        discount.due_date_rule as discountRule,
        Number(discount.days_before_due_date)
      )

      const { due_date_rule: _due_date_rule, ...restDiscount } = discount
      return {
        ...restDiscount,
        days_before_due_date: daysBeforeDueDate,
      }
    })

  const adaptFormDataToPayloadConfig = (
    formData: FormProps | null,
    enrollmentId: uuid = ''
  ): AddBulkInstallmentData => {
    if (!formData) {
      throw new Error('formData cannot be null')
    }

    const {
      custom_monthly_amount,
      discounts,
      due_day,
      due_date,
      due_date_month,
      duration_months,
      installment_due_date_rule,
    } = formData
    const normalizedDiscounts = normalizeDiscounts(discounts)

    return {
      enrollment_id: enrollmentId,
      discounts: normalizedDiscounts,
      due_day: Number(due_day),
      custom_monthly_amount: custom_monthly_amount,
      duration_months: Number(duration_months),
      school_charge_operation: isIsaacPaySchool(school)
        ? SchoolChargeOperation.isaacPay
        : SchoolChargeOperation.default,
      start_month: (Number(duration_months) === 1 ? due_date : due_date_month) ?? dayjs().toDate(),
      use_working_dates_tuition: installment_due_date_rule === installmentDueDateRule.WORKING_DAY,
    }
  }

  const addTuition = ({
    formData,
    excludedEnrollmentIds = [],
    customInstallmentsFormData = new Map<string, FormProps>(),
    enrollmentId,
  }: AddTuitionParams) => {
    if (!school) {
      toast({
        title: 'Erro ao identificar a escola',
        type: 'error',
      })
      return
    }
    return mutateAddTuition(
      {
        params: { campaignID: campaignId },
        body: {
          custom_config: Array.from(
            customInstallmentsFormData
          ).map(([enrollmentId, customFormData]) =>
            adaptFormDataToPayloadConfig(customFormData, enrollmentId)
          ),
          not_included_students: excludedEnrollmentIds,
          default_config: adaptFormDataToPayloadConfig(formData, enrollmentId),
        },
      },
      {
        onSuccess: () => {
          toast({
            title: 'Mensalidades adicionadas com sucesso!',
            type: 'success',
          })
          setTimeout(() => {
            queryClient.invalidateQueries(['fetch-campaign-students'])
          }, 2000)
        },
        onError: () => {
          toast({
            title:
              'Não foi possível adicionar as parcelas de mensalidade. Tente novamente em alguns instantes.',
            type: 'error',
          })
        },
      }
    )
  }

  return {
    addTuition,
    isLoading,
  }
}
