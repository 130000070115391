import { Button } from '@olaisaac/design-system'

import type { ChangeGuardianBannerProps } from './types'

import { Container, Explanation, InfoContainer, Title } from './style'

const ChangeGuardianBanner = (props: ChangeGuardianBannerProps) => {
  const {
    title = 'Estamos alterando o responsável financeiro',
    explanation = 'Estamos gerando outro contrato com os dados do novo responsável financeiro',
    buttonLabel,
    imageSrc,
    isLoading = false,
    buttonOnClick,
  } = props
  return (
    <Container>
      <InfoContainer>
        <img src={imageSrc} alt="" width={403} height={214} />
        <Title>{title}</Title>
        <Explanation>{explanation}</Explanation>
        <Button loading={isLoading} onClick={buttonOnClick}>
          {buttonLabel}
        </Button>
      </InfoContainer>
    </Container>
  )
}

export default ChangeGuardianBanner
