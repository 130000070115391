import { useEffect, useState } from 'react'
import { LinearProgress } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useSchools } from 'src/escolas/hooks/useSchools'

import SchoolTransitionImage from '@/modules/app/assets/school-transition-ge.svg'

import { StyledBox, StyledContainer, StyledTextGoiaba } from './styles'

type SchoolTransitionProps = {
  onComplete?: () => void
}

const INTERVAL_DELAY_MS = 550
const MAX_PROGRESS = 100
const ADD_PROGRESS = 35

export const SchoolTransition = ({ onComplete }: SchoolTransitionProps) => {
  const [progress, setProgress] = useState(0)

  const { schoolSlug } = useSelectedSchool()
  const { schools } = useSchools()

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(state => {
        if (state === MAX_PROGRESS) {
          clearInterval(timer)
          onComplete?.()

          return MAX_PROGRESS
        }

        const newProgress = state + ADD_PROGRESS

        return newProgress > MAX_PROGRESS ? MAX_PROGRESS : newProgress
      })
    }, INTERVAL_DELAY_MS)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const currentSchoolName = (() => {
    if (!schoolSlug || schools.length === 0) return ''

    const school = schools.find(school => school.slug === schoolSlug)

    return school?.name ?? ''
  })()

  return (
    <StyledContainer>
      <img src={SchoolTransitionImage} alt="Preparando a unidade selecionada." />

      <Typography variation="headlineDesktopMedium">
        Preparando a unidade <StyledTextGoiaba>{currentSchoolName}</StyledTextGoiaba>
      </Typography>

      <StyledBox>
        <LinearProgress variant="determinate" value={progress} />
      </StyledBox>
    </StyledContainer>
  )
}
