import { useRef } from 'react'

import { SchoolSideMenu } from '@/modules/app/components/SideMenu/SchoolSideMenu'
import { Header } from '@/modules/app/components/Header'

import { useScrollToTop } from '@/shared/hooks/useScrollToTop'

import { Container, Content, HeaderContainer, PageContent, SideMenuContainer } from './styles'

import type { LayoutProps } from '@/shared/contexts/Layout/types'
/**
 * Component that represents the base structure (shell) of all school pages
 *
 * @param props.title Header title
 * @param props.isHeaderVisible Indicates if header should be displayed
 * @param props.isSideMenuVisible Indicates if side-menu should be displayed
 * @param props.children
 */
export const Layout = ({ title, isSideMenuVisible, isHeaderVisible, children }: LayoutProps) => {
  const mainRef = useRef<HTMLElement>(null)

  useScrollToTop(mainRef)

  return (
    <Container>
      {isSideMenuVisible && (
        <SideMenuContainer>
          <SchoolSideMenu />
        </SideMenuContainer>
      )}

      <Content>
        {isHeaderVisible && (
          // REVIEW: HeaderContainer
          <HeaderContainer id="page-header">
            <Header title={title} />
          </HeaderContainer>
        )}

        <PageContent ref={mainRef}>{children}</PageContent>
      </Content>
    </Container>
  )
}
