import { Typography, TextField } from '@olaisaac/design-system'
import NumberFormat from 'react-number-format'
import { formatCentsToReal } from 'src/shared/utils'
import { StyledInputMinDown, StyledInputLabel } from './styles'
import type { InputMinDownProps } from './types'

const InputMinDown = ({
  handleValue,
  selectedValue,
  minAmount,
  maxAmount,
  disabled = false,
  title,
  label,
}: InputMinDownProps) => {
  const invalidAmount = selectedValue < minAmount || selectedValue > maxAmount

  const messageError = () => {
    if (!invalidAmount) return ''
    if (selectedValue < minAmount) return `O valor mínimo é de ${formatCentsToReal(minAmount)}`
    return `O valor máximo é de ${formatCentsToReal(maxAmount)}`
  }

  return (
    <StyledInputMinDown marginRight={4} minWidth={294} disabled={disabled}>
      <StyledInputLabel>
        <Typography variation="bodySmall" withoutMargin color="secondary">
          {title}
        </Typography>
      </StyledInputLabel>

      <NumberFormat
        id="min_down_payment"
        onValueChange={({ floatValue }) => {
          handleValue(floatValue)
        }}
        customInput={TextField}
        variant="outlined"
        label={label}
        error={invalidAmount}
        helperText={messageError()}
        format={formatCentsToReal}
        value={selectedValue}
        style={{ minWidth: '255px', maxWidth: '294px' }}
        disabled={disabled}
      />
    </StyledInputMinDown>
  )
}

export default InputMinDown
