import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'
import { Card } from '@gravity/card'
import { Box } from '@material-ui/core'
import { spacing, colors, fontWeight, fontSize, borderRadius, lineHeight } from '@gravity/tokens'

export const ReceiptsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  max-height: 318px;
  width: 40%;
  @media screen and (max-width: 1112px) {
    width: 100%;
  }
`

export const ReceiptsCardContainer = styled(Card)`
  cursor: pointer;
  display: flex;
  width: 100%;
  max-height: 151px;
  padding: ${spacing[8]};
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: ${borderRadius[4]};
  border: 1px solid ${colors['colors-border-neutral-3']};
`

export const ColumnReceipts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[4]};
`

export const ReceiptsTitle = styled(Typography)`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.lg};
  font-style: normal;
  font-weight: ${fontWeight.medium};
  line-height: 125%;
`

export const ReceiptsValue = styled(Typography)`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.base};
  font-style: normal;
  font-weight: ${fontWeight.normal};
  line-height: ${lineHeight.normal};
  letter-spacing: 0.03125rem;
`
