import { spacing, colors } from '@gravity/tokens'
import { Text } from '@gravity/text'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import styled from 'styled-components'

export const SplitInputRow = styled.div`
  margin-top: ${spacing[4]};
  display: flex;
  justify-content: space-between;
  gap: ${spacing[6]};
  > div {
    flex: 1;
  }
`

export const DatepickerTrigger = styled(Datepicker.Trigger)`
  width: 100%;
`

export const InputRow = styled.div`
  margin-top: ${spacing[4]};
`

export const InputLabelWrapper = styled.div`
  margin: ${spacing[8]} 0 ${spacing[4]};
`

export const SelectWrapper = styled.div`
  width: 100%;
`

export const SelectFeedbackText = styled(Text)`
  margin-top: ${spacing[3]};
  margin-left: ${spacing[1]};
  color: ${colors['colors-background-semantic-colors-error-1']};
`

export const InputLabel = styled.div`
  margin-bottom: ${spacing[2]};
`

export const InputWrapper = styled.div<{ hasError: boolean }>`
  > span {
    margin-bottom: ${spacing[2]};
    line-height: 24px;
  }

  ${({ hasError }) =>
    hasError &&
    `
    button {
      --tw-ring-shadow: 0 0 #000 !important;
      border: 1px solid ${colors['colors-interaction-dangerous-1']};
    }
    span {
      color: ${colors['colors-interaction-dangerous-1']};
    }
  `}
`
