import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { InstallmentRowType } from '../../GuardianDetails/constants'

export const TrackDrawerClickEvents = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  BUTTON_CLICK: 'BUTTON_CLICK',
} as const

export const useGuardiansInstallmentsTabEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const trackRowClick = ($installment_type: InstallmentRowType, $contract_id: uuid) => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.GUARDIANS,
        name: EventDispatcherEvents.TABLE_ROW_CLICK,
        action: 'click',
        customProperties: {
          $page_name: 'Página do RF',
          $installment_type,
          $contract_id,
        },
      })
    }
  }

  const trackDrawerClick = (
    $installment_urn: string,
    $event: keyof typeof TrackDrawerClickEvents,
    $element?: string
  ) => {
    if (isInitialized) {
      const date = new Date()
      const customProperties = {
        $installment_urn,
        $open_at: null,
        $close_at: null,
        $button_click: null,
      }

      if ($event === TrackDrawerClickEvents.OPEN) {
        customProperties.$open_at = date.toISOString()
      }

      if ($event === TrackDrawerClickEvents.CLOSE) {
        customProperties.$close_at = date.toISOString()
      }

      if ($event === TrackDrawerClickEvents.BUTTON_CLICK) {
        customProperties.$button_click = $element || 'unknown'
      }

      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.GUARDIANS,
        name: EventDispatcherEvents.DRAWER_VIEW,
        action: 'click',
        customProperties,
      })
    }
  }

  return {
    trackDrawerClick,
    trackRowClick,
  }
}
