export const stringToNumber = (value: string) => Number(value.replace(/[^0-9]/g, ''))

export const removeCentsAndRoundUp = (value: string): string => {
  const hasCents = stringToNumber(value) % 100 !== 0
  const formattedValue = value.length > 2 ? value.slice(0, -2) : value
  const response = hasCents ? Number(formattedValue) + 1 : Number(formattedValue)
  return `${response.toString()}00`
}

export const numberWithoutCentsAndRoundUp = (value: string) =>
  Number(removeCentsAndRoundUp(value.replace(/\D/g, '')))

export const numberWithoutCents = (value: string) => stringToNumber(value) / 100
