import { Accordion, AccordionRootProps } from '@gravity/accordion'
import Icon from '@mdi/react'
import { mdiAlertCircle, mdiAlertCircleCheck, mdiCheckCircle, mdiMinusCircle } from '@mdi/js'
import { IrregularStudentItem } from '@/modules/enrollment/services/types'
import { IrregularStudentStatus } from '../../types'
import { IrregularStudentTableProps } from './types'
import * as Styled from './styles'
import { IrregularStudentContent } from '../IrregularStudentContent'
import { IrregularStatusBadge } from '../IrregularStatusBadge'
import { useIrregularStudentsEvents } from '../../hooks/useIrregularStudentsEvents'
import { formatCurrencyValue } from '@/shared/utils'
import { LoadingState } from '../LoadingState'
import { CopyToClipboardButton } from '../CopyToClipboardButton'
import { Heading } from '@gravity/heading'
import { pluralize } from '@/shared/utils/pluralize'
import { colors } from '@gravity/tokens'

export const formatPendingItemsText = (studentItem: IrregularStudentItem): string => {
  if (studentItem.status.toLowerCase() === IrregularStudentStatus.FINISHED) {
    return pluralize('pendência concluída', studentItem.total_pending_items)
  }

  if (studentItem.enrollment_requests?.length > 1) {
    const productsSet: Set<uuid> = new Set()
    studentItem.enrollment_requests.forEach(request => {
      productsSet.add(request.product_id)
    })

    if (productsSet.size > 1) {
      return `${studentItem.total_pending_items} pendências em ${productsSet.size} produtos`
    }
  }

  return pluralize('pendência', studentItem.total_pending_items)
}

const formatBlockedAmountText = (studentItem: IrregularStudentItem): string => {
  return studentItem.status.toLowerCase() === IrregularStudentStatus.FINISHED
    ? `${formatCurrencyValue(studentItem.total_blocked_amount / 100)} liberados`
    : `${formatCurrencyValue(studentItem.total_blocked_amount / 100)} pendentes`
}

const AccordionTitle = ({ name }: { name: string }) => {
  const { sendClickCopyStudentNameEvent } = useIrregularStudentsEvents()

  return (
    <Styled.AccordionTitleContainer>
      <Heading style={{ lineHeight: 1 }} variant="heading-h4-medium">
        {name}
      </Heading>
      <CopyToClipboardButton
        copyableText={name}
        tooltipText="Nome do aluno copiado"
        onClick={sendClickCopyStudentNameEvent}
      />
    </Styled.AccordionTitleContainer>
  )
}

const AccordionTrigger = ({ item }: { item: IrregularStudentItem }) => {
  const { sendClickAccordionEvent } = useIrregularStudentsEvents()

  return (
    <Accordion.Trigger
      title="Clique para visualizar as pendências do aluno"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        sendClickAccordionEvent(item)
      }}
      labelComponent={<IrregularStatusBadge status={item.status} />}
      descriptionList={[
        {
          iconStart:
            item.status.toLowerCase() === IrregularStudentStatus.FINISHED ? (
              <Icon path={mdiAlertCircleCheck} color={colors['colors-text-main-2']} />
            ) : (
              <Icon path={mdiAlertCircle} color={colors['colors-text-main-2']} />
            ),
          text: formatPendingItemsText(item),
        },
        {
          iconStart:
            item.status.toLowerCase() === IrregularStudentStatus.FINISHED ? (
              <Icon path={mdiCheckCircle} color={colors['colors-text-main-2']} />
            ) : (
              <Icon path={mdiMinusCircle} color={colors['colors-text-main-2']} />
            ),
          text: formatBlockedAmountText(item),
        },
      ]}
    >
      <AccordionTitle name={item.student_name} />
    </Accordion.Trigger>
  )
}

export const IrregularStudentTable = ({ data, isLoading }: IrregularStudentTableProps) => {
  if (isLoading) {
    return <LoadingState />
  }

  if (!data?.length) {
    return <Styled.EmptyStateContainer>Nenhum resultado encontrado</Styled.EmptyStateContainer>
  }

  const accordionProps: AccordionRootProps & { collapsible: boolean } = {
    type: 'single',
    collapsible: true,
    defaultValue: data?.length > 0 ? data[0].student_name : '',
  }

  return (
    <Styled.AccordionContainer>
      <Accordion.Root {...accordionProps}>
        {data?.map(item => (
          <Styled.AccordionItem key={item.student_name} value={item.student_name}>
            <AccordionTrigger item={item} />
            <Accordion.Content>
              <IrregularStudentContent student={item} />
            </Accordion.Content>
          </Styled.AccordionItem>
        ))}
      </Accordion.Root>
    </Styled.AccordionContainer>
  )
}
