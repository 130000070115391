import styled from 'styled-components'
import { RadioGroup } from '@material-ui/core'

export const SectionWrapper = styled.section`
  margin: 24px 0;
`

export const DiscountModeWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
`

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TextContainer = styled.div`
  margin-bottom: 24px;
`
export const RadioGroupRow = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
`
