import styled from 'styled-components'

export const Wrapper = styled.div`
  .MuiButton-root,
  .MuiButton-label {
    font-size: 13px !important;
  }
`

export const ButtonImg = styled.img`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  width: 18px;
  height: 18px;
`
