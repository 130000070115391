import { Notification } from '@olaisaac/design-system'

interface EditAvailabilityWarningProps {
  isContractInFlexiblePeriod: boolean
  isNewContractEditRulesEnabled: boolean
}

export const EditAvailabilityWarning = ({
  isNewContractEditRulesEnabled,
  isContractInFlexiblePeriod,
}: EditAvailabilityWarningProps) => {
  if (
    !isNewContractEditRulesEnabled ||
    (isNewContractEditRulesEnabled && isContractInFlexiblePeriod)
  )
    return null

  return (
    <div style={{ margin: '0 -24px' }}>
      <Notification
        description="Edição disponível somente para parcelas com vencimento no próximo mês"
        variation="warning"
      />
    </div>
  )
}
