import { ROLES, ROLES_ISAAC } from '@/shared/constants'

export const getUserRole = (isGroupManager: boolean, isMaintainer: boolean) => {
  if (isGroupManager) {
    return ROLES_ISAAC[ROLES.GROUP_MANAGER]
  }

  if (isMaintainer) {
    return ROLES_ISAAC[ROLES.PROFILE_MANTAINER]
  }

  return ROLES_ISAAC[ROLES.PROFILE_SECRETARY]
}
