import { useSnackbar } from '@/shared/hooks'

import { useGuardianHistoryNegotiationQuery } from './useGuardianHistoryNegotiationQuery'
import { useEffect } from 'react'

export const useGuardianHistoryNegotiation = (negotiationId?: string) => {
  const { setMessage, setVariation, setIsOpen } = useSnackbar()

  const {
    guardianHistoryNegotiation,
    isError,
    isLoading,
    isSuccess,
  } = useGuardianHistoryNegotiationQuery({
    id: negotiationId,
  })

  useEffect(() => {
    if (isError) {
      setMessage('Ocorreu um erro ao buscar os detalhes da negociação. Tente novamente mais tarde.')
      setVariation('error')
      setIsOpen(true)
    }
  })

  return {
    guardianHistoryNegotiation,
    isError,
    isLoading,
    isSuccess,
  }
}
