import { TuitionEditFormValues } from 'src/escolas/pages/PreContractEdit/types'

export const formatDueDay = (
  dueDay: TuitionEditFormValues['due_day'],
  useWorkingDates: TuitionEditFormValues['use_working_dates_tuition']
): number | string => {
  if (!dueDay) {
    return '-'
  }

  if (useWorkingDates === 'true') {
    return `${dueDay}º dia útil`
  }

  return dueDay
}
