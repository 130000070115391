import { Skeleton } from '@gravity/skeleton'

import { Container } from './styles'

export const NotificationCardPlaceholder = () => {
  return (
    <Container>
      <div>
        <Skeleton width={64} height={28} />
        <Skeleton width={80} height={20} />
      </div>

      <Skeleton width={270} height={16} />
      <Skeleton fullWidth />
      <Skeleton fullWidth />
    </Container>
  )
}
