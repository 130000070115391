import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApi } from 'src/shared/hooks'

import { PayoutSummary } from '@/modules/report/models/PayoutSummary'

type PayoutsQueryProps = {
  options?: UseQueryOptions<PayoutSummary[]>
  schoolId: string
}

export const usePayoutsQuery = ({ schoolId, options }: PayoutsQueryProps) => {
  const { api } = useApi()

  return useQuery<PayoutSummary[]>(
    ['payouts', schoolId],
    async () => {
      return await api.report.fetchPayoutsSummary(schoolId)
    },
    options
  )
}
