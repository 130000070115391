import { Typography } from '@olaisaac/design-system'
import { FC } from 'react'

import { StepTitleProps } from './types'
import * as S from './styles'
import { formatInstallmentTextCount } from '../../helpers/formatInfoText'

const StepTitle: FC<StepTitleProps> = ({
  studentName,
  productName,
  overdueCount,
  ondueCount,
  totalAmount,
}) => {
  return (
    <S.WrapperColumn>
      <Typography withoutMargin color="primary" variation="headlineDesktopSmall">
        {`${studentName} - ${productName}`}
      </Typography>
      <S.Wrapper>
        <Typography color="secondary" variation="bodySmall">
          {formatInstallmentTextCount(overdueCount, ondueCount, totalAmount)}
        </Typography>
      </S.Wrapper>
    </S.WrapperColumn>
  )
}

export default StepTitle
