import styled from 'styled-components'
import { spacing } from '@gravity/tokens'

export const Form = styled.form`
  padding-top: ${spacing[6]};
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ChipsWrapper = styled.div`
  margin-top: ${({ theme }) => `${theme.gravity.spacing['2']}`};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > :last-child {
    margin-right: 0;
  }
`
export const ChipContainer = styled.div`
  margin-top: ${({ theme }) => `${theme.gravity.spacing['2']}`};
  margin-right: ${({ theme }) => `${theme.gravity.spacing['2']}`};
`
