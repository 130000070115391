import { rest } from 'msw'

import config from '@/config'

import {
  guardiansInstallmentsMock,
  guardiansDetailsMock,
  guardiansListMock,
  guardiansContractsMock,
  guardiansFiltersMock,
  negotiatedInstV1Mock,
} from '.'

const guardiansBFF = path => {
  return new URL(`/ws/v2${path}`, config.API.URL).toString()
}

const guardiansList = rest.get(guardiansBFF('/guardians'), (req, res, ctx) =>
  res(ctx.json(guardiansListMock()))
)

const guardiansDetails = rest.get(guardiansBFF('/guardians/:guardianId'), (req, res, ctx) =>
  res(ctx.json(guardiansDetailsMock()))
)

const guardiansContracts = rest.get(guardiansBFF('/contract'), (req, res, ctx) =>
  res(ctx.json(guardiansContractsMock()))
)

const guardiansFilters = rest.get(guardiansBFF('/contract/filters'), (req, res, ctx) =>
  res(ctx.json(guardiansFiltersMock()))
)

const guardiansInstallments = rest.get(guardiansBFF('/installment'), (req, res, ctx) =>
  res(ctx.delay(2000), ctx.json(guardiansInstallmentsMock()))
)

const guardiansNegotiationInstallment = rest.get(
  guardiansBFF('/installment/:urn/guardian-negotiation-drawer'),
  (req, res, ctx) => res(ctx.delay(2000), ctx.json({ data: negotiatedInstV1Mock() }))
)

export const guardiansModuleMocks = [
  guardiansList,
  guardiansDetails,
  guardiansContracts,
  guardiansFilters,
  guardiansInstallments,
  guardiansNegotiationInstallment,
]
