import { FC } from 'react'
import { ConfirmManualLiquidationDrawerActions } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/ConfirmManualLiquidationDrawerActions'
import { Typography, DialogContent } from '@olaisaac/design-system'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

const StyledOverlay = styled.div`
  opacity: 0.87;
  position: absolute;
  background: #fff;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
`

export type ConfirmManualLiquidationContentProps = {
  isLoading: boolean
  submitHandler: () => Promise<void>
}

const ConfirmManualLiquidationContent: FC<ConfirmManualLiquidationContentProps> = ({
  submitHandler,
  isLoading,
}) => {
  return (
    <>
      {isLoading && (
        <StyledOverlay>
          <CircularProgress />
        </StyledOverlay>
      )}
      <DialogContent>
        <Typography variation="bodyLarge">Essa ação altera os próximos repasses.</Typography>
      </DialogContent>

      <ConfirmManualLiquidationDrawerActions submitHandler={submitHandler} />
    </>
  )
}

export default ConfirmManualLiquidationContent
