import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useSelectPos } from 'src/escolas/hooks'
import InputListPos from '@/modules/guardians/Negotiation/components/Inputs/InputListPos'
import { Pos } from 'src/shared/interfaces'
import { CardFormProps } from './types'
import InputInstallments from '@/modules/guardians/Negotiation/components/Inputs/InputInstallments'

const CardForm = ({
  handleValidate,
  handleValue,
  paymentOption,
  maxInstallment,
  totalAmount,
}: CardFormProps) => {
  const [pos, setPos] = useState<Pos | null>(null)
  const [installments, setInstallments] = useState<number>(1)
  const { listPos } = useSelectPos()

  useEffect(() => {
    if (listPos.length === 1) {
      setPos(listPos[0])
    }
  }, [listPos])

  useEffect(() => {
    const isValid = !!pos

    if (isValid) {
      handleValue({ pos, installments, minDownPayment: 0, paymentOption })
    }
    handleValidate(isValid)
  }, [pos, installments])

  const showListPos = listPos.length > 1

  const handlePos = value => {
    setPos(listPos.find(pos => value === pos.pos_id))
  }

  return (
    <Box display="flex" flexWrap="wrap" marginLeft={8}>
      {maxInstallment > 1 && (
        <InputInstallments
          maxInstallment={maxInstallment}
          totalAmount={totalAmount}
          handleValue={(value: number) => setInstallments(value)}
          selectedValue={installments}
        />
      )}

      {showListPos && (
        <InputListPos handleValue={handlePos} selectedValue={pos?.pos_id || null} list={listPos} />
      )}
    </Box>
  )
}

export default CardForm
