import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'
import { Installment } from '../../GuardianDetails/types'

export const useNegotiationFlow = () => {
  const { checkoutCart } = useCheckoutCart()

  const isDownPayment = (installment: Installment) =>
    (installment.type === 'DOWN_PAYMENT' || installment.is_from_credit_card_flow) &&
    installment.urn.includes('urn:down-payment')

  const downPayments = checkoutCart?.filter(installment => isDownPayment(installment))
  const receivables = checkoutCart?.filter(installment => !isDownPayment(installment))

  return {
    isEnableCheckoutWithoutRestrictions: !(downPayments?.length === 1 && receivables?.length === 0),
  }
}
