import styled from 'styled-components'

import theme from '@/shared/theme'

const VALUE_COLOR = theme.primitiveTokens.colors.gray[80]

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2.5rem;
`

export const Value = styled.p`
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  white-space: nowrap;
  color: ${VALUE_COLOR};
`
