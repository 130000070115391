import { Text } from '@gravity/text'
import { Table } from '@gravity/table'

import { PaymentDisputeItemTableRow } from './components/PaymentDisputeItemTableRow'
import { PaymentDisputeAccordionContentVariationsProps } from '..'

import { AccordionContentTable, AccordionContentWrapper, AccordionTitleWrapper } from './styles'

export const PreviousPayment = ({
  loading,
  paymentDispute,
  paymentDisputeItems,
}: PaymentDisputeAccordionContentVariationsProps) => {
  return (
    <AccordionContentWrapper>
      <AccordionTitleWrapper>
        <Text variant="subtitle-medium">Justificativa da cobrança indevida</Text>
        <Text variant="body-1-regular">
          O responsável financeiro informa que&nbsp;
          <strong>realizou o pagamento das parcelas diretamente na escola.</strong>
        </Text>
      </AccordionTitleWrapper>

      <AccordionContentTable>
        <Text variant="subtitle-medium">Faturas contestadas</Text>
        <Table.Root>
          <Table.Head>
            <Table.HeaderCell name="due_date">Vencimento</Table.HeaderCell>
            <Table.HeaderCell minWidth={200} name="student_name">
              Aluno
            </Table.HeaderCell>
            <Table.HeaderCell minWidth={200} name="product_name">
              Produto
            </Table.HeaderCell>
            <Table.HeaderCell name="paid_date">Data de pagamento</Table.HeaderCell>
            <Table.HeaderCell name="amount">Valor</Table.HeaderCell>
            <Table.HeaderCell minWidth={200} name="status">
              Situação
            </Table.HeaderCell>
          </Table.Head>

          <Table.Body>
            {loading
              ? Array.from({ length: 3 }).map((_, key) => (
                  <Table.Row key={key}>
                    <Table.LoadingCell />
                    <Table.LoadingCell />
                    <Table.LoadingCell />
                    <Table.LoadingCell />
                    <Table.LoadingCell />
                    <Table.LoadingCell />
                  </Table.Row>
                ))
              : paymentDisputeItems.map(item => (
                  <PaymentDisputeItemTableRow
                    key={item.id}
                    paymentDispute={paymentDispute}
                    paymentDisputeItem={item}
                  />
                ))}
          </Table.Body>
        </Table.Root>
      </AccordionContentTable>
    </AccordionContentWrapper>
  )
}
