export enum PaymentMethod {
  BANK_CHECK = 'BANK_CHECK',
  BANK_DEPOSIT = 'BANK_DEPOSIT',
  BANK_SLIP_PAYMENT_METHOD = 'BANK_SLIP',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CASH = 'CASH',
  CREDIT_CARD_PAYMENT_METHOD = 'CREDIT_CARD',
  DEBIT_CARD_PAYMENT_METHOD = 'DEBIT_CARD',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  OTHER = 'OTHER',
  PIX_PAYMENT_METHOD = 'PIX',
  SCHOOL_PORTFOLIO_REPURCHASE = 'SCHOOL_PORTFOLIO_REPURCHASE',
  SUBSCRIPTION = 'SUBSCRIPTION',
}
