import styled, { css } from 'styled-components'
import { SideMenuLink as SideMenuLinkDS } from '@olaisaac/design-system'

export const SideMenuLink = styled(SideMenuLinkDS)`
  .label-item {
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.gravity.colors['colors-text-main-3']};
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus-visible {
        .label-item {
          color: ${({ theme }) => theme.gravity.colors['colors-text-main-1']};
        }
      }
    `}

  ${({ active }) =>
    active &&
    css`
      .label-item {
        color: ${({ theme }) => theme.gravity.colors['colors-text-main-1']};
      }
    `}
`
