import styled from 'styled-components'
import { Box } from '@material-ui/core'

import { spacing } from '@gravity/tokens'

export const ButtonGroup = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]};
`
