import styled from 'styled-components'

export const Container = styled.div`
  padding-top: ${props => props.theme.gravity.spacing[16]};

  .quick-access {
    padding-bottom: 0;
  }

  .banner {
    margin-bottom: 0;
  }

  .main-info {
    margin-top: 0;
  }

  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[20]};
`
