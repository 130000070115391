import { UseFormSetValue } from 'react-hook-form'
import {
  FiltersInstallmentsFormType,
  FiltersInstallmentsGroupType,
} from '../components/GuardianDetailsInstallmentsFilter/types'
import { AllFiltersOptionsType } from '../../GuardianDetailsContractsTab/types'

/**
 * @description Make a group of filters to be used in Filters Drawer.
 * @param allFiltersOptions Options of filters by the API.
 * @param setValue React Hook Form setValue function.
 * @returns A group of filters FiltersGroupType[].
 */
export const getInstallmentsFiltersGroup = (
  allFiltersOptions: AllFiltersOptionsType,
  setValue: UseFormSetValue<FiltersInstallmentsFormType>
): FiltersInstallmentsGroupType[] => {
  const filters: Record<string, FiltersInstallmentsGroupType> = {
    reference_years: {
      label: 'Ano',
      filterName: 'reference_years',
      options: allFiltersOptions.year,
      totalOptions: allFiltersOptions.year?.length,
      searchText: '',
      onClearFilter: () => setValue('reference_years', []),
    },
    student_ids: {
      label: 'Aluno',
      filterName: 'student_ids',
      options: allFiltersOptions?.student,
      totalOptions: allFiltersOptions.student?.length || 0,
      searchText: '',
      onClearFilter: () => setValue('student_ids', []),
    },
    product_ids: {
      label: 'Produto',
      filterName: 'product_ids',
      options: allFiltersOptions.products,
      totalOptions: allFiltersOptions.products?.length || 0,
      searchText: '',
      onClearFilter: () => setValue('product_ids', []),
    },
    installment_types: {
      label: 'Tipo de fatura',
      filterName: 'installment_types',
      options: allFiltersOptions.installmentType,
      totalOptions: allFiltersOptions.installmentType?.length,
      searchText: '',
      onClearFilter: () => setValue('installment_types', []),
    },
    installment_statuses: {
      label: 'Situação',
      filterName: 'installment_statuses',
      options: allFiltersOptions.installmentStatus,
      totalOptions: allFiltersOptions.installmentStatus?.length,
      searchText: '',
      onClearFilter: () => setValue('installment_statuses', []),
    },
  }

  return [
    filters.reference_years,
    filters.installment_statuses,
    filters.student_ids,
    filters.product_ids,
    filters.installment_types,
  ]
}
