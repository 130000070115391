import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import { TextField, Typography, DatePicker } from '@olaisaac/design-system'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useIsReenrollment } from './hooks/useIsReenrollment'
import { FormProps } from './types'
import { useEffect } from 'react'
import { validateCpf, DATE_FORMAT, isDayBeforeToday } from '@/shared/utils'
import NumberFormat from 'react-number-format'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { Checkbox } from '@gravity/checkbox'
import { omit } from 'ramda'

type StudentFormProps = {
  form: UseFormReturn<FormProps>
}

export const StudentForm = ({ form }: StudentFormProps) => {
  const { control, trigger, clearErrors, watch, getValues } = form
  const { isReenrollment, student } = useIsReenrollment()

  const noTaxId = watch('student.no_tax_id')

  const enableStudentCPFandBirthDate = useUnleashFlag(
    UnleashFlags.EFI_171_STUDENT_CPF_AND_BIRTHDATE
  )

  useEffect(() => {
    form.setValue('student', {
      ...student,
      birth_date: null,
      tax_id: '',
      no_tax_id: isReenrollment || !enableStudentCPFandBirthDate,
    })
  }, [student])

  const validateStudentTaxId = (value: string) => {
    const skipTaxIdValidation = getValues('student.no_tax_id')
    if (skipTaxIdValidation) return true

    return validateCpf(value)
  }

  // TODO: Review this rule for reenrollment flow
  const shouldShowCPFAndBirthDate = enableStudentCPFandBirthDate && !isReenrollment

  return (
    <FormControl fullWidth variant="outlined">
      <Box mb={3}>
        <Typography variation="headlineDesktopXsmall">Aluno(a)</Typography>
        <Typography variation="bodyLarge" color="secondary">
          Este é o nome do aluno(a) que será matrículado ou rematrículado.
        </Typography>
      </Box>
      <Controller
        rules={{ required: true }}
        name="student.name"
        control={control}
        render={({ field: { ...rest }, fieldState: { error } }) => (
          <TextField
            {...rest}
            id="student"
            disabled={isReenrollment}
            label="Nome do aluno"
            error={Boolean(error?.type)}
            helperText={error?.type ? 'Insira um nome ou selecione um aluno cadastrado' : ''}
          />
        )}
      />

      {shouldShowCPFAndBirthDate && (
        <Box mt={3} display="flex" alignItems="start" style={{ gap: '24px' }}>
          <Controller
            control={control}
            name="student.birth_date"
            rules={{
              required: true,
              validate: {
                isInThePast: v => isDayBeforeToday(v) || 'Informe uma data válida no passado',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Data de nascimento"
                placeholder={DATE_FORMAT}
                format={DATE_FORMAT}
                disableFuture
                error={Boolean(error)}
                helperText={error?.message}
                {...omit(['ref'], field)}
              />
            )}
          />
          <Controller
            rules={{
              validate: validateStudentTaxId,
            }}
            name="student.tax_id"
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <NumberFormat
                style={{ flexGrow: 1 }}
                {...rest}
                disabled={noTaxId}
                id="student.tax_id"
                type="tel"
                value={value}
                format="###.###.###-##"
                label="CPF"
                error={Boolean(error?.type)}
                helperText={error ? 'Insira um CPF válido' : ''}
                onValueChange={async currentValue => {
                  clearErrors('student.tax_id')

                  onChange(currentValue.value)

                  if (currentValue.value.length === 11) {
                    trigger('student.tax_id')
                  }
                }}
                customInput={TextField}
                variant="outlined"
              />
            )}
          />
          <Box style={{ paddingTop: '14px' }}>
            <Controller
              name="student.no_tax_id"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  checked={value}
                  onCheckedChange={e => {
                    onChange(e)
                    trigger('student.tax_id')
                  }}
                  text="Aluno não possui CPF"
                  size={1}
                />
              )}
            />
          </Box>
        </Box>
      )}
    </FormControl>
  )
}
