import { ButtonGroup, CheckBoxContainer, GhostButton } from './styles'
import { Button } from '@gravity/button'
import { Checkbox } from '@gravity/checkbox'
import { useHistory } from 'react-router-dom'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useEffect, useState } from 'react'
import DefaultARTemplate from '@/modules/credit/templates/DefaultARTemplate'
import { Link } from '@gravity/link'
import { Typography } from '@olaisaac/design-system'
import { DefaultModal } from '../../../components/Modal'
import { AnticipationChooseOfferButton } from '../../../components/PayoutAnticipationComponents/AnticipationChooseOfferButton'
import { useGetLock } from '../../../hooks/queries/useGetLock'
import { Tooltip } from '@gravity/tooltip'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import {
  useContextChosenOffer,
  useContextEligibility,
  useContextRequestSimulation,
  useContextResponseSimulation,
} from '@/modules/credit/contexts/PayoutAnticipationContext'
import PayoutAnticipationSummaryCard from '@/modules/credit/components/PayoutAnticipationComponents/PayoutAnticipationSummaryCard'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '@/modules/credit/constants/MixpanelEventActions.enum'
import { CreditEventComponentNames } from '@/modules/credit/constants/CreditEventComponentNames.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import useMixpanelEventSender from '@/modules/credit/utils/useMixpanelEventSender'
import { Banner } from '@/modules/credit/components/PayoutAnticipationComponents/Banner'
import { useGetSchoolEligibility } from '@/modules/credit/hooks/queries/useGetSchoolEligibility'
import { LoadingSimulation } from '../Simulation/LoadingSimulation'

export const PayoutAnticipationSummary = () => {
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()
  const { push } = useHistory()
  const { school, schoolSlug } = useSelectedSchool()
  const { responseSimulation } = useContextResponseSimulation()
  const schoolId = school?.id ?? ''
  const { data: lock, isLoading: isLoadingLock } = useGetLock(schoolId)

  const { setChosenOffer } = useContextChosenOffer()
  setChosenOffer(responseSimulation.offer)

  const { eligibility } = useContextEligibility()
  const { requestSimulation } = useContextRequestSimulation()
  const isTermsAndConditionsEnabled = useUnleashFlag(
    UnleashFlags.CRD_114_ENABLE_TERMS_AND_CONDITIONS_TO_AR
  )
  const isInMaintenance = useUnleashFlag(UnleashFlags.CRD_166_DISABLE_CREDIT_FLOWS)
  const isCreditBannerEnabled = useUnleashFlag(UnleashFlags.CRD_189_ENABLE_CREDIT_BANNER)

  const {
    data: creditEligibility,
    isLoading: isLoadingCreditEligibility,
  } = useGetSchoolEligibility(schoolId, {
    enabled: !isInMaintenance,
    retry: 1,
  })

  useEffect(() => {
    sendCreditEventToMixpanel(
      EventDispatcherEvents.PAGE_VIEWED,
      MixpanelEventActions.PAGE_VIEW,
      {
        $page_name: CreditEventComponentNames.SIMULATION_RESULT,
      },
      EventDispatcherEventScopes.PAYOUT_ANTICIPATION
    )
  }, [])

  const arPA = eligibility?.eligibility?.pa?.AR ?? 0
  const isEqualPa = requestSimulation.requestedValue / 100 === arPA
  const isValueGreaterThanPa = arPA < requestSimulation.requestedValue / 100
  const creditEligible = creditEligibility?.eligibility?.eligible ?? { CP: false, CPCR: false }

  const orderLock = !isLoadingLock && {
    isLocked: lock?.isLocked ?? false,
    creditType: lock?.order?.data?.chosen_offer?.type ?? '',
  }
  const handleSimulateNewOffer = () => {
    push(`/${schoolSlug}/antecipacao/repasse/simulacao`)
  }

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const [isChecked, setIsChecked] = useState(!isTermsAndConditionsEnabled)

  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked)
    if (checked) {
      sendCreditEventToMixpanel(
        EventDispatcherEvents.MODAL_VIEWED,
        MixpanelEventActions.COMPONENT_VIEW,
        {
          $page_name: CreditEventComponentNames.SIMULATION_RESULT,
          $modal_name: CreditEventComponentNames.TERMS_AND_CONDITIONS_MODAL,
        },
        EventDispatcherEventScopes.PAYOUT_ANTICIPATION
      )
    }
  }

  const handleChooseOfferError = () => {
    setShowConfirmModal(false)
    setShowErrorModal(true)
    sendCreditEventToMixpanel(
      EventDispatcherEvents.MODAL_VIEWED,
      MixpanelEventActions.COMPONENT_VIEW,
      {
        $page_name: CreditEventComponentNames.SIMULATION_RESULT,
        $modal_name: CreditEventComponentNames.ANTICIPATION_ERROR_CONFIRM_MODAL,
      },
      EventDispatcherEventScopes.PAYOUT_ANTICIPATION
    )
  }

  const handleChooseOfferClick = () => {
    sendCreditEventToMixpanel(
      EventDispatcherEvents.BUTTON_CLICKED,
      MixpanelEventActions.CLICK,
      {
        $button_name: CreditEventComponentNames.REQUEST_ANTICIPATION,
        $page_name: CreditEventComponentNames.SIMULATION_RESULT,
        $selected: requestSimulation.reason,
        $note: requestSimulation.note,
        $request_value: requestSimulation.requestedValue,
        $maximum: isValueGreaterThanPa ? 'more' : isEqualPa ? 'equal' : 'less',
      },
      EventDispatcherEventScopes.PAYOUT_ANTICIPATION
    )
    setShowConfirmModal(true)

    sendCreditEventToMixpanel(
      EventDispatcherEvents.MODAL_VIEWED,
      MixpanelEventActions.COMPONENT_VIEW,
      {
        $page_name: CreditEventComponentNames.SIMULATION_RESULT,
        $modal_name: CreditEventComponentNames.ANTICIPATION_CONFIRM_MODAL,
      },
      EventDispatcherEventScopes.PAYOUT_ANTICIPATION
    )
  }

  if (!isInMaintenance && isLoadingCreditEligibility) {
    return <LoadingSimulation />
  }

  return (
    <DefaultARTemplate
      hasBackButton={false}
      title="Resumo da antecipação"
      child={
        <>
          <PayoutAnticipationSummaryCard
            requestValue={responseSimulation.creditValue}
            offer={responseSimulation.offer}
          />

          {isTermsAndConditionsEnabled && (
            <CheckBoxContainer>
              <Checkbox
                size={1}
                variant="surface"
                onCheckedChange={(checked: boolean) => handleCheckboxChange(checked)}
              />
              <Typography>
                Aceito os{' '}
                <DefaultModal
                  title="Termos e Condições"
                  description="O texto definido entrará nessa modal como forma de visualização"
                  backdrop
                >
                  <Link>Termos e condições</Link>
                </DefaultModal>{' '}
                da Antecipação de Repasse
              </Typography>
            </CheckBoxContainer>
          )}

          <ButtonGroup>
            <GhostButton variant="ghost" onClick={handleSimulateNewOffer}>
              Simular outro valor
            </GhostButton>

            <Tooltip
              hidden={!orderLock || !orderLock.isLocked}
              text={`Existe um pedido de crédito ou antecipação\n em andamento. Aguarde sua conclusão\n antes de solicitar uma nova antecipação.`}
            >
              <span>
                <Button
                  disabled={!orderLock || orderLock.isLocked || !isChecked}
                  onClick={handleChooseOfferClick}
                >
                  Solicitar antecipação
                </Button>
              </span>
            </Tooltip>
          </ButtonGroup>

          <Banner
            hidden={!isCreditBannerEnabled || (!creditEligible.CP && !creditEligible.CPCR)}
            buttonLabel="Simular crédito"
            buttonOnClick={() => {
              sendCreditEventToMixpanel(
                EventDispatcherEvents.BUTTON_CLICKED,
                MixpanelEventActions.CLICK,
                {
                  $button_name: CreditEventComponentNames.CREDIT_SIMULATION_BUTTON,
                },
                EventDispatcherEventScopes.PAYOUT_ANTICIPATION
              )
              push(`/${schoolSlug}/credito`)
            }}
            explanation="Simule o Crédito isaac: ideal para fortalecer o capital de giro, com parcelamento flexível e sem afetar o fluxo de caixa a longo prazo."
            title="O valor antecipado vai fazer falta no mês seguinte?"
          />

          <DefaultModal
            title="Deseja confirmar a antecipação?"
            description={`Você receberá uma antecipação de ${responseSimulation.creditValue} em ${responseSimulation.offer.disbursementDate}.`}
            size={2}
            backdrop
            confirmButton={
              <AnticipationChooseOfferButton
                text="Confirmar"
                handleError={handleChooseOfferError}
                redirectionWhenSuccess={`/${schoolSlug}/antecipacao/repasse/status`}
              />
            }
            backButton={
              <Button variant="ghost" onClick={() => setShowConfirmModal(false)}>
                Cancelar
              </Button>
            }
            showModal={showConfirmModal}
            setShowModal={setShowConfirmModal}
          />

          <DefaultModal
            title="Solicitação não concluída"
            description="Houve um problema ao processar a sua solicitação de antecipação de repasse. Tente novamente mais tarde."
            size={1}
            backButton={<></>}
            confirmButton={
              <Button
                onClick={() => {
                  setShowErrorModal(false)
                  location.reload()
                }}
              >
                Ok, entendi
              </Button>
            }
            backdrop
            showModal={showErrorModal}
            setShowModal={setShowErrorModal}
          />
        </>
      }
    />
  )
}
