import { includes } from 'ramda'
import { BadgeProps } from '@gravity/badge'

import { Installment } from '@/modules/guardians/GuardianDetails/types'
import {
  InstallmentType,
  InstallmentRowType,
  ReceivableType,
  InstallmentStatus,
} from '@/modules/guardians/GuardianDetails/constants'

import { Rows } from '@/modules/guardians/types'

export const getRowType = (
  type: Installment['type'],
  receivable_type: Installment['receivable_type']
) => {
  if (type === InstallmentType.DOWN_PAYMENT) return InstallmentRowType.NEGOTIATION_START

  if (receivable_type === ReceivableType.NEGOTIATION) return InstallmentRowType.NEGOTIATION

  if (receivable_type === ReceivableType.ENROLLMENT)
    return InstallmentRowType.ENROLLMENT_INSTALLMENT

  return InstallmentRowType.INSTALLMENT
}

export const paramsDict: PartialRecord<
  InstallmentStatus,
  { color: BadgeProps['color']; text: string }
> = {
  AGGLUTINATED: { color: 'neutral', text: 'Aglutinada' },
  CANCELED: { color: 'neutral', text: 'Cancelada' },
  DUE_TODAY: { color: 'warning', text: 'Vence hoje' },
  OPEN: { color: 'accent', text: 'A vencer' },
  OVERDUE: { color: 'error', text: 'Vencido' },
  PAID: { color: 'success', text: 'Pago' },
  PAID_AND_CANCELED: { color: 'neutral', text: 'Paga e cancelada' },
  RENEGOTIATED: { color: 'neutral', text: 'Renegociada' },
  PENDING: { color: 'neutral', text: 'Aguardando' },
}

export const mapDataToRows = (rawData: Installment[]): Rows => {
  return rawData?.map(item => {
    const row_type = getRowType(item.type, item.receivable_type)

    const isStatusOpen = includes(item.status, [
      InstallmentStatus.DUE_TODAY,
      InstallmentStatus.OPEN,
      InstallmentStatus.OVERDUE,
    ])

    return {
      ...item,
      installment_order: {
        order_reference: item.order_reference,
        row_type,
      },
      installment_info: {
        checkable: isStatusOpen,
        competence_date: item.competence_date,
        negotiation_id: item.negotiation_id,
        row_type,
      },
      id: item.urn,
      installment_status: {
        overdue_for_days: item.overdue_for_days,
        paid_date: item.paid_date,
        row_type: row_type,
        status: item.status,
        status_description: item.status_description,
        // -- --
        color: paramsDict[item.status].color,
        text: paramsDict[item.status].text,
      },
      key: item.urn,
      row_type,
      disabled: includes(item.status, [InstallmentStatus.PENDING, InstallmentStatus.CANCELED]),
      checked: false,
      enableCheckbox:
        !(
          item.receivable_type === ReceivableType.ENROLLMENT &&
          item.status === InstallmentStatus.OVERDUE
        ) && isStatusOpen,
      enableCheckboxVisibility: isStatusOpen,
    }
  })
}
