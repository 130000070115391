import IsaacLogo from '@/escolas/components/IsaacLogo'
import { UnleashFlags, useUnleashVariant } from '@/shared/hooks'
import { Box } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import Graphism from '@/shared/assets/graphism.svg'

import { StyledContainer, StyledGraphismContainer, StyledMain } from './styles'

export const Maintenance = () => {
  const variant = useUnleashVariant(UnleashFlags.ENABLE_MAINTENANCE_PAGE)
  const message = variant?.payload?.value

  return (
    <StyledMain>
      <StyledContainer>
        <Box pt={28} textAlign="center" position="relative" zIndex={1}>
          <IsaacLogo />

          <Box pt={7}>
            <Typography variation="headlineDesktopSmall" color="accent">
              Estamos em manutenção e retornaremos em breve!
            </Typography>
            {message && (
              <Typography variation="bodySmall" dangerouslySetInnerHTML={{ __html: message }} />
            )}
          </Box>
        </Box>

        <StyledGraphismContainer>
          <img src={Graphism} />
        </StyledGraphismContainer>
      </StyledContainer>
    </StyledMain>
  )
}
