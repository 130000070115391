import styled from 'styled-components'

import { Card } from '@gravity/card'

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gravity.spacing[3]};
`

export const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.gravity.spacing[8]};
  justify-content: space-between;
`
