import { RadioGroup, Box } from '@material-ui/core'
import styled from 'styled-components'

export const Card = styled(Box)`
  display: flex;
  padding: 24px 32px 40px 34px;
  flex-direction: column;
`

export const RadioGroupStyled = styled(RadioGroup)`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  &:last-child ${Card} {
    border-bottom: 0;
  }
`
