import { ReactNode } from 'react'

import { Container } from './styles'

type PageWrapperProps = {
  children: ReactNode
}

/**
 * INFO: Componente provisório para compensar o padding que foi removido do layout
 */
export const PageWrapper = ({ children }: PageWrapperProps) => {
  return <Container>{children}</Container>
}
