import { FiltersContractsFormType } from '../components/GuardianDetailsContractsFilter/types'
import { ContractsFiltersType } from '../types'

/**
 * @description Transform filters form data to filters to be used in API.
 * @param data Filters form data.
 * @returns Filters to be used in API.
 */
export const updatedFiltersForm = (data: FiltersContractsFormType) => {
  return Object.keys(data).reduce((acc, key) => {
    const filter = data[key as keyof FiltersContractsFormType]

    if (Array.isArray(filter) && filter.length === 0) return acc

    if (!Array.isArray(filter) && !filter) return acc

    return {
      ...acc,
      [key]: filter,
    }
  }, {} as ContractsFiltersType)
}
