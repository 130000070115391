import dayjs from 'dayjs'

export default function getReceivableMonth(dueDate: string, competenceDate?: string) {
  const isRenegotiation = competenceDate && competenceDate !== dueDate
  const dueMonth = dayjs(dueDate).utc().format('MMMM')
  const competenceMonth = dayjs(competenceDate).utc().format('MMMM')

  if (isRenegotiation) {
    return dueMonth === competenceMonth
      ? `${dueMonth} (Parcela renegociada)`
      : `${dueMonth} (Parcela renegociada de ${competenceMonth})`
  }

  return dueMonth
}
