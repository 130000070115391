import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const UserGuidingGuidesIds = {
  /**
   * Alerta do botao de acordo
   */
  AGREEMENT_BUTTON: '71676',
  /**
   * Matrículas 2024
   */
  ENROLLMENT_PAGE: '92937',
  /**
   * Matrículas 2024 - Escolas integradas
   */
  ENROLLMENT_PAGE_INTEGRATED_SCHOOL: '93809',
  /**
   * Nova página do responsável - Escolas Autoserviço
   */
  GUARDIAN_DETAILS_PAGE: '93601',
  /**
   * Nova página do responsável - Escolas integradas
   */
  GUARDIAN_DETAILS_PAGE_INTEGRATED_SCHOOL: '94077',
  /**
   * Listagem de responsável
   */
  LIST_GUARDIANS_PAGE: '94242',

  /**
   * Negociação de parcelas
   */
  NEGOTIATION: '95264',
} as const

/**
 * Hook used to load a page and trigger a guide when clicking on one of the Home page guides
 */

export const useUserGuidingTrigger = () => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  useEffect(() => {
    const guideId = searchParams.get('guide')
    const trigger = searchParams.get('trigger')

    if (guideId && trigger === 'custom') {
      /**
       * Triggers guides using a custom trigger. Should not be used when userGuiding auto-trigger option is enabled, unless you want to combine those two strategies.
       */
      window.userGuiding.previewGuide(parseInt(guideId))
    }
  }, [])

  return {
    canUseAutoTrigger: !(searchParams.has('trigger') && searchParams.get('trigger') === 'custom'),
  }
}
