import { useContext } from 'react'
import { CheckoutCartStore } from '../contexts/CheckoutCartContext'

const useCheckoutCart = () => {
  const {
    addInstallments,
    addInstallment,
    checkoutCart,
    clearCheckoutCart,
    removeInstallment,
    totalAmount,
  } = useContext(CheckoutCartStore)
  return {
    addInstallments,
    addInstallment,
    checkoutCart,
    clearCheckoutCart,
    removeInstallment,
    totalAmount,
  }
}

export default useCheckoutCart
