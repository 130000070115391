import { TableRowProps } from './types'

import { StyledMuiTableRow } from './styles'

export const TableRow = (props: TableRowProps) => {
  const { children, onRowClick, onKeyDown } = props

  return (
    <StyledMuiTableRow onClick={onRowClick} onKeyDown={onKeyDown} tabIndex={0} hover>
      {children}
    </StyledMuiTableRow>
  )
}
