import dayjs from 'dayjs'
import { PreContract } from 'src/shared/interfaces'
import { TuitionEditFormValues } from '../types'
import { getDiscounts } from './getDiscounts'

export const getInstallments = (contract: PreContract): TuitionEditFormValues => {
  const {
    tuition_installments: {
      monthly_amount,
      duration_months,
      start_month,
      use_working_dates,
      due_day,
      pre_contract_buffer_id,
    },
  } = contract

  return {
    duration_months,
    monthly_amount,
    start_month: duration_months > 1 ? dayjs(start_month).set('date', 1) : dayjs(start_month),
    use_working_dates_tuition: use_working_dates ? 'true' : 'false',
    due_day,
    discounts: getDiscounts(contract),
    pre_contract_buffer_id,
  }
}
