import styled from 'styled-components'
import { Card } from '@gravity/card'
import { colors, spacing } from '@gravity/tokens'

export const ContainerCard = styled(Card)`
  margin-top: ${spacing[10]};
  margin-bottom: ${spacing[12]};
  padding: ${spacing[6]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[4]};
  width: 100%;
  border-radius: ${spacing[2]};
  border: none;
  background-color: ${colors['colors-background-neutral-2']};
  align-items: flex-start;
`
