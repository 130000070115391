export enum EventDispatcherEntities {
  AGGLUTINATION = 'agglutination',
  AGGLUTINATION_FINISH = 'agglutination_finish',
  BUTTON_BASE_HELP = 'button_base_help',
  CHECKOUT_CANCEL = 'checkout_cancel',
  CHECKOUT_FIRST_STEP_BACK = 'checkout_first_step_back',
  CHECKOUT_FIRST_STEP_CONTINUE = 'checkout_first_step_continue',
  CHECKOUT_GO_BACK = 'checkout_go_back',
  CHECKOUT_LAST_STEP = 'checkout_last_step',
  CHECKOUT_SECOND_STEP_CONTINUE = 'checkout_second_step_continue',
  CHECKOUT_SECOND_STEP_GO_BACK = 'checkout_second_step_go_back',
  CHECKOUT_SUCCESSFUL_TRANSACTION_COPY_LINK = 'checkout_successful_transaction_copy_link',
  CHECKOUT_SUCCESSFUL_TRANSACTION_GENERATE_RECEIPT = 'checkout_successful_transaction_generate_receipt',
  CHECKOUT_TRY_AGAIN = 'checkout_try_again',
  INVOICE_LINK_COPIED = 'invoice_link_copied',
  MANUAL_LIQUIDATION = 'manual_liquidation',
  MANUAL_LIQUIDATION_FINISH = 'manual_liquidation_finish',
  MANUAL_LIQUIDATION_PAST_DATE_WARNING = 'manual_liquidation_past_date_warning',
  OPEN_NEGOTIATION_MODAL = 'open_negotiation_modal',
  OPEN_NEGOTIATION_MODAL_CONFIRM = 'open_negotiation_modal_confirm',
  PRINT_BANKSLIP = 'print_bankslip',
  PRINT_DEMONSTRATIVE_PAID_VALUES = 'print_demonstrative_paid_values',
  PRINT_OPEN_SLIPS = 'print_open_slips',
  RENEGOTIATE = 'renegotiate',
  RENEGOTIATE_CONFIRM = 'renegotiate_confirm',
}
