import { Icon } from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'

import * as Styled from './styles'
import { Status, StepItemProps } from './types'

export const StepItem = ({ label, status, active, onClick }: StepItemProps) => {
  return (
    <Styled.StepItem onClick={onClick} active={active} status={status}>
      {label}
      {status !== Status.DISABLED && <Icon path={mdiChevronRight} size={1} />}
    </Styled.StepItem>
  )
}
